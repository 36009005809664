
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class BiddingAnnouncement extends Vue {
  @Prop() info: any
  @Prop({ default: false }) checkboxShow?: boolean
  @Prop({
    default: () => []
  })
  itemStatus?: any
  @Prop({}) processStatus:number
}
