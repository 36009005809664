var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "add-file" },
    [
      _c(
        "el-form",
        {
          ref: "formInfo",
          attrs: {
            rules: _vm.rules,
            model: _vm.formInfo,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "文件类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.formInfo.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "type", $$v)
                    },
                    expression: "formInfo.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "文件", value: 1 } }),
                  _c("el-option", { attrs: { label: "文本", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件名称：", prop: "fileName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文件名称" },
                model: {
                  value: _vm.formInfo.fileName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "fileName", $$v)
                  },
                  expression: "formInfo.fileName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件唯一标识：", prop: "fileType" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文件唯一标识" },
                model: {
                  value: _vm.formInfo.fileType,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "fileType", $$v)
                  },
                  expression: "formInfo.fileType"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色：", prop: "role" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.formInfo.role,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "role", $$v)
                    },
                    expression: "formInfo.role"
                  }
                },
                _vm._l(_vm.roleList, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.value, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否必须：", prod: "isRequire" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.isRequireChange },
                  model: {
                    value: _vm.formInfo.isRequire,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "isRequire", $$v)
                    },
                    expression: "formInfo.isRequire"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm.formInfo.type == 1 && _vm.formInfo.isRequire
            ? _c(
                "el-form-item",
                { attrs: { label: "需要数量：", prod: "requireNumber" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, placeholder: "请输入文件需要数量" },
                    on: { blur: _vm.requireNumberBlur },
                    model: {
                      value: _vm.formInfo.requireNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "requireNumber", $$v)
                      },
                      expression: "formInfo.requireNumber"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formInfo.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "最大可上传数量：", prod: "maxNumber" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: _vm.maxMin,
                      placeholder: "请输入文件最大可上传数量"
                    },
                    model: {
                      value: _vm.formInfo.maxNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "maxNumber", $$v)
                      },
                      expression: "formInfo.maxNumber"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", prod: "sort" } },
            [
              _c("el-input-number", {
                attrs: { placeholder: "排序" },
                model: {
                  value: _vm.formInfo.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "sort", $$v)
                  },
                  expression: "formInfo.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生成位置：", prop: "generatePosition" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.formInfo.generatePosition,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "generatePosition", $$v)
                    },
                    expression: "formInfo.generatePosition"
                  }
                },
                _vm._l(_vm.generatePositionList, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.value, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.formInfo.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "上传文件：" } },
                [
                  _c("uploadTemp", {
                    attrs: { limt: 1, fileslist: _vm.fileList },
                    on: {
                      result: function($event) {
                        return _vm.fileUploadSuccess($event)
                      },
                      remove: function($event) {
                        return _vm.deleteDownloadUrl()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "分类：", prop: "listingMode" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.formInfo.listingMode,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "listingMode", $$v)
                    },
                    expression: "formInfo.listingMode"
                  }
                },
                _vm._l(_vm.listingModeList, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.value, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleModifyFile("formInfo")
                    }
                  }
                },
                [_vm._v("修改")]
              )
            : _vm._e(),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submit("formInfo")
                    }
                  }
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }