
import { Component, Vue } from 'vue-property-decorator'
import UploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
import QrCode from '@/components/QrCode.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { subsidyInport, subsidyImportSumbit, subsidyReadExcel } from '@/api/countrysidePropertyTransaction'
import { checkBankCardInfo } from '@/api/center'
import { sessionStore } from '../../../utils/data-management'
// import { isNeedFace } from '@/api/common.api'
@Component({
  components: {
    UploadBase,
    QrCode
  }
})
export default class SubsidyInport extends Vue {
  private loadingTxt: string = loadingText
  private file: any
  private fileAddr: String = ''
  private fileName: String = ''
  private isClick: boolean = true
  private bgImg: any = bgSrc
  private download: String = `${process.env.VUE_APP_BASE_URL}/subsidy/template/download`
  // private download: String = 'api/subsidy/template/download'
  private action: String = `${process.env.VUE_APP_BASE_URL}subsidy/template/upload`

  private uploadOption: any = {
    limit: 1,
    accept: '.xlsx,.xls,.csv',
    flag: '1'
  }

  private banks: any = []
  private fee: any = ''
  private grantInfo: any = {
    payBankId: '',
    bussinessId: '',
    payRemark: ''
  }
  private grantRules: any = {
    payBankId: { required: true, message: '请选择支付银行卡', trigger: 'blur' },
    bussinessId: { required: true, message: '请选择费项', trigger: 'blur' }
  }
  private qrDiaShow: boolean = false // 授权认证标志
  private publicPath = process.env.VUE_APP_DOWNLOAD_URL
  private publicName: any = ['subsidyTemplate1', '直接发放模板']

  private info: any = {
    UsIF: '',
    num: null,
    total: null
  }
  private loading: boolean = false
  private callbackId: string = ''
  private uniqueKey: string = ''

  async created() {
    // await this.init()
    await this.getBankList()
    // if (process.env.NODE_ENV == 'development') {
    //   this.download = '/subsidy/template/download'
    // }
    await this.getDetail()
    await this.getUuid()
  }

  private getUuid() {
    if (sessionStore.get('uniqueKey')) {
      this.uniqueKey = sessionStore.get('uniqueKey')
      return
    }
    var s: any = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'
    var uuid = s.join('')
    sessionStore.set('uniqueKey', uuid)
    this.uniqueKey = uuid
  }

  private getDetail() {
    let type = this.$route.params.type
    if (type != '1') {
      this.publicName = ['subsidyTemplate2', '确认信息发放模板']
      if (type == '3') {
        this.publicName = ['subsidyTemplate2', '采集信息发放模板']
      }
    }
    if (sessionStore.get('importExcel')) {
      let data = sessionStore.get('importExcel')
      this.fileAddr = data.fileAddr
      this.fileName = data.fileName
      this.info = {
        UsIF: data.UsIF,
        num: data.num,
        total: data.total
      }
      this.grantInfo = {
        payBankId: data.payBankId,
        bussinessId: data.bussinessId,
        payRemark: data.payRemark
      }
      sessionStore.remove('importExcel')
    }
  }

  // 银行卡列表
  private getBankList() {
    let id = ''
    if (sessionStore.get('importExcel')) {
      id = sessionStore.get('importExcel').payBankId
    }
    checkBankCardInfo({ type: 2 }).then((res) => {
      this.banks = res.data.data
      if (id) {
        this.handleBank(id)
      }
    })
  }
  // 选择银行
  handleBank(val: any) {
    this.grantInfo.bussinessId = ''
    let data = this.banks.filter((item: any) => item.id == val)
    this.fee = (data[0].bankCardBussinessRels || []).filter((item: any) => item.bussinessType == 1)
  }

  // private init() {
  //   if (this.$route.params.type == '1') {
  //     // this.download =
  //   }
  // }

  private getFileUrl(v: any) {
    if (v) {
      this.fileAddr = v.url
      this.fileName = v.name
      this.readExcel()
    } else {
      this.uploadOption.limit = this.uploadOption.limit + 1
    }
  }

  private fileChange(file: any, fileL: any) {
    this.file = file
    this.fileName = file.name
  }

  private readExcel() {
    let params: any = {
      fileUrl: this.fileAddr
    }
    this.loading = true
    subsidyReadExcel(params)
      .then((res) => {
        let data = res.data.data
        this.info.num = data.length || 0
        this.info.total = 0
        data.map((item: any) => {
          this.info.total += Number(item.amount) * 100
        })
        this.info.total = this.info.total / 100
        this.info.UsIF = sessionStore.get('UsIF')
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }
  private handleSubmit() {
    if (!this.fileName) {
      return this.$message({
        message: '请先上传文件',
        type: 'error'
      })
    }
    if (this.repeatT()) {
      return
    }
    // this.isClick = false
    if (this.$route.params.type != '3') {
      ;(this.$refs.grantInfo as any).validate((v: Boolean) => {
        if (v) {
          // isNeedFace({})
          //   .then((res) => {
          //     if (res.data.data && res.data.data.result) {
          //       this.callbackId = res.data.data.callbackId
          //       this.handleSubsidyimport()
          //     } else {
          //       this.qrDiaShow = true
          //     }
          //   })
          //   .catch(() => {
          this.qrDiaShow = true
          // })
        }
      })
      return
    }
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       this.callbackId = res.data.data.callbackId
    //       this.handleSubsidyimport()
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
    return
    let fd = new FormData()
    fd.append('file', this.file.raw)
    // fd.append('file', this.file)
    subsidyInport(fd).then((res) => {
      if (res.data.code == 1000) {
        this.$message.success('导入成功')
        setTimeout(() => {
          this.handleReturn()
        }, 300)
      }
    })
  }
  private fileSuccess() {
    this.$message.success('导入成功')
    // setTimeout(() => {
    //   this.handleReturn()
    // }, 300)
  }
  private handleReturn() {
    this.$router.go(-1)
  }

  private closeFn() {
    this.fileAddr = ''
    this.fileName = ''
    this.uploadOption.limit++
  }

  private handleSubsidyimport() {
    this.isClick = false
    let params = {
      type: this.$route.params.type,
      fileUrl: this.fileAddr,
      callbackId: this.callbackId,
      uniqueKey: this.uniqueKey
    }
    if (params.type != '3') {
      params = {
        ...params,
        ...this.grantInfo
      }
    }
    this.loading = true
    subsidyImportSumbit(params)
      .then((res) => {
        let msg = '直接发放成功'
        if (params.type == '2') {
          msg = '确认信息发放成功'
        } else if (params.type == '3') {
          msg = '信息采集发放补贴成功'
        }
        sessionStore.set('uniqueKey', '')
        this.$message.success(msg)
        this.$router.go(-2)
      })
      .finally(() => {
        this.isClick = true
        this.loading = false
      })
  }

  private toList() {
    let data = {
      fileAddr: this.fileAddr,
      fileName: this.fileName,
      ...this.info,
      ...this.grantInfo
    }
    sessionStore.set('importExcel', data)
    this.$router.push({
      name: 'SubsidyImportList',
      params: { url: `${this.fileAddr}`, type: this.$route.params.type }
    })
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    this.callbackId = callbackId
    if (v) {
      this.qrDiaShow = false
      this.handleSubsidyimport()
    }
  }
}
