
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getBusinessTypePage, businessTypeDelete } from '@/api/systemConfig'

@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class BusinessTypeConfig extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    properties: '',
    type: ''
  }
  private time: any = ''
  private tableData: any = []
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private loading: boolean = false

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      if (from.name == 'BusinessTypeDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.getList()
    })
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'BusinessTypeDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        time: this.time
      }
      sessionStore.set('pageParams', JSON.stringify(pageParams))
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }

  private getList() {
    this.loading = true
    let params = {
      ...this.formInfo,
      beginTime: this.time[0],
      endTime: this.time[1],
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getBusinessTypePage(params)
      .then((res) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  private resetFn() {
    this.time = ''
    this.formInfo.type = ''
    this.formInfo.properties = ''
  }
  private handleAdd() {
    this.$router.push({
      name: 'BusinessTypeDetail',
      params: {
        id: 'add'
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'BusinessTypeDetail',
      params: {
        id: row.id
      }
    })
  }
  private handleDelete(row: any) {
    this.$confirm('确认删除？').then(() => {
      this.loading = true
      businessTypeDelete({ id: row.id })
        .then((res) => {
          this.$message.success('删除成功')
          this.getList()
        })
        .finally(() => {
          this.loading = false
        })
    })
  }
}
