var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          staticClass: "formBox",
          attrs: {
            model: _vm.formDatas,
            rules: _vm.rules,
            size: "small",
            "label-width": "210px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "itemPart" },
            [
              _c("span", { staticClass: "itemTitle" }, [_vm._v("机构信息")]),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "单位名称：", prop: "name" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled:
                        (_vm.types != 1 || _vm.types != 2) &&
                        (_vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === "")
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "name", $$v)
                      },
                      expression: "formDatas.companyInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "所属行业：", prop: "industry" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedIndustry },
                      model: {
                        value: _vm.formDatas.companyInfo.industry,
                        callback: function($$v) {
                          _vm.$set(_vm.formDatas.companyInfo, "industry", $$v)
                        },
                        expression: "formDatas.companyInfo.industry"
                      }
                    },
                    _vm._l(_vm.industry, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "成立时间：", prop: "establishedTime" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.establishedTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formDatas.companyInfo,
                          "establishedTime",
                          $$v
                        )
                      },
                      expression: "formDatas.companyInfo.establishedTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: {
                    label: "统一社会信用代码：",
                    prop: "unifiedCreditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled:
                        (_vm.types != 1 || _vm.types != 2) &&
                        (_vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === "")
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.unifiedCreditCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formDatas.companyInfo,
                          "unifiedCreditCode",
                          $$v
                        )
                      },
                      expression: "formDatas.companyInfo.unifiedCreditCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "上级管理部门：", prop: "higherAuthorities" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.higherAuthorities,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formDatas.companyInfo,
                          "higherAuthorities",
                          $$v
                        )
                      },
                      expression: "formDatas.companyInfo.higherAuthorities"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: {
                    label: "注册资本（万元）：",
                    prop: "registeredCapital"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.registeredCapital,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formDatas.companyInfo,
                          "registeredCapital",
                          $$v
                        )
                      },
                      expression: "formDatas.companyInfo.registeredCapital"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "实缴资本（万元）：", prop: "realCapital" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.realCapital,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "realCapital", $$v)
                      },
                      expression: "formDatas.companyInfo.realCapital"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "所属区域：", prop: "applyAreaConfigId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      model: {
                        value: _vm.formDatas.companyInfo.applyAreaConfigId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "applyAreaConfigId",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.applyAreaConfigId"
                      }
                    },
                    _vm._l(_vm.options.areaOptions, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.areaConfigName,
                          value: item.areaConfigId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "formItems", attrs: { label: "注册区域：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "省份",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedProvince },
                      model: {
                        value: _vm.formDatas.companyInfo.registRegionProvince,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "registRegionProvince",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.registRegionProvince"
                      }
                    },
                    _vm._l(_vm.options.provinceOption, function(item) {
                      return _c("el-option", {
                        key: item.provinceCode,
                        attrs: {
                          label: item.provinceName,
                          value: item.provinceCode
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "市区",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedCity },
                      model: {
                        value: _vm.formDatas.companyInfo.registRegionCity,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "registRegionCity",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.registRegionCity"
                      }
                    },
                    _vm._l(_vm.options.cityOption, function(item) {
                      return _c("el-option", {
                        key: item.cityCode,
                        attrs: { label: item.cityName, value: item.cityCode }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "区（镇）",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedCounty },
                      model: {
                        value: _vm.formDatas.companyInfo.registRegionArea,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "registRegionArea",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.registRegionArea"
                      }
                    },
                    _vm._l(_vm.options.countyOption, function(item) {
                      return _c("el-option", {
                        key: item.areaCode,
                        attrs: { label: item.areaName, value: item.areaCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册地址：" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "600px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.registRegion,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "registRegion", $$v)
                      },
                      expression: "formDatas.companyInfo.registRegion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "formItems", attrs: { label: "办公区域：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "省份",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedProvinces },
                      model: {
                        value: _vm.formDatas.companyInfo.workRegionProvince,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "workRegionProvince",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.workRegionProvince"
                      }
                    },
                    _vm._l(_vm.options.provinceOptions, function(item) {
                      return _c("el-option", {
                        key: item.provinceCode,
                        attrs: {
                          label: item.provinceName,
                          value: item.provinceCode
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "市（区）",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedCitys },
                      model: {
                        value: _vm.formDatas.companyInfo.workRegionCity,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "workRegionCity",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.workRegionCity"
                      }
                    },
                    _vm._l(_vm.options.cityOptions, function(item) {
                      return _c("el-option", {
                        key: item.cityCode,
                        attrs: { label: item.cityName, value: item.cityCode }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "区、县（镇）",
                        disabled:
                          _vm.isPassStatus === "0" ||
                          _vm.isPassStatus === "1" ||
                          _vm.isPassStatus === "2" ||
                          _vm.isPassStatus === ""
                      },
                      on: { change: _vm.selecttedCountys },
                      model: {
                        value: _vm.formDatas.companyInfo.workRegionArea,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formDatas.companyInfo,
                            "workRegionArea",
                            $$v
                          )
                        },
                        expression: "formDatas.companyInfo.workRegionArea"
                      }
                    },
                    _vm._l(_vm.options.countyOptions, function(item) {
                      return _c("el-option", {
                        key: item.areaCode,
                        attrs: { label: item.areaName, value: item.areaCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "办公地址：" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "600px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.workRegion,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "workRegion", $$v)
                      },
                      expression: "formDatas.companyInfo.workRegion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照(或相关证件)扫描件：" } },
                [
                  _c("upLoadFileImg", {
                    ref: "refa",
                    attrs: {
                      uploadOption: _vm.uploadOption,
                      listShow: _vm.listShow,
                      flag: 1,
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:listShow": function($event) {
                        _vm.listShow = $event
                      },
                      "update:list-show": function($event) {
                        _vm.listShow = $event
                      },
                      fileUrl: _vm.getFileUrlUrl
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "itemPart" },
            [
              _c("span", { staticClass: "itemTitle" }, [
                _vm._v("单位负责人信息（必填）")
              ]),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "负责人姓名：", prop: "managerName" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入负责人姓名",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.managerName,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "managerName", $$v)
                      },
                      expression: "formDatas.companyInfo.managerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "身份证号：", prop: "managerIdentity" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "身份证号",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.managerIdentity,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formDatas.companyInfo,
                          "managerIdentity",
                          $$v
                        )
                      },
                      expression: "formDatas.companyInfo.managerIdentity"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证照片：" } },
                [
                  _c("upLoadFileImg", {
                    ref: "refb",
                    attrs: {
                      uploadOption: _vm.uploadOption,
                      listShow: _vm.listShow,
                      flag: 2,
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:listShow": function($event) {
                        _vm.listShow = $event
                      },
                      "update:list-show": function($event) {
                        _vm.listShow = $event
                      },
                      fileUrl: _vm.getFileUrlUrl
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "formItems", attrs: { label: "负责人授权：" } },
                [
                  _vm.authorize
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAuthorize }
                        },
                        [_vm._v("点击授权")]
                      )
                    : _c(
                        "span",
                        [
                          _c("span", { staticClass: "authed" }, [
                            _vm._v(
                              _vm._s(_vm.formDatas.companyInfo.managerName) +
                                "(已授权)"
                            )
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled:
                                  _vm.isPassStatus === "0" ||
                                  _vm.isPassStatus === "1" ||
                                  _vm.isPassStatus === "2" ||
                                  _vm.isPassStatus === ""
                              },
                              on: { click: _vm.handleAuthorize }
                            },
                            [_vm._v("重新授权")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "itemPart" },
            [
              _c("span", { staticClass: "itemTitle" }, [
                _vm._v("联系人信息（必填）")
              ]),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "联系人姓名：", prop: "contactName" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入姓名",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.contactName,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "contactName", $$v)
                      },
                      expression: "formDatas.companyInfo.contactName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "联系人邮箱：", prop: "contactEmail" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入邮箱",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.contactEmail,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "contactEmail", $$v)
                      },
                      expression: "formDatas.companyInfo.contactEmail"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: { label: "联系人手机号：", prop: "contactPhone" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入手机号",
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    model: {
                      value: _vm.formDatas.companyInfo.contactPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas.companyInfo, "contactPhone", $$v)
                      },
                      expression: "formDatas.companyInfo.contactPhone"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "itemPart" },
            [
              _c("span", { staticClass: "itemTitle" }, [
                _vm._v("资质认证（选填）")
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "办公环境：" } },
                [
                  _c("upLoadFileImg", {
                    ref: "refc",
                    attrs: {
                      uploadOption: _vm.uploadOption,
                      listShow: _vm.listShow,
                      flag: 3,
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:listShow": function($event) {
                        _vm.listShow = $event
                      },
                      "update:list-show": function($event) {
                        _vm.listShow = $event
                      },
                      fileUrl: _vm.getFileUrlUrl
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生产许可证：" } },
                [
                  _c("upLoadFileImg", {
                    ref: "refd",
                    attrs: {
                      uploadOption: _vm.uploadOption,
                      listShow: _vm.listShow,
                      flag: 4,
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:listShow": function($event) {
                        _vm.listShow = $event
                      },
                      "update:list-show": function($event) {
                        _vm.listShow = $event
                      },
                      fileUrl: _vm.getFileUrlUrl
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "进出口许可证：" } },
                [
                  _c("upLoadFileImg", {
                    ref: "refe",
                    attrs: {
                      uploadOption: _vm.uploadOption,
                      listShow: _vm.listShow,
                      flag: 5,
                      disabled:
                        _vm.isPassStatus === "0" ||
                        _vm.isPassStatus === "1" ||
                        _vm.isPassStatus === "2" ||
                        _vm.isPassStatus === ""
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:listShow": function($event) {
                        _vm.listShow = $event
                      },
                      "update:list-show": function($event) {
                        _vm.listShow = $event
                      },
                      fileUrl: _vm.getFileUrlUrl
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司章程/修正案：" } },
                [
                  _vm.reffLinkImg
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href: _vm.reffLinkImg,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [
                          _c("div", { staticClass: "preview" }, [
                            _vm._v("预览")
                          ])
                        ]
                      )
                    : _c("uploadFileBtn", {
                        ref: "reff",
                        attrs: {
                          uploadOption: _vm.uploadOptionBtn,
                          listShow: _vm.listShow,
                          flag: 6,
                          disabled:
                            _vm.isPassStatus === "0" ||
                            _vm.isPassStatus === "1" ||
                            _vm.isPassStatus === "2" ||
                            _vm.isPassStatus === ""
                        },
                        on: {
                          "update:uploadOption": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:upload-option": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:listShow": function($event) {
                            _vm.listShow = $event
                          },
                          "update:list-show": function($event) {
                            _vm.listShow = $event
                          },
                          fileUrl: _vm.getFileUrlUrl
                        }
                      })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "企业资质报告：" } },
                [
                  _vm.refgLinkImg
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href: _vm.refgLinkImg,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [
                          _c("div", { staticClass: "preview" }, [
                            _vm._v("预览")
                          ])
                        ]
                      )
                    : _c("uploadFileBtn", {
                        ref: "refg",
                        attrs: {
                          uploadOption: _vm.uploadOptionBtn,
                          listShow: _vm.listShow,
                          flag: 7,
                          disabled:
                            _vm.isPassStatus === "0" ||
                            _vm.isPassStatus === "1" ||
                            _vm.isPassStatus === "2" ||
                            _vm.isPassStatus === ""
                        },
                        on: {
                          "update:uploadOption": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:upload-option": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:listShow": function($event) {
                            _vm.listShow = $event
                          },
                          "update:list-show": function($event) {
                            _vm.listShow = $event
                          },
                          fileUrl: _vm.getFileUrlUrl
                        }
                      })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审计报告：" } },
                [
                  _vm.refhLinkImg
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href: _vm.refhLinkImg,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [
                          _c("div", { staticClass: "preview" }, [
                            _vm._v("预览")
                          ])
                        ]
                      )
                    : _c("uploadFileBtn", {
                        ref: "refh",
                        attrs: {
                          uploadOption: _vm.uploadOptionBtn,
                          listShow: _vm.listShow,
                          flag: 8,
                          disabled:
                            _vm.isPassStatus === "0" ||
                            _vm.isPassStatus === "1" ||
                            _vm.isPassStatus === "2" ||
                            _vm.isPassStatus === ""
                        },
                        on: {
                          "update:uploadOption": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:upload-option": function($event) {
                            _vm.uploadOptionBtn = $event
                          },
                          "update:listShow": function($event) {
                            _vm.listShow = $event
                          },
                          "update:list-show": function($event) {
                            _vm.listShow = $event
                          },
                          fileUrl: _vm.getFileUrlUrl
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "授权认证",
            visible: _vm.qrDiaShow,
            width: "800px",
            "close-on-click-modal": _vm.isOrModalClose,
            "before-close": _vm.handleCancelDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow, qrdata: _vm.qrData },
            on: {
              notify: _vm.notify,
              qrCodesuccessdata: _vm.qrCodeSuccessdata,
              errorclose: _vm.handleClose
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-button",
            { staticClass: "returnBtn", on: { click: _vm.handleReturn } },
            [_vm._v("返回")]
          ),
          _vm.isPassStatus === "6"
            ? _c(
                "el-button",
                {
                  staticClass: "sumbitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm.isPassStatus === "1" || _vm.isPassStatus === "2"
            ? _c(
                "el-button",
                {
                  staticClass: "sumbitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleChangeIpass }
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.isPassStatus === "5"
            ? _c(
                "el-button",
                {
                  staticClass: "sumbitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUpdateForm }
                },
                [_vm._v("保存并提交")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }