
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getVillageManageList, getProvinceList, getCityList, getCountyList, getTownshipList, VillageManageImport, getVillageTemplate } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class VillageList extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private nowAreaconfigInfo: any = ''
  private nowDownloadUrl: string = ''
  private nowFileName: string = ''
  private action: String = `${process.env.VUE_APP_BASE_URL}subsidy/template/upload`
  private file: any
  private fileName: String = ''
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    managerName: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading: boolean = false
  private btnLoading: boolean = false
  private btnLoading2: boolean = false
  private requestEnd: boolean = true
  private markShow: boolean = false // 上传面板开关
  private downloadFn() {
    this.btnLoading2 = true
    getVillageTemplate()
      .then((res) => {
        if (res.data.data) {
          let urlArr: any = res.data.data.split('/')
          this.nowDownloadUrl = res.data.data
          this.nowFileName = '.' + urlArr[urlArr.length - 1].split('.')[1]
          setTimeout(() => {
            ;(this.$refs.fileDown as any).click()
          })
        } else {
          this.$message.error('没有文件可下载')
        }
      })
      .finally(() => {
        this.btnLoading2 = false
      })
  }
  private fileChange(file: any, fileL: any) {
    this.btnLoading = true
    this.file = file.raw
    const params = new FormData()
    params.append('file', file.raw)
    VillageManageImport(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message.success('导入成功')
          setTimeout(() => {
            this.getList()
          }, 100)
        }
      })
      .finally(() => {
        this.btnLoading = false
      })
  }
  private fileSuccess() {
    this.$message.success('导入成功')
  }
  get isDisBtn() {
    return this.isSuperAdmin != '1' && (!this.formInfo.province || !this.formInfo.city)
  }
  private hasCounty() {
    if (this.isSuperAdmin != '1' && this.nowAreaconfigInfo && this.nowAreaconfigInfo.areaCode) {
      return true
    } else {
      return false
    }
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      vim.nowAreaconfigInfo = sessionStorage.getItem('nowAreaconfigInfo') ? JSON.parse(sessionStorage.getItem('nowAreaconfigInfo') as any) : ''
      if (from.name == 'VillageDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        if (vim.nowAreaconfigInfo) {
          !vim.formInfo.province && (vim.formInfo.province = vim.nowAreaconfigInfo.provinceCode && vim.nowAreaconfigInfo.provinceName ? vim.nowAreaconfigInfo.provinceCode + ',' + vim.nowAreaconfigInfo.provinceName : '')
          !vim.formInfo.city && (vim.formInfo.city = vim.nowAreaconfigInfo.cityCode && vim.nowAreaconfigInfo.cityName ? vim.nowAreaconfigInfo.cityCode + ',' + vim.nowAreaconfigInfo.cityName : '')
          !vim.formInfo.county && (vim.formInfo.county = vim.nowAreaconfigInfo.areaCode && vim.nowAreaconfigInfo.areaName ? vim.nowAreaconfigInfo.areaCode + ',' + vim.nowAreaconfigInfo.areaName : '')
          ;(!vim.formInfo.province || !vim.formInfo.city) && (isGetList = false)
        } else {
          isGetList = false
        }
      }
      if (isGetList && !vim.provinceOptions.length) {
        getProvinceList().then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
        if (vim.isSuperAdmin != '1') {
          getCityList({ provinceCode: vim.formInfo.province.split(',')[0] }).then((res) => {
            if (res.data.data) {
              vim.cityOptions = res.data.data
            }
          })
          getCountyList({ cityCode: vim.formInfo.city.split(',')[0] }).then((res) => {
            if (res.data.data) {
              vim.countyOptions = res.data.data
            }
          })
          vim.formInfo.county &&
            getTownshipList({ areaCode: vim.formInfo.county.split(',')[0] }).then((res) => {
              if (res.data.data) {
                vim.townshipOptions = res.data.data
              }
            })
        }
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'VillageDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    console.log(this.nowAreaconfigInfo)
    Object.keys(this.formInfo).forEach((key) => {
      if (this.isSuperAdmin != '1' && (key == 'province' || key == 'city' || key == 'county')) {
        return
      } else {
        this.formInfo[key] = ''
      }
    })
    if (this.isSuperAdmin == '1') {
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
    } else {
      if (!(this.nowAreaconfigInfo && this.nowAreaconfigInfo.areaCode && this.nowAreaconfigInfo.areaName)) {
        this.formInfo.county = ''
        this.townshipOptions = []
      }
    }
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    getVillageManageList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 搜索人员列表
  private handleSearch() {
    this.getList()
  }
  // 添加人员
  private handleAddPersonnal() {
    this.$router.push({ name: 'VillageDetail', params: { type: 'add' } })
  }
  // 查看人员
  private handleDetails(row: any) {
    sessionStorage.setItem('villageDetail', JSON.stringify(row))
    this.$router.push({
      name: 'VillageDetail',
      params: { type: 'up' }
    })
  }
}
