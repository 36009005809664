
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { organizationalManagementList, organizationalManagementDelete } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class OrganizationalManagementList extends Vue {
  private nowAreaconfigId: any = ''
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'OrganizationalManagementDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.paginationParam = pageParams
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        let nowAreaconfigInfo: any = sessionStorage.getItem('nowAreaconfigInfo')
        if (nowAreaconfigInfo) {
          nowAreaconfigInfo = JSON.parse(nowAreaconfigInfo)
          vim.nowAreaconfigId = sessionStorage.getItem('nowAreaconfigId')
        }
        !vim.nowAreaconfigId && (isGetList = false)
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'OrganizationalManagementDetail') {
      let pageParams: any = this.paginationParam
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    this.loading = true
    const params = {
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    organizationalManagementList(params)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any = null) {
    let params: any = {
      type: row ? 'edit' : 'add'
    }
    row && (params.row = JSON.stringify(row))
    this.$router.push({
      name: 'OrganizationalManagementDetail',
      params
    })
  }
  private deleteFn(id: any) {
    this.$confirm('确认删除？').then(() => {
      this.loading = true
      organizationalManagementDelete({ id })
        .then(() => {
          this.$message.success('删除成功')
          this.getList()
        })
        .catch(() => {
          this.loading = false
        })
    })
  }
}
