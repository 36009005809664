
import { Component, Vue, Prop } from 'vue-property-decorator'
import bgSrc from '@/assets/images/eitd.png'
import { preserveRecordNo } from '@/api/financing'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class ProInfo extends Vue {
  @Prop() info: any
  // 标题
  @Prop({ default: '项目状态' }) title?: string
  // 挂牌摘牌时间展示标志
  @Prop({ default: false }) isShowKey?: boolean
  // 信用信息登记编号展示标志
  @Prop({ default: false }) isShowRegistrationNo?: boolean
  // 信用信息登记编号可否编辑权限
  @Prop({ default: false }) modifyProjectNum?: any
  // 信用信息登记编号id
  @Prop({ default: '' }) eitdId?: any
  // 所属区域展示标志
  @Prop({ default: false }) areaShow?: boolean
  @Prop({ default: '' }) areaName?: any
  private loadingTxt: string = loadingText
  // 是否可编辑
  private isEitd: boolean = false
  // 编辑按钮背景
  private bgImg: any = bgSrc
  // 当前编辑信用信息登记编号
  private projectNum: string = ''
  // 编辑方法
  private eitdFn() {
    if (!this.isEitd) {
      this.projectNum = this.info.projectNum
      this.isEitd = true
    } else {
      let isSuccess = false
      let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
      preserveRecordNo({
        id: this.eitdId,
        projectNum: this.info.projectNum
      })
        .then((res) => {
          if (res.data.code == 1000) {
            isSuccess = true
            this.$message.success('修改成功')
          }
        })
        .finally(() => {
          if (!isSuccess) {
            this.$message.error('修改失败,请稍后再试')
            this.info.projectNum = this.projectNum
          }
          this.isEitd = false
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
}
