import { render, staticRenderFns } from "./appoint-investment.vue?vue&type=template&id=5e8b4757&scoped=true&"
import script from "./appoint-investment.vue?vue&type=script&lang=ts&"
export * from "./appoint-investment.vue?vue&type=script&lang=ts&"
import style0 from "./appoint-investment.vue?vue&type=style&index=0&id=5e8b4757&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8b4757",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e8b4757')) {
      api.createRecord('5e8b4757', component.options)
    } else {
      api.reload('5e8b4757', component.options)
    }
    module.hot.accept("./appoint-investment.vue?vue&type=template&id=5e8b4757&scoped=true&", function () {
      api.rerender('5e8b4757', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project-management/center-list/centers/appoint-investment.vue"
export default component.exports