export function tableFilter(data: any, hasSum: any = null, getVillagesInd: any = null) {
    if (!data.columnList) {
        return
    }
    let splitData: any = {}
    for (let n = 0; n < data.columnList.length; n++) { // 栏目遍历
        let fieldList = data.columnList[n].fieldList, valueList = data.columnList[n].valueList,
            addfieldList: any = [], startInd: any = [], ishasSum = false
        if (getVillagesInd) {
            getVillagesInd.push(false)
        }
        for (let i = 0; i < fieldList.length; i++) {
            if (getVillagesInd && !getVillagesInd[n] && fieldList[i].fieldName == "所属村组(合作社/社区)") {
                getVillagesInd[n] = true
            }
            if (!ishasSum && hasSum && (fieldList[i].valueType == '2' || fieldList[i].valueType == '3')) {
                ishasSum = true
            }
            if (fieldList[i].valueType == '2') { // 拆分键名
                let splitName = fieldList[i].fieldName.split(',')
                startInd.push(i)
                splitName.forEach((item: any, index: any) => {
                    let nowAddfield: any = { ...fieldList[i] }
                    nowAddfield.fieldName = item
                    if (index == splitName.length - 1) {
                        nowAddfield.valueType = 2 + 'sum'
                    }
                    addfieldList.push(nowAddfield)
                });
            }
        }
        if (hasSum) {
            hasSum.push(ishasSum)
        }
        for (let i = 0; i < valueList.length; i++) { // 行遍历
            let valueData = []
            for (let l = 0; l < fieldList.length; l++) { // 列名遍历
                for (let m = 0; m < valueList[i].valueData.length; m++) { // 行内列值遍历
                    if (fieldList[l].fieldId == valueList[i].valueData[m].fieldId) { // 按列名顺序排列列值
                        valueData.push(valueList[i].valueData[m])
                        break
                    } else if (m == valueList[i].valueData.length - 1) {
                        valueData.push({ fieldId: fieldList[l].fieldId, value: '' })
                    }
                }
            }
            for (let l = 0; l < startInd.length; l++) { // 遍历拆分项值
                let nowAddValue = valueData[startInd[l] + l * 2].value ? valueData[startInd[l] + l * 2].value.split(',') : ['', '', ''],
                    addValueList = [
                        {
                            fieldId: valueData[startInd[l]].fieldId,
                            value: nowAddValue[0]
                        },
                        {
                            fieldId: valueData[startInd[l]].fieldId,
                            value: nowAddValue[1]
                        },
                        {
                            fieldId: valueData[startInd[l]].fieldId,
                            value: nowAddValue[2]
                        }
                    ]
                valueData.splice(startInd[l] + l * 2, 1, ...addValueList)
            }
            valueList[i].valueData = valueData
        }
        for (let l = 0; l < startInd.length; l++) { // 遍历拆分项键
            fieldList.splice(startInd[l] + l * 2, 1, ...addfieldList.splice(l + l * 3, 3 * (l + 1)))
        }
        if (startInd.length) {
            splitData[n] = startInd
        }
    }
    return Object.keys(splitData) ? splitData : null
}
