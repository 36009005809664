import { render, staticRenderFns } from "./subsidyAddType.vue?vue&type=template&id=149885bc&scoped=true&"
import script from "./subsidyAddType.vue?vue&type=script&lang=ts&"
export * from "./subsidyAddType.vue?vue&type=script&lang=ts&"
import style0 from "./subsidyAddType.vue?vue&type=style&index=0&id=149885bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149885bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('149885bc')) {
      api.createRecord('149885bc', component.options)
    } else {
      api.reload('149885bc', component.options)
    }
    module.hot.accept("./subsidyAddType.vue?vue&type=template&id=149885bc&scoped=true&", function () {
      api.rerender('149885bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/peasant-subsidy-management/subsidy-list/subsidyAddType.vue"
export default component.exports