
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { projectLimitList, deleteProjectLimit, getBusinessTypeList } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class ProjectLimitManagement extends Vue {
  private loadingTxt: string = loadingText
  private nowAreaInfo: any = {
    isSuperAdmin: '0',
    nowAreaconfigId: ''
  }
  // 业务类型选择参数
  private businessOptions: any = []
  // 搜索的时间对象
  private time: any = ''
  // 分页参数
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 表格数据
  private tableData: any = []
  // 表格加载中标志位
  private loading = false
  // 提交数据
  private formInfo: any = {
    businessType: '',
    name: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'ProjectLimitDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.nowAreaInfo.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.nowAreaInfo.isSuperAdmin != '1') {
        vim.nowAreaInfo.nowAreaconfigId = sessionStorage.getItem('nowAreaconfigId')
      }
      getBusinessTypeList().then((res) => {
        if (res.data.data) {
          vim.businessOptions = res.data.data
        }
      })
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'ProjectLimitDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        businessOptions: this.businessOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  // 查询
  private handleSearch() {
    this.getList()
  }
  // 获取表格数据
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'start', this.time[0])
      this.$set(this.formInfo, 'end', this.time[1])
    } else {
      this.$set(this.formInfo, 'start', '')
      this.$set(this.formInfo, 'end', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    projectLimitList(params).then((res) => {
      if (res.data && res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 跳转查看详情
  private handleDetails(row: any) {
    sessionStorage.setItem('projectLimitDetail', JSON.stringify(row))
    this.$router.push({
      name: 'ProjectLimitDetail',
      params: {
        type: 'search'
      }
    })
  }
  // 跳转添加
  private addPage() {
    this.$router.push({
      name: 'ProjectLimitDetail',
      params: {
        type: 'add'
      }
    })
  }
  // 删除
  private deleteDetails(deleteId: any) {
    this.$confirm('确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        deleteProjectLimit({
          listingApplyId: deleteId
        }).then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message.success('删除成功')
            this.getList()
          }
        })
      })
      .catch(() => {})
  }
}
