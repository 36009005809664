var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "liquidation-basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("业务类型")]),
              _c("span", [_vm._v(_vm._s(_vm.info.businessTypeDesc))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("标的名称")]),
              _c(
                "span",
                { staticClass: "a-span", on: { click: _vm.intoDetails } },
                [_vm._v(_vm._s(_vm.info.bidName))]
              )
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("所属区域")]),
              _c("span", [_vm._v(_vm._s(_vm.info.areaName))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("挂牌方")]),
              _c("span", [_vm._v(_vm._s(_vm.info.listingParty))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("摘牌方")]),
              _c("span", [_vm._v(_vm._s(_vm.info.delistParty))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("社会信用代码/身份证号")]),
              _c("span", [_vm._v(_vm._s(_vm.filterSocialCode))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("挂牌价格（元）")]),
              _c("span", [_vm._v(_vm._s(_vm.info.listingPrice))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("成交价格（元）")]),
              _c("span", [_vm._v(_vm._s(_vm.info.transactionAmount))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("清算状态")]),
              _c("span", [_vm._v(_vm._s(_vm.liquidationStatusDesc))])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }