var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "project-lists-detail" },
    [
      _vm.proDetail && _vm.getProLength && _vm.$route.params.isExport == "1"
        ? _c("div", { ref: "printPdf", staticClass: "print-pdf" }, [
            _c("div", { ref: "print1", staticClass: "print-one" }, [
              _c("div", { staticClass: "print-total-title" }, [
                _vm._v(
                  _vm._s(_vm.proDetail.bidInfo.name.value) + "产权交易相关信息"
                )
              ]),
              _c("div", { staticClass: "print-small-title" }, [
                _vm._v("基本信息")
              ]),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("状态:")]),
                _c("span", [_vm._v(_vm._s(_vm.proDetail.projectStatus.status))])
              ]),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("所属区域:")]),
                _c("span", [_vm._v(_vm._s(_vm.proDetail.areaName))])
              ]),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("挂牌申请时间:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.proDetail.projectStatus.applyTime))
                ])
              ]),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("挂牌截止时间:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.proDetail.projectStatus.endTime))
                ])
              ]),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("摘牌时间:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.proDetail.projectStatus.delistingTime))
                ])
              ]),
              _c("div", { staticClass: "print-small-title" }, [
                _vm._v("标的信息")
              ]),
              _vm.proDetail.businessType == "1" ||
              _vm.proDetail.businessType == "2"
                ? _c("div", { staticClass: "print-item" }, [
                    _c("span", [_vm._v("挂牌申请人:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.bidInfo.applyPerson.value))
                    ])
                  ])
                : _vm._e(),
              _vm.proDetail.businessType == "1" ||
              _vm.proDetail.businessType == "2"
                ? _c("div", { staticClass: "print-item" }, [
                    _c("span", [_vm._v("联系电话:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.bidInfo.contactNumber.value))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "print-item" }, [
                _c("span", [_vm._v("标的名称:")]),
                _c("span", [_vm._v(_vm._s(_vm.proDetail.bidInfo.name.value))])
              ]),
              _vm.proDetail.businessType != "1"
                ? _c("div", { staticClass: "print-item" }, [
                    _c("span", [_vm._v("标的位置:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.bidInfo.location.value))
                    ])
                  ])
                : _vm._e(),
              _vm.proDetail.businessType != "2" &&
              _vm.proDetail.businessType != "1"
                ? _c("div", [
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("标的单位:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.unit.value))
                      ])
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("标的数量:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.number.value))
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.proDetail.businessType == "2"
                ? _c("div", [
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("林地属性:")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.proDetail.bidInfo.forestAttr.value == "1"
                              ? "国有"
                              : _vm.proDetail.bidInfo.forestAttr.value == "2"
                              ? "非国有"
                              : _vm.proDetail.bidInfo.forestAttr.value
                          )
                        )
                      ])
                    ]),
                    _vm.proDetail.bidInfo.holdArea &&
                    _vm.proDetail.bidInfo.holdArea.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s("占地面积(" + _vm.areaUnit + ")") + ":"
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.proDetail.bidInfo.holdArea.value))
                          ])
                        ])
                      : _vm._e(),
                    _vm.proDetail.bidInfo.forestStock &&
                    _vm.proDetail.bidInfo.forestStock.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [_vm._v("林木蓄积(立方米):")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.bidInfo.forestStock.value)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.proDetail.bidInfo.treeType &&
                    _vm.proDetail.bidInfo.treeType.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [_vm._v("树种:")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.proDetail.bidInfo.treeType.value))
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.proDetail.businessType == "1"
                ? _c("div", [
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("土地类型:")]),
                      _vm.proDetail.bidInfo.landType
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.proDetail.bidInfo.landType.value))
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("用途:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.usePurpose.value))
                      ])
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("权属类型:")]),
                      _vm.proDetail.bidInfo.ownershipType
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.bidInfo.ownershipType.value)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm.proDetail.bidInfo.warrantName &&
                    _vm.proDetail.bidInfo.warrantName.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [_vm._v("权证名称:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.bidInfo.warrantName.value)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.proDetail.bidInfo.warrantTerm &&
                    _vm.proDetail.bidInfo.warrantTerm.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [_vm._v("权证年限:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.bidInfo.warrantTerm.value)
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("转出方式:")]),
                      _vm.proDetail.bidInfo.transferMethod
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.bidInfo.transferMethod.value)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("是否属于再次转出:")]),
                      _vm.proDetail.bidInfo.isReTransfer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.bidInfo.isReTransfer.value == "1"
                                  ? "是"
                                  : "否"
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("是否有优先受让方:")]),
                      _vm.proDetail.bidInfo.isPriorTransfer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.bidInfo.isPriorTransfer.value ==
                                  "1"
                                  ? "是"
                                  : "否"
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("所有权人:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.owner.value))
                      ])
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("相关人员是否放弃优先权:")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.proDetail.bidInfo.isGiveUpAdvanceRight.value ==
                              "1"
                              ? "是"
                              : "否"
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.proDetail.businessType == "7"
                              ? "标的使用范围"
                              : "标的位置"
                          ) + ":"
                        )
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.location.value))
                      ])
                    ]),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [_vm._v("承包方式:")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.proDetail.bidInfo.contractingMode.value) +
                            _vm._s(
                              _vm.proDetail.bidInfo.contractingMode.value ==
                                "其他" &&
                                _vm.proDetail.bidInfo.otherContractingMode &&
                                _vm.proDetail.bidInfo.otherContractingMode.value
                                ? " - " +
                                    _vm.proDetail.bidInfo.otherContractingMode
                                      .value
                                : ""
                            )
                        )
                      ])
                    ]),
                    _vm.proDetail.bidInfo.fourSide &&
                    _vm.proDetail.bidInfo.fourSide.value
                      ? _c("div", { staticClass: "print-item" }, [
                          _c("span", [_vm._v("土地四至:")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.proDetail.bidInfo.fourSide.value))
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "print-item" }, [
                      _c("span", [
                        _vm._v(_vm._s("持有面积(" + _vm.areaUnit + ")") + ":")
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.proDetail.bidInfo.holdArea.value))
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { ref: "print1-1", staticClass: "print-item" }, [
              _c("span", [_vm._v("是否存在他项权利人:")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.proDetail.bidInfo.isOtherRightsHolder.value == "1"
                      ? "是"
                      : "否"
                  )
                )
              ])
            ]),
            _vm.proDetail.bidInfo.isOtherRightsHolder.value == "1"
              ? _c("div", { ref: "print1-2", staticClass: "print-item" }, [
                  _c("span", [_vm._v("他项权利人:")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.proDetail.bidInfo.otherRightsHolder.value)
                    )
                  ])
                ])
              : _vm._e(),
            _vm.proDetail.bidInfo.isOtherRightsHolder.value == "1"
              ? _c("div", { ref: "print1-3", staticClass: "print-item" }, [
                  _c("span", [_vm._v("他项权利内容:")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.proDetail.bidInfo.otherRightsContent.value)
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { ref: "print1-4", staticClass: "print-item" }, [
              _c("span", [_vm._v("转让期限:")]),
              _c("span", [
                _vm._v(_vm._s(_vm.proDetail.bidInfo.transferPeriod.value))
              ])
            ]),
            _c("div", { ref: "print1-5", staticClass: "print-item" }, [
              _c("span", [_vm._v("交易方式:")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.tradeType[_vm.proDetail.bidInfo.tradeType.value])
                )
              ])
            ]),
            _c("div", { ref: "print1-6", staticClass: "print-item" }, [
              _vm.proDetail.bidInfo.listingPrice &&
              _vm.proDetail.bidInfo.listingPrice.value
                ? _c("span", [_vm._v("挂牌价格:")])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.proDetail.bidInfo.tradeType.value != "1"
                          ? "标的价格(元):"
                          : "起拍价格(元):"
                      )
                    )
                  ]),
              _vm.proDetail.bidInfo.listingPrice &&
              _vm.proDetail.bidInfo.listingPrice.value
                ? _c("span", [_vm._v(_vm._s(_vm.listingPriceShow))])
                : _c("span", [
                    _vm._v(_vm._s(_vm.proDetail.bidInfo.amount.value))
                  ])
            ]),
            _c("div", { ref: "print1-7", staticClass: "print-item" }, [
              _c("span", [
                _vm._v(
                  "预付款" +
                    _vm._s(
                      _vm.proDetail.bidInfo.advancePaymentAmount &&
                        _vm.proDetail.bidInfo.advancePaymentAmount.value
                        ? "(元):"
                        : ":"
                    )
                )
              ]),
              _vm.proDetail.bidInfo.advancePaymentAmount
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.proDetail.bidInfo.advancePaymentAmount.value
                          ? _vm.proDetail.bidInfo.advancePaymentAmount.value
                          : "否"
                      )
                    )
                  ])
                : _vm._e()
            ]),
            _vm.proDetail.businessType != "1" &&
            _vm.proDetail.businessType != "2" &&
            _vm.proDetail.customFields &&
            _vm.proDetail.customFields.length
              ? _c(
                  "div",
                  _vm._l(_vm.proDetail.customFields, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        ref: "print1-custom" + index,
                        refInFor: true,
                        staticClass: "print-item"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.fieldName) + ":")]),
                        _c("span", [_vm._v(_vm._s(item.fieldValue))])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.proDetail.imgList && _vm.proDetail.imgList.length
              ? _c("div", { ref: "print1-8", staticClass: "print-item" }, [
                  _c("span", [_vm._v("标的图:")])
                ])
              : _vm._e(),
            _vm.proDetail.imgList && _vm.proDetail.imgList.length
              ? _c(
                  "div",
                  _vm._l(_vm.proDetail.imgList, function(item, index) {
                    return _c(
                      "div",
                      { key: "imgList" + index, staticClass: "img-item" },
                      [
                        item.imgUrl
                          ? _c(
                              "div",
                              {
                                ref: "print1-img" + index,
                                refInFor: true,
                                staticClass: "print-img"
                              },
                              [
                                _c("img", {
                                  attrs: { src: _vm.seeFileGet(item.imgUrl) }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { ref: "print2", staticClass: "print-small-title" }, [
              _vm._v("所需资料")
            ]),
            _vm.proDetail.files
              ? _c(
                  "div",
                  _vm._l(_vm.filterFiles(_vm.proDetail.files), function(
                    item,
                    index
                  ) {
                    return _c(
                      "div",
                      {
                        key: "files" + index,
                        ref: "print2-" + index,
                        refInFor: true,
                        staticClass: "print-filename"
                      },
                      [_vm._v(_vm._s(item.fileName))]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.proDetail.otherFileList
              ? _c(
                  "div",
                  _vm._l(_vm.proDetail.otherFileList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "otherFiles" + index,
                        ref: "print2-other" + index,
                        refInFor: true,
                        staticClass: "print-filename"
                      },
                      [_vm._v(_vm._s(item.fileName))]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.proDetail.status &&
            [4, 6, 8].indexOf(_vm.proDetail.status) != -1 &&
            _vm.proDetail.tradeInfo
              ? _c("div", { ref: "print3", staticClass: "print-small-title" }, [
                  _vm._v("交易信息")
                ])
              : _vm._e(),
            _vm.proDetail.status &&
            [4, 6, 8].indexOf(_vm.proDetail.status) != -1 &&
            _vm.proDetail.tradeInfo
              ? _c("div", [
                  _c("div", { ref: "print3-1", staticClass: "print-item" }, [
                    _c("span", [_vm._v("摘牌人性质:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.proDetail.tradeInfo.userType == 1
                            ? "个人"
                            : _vm.proDetail.tradeInfo.userType == 2
                            ? "企业"
                            : _vm.proDetail.tradeInfo.userType == 3
                            ? "组织"
                            : ""
                        )
                      )
                    ])
                  ]),
                  _vm.proDetail.tradeInfo.userType == 1
                    ? _c("div", [
                        _c(
                          "div",
                          { ref: "print3-2", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("姓名:")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.proDetail.tradeInfo.name))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "print3-3", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("身份证号:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.proDetail.tradeInfo.identityCode)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "print3-4", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("联系电话:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.proDetail.tradeInfo.contactPhone)
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.userType == 2
                    ? _c("div", [
                        _c(
                          "div",
                          { ref: "print3-5", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("法定代表人:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.proDetail.tradeInfo.legalPerson)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "print3-6", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("企业名称:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.proDetail.tradeInfo.companyName)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "print3-7", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("注册地址:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.proDetail.tradeInfo.registAddress)
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c("div", { ref: "print3-8", staticClass: "print-item" }, [
                    _c("span", [_vm._v("开户银行:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.tradeInfo.openBank))
                    ])
                  ]),
                  _c("div", { ref: "print3-9", staticClass: "print-item" }, [
                    _c("span", [_vm._v("银行账号:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.tradeInfo.bankCode))
                    ])
                  ]),
                  _vm.proDetail.tranferType == 1 &&
                  [4, 5, 6].indexOf(_vm.proDetail.status) > -1 &&
                  _vm.proDetail.tradeInfo.deduction !== null &&
                  _vm.proDetail.tradeInfo.deduction !== undefined
                    ? _c(
                        "div",
                        { ref: "print3-10", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("保证金抵扣:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.deduction ? "是" : "否"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c("div", { ref: "print3-11", staticClass: "print-item" }, [
                    _c("span", [_vm._v("摘牌时间:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.proDetail.tradeInfo.delistingTime))
                    ])
                  ]),
                  _c("div", { ref: "print3-12", staticClass: "print-item" }, [
                    _c("span", [_vm._v("摘牌方式:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.delistingType[
                            _vm.proDetail.tradeInfo.delistingType
                          ]
                        )
                      )
                    ])
                  ]),
                  _vm.proDetail.tradeInfo.transactionPrice
                    ? _c(
                        "div",
                        { ref: "print3-13", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("成交价格(元):")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.tradeInfo.transactionPrice)
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.delistingType == "2" &&
                  _vm.proDetail.tradeInfo.paymentInfo &&
                  _vm.proDetail.tradeInfo.paymentInfo.amount
                    ? _c(
                        "div",
                        { ref: "print3-14", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("预付金额(元):")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.proDetail.tradeInfo.paymentInfo.amount)
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.paymentInfo &&
                  _vm.proDetail.tradeInfo.paymentInfo.createdTime
                    ? _c(
                        "div",
                        { ref: "print3-15", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("付款时间:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.paymentInfo.createdTime
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.paymentInfo &&
                  _vm.proDetail.tradeInfo.paymentInfo.payMethod
                    ? _c(
                        "div",
                        { ref: "print3-16", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("转账方式:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.paymentInfo.payMethod ==
                                  1
                                  ? "代收代付"
                                  : "自行转账"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.paymentInfo &&
                  _vm.proDetail.tradeInfo.paymentInfo.fileUrl
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.proDetail.tradeInfo.paymentInfo.fileUrl,
                          function(ite, ind) {
                            return _c("div", { key: ind }, [
                              _c(
                                "div",
                                {
                                  ref: "print3-16-img" + ind,
                                  refInFor: true,
                                  staticClass: "print-img"
                                },
                                [
                                  _vm.isImg(ite)
                                    ? _c("img", {
                                        attrs: { src: _vm.seeFileGet(ite) }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.promiseAmountInfo &&
                  _vm.proDetail.tradeInfo.promiseAmountInfo.amount
                    ? _c(
                        "div",
                        { ref: "print3-17", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("保证金(元):")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.promiseAmountInfo.amount
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.promiseAmountInfo &&
                  _vm.proDetail.tradeInfo.promiseAmountInfo.createdTime
                    ? _c(
                        "div",
                        { ref: "print3-18", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("付款时间:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.promiseAmountInfo
                                  .createdTime
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.promiseAmountInfo &&
                  _vm.proDetail.tradeInfo.promiseAmountInfo.payMethod
                    ? _c(
                        "div",
                        { ref: "print3-19", staticClass: "print-item" },
                        [
                          _c("span", [_vm._v("转账方式:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tradeInfo.promiseAmountInfo
                                  .payMethod == 1
                                  ? "代收代付"
                                  : "自行转账"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.promiseAmountInfo &&
                  _vm.proDetail.tradeInfo.promiseAmountInfo.fileUrl
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.proDetail.tradeInfo.promiseAmountInfo.fileUrl,
                          function(ite, ind) {
                            return _c("div", { key: ind }, [
                              _c(
                                "div",
                                {
                                  ref: "print3-19-img" + ind,
                                  refInFor: true,
                                  staticClass: "print-img"
                                },
                                [
                                  _vm.isImg(ite)
                                    ? _c("img", {
                                        attrs: { src: _vm.seeFileGet(ite) }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.finalPaymentInfo &&
                  _vm.proDetail.tradeInfo.finalPaymentInfo.createdTime
                    ? _c("div", [
                        _vm.proDetail.tradeInfo.finalPaymentInfo.amount
                          ? _c(
                              "div",
                              { ref: "print3-20", staticClass: "print-item" },
                              [
                                _c("span", [_vm._v("尾款金额(元):")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.proDetail.tradeInfo.finalPaymentInfo
                                        .amount
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { ref: "print3-21", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("付款时间:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.proDetail.tradeInfo.finalPaymentInfo
                                    .createdTime
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { ref: "print3-22", staticClass: "print-item" },
                          [
                            _c("span", [_vm._v("转账方式:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.proDetail.tradeInfo.finalPaymentInfo
                                    .payMethod == 1
                                    ? "代收代付"
                                    : "自行转账"
                                )
                              )
                            ])
                          ]
                        ),
                        _vm.proDetail.tradeInfo.finalPaymentInfo.fileUrl
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.proDetail.tradeInfo.finalPaymentInfo
                                  .fileUrl,
                                function(ite, ind) {
                                  return _c("div", { key: ind }, [
                                    _c(
                                      "div",
                                      {
                                        ref: "print3-22-img" + ind,
                                        refInFor: true,
                                        staticClass: "print-img"
                                      },
                                      [
                                        _vm.isImg(ite)
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.seeFileGet(ite)
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  (_vm.proDetail.tradeInfo.delistingFiles &&
                    _vm.proDetail.tradeInfo.delistingFiles.length) ||
                  (_vm.proDetail.tradeInfo.contractFiles &&
                    _vm.proDetail.tradeInfo.contractFiles.length)
                    ? _c(
                        "div",
                        { ref: "print3-23", staticClass: "print-item" },
                        [_c("span", [_vm._v("相关资料:")])]
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.delistingFiles &&
                  _vm.proDetail.tradeInfo.delistingFiles.length
                    ? _c(
                        "div",
                        _vm._l(_vm.proDetail.tradeInfo.delistingFiles, function(
                          ite,
                          ind
                        ) {
                          return _c("div", { key: ind }, [
                            _vm.isImg(ite.url)
                              ? _c(
                                  "div",
                                  {
                                    ref: "print3-23-a-img" + ind,
                                    refInFor: true,
                                    staticClass: "print-img"
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.seeFileGet(ite.url) }
                                    })
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    ref: "print3-23-a-" + ind,
                                    refInFor: true,
                                    staticClass: "print-filename"
                                  },
                                  [_vm._v(_vm._s(ite.name))]
                                )
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.proDetail.tradeInfo.contractFiles &&
                  _vm.proDetail.tradeInfo.contractFiles.length
                    ? _c(
                        "div",
                        _vm._l(_vm.proDetail.tradeInfo.contractFiles, function(
                          ite,
                          ind
                        ) {
                          return _c(
                            "div",
                            { key: ind },
                            _vm._l(ite.fileUrl, function(val, k) {
                              return _c("div", { key: k }, [
                                _vm.isImg(val)
                                  ? _c(
                                      "div",
                                      {
                                        ref: "print3-23-b-img" + ind + "-" + k,
                                        refInFor: true,
                                        staticClass: "print-img"
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: _vm.seeFileGet(val) }
                                        })
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        ref: "print3-23-b-" + ind + "-" + k,
                                        refInFor: true,
                                        staticClass: "print-filename"
                                      },
                                      [_vm._v(_vm._s(ite.fileName + (k + 1)))]
                                    )
                              ])
                            }),
                            0
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "1" } },
            [
              _c("pro-info", {
                attrs: {
                  areaShow: true,
                  areaName: _vm.proDetail.areaName,
                  info: _vm.proDetail.projectStatus,
                  title: "基本信息",
                  isShowKey: true
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "标的信息", name: "2" } },
            [
              _c("mark-info", {
                attrs: {
                  projectNum: _vm.proDetail.projectNum,
                  info: _vm.proDetail.bidInfo,
                  imgList: _vm.proDetail.imgList,
                  customFields: _vm.proDetail.customFields,
                  type: _vm.proDetail.businessType,
                  auctionInterval: _vm.proDetail.auctionInfo
                    ? _vm.proDetail.auctionInfo.auctionInterval
                    : "",
                  tranferEnsureAmount: _vm.proDetail.auctionInfo
                    ? _vm.proDetail.auctionInfo.tranferEnsureAmount
                    : ""
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "标的物所属主体信息", name: "3" } },
            [
              _c("subject-info", {
                attrs: { info: _vm.proDetail.listingMainPartInfo }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "所需资料", name: "4" } },
            [
              _c("need-data", {
                attrs: {
                  isUpdateFiles:
                    [3, 4, 5, 6, 8, 10, 11, 12, 13].indexOf(
                      _vm.proDetail.status
                    ) > -1,
                  info: _vm.proDetail.files,
                  otherFileList: _vm.proDetail.otherFileList,
                  listingApplyId: _vm.listingApplyId
                },
                on: { updateFilesFn: _vm.updateFilesFn }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label:
                  _vm.proDetail.tranferType == "1" ? "竞买公告" : "交易公告",
                name: "12"
              }
            },
            [
              _c("project-notice", {
                attrs: {
                  listingApplyId: _vm.listingApplyId,
                  info: _vm.proDetail
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "审批信息", name: "6" } },
            [
              _c("approval-info", {
                attrs: {
                  info: _vm.proDetail,
                  qualificationsShow: false,
                  projectDetailShow: false
                }
              })
            ],
            1
          ),
          _vm.proDetail.businessType == "7" &&
          _vm.proDetail.tranferType == "1" &&
          _vm.proDetail.status &&
          [1, 2, 7, 9].indexOf(_vm.proDetail.status) == -1
            ? _c(
                "el-tab-pane",
                { attrs: { label: "竞买信息", name: "7" } },
                [
                  _c("compete-buy-info", {
                    attrs: {
                      auctionInfo: _vm.proDetail.auctionInfo,
                      isSetPerson: _vm.isSetPerson,
                      isEdit:
                        _vm.proDetail.status == "3" ||
                        _vm.proDetail.status == "10",
                      info: _vm.competeBuyInfo
                    },
                    on: {
                      "update:isSetPerson": function($event) {
                        _vm.isSetPerson = $event
                      },
                      "update:is-set-person": function($event) {
                        _vm.isSetPerson = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.businessType == "7" &&
          _vm.proDetail.tranferType == "1" &&
          _vm.proDetail.status &&
          [5, 6, 11, 12, 13].indexOf(_vm.proDetail.status) > -1
            ? _c(
                "el-tab-pane",
                { attrs: { label: "交易信息", name: "8" } },
                [
                  _c("relation-transaction-info", {
                    attrs: {
                      isDisabled: [12, 13].indexOf(_vm.proDetail.status) > -1,
                      info: _vm.relationTransactionInfo
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.businessType != "7" &&
          _vm.proDetail.auctionInfo &&
          _vm.proDetail.tranferType == "1"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "竞拍信息", name: "9" } },
                [
                  _c("auction-info", {
                    attrs: {
                      info: _vm.proDetail.auctionInfo,
                      transactionAmountShow:
                        !_vm.proDetail.tradeInfo ||
                        !_vm.proDetail.tradeInfo.transactionAmount,
                      transactionPriceShow:
                        !_vm.proDetail.tradeInfo ||
                        !_vm.proDetail.tradeInfo.transactionPrice,
                      isSetPerson: _vm.isSetPerson,
                      listingApplyId: _vm.listingApplyId
                    },
                    on: {
                      "update:isSetPerson": function($event) {
                        _vm.isSetPerson = $event
                      },
                      "update:is-set-person": function($event) {
                        _vm.isSetPerson = $event
                      },
                      setPersonSuccess: _vm.setPersonSuccess
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.proDetail.businessType != "7" ||
            (_vm.proDetail.businessType == "7" &&
              _vm.proDetail.tranferType != "1")) &&
          _vm.proDetail.status &&
          [4, 6, 8].indexOf(_vm.proDetail.status) != -1 &&
          _vm.proDetail.tradeInfo
            ? _c(
                "el-tab-pane",
                { attrs: { label: "交易信息", name: "10" } },
                [
                  _c("transaction-info", {
                    attrs: {
                      listingApplyId: _vm.listingApplyId,
                      isUpdateFiles: true,
                      info: _vm.proDetail.tradeInfo,
                      status: _vm.proDetail.status,
                      tranferType: _vm.proDetail.tranferType
                    },
                    on: {
                      updateFilesFn: _vm.updateFilesFn,
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "tradeInfo", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.proDetail.businessType != "7" ||
            (_vm.proDetail.businessType == "7" &&
              _vm.proDetail.tranferType != "1")) &&
          _vm.proDetail.authenticationBook
            ? _c(
                "el-tab-pane",
                { attrs: { label: "鉴证书", name: "11" } },
                [
                  _c("authentication-book", {
                    attrs: { info: _vm.proDetail.authenticationBook },
                    on: {
                      "update:info": function($event) {
                        return _vm.$set(
                          _vm.proDetail,
                          "authenticationBook",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.$route.params.isExport == "1"
        ? _c(
            "div",
            { staticClass: "import-btn" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.printLoading,
                      expression: "printLoading"
                    }
                  ],
                  staticClass: "exportBtn global-dotted-btn",
                  attrs: {
                    disabled: !_vm.getProLength || _vm.printLoading,
                    size: "small"
                  },
                  on: { click: _vm.printInfo }
                },
                [_vm._v("打印项目信息")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.exportLoading,
                      expression: "exportLoading"
                    }
                  ],
                  staticClass: "exportBtn global-dotted-btn",
                  attrs: {
                    disabled: !_vm.getProLength || _vm.exportLoading,
                    size: "small"
                  },
                  on: { click: _vm.exportInfo }
                },
                [_vm._v("导出项目信息")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.btnShow
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  staticClass: "goback",
                  attrs: { size: "small" },
                  on: { click: _vm.returnPaper }
                },
                [_vm._v("返回")]
              ),
              _vm.proDetail.businessType == "7" &&
              _vm.proDetail.tranferType == "1" &&
              _vm.proDetail.status &&
              [3, 5, 10, 11].indexOf(_vm.proDetail.status) > -1
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.sumbitFn }
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm.isDelisting && _vm.getProLength && _vm.proDetail.status == "3"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.activeDelisting }
                    },
                    [_vm._v("主动摘牌")]
                  )
                : _vm._e(),
              (_vm.proDetail.businessType != "7" ||
                (_vm.proDetail.businessType == "7" &&
                  _vm.proDetail.tranferType != "1")) &&
              _vm.getProLength &&
              _vm.isSetResult &&
              _vm.proDetail.status == "4"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.setResult }
                    },
                    [_vm._v("设置成交结果")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "授权认证", visible: _vm.qrDiaShow },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow },
            on: { notify: _vm.notify }
          })
        ],
        1
      ),
      _vm.markShow
        ? _c("div", { staticClass: "marks" }, [
            _c("div", { staticClass: "mark-main" }, [
              _c("div", { staticClass: "mark-title" }, [
                _vm._v(" 设置成交结果 "),
                _c(
                  "span",
                  { staticClass: "close-btn", on: { click: _vm.closeFn } },
                  [_vm._v("x")]
                )
              ]),
              _c("div", { staticClass: "mark-item" }, [
                _c("div", [_vm._v("成交结果")]),
                _c(
                  "div",
                  { staticClass: "mark-select" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.isSuccess,
                          callback: function($$v) {
                            _vm.isSuccess = $$v
                          },
                          expression: "isSuccess"
                        }
                      },
                      [_vm._v("已成交")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "0" },
                        model: {
                          value: _vm.isSuccess,
                          callback: function($$v) {
                            _vm.isSuccess = $$v
                          },
                          expression: "isSuccess"
                        }
                      },
                      [_vm._v("未成交")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "mark-btn" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "goback",
                      attrs: { size: "small" },
                      on: { click: _vm.closeFn }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm.markShow2
        ? _c("div", { staticClass: "marks" }, [
            _c(
              "div",
              {
                staticClass: "mark-main2",
                style: {
                  width: this.proDetail.tranferType == "1" ? "592px" : "500px"
                }
              },
              [
                _c("div", { staticClass: "mark-title" }, [
                  _vm._v(" 重新竞拍 "),
                  _c(
                    "span",
                    {
                      staticClass: "close-btn",
                      on: {
                        click: function($event) {
                          _vm.markShow2 = false
                        }
                      }
                    },
                    [_vm._v("x")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mark-content",
                    style: { height: _vm.markHeight }
                  },
                  [
                    [1, 2].indexOf(_vm.proDetail.businessType) > -1
                      ? _c("div", { staticClass: "mark-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "must" }, [_vm._v("*")]),
                            _vm._v(_vm._s("面积(" + _vm.areaUnit + ")"))
                          ]),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width-200",
                                attrs: { size: "small", placeholder: "请输入" },
                                model: {
                                  value: _vm.markInfo.holdArea,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "holdArea", $$v)
                                  },
                                  expression: "markInfo.holdArea"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    [1, 2].indexOf(_vm.proDetail.businessType) == -1
                      ? _c("div", { staticClass: "mark-item" }, [
                          _c(
                            "div",
                            { staticStyle: { "word-break": "break-all" } },
                            [
                              _c("span", { staticClass: "must" }, [
                                _vm._v("*")
                              ]),
                              _vm._v(
                                _vm._s(
                                  "标的数量(" +
                                    (_vm.proDetail.bidInfo.unit &&
                                    _vm.proDetail.bidInfo.unit.value
                                      ? _vm.proDetail.bidInfo.unit.value
                                      : "") +
                                    ")"
                                )
                              )
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width-200",
                                attrs: { size: "small", placeholder: "请输入" },
                                model: {
                                  value: _vm.markInfo.number,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "number", $$v)
                                  },
                                  expression: "markInfo.number"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mark-item" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.markInfo.bidType,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "bidType", $$v)
                                },
                                expression: "markInfo.bidType"
                              }
                            },
                            [_vm._v("一口价")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.markInfo.bidType,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "bidType", $$v)
                                },
                                expression: "markInfo.bidType"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  [1, 2].indexOf(_vm.proDetail.businessType) >
                                    -1
                                    ? "每" + _vm.areaUnit + "价格"
                                    : "单价"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mark-item listingPrice-item" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "listingPrice-content" },
                        [
                          _c("el-input", {
                            staticClass: "width-200",
                            attrs: { size: "small", placeholder: "请输入" },
                            model: {
                              value: _vm.markInfo.listingPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "listingPrice", $$v)
                              },
                              expression: "markInfo.listingPrice"
                            }
                          }),
                          [1, 2].indexOf(_vm.proDetail.businessType) > -1
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.markInfo.bidType == 0
                                      ? "元/" +
                                          _vm.markInfo.holdArea +
                                          _vm.areaUnit
                                      : "元/" + _vm.areaUnit
                                  )
                                )
                              ])
                            : [1, 2].indexOf(_vm.proDetail.businessType) ==
                                -1 && _vm.proDetail.bidInfo.unit
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.markInfo.bidType == 0
                                      ? "元/" +
                                          _vm.markInfo.number +
                                          _vm.proDetail.bidInfo.unit.value
                                      : "元/" + _vm.proDetail.bidInfo.unit.value
                                  )
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm.proDetail.tranferType == "1"
                      ? _c("div", { staticClass: "mark-item" }, [
                          _vm._m(2),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width-200",
                                attrs: { size: "small", placeholder: "请输入" },
                                model: {
                                  value: _vm.markInfo.tranferEnsureAmount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.markInfo,
                                      "tranferEnsureAmount",
                                      $$v
                                    )
                                  },
                                  expression: "markInfo.tranferEnsureAmount"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.proDetail.tranferType == "1"
                      ? _c("div", { staticClass: "mark-item" }, [
                          _vm._m(3),
                          _c(
                            "div",
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  size: "small",
                                  "range-separator": "至",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  "default-time": ["12:00:00", "12:00:00"],
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.pickerOptions
                                },
                                model: {
                                  value: _vm.time,
                                  callback: function($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.proDetail.tranferType == "1"
                      ? _c("div", { staticClass: "mark-item" }, [
                          _vm._m(4),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                staticClass: "width-200",
                                attrs: { size: "small", placeholder: "请输入" },
                                model: {
                                  value: _vm.markInfo.auctionInterval,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.markInfo,
                                      "auctionInterval",
                                      $$v
                                    )
                                  },
                                  expression: "markInfo.auctionInterval"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mark-item" }, [
                      _c("div", [
                        _c("span", { staticClass: "must" }, [_vm._v("*")]),
                        _vm._v(
                          _vm._s(
                            _vm.proDetail.tranferType != "1"
                              ? "竞买信息展示"
                              : "竞拍信息展示"
                          )
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.markInfo.isShowBidInfo,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "isShowBidInfo", $$v)
                                },
                                expression: "markInfo.isShowBidInfo"
                              }
                            },
                            [_vm._v("展示")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.markInfo.isShowBidInfo,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "isShowBidInfo", $$v)
                                },
                                expression: "markInfo.isShowBidInfo"
                              }
                            },
                            [_vm._v("不展示")]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.markInfo.isShowBidInfo == "1"
                      ? _c("div", { staticClass: "mark-item" }, [
                          _c("div", [
                            _c("span", { staticClass: "must" }, [_vm._v("*")]),
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.tranferType != "1"
                                  ? "竞买人信息"
                                  : "竞拍人信息"
                              )
                            )
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.markInfo.isShowBidder,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.markInfo,
                                        "isShowBidder",
                                        $$v
                                      )
                                    },
                                    expression: "markInfo.isShowBidder"
                                  }
                                },
                                [_vm._v("直接展示")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.markInfo.isShowBidder,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.markInfo,
                                        "isShowBidder",
                                        $$v
                                      )
                                    },
                                    expression: "markInfo.isShowBidder"
                                  }
                                },
                                [_vm._v("掩码展示")]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mark-item" }, [
                      _vm._m(5),
                      _c(
                        "div",
                        [
                          _c("el-date-picker", {
                            staticClass: "width-200",
                            attrs: {
                              type: "date",
                              size: "small",
                              "value-format": "yyyy-MM-dd 23:59:59",
                              placeholder: "选择日期",
                              "picker-options": _vm.pickerOptions
                            },
                            model: {
                              value: _vm.markInfo.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "endTime", $$v)
                              },
                              expression: "markInfo.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mark-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.confirm }
                      },
                      [_vm._v("确定通过")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("出价方式")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("挂牌价格")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("交易保证金(元)")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("竞拍时间")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("竞价阶梯(元)")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("挂牌截止日期")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }