
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class AboutInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: () => {
      return {}
    }
  })
  checkboxValue?: any
  @Prop({ default: null }) checkboxValueShow?: any
  get bankShow() {
    let show: boolean = false,
      bankPersonnelInfos: any = this.info ? this.info.bankPersonnelInfos : null
    if (bankPersonnelInfos && (bankPersonnelInfos[0].name || bankPersonnelInfos[0].phone || (!this.checkboxValueShow && bankPersonnelInfos[0].settlementRelativeFiles && bankPersonnelInfos[0].settlementRelativeFiles.length))) {
      show = true
    }
    return show
  }
}
