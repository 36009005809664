
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { tableFilter } from '../../peasant-info-management/components/tableFilter'

import {
  subsidyDetail, // 申请人信息
  farmersInfo, // 基本信息
  subsidyGrantDetail, // 补贴信息
  subsidyApply
} from '@/api/countrysidePropertyTransaction'
import { getFarmerInfo } from '@/api/peasantInfoManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {},
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class SubsidyDetail extends Vue {
  private loadingTxt: string = loadingText
  private info: any = {}
  private grantInfo: any = {
    transferStatus: ''
  }
  private subsidyExamineRemarks: string = ''
  private tableData: any = {
    columnList: [
      {
        columnName: '家庭基本信息1',
        fieldList: [{ fieldName: '村组1' }, { fieldName: '村组2' }, { fieldName: '村组3' }],
        valueList: [{ valueData: [{ value: '村组1' }, { value: '村组1' }, { value: '村组1' }] }, { valueData: [{ value: '村组1' }, { value: '村组1' }, { value: '村组1' }] }]
      },
      {
        columnName: '家庭基本信息2',
        fieldList: [{ fieldName: '村组1' }, { fieldName: '村组2' }, { fieldName: '村组3' }],
        valueList: [{ valueData: [{ value: '村组1' }, { value: '村组1' }, { value: '村组1' }] }, { valueData: [{ value: '村组1' }, { value: '村组1' }, { value: '村组1' }] }]
      }
    ]
  }
  private loading = false
  private isClick: boolean = true
  created() {
    // index 1-审批 2-查看
    this.getList()
  }
  private getList() {
    let data = {
      applyId: this.$route.params.id
    }
    subsidyDetail(data).then((res) => {
      this.info = res.data.data
      if (this.info.subsidyExamineStatus == 2) {
        this.subsidyExamineRemarks = this.info.subsidyExamineRemarks
      }
      if (this.$route.params.type == '3') {
        // farmersInfo({ phone: this.info.applyPhoneNumber }).then((res) => {
        //   let splitData = tableFilter(res.data.data)
        //   this.tableData = res.data.data
        // })
        this.loading = true
        getFarmerInfo({ farmersInfoId: this.info.farmersInfoId })
          .then((res) => {
            if (res.data.code == 1000) {
              let splitData = tableFilter(res.data.data)
              this.tableData = res.data.data
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    })
    // 补贴,发放中，已发放信息
    // let params = new URLSearchParams()
    // params.append('applyId', this.$route.params.id)
    let params = {
      applyId: this.$route.params.id
    }
    if (this.$route.params.index == '5') {
      subsidyGrantDetail(params).then((res) => {
        this.grantInfo = res.data.data
      })
    }
  }
  private getStatus(val: any, payStatus: any) {
    switch (val) {
      case 0:
        return '待申请'
        break
      case 1:
        return '已通过'
        break
      case 2:
        return '不通过'
        break
      case 3:
        return '待提交审核'
        break
      case 4:
        return '待审核'
        break
      case 5:
        if (payStatus == 0) {
          return '发放中（排队中）'
        } else if (payStatus == 1) {
          return '发放成功'
        } else if (payStatus == 2) {
          return '发放失败'
        } else if (payStatus == 3) {
          return '发放中（处理中）'
        }
        return '未发放'
        break
      case 6:
        return '未提交'
        break
      default:
        return '待申请'
        break
    }
  }
  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }
  private handleRefuse(index: number) {
    this.$confirm('确认拒绝该审批？').then(() => {
      this.handleSubmit(index)
    })
  }
  private handleSubmit(index: number) {
    if (this.repeatT()) {
      return
    }
    let params: any = {
      applyId: this.$route.params.id,
      subsidyExamineStatus: index,
      subsidyExamineRemarks: this.subsidyExamineRemarks
    }
    if (index == 2 && !params.subsidyExamineRemarks) {
      return this.$message.error('请先输入拒绝原因')
    }
    if (!params.subsidyExamineRemarks) {
      delete params.subsidyExamineRemarks
    }
    this.isClick = false
    subsidyApply(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message.success('审批成功')
          setTimeout(() => {
            this.handleReturn()
          }, 300)
        }
      })
      .finally(() => {
        this.isClick = true
      })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
}
