var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("项目立项申请信息")]),
        _vm.openShow && _vm.info && _vm.info.publicInfo
          ? _c(
              "div",
              { staticClass: "open-box" },
              [
                _c("span", [_vm._v("是否公开")]),
                _c("el-switch", {
                  on: { change: _vm.openChange },
                  model: {
                    value: _vm.info.publicInfo.status,
                    callback: function($$v) {
                      _vm.$set(_vm.info.publicInfo, "status", $$v)
                    },
                    expression: "info.publicInfo.status"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.redTitle && (_vm.detailtype == 1 || _vm.detailtype == 5)
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("业务类型")]),
                _vm.checkboxShow && _vm.itemShow.listingMode
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.listingMode,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "listingMode", $$v)
                        },
                        expression: "itemStatus.listingMode"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.listingMode
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.info.listingMode == 1
                          ? "非公开发行计划（场外）"
                          : _vm.info.listingMode == 2
                          ? "非公开发行计划（场内）"
                          : _vm.info.listingMode == 3
                          ? "收益权（场外）"
                          : _vm.info.listingMode == 4
                          ? "收益权（场内）"
                          : _vm.info.listingMode == 5
                          ? "通道类（场内）"
                          : ""
                      )
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("推荐人代码")]),
                _vm.checkboxShow && _vm.itemShow.recommendCode
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.recommendCode,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "recommendCode", $$v)
                        },
                        expression: "itemStatus.recommendCode"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.recommendCode
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.recommendCode))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("立项人姓名")]),
                _vm.checkboxShow && _vm.itemShow.userName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.userName,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "userName", $$v)
                        },
                        expression: "itemStatus.userName"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.userName
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.userName))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系电话")]),
                _vm.checkboxShow && _vm.itemShow.demandTel
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.demandTel,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "demandTel", $$v)
                        },
                        expression: "itemStatus.demandTel"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.demandTel
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.demandTel))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("项目名称")]),
                _vm.checkboxShow && _vm.itemShow.demandName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.demandName,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "demandName", $$v)
                        },
                        expression: "itemStatus.demandName"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.demandName
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.demandName))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("项目简称")]),
              _c("span", [_vm._v(_vm._s(_vm.info.demandShortName))])
            ]),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("挂牌等级规模 (万)")]),
                _vm.checkboxShow && _vm.itemShow.expectedAmount
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.expectedAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "expectedAmount", $$v)
                        },
                        expression: "itemStatus.expectedAmount"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.expectedAmount
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.expectedAmount))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("产品期限")]),
                _vm.checkboxShow && _vm.itemShow.productDeadline
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.productDeadline,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "productDeadline", $$v)
                        },
                        expression: "itemStatus.productDeadline"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.productDeadline
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.productDeadline))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("预期收益率 (年化)")]),
                _vm.checkboxShow && _vm.itemShow.expectedRate
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.expectedRate,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "expectedRate", $$v)
                        },
                        expression: "itemStatus.expectedRate"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.expectedRate
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.info.expectedRate +
                          (_vm.info.expectedRate ? "%" : "")
                      )
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("挂牌服务费率 (%年)")]),
                _vm.checkboxShow && _vm.itemShow.providerRate
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.providerRate,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "providerRate", $$v)
                        },
                        expression: "itemStatus.providerRate"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.providerRate
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.info.providerRate +
                          (_vm.info.providerRate == null ||
                          _vm.info.providerRate == undefined
                            ? ""
                            : "%")
                      )
                    )
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("支付方式")]),
              _vm.info.providerRateRules == 0
                ? _c("span", [_vm._v("前置支付")])
                : _vm.info.providerRateRules == 1
                ? _c("span", [_vm._v("后置支付")])
                : _vm._e()
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("经纪服务商")]),
              _c(
                "span",
                { staticClass: "flex-box" },
                [
                  _vm._v(" " + _vm._s(_vm.info.brokerProvider) + " "),
                  _vm.providerPontract.broker_provider_contract
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href: _vm.providerPontract.broker_provider_contract,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [_vm._v("文件")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("咨询服务商")]),
              _c(
                "span",
                { staticClass: "flex-box" },
                [
                  _vm._v(" " + _vm._s(_vm.info.consultingProvider) + " "),
                  _vm.providerPontract.consulting_provider_contract
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href:
                              _vm.providerPontract.consulting_provider_contract,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [_vm._v("文件")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("渠道服务商")]),
              _c(
                "span",
                { staticClass: "flex-box" },
                [
                  _vm._v(" " + _vm._s(_vm.info.channelProvider) + " "),
                  _vm.providerPontract.channel_provider_contract
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href:
                              _vm.providerPontract.channel_provider_contract,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [_vm._v("文件")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }