
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class proProductRelated extends Vue {
  @Prop() info: any
  @Prop() detailtype: any
  @Prop({ default: 'false' }) isSecond: any
  @Prop({ default: false }) refuse?: boolean
  @Prop({ default: true }) isShowRed?: boolean
  @Prop({ default: false }) openShow?: boolean
  private checkboxShow: boolean = false
  private isFirst: boolean = true
  private redTitle: boolean = true
  private itemStatus: any = {
    purpose: false,
    addCreditMeasures: false,
    minScale: false,
    repaymentWay: false,
    paymentMethod: false,
    firstRepayTime: false,
    shortcutElements: false,
    investmentPrice: false
  }
  private itemShow: any = {
    purpose: true,
    addCreditMeasures: true,
    minScale: true,
    repaymentWay: true,
    paymentMethod: true,
    firstRepayTime: true,
    shortcutElements: true,
    investmentPrice: true
  }
  @Watch('info', { deep: true })
  infoWatch(newVal: any) {
    if (this.isFirst && newVal) {
      this.isFirst = false
      let nameShow = false
      if ((this.detailtype == 1 && this.isSecond == 'true') || this.detailtype == 0) {
        let fieldList = this.info.fieldList
        for (let i = 0; i < fieldList.length; i++) {
          if (fieldList[i].status) {
            this.$set(this.itemShow, fieldList[i].fieldName, false)
          } else {
            nameShow = true
          }
        }
        if (this.detailtype != 0 && !nameShow) {
          this.redTitle = false
        }
      }
    }
  }
  @Watch('refuse')
  handler(newValue: any) {
    if (newValue) {
      this.$emit('addRefuseItem', 'productReleative', this.itemStatus)
    }
  }
  created() {
    if (this.detailtype == 1 || this.detailtype == 5) {
      this.checkboxShow = true
    }
  }
  private openChange() {
    this.$emit('openChange', '产品相关', this.info.publicInfo)
  }
}
