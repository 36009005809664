
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { searchArea } from '@/api/noticeManagement'
import { Loading as Loadings } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
// 接口联调
import {
  rolePersonnelList,
  // getAllRole,
  checkRolePersonnel,
  deleteRolePersonnel
} from '@/api/personnel'
import numsTips from '@/components/numsTips/index.vue'

@Component({
  components: {
    numsTips
    // Pagination
  },
  filters: {
    formatStatus: (istatus: number) => {
      if (istatus === 0) {
        return '正常'
      } else {
        return '禁用'
      }
    }
  }
})
export default class PersonnelList extends Vue {
  private loadingTxt: string = loadingText
  // private roleOption: string = ''
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  private isSuperAdmin: any = '0'
  private statusOption: string = ''
  private disabled: boolean = false
  private areaOptions: any = []
  // private roleOptions: any = []
  private statusOptions: any = [
    { label: '正常', value: 0 },
    { label: '禁用', value: -1 }
  ]
  private totalCount: number = 0
  private formInfo: any = {
    pageNumber: 1,
    pageSize: 10,
    name: '',
    status: '',
    areaId: ''
  }

  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'RoleManagementDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.totalCount = pageParams.totalCount
        if (pageParams.areaOptions.length) {
          vim.areaOptions = pageParams.areaOptions
        }
      }
      vim.systemType != 'zct' && (vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin'))
      if (vim.systemType != 'zct' && vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (vim.systemType != 'zct' && isGetList && !vim.areaOptions.length) {
        searchArea({}).then((res) => {
          if (res.data.data && res.data.data.aeras) {
            vim.areaOptions = vim.filteAreaOptions(res.data.data.aeras)
          }
        })
      }
      isGetList && vim.handleRoleList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'RoleManagementDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        totalCount: this.totalCount,
        areaOptions: this.areaOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  // ---------------------------------------------------自定义函数--------------------------   start---------------------------------------
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'pageNumber' && key != 'pageSize' && (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1'))) {
        this.formInfo[key] = ''
      }
    })
  }
  private handleSearch() {
    this.handleRoleList()
  }

  private handleAddPersonnal() {
    this.$router.push({ name: 'RoleManagementDetail', params: { flag: '0' } })
  }
  // filterData(datas?: any) {
  //   let menuData: any = datas.menus
  //   let newMenuData: any = JSON.parse(
  //     JSON.stringify(menuData)
  //       .replace(/name/g, 'label')
  //       .replace(/menuId/g, 'id')
  //       .replace(/subMenu/g, 'children')
  //   )
  //   return newMenuData
  // }
  // 获取所有角色
  // handleAllRole() {
  //   getAllRole()
  //     .then((res) => {
  //       let datas = res.data
  //       this.roleOptions = datas.data
  //     })
  // }
  // 获取角色列表数据
  handleRoleList() {
    this.loading = true
    let datas: any = this.formInfo
    rolePersonnelList(datas).then((res) => {
      let datas = res.data
      this.tableData = datas.data
      this.totalCount = datas.total
      this.loading = false
    })
  }
  // 分页--改变每页条数触发 -----
  handleSizeChange(pageSize: number) {
    this.formInfo.pageSize = pageSize
    this.handleRoleList()
  }
  // 分页--改变页码数触发 -----
  handleCurrentChange(currentPage: number) {
    this.formInfo.pageNumber = currentPage
    this.handleRoleList()
  }

  // toAddPage() {
  // this.$router.push({ name: 'AddProjectNotice' })
  // }
  // selecttedRole(value: string) {
  // this.roleOption = value
  // }

  selecttedStatus(value: string) {
    this.statusOption = value
  }

  handleSwitch() {}
  // 查看角色
  handleDetails(row: any) {
    let loadingInstance = Loadings.service({ target: document.body, text: this.loadingTxt })
    let id: number = row.id
    checkRolePersonnel(id)
      .then((res) => {
        let datas: any = res.data.data
        datas.areaId = row.areaId ? row.areaId : ''
        const disabled: boolean = true
        this.$router.push({
          name: 'RoleManagementDetail',
          params: { dataDetail: datas, disabled: 'true', flag: '1' }
        })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 删除角色
  handleDelete(row: any) {
    this.$confirm('确定删除该角色？', '温馨提示')
      .then(() => {
        this.loading = true
        deleteRolePersonnel(row.id)
          .then((res) => {
            // let datas: any = res.data.data
            this.handleRoleList()
            // this.$router.push(name:'RoleManagement',params)
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
  }
}
