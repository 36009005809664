
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getBusinessTypeList,offlineProjectUpdate } from '@/api/countrysidePropertyTransaction'
import timeFormat from '@/utils/timeFormat'
import { loadingText } from '@/utils/defaultGoalConfig'
import { Loading } from 'element-ui'
@Component({
  components: {}
})
export default class OfflineBasicInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: false
  })
  openStatus?: boolean
  private loadingTxt: string = loadingText
  private businessOptions: any = []
  private ruleTypeOptions: any = [
    { label: '竞拍', value: '1' },
    { label: '非竞拍', value: '2' },
    { label: '预售', value: '3' }
  ]
  btnProject: boolean = false
  // 返回页面
  private returnPaper() {
    this.$router.push({ name: 'ProjectOffline' })
  }
  private editProject() {
    this.btnProject = true
    getBusinessTypeList().then((res) => {
      if (res.data.code == 1000) {
        this.businessOptions = res.data.data
      }
    })
  }
  private updateProject() {
    if (this.info.listingName == null || this.info.listingName == '') {
      this.$message.error('标的名称不能为空')
      return
    }
    if (this.info.listingType == null || this.info.listingType == '') {
      this.$message.error('标的类型不能为空')
      return
    }
    if (this.info.tranferType == null || this.info.tranferType == '') {
      this.$message.error('交易方式不能为空')
      return
    }
    if (this.info.amount == null || this.info.amount == '') {
      this.$message.error('成交总金额不能为空')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    this.btnProject = false
    offlineProjectUpdate(this.info).then((res) => {
      if (res.data.code == 1000) {
        this.$message.success('修改成功')
        this.$emit('updateSuccess')
      }
    }) .finally(() => {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
    })
  }
  private termOfTransferDate() {
    this.info.termOfTransfer = timeFormat(this.info.termOfTransfer,'YYYY-MM-DD')
  }
}
