var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "application-materials" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("申请材料")]),
        _vm.checkboxValueShow
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.info, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "item" },
                [
                  _c("span", [_vm._v(_vm._s(item.fieldName))]),
                  _vm.checkboxValueShow && _vm.checkboxValueShow[index]
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.checkboxValue[index],
                          callback: function($$v) {
                            _vm.$set(_vm.checkboxValue, index, $$v)
                          },
                          expression: "checkboxValue[index]"
                        }
                      })
                    : _vm._e(),
                  item.fileAddrs
                    ? _c(
                        "div",
                        { staticClass: "margin-negative" },
                        _vm._l(item.fileAddrs, function(ite, ind) {
                          return _c(
                            "div",
                            { key: ind, class: { "top-padding": !!ite } },
                            [
                              ite && ite != "NOFILE"
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: ite,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.fileName +
                                            (item.fileName
                                              ? _vm.getFileSuffix(
                                                  ite,
                                                  item.fileAddrs.length,
                                                  ind
                                                )
                                              : "")
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              ite == "NOFILE"
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }