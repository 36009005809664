
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import md5 from 'js-md5'
import StringUtils from '@/utils/strRandom'
import { localStore } from '@/utils/data-management'
import jsonbigStringParse from '@/utils/jsonbigStringParse'
import * as secret from '@/utils/secret'
interface FileInfo {
  [key: string]: string | object | number | bigint
}

@Component({
  components: {}
})
export default class UploadBase extends Vue {
  @Prop() private uploadOption!: any // 接收父组件传过来的数据
  @Prop({ default: false }) private disabled?: boolean
  @Prop({ default: false }) private loadingFlag?: boolean
  @Prop({ default: true }) private needTitp?: boolean
  // { text: string,  placement: '方向参考elementui'}
  @Prop({ default: null }) private tooltipObj?: any
  private uploadFileUrl = '' // 上传文件地址
  private accept: any = '' // 上传文件类型
  private uploadUrl: any = ''
  private btnText: any = '上传文件'
  private myHeaders: any = ''
  created() {
    const zctToken = localStore.get('zctToken')
    const timestamp = new Date().getTime() + ''
    const nonce = StringUtils.randomStr(32)
    const ZCT_SECRET = '21fa6sd1f95w1133edsafas6'
    const encrptString = `${timestamp}.${ZCT_SECRET}.${nonce}`
    const sign = md5(encrptString)
    this.myHeaders = {
      sign,
      timestamp,
      nonce,
      zctToken: zctToken
    }
  }
  @Watch('uploadOption', { deep: true, immediate: true })
  private watchUploadOption(newValue: any) {
    if (newValue.accept) this.uploadFileUrl = newValue.uploadFileUrl
    if (newValue.accept) this.accept = newValue.accept
    if (newValue.btnText) this.btnText = newValue.btnText
  }
  // 上传文件成功
  private handleUploadSuccess(res: any, file: FileInfo, fileList: []) {
    let fileInfo: any = file.raw
    if (res && Object.prototype.toString.call(res) === '[object String]') {
      res = jsonbigStringParse(secret.default.decrypt(res))
    }
    if (res.code == 1000) {
      this.uploadUrl = res.data
      this.$emit('fileUrl', {
        url: this.uploadUrl,
        name: fileInfo.name,
        type: fileInfo.type
      })
      this.$emit('uploadSuccess')
      this.needTitp && this.$message.success('上传成功！')
    } else {
      this.$emit('uploadError')
      this.$message.error(res.message)
    }
  }
  private handleUploadError(err: Error, file: FileInfo, fileList: FileInfo[]) {
    this.$emit('uploadError')
    if (err.message && Object.prototype.toString.call(err.message) === '[object String]') {
      err.message = jsonbigStringParse(secret.default.decrypt(err.message))
    }
    let returnErrAny = (err.message as any).body
    this.$message.error(returnErrAny ? (returnErrAny.message ? returnErrAny.message : '上传失败') : '上传失败')
  }
  // 上传之前的校验
  private beforeUpload(file: FileInfo) {
    if (this.accept) {
      let accept = JSON.parse(JSON.stringify(this.accept))
      accept = [...accept.split(',')]
      let str: any = file.name
      let fileNameAll = str.split('.')
      fileNameAll = '.' + fileNameAll[fileNameAll.length - 1]
      if (accept.indexOf(fileNameAll) == -1) {
        this.$message.error('上传文件格式有误!')
        return false
      }
    }
    this.$emit('beforeUpFn')
  }
}
