
import { Component, Vue } from 'vue-property-decorator'
import { getVillageProvinceList, getVillageCityList, getVillageCountyList, getVillageTownshipList } from '@/api/peasantInfoManagement'
import { getAssessmentTeamDetails, addAssessmentTeam, updateAssessmentTeam } from '@/api/regionalCreditManagement'
import { allApprovalPerson } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
import { Loading } from 'element-ui'
@Component({})
export default class AssessmentTeamDetails extends Vue {
  private loadingTxt: string = loadingText
  private mainLoading: boolean = false
  private formInfo: any = {
    assessmentGroupId: '',
    province: '',
    city: '',
    county: '',
    township: '',
    groupName: '',
    levels: [],
    personIds: []
  }
  private requestEnd: boolean = true // 区域请求结束标志
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private flag: string = '' // 人员编辑类型标志
  private search: string = '' // 人员搜索字段
  private dialogTableVisible: boolean = false // 人员选择弹窗显示标志
  private isSelectData: any = {} // 选择数据
  private personList: any = [] // 选择的人员列表
  private gridData: any = []
  private copyData: any = []
  private inputValue: any = []
  private isGetPerson: boolean = false
  private isPersonRequestEnd: boolean = false
  async created() {
    this.mainLoading = true
    this.requestEnd = false
    allApprovalPerson()
      .then((res) => {
        if (res.data.data) {
          this.gridData = res.data.data
          this.copyData = res.data.data
          res.data.data.length && (this.isGetPerson = true)
        } else {
          this.isGetPerson = false
        }
      })
      .catch(() => {
        this.isGetPerson = false
      })
      .finally(() => {
        this.isPersonRequestEnd = true
      })
    if (this.$route.params.pageType == 'search') {
      let routeParams = this.$route.params
      if (routeParams.assessmentGroupId) {
        this.formInfo.assessmentGroupId = routeParams.assessmentGroupId
        sessionStorage.setItem('assessmentTeamDetails', this.formInfo.assessmentGroupId)
      } else {
        this.formInfo.assessmentGroupId = sessionStorage.getItem('assessmentTeamDetails')
      }
      let detailSuss: boolean = false,
        provinceCode: string = ''
      await getAssessmentTeamDetails({ assessmentGroupId: this.formInfo.assessmentGroupId }).then((res) => {
        let getData: any = res.data.data
        if (getData) {
          Object.keys(this.formInfo).forEach((key) => {
            if (['levels', 'personIds'].indexOf(key) > -1) {
              this.formInfo[key] = getData[key] ? getData[key] : []
            } else {
              this.formInfo[key] = getData[key] ? getData[key] : ''
            }
          })
          this.personList = getData.groupPersons ? getData.groupPersons : []
          detailSuss = true
        }
      })
      await getVillageProvinceList({}).then((res) => {
        if (res.data.data) {
          this.provinceOptions = res.data.data
          if (detailSuss) {
            provinceCode = this.formInfo.province
          }
        }
      })
      if (provinceCode) {
        let cityCode: string = ''
        await getVillageCityList({ province: provinceCode }).then((res) => {
          if (res.data.data) {
            this.cityOptions = res.data.data
            if (this.formInfo.city) {
              cityCode = this.formInfo.city
            }
          }
        })
        if (cityCode) {
          let countyCode: string = ''
          await getVillageCountyList({ province: this.formInfo.province, city: cityCode }).then((res) => {
            if (res.data.data) {
              this.countyOptions = res.data.data
              if (this.formInfo.county) {
                countyCode = this.formInfo.county
              }
            }
          })
          if (countyCode) {
            getVillageTownshipList({
              province: this.formInfo.province,
              city: this.formInfo.city,
              county: countyCode
            })
              .then((res) => {
                if (res.data.data) {
                  this.townshipOptions = res.data.data
                }
              })
              .finally(() => {
                this.mainLoading = false
              })
          } else {
            this.mainLoading = false
          }
        } else {
          this.mainLoading = false
        }
      } else {
        this.mainLoading = false
      }
    } else {
      getVillageProvinceList({})
        .then((res) => {
          if (res.data.code == 1000) {
            this.provinceOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
          this.mainLoading = false
        })
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('assessmentTeamDetails')
    next()
  }
  // 获取当前对应的code
  // private getNowArea(keyName: string, nowKey: string, forArr: any) {
  //   let returnVal: string = ''
  //   for (let i = 0; i < forArr.length; i++) {
  //     if (forArr[i][keyName + 'Name'] == this.formInfo[nowKey]) {
  //       this.formInfo[nowKey] = forArr[i][keyName + 'Code'] + ',' + this.formInfo[nowKey]
  //       returnVal = forArr[i][keyName + 'Code']
  //       break
  //     }
  //   }
  //   return returnVal
  // }
  get townshipDisabled() {
    let returnVal: boolean = true
    if (this.$route.params.pageType != 'search' && this.formInfo.levels.indexOf(3) == -1) {
      returnVal = false
    }
    return returnVal
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCityList({ province: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCountyList({ province: this.formInfo.province, city: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getVillageTownshipList({
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: value
      })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 每个已添加人员tab的点击事件
  private handleTab(tag?: any, index?: any) {
    this.isSelectData = { tag, index }
    this.$confirm('', '请选择操作', {
      confirmButtonText: '重选',
      cancelButtonText: '删除',
      center: true,
      distinguishCancelAndClose: true
    })
      .then(() => {
        if (this.isPersonRequestEnd && !this.isGetPerson) {
          this.isPersonRequestEnd = false
          allApprovalPerson()
            .then((res) => {
              if (res.data.data) {
                this.gridData = res.data.data
                this.copyData = res.data.data
                res.data.data.length && (this.isGetPerson = true)
              } else {
                this.isGetPerson = false
              }
            })
            .catch(() => {
              this.isGetPerson = false
            })
            .finally(() => {
              this.isPersonRequestEnd = true
            })
        }
        this.flag = '1'
        this.dialogTableVisible = true
      })
      .catch((res) => {
        if (res === 'cancel') {
          this.personList.splice(this.isSelectData.index, 1)
          this.$message({
            type: 'info',
            message: '已删除'
          })
        }
      })
  }
  // 新增审批人
  private addApprovalPerson() {
    if (this.isPersonRequestEnd && !this.isGetPerson) {
      this.isPersonRequestEnd = false
      allApprovalPerson()
        .then((res) => {
          if (res.data.data) {
            this.gridData = res.data.data
            this.copyData = res.data.data
            res.data.data.length && (this.isGetPerson = true)
          } else {
            this.isGetPerson = false
          }
        })
        .catch(() => {
          this.isGetPerson = false
        })
        .finally(() => {
          this.isPersonRequestEnd = true
        })
    }
    this.flag = '0'
    this.dialogTableVisible = true
  }
  // 人员搜索
  private handleSearch() {
    let gridData = this.copyData
    this.gridData = gridData.filter((data: any) => (this.search ? (data.name ? data.name.includes(this.search) : false) : this.copyData))
  }
  // 弹框选中人员获取人员信息
  private handleIsSelect(val: any) {
    let select: any = val
    this.inputValue = select
  }
  // 确定获取选中人员信息加到现有列详情数据
  private handleConfirm() {
    let isSelectData: any = this.isSelectData
    if (!this.inputValue.personId) {
      this.dialogTableVisible = false
      return
    }
    if (this.flag === '1') {
      this.personList.splice(isSelectData.index, 1, {
        ...this.inputValue,
        sort: isSelectData.tag.sort
      })
      this.dialogTableVisible = false
    } else {
      this.personList.push({
        sort: null,
        name: this.inputValue.name,
        personId: this.inputValue.personId
      })
      this.dialogTableVisible = false
    }
  }
  // 提交
  private sumbitFn(type: string) {
    let params: any = {
      ...this.formInfo
    }
    if (!params.groupName.trim()) {
      this.$message.error('评定小组名称不能为空!!')
      return
    } else if (!params.province) {
      this.$message.error('请选择评定小组管辖区域!!')
      return
    } else if (!this.personList.length) {
      this.$message.error('请设置小组人员!!')
      return
    } else if (!params.levels.length) {
      this.$message.error('请选择评定层级!!')
      return
    }
    params.personIds = []
    for (let i = 0; i < this.personList.length; i++) {
      params.personIds.push(this.personList[i].personId)
    }
    Object.keys(params).forEach((key) => {
      !params[key] && (params[key] = null)
    })
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (type == 'search') {
      updateAssessmentTeam(params)
        .then(() => {
          this.$message.success('更新成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      delete params.assessmentGroupId
      addAssessmentTeam(params)
        .then(() => {
          this.$message.success('添加成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
}
