
import { Component, Vue } from 'vue-property-decorator'
import { handleApprovalRule } from '@/api/financing'
import { getAreaApprovalPerson } from '@/api/countrysidePropertyTransaction'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  // name: 'ApprovalSetting',
  components: {}
})
// export default class ApprovalSetting extends Vue {
export default class CountrysideApprovalDetail extends Vue {
  private loadingTxt: string = loadingText
  private dynamicTags: any = []
  private inputValue: any = []
  private datas: any = []
  private dialogTableVisible: boolean = false
  private search: string = ''
  private gridData: any = []
  private copyData: any = []
  private center: boolean = true
  private isSelectData: any = {}
  private flag: string = ''
  private ruleType: string = ''
  private dataDetail: any = ''
  created() {
    if (this.$route.params.dataDetail) {
      this.dataDetail = this.$route.params.dataDetail
      sessionStorage.setItem('countrysideApprovalDetail', JSON.stringify(this.dataDetail))
    } else {
      let nowGet: any = sessionStorage.getItem('countrysideApprovalDetail')
      this.dataDetail = JSON.parse(nowGet)
    }
  }
  mounted() {
    this.ruleType = this.$route.params.ruleType
    this.showDetail()
    this.handleAllApprovalPerson()
  }
  // 销毁前清理sessionStorage
  beforeDestroy() {
    sessionStorage.removeItem('countrysideApprovalDetail')
  }
  // 展示审批人员的列详情（数据从上一级页面传来...）
  private showDetail() {
    let dataLists: any = this.dataDetail
    this.dynamicTags = dataLists
  }
  // 新增方法
  private addApprovalPerson() {
    this.flag = '0'
    this.dialogTableVisibles()
  }
  // 每个tab的点击事件---------
  private handleTab(tag?: any, index?: any) {
    this.isSelectData = { tag, index }
    this.$confirm('', '请选择操作', {
      confirmButtonText: '编辑',
      cancelButtonText: '删除',
      center: true,
      distinguishCancelAndClose: true
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: '进入编辑状态'
        })
        this.flag = '1'
        this.dialogTableVisibles()
      })
      .catch((res) => {
        if (res === 'cancel') {
          this.dynamicTags.splice(this.isSelectData.index, 1)
          this.$message({
            type: 'info',
            message: '已删除'
          })
        }
      })
  }
  // 弹框方法------------------------
  private dialogTableVisibles() {
    this.dialogTableVisible = true
  }
  // private handleClose(tag?: string) {
  //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
  // }

  // 搜索方法--------
  private handleSearch() {
    let gridData = this.copyData
    this.gridData = gridData.filter((data: any) => (this.search ? (data.name ? data.name.includes(this.search) : false) : this.copyData))
  }

  // 弹框选中人员获取人员信息
  private handleIsSelect(val: any) {
    let select: any = val
    this.inputValue = select
  }
  // 确定获取选中人员信息加到现有列详情数据
  private handleConfirm() {
    let isSelectData: any = this.isSelectData
    if (!this.inputValue.personId) {
      this.dialogTableVisible = false
      return
    }
    if (this.flag === '1') {
      this.dynamicTags.splice(isSelectData.index, 1, {
        ...this.inputValue,
        sort: isSelectData.tag.sort
      })
      this.dialogTableVisible = false
    } else {
      this.dynamicTags.push({
        sort: null,
        name: this.inputValue.name,
        personId: this.inputValue.personId
      })
      this.dialogTableVisible = false
    }
  }

  // 获取所有人员
  private handleAllApprovalPerson() {
    getAreaApprovalPerson(this.$route.params.areaConfigId ? this.$route.params.areaConfigId : '')
      .then((res) => {
        this.gridData = res.data.data ? res.data.data : []
        this.copyData = res.data.data ? res.data.data : []
      })
      .catch(() => {})
  }
  // 提交变更的信息
  private undateApprovalRule() {
    let personIdArr: any = []
    this.dynamicTags.forEach((item: any) => {
      personIdArr.push(item.personId)
    })
    let data: any = {
      ruleType: this.ruleType,
      personIds: personIdArr,
      areaConfigId: this.$route.params.areaConfigId
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    handleApprovalRule(data)
      .then((res) => {
        setTimeout(() => {
          this.$router.go(-1)
        }, 300)
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  // private handleInputConfirm() {
  //   let inputValue: string = this.inputValue
  //   if (inputValue) {
  //     this.dynamicTags.push(inputValue)
  //   }
  //   // this.inputVisible = false
  //   // this.inputValue = ''
  // }
}
