
import { Component, Vue } from 'vue-property-decorator'
import needData from '../components/need-data.vue'
import bidderInfo from '../components/bidder-info.vue'
import { getBiddersInfo } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    needData,
    bidderInfo
  }
})
export default class BiddersDetail extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true // 主loading
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {}
  private requestId: any = ''
  created() {
    if (this.$route.params.id) {
      this.requestId = this.$route.params.id
      sessionStorage.setItem('biddersDetail', this.requestId)
    } else {
      this.requestId = sessionStorage.getItem('biddersDetail')
    }
    getBiddersInfo({
      delistingBaseId: this.requestId
    })
      .then((res) => {
        if (res.data.data) {
          this.proDetail = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStorage.removeItem('biddersDetail')
    next()
  }
}
