
import { Component, Vue } from 'vue-property-decorator'
import { PersonnelForm } from '@/views/type'
import { getAllRole, addPersonnel, upDatePersonnel, getPresonInfoByPhone } from '@/api/personnel'
import { getRoleMenus } from '@/api/login'
import { localStore, sessionStore } from '@/utils/data-management'
import { decodeS } from 'nzh/dist/nzh.cn.min.js'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class PersonnelDetail extends Vue {
  private loadingTxt: string = loadingText
  private routeParams: any
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  private inputDisabled: boolean = false
  private areaConfigName: any = ''
  //----------------------------- data部分------------------------------------
  private recommenderCodeValue: string = ''
  private formInfo: PersonnelForm = {
    personId: '',
    name: '',
    idCard: '',
    phone: '',
    email: '',
    roleId: '',
    recommendCode: '',
    status: 0
  }
  private roleOptions: any = []
  private rules = {
    name: { required: true, message: '请输入名字', trigger: 'blur' },
    idCard: { required: true, message: '请输入身份证', trigger: 'blur' },
    phone: { required: true, message: '请输入手机号', trigger: 'blur' },
    // email: { required: true, message: '请输入邮箱', trigger: 'blur' },
    roleId: { required: true, message: '请选择角色', trigger: 'blur' },
    recommendCode: { required: true, message: '请输入推荐人代码', trigger: 'blur' }
  }
  private system: any = process.env.VUE_APP_SYSTEM_TYPE === 'zct' ? true : false

  // -------------周期 过滤 计算属性 监听等函数 --部分---------------------------

  get recommenderCode() {
    return this.recommenderCodeValue === '立项申请人' ? true : false
  }
  created() {
    let personDetail: any = sessionStore.get('personDetail')
    if (!personDetail) {
      sessionStore.set('personDetail', JSON.stringify(this.$route.params))
      this.routeParams = this.$route.params
    } else {
      this.routeParams = JSON.parse(personDetail)
    }
    if (this.routeParams.flag == '1') {
      // this.$route.meta.title = '查看详情'
      let getData: any = this.routeParams
      let formInfo: any = {}
      formInfo.personId = getData.dataDetail.personId
      formInfo.name = getData.dataDetail.name
      formInfo.idCard = getData.dataDetail.idCard
      formInfo.phone = getData.dataDetail.phone
      formInfo.email = getData.dataDetail.email
      formInfo.roleId = getData.dataDetail.roleId
      formInfo.recommendCode = getData.dataDetail.recommendCode
      formInfo.status = getData.dataDetail.status
      this.systemType != 'zct' && (this.areaConfigName = getData.dataDetail.areaName)
      this.recommenderCodeValue = getData.dataDetail.roleName
      this.formInfo = formInfo
    }
    // else {
    // this.$route.meta.title = '添加人员'
    // this.$set(this.$route.meta, 'title', '添加人员')
    // }

    getAllRole()
      .then((res) => {
        let datas = res.data
        this.roleOptions = datas.data
      })
      .catch(() => {})
    // this.$forceUpdate()
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.params.flag === '0') {
      to.meta.title = '添加人员'
    } else {
      to.meta.title = '查看详情'
    }
    next()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStore.remove('personDetail')
    next()
  }
  // ------------------------------自定义函数部分------------------------------
  private getInfo() {
    if (this.routeParams.flag == '0') {
      this.inputDisabled = true
      getPresonInfoByPhone({ phone: this.formInfo.phone })
        .then((res) => {
          let datas: any = res.data.data
          if (datas) {
            this.formInfo.name = datas.name ? datas.name : ''
            this.formInfo.idCard = datas.idCard ? datas.idCard : ''
            this.formInfo.email = datas.email ? datas.email : ''
          }
        })
        .finally(() => {
          this.inputDisabled = false
        })
    }
  }
  changeInput() {
    let values: string = this.formInfo.idCard || ''

    if (values.indexOf(' ') == -1) {
      if (values.length < 18) {
        this.$message.error('输入的身份证号码不能少于18位数')
      } else {
        this.formInfo.idCard = values
      }
    } else {
      this.$message.error('输入的身份证号码不能含有空格')
    }
    // this.formInfo.idCard = values.replace(/\s+/g,"");
  }
  selecttedRole(value: number) {
    let roleOptions = JSON.parse(JSON.stringify(this.roleOptions))
    let resultObj = roleOptions.filter((item: any) => {
      return item.value === value
    })
    this.formInfo.roleId = resultObj[0].value
    this.recommenderCodeValue = resultObj[0].label
    this.systemType != 'zct' && (this.areaConfigName = resultObj[0].areaName)
  }
  handleSubmit(data: any) {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
      datas: any = this.formInfo
    if (this.routeParams.flag == '1') {
      upDatePersonnel(datas)
        .then((res) => {
          if (res.data.message === 'success') {
            if (sessionStore.get('userPhone') == this.formInfo.phone) {
              getRoleMenus().then((res) => {
                let datas: any = res.data.data
                if (res.data.data) {
                  datas.forEach((item: any) => {
                    item.children.unshift({
                      path: '/home',
                      name: 'Home',
                      meta: {
                        title: '首页',
                        icon: 'star'
                      }
                    })
                  })
                  this.$store.commit({ type: 'ROUTE_UPDATE', datas: datas[0].children })
                  localStore.set('routes', datas[0].children)
                }
              })
            }
            this.$message({
              message: '成功提交',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      addPersonnel(datas)
        .then((res) => {
          if (res.data.message === 'success') {
            this.$message({
              message: '成功提交',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  filterSelect(data?: any) {
    // switch(){
    // }
  }
  handleReturn() {
    this.$router.go(-1)
  }
}
