
import { Component, Vue, Prop } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat'

@Component({
  components: {},
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class tradeReceipt extends Vue {
  @Prop() tableData: any
  @Prop() obj: any
  private interval: number = 0
  private seal = require('@/assets/images/certificate_seal.png')
  private userName: String = this.$store.state.userName || sessionStorage.getItem('UsIF')
  private date: String = timeFormat(+new Date())
  created() {
    if (this.userName) {
      this.userName = this.userName.replace(/\"/g, '')
    }
  }
  get filterTable() {
    let returnTable: any = []
    if (this.tableData && this.tableData.length) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i] && this.tableData[i].status == '1') {
          returnTable.push(this.tableData[i])
        }
      }
    }
    return returnTable
  }

  private clickprinting() {
    ;(this.$refs.btn as any).$el.style.display = 'none'
    let print = this.$refs.print
    //获取目标区域的HTML结构
    document.body.innerHTML = (print as any).innerHTML //替换网页的html内容
    this.interval = window.setInterval(this.printOperation, 500)
  }

  private printOperation() {
    window.print() //打印
    window.location.reload() //刷新页面，因为网页的html被替换后很丑
    window.clearInterval(this.interval)
  }
}
