var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "village-list" },
    [
      _c(
        "el-form",
        { attrs: { inline: "", model: _vm.formInfo, size: "small" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "省份：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        disabled: _vm.isSuperAdmin != "1" || !_vm.requestEnd,
                        placeholder: "请选择省份",
                        size: "small"
                      },
                      on: { change: _vm.selecttedProvince },
                      model: {
                        value: _vm.formInfo.province,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "province", $$v)
                        },
                        expression: "formInfo.province"
                      }
                    },
                    _vm._l(_vm.provinceOptions, function(item) {
                      return _c("el-option", {
                        key: item.provinceCode,
                        attrs: {
                          label: item.provinceName,
                          value: item.provinceCode + "," + item.provinceName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        disabled: _vm.isSuperAdmin != "1" || !_vm.requestEnd,
                        placeholder: "请选择城市",
                        size: "small"
                      },
                      on: { change: _vm.selecttedCity },
                      model: {
                        value: _vm.formInfo.city,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "city", $$v)
                        },
                        expression: "formInfo.city"
                      }
                    },
                    _vm._l(_vm.cityOptions, function(item) {
                      return _c("el-option", {
                        key: item.cityCode,
                        attrs: {
                          label: item.cityName,
                          value: item.cityCode + "," + item.cityName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区县：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        disabled: _vm.hasCounty() || !_vm.requestEnd,
                        placeholder: "请选择区县",
                        size: "small"
                      },
                      on: { change: _vm.selecttedCounty },
                      model: {
                        value: _vm.formInfo.county,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "county", $$v)
                        },
                        expression: "formInfo.county"
                      }
                    },
                    _vm._l(_vm.countyOptions, function(item) {
                      return _c("el-option", {
                        key: item.areaCode,
                        attrs: {
                          label: item.areaName,
                          value: item.areaCode + "," + item.areaName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "街道/乡镇：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: "请选择街道/镇",
                        size: "small"
                      },
                      model: {
                        value: _vm.formInfo.township,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "township", $$v)
                        },
                        expression: "formInfo.township"
                      }
                    },
                    _vm._l(_vm.townshipOptions, function(item) {
                      return _c("el-option", {
                        key: item.streetCode,
                        attrs: {
                          label: item.streetName,
                          value: item.streetCode + "," + item.streetName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "村组：" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.formInfo.villageName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "villageName", $$v)
                      },
                      expression: "formInfo.villageName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管理人员：" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.formInfo.managerName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "managerName", $$v)
                      },
                      expression: "formInfo.managerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { disabled: _vm.isDisBtn, size: "small" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small" },
                      on: { click: _vm.resetFn }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: {
                        disabled: _vm.isDisBtn,
                        size: "small",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.handleAddPersonnal }
                    },
                    [_vm._v("添加村组")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { disabled: _vm.isDisBtn, size: "small" },
                      on: {
                        click: function($event) {
                          _vm.markShow = true
                        }
                      }
                    },
                    [_vm._v("村组导入")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("nums-tips", { attrs: { nums: _vm.paginationParam.total } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  index: index => index + 1,
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "省份", prop: "province", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "城市", prop: "city", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "区县", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.county ? scope.row.county : "-")
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "街道/乡镇", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.township ? scope.row.township : "-")
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "所属村组(合作社/社区)", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.villageName ? scope.row.villageName : "-"
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "全村农户总数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.villageFarmerNumber ||
                              scope.row.villageFarmerNumber === 0
                              ? scope.row.villageFarmerNumber
                              : "-"
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "应采集农户信息数", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.shouldCollectFarmerNumber ||
                              scope.row.shouldCollectFarmerNumber === 0
                              ? scope.row.shouldCollectFarmerNumber
                              : "-"
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "管理人员",
                  align: "center",
                  "min-width": "120px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.managePeopleList, function(
                        ite,
                        ind
                      ) {
                        return _c("span", { key: ind }, [
                          _vm._v(
                            _vm._s(
                              (ind > 0 ? "," : "") +
                                (ite.managerName ? ite.managerName : "")
                            )
                          )
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.paginationParam.total,
          page: _vm.paginationParam.pageIndex,
          limit: _vm.paginationParam.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.paginationParam, "pageIndex", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.paginationParam, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm.markShow
        ? _c("div", { staticClass: "marks" }, [
            _c("div", { staticClass: "mark-main" }, [
              _c("div", { staticClass: "mark-title" }, [
                _c(
                  "span",
                  {
                    staticClass: "close-btn",
                    on: {
                      click: function($event) {
                        _vm.markShow = false
                      }
                    }
                  },
                  [_vm._v("x")]
                )
              ]),
              _c(
                "div",
                { staticClass: "mark-btn" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnLoading2,
                          expression: "btnLoading2"
                        }
                      ],
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small", disabled: _vm.btnLoading2 },
                      on: { click: _vm.downloadFn }
                    },
                    [_vm._v("下载模板")]
                  ),
                  _c(
                    "a",
                    {
                      ref: "fileDown",
                      staticStyle: { display: "none" },
                      attrs: {
                        download: "村组模板" + _vm.nowFileName,
                        href: _vm.nowDownloadUrl
                      }
                    },
                    [_vm._v("模板下载")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mark-btn" },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        disabled: _vm.btnLoading,
                        action: _vm.action,
                        type: "file",
                        accept: ".xlsx, .xls, .csv",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.fileChange,
                        "on-success": _vm.fileSuccess
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.btnLoading,
                              expression: "btnLoading"
                            }
                          ],
                          staticClass: "global-dotted-btn vertical-bottom",
                          attrs: { size: "small" }
                        },
                        [_vm._v("导入村组")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }