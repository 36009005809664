
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
  filters: {}
})
export default class InfoTemplateConfigDialog extends Vue {
  @Prop() private fieldOption!: any // 接收父组件传过来的数据
  @Prop({ default: false }) private isShow!: Boolean

  private title: any = '' //标题
  private disabled: boolean = false // 是否禁用
  // private columnId: any = '' // 栏目id
  private fieldId: any = '' // 字段id
  private columnName: string = '' // 栏目名称
  // private flag: any = '' // 标识
  // private maxSize: any = 5 // 文件大小

  @Watch('fieldOption', { deep: true, immediate: true })
  private handleDialogOption(newValue: any) {
    if (newValue.title) this.title = newValue.title
    if (newValue.disabled) this.disabled = newValue.disabled
    if (newValue.columnId) this.formData.templateMenuId = newValue.columnId
    // if (newValue.fieldId) this.fieldId = newValue.fieldId
    if (newValue.name) this.columnName = newValue.name

    this.formData.valueType = newValue.valueType ? newValue.valueType : null
    this.formData.colName = newValue.colName ? newValue.colName : ''
    this.formData.isRequired =
      newValue.isRequired || newValue.isRequired === 0 ? newValue.isRequired : 1
    this.formData.loanLimit = newValue.loanLimit ? newValue.loanLimit : 0
    this.formData.colType = newValue.colType ? newValue.colType : null
    this.formData.id = newValue.id ? newValue.id : ''
    // this.formData.templateMenuId = newValue.templateMenuId ? newValue.templateMenuId : ''
    this.formData.itemSaveRequestList = newValue.itemSaveRequestList
      ? newValue.itemSaveRequestList
      : [{ itemValue: '' }, { itemValue: '' }]

    // if (newValue.valueType) this.formData.valueType = newValue.valueType || 0
    // if (newValue.colName) this.formData.colName = newValue.colName
    // if (newValue.isRequired) this.formData.isRequired = newValue.isRequired
    // if (newValue.loanLimit) this.formData.loanLimit = newValue.loanLimit
    // if (newValue.colType) this.formData.colType = newValue.colType
    // if (newValue.id) this.formData.id = newValue.id
    // if (newValue.templateMenuId) this.formData.templateMenuId = newValue.templateMenuId
    // if (newValue.itemSaveRequestList) this.formData.itemSaveRequestList = newValue.itemSaveRequestList
  }
  @Watch('isShow')
  private handleIsShow(newValue: Boolean) {
    this.dialogTableVisible = newValue
  }

  private disableds: boolean = false
  private rules = {
    columnName: { required: true, message: '请输入栏目名称', trigger: 'blur' },
    sort: { required: true, message: '请输入栏目排序', trigger: 'blur' },
    mustFill: { required: true, message: '栏目内容是否必填', trigger: 'blur' },
    addOrNot: { required: true, message: '栏目内容是否允许新增', trigger: 'blur' }
  }
  private dialogTableVisible: Boolean = false
  private tableDatas: any = []

  private isOrModalClose: boolean = false
  private center: boolean = true
  private formData: any = {
    templateMenuId: '',
    valueType: 0,
    colName: '',
    isRequired: 1,
    loanLimit: 0,
    colType: '',
    itemSaveRequestList: []
  }

  private cancelDialog() {
    this.$emit('CancelDialog', { flag: false })
  }
  private confirmDialog(formName: any) {
    // ;(this.$refs[formName] as any).validate((valid: any) => {
    //   if (valid) {
    //     alert('submit!')
    //   } else {
    //     return false
    //   }
    // })
    let formData: any = {}
    if (this.title === '新增字段') {
      formData = this.formData
    } else {
      formData = JSON.parse(JSON.stringify(this.formData))
      // formData.id = this.fieldId
    }
    this.$emit('ConfirmDialog', { formData, flag: true })
  }
  private handleDialogClose(done: any) {
    this.$emit('CancelDialog', { flag: false })
  }
  private fieldOptions: any = [
    { label: '自行输入', value: 1 },
    { label: '数量单价计算组', value: 2 },
    { label: '金额（元）', value: 3 },
    { label: '收入', value: 4 },
    { label: '支出', value: 5 }
  ]
  private fieldTypeOptions: any = [
    { label: '选项', value: 1 },
    { label: '文本', value: 2 },
    { label: '数字', value: 3 },
    { label: '整数', value: 4 },
    { label: '日期', value: 5 }
  ]
  private handleFieldOptions(value: any) {
    this.disableds = value !== 2 ? false : true
    // this.disableds = value === 1 ? false : true
    this.formData.colName =
      // value === 1 ? '' : value === 2 ? '数量,单价,金额' : value === 3 ? '金额' : ''
      value === 2 ? '数量,单价,金额' : ''
  }

  private addOption() {
    this.formData.itemSaveRequestList.push({
      itemValue: ''
    })
  }
  // 删除选项
  private handleDeleteOption(value: any, data: any) {
    if (this.formData.itemSaveRequestList.length > 2) {
      this.formData.itemSaveRequestList = data.splice(value, 1)
    } else {
      this.$message.warning('选项必需有两项')
    }
  }
}
