import request from '@/utils/request'
// 权限配置

// 获取权限列表
export function getPowerList() {
    return request({
        url: '/admin/permission/list',
        method: 'GET'
    })
}

// 获取对应id的权限详情
export function getPowerDetails(data: object) {
    return request({
        url: '/admin/permission/selectById',
        method: 'POST',
        data
    })
}

// 添加权限
export function addPower(data: object) {
    return request({
        url: '/admin/permission/add',
        method: 'POST',
        data
    })
}

// 更新权限
export function updatePower(data: object) {
    return request({
        url: '/admin/permission/update',
        method: 'POST',
        data
    })
}

// 删除权限
export function deletePower(data: object) {
    return request({
        url: '/admin/permission/delete',
        method: 'POST',
        data
    })
}
