
import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class RatingStandard extends Vue {
  private tableData1: any = [
    {
      classify: '偿债能力指标',
      name: '资产负债率',
      formula: '负债总额 / 资产总额',
      value: '60%',
      fullMarks: 12,
      explain: '60%或以下为满分；每上升2个百分点扣1分；扣完为止。',
      row: 3
    },
    {
      classify: '偿债能力指标',
      name: '流动比率',
      formula: '流动资产 / 流动负债',
      value: '130%',
      fullMarks: 10,
      explain: '130%或以上为满分；每下降5个百分点扣1分；扣完为止。'
    },
    {
      classify: '偿债能力指标',
      name: '现金比率',
      formula: '现金 / 流动负债',
      value: '30%',
      fullMarks: 8,
      explain: '30%或以上为满分；每上升2个百分点扣1分；扣完为止。'
    },
    {
      classify: '获利能力指标',
      name: '销售利润率',
      formula: '销售利润 / 销售收入',
      value: '8%',
      fullMarks: 6,
      explain: '8%或以上为满分；每下降1.5个百分点扣1分；扣完为止。',
      row: 2
    },
    {
      classify: '获利能力指标',
      name: '资本回报率',
      formula: '净利润 / 所有者权益',
      value: '8%',
      fullMarks: 4,
      explain: '8%或以上为满分；每下降2个百分点扣1分；扣完为止。'
    },
    {
      classify: '经营管理指标',
      name: '销售收入现金流量',
      formula: '销售商品或提供劳务收到现金 / 销售收入',
      value: '80%',
      fullMarks: 6,
      explain: '80%或以上为满分，每下降5个百分点扣1分，扣完为止。',
      row: 5
    },
    {
      classify: '经营管理指标',
      name: '应收账款周转率',
      formula: '销售收入 / 平均应收账款',
      value: '400%',
      fullMarks: 6,
      explain: '400%以上为满分；每下降20个百分点扣1分；扣完为止。'
    },
    {
      classify: '经营管理指标',
      name: '存货周转率',
      formula: '产品销售成本 / 平均存货',
      value: '300%',
      fullMarks: 6,
      explain: '300%以上为满分；每下降20个百分点扣1分；扣完为止。'
    },
    {
      classify: '经营管理指标',
      name: '管理水平',
      formula: '规章制度的建设和执行；企业文化；财务管理；质量、技术、信息、管理。',
      value: '',
      fullMarks: 3,
      explain: '视情况给予0分至满分。'
    },
    {
      classify: '经营管理指标',
      name: '商誉',
      formula: '企业形象、产品质量、服务评价、行业声誉、纳税情况及遵纪守法程度等。',
      value: '',
      fullMarks: 2,
      explain: '视情况给予0分至满分。'
    },
    {
      classify: '履约指标',
      name: '授信资产本金偿还记录',
      formula: '',
      value: '',
      fullMarks: 10,
      explain:
        '根据本年度应归还银行的各类授信资产总额进行衡量：按期还本为满分；按期付息，但在评估时点存在逾期 1 个月以上的贷款记录，扣 4 分；未按期还本超过 3 个月，为 0 分，若本年度应还银行贷款本金总额为 0，以其他授信形式比照给分。',
      row: 2
    },
    {
      classify: '履约指标',
      name: '资产负债率',
      formula: '',
      value: '',
      fullMarks: 6,
      explain:
        '按期付息为满分；本年度存在拖欠利息10天以上的记录，扣3分；评估时点存在欠息情况为0分。若本年度应付银行利息总额为0，以其他授信形式比照给分。'
    },
    {
      classify: '发展能力的潜力指标',
      name: '固定资产净值率',
      formula: '固定资产净值 / 固定资产原值',
      value: '65%',
      fullMarks: 4,
      explain: '65%或以上为满分；每下降3个百分点扣1分；扣完为止。',
      row: 5
    },
    {
      classify: '发展能力的潜力指标',
      name: '销售收入增长率',
      formula: '（本期销售收入-上期销售收入）/ 上期销售收入',
      value: '8%',
      fullMarks: 6,
      explain: '8%或以上为满分；每下降2个百分点扣1分；扣完为止。'
    },
    {
      classify: '发展能力的潜力指标',
      name: '利润增长率',
      formula: '（本期实现净利润-上期实现净利润）/ 上期实现净利润',
      value: '10%',
      fullMarks: 4,
      explain:
        '10%或以上为满分；每下降2个百分点扣1分；扣完为止。上期亏损，本期盈利为2分；上期、本期都亏损为0分。'
    },
    {
      classify: '发展能力的潜力指标',
      name: '领导者素质',
      formula: '领导才能、管理素质、技术素质、开拓能力、应变能力、团结协作、法制观念',
      value: '',
      fullMarks: 4,
      explain: '视情况给予0分至满分；如发生不利于预测企业发展的重大人事变更，应及时下调分值。'
    },
    {
      classify: '发展能力的潜力指标',
      name: '市场前景、发展规划与实施条件',
      formula:
        '行业及产品销售远景、产业政策的影响、远近期目标、经济实力、技术条件、营销策略、人才条件、实施措施',
      value: '',
      fullMarks: 4,
      explain: '视情况给予0分至满分。'
    }
  ]
  private tableData2: any = [
    {
      name:
        '1. 资产负债率： ⑴如资产负债率大于80%小于90%，信用等级不得超过A级；⑵如资产负债率大于90%小于100%，信用等级不得超过B级；⑶如资产负债率大于100%信用等级为D级。'
    },
    {
      name:
        '2. 利润增长率：⑴如本期亏损，信用等级不得超过A级；⑵如本期、上期均亏损，信用等级不得超过 BB级。'
    },
    {
      name:
        '3. 履约指标：根据贷款五级分类的结果⑴如存在次级类贷款，信用等级不得超过B级；⑵如存在可疑类贷款，信用等级不得超过CC级；⑶如存损失类贷款信用等级为D级。'
    },
    {
      name: '4. 客户规模指标：如资产或销售收入之一小于5000万元，信用等级不得超过BBB级。'
    },
    {
      name: '5. 同业竞争力：如客户在行业中不处于领先位置，可相应降低其信用等级'
    },
    {
      name:
        '6. 报表真实性：如客户提供的财务报表未经权威审计部门审计，客户信用等级相应下调一个等级。'
    }
  ]
  private tableData3: any = [
    {
      mark: '90~100',
      grade: 'AAA',
      rank: '特优',
      explain:
        '信用很好，整体业务稳固发展，经营状况和财务状况良好，资产负债结构合理，经营过程中现金流量较为充足，偿债能力强，授信风险较小',
      row: 3
    },
    {
      mark: '85~89',
      grade: 'AA',
      rank: '优'
    },
    {
      mark: '80~84',
      grade: 'A',
      rank: '良'
    },
    {
      mark: '70~79',
      grade: 'BBB',
      rank: '较好',
      explain:
        '信用较好，现金周转和资产负债状况可为债务偿还提供保证，授信有一定风险，需落实有效的担保规避授信风险',
      row: 3
    },
    {
      mark: '65~69',
      grade: 'BB',
      rank: '尚可'
    },
    {
      mark: '60~64',
      grade: 'B',
      rank: '一般'
    },
    {
      mark: '50~59',
      grade: 'CCC',
      rank: '较差',
      explain:
        '信用较差，整体经营状况和财务状况不佳，授信风险较大，应采取措施改善债务人的偿债能力和偿还意愿，以确保银行债权的安全',
      row: 3
    },
    {
      mark: '45~49',
      grade: 'CC',
      rank: '差'
    },
    {
      mark: '40~44',
      grade: 'C',
      rank: '很差'
    }
  ]

  private arrayMethod1({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex == 1) {
      return {
        rowspan: row.row,
        colspan: 1
      }
    }
  }
  private arrayMethod3({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex == 3) {
      return {
        rowspan: row.row,
        colspan: 1
      }
    }
  }
}
