var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.authForm
        ? _c(
            "div",
            [
              _c("auth-form", {
                attrs: {
                  initManagerIdentityFront: _vm.initManagerIdentityFront,
                  markPng: _vm.initPng,
                  types: _vm.type,
                  "form-datass": _vm.formDatass,
                  isPass: _vm.isPass
                },
                on: {
                  formData: _vm.handleFormData,
                  triggers: _vm.getTriggers,
                  "update:types": function($event) {
                    _vm.type = $event
                  },
                  "update:isPass": function($event) {
                    _vm.isPass = $event
                  },
                  "update:is-pass": function($event) {
                    _vm.isPass = $event
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.uploadImg
        ? _c("UploadFirst", {
            ref: "asdf",
            attrs: { uploadOption: _vm.uploadOption },
            on: {
              "update:uploadOption": function($event) {
                _vm.uploadOption = $event
              },
              "update:upload-option": function($event) {
                _vm.uploadOption = $event
              },
              fileUrl: _vm.getFileUrlUrl
            }
          })
        : _vm._e(),
      _vm.QrCode
        ? _c("QrCode", {
            attrs: {
              userInfo: _vm.userInfo,
              size: 260,
              imageurl: _vm.imageUrl
            },
            on: {
              notify: function($event) {
                return _vm.resultVerify($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }