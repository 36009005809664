export function bankCardEncryption(bankCard: any) {
    let returnBankCard: string = ''
    if (bankCard || bankCard === 0) {
        if (!isNaN(bankCard)) {
            bankCard += ''
        }
        if (bankCard.length >= 16) {
            returnBankCard = bankCard.slice(0, 4) + '********' + bankCard.slice(bankCard.length - 4)
        } else {
            if (bankCard.length <= 8) {
                returnBankCard = '********'
            } else {
                let nums: number = bankCard.length - 8,
                    lastNums: number = 0
                if (nums == 1) {
                    nums = 0
                    lastNums = 1
                } else if (nums % 2 == 0) {
                    nums = nums / 2
                    lastNums = nums
                } else {
                    nums = (nums - 1) / 2
                    lastNums = nums + 1
                }
                returnBankCard = bankCard.slice(0, nums) + '********' + bankCard.slice(bankCard.length - lastNums)
            }
        }
    }
    return returnBankCard;
}