
import { Component, Vue } from 'vue-property-decorator'
import { approvalManagementList, checkApprovalSetting } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
import iconPretrial from '@/assets/images/admin/selected_pretrial.png'

@Component({
  name: 'ApprovalManagement',
  components: {}
})
export default class ApprovalManagement extends Vue {
  private loadingTxt: string = loadingText
  private dataList: any = []
  private iconPretrial: any = iconPretrial
  private loading = true
  created() {
    this.getRuleList()
  }
  private handleSetting(data?: any) {
    this.loading = true
    let ruleType: string = data.ruleType
    checkApprovalSetting({ ruleType,areaConfigId: '1' })
      .then((res) => {
        setTimeout(() => {
          this.$router.push({
            name: 'UnitApprovalSetting',
            params: { ruleType, dataDetail: res.data.data }
          })
        }, 300)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getRuleList() {
    const value: string = 'unit'
    approvalManagementList(value)
      .then((res) => {
        this.dataList = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
