
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { getAreaPowerList, getBusinessTypeList, liquidationList } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class LiquidationManagementList extends Vue {
  private loadingTxt: string = loadingText
  private time: any = ''
  private isSuperAdmin: any = '0'
  private businessTypeOptions: any = [
    // {
    //   businessType: '1',
    //   businessTypeDesc: '农村土地'
    // },
    // {
    //   businessType: '2',
    //   businessTypeDesc: '林权'
    // },
    // {
    //   businessType: '1334767379751936001',
    //   businessTypeDesc: '水产'
    // },
    // {
    //   businessType: '1334769116701302785',
    //   businessTypeDesc: '娱乐类'
    // }
  ]
  private areaOptions: any = []
  private statusOptions: any = [
    { label: '待清算', value: 1 },
    { label: '审批中', value: 2 },
    { label: '已驳回', value: 4 },
    { label: '清算中', value: 3 },
    { label: '清算失败', value: 5 },
    { label: '已清算', value: 6 },
    //22-9-8新加状态
    { label: '无需清算', value: 99 } 
  ]
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    businessType: '',
    bidName: '',
    listingParty: '',
    areaId: '',
    status: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'LiquidationDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      if (isGetList && !vim.businessTypeOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessTypeOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'LiquidationDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        businessTypeOptions: this.businessTypeOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private getStatusDesc(status: any) {
    let returnDesc: string = ''
    if (status) {
      for (let i = 0; i < this.statusOptions.length; i++) {
        if (status == this.statusOptions[i].value) {
          returnDesc = this.statusOptions[i].label
          break
        }
      }
    }
    return returnDesc
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    
    liquidationList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'LiquidationDetail',
      params: {
        id: row.id
      }
    })
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
}
