var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "contentBox",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    _vm._l(_vm.dataList, function(item, index) {
      return _c("div", { key: index, staticClass: "innerBox" }, [
        item.ruleName !== "成立审批"
          ? _c(
              "div",
              {
                staticClass: "items",
                on: {
                  click: function($event) {
                    return _vm.handleSetting(item)
                  }
                }
              },
              [
                item.ruleName === "预审审批"
                  ? _c("img", { attrs: { src: _vm.image.iconPretrial } })
                  : _vm._e(),
                item.ruleName === "终审审批"
                  ? _c("img", { attrs: { src: _vm.image.iconFinalJudgment } })
                  : _vm._e(),
                item.ruleName === "受理审批"
                  ? _c("img", { attrs: { src: _vm.image.iconAcceptance } })
                  : _vm._e(),
                item.ruleName === "成立审批"
                  ? _c("img", { attrs: { src: _vm.image.iconEstablish } })
                  : _vm._e(),
                _c("span", { staticClass: "names" }, [
                  _vm._v(_vm._s(item.ruleName))
                ])
              ]
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }