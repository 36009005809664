import request from '@/utils/request'
// 菜单配置

// 获取菜单列表
export function getMenuList() {
    return request({
        url: '/admin/menu/list',
        // method: 'GET'
        method: 'POST'
    })
}

// 获取对应id的菜单详情
export function getMenuDetails(data: object) {
    return request({
        url: '/admin/menu/selectById',
        method: 'POST',
        data
    })
}

// 添加菜单
export function addMenu(data: object) {
    return request({
        url: '/admin/menu/add',
        method: 'POST',
        data
    })
}

// 更新菜单
export function updateMenu(data: object) {
    return request({
        url: '/admin/menu/update',
        method: 'POST',
        data
    })
}

// 删除菜单
export function deleteMenu(data: object) {
    return request({
        url: '/admin/menu/delete',
        method: 'POST',
        data
    })
}
