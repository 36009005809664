var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contents" }, [
    _c("div", { staticClass: "loginPicture" }, [
      _c("img", {
        staticClass: "loginImg",
        attrs: { src: _vm.images.loginBgImgs, alt: "首页图" }
      })
    ]),
    _c("div", { staticClass: "rightBox" }, [
      _c("div", { staticClass: "rightContent" }, [
        _c("div", { staticClass: "tabText" }, [
          _c(
            "span",
            { staticClass: "loginText", on: { click: _vm.tabLogin } },
            [_c("span", [_vm._v("登录")]), _c("i")]
          ),
          _c(
            "span",
            { staticClass: "registerText", on: { click: _vm.tabRegister } },
            [_vm._v("注册")]
          )
        ]),
        _c("div", { staticClass: "inputBox" }, [
          _c(
            "div",
            { staticClass: "itemInput" },
            [
              _c("span", { staticClass: "itemInputText" }, [_vm._v("手机号")]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入手机号码" },
                model: {
                  value: _vm.formData.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "itemInput" },
            [
              _c("span", { staticClass: "itemInputText" }, [_vm._v("密码")]),
              _c("el-input", {
                staticClass: "input",
                attrs: { type: "password", placeholder: "请输入密码" },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "btnBox", on: { click: _vm.handleLogin } }, [
          _c("span", [_vm._v("登录")])
        ]),
        _c("div", { staticClass: "operationPassword" }, [
          _c(
            "div",
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.remenberpassword },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                },
                [_vm._v("记住密码")]
              )
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "forgetPwd", on: { click: _vm.tabForget } },
            [_vm._v("忘记密码?")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }