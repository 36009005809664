
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getPowerList, getPowerDetails, addPower, updatePower, deletePower } from '@/api/powerConfig'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class PowerConfig extends Vue {
  private loadingTxt: string = loadingText
  private nowId: any = ''
  private loadin: boolean = false
  private powerList: any = []
  // 弹窗类型
  private markType: string = ''
  private markShow: boolean = false // 弹窗显示标志位
  // 弹窗数据
  private markInfo: any = {
    code: '',
    name: ''
  }
  created() {
    this.hadnlePower()
  }
  // 获取权限列表
  private hadnlePower() {
    this.loadin = true
    getPowerList()
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          this.powerList = res.data.data
        }
      })
      .finally(() => {
        this.loadin = false
      })
  }
  private addFn() {
    this.markType = 'add'
    this.markShow = true
  }
  private eitdFn() {
    if (!this.nowId) {
      this.$message.error('请选择更改项！！')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    getPowerDetails({
      permissionId: this.nowId
    })
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          Object.keys(this.markInfo).forEach((key) => {
            this.markInfo[key] = res.data.data[key] ? res.data.data[key] : ''
          })
          this.markType = 'update'
          this.markShow = true
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private deletedInfo() {
    if (!this.nowId) {
      this.$message.error('请选择更改项！！')
      return
    }
    this.$confirm('确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.deletedFn()
      })
      .catch(() => {})
  }
  private deletedFn() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    deletePower({
      permissionId: this.nowId
    })
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          this.$message.success('删除成功')
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.nowId = ''
            loadingInstance.close()
            this.hadnlePower()
          })
        } else {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        }
      })
      .catch(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 关闭弹窗
  private closeFn() {
    this.markShow = false
    this.markInfo = {
      code: '',
      name: ''
    }
  }
  // 弹窗确认按钮
  private confirm(type: string) {
    if (!this.markInfo.name.trim()) {
      this.$message.error('权限名称为必填项!!')
      return
    }
    if (this.markType == 'add' && !this.markInfo.code.trim()) {
      this.$message.error('编码为必填项!!')
      return
    }
    this.markShow = false
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let params: any = {
      ...this.markInfo
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    if (type == 'add') {
      addPower(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message.success('添加成功')
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
              this.hadnlePower()
            })
          } else {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          }
        })
        .catch(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
        .finally(() => {
          this.markInfo = {
            code: '',
            name: ''
          }
        })
    } else if (type == 'update') {
      params.permissionId = this.nowId
      updatePower(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message.success('编辑成功')
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
              this.hadnlePower()
            })
          } else {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          }
        })
        .catch(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
        .finally(() => {
          this.markInfo = {
            code: '',
            name: ''
          }
        })
    }
  }
}
