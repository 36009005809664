
import { Component, Vue } from 'vue-property-decorator'
import { getAllRole, addRolePersonnel, upDateRolePersonnel, getAllRouter } from '@/api/personnel'
import { decodeS } from 'nzh/dist/nzh.cn.min.js'
import { searchArea } from '@/api/noticeManagement'
import { Loading } from 'element-ui'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class PersonnelDetail extends Vue {
  //----------------------------- data部分------------------------------------
  private loadingTxt: string = loadingText
  private routeParams: any
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  private code: any = ''
  private isSuperAdmin: any = '0'
  private areaId: any = ''
  private areaOptions: any = []
  private showBidPriceInfoPanel: boolean = true
  private roleName: string = ''
  private status: number = 0
  private roleOptions: any = []
  private dataTree: any = []
  private roleId: number = 0
  private allPermissions: any = []
  private checkList: any = []

  // -------------周期 过滤 计算属性 监听等函数 --部分---------------------------
  created() {
    let roleDetail: any = sessionStore.get('roleDetail')
    if (!roleDetail) {
      sessionStore.set('roleDetail', JSON.stringify(this.$route.params))
      this.routeParams = this.$route.params
    } else {
      this.routeParams = JSON.parse(roleDetail)
    }
    let params: any = {}
    this.systemType != 'zct' && (this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin'))
    if (this.routeParams.flag === '0') {
      if (this.systemType != 'zct') {
        this.isSuperAdmin != '1' && (this.areaId = sessionStorage.getItem('nowAreaconfigId'))
        params = { isDelete: false, isDisable: false }
      }
      this.hadnleAllRouter()
    }
    this.systemType != 'zct' &&
      searchArea(params).then((res) => {
        if (res.data && res.data.code == 1000) {
          this.areaOptions = this.filteAreaOptions(res.data.data.aeras)
        }
      })
  }

  mounted() {
    getAllRole()
      .then((res) => {
        let datas = res.data
        this.roleOptions = datas.data
      })
      .catch(() => {})
    if (this.routeParams.flag === '1') {
      this.$route.meta.title = '角色详情'
      let datas: any = this.routeParams.dataDetail
      this.roleId = datas.roleId
      this.code = datas.roleCode
      this.roleName = datas.roleName
      this.status = datas.status
      this.areaId = datas.areaId
      let newArr = this.filterData(datas.menus)
      this.$nextTick(() => {
        ;(this as any).$refs.tree.setCheckedKeys([...this.getDatas(newArr)])
      })
      this.dataTree = newArr
      this.allPermissions = datas.permissions
      if (datas.permissions.length > 0) {
        let checkList: any = []
        let permissions: any = datas.permissions
        datas.permissions.forEach((item: any) => {
          if (item.mark) {
            checkList.push(item.permissionId)
          }
        })
        this.checkList = checkList
      }
    } else {
      this.$route.meta.title = '添加角色'
    }
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.params.flag === '0') {
      to.meta.title = '添加角色'
    } else {
      to.meta.title = '查看详情'
    }
    next()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStore.remove('roleDetail')
    next()
  }
  // ------------------------------自定义函数部分------------------------------
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 获取所有角色菜单
  private hadnleAllRouter() {
    getAllRouter()
      .then((res) => {
        let newData = this.filterData(res.data.data.allMenus)
        this.dataTree = newData
        this.allPermissions = res.data.data.allPermissions
      })
      .catch(() => {})
  }
  // 过滤后台数据的属性名
  filterData(datas?: any) {
    let newMenuData: any = JSON.parse(
      JSON.stringify(datas)
        .replace(/name/g, 'label')
        .replace(/menuId/g, 'id')
        .replace(/subMenu/g, 'children')
    )
    return newMenuData
  }
  // 递归菜单数据
  getDatas(data?: any) {
    /** 数据结构
     *
     *  id: 1
     * label: "金融项目"
     * path: ""
     * pid: (...)
     *  mark: true
     * children: Array(3)    这里还能继续递归执行，直到children数组为0
     */
    let ids: any = []
    function treeData(nodeArr: any) {
      if (nodeArr.length > 0) {
        nodeArr.forEach((node: any, index: number) => {
          if (node.mark) {
            if (!node.children || node.children.length == 0) {
              ids.push(node.id)
            }
            if (node.children && node.children.length > 0) {
              treeData(node.children)
            }
          }
        })
      }
    }

    treeData(data)
    return ids
  }

  // selecttedRole(value: number) {
  //   this.formInfo.roleId = value
  // }
  handleSubmit() {
    let datas: any = {}
    datas.roleName = this.roleName
    datas.status = this.status
    datas.roleId = this.roleId
    datas.areaId = this.areaId
    let dataArrChilren: any = (this as any).$refs.tree.getCheckedKeys()
    let dataArrParent: any = (this as any).$refs.tree.getHalfCheckedKeys()
    datas.menuIds = dataArrChilren.concat(dataArrParent)
    datas.permissionIds = this.checkList
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (this.routeParams.flag == '0') {
      addRolePersonnel(datas)
        .then((res) => {
          if (res.data.message === 'success') {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      upDateRolePersonnel(datas)
        .then((res) => {
          if (res.data.message === 'success') {
            this.$message({
              message: '更新成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  handleReturn() {
    this.$router.go(-1)
  }
  // getCheckedNodes() {
  //   console.log((this as any).$refs.tree.getCheckedNodes())
  // }
  // getCheckedKeys() {
  //   console.log((this as any).$refs.tree.getCheckedKeys())
  // }
  // setCheckedNodes() {
  //   ;(this as any).$refs.tree.setCheckedNodes([
  //     {
  //       id: 5,
  //       label: '二级 2-1'
  //     },
  //     {
  //       id: 9,
  //       label: '三级 1-1-1'
  //     }
  //   ])
  // }
  // setCheckedKeys() {
  //   ;(this as any).$refs.tree.setCheckedKeys([3])
  // }
  // resetChecked() {
  //   ;(this as any).$refs.tree.setCheckedKeys([])
  // }
}
