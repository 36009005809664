var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sign-config" },
    [
      _c(
        "el-form",
        {
          ref: "formInfo",
          attrs: {
            rules: _vm.rules,
            model: _vm.formInfo,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司名称：", prop: "companyName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公司名称" },
                model: {
                  value: _vm.formInfo.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "companyName", $$v)
                  },
                  expression: "formInfo.companyName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地址：", prop: "address" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入地址" },
                model: {
                  value: _vm.formInfo.address,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "address", $$v)
                  },
                  expression: "formInfo.address"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "法定代表人：", prop: "legalPerson" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入法定代表人" },
                model: {
                  value: _vm.formInfo.legalPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "legalPerson", $$v)
                  },
                  expression: "formInfo.legalPerson"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "法人身份证号：", prop: "legalPersonIdCard" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入地址" },
                model: {
                  value: _vm.formInfo.legalPersonIdCard,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "legalPersonIdCard", $$v)
                  },
                  expression: "formInfo.legalPersonIdCard"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "统一社会信用代码：", prop: "creditCode" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入法定代表人" },
                model: {
                  value: _vm.formInfo.creditCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "creditCode", $$v)
                  },
                  expression: "formInfo.creditCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "营业执照：", prop: "businessLicense" } },
            [
              _c("upLoadFile-sign", {
                attrs: {
                  uploadOption: _vm.uploadOption,
                  listShow: _vm.listShow
                },
                on: {
                  "update:uploadOption": function($event) {
                    _vm.uploadOption = $event
                  },
                  "update:upload-option": function($event) {
                    _vm.uploadOption = $event
                  },
                  "update:listShow": function($event) {
                    _vm.listShow = $event
                  },
                  "update:list-show": function($event) {
                    _vm.listShow = $event
                  },
                  fileUrl: _vm.getFileUrlUrl
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人：", prop: "contactPerson" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系人" },
                model: {
                  value: _vm.formInfo.contactPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "contactPerson", $$v)
                  },
                  expression: "formInfo.contactPerson"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电子邮箱：", prop: "email" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入电子邮箱" },
                model: {
                  value: _vm.formInfo.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "email", $$v)
                  },
                  expression: "formInfo.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电话：", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入电话" },
                model: {
                  value: _vm.formInfo.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "mobile", $$v)
                  },
                  expression: "formInfo.mobile"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "银行户名：", prop: "accountName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入银行户名" },
                model: {
                  value: _vm.formInfo.accountName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "accountName", $$v)
                  },
                  expression: "formInfo.accountName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户银行：", prop: "openBank" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输开户银行" },
                model: {
                  value: _vm.formInfo.openBank,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "openBank", $$v)
                  },
                  expression: "formInfo.openBank"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "银行账号：", prop: "bankAccount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入银行账号" },
                model: {
                  value: _vm.formInfo.bankAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "bankAccount", $$v)
                  },
                  expression: "formInfo.bankAccount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司印章：", prop: "companySignUrl" } },
            [
              _c("SignatureUpload", {
                attrs: {
                  userId: _vm.formInfo.companyId,
                  value: _vm.companySignUrl,
                  signType: "1"
                },
                on: { option: _vm.comOption, imgBase: _vm.imgBaseCompany }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "法人印章：", prop: "legalPersonSignUrl" } },
            [
              _c("SignatureUpload", {
                attrs: {
                  userId: _vm.formInfo.companyId,
                  value: _vm.legalPersonSignUrl,
                  signType: "2"
                },
                on: { option: _vm.legalOption, imgBase: _vm.imgBaselegal }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit("formInfo")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "授权认证", visible: _vm.qrDiaShow },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow },
            on: { notify: _vm.notify }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }