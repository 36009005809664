import request from '@/utils/request'
// ------------结算列表--------------

//获取结算列表
export function getSettlementList(data: Object) {
    return request({
        url: '/settlementApply/back/page',
        method: 'POST',
        data: data
    })
}

//获取结算详情
export function getSettlementDetails(data: Object) {
    return request({
        url: '/settlementApply/back/detail',
        method: 'POST',
        data: data
    })
}

// ------------我的审批--------------

//获取结算审批列表
export function getSettlementApprovalList(data: Object) {
    return request({
        url: '/settlementApproval/page',
        method: 'POST',
        data: data
    })
}

//获取结算审批详情
export function getSettlementApprovalDetails(data: Object) {
    return request({
        url: '/settlementApproval/detail',
        method: 'POST',
        data: data
    })
}

// 提交结算审批
export function sumbitSettlementApproval(data: Object) {
    return request({
        url: '/settlementApproval/approval',
        method: 'POST',
        data: data
    })
}
