
import { Component, Vue } from 'vue-property-decorator'
import { VillageDetail, TownshipDetail, CountyDetail, villageSave, townshipSave, countySave, applyCredit } from '@/api/regionalCreditManagement'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class MyJurisdictionDetails extends Vue {
  private loadingTxt: string = loadingText
  private mainLoading: boolean = false
  // 时间线状态logo
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  // 时间线状态
  private iconType: object = ['primary', 'success', 'danger']
  private isEdit: boolean = false
  private isSave: boolean = true
  private isGetSuccess = false
  private datas: any = {}
  private villageId: any
  created() {
    this.mainLoading = true
    let routeParams = this.$route.params
    if (routeParams.villageId) {
      this.villageId = routeParams.villageId
      sessionStorage.setItem('myJurisdictionDetails', this.villageId)
    } else {
      this.villageId = sessionStorage.getItem('myJurisdictionDetails')
    }
    if (routeParams.type == '0') {
      VillageDetail({ villageId: this.villageId })
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    } else if (routeParams.type == '1') {
      TownshipDetail({ villageId: this.villageId })
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    } else {
      CountyDetail({ villageId: this.villageId })
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('myJurisdictionDetails')
    next()
  }
  // private changeCode(name: any) {
  //   this.$nextTick(() => {
  //     if (this.datas[`${name}`] != null) {
  //       this.datas[`${name}`] = this.datas[`${name}`].replace(/[^\d.]/g, '')
  //     }
  //   })
  // }
  private changeInput(value: any, name: string) {
    this.datas[name] = value.replace(/[^\d\.]/g, '')
  }
  private filterFn(name: string) {
    let doingVal: any = this.datas[name]
    if (doingVal) {
      let moveDot: any = doingVal + '',
        startDot: number = moveDot.indexOf('.')
      if (startDot > -1 && moveDot.length > 1) {
        doingVal = moveDot.slice(0, startDot + 1) + moveDot.slice(startDot + 1).replace('.', '')
        let nowPD: any = doingVal.split(''),
          endLength: number = nowPD.length - 1
        if (nowPD[0] == '.' || (nowPD.length > 1 && nowPD[0] == '0' && nowPD[1] != '.')) {
          nowPD.splice(0, 1)
          endLength -= 1
        }
        if (nowPD.indexOf('.') == endLength) {
          nowPD.splice(endLength, 1)
        }
        doingVal = nowPD.join('')
      } else if (startDot > -1 && moveDot.length == 1) {
        doingVal = 0
      }
    }
    return doingVal
  }
  private blurInput() {
    let doingVal: any = this.filterFn('villageBadLoanBalance')
    if (doingVal > this.datas.villageLoanBalance) {
      this.$message.error('农户不良贷款余额不能大于农户贷款余额')
      this.datas.villageBadLoanBalance = 0
      return
    }
    this.datas.villageBadLoanBalance !== doingVal && (this.datas.villageBadLoanBalance = doingVal)
    if (!doingVal) {
      this.datas.badCreditFarmerRate = 0
    } else {
      this.datas.badCreditFarmerRate = Number(((doingVal / this.datas.villageLoanBalance) * 100).toFixed(2))
    }
  }
  private baseBlur(obj: any, name: string) {
    let doingVal: any = this.filterFn(name)
    this.datas[name] !== doingVal && (this.datas[name] = doingVal)
  }
  private filteInput() {
    let isReturn: boolean = false,
      errorTitle: string = ''
    if (this.$route.params.type == '0' || this.$route.params.type == '1') {
      if (this.datas.isCareFinancial === null) {
        errorTitle = '是否关心和支持金融相关工作为必选项！！'
        isReturn = true
      }
    }
    if (this.$route.params.type == '1' || this.$route.params.type == '2') {
      if (this.datas.isActivePromote === null) {
        errorTitle = '是否积极推动农村信用体系建设为必选项！！'
        isReturn = true
      }
    }
    if (this.$route.params.type == '0') {
      if (isReturn) {
      } else if (!this.datas.villageBadLoanBalance && this.datas.villageBadLoanBalance !== 0) {
        errorTitle = '全村农户不良贷款余额为必填项！！'
        isReturn = true
      } else if (this.datas.isSetFinancial === null) {
        errorTitle = '是否设立三农金融服务室为必选项！！'
        isReturn = true
      } else if (this.datas.isHonesty === null) {
        errorTitle = '“两委”班子是否团结、群组威信高为必选项！！'
        isReturn = true
      } else if (this.datas.isBlacklist === null) {
        errorTitle = '农户被列入行业管理严重失信名单情况(占比)为必选项！！'
        isReturn = true
      } else if (this.datas.isBlacklist && !this.datas.blacklistRate && this.datas.blacklistRate !== 0) {
        errorTitle = '农户被列入行业管理严重失信名单情况(占比)为必填项！！'
        isReturn = true
      } else if (Number(this.datas.villageBadLoanBalance) > this.datas.villageLoanBalance) {
        errorTitle = '农户不良贷款余额不能大于农户贷款余额'
        isReturn = true
      }
    } else if (this.$route.params.type == '1') {
      if (isReturn) {
      } else if (this.datas.isJurisdictionNoCreditFarmer === null) {
        errorTitle = '辖区自然人、法人被列入行业管理严重失信名单情况(占比)为必选项！！'
        isReturn = true
      } else if (this.datas.isJurisdictionNoCreditFarmer && !this.datas.jurisdictionNoCreditFarmerRate && this.datas.jurisdictionNoCreditFarmerRate !== 0) {
        errorTitle = '辖区自然人、法人被列入行业管理严重失信名单情况(占比)为必填项！！'
        isReturn = true
      } else if (this.datas.isTownshipDefaulted === null) {
        errorTitle = '乡镇所属部门、单位及创办经济实体是否有逃废债行为为必选项！！'
        isReturn = true
      } else if (this.datas.isTownshipBadLoan === null) {
        errorTitle = '乡镇所属部门、单位及创办经济实体有无不良贷款为必选项！！'
        isReturn = true
      } else if (this.datas.isHonesty === null) {
        errorTitle = '班子是否团结务实、诚实守信为必选项！！'
        isReturn = true
      }
    } else if (this.$route.params.type == '2') {
      if (isReturn) {
      } else if (!this.datas.threeYearLoanFinancialRate && this.datas.threeYearLoanFinancialRate !== 0) {
        errorTitle = '辖区金融机构近三年涉农贷款占全县（市、区）金融机构贷款总量为必填项！！'
        isReturn = true
      } else if (this.datas.isJurisdictionNoCreditFarmer === null) {
        errorTitle = '辖区自然人、法人被列入各行业严重失信名单、黑名单情况(占比)为必选项！！'
        isReturn = true
      } else if (this.datas.isJurisdictionNoCreditFarmer && !this.datas.jurisdictionNoCreditFarmerRate && this.datas.jurisdictionNoCreditFarmerRate !== 0) {
        errorTitle = '辖区自然人、法人被列入各行业严重失信名单、黑名单情况(占比)为必填项！！'
        isReturn = true
      } else if (!this.datas.ruralSmallFinancialRate && this.datas.ruralSmallFinancialRate !== 0) {
        errorTitle = '农村中小金融机构总数为必填项！！'
        isReturn = true
      } else if (!this.datas.farmerCreditCoverageLoanRate && this.datas.farmerCreditCoverageLoanRate !== 0) {
        errorTitle = '农户信用贷款覆盖率为必填项！！'
        isReturn = true
      } else if (!this.datas.farmerLoanMeetRate && this.datas.farmerLoanMeetRate !== 0) {
        errorTitle = '农户贷款满足率为必填项！！'
        isReturn = true
      } else if (!this.datas.newMedicalInsuranceRate && this.datas.newMedicalInsuranceRate !== 0) {
        errorTitle = '新型农村合作医疗保险参保率为必填项！！'
        isReturn = true
      } else if (this.datas.isThreeBetterThanAverage === null) {
        errorTitle = '辖区金融机构近三年涉农贷款占全县（市、区）金融机构贷款总量是否高于全区平均水平为必选项！！'
        isReturn = true
      } else if (this.datas.isServiceMoreThanTenThousand === null) {
        errorTitle = '金融机构服务点行政村覆盖率达到1个/万人以上为必选项！！'
        isReturn = true
      } else if (this.datas.isSamplingPassRateThanAverage === null) {
        errorTitle = '县（市、区）产品监督抽查合格率是否居全区平均水平以上为必选项！！'
        isReturn = true
      } else if (this.datas.isQualityOneHundred === null) {
        errorTitle = '工业品和食品生产许可企业是否100%建立企业质量信用档案为必选项！！'
        isReturn = true
      } else if (this.datas.isThreeDepositThanAverage === null) {
        errorTitle = '金融机构近三年资产利润率是否高于全区平均水平为必选项！！'
        isReturn = true
      } else if (this.datas.isThreeProfitThanAll === null) {
        errorTitle = '金融机构近三年存款增幅高于全区水平为必选项！！'
        isReturn = true
      } else if (this.datas.isThreeLoanAddThanAverage === null) {
        errorTitle = '金融机构近三年贷款增幅高于全区平均水平为必选项！！'
        isReturn = true
      }
    }
    if (isReturn) {
      this.$message.error(errorTitle)
    }
    return isReturn
  }
  private editFn() {
    if (!this.isEdit) {
      this.isEdit = true
      this.isSave = false
    } else {
      let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
      if (this.filteInput()) {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      }
      !this.datas.villageId && (this.datas.villageId = this.villageId)
      if (this.$route.params.type == '0') {
        villageSave(this.datas)
          .then((res: any) => {
            this.isEdit = false
            this.isSave = true
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      } else if (this.$route.params.type == '1') {
        townshipSave(this.datas)
          .then((res: any) => {
            this.isEdit = false
            this.isSave = true
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      } else if (this.$route.params.type == '2') {
        countySave(this.datas)
          .then((res: any) => {
            this.isEdit = false
            this.isSave = true
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      }
    }
  }
  private applyFn() {
    if (!this.isSave) {
      this.$message.error('请保存后再申请！！')
      return
    }
    this.$confirm('', '是否确认申请信用区域评定？', {
      confirmButtonText: '是',
      cancelButtonText: '否',
      center: true,
      distinguishCancelAndClose: true
    })
      .then(() => {
        let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
        if (this.filteInput()) {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        applyCredit({ villagePermissionId: this.villageId })
          .then((res) => {
            this.$message.success('申请成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      })
      .catch(() => {})
  }
}
