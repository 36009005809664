
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { PersonnelForm } from '@/views/type'

// 接口联调
// import { getProjectList, deleteProject } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
import { getCompanyBankBusinessList, deleteCompanyBankBusiness } from '@/api/center'

import numsTips from '@/components/numsTips/index.vue'

@Component({
  components: {
    numsTips
  },
  filters: {
    filterStatus: (status: any) => {
      if (status === 1) {
        return '政府部门'
      } else if (status === 2) {
        return '金融机构'
      } else {
        return '无数据'
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class UnitList extends Vue {
  // private roleOption: string = ''
  // private statusOption: string = ''
  // private disabled: boolean = false
  // private roleOptions: any = []
  private businessItem: any = [
    { label: '实时付款', value: 1 },
    { label: '实时收款', value: 2 },
    { label: '批量代付', value: 3 },
    { label: '批量代收', value: 4 }
  ]
  private formInfo: any = {
    pageNumber: 1,
    pageSize: 10,
    type: 1,
    bankCard: '',
    bussinessType: '',
    bankName: '',
    feeCode: ''
  }
  private companyType: any = [
    { label: '政府部门', value: 1 },
    { label: '金融机构', value: 2 }
  ]
  private pageSizesList: any = [10, 20, 40, 80]
  private tableData: any = []
  private loading = false
  private totalCount: number = 0
  // private mounted() {
  // getAllRole()
  //   .then((res) => {
  //     // console.log(res.data)
  //     let datas = res.data
  //     this.roleOptions = datas.data
  //   })
  //   .catch(() => {})
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (['AddBankManagement', 'ImportBankBusiness'].indexOf(from.name) > -1 && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.totalCount = pageParams.totalCount
      }
      vim.handleBankBusinessList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['AddBankManagement', 'ImportBankBusiness'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo,
        totalCount: this.totalCount
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  // 分页--改变每页条数触发 -----
  private handleSizeChange(pageSize: number): void {
    console.log(`每页${pageSize}条`)
    this.formInfo.pageSize = pageSize
    this.handleBankBusinessList()
  }
  // 分页--改变页码数触发 -----
  private handleCurrentChange(currentPage: number): void {
    console.log(`当前页:${currentPage}`)
    this.formInfo.pageNumber = currentPage
    this.handleBankBusinessList()
  }

  // selecttedRole(value: number): void {
  // console.log(value)
  // this.formInfo.roleId = value
  // }

  // selecttedStatus(value: string): void {
  // this.statusOption = value
  // }
  // 获取银行业务列表数据
  private handleBankBusinessList(): void {
    // let checkPersonData: any = this.formInfo
    let data: any = {
      ...this.formInfo
    }
    getCompanyBankBusinessList(data)
      .then((res) => {
        let datas: any = res.data
        this.tableData = res.data.data
        this.totalCount = datas.total
      })
      .catch(() => {})
      .finally()
  }
  // // 搜索银行业务列表
  private handleSearch() {
    this.handleBankBusinessList()
  }

  // 查看列表详情
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'AddBankManagement',
      params: { id: datas.id, flag: 'check', datas }
    })
  }
  // 导入模板
  private handleImportBusiness(): void {
    this.$router.push({
      name: 'ImportBankBusiness'
    })
  }
  // 新增
  private handleAddBusiness(): void {
    this.$router.push({
      name: 'AddBankManagement',
      params: { id: '', flag: 'add' }
    })
  }

  // 删除
  handleDelete(row: any) {
    let data: any = {
      id: row.id
    }
    deleteCompanyBankBusiness(data).then((res) => {
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
      this.handleBankBusinessList()
    })
  }
}
