
import { Component, Vue, Watch } from 'vue-property-decorator'
import {} from '@/api/vip' //接口

@Component({
  components: {}
})
export default class CentetDetail extends Vue {
  private detailtype: any = '' //  type: '', //1-公司 2 个人
  private remark: any = '' //
  private detailInfo: any = '' //
  //详情数据
  created() {
    this.detailtype = this.$route.params.type
    // this.getList()
  }
  //获取详情
  // private async getList() {
  //   getApprovalDetail({ id: Number(this.$route.params.id) })
  //     .then((res) => {
  //       this.proDetail = res.data.data
  //     }).finally(() => {})
  // }
  // 获取风控意见
  getRemark(v: any) {
    this.remark = v
  }

  // 审核
  // approvalEvt(flag: any) {
  //     if (!this.remark) {
  //       this.$message.warning('请填写风控意见')
  //       return
  //     }
  //   }
  //   const approvalFrom: any = {}
  //   doneApproval(approvalFrom).then((res) => {
  //       console.log(res.data)
  //       if (res.data.code == 1000) {
  //         this.$message.success('审核成功')
  //         setTimeout(() => {
  //           this.$router.push({ name: 'VipCheckList' })
  //         }, 1500)
  //       }
  //     }).finally(() => {})
  // }
}
