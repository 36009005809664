
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({})
export default class RatingResult extends Vue {
  @Prop() private info!: any

  get ratingRes() {
    let obj = {
      ...this.info.companyCreditRateRecord
    }
    this.info.companyCreditIDResponses.map((item: any) => {
      let map = ['偿债能力指标', '获利能力指标', '经营管理指标', '履约指标', '发展能力和潜力']
      let index = map.indexOf(item.creditGroup)
      obj[`creditGroup${index}`] = item.creditGrade
      // obj[item.creditGroup] = item.creditGrade
    })
    return obj
  }
}
