var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "approval-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTitle,
              expression: "showTitle"
            }
          ],
          staticClass: "title"
        },
        [_c("span", [_vm._v("审批信息")])]
      ),
      _vm.info
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _vm.showTimeline
                ? _c(
                    "el-timeline",
                    _vm._l(_vm.info.approvalInfos, function(item, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: {
                            icon: _vm.icon[item.status],
                            type: _vm.iconType[item.status],
                            size: "large",
                            hideTimestamp: true
                          }
                        },
                        [
                          _c("div", { staticClass: "row-one" }, [
                            _c("span", { staticClass: "Name" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            item.status != 0
                              ? _c("span", { staticClass: "approval-time" }, [
                                  _vm._v(_vm._s(item.approvalTime))
                                ])
                              : _vm._e(),
                            item.status == 1
                              ? _c("span", { staticClass: "succ" }, [
                                  _vm._v("同意")
                                ])
                              : _vm._e(),
                            item.status == 2
                              ? _c("span", { staticClass: "err" }, [
                                  _vm._v("拒绝")
                                ])
                              : _vm._e()
                          ]),
                          item.status != 0
                            ? _c("div", { staticClass: "row-two" }, [
                                _c("span", [_vm._v("审批意见：")]),
                                _vm.oldType
                                  ? _c("span", [_vm._v(_vm._s(item.remark))])
                                  : _c("span", [_vm._v(_vm._s(item.opinion))])
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.isRemarkShow && _vm.processStatus == 0
                ? _c(
                    "div",
                    { staticClass: "approval-remark edit-item" },
                    [
                      _c("span", [_vm._v("审批意见：")]),
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          "show-word-limit": "",
                          type: "textarea",
                          resize: "none",
                          autosize: { minRows: 6 }
                        },
                        on: {
                          blur: function($event) {
                            $event.preventDefault()
                            return _vm.changeremark()
                          }
                        },
                        model: {
                          value: _vm.remark,
                          callback: function($$v) {
                            _vm.remark = $$v
                          },
                          expression: "remark"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.transactionAmountShow && _vm.processStatus == 0
                ? _c(
                    "div",
                    { staticClass: "approval-remark edit-item padding-t" },
                    [
                      _c("span", [_vm._v("交易服务费（元）：")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { size: "small" },
                        on: {
                          blur: function($event) {
                            $event.preventDefault()
                            return _vm.changeTransactionAmount()
                          }
                        },
                        model: {
                          value: _vm.transactionAmount,
                          callback: function($$v) {
                            _vm.transactionAmount = $$v
                          },
                          expression: "transactionAmount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.transactionAmountShow && _vm.processStatus == 0
                ? _c(
                    "div",
                    { staticClass: "approval-remark edit-item padding-t" },
                    [
                      _c("span", [_vm._v("成交价款（元）：")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { size: "small" },
                        on: {
                          blur: function($event) {
                            $event.preventDefault()
                            return _vm.changeTransactionPrice()
                          }
                        },
                        model: {
                          value: _vm.transactionPrice,
                          callback: function($$v) {
                            _vm.transactionPrice = $$v
                          },
                          expression: "transactionPrice"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.qualificationsShow && _vm.processStatus == 0
                ? _c("div", { staticClass: "upload-box" }, [
                    _c("span", [_vm._v("资格参与通知书：")]),
                    _c(
                      "div",
                      [
                        _vm.qualificationsEdit
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ],
                                staticClass: "upload-btn",
                                on: { click: _vm.activeUpload }
                              },
                              [_vm._v("+ 上传文件")]
                            )
                          : _vm._e(),
                        _c("upload-base", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: {
                            showSuccessTip: false,
                            id: "appinfo-files-btn",
                            listShow: false,
                            uploadOption: _vm.uploadOption
                          },
                          on: {
                            fileUrl: _vm.getFileUrl,
                            beforeUpFn: _vm.beforeUpFn,
                            uploadError: _vm.uploadError
                          }
                        }),
                        _vm._l(_vm.fileList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "file-list" },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    href: item.fileUrl,
                                    target: "_blank",
                                    underline: false
                                  }
                                },
                                [_vm._v(_vm._s(item.fileName))]
                              ),
                              _vm.qualificationsEdit
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteFile(index)
                                        }
                                      }
                                    },
                                    [_vm._v("x")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm.selectPay && _vm.processStatus == 0
                ? _c("div", { staticClass: "select-pay" }, [
                    _c(
                      "div",
                      { staticClass: "edit-item select-bank" },
                      [
                        _c("span", [_vm._v("付款银行卡：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择一张银行卡",
                              size: "small"
                            },
                            on: { change: _vm.selecttedbankCard },
                            model: {
                              value: _vm.bankCard,
                              callback: function($$v) {
                                _vm.bankCard = $$v
                              },
                              expression: "bankCard"
                            }
                          },
                          _vm._l(_vm.bankCardOptions, function(ite, ind) {
                            return _c("el-option", {
                              key: ind,
                              attrs: {
                                label: _vm.getBankCardIdTail(
                                  ite.bankName,
                                  ite.bankCard
                                ),
                                value: ind
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "edit-item select-cost" },
                      [
                        _c("span", [_vm._v("费项：")]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择费项", size: "small" },
                            on: { change: _vm.selecttedResult },
                            model: {
                              value: _vm.costItem,
                              callback: function($$v) {
                                _vm.costItem = $$v
                              },
                              expression: "costItem"
                            }
                          },
                          _vm._l(_vm.getFeedItems(_vm.bankCard), function(
                            ite,
                            ind
                          ) {
                            return _c("el-option", {
                              key: ind,
                              attrs: { label: ite.feeItem, value: ind }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.selectPay && _vm.processStatus == 0
                ? _c(
                    "div",
                    { staticClass: "approval-remark edit-item remarks" },
                    [
                      _c("span", [_vm._v("备注：")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          resize: "none",
                          autosize: { minRows: 6 }
                        },
                        on: {
                          blur: function($event) {
                            $event.preventDefault()
                            return _vm.changeRemarks()
                          }
                        },
                        model: {
                          value: _vm.remarks,
                          callback: function($$v) {
                            _vm.remarks = $$v
                          },
                          expression: "remarks"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.info.ruleType &&
              _vm.info.ruleType == "7" &&
              _vm.isOpenBidNotice &&
              _vm.processStatus == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "approval-remark edit-item padding-t" },
                        [
                          _c("span", [_vm._v("项目公告：")]),
                          _vm.projectDetailShow
                            ? _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-left": "5%" },
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.info.isShowProject,
                                    callback: function($$v) {
                                      _vm.$set(_vm.info, "isShowProject", $$v)
                                    },
                                    expression: "info.isShowProject"
                                  }
                                },
                                [_vm._v("自定义")]
                              )
                            : _vm._e(),
                          _vm.projectDetailShow
                            ? _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.info.isShowProject,
                                    callback: function($$v) {
                                      _vm.$set(_vm.info, "isShowProject", $$v)
                                    },
                                    expression: "info.isShowProject"
                                  }
                                },
                                [_vm._v("选择链接")]
                              )
                            : _vm._e(),
                          !_vm.projectDetailShow
                            ? _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-left": "5%" },
                                  attrs: { disabled: "", label: 1 },
                                  model: {
                                    value: _vm.info.isShowProject,
                                    callback: function($$v) {
                                      _vm.$set(_vm.info, "isShowProject", $$v)
                                    },
                                    expression: "info.isShowProject"
                                  }
                                },
                                [_vm._v("自定义")]
                              )
                            : _vm._e(),
                          !_vm.projectDetailShow
                            ? _c(
                                "el-radio",
                                {
                                  attrs: { disabled: "", label: 2 },
                                  model: {
                                    value: _vm.info.isShowProject,
                                    callback: function($$v) {
                                      _vm.$set(_vm.info, "isShowProject", $$v)
                                    },
                                    expression: "info.isShowProject"
                                  }
                                },
                                [_vm._v("选择链接")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.info.isShowProject == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "approval-remark edit-item padding-t"
                            },
                            [
                              _c("span", [_vm._v("项目公告链接：")]),
                              !_vm.info.projectName
                                ? _c("el-select", {
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      focus: function($event) {
                                        return _vm.projectEvt()
                                      }
                                    },
                                    model: {
                                      value: _vm.projectName,
                                      callback: function($$v) {
                                        _vm.projectName = $$v
                                      },
                                      expression: "projectName"
                                    }
                                  })
                                : _vm._e(),
                              _vm.info.projectName
                                ? _c("el-select", {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.info.projectName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.info, "projectName", $$v)
                                      },
                                      expression: "info.projectName"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.info.bidNotice.bidNoticDetail, function(
                        ite,
                        ind
                      ) {
                        return _vm.info.isShowProject == 1
                          ? _c(
                              "div",
                              {
                                key: "edit" + ind,
                                staticClass:
                                  "approval-remark edit-item padding-t"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(ite.labelName) + "：")
                                ]),
                                !_vm.isRemarkShow
                                  ? _c("div", [
                                      _c("div", {
                                        staticClass: "text-box",
                                        domProps: {
                                          innerHTML: _vm._s(ite.value)
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm.isRemarkShow
                                  ? _c(
                                      "div",
                                      { staticClass: "quill-text" },
                                      [
                                        _c("vue-ueditor-wrap", {
                                          attrs: { config: _vm.myConfig },
                                          model: {
                                            value: ite.value,
                                            callback: function($$v) {
                                              _vm.$set(ite, "value", $$v)
                                            },
                                            expression: "ite.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "项目公告",
                    visible: _vm.projectShow,
                    width: "80%"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.projectShow = $event
                    }
                  }
                },
                [
                  _c("project-info", {
                    on: {
                      projectId: _vm.getProjectId,
                      projectName: _vm.getProjectName
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }