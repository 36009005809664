var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("挂牌申请人")]),
              _c("span", [_vm._v(_vm._s(_vm.info.zctUserName))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("联系电话")]),
              _c("span", [_vm._v(_vm._s(_vm.info.zctUserPhone))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("标的名称")]),
              _c(
                "span",
                { staticClass: "a-span", on: { click: _vm.intoDetails } },
                [_vm._v(_vm._s(_vm.info.listingApplyName))]
              )
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("所属区域")]),
              _c("span", [_vm._v(_vm._s(_vm.info.areaConfigName))])
            ]),
            _vm.openStatus
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("保证金状态")]),
                  _c("span", [_vm._v(_vm._s(_vm.filteStatus))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }