var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: { title: "注销会员", visible: _vm.show, width: "60%", center: "" },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialog-title" }, [
        _c("i", { staticClass: "el-icon-warning" }),
        _vm._v(" 确认注销该用户的 "),
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("投资类/非投资类")
        ]),
        _vm._v("会员吗？")
      ]),
      _c(
        "div",
        { staticClass: "flexs" },
        [
          _c("div", [_vm._v("情况说明")]),
          _c("el-input", {
            staticClass: "area",
            attrs: { type: "textarea", autosize: { minRows: 6 } },
            model: {
              value: _vm.remark,
              callback: function($$v) {
                _vm.remark = $$v
              },
              expression: "remark"
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.show = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.diaSure } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }