
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getCompanyCreditList } from '@/api/enterpriseCreditRating'
import { sessionStore } from '@/utils/data-management'

@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class RatingList extends Vue {
  private loadingTxt: string = loadingText
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading: boolean = false
  private creditCode: any = ''

  created() {
    this.getList()
  }

  private getList() {
    this.loading = true
    let params = {
      creditCode: this.$route.params.creditCode || sessionStore.get('creditCode')
    }
    if (sessionStore.get('creditCode')) {
      sessionStore.remove('creditCode')
    }
    this.creditCode = params.creditCode
    getCompanyCreditList(params)
      .then((res: any) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetail(id: any) {
    sessionStore.set('creditCode', this.creditCode)
    this.$router.push({
      name: 'RatingDetail',
      params: {
        id: id
      }
    })
  }
}
