var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "subject-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("标的物所属主体信息")]),
        _vm.checkboxShow && _vm.processStatus === 0
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("主体身份")]),
              _c("span", [_vm._v(_vm._s(_vm.getIdentityType))])
            ]),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      ["企业", "组织"].indexOf(_vm.getIdentityType) > -1
                        ? _vm.getIdentityType + "名称"
                        : _vm.getIdentityType == "个人"
                        ? "姓名"
                        : "名称"
                    )
                  )
                ]),
                _vm.checkboxShow && _vm.processStatus === 0
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.name,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "name", $$v)
                        },
                        expression: "itemStatus.name"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.name.value))])
              ],
              1
            ),
            _vm.getIdentityType == "企业"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("统一信用代码")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.creditCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "creditCode", $$v)
                            },
                            expression: "itemStatus.creditCode"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.creditCode.value))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.getIdentityType == "企业"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("注册地址")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.registAddrss,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "registAddrss", $$v)
                            },
                            expression: "itemStatus.registAddrss"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.registAddrss.value))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.getIdentityType == "企业"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("法定代表人")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.legalName,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "legalName", $$v)
                            },
                            expression: "itemStatus.legalName"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.legalName.value))])
                  ],
                  1
                )
              : _vm._e(),
            ["企业", "组织"].indexOf(_vm.getIdentityType) > -1
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("联系人")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.contactPerson,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "contactPerson", $$v)
                            },
                            expression: "itemStatus.contactPerson"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.contactPerson.value))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.getIdentityType == "个人"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("身份证号")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.idNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "idNumber", $$v)
                            },
                            expression: "itemStatus.idNumber"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.idNumber.value))])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系电话")]),
                _vm.checkboxShow && _vm.processStatus === 0
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.contactNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "contactNumber", $$v)
                        },
                        expression: "itemStatus.contactNumber"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.contactNumber.value))])
              ],
              1
            ),
            ["企业", "组织"].indexOf(_vm.getIdentityType) > -1
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("联系地址")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.contactAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "contactAddress", $$v)
                            },
                            expression: "itemStatus.contactAddress"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.contactAddress.value))])
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }