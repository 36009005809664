
import timeFormat from '@/utils/timeFormat'
import { Component, Vue } from 'vue-property-decorator'
import basicInfo from '../components/basic-info.vue'
import newPayInfo from '../components/new-pay-info.vue'
import newBasicInfo from '../components/new-basic-info.vue'
import applicantInfo from '../components/applicant-info.vue'
import liquidationBasicInfo from '../components/liquidation-basic-info.vue'
import bondInfo from '../components/bond-info.vue'
import liquidationInfo from '../components/liquidation-info.vue'
import markInfo from '../components/mark-info.vue'
import needData from '../components/need-data.vue'
import approvalInfo from '../components/approval-info.vue'
import simpleApprovalInfo from '../components/simple-approval-info.vue'
import subjectInfo from '../components/subject-info.vue'
import biddingAnnouncement from '../components/bidding-announcement.vue'
import bidderInfo from '../components/bidder-info.vue'
import QrCode from '@/components/QrCode.vue'
import { getMyApprovalDetail, actionApproval, listingApproval, discontinueApproval, liquidationDetail, searchPayeeInfo, liquidationApproval, getBankAndCost, getIsLastPerson, marginManagementList, marginManagementDetails, bondApprovalDetail, bondApproval, qualificationApproval } from '@/api/countrysidePropertyTransaction'
import { floatAdd, floatMul, floatDiv } from '@/utils/basicOperation'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    basicInfo,
    newBasicInfo,
    newPayInfo,
    liquidationBasicInfo,
    bondInfo,
    applicantInfo,
    liquidationInfo,
    markInfo,
    needData,
    approvalInfo,
    simpleApprovalInfo,
    subjectInfo,
    biddingAnnouncement,
    bidderInfo,
    QrCode
  }
})
export default class myApprovalDetail extends Vue {
  private validatePrice: any = (rule, value, callback) => {
    let reg = /^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/
    if (!value) {
      callback(new Error('必填项不能为空'))
    } else if (!reg.test(value)) {
      callback(new Error('请输入正确格式的金额'))
    } else {
      callback()
    }
  }
  private unitNumOpiton: any = []
  private applFileList: any = [] // 资格参与通知书
  private loadingTxt: string = loadingText
  private listingMainPartStatus: any = {
    name: false,
    creditCode: false,
    registAddrss: false,
    legalName: false,
    contactPerson: false,
    idNumber: false,
    contactNumber: false,
    contactAddress: false
  }

  private loading: boolean = true // 主loading
  private bondLoading: boolean = false // 保证金loading
  private depositInfos: any = null // 保证金列表
  // 保证金分页数据
  private bondPaginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private isAgee: any = 1 // 审批状态
  private disableList: any = [] // 清算列表禁用选项
  private oldRecord: any = null // 记录数据
  private bondBasic: any = null // 保证金基本信息
  private newBondBasic: any = null // 摘牌审核基本信息
  private routeParams: any = {}
  private activeName: any = '4' // 当前 tab 选项
  private proDetail: any = {}
  private qrDiaShow: boolean = false // 验证码展示
  // private fileDisabled: any = [] // 固定文件禁用状态
  // private otherFileDisabled: any = [] // 其它文件禁用状态
  private imgListStatus: any = [] // 标的图勾选列表
  private customStatus: any = []
  private needFilesStatus: any = [] // 所需文件勾选列表
  private otherFileStatus: any = [] // 其它文件勾选列表
  private bidNoticeStatus: any = [] // 竞买公告勾选列表
  // 竞买人勾选列表
  private bidderInfoStatus: any = {
    userType: false,
    legalPerson: false,
    enterpriseName: false,
    creditCode: false,
    registAddress: false,
    name: false,
    identityCode: false,
    contactAddress: false,
    contactPhone: false
  }
  private markShow: boolean = false // 弹窗显示标志位
  private ruleType: string = '' // 当前审批类型
  private checkboxShow: boolean = false // 是否打开选择项
  private time: any = '' // 时间
  private applyTime: string[] = [] //报名时间
  // 弹窗数据
  private markInfo: any = {
    holdArea: '',
    bidType: 0,
    listingPrice: '',
    tranferEnsureAmount: '',
    auctionInterval: '',
    endTime: '',
    isShowBidInfo: 1,
    isShowBidder: 2,
    number: '',
    unitTime: '',
    unitNum: '',
    paymentMethod: '',
    delayedCycle: 7
  }
  private bidNotice: any = null
  private isRefuse: boolean = false // 拒绝开关
  private markStatus: any // 标状态
  private isGetStatus: boolean = false // 是否获取了标状态
  private remark: string = '' // 审批意见
  private remarks: string = '' // 备注
  private transactionAmount: any //交易服务费
  private transactionPrice: any //成交价款
  private projectId: string = ''
  private pickerOptions: any = {
    // 限制日期选择器不让选择今天以前的
    disabledDate(time: any) {
      return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
    }
  }
  private bankCardOptions: any = [] // 银行卡参数
  private bankCard: any = '' // 银行卡id
  private costItem: any = '' // 费项id
  private approvalInfo: any = {}
  // 去审批
  prjMarginDiaShow = false
  approvalInfoData: any = null
  bondLoading2 = false
  chooseListPersonName = ""
  chooseList = []
  
  // 获取proDetail长度
  get getProLength() {
    return Object.keys(this.proDetail).length
  }
  get processStatus() {
    return this.$route.query.processStatus
  }
  get markHeight() {
    return this.proDetail.tranferType == '1' ? '450px' : '280px'
  }
  get areaUnit() {
    let info: any = this.proDetail.bidInfo
    return info && info.holdAreaUnit && info.holdAreaUnit.value ? info.holdAreaUnit.value : ''
  }
  //多选框
  selectable(row, index) {
    if(row.payStatus == 5){
      return true
    }
    return false
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      if (from.name == 'CountrysideProjectDetail') {
        let nowP: any = sessionStorage.getItem('myApprovalDetailP')
        nowP = JSON.parse(nowP)
        vim.oldRecord = nowP
        vim.remark = vim.oldRecord.remark
      }
      vim.routeParams = vim.$route.query

      if (vim.routeParams.approvalId) {
        sessionStorage.setItem('myApprovalDetail', JSON.stringify(vim.routeParams))
      } else {
        let routeParams: any = sessionStorage.getItem('myApprovalDetail')
        routeParams = JSON.parse(routeParams)
        vim.routeParams = routeParams
      }
      vim.ruleType = vim.routeParams.ruleType
      if (vim.ruleType == '9' || vim.ruleType == '12' || vim.ruleType == '16') {
        vim.activeName = '1'
      }
      if (vim.ruleType == '13') {
        vim.activeName = '6'
        vim.checkboxShow = true
      }
      if (vim.ruleType == '7') {
        vim.checkboxShow = true
      }
      if (vim.ruleType == '16') {
        vim.checkboxShow = true
      }
      if (vim.ruleType == '12') {
        vim.getLquidation()
      } else if (vim.ruleType == '9') {
        vim.getBondDetail()
      } else {
        vim.getList()
      }
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name != 'CountrysideProjectDetail') {
      // 当离开组件时，是否允许离开
      sessionStorage.removeItem('myApprovalDetail')
    } else {
      let pageDate: any = {
        remark: this.remark,
        paginationParam: this.bondPaginationParam
      }
      if (this.proDetail.lastPerson) {
        pageDate.remarks = this.remarks
        pageDate.bankCard = this.bankCard
        pageDate.costItem = this.costItem
        pageDate.bankCardOptions = this.bankCardOptions
      }
      if (this.ruleType == '12') {
        pageDate.payeeList = this.proDetail.payeeList
        pageDate.liquidationApply = this.proDetail.liquidationApply
      }
      sessionStorage.setItem('myApprovalDetailP', JSON.stringify(pageDate))
    }
    next()
  }
  // 获取审批详情
  private getApprovalData() {
    this.bondLoading2 = true
    bondApprovalDetail({
      listingApplyId: this.routeParams.listingApplyId
    }).then((res) => {
      if (res.data.data) {
        this.approvalInfo = res.data.data
      }
    }).finally(() => {
      this.bondLoading2 = false
    })
  }
  private cardSelected(card: any, cost: any) {
    this.bankCard = card
    this.costItem = cost
  }
  // 获取项目id
  private getProjectId(projectId: string) {
    this.projectId = projectId.trim()
  }
  // 获取审批意见
  private getRemark(remark: string) {
    this.remark = remark.trim()
  }
  // 获取备注
  private getRemarks(remarks: string) {
    this.remarks = remarks.trim()
  }
  private getTransactionAmount(transactionAmount: string) {
    this.transactionAmount = transactionAmount.trim()
  }
  private getTransactionPrice(transactionPrice: string) {
    this.transactionPrice = transactionPrice.trim()
  }
  // 获取标信息选择框状态
  private getMarkStatus(val: any) {
    this.isGetStatus = true
    this.markStatus = val
  }
  // 限制输入
  private changeInput(value: any, liquidationAmount: any, type: any) {
    let returnVal: any = ''
    if (!value && value !== 0) {
      return
    }
    if (type == 1) {
      returnVal = floatMul(floatDiv(value, 100), liquidationAmount).toFixed(2)
    } else {
      returnVal = parseFloat(value).toFixed(2)
    }
    return returnVal
  }
  private async filtePayeeList(list: any, listingParty: any, type: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let returnArr: any = [],
        firstItem: any = null
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == 2) {
            firstItem = list[i]
          } else {
            returnArr.push(list[i])
          }
        }
        if (!firstItem) {
          firstItem = {
            payeeNature: '挂牌方',
            type: 2,
            payee: listingParty,
            openBank: '',
            bankAccount: '',
            amountScale: '',
            amount: ''
          }
          await searchPayeeInfo({
            payee: listingParty
          }).then((res) => {
            let datas: any = res.data.data
            if (datas) {
              datas.openBank && (firstItem.openBank = datas.openBank)
              datas.bankAccount && (firstItem.bankAccount = datas.bankAccount)
              datas.amountScale && (firstItem.amountScale = datas.amountScale)
              datas.amountScale && listingParty && (firstItem.amount = this.changeInput(datas.amountScale, listingParty, type))
            }
          })
        }
        returnArr.unshift(firstItem)
      }
      resolve(returnArr)
    })
  }
  // 清算详情
  private getLquidation() {
    liquidationDetail({
      id: this.routeParams.id
    })
      .then(async (res) => {
        if (res.data.data) {
          this.proDetail = res.data.data
          if (this.oldRecord) {
            this.proDetail.payeeList = this.oldRecord.payeeList
            this.proDetail.liquidationApply = this.oldRecord.liquidationApply
          } else {
            !this.proDetail.liquidationApply &&
              (this.proDetail.liquidationApply = {
                id: this.proDetail.id,
                type: 1,
                liquidationAmount: this.proDetail.transactionAmount,
                status: this.proDetail.status
              })
            await this.filtePayeeList(this.proDetail.payeeList, this.proDetail.listingParty, this.proDetail.liquidationApply.type).then((res: any) => {
              this.proDetail.payeeList = res
            })
          }
          if (this.proDetail.payeeList) {
            for (let i = 0; i < this.proDetail.payeeList.length; i++) {
              this.disableList.push(false)
            }
          }
          await getIsLastPerson({ approvalId: this.routeParams.approvalId })
            .then((res) => {
              if (res.data.data) {
                this.proDetail.lastPerson = res.data.data.lastPerson
                if (this.proDetail.lastPerson) {
                  this.oldRecord && (this.remarks = this.oldRecord.remarks)
                  if (this.oldRecord && this.oldRecord.bankCardOptions && this.oldRecord.bankCardOptions.length) {
                    this.bankCardOptions = this.oldRecord.bankCardOptions
                    this.bankCard = this.oldRecord.bankCard
                    this.costItem = this.oldRecord.costItem
                  } else {
                    getBankAndCost().then((res) => {
                      if (res.data.data) {
                        this.bankCardOptions = res.data.data
                      }
                    })
                  }
                }
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
  // 保证金详情
  private getBondDetail() {
    marginManagementList({
      listingApplyId: this.routeParams.id,
      pageNumber: 1,
      pageSize: 10
    })
      .then(async (res) => {
        let dataList: any = res.data.data
        if (dataList && dataList.length) {
          this.bondBasic = dataList[0]
          if (this.oldRecord && this.oldRecord.paginationParam) {
            this.bondPaginationParam = this.oldRecord.paginationParam
          }
          this.searchBondList()
          this.bondLoading2 = true
          await bondApprovalDetail({
            listingApplyId: this.routeParams.id
          }).then((res) => {
            this.bondLoading2 = false
            let datas: any = res.data.data
            if (datas) {
              datas.approvalRecordStatuses && this.$set(this.proDetail, 'approvalInfos', datas.approvalRecordStatuses)
              datas.isEnd && (this.proDetail.lastPerson = datas.isEnd)
              if (datas.isEnd) {
                if (this.oldRecord && this.oldRecord.bankCardOptions && this.oldRecord.bankCardOptions.length) {
                  this.bankCardOptions = this.oldRecord.bankCardOptions
                  this.bankCard = this.oldRecord.bankCard
                  this.costItem = this.oldRecord.costItem
                } else {
                  getBankAndCost().then((res) => {
                    if (res.data.data) {
                      this.bankCardOptions = res.data.data
                    }
                  })
                }
              }
            }
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 获取保证金列表
  private searchBondList() {
    this.bondLoading = true
    marginManagementDetails({
      listingApplyId: this.routeParams.id,
      pageNumber: this.bondPaginationParam.pageIndex,
      pageSize: this.bondPaginationParam.pageSize
    })
      .then((res) => {
        if (res.data.data) {
          this.depositInfos = res.data.data
          this.bondPaginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.bondLoading = false
      })
  }
  // 获取详情
  private getList() {
    getMyApprovalDetail({ approvalId: this.routeParams.approvalId })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          if (this.ruleType == '7') {
            let needFiles = datas.files,
              otherFileList = datas.otherFileList,
              imgList = datas.imgList
            if (needFiles) {
              for (let i = 0; i < needFiles.length; i++) {
                this.needFilesStatus.push(false)
                // this.fileDisabled.push(false)
              }
            }
            if (otherFileList) {
              for (let i = 0; i < otherFileList.length; i++) {
                this.otherFileStatus.push(false)
                // this.otherFileDisabled.push(false)
              }
            }
            if (imgList) {
              for (let i = 0; i < imgList.length; i++) {
                this.imgListStatus.push(false)
              }
            }
            let type: any = datas.businessType
            if (type != '1' && type != '2' && datas.customFields && datas.customFields.length) {
              for (let i = 0; i < datas.customFields.length; i++) {
                this.customStatus.push(false)
              }
            }
            if (type == '7' && datas.bidNotice && datas.bidNotice.bidNoticDetail) {
              for (let i = 0; i < datas.bidNotice.bidNoticDetail.length; i++) {
                this.bidNoticeStatus.push(false)
              }
            }
          }
          if (this.ruleType == '13') {
            let needFiles = datas.delistingFiles,
              otherFileList = datas.delistingOtherFiles
            if (needFiles) {
              for (let i = 0; i < needFiles.length; i++) {
                this.needFilesStatus.push(false)
              }
            }
            if (otherFileList) {
              for (let i = 0; i < otherFileList.length; i++) {
                this.otherFileStatus.push(false)
              }
            }
          }
          // 摘牌审批基本信息
          if (this.ruleType == '16') {
            this.newBondBasic = {
              listingApplyName: datas.listingApplyName,
              businessTypeDesc: datas.businessTypeDesc,
              businessType: datas.businessType,
              tranferType: datas.tranferType,
              listingApplyId: datas.listingApplyId,
              amount: datas.amount
            }
          }
          this.proDetail = datas
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private liquidationApprovalFn(type: any) {
    let params: any = {
      approvalId: this.routeParams.approvalId,
      isAgree: type,
      remark: this.remark
    }
    if (this.proDetail.lastPerson && type == 1) {
      let bankObj: any = this.bankCardOptions[this.bankCard]
      params.bankRemark = this.remarks
      params.bankCard = bankObj.bankCard
      params.bankName = bankObj.bankName
      params.bankCode = bankObj.bankCode
      params.feeItem = bankObj.bussinessRels[this.costItem].feeItem
      params.feeCode = bankObj.bussinessRels[this.costItem].feeCode
      params.contractCode = bankObj.bussinessRels[this.costItem].contractCode
      params.accountName = bankObj.accountName
      // params.type = this.proDetail.liquidationApply.type
      // params.payeeList = this.proDetail.payeeList
    }
    params.type = this.proDetail.liquidationApply.type
    params.payeeList = this.proDetail.payeeList
    liquidationApproval(params)
      .then(() => {
        this.$message.success('提交成功')
        this.$router.push({ name: 'MyApproval' })
      })
      .finally(() => {
        this.loading = false
      })
  }
  private bondApprovalFn(type: any) {
    //保证金审批
    if (this.chooseList.length <= 0 && this.ruleType == '9') {
      this.$message.error('请勾选选项！')
      this.activeName = '2'
      return
    }

    let params: any = {
      approvalId: this.routeParams.approvalId,
      isAgree: type,
      opinion: this.remark
    }
    if (this.proDetail.lastPerson && type == 1) {
      let bankObj: any = this.bankCardOptions[this.bankCard]
      params.mark = this.remarks
      params.bank = bankObj.id
      params.type = bankObj.bussinessRels[this.costItem].id

    }
    //保证金审批
    if (this.chooseList.length > 0 && this.ruleType == '9') {
      params.flowIdList = this.chooseList.map(item=>{
        return item.delistingAmountFlowId
      })
    }
    console.log(params);
    bondApproval(params)
      .then(() => {
        this.$message.success('提交成功')
        this.$router.push({ name: 'MyApproval' })
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 同意（1），拒绝（2）
  private approvalEvt(type: any) {
    if (!(type == '1' && this.proDetail.lastPerson && (this.ruleType == '7' || this.ruleType == '9' || this.ruleType == '12' || this.ruleType == '16'))) {
      this.loading = true
    }
    if (type == '2') {
      if (!this.remark) {
        this.$message({
          message: '请填写审批意见',
          type: 'error'
        })
        this.loading = false
        return
      }
    }
    if (this.ruleType == '8') {
      discontinueApproval({
        approvalId: this.routeParams.approvalId,
        remark: this.remark,
        isAgree: type
      })
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .catch((res) => {
          if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .finally(() => {
          this.loading = false
        })
      return
    }
    // 清算同意进入
    if (this.ruleType == '12' && type == '1' && this.proDetail.lastPerson) {
      // 清算信息
      let payeeList: any = this.proDetail.payeeList,
        liquidationApply: any = this.proDetail.liquidationApply,
        // 错误标志
        isError: boolean = false
      if (payeeList && liquidationApply.liquidationAmount) {
        // 总计值
        let total: number = 0
        // 遍历收款人
        for (let i = 0; i < payeeList.length; i++) {
          // 遍历收款人字段
          for (let k in payeeList[i]) {
            // 非必填跳过
            if (['id', 'status', 'liquidationApplyId', 'type'].indexOf(k) > -1) {
              continue
              // 必填为空结束提示
            } else if ([null, undefined, ''].indexOf(payeeList[i][k]) > -1) {
              this.$message({
                message: '清算信息有字段没有填写',
                type: 'error'
              })
              return
            } else if (k == 'amountScale' && payeeList[i][k] == 0) {
              this.$message({
                message: '清算的比例/金额不能为0',
                type: 'error'
              })
              return
              // 累加收款总计
            } else if (k == 'amountScale') {
              let amountScale: any = payeeList[i][k]
              if (amountScale !== null && amountScale + '') {
                total = floatAdd(total, amountScale)
              }
            }
          }
        }
        // 根据收款提示判断总计对否
        if (liquidationApply.type == 1) {
          if (total != 100) {
            isError = true
          }
        } else if (liquidationApply.type == 2) {
          if (total != liquidationApply.liquidationAmount) {
            isError = true
          }
        }
        // 总计有误结束提示
        if (isError) {
          let errorTitle: string = ''
          errorTitle = liquidationApply.type == 1 ? '清算信息的收款方的金额比例和必须为100%' : '清算信息的收款方的金额和必须等于清算总金额'
          this.$message({
            message: errorTitle,
            type: 'error'
          })
          return
        }
      } else {
        this.$message({
          message: '清算信息为空',
          type: 'error'
        })
        return
      }
    }
    if (this.ruleType == '12' && (type == '2' || (type == '1' && !this.proDetail.lastPerson))) {
      this.liquidationApprovalFn(type)
      return
    }
    //如果是保证金审批需判断勾选个人
    if (this.chooseList.length <= 0 && this.ruleType == '9') {
        this.$message.error('请勾选选项！')
        this.activeName = '2'
        return
      }
    if (this.ruleType == '9' && type == '1' && !this.proDetail.lastPerson) {
      this.bondApprovalFn(type)
      return
    }
    if ((this.ruleType == '9' || this.ruleType == '12') && type == '1' && this.proDetail.lastPerson) {
      if ((!this.bankCard && this.bankCard !== 0) || (!this.costItem && this.costItem !== 0)) {
        this.$message({
          message: '请选择银行卡和费项',
          type: 'error'
        })
        return
      }
      this.isAgee = type
      this.qrDiaShow = true
      return
    } else if (this.ruleType == '9' && type == '2') {
      this.bondApprovalFn(type)
      return
    }
    if (this.ruleType == '13' || this.ruleType == '16') {
      let params: any = {
        approvalId: this.routeParams.approvalId,
        remark: this.remark,
        isAgree: type
      }
      if (type == '2') {
        params.selectList = []
        let isRefused: boolean = false,
          objData = this.proDetail
        for (let i = 0; i < this.needFilesStatus.length; i++) {
          objData.delistingFiles[i].flag = !this.needFilesStatus[i]
          if (!isRefused && this.needFilesStatus[i]) {
            isRefused = true
          }
        }
        for (let i = 0; i < this.otherFileStatus.length; i++) {
          objData.delistingOtherFiles[i].flag = !this.otherFileStatus[i]
          if (!isRefused && this.otherFileStatus[i]) {
            isRefused = true
          }
        }
        for (let key in this.bidderInfoStatus) {
          if (this.bidderInfoStatus[key]) {
            params.selectList.push(key)
            !isRefused && (isRefused = true)
          }
        }
        if (!isRefused) {
          this.$message({
            message: '请勾选拒绝项',
            type: 'error'
          })
          this.loading = false
          return
        }
        params.delistingFiles = objData.delistingFiles ? objData.delistingFiles : []
        params.delistingOtherFiles = objData.delistingOtherFiles ? objData.delistingOtherFiles : []
      } else {
        if (this.proDetail.lastPerson && this.proDetail.businessType == '7') {
          if (!this.applFileList.length) {
            this.$message({
              message: '请上传资格参与通知书',
              type: 'error'
            })
            this.loading = false
            return
          }
          params.qualificationFile = this.applFileList
        }
        if (this.proDetail.lastPerson && this.proDetail.businessType != '7' && this.proDetail.tranferType != '1') {
          if (!this.transactionAmount) {
            this.$message({
              message: '请输入交易服务费',
              type: 'error'
            })
            this.loading = false
            return
          } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2}))|0)$/.test(this.transactionAmount)) {
            this.$message({
              message: '请输入正确的交易服务费，最多保留2位小数',
              type: 'error'
            })
            this.loading = false
            return
          }
          params.transactionAmount = this.transactionAmount
          if (!this.transactionPrice) {
            this.$message({
              message: '请输入成交价款',
              type: 'error'
            })
            this.loading = false
            return
          } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2}))|0)$/.test(this.transactionPrice)) {
            this.$message({
              message: '请输入正确的成交价款，最多保留2位小数',
              type: 'error'
            })
            this.loading = false
            return
          }
          params.transactionPrice = this.transactionPrice
        }
      }
      if (this.ruleType == '16') {
        actionApproval(params)
          .then(() => {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        qualificationApproval(params)
          .then(() => {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          })
          .finally(() => {
            this.loading = false
          })
      }

      return
    }
    if (type == '1') {
      let bidNotice: any = this.proDetail.bidNotice
      if (this.proDetail.businessType != '7' && bidNotice && bidNotice.bidNoticDetail) {
        this.bidNotice = JSON.parse(JSON.stringify(this.proDetail.bidNotice))
        for (let i = 0; i < bidNotice.bidNoticDetail.length; i++) {
          let nowBid: any = bidNotice.bidNoticDetail[i]
          if (nowBid && nowBid.value) {
            this.bidNotice.bidNoticDetail[i].value = nowBid.value.replace(/<table/g, "<table border='1' bordercolor='#E6E6E6' style='border-collapse:collapse'")
          }
        }
      }
      if (this.proDetail.lastPerson && this.ruleType == '7') {
        let bidInfo: any = this.proDetail.bidInfo ? this.proDetail.bidInfo : {}
        if ([1, 2].indexOf(this.proDetail.businessType) > -1) {
          this.markInfo.holdArea = bidInfo.holdArea && bidInfo.holdArea.value ? bidInfo.holdArea.value + '' : ''
        } else {
          this.markInfo.number = bidInfo.number && bidInfo.number.value ? bidInfo.number.value + '' : ''
        }
        this.markInfo.bidType = bidInfo.bidType.value
        if (bidInfo.unitTime && bidInfo.unitTime.value) {
          this.markInfo.unitTime = bidInfo.unitTime.value
        }
        if (bidInfo.unitNum && bidInfo.unitNum.value) {
          this.markInfo.unitNum = bidInfo.unitNum.value
        }
        if(!bidInfo.unitNum.value || bidInfo.unitNum.value=='无'){
          this.unitNumOpiton = ['无']
        }else {
          this.unitNumOpiton = [bidInfo.unitNum.value,'无']
        }
        if (bidInfo.listingPrice && bidInfo.listingPrice.value) {
          this.markInfo.listingPrice = bidInfo.listingPrice.value + ''
        }
        this.markInfo.paymentMethod = bidInfo.paymentMethod.value
        if(this.proDetail.tranferType == '1'){
          // this.markInfo.endTime = '2037-12-31 23:59:59'
          this.markInfo.endTime = timeFormat(new Date(new Date().getTime()+30*365*24*3600*1000), 'YYYY-MM-DD HH:mm:ss')
        }
        this.markShow = true
      } else if (!this.proDetail.lastPerson && this.ruleType == '7') {
        let params: any = {
          approvalId: this.routeParams.approvalId,
          remark: this.remark,
          projectId: this.projectId,
          isShowProject: this.proDetail.isShowProject,
          isAgree: type
        }
        this.proDetail.businessType != '7' && (params.bidNotice = this.bidNotice)
        listingApproval(params)
          .then((res) => {
            if (res.data.code == 1000) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .catch((res) => {
            if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    } else {
      this.checkboxShow == true && (this.isRefuse = true)
      if (this.isRefuse) {
        let isRefused = false,
          objData = this.proDetail
        for (let i = 0; i < this.needFilesStatus.length; i++) {
          objData.files[i].flag = !this.needFilesStatus[i]
          if (!isRefused && this.needFilesStatus[i]) {
            isRefused = true
          }
        }
        for (let i = 0; i < this.otherFileStatus.length; i++) {
          objData.otherFileList[i].flag = !this.otherFileStatus[i]
          if (!isRefused && this.otherFileStatus[i]) {
            isRefused = true
          }
        }
        for (let i = 0; i < this.imgListStatus.length; i++) {
          objData.imgList[i].flag = !this.imgListStatus[i]
          if (!isRefused && this.imgListStatus[i]) {
            isRefused = true
          }
        }
        for (let i = 0; i < this.customStatus.length; i++) {
          objData.customFields[i].flag = !this.customStatus[i]
          if (!isRefused && this.customStatus[i]) {
            isRefused = true
          }
        }
        if (objData.listingMainPartInfo) {
          for (let key in objData.listingMainPartInfo) {
            objData.listingMainPartInfo[key].flag = !this.listingMainPartStatus[key]
            if (!isRefused && this.listingMainPartStatus[key]) {
              isRefused = true
            }
          }
        }
        if (objData.businessType == '7' && this.bidNoticeStatus.length) {
          for (let i = 0; i < this.bidNoticeStatus.length; i++) {
            objData.bidNotice.bidNoticDetail[i].flag = !this.bidNoticeStatus[i]
            if (!isRefused && this.bidNoticeStatus[i]) {
              isRefused = true
            }
          }
        }
        let timer = setInterval(() => {
          if (this.isGetStatus) {
            this.isGetStatus = false
            this.isRefuse = false
            clearInterval(timer)
            for (let key in this.markStatus) {
              objData.bidInfo[key].flag = !this.markStatus[key]
              if (!isRefused && this.markStatus[key]) {
                isRefused = true
              }
            }
            if (!isRefused) {
              this.$message({
                message: '请勾选拒绝项',
                type: 'error'
              })
              this.loading = false
              return
            }
            let params: any = {
              approvalId: this.routeParams.approvalId,
              remark: this.remark,
              projectId: this.projectId,
              isShowProject: this.proDetail.isShowProject,
              isAgree: type,
              bidInfo: this.proDetail.bidInfo,
              files: this.proDetail.files,
              otherFileList: this.proDetail.otherFileList ? this.proDetail.otherFileList : [],
              imgList: this.proDetail.imgList ? this.proDetail.imgList : [],
              listingMainPartInfo: this.proDetail.listingMainPartInfo
            }
            if (this.proDetail.businessType == '7') {
              params.bidNotice = this.proDetail.bidNotice
            }
            if ([1, 2, 7].indexOf(this.proDetail.businessType) == -1) {
              params.customFields = this.proDetail.customFields
            }
            listingApproval(params)
              .then((res) => {
                if (res.data.code == 1000) {
                  this.$message({
                    message: '提交成功',
                    type: 'success'
                  })
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 300)
                }
              })
              .catch((res) => {
                if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 300)
                }
              })
              .finally(() => {
                this.loading = false
              })
          }
        }, 200)
      }
    }
  }
  // 二维码认证成功
  private notify(v: any) {
    if (v) {
      this.qrDiaShow = false
      this.loading = true
      if (this.ruleType == '12') {
        this.liquidationApprovalFn(this.isAgee)
      } else {
        this.bondApprovalFn(this.isAgee)
      }
    }
  }
  // 关闭弹窗
  private closeFn() {
    this.markShow = false
  }
  // 弹窗确认按钮
  private confirm() {
    const regPrice = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/ //校验金额
    const regDecimal4 = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,4})))$/ //校验3位小数
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
      params: any = {
        approvalId: this.routeParams.approvalId,
        remark: this.remark,
        projectId: this.projectId,
        isShowProject: this.proDetail.isShowProject,
        isAgree: '1',
        endTime: this.markInfo.endTime,
        listingPrice: this.markInfo.listingPrice,
        bidType: this.markInfo.bidType,
        isShowBidInfo: this.markInfo.isShowBidInfo,
        isShowBidder: this.markInfo.isShowBidder,
        paymentMethod: this.markInfo.paymentMethod
      },
      isReturn = false,
      errMessage = '',
      isCustom: boolean = [1, 2].indexOf(this.proDetail.businessType) > -1 ? false : true
    this.proDetail.businessType != '7' && (params.bidNotice = this.bidNotice)
    if (!isCustom && !this.markInfo.holdArea.trim()) {
      isReturn = true
      errMessage = '面积为必填项'
    } else if (isCustom && !this.markInfo.number.trim()) {
      isReturn = true
      errMessage = '标的数量为必填项'
    } else if (!this.markInfo.listingPrice.trim()) {
      isReturn = true
      errMessage = '挂牌价格为必填项'
    } else if (!isCustom && !regDecimal4.test(this.markInfo.holdArea)) {
      isReturn = true
      errMessage = '请输入正确格式的面积，至多保留4位小数'
    } else if (isCustom && isNaN(this.markInfo.number)) {
      isReturn = true
      errMessage = '标的数量请输入数字'
    } else if (!regPrice.test(this.markInfo.listingPrice)) {
      isReturn = true
      errMessage = '请输入正确格式的挂牌价格，至多保留2位小数'
    } else if (this.markInfo.bidType == 1) {
      if ((!this.markInfo.unitNum && !this.markInfo.unitTime) || (this.markInfo.unitNum == '无' && this.markInfo.unitTime == '0') ||
        (!this.markInfo.unitNum && this.markInfo.unitTime == '0') || (this.markInfo.unitNum == '无' && !this.markInfo.unitTime)) {
        isReturn = true
        errMessage = '挂牌价格数量单位与时间单位，至少填写一个'
      }
    }
    // else if (!this.markInfo.endTime) {
    //   isReturn = true
    //   errMessage = '挂牌截止日期为必选项'
    // }
    if (!isReturn && this.proDetail.tranferType == '1') {
      if (!this.markInfo.tranferEnsureAmount.trim()) {
        isReturn = true
        errMessage = '交易保证金为必填项'
      } else if (this.applyTime.length == 0) {
        isReturn = true
        errMessage = '报名时间为必选项'
      } else if (!this.time) {
        isReturn = true
        errMessage = '竞拍时间为必选项'
      } else if (!this.markInfo.auctionInterval.trim()) {
        isReturn = true
        errMessage = '竞价阶梯为必填项'
      } else if (!regPrice.test(this.markInfo.tranferEnsureAmount)) {
        isReturn = true
        errMessage = '请输入正确格式的交易保证金，至多保留2位小数'
      } else if (!regPrice.test(this.markInfo.auctionInterval)) {
        isReturn = true
        errMessage = '请输入正确格式的竞价阶梯，至多保留2位小数'
      } else if (new Date(this.time[0]).getTime() <= new Date().getTime()) {
        isReturn = true
        errMessage = '竞拍开始时间必须大于当前时间'
      } else if (new Date(this.applyTime[1]).getTime() > new Date(this.time[0]).getTime()) {
        isReturn = true
        errMessage = '报名截止时间需小于等于竞拍开始时间'
      } else if (Date.parse(new Date(this.time[1]) as any) / 1000 >= Date.parse(new Date(this.markInfo.endTime) as any) / 1000) {
        isReturn = true
        errMessage = '挂牌截止日期必须大于竞拍结束时间'
      }
    }
    if (isReturn) {
      this.$message({
        message: errMessage,
        type: 'error'
      })
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
      return
    }
    if (this.proDetail.tranferType == '1') {
      params.tranferEnsureAmount = this.markInfo.tranferEnsureAmount.trim()
      params.auctionInterval = this.markInfo.auctionInterval.trim()
      params.auctionStartTime = this.time[0]
      params.auctionEndTime = this.time[1]
      params.applyStartTime = this.applyTime[0]
      params.applyEndTime = this.applyTime[1]
      params.delayedCycle = this.markInfo.delayedCycle
      if (this.markInfo.bidType == 1) {
        params.unitTime = this.markInfo.unitTime
        params.unitNum = this.markInfo.unitNum
      }
    }
    if (!isCustom) {
      params.holdArea = this.markInfo.holdArea
    } else {
      params.number = this.markInfo.number
    }
    this.markShow = false
    params.bidNotice = this.proDetail.bidNotice
    listingApproval(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        }
      })
      .catch((res) => {
        if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }

  //去审批
  goApproval() {
    let rowList = (this.$refs.bondInfo as any).multipleSelection
    if (rowList.length <= 0) {
      this.$message.error('请勾选选项！')
      return
    }
    this.chooseList = rowList
    this.chooseListPersonName = rowList.map(item => {
      return item.userName
    }).join(' ，')
    // this.prjMarginDiaShow = true
    this.activeName = '9'
  }
}
