var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { "label-width": "150px", model: _vm.formData, rules: _vm.rules }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "收款方性质：", prop: "userName" } },
        [_vm._v(_vm._s(_vm.formData.user))]
      ),
      _c("el-form-item", { attrs: { label: "收款方：", prop: "userName" } }, [
        _vm._v(_vm._s(_vm.formData.userName))
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "清算金额：", prop: "liquidationAmount" } },
        [
          _c("el-input", {
            attrs: { placeholder: "最多8位字符" },
            model: {
              value: _vm.formData.liquidationAmount,
              callback: function($$v) {
                _vm.$set(_vm.formData, "liquidationAmount", $$v)
              },
              expression: "formData.liquidationAmount"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "清算人员：", prop: "liquidationName" } },
        [
          _c("el-input", {
            attrs: { placeholder: "最多8位字符" },
            model: {
              value: _vm.formData.liquidationName,
              callback: function($$v) {
                _vm.$set(_vm.formData, "liquidationName", $$v)
              },
              expression: "formData.liquidationName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "清算日期：", prop: "liquidationTime" } },
        [
          _c("el-date-picker", {
            attrs: { type: "date", placeholder: "选择日期" },
            model: {
              value: _vm.formData.liquidationTime,
              callback: function($$v) {
                _vm.$set(_vm.formData, "liquidationTime", $$v)
              },
              expression: "formData.liquidationTime"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { "margin-bottom": "25px" },
          attrs: { label: "清算凭证：", prop: "fileAddr" }
        },
        [
          _c(
            "div",
            { staticClass: "img-box" },
            [
              _vm._l(_vm.files, function(item, index) {
                return _c("div", { key: index, staticClass: "show-img" }, [
                  _c(
                    "span",
                    {
                      staticClass: "close-btn",
                      on: {
                        click: function($event) {
                          return _vm.deleteImgFn(index, item)
                        }
                      }
                    },
                    [_vm._v("x")]
                  ),
                  _c("img", {
                    attrs: { src: item.url },
                    on: {
                      click: function($event) {
                        return _vm.enlargeImg(item.url)
                      }
                    }
                  }),
                  _c("br"),
                  _vm._v("清算凭证 ")
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "up-files",
                  style: { "background-image": "url(" + _vm.bgImg + ")" }
                },
                [
                  _c("UploadBase", {
                    attrs: {
                      listShow: false,
                      showSuccessTip: false,
                      uploadOption: _vm.uploadOption
                    },
                    on: {
                      "update:uploadOption": function($event) {
                        _vm.uploadOption = $event
                      },
                      "update:upload-option": function($event) {
                        _vm.uploadOption = $event
                      },
                      fileUrl: _vm.getFileUrl,
                      beforeUpFn: _vm.beforeUpFn,
                      uploadError: _vm.uploadError
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c("span", { staticStyle: { color: "#ccc" } }, [
            _vm._v("支持扩展名：.pdf .jpg .png")
          ])
        ]
      ),
      _c(
        "el-form-item",
        { staticClass: "btn", staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$parent.hide()
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmDialog } },
            [_vm._v("确认清算")]
          )
        ],
        1
      ),
      _vm.showEnlarge
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showEnlarge,
                "modal-append-to-body": true,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.showEnlarge = $event
                }
              }
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.nowSrc }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }