var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "auction-info" }, [
    _c(
      "div",
      { staticClass: "part first" },
      [
        _vm._m(0),
        _vm.info
          ? _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("竞价次数")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.info.auctionTotal ? _vm.info.auctionTotal : 0 + "次"
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("最高报价")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.info.highestPrice + "元" + _vm.unitAppend(_vm.info)
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("交易保证金(元)")]),
                _c("span", [_vm._v(_vm._s(_vm.info.tranferEnsureAmount))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("竞拍时间")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.info.auctionStartTime || "") +
                        (_vm.info.auctionStartTime || _vm.info.auctionEndTime
                          ? " 至 "
                          : "") +
                        (_vm.info.auctionEndTime || "")
                    )
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.info && _vm.info.auctionPersonList
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  height: "335",
                  data: _vm.info.auctionPersonList,
                  "header-cell-style": {
                    textAlign: "center",
                    background: "#FAFAFA"
                  },
                  "cell-style": { textAlign: "center" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "竞拍人性质", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                scope.row.userType == 1
                                  ? "个人"
                                  : scope.row.userType == 2
                                  ? "企业"
                                  : scope.row.userType == 3
                                  ? "组织"
                                  : ""
                              )
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2537310761
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "姓名/企业名称",
                    prop: "name",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "竞价金额", prop: "amount", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.amount == null
                                    ? "--"
                                    : scope.row.amount +
                                        "元" +
                                        _vm.unitAppend(scope.row)
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3748414311
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "竞价时间",
                    prop: "createdTime",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "服务费（元）", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.transactionAmount == null
                                    ? "--"
                                    : scope.row.transactionAmount
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3517269347
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "成交价款（元）", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.transactionPrice == null
                                    ? "--"
                                    : scope.row.transactionPrice
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2686646307
                  )
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "tool" }, [
                              !_vm.isSetPerson && scope.row.status == "1"
                                ? _c("span", [_vm._v("中标人")])
                                : _vm._e(),
                              !_vm.isSetPerson &&
                              scope.row.status == "1" &&
                              _vm.transactionAmountShow
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "set-btn",
                                      staticStyle: { "margin-left": "12px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setPerson(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("设置服务费")]
                                  )
                                : _vm._e(),
                              !_vm.isSetPerson &&
                              scope.row.status == "1" &&
                              _vm.transactionPriceShow
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "set-btn",
                                      staticStyle: { "margin-left": "12px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setFinalPrice(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("设置成交价款")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3599122562
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.markShow
          ? _c("div", { staticClass: "mark" }, [
              _c("div", { staticClass: "mark-main" }, [
                _c("div", { staticClass: "mark-title" }, [
                  _vm._v(" 设置服务费 "),
                  _c(
                    "span",
                    { staticClass: "close-btn", on: { click: _vm.closeFn } },
                    [_vm._v("x")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "mark-item" },
                  [
                    _c("div", [_vm._v("服务费金额(元)")]),
                    _c("el-input", {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.transactionAmount,
                        callback: function($$v) {
                          _vm.transactionAmount = $$v
                        },
                        expression: "transactionAmount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mark-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "goback",
                        attrs: { size: "small" },
                        on: { click: _vm.closeFn }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.confirm }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _vm.markShowFinal
          ? _c("div", { staticClass: "mark" }, [
              _c("div", { staticClass: "mark-main" }, [
                _c("div", { staticClass: "mark-title" }, [
                  _vm._v(" 设置成交价款 "),
                  _c(
                    "span",
                    {
                      staticClass: "close-btn",
                      on: {
                        click: function($event) {
                          _vm.markShowFinal = false
                        }
                      }
                    },
                    [_vm._v("x")]
                  )
                ]),
                _c("p", { staticStyle: { "margin-left": "46px" } }, [
                  _vm._v("请设置标的成交价款或首期应付价款")
                ]),
                _c(
                  "div",
                  { staticClass: "mark-item" },
                  [
                    _c("div", [_vm._v("成交价款(元)")]),
                    _c("el-input", {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.transactionPrice,
                        callback: function($$v) {
                          _vm.transactionPrice = $$v
                        },
                        expression: "transactionPrice"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "mark-item" }, [
                  _c("div", [_vm._v("保证金抵扣")]),
                  _c(
                    "div",
                    { staticClass: "mark-select" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.isCostFinal,
                            callback: function($$v) {
                              _vm.isCostFinal = $$v
                            },
                            expression: "isCostFinal"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.isCostFinal,
                            callback: function($$v) {
                              _vm.isCostFinal = $$v
                            },
                            expression: "isCostFinal"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "mark-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "goback",
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            _vm.markShowFinal = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.confirmFinal }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("竞拍信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }