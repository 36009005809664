var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      staticClass: "uploader",
      attrs: {
        action: _vm.FileUploadURL,
        "show-file-list": _vm.resultShow,
        accept: _vm.accept,
        "on-success": _vm.uploadSuccess,
        "on-error": _vm.uploadError,
        "before-upload": _vm.beforeUpload,
        limit: _vm.limt,
        "file-list": _vm.fileslist,
        "on-remove": _vm.removeTrigger,
        "on-exceed": _vm.overLimit
      }
    },
    [
      _c(
        "el-button",
        { staticClass: "global-dotted-btn", attrs: { size: "small" } },
        [_vm._v(_vm._s(_vm.text))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }