
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class RelationTransactionInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: false
  })
  isDisabled?: boolean
}
