var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.infoCopy
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                { staticClass: "item", attrs: { size: "small" } },
                [
                  _c("el-form-item", { attrs: { label: "预审审批" } }, [
                    _vm.detailtype == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-timeline",
                              _vm._l(_vm.info.approvalUsers, function(
                                activity,
                                index
                              ) {
                                return _c(
                                  "el-timeline-item",
                                  {
                                    key: index,
                                    attrs: {
                                      icon: _vm.icon[activity.status],
                                      type: _vm.iconType[activity.status],
                                      size: "large",
                                      hideTimestamp: true
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "min-width": "100px",
                                            display: "inline-block"
                                          }
                                        },
                                        [_vm._v(_vm._s(activity.name))]
                                      ),
                                      activity.status != 0
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(activity.approvalTime)
                                            )
                                          ])
                                        : _vm._e(),
                                      activity.status == 1
                                        ? _c("span", { staticClass: "succ" }, [
                                            _vm._v("同意")
                                          ])
                                        : _vm._e(),
                                      activity.status == 2
                                        ? _c("span", { staticClass: "err" }, [
                                            _vm._v("拒绝")
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm.info.preApprovalInfo && _vm.detailtype != 1
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.info.preApprovalInfo.approvalTime)
                            )
                          ]),
                          _vm.info.preApprovalInfo.status
                            ? _c("span", { staticClass: "succ" }, [
                                _vm._v("同意")
                              ])
                            : _vm._e(),
                          !_vm.info.preApprovalInfo.status
                            ? _c("span", { staticClass: "err" }, [
                                _vm._v("拒绝")
                              ])
                            : _vm._e(),
                          _vm.detailtype == 5
                            ? _c("span", { staticClass: "err" }, [
                                _vm._v("使用一票否决")
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm.detailtype == 1 && _vm.info.setPayTypePermission
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "支付方式" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "left-margin-20",
                              attrs: { placeholder: "请选择", size: "small" },
                              model: {
                                value: _vm.infoCopy.payType,
                                callback: function($$v) {
                                  _vm.$set(_vm.infoCopy, "payType", $$v)
                                },
                                expression: "infoCopy.payType"
                              }
                            },
                            _vm._l(_vm.payType, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预审意见" } },
                    [
                      _vm.detailtype == 1
                        ? _c("el-input", {
                            staticClass: "area",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 6 }
                            },
                            on: {
                              blur: function($event) {
                                $event.preventDefault()
                                return _vm.changeremark()
                              }
                            },
                            model: {
                              value: _vm.remark,
                              callback: function($$v) {
                                _vm.remark = $$v
                              },
                              expression: "remark"
                            }
                          })
                        : _vm.detailtype != 1 && _vm.info.preApprovalInfo
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.info.preApprovalInfo.opinion))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.noticeShow &&
                  _vm.infoCopy.payType == 0 &&
                  _vm.info.paymentNoticeList &&
                  _vm.info.paymentNoticeList.length
                    ? _c("el-form-item", { attrs: { label: "缴费通知书" } }, [
                        _c(
                          "div",
                          _vm._l(_vm.info.paymentNoticeList, function(
                            item,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              _c("span", [
                                _vm._v("缴费金额（元）：" + _vm._s(item.amount))
                              ]),
                              item.fileUrl
                                ? _c(
                                    "div",
                                    { staticClass: "link-div" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href: item.fileUrl,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("缴费通知书")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm.detailtype == 0 &&
                  _vm.info.preDecisionApprovalInfo &&
                  _vm.info.preDecisionApprovalInfo.length
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "预审决策委员会" } },
                        _vm._l(_vm.info.preDecisionApprovalInfo, function(
                          items,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: { "left-padding-20": index > 0 }
                            },
                            [
                              _c(
                                "span",
                                { class: { "none-left-pad": index > 0 } },
                                [_vm._v(_vm._s(items.name))]
                              ),
                              _c("span", [_vm._v(_vm._s(items.approvalTime))]),
                              items.status == 1
                                ? _c("span", { staticClass: "succ" }, [
                                    _vm._v("同意")
                                  ])
                                : items.status == 2
                                ? _c("span", { staticClass: "err" }, [
                                    _vm._v("拒绝")
                                  ])
                                : _c("span", { staticClass: "orange" }, [
                                    _vm._v("保留意见")
                                  ]),
                              _c(
                                "div",
                                { class: { "left-padding-20": index == 0 } },
                                [_vm._v(_vm._s(items.remark))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.detailtype == 2 ||
                  _vm.detailtype == 3 ||
                  _vm.detailtype == 6 ||
                  (_vm.detailtype == 0 &&
                    _vm.info.finalApprovalInfo &&
                    _vm.info.finalApprovalInfo.approvalTime)
                    ? _c("el-form-item", { attrs: { label: "终审审批" } }, [
                        _vm.detailtype == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-timeline",
                                  _vm._l(_vm.info.approvalUsers, function(
                                    activity,
                                    index
                                  ) {
                                    return _c(
                                      "el-timeline-item",
                                      {
                                        key: index,
                                        attrs: {
                                          icon: _vm.icon[activity.status],
                                          type: _vm.iconType[activity.status],
                                          size: "large",
                                          hideTimestamp: true
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "min-width": "100px",
                                                display: "inline-block"
                                              }
                                            },
                                            [_vm._v(_vm._s(activity.name))]
                                          ),
                                          activity.status != 0
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(activity.approvalTime)
                                                )
                                              ])
                                            : _vm._e(),
                                          activity.status == 1
                                            ? _c(
                                                "span",
                                                { staticClass: "succ" },
                                                [_vm._v("同意")]
                                              )
                                            : _vm._e(),
                                          activity.status == 2
                                            ? _c(
                                                "span",
                                                { staticClass: "err" },
                                                [_vm._v("拒绝")]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm.info.finalApprovalInfo && _vm.detailtype != 2
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.info.finalApprovalInfo.approvalTime
                                  )
                                )
                              ]),
                              _vm.info.finalApprovalInfo.status
                                ? _c("span", { staticClass: "succ" }, [
                                    _vm._v("同意")
                                  ])
                                : _vm._e(),
                              !_vm.info.finalApprovalInfo.status
                                ? _c("span", { staticClass: "err" }, [
                                    _vm._v("拒绝")
                                  ])
                                : _vm._e(),
                              _vm.detailtype == 6
                                ? _c("span", { staticClass: "err" }, [
                                    _vm._v("使用一票否决")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.detailtype == 2 ||
                  _vm.detailtype == 3 ||
                  _vm.detailtype == 6 ||
                  (_vm.detailtype == 0 &&
                    _vm.info.finalApprovalInfo &&
                    _vm.info.finalApprovalInfo.approvalTime)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "终审意见" } },
                        [
                          _vm.detailtype == 2
                            ? _c("el-input", {
                                staticClass: "area",
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 6 }
                                },
                                on: {
                                  blur: function($event) {
                                    $event.preventDefault()
                                    return _vm.changeremark()
                                  }
                                },
                                model: {
                                  value: _vm.remark,
                                  callback: function($$v) {
                                    _vm.remark = $$v
                                  },
                                  expression: "remark"
                                }
                              })
                            : _vm.detailtype != 2 && _vm.info.finalApprovalInfo
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.info.finalApprovalInfo.opinion)
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detailtype == 0 &&
                  _vm.info.finalDecisionApprovalInfo &&
                  _vm.info.finalDecisionApprovalInfo.length
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "终审决策委员会" } },
                        _vm._l(_vm.info.finalDecisionApprovalInfo, function(
                          items,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: { "left-padding-20": index > 0 }
                            },
                            [
                              _c(
                                "span",
                                { class: { "none-left-pad": index > 0 } },
                                [_vm._v(_vm._s(items.name))]
                              ),
                              _c("span", [_vm._v(_vm._s(items.approvalTime))]),
                              items.status == 1
                                ? _c("span", { staticClass: "succ" }, [
                                    _vm._v("同意")
                                  ])
                                : items.status == 2
                                ? _c("span", { staticClass: "err" }, [
                                    _vm._v("拒绝")
                                  ])
                                : _c("span", { staticClass: "orange" }, [
                                    _vm._v("保留意见")
                                  ]),
                              _c(
                                "div",
                                { class: { "left-padding-20": index == 0 } },
                                [_vm._v(_vm._s(items.remark))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.detailtype == 0 &&
                  _vm.info.acceptInfo &&
                  _vm.info.acceptInfo.approvalTime
                    ? _c("el-form-item", { attrs: { label: "受理审批" } }, [
                        _vm.info.acceptInfo
                          ? _c(
                              "div",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.info.acceptInfo.name))
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.info.acceptInfo.approvalTime)
                                  )
                                ]),
                                _vm._l(_vm.info.acceptInfo.files, function(
                                  items,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      class: {
                                        "link-div": true,
                                        "is-top-pad":
                                          items.fileType == "acceptance-notices"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href: items.fileAddr,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(items.fileName) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.noticeShow &&
                  _vm.infoCopy.payType == 1 &&
                  _vm.info.paymentNoticeList &&
                  _vm.info.paymentNoticeList.length
                    ? _c("el-form-item", { attrs: { label: "缴费通知书" } }, [
                        _c(
                          "div",
                          _vm._l(_vm.info.paymentNoticeList, function(
                            item,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              _c("span", [
                                _vm._v("缴费金额（元）：" + _vm._s(item.amount))
                              ]),
                              item.fileUrl
                                ? _c(
                                    "div",
                                    { staticClass: "link-div" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href: item.fileUrl,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("缴费通知书")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm.detailtype == 5 || _vm.detailtype == 6
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "决策意见" } },
                        [
                          _c("el-input", {
                            staticClass: "area",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 6 }
                            },
                            on: {
                              blur: function($event) {
                                $event.preventDefault()
                                return _vm.changeremark()
                              }
                            },
                            model: {
                              value: _vm.remark,
                              callback: function($$v) {
                                _vm.remark = $$v
                              },
                              expression: "remark"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.allowInvest && _vm.detailtype == 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否允许投资" } },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { label: "1" },
                              model: {
                                value: _vm.isInvest,
                                callback: function($$v) {
                                  _vm.isInvest = $$v
                                },
                                expression: "isInvest"
                              }
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.isInvest,
                                callback: function($$v) {
                                  _vm.isInvest = $$v
                                },
                                expression: "isInvest"
                              }
                            },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detailtype == 3
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "do-approval",
                          attrs: { label: "受理审批" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._v(" 备案服务协议 "),
                              _c("uploadTemp", {
                                attrs: {
                                  accept: _vm.accept,
                                  resultShow: false,
                                  text: "上传",
                                  limt: 1
                                },
                                on: {
                                  error: function($event) {
                                    return _vm.fileUploadError($event)
                                  },
                                  result: function($event) {
                                    return _vm.fileUploadSuccess(
                                      $event,
                                      "listingServices"
                                    )
                                  }
                                }
                              }),
                              _vm.upSuccess.listingServices
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: _vm.upSuccessUrl.listingServices,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [_vm._v("预览")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.info.administrator
                            ? _c(
                                "div",
                                [
                                  _vm._v(" 受托管理协议 "),
                                  _c("uploadTemp", {
                                    attrs: {
                                      accept: _vm.accept,
                                      resultShow: false,
                                      text: "上传",
                                      limt: 1
                                    },
                                    on: {
                                      error: function($event) {
                                        return _vm.fileUploadError($event)
                                      },
                                      result: function($event) {
                                        return _vm.fileUploadSuccess(
                                          $event,
                                          "trustee"
                                        )
                                      }
                                    }
                                  }),
                                  _vm.upSuccess.trustee
                                    ? _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href: _vm.upSuccessUrl.trustee,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("预览")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.info.provider
                            ? _c(
                                "div",
                                [
                                  _vm._v(" 服务商协议 "),
                                  _c("uploadTemp", {
                                    attrs: {
                                      accept: _vm.accept,
                                      resultShow: false,
                                      text: "上传",
                                      limt: 1
                                    },
                                    on: {
                                      error: function($event) {
                                        return _vm.fileUploadError($event)
                                      },
                                      result: function($event) {
                                        return _vm.fileUploadSuccess(
                                          $event,
                                          "serviceProvider"
                                        )
                                      }
                                    }
                                  }),
                                  _vm.upSuccess.serviceProvider
                                    ? _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href:
                                              _vm.upSuccessUrl.serviceProvider,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("预览")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _vm._v(" 产品挂牌登记受理通知书 "),
                              _c("uploadTemp", {
                                attrs: {
                                  accept: _vm.accept,
                                  resultShow: false,
                                  text: "上传",
                                  limt: 1
                                },
                                on: {
                                  error: function($event) {
                                    return _vm.fileUploadError($event)
                                  },
                                  result: function($event) {
                                    return _vm.fileUploadSuccess(
                                      $event,
                                      "acceptanceNotices"
                                    )
                                  }
                                }
                              }),
                              _vm.upSuccess.acceptanceNotices
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href:
                                          _vm.upSuccessUrl.acceptanceNotices,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [_vm._v("预览")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  (_vm.detailtype == 1 || _vm.detailtype == 2) && _vm.info.last
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "一票否决权" } },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { label: "0" },
                              model: {
                                value: _vm.oneFalse,
                                callback: function($$v) {
                                  _vm.oneFalse = $$v
                                },
                                expression: "oneFalse"
                              }
                            },
                            [_vm._v("不使用")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.oneFalse,
                                callback: function($$v) {
                                  _vm.oneFalse = $$v
                                },
                                expression: "oneFalse"
                              }
                            },
                            [_vm._v("使用")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("审批信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }