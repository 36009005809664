
import { Component, Vue, Watch } from 'vue-property-decorator'
import loginBgImg from '@/assets/images/admin/loginBgImg.png'
import { singleRegiter, singleSMSverity } from '@/api/login'
@Component({})
export default class ZqtRegister extends Vue {
  private images: Object = {
    loginBgImg: loginBgImg
  }
  private img: string = ''
  private isVerity: boolean = true
  private formData: any = {
    phone: '',
    password: '',
    captcha: ''
  }

  private countTime: number = 0
  private timer: any = null
  private tabLogin() {
    this.$router.push({ name: 'Login' })
  }
  private tabRegister() {
    this.$router.push({ name: 'Register' })
  }
  private handleRegister() {
    let data: any = {
      mobile: this.formData.phone,
      password: this.formData.password,
      captcha: this.formData.captcha
    }
    singleRegiter(data).then((res) => {
      this.$router.push({ name: 'Login' })
    })
  }

  private handleVerity() {
    if (this.formData.phone === '') {
      this.$message.warning('手机号不能为空')
      return
    }
    if (this.formData.phone.length < 11 || this.formData.phone.length > 11) {
      this.$message.warning('请输入正确的手机号码')
      return
    }
    let data: any = {
      mobile: this.formData.phone
    }
    singleSMSverity(data).then((res) => {
      this.countDown()
    })
  }
  // 强制刷新
  // private handleChang(e:DragEvent){
  //   this.$forceUpdate()
  // }
  // 倒计时
  private countDown() {
    clearInterval(this.timer)
    const TIME_COUNT = 60
    this.countTime = TIME_COUNT
    this.isVerity = false
    this.timer = setInterval(() => {
      if (this.countTime > 1 && this.countTime <= TIME_COUNT) {
        this.countTime = this.countTime - 1
      } else {
        this.isVerity = true
        clearInterval(this.timer)
        this.timer = null
      }
    }, 1000)
  }
}
