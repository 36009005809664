var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "企业基本信息", name: "1" } },
            [_c("base-info")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "参与备案项目", name: "2" } },
            [_c("project-list")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }