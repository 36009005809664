var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "feedback-management-detail" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "content",
          attrs: { "element-loading-text": _vm.loadingTxt }
        },
        [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("反馈内容")]),
            _c("span", [_vm._v(_vm._s(_vm.info.opinion))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("图片")]),
            _vm.info.suggestionFileList
              ? _c(
                  "div",
                  { staticClass: "img-box" },
                  _vm._l(_vm.info.suggestionFileList, function(item, index) {
                    return _c("img", {
                      key: index,
                      attrs: { src: item.filePath },
                      on: {
                        click: function($event) {
                          return _vm.enlargeImg(item.filePath)
                        }
                      }
                    })
                  }),
                  0
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("提交人")]),
            _c("span", [_vm._v(_vm._s(_vm.info.name))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("手机号")]),
            _c("span", [_vm._v(_vm._s(_vm.info.phone))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("来源系统")]),
            _c("span", [_vm._v(_vm._s(_vm.info.type))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("提交时间")]),
            _c("span", [
              _vm._v(_vm._s(_vm._f("formatTime")(_vm.info.createdTime)))
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showEnlarge },
          on: {
            "update:visible": function($event) {
              _vm.showEnlarge = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.nowSrc }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }