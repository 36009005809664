import request, { request as http } from '@/utils/request'
import { AxiosPromise } from 'axios'

// 法律意见书列表
export function getlawList(data: Object) {
  return request({
    url: '/legalOpinion/page',
    method: 'POST',
    data: data
  })
}

// 法律意见书详情
export function getLawDetail(data: Object) {
  return request({
    url: '/legalOpinion/getDetail',
    method: 'POST',
    data: data
  })
}
// 法律意见书提交
export function lawSubmit(data: Object) {
  return request({
    url: '/legalOpinion/submit',
    method: 'POST',
    data: data
  })
}
