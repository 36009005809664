var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settlement-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("结算明细")]),
        _vm.checkboxValueShow
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("产品名称")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.productName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "productName", $$v)
                        },
                        expression: "checkboxValue.productName"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.productName))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("结算明细")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.settlementFile
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.settlementFile,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "settlementFile", $$v)
                        },
                        expression: "checkboxValue.settlementFile"
                      }
                    })
                  : _vm._e(),
                _vm.info.settlementFile
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          href: _vm.info.settlementFile,
                          underline: false,
                          download: ""
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.info.settlementFileName) + " ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("企业/人员总数")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.personNumber
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.personNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "personNumber", $$v)
                        },
                        expression: "checkboxValue.personNumber"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.personNumber))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("总金额(元)")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.amount
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "amount", $$v)
                        },
                        expression: "checkboxValue.amount"
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "item-content" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("toThousandsSigns")(_vm.info.amount)))
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("numMoneyToChinese")(_vm.info.amount)) +
                      " "
                  )
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("结算服务费缴纳方式")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.serviceAmountType
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.serviceAmountType,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "serviceAmountType", $$v)
                        },
                        expression: "checkboxValue.serviceAmountType"
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "item-content" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(["", "按比例", "固定"][_vm.info.serviceAmountType])
                    )
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.serviceAmountType == 1 &&
                          (_vm.info.serviceAmountRate ||
                            _vm.info.serviceAmountRate === 0)
                          ? _vm.info.serviceAmountRate + "%"
                          : ""
                      ) +
                      " "
                  )
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("结算服务费(元)")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.serviceAmount
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.serviceAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "serviceAmount", $$v)
                        },
                        expression: "checkboxValue.serviceAmount"
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "item-content" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("toThousandsSigns")(_vm.info.serviceAmount))
                    )
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("numMoneyToChinese")(_vm.info.serviceAmount)
                      ) +
                      " "
                  )
                ])
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }