
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class PaymentInfo extends Vue {
  @Prop() info: any
  @Prop() amount: any
  @Prop() serviceAmount: any
  private showEnlarge: boolean = false // 放大图片
  private nowSrc: any = '' // 当前凭证图片路径
  private enlargeImg(src: any) {
    this.nowSrc = src
    this.showEnlarge = true
  }
}
