
import { Component, Vue } from 'vue-property-decorator'
import { exportLoanProdPuctApproval, getLoanApprovalDetail, getLoanItems, insertLoanApproved, insertLoanSettleProof } from '@/api/loans'
import { conditionOptions, yesOrNoOptions } from '@/views/project-management/options'
import { uploadFileUrl } from '@/api/common.api'
import { Loading } from 'element-ui'
import { tableFilter } from '@/views/peasant-info-management/components/tableFilter'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {},
  filters: {
    statusfilter: function (val: any) {
      let res = ''
      if (val == '1') {
        res = '待审批'
      } else if (val == '2') {
        res = '已拒绝'
      } else if (val == '3') {
        res = '待补充资料'
      } else if (val == '4') {
        res = '已通过'
      } else if (val == '5') {
        res = '已还清'
      }
      return res
    },
    //还款方式（1：等额本金；2：等额本息；3：按月还息到期还本；4：到期一次还本付息）
    repaymentFilter: function (val: any) {
      let res = ''
      if (val == '1') {
        res = '等额本金'
      } else if (val == '2') {
        res = '等额本息'
      } else if (val == '3') {
        res = '按月还息到期还本'
      } else if (val == '4') {
        res = '到期一次还本付息'
      }
      return res
    },
    //发放方式（1：银行直接发放；2：现场办理）
    issuingMethodFilter: function (val: any) {
      let res = ''
      if (val == '1') {
        res = '银行直接发放'
      } else if (val == '2') {
        res = '现场办理'
      }
      return res
    }
  }
})
export default class ApprovalDetail extends Vue {
  private loadingTxt: string = loadingText
  private mainLoading: any = false
  private routeParams: any = {}
  private approval: string = '' //approvalComments
  private show: boolean = false
  private paramsInfo: any = {
    approvalComments: '',
    approvalAmount: '',
    deadline: '',
    interestRate: '',
    repayment: '',
    issuingMethod: '',
    address: '',
    status: '',
    loanId: ''
  }
  private rules = {
    approvalAmount: { required: true, message: '请输入审批额度', trigger: 'blur' },
    deadline: { required: true, message: '请输入贷款期限', trigger: 'blur' },
    interestRate: { required: true, message: '请输入贷款利率', trigger: 'blur' },
    repayment: { required: true, message: '请选择还款方式', trigger: 'change' },
    issuingMethod: { required: true, message: '请选择发放方式', trigger: 'change' },
    address: { required: true, message: '请输入办理地址', trigger: 'blur' }
  }
  private loading = false
  private isClick: boolean = true
  private datas: any = {}
  private loanTermsResponses: any = []
  private conditionOptions = conditionOptions
  private yesOrNoOptions = yesOrNoOptions
  private projectsOptions: any = {}
  private columnListApply: any = [] // 申请人信息
  private columnListFarmer: any = [] // 农户贷信息
  private loanApprovalFileArr: any = [] //声请人相关信息
  private loanAddFileArr: any = [] // 补充资料
  private loanId: string = ''
  private loanApprovedObj: any = {}
  private loanRepaymentFileArr: any = [] // 贷款还清证明
  // private tradeCenterOptions: any = []
  private isFilePreview: boolean = false
  private previewUrl: string = ''
  private pages: any = 0
  private pdfDoc: any = null

  private uploadUrl: string = uploadFileUrl
  private auditForm = {
    authenticateNo: '',
    fileName: '',
    fileAddr: ''
  }

  private async created() {
    this.mainLoading = true
    this.routeParams = this.$route.params
    if (this.routeParams.id) {
      sessionStorage.setItem('productsApproval', JSON.stringify(this.routeParams))
    } else {
      let nowGet: any = sessionStorage.getItem('productsApproval')
      this.routeParams = JSON.parse(nowGet)
    }
    // this.tradeCenterOptions = this.routeParams.tradeCenterOptions
    await getLoanItems({}).then((res) => {
      let projectArr: any = res.data.data
      let arr: any = []
      for (let i = 0; i < projectArr.length; i++) {
        let projectInfo: any = projectArr[i]
        let newProjecInfo: any = {}
        newProjecInfo['label'] = projectInfo.colName
        newProjecInfo['value'] = projectInfo.id
        arr.push(newProjecInfo)
      }
      this.projectsOptions = arr
    })
    let id = this.routeParams.id
    this.loanId = id
    this.getLoanApprovalDetail(id)
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('productsApproval')
    next()
  }
  private changeInput(value: any, name: string) {
    this.paramsInfo[name] = value.replace(/[^\d\.]/g, '')
  }
  private isPdf(url: any) {
    let returnBool: boolean = false
    if (url && (url.lastIndexOf('.pdf') > -1 || url.lastIndexOf('.blob') > -1)) {
      returnBool = true
    }
    return returnBool
  }
  showDialogLoan(url: string) {
    if (url) {
      let res = url.split('').reverse().join('')
      let suffix = res.substring(0, res.search(/\./)).split('').reverse().join('')
      this.previewUrl = url
      this.isFilePreview = true
    }
  }

  private handleUploadSuccess(res: any, file: any, fileList: []) {
    const { name } = file
    const addr = res.data
    // this.auditForm.fileName = name
    // this.auditForm.fileAddr = addr
    let fileInfo: any = {
      fileName: name,
      fileAddr: addr
    }
    this.loanRepaymentFileArr.push(fileInfo)
  }

  private handleUploadError() {
    this.$message.error('上传错误')
  }

  private handleRemove() {
    this.auditForm.fileName = ''
    this.auditForm.fileAddr = ''
  }
  private handleExportData() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let datas: any = {
      id: this.routeParams.id
    }
    exportLoanProdPuctApproval(datas)
      .then((res) => {
        if (res.data && (res.data.type == 'application/octet-stream' || res.data.type == 'application/octet-stream;charset=utf-8')) {
          const content = res.data
          const blob = new Blob([content], { type: 'application/zip' })
          let fileStr: any = res.headers['content-disposition'].split('filename=')[1]
          const fileName = decodeURI(fileStr)
          if (navigator && typeof navigator.msSaveBlob === 'function') {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          } else {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = window.URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            window.URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }
        } else {
          this.$message.error('下载失败')
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private getLoanApprovalDetail(id: string) {
    this.loading = true
    getLoanApprovalDetail({ id: id })
      .then((res) => {
        this.datas = res.data.data
        // for (let i = 0; i < this.tradeCenterOptions.length; i++) {
        //   let item = this.tradeCenterOptions[i]
        //   if (this.datas.propertyTradingCenter == item.value) {
        //     this.datas.propertyTradingCenter = item.label
        //   }
        // }
        if (this.datas.loanTermsResponses && this.datas.loanTermsResponses.length > 0) {
          this.showApplyConditionTable(this.datas.loanTermsResponses)
        }
        if (this.datas.loanApprovedEntities && this.datas.loanApprovedEntities.length > 0) {
          this.loanApprovedObj = this.datas.loanApprovedEntities[0]
        }
        if (this.datas.loanApprovalFileEntities && this.datas.loanApprovalFileEntities.length > 0) {
          this.loanApprovalFileArr = this.datas.loanApprovalFileEntities
        }
        if (this.datas.loanAddFileEntities && this.datas.loanAddFileEntities.length > 0) {
          this.loanAddFileArr = this.datas.loanAddFileEntities
        }
        if (this.datas.loanSettleProofEntities && this.datas.loanSettleProofEntities.length > 0) {
          this.loanRepaymentFileArr = this.datas.loanSettleProofEntities
        }
        if (this.datas.farmersInfoDetailResponse) {
          //申请人信息
          let farmersInfo: any = this.datas.farmersInfoDetailResponse
          tableFilter(farmersInfo)
          let columnList = farmersInfo.columnList
          if (columnList && columnList.length > 0) {
            this.columnListApply = columnList
          }
        } else {
          this.columnListApply = null
        }
        if (this.datas.loanInformationDetailResponse) {
          // 农户贷信息
          let loanInformations: any = this.datas.loanInformationDetailResponse
          tableFilter(loanInformations.detail)
          let columnList = loanInformations.detail.columnList
          if (columnList && columnList.length > 0) {
            this.columnListFarmer = columnList
          }
        } else {
          this.columnListFarmer = null
        }
        this.paramsInfo.approvalComments = this.datas.approvalComments
      })
      .finally(() => {
        this.loading = false
        this.mainLoading = false
      })
  }

  // 申请条件表数据展示
  showApplyConditionTable(conditionArr: any) {
    let _loanTermsResponses: any = []
    for (let i = 0; i < conditionArr.length; i++) {
      let info: any = conditionArr[i]
      _loanTermsResponses.push(this.transformConditionInfo(info))
    }
    this.loanTermsResponses = _loanTermsResponses
  }

  transformConditionInfo(info: any): any {
    let newInfo: any = {}
    for (let k in info) {
      let val = info[k]
      let itemId = info['itemId']
      if (k == 'item') {
        // 项目
        for (let i = 0; i < this.projectsOptions.length; i++) {
          let item = this.projectsOptions[i]
          if (item.label == val) {
            newInfo[k] = val
            newInfo['itemId'] = item.itemId
          }
        }
      } else if (k == 'condition') {
        this.conditionOptions.forEach((item, index) => {
          if (item.value == val) {
            newInfo[k] = item.label
          }
        })
      } else if (k == 'isNecessary') {
        this.yesOrNoOptions.forEach((item, index) => {
          if (item.value == val) {
            newInfo[k] = item.label
          }
        })
      } else if (k == 'value') {
        newInfo[k] = val
      }
    }

    return newInfo
  }

  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }

  // 补充资料
  private handleSupply() {
    if (!this.approval) {
      return this.$message.error('请先填写审批意见')
    }
  }

  // 提交
  private handleSubmit(index: number) {
    if (this.checkNotNull(this.paramsInfo)) {
      if (this.repeatT()) {
        return
      }
      this.isClick = false
      this.insertLoanApproved(this.paramsInfo)
    }
  }

  checkNotNull(info: any): boolean {
    if (!info['approvalAmount']) {
      alert('请输入审批额度')
      return false
    } else if (!info['deadline']) {
      alert('请输入贷款期限')
      return false
    } else if (!info['interestRate']) {
      alert('请输入贷款利率')
      return false
    } else if (!info['repayment']) {
      alert('请选择还款方式')
      return false
    } else if (!info['issuingMethod']) {
      alert('请选择发放方式')
      return false
    } else if (!info['address'] && info['issuingMethod'] == 2) {
      alert('请输入办理地址')
      return false
    }
    return true
  }

  // 添加审批记录  status(2：拒绝；3：待补充资料；4：通过)
  private handleClick(status: string) {
    this.loading = true
    this.paramsInfo.status = status
    this.paramsInfo.loanId = this.loanId
    switch (status) {
      case '2': //拒绝
        if (this.repeatT()) {
          return
        }
        this.isClick = false
        this.insertLoanApproved(this.paramsInfo)
        break
      case '3': //待补充资料
        if (!this.paramsInfo.approvalComments) {
          alert('请填写审批意见')
          return
        }
        if (this.repeatT()) {
          return
        }
        this.isClick = false
        this.insertLoanApproved(this.paramsInfo)
        break
      case '4': //通过
        this.show = true
        break
    }
  }

  private insertLoanApproved(params: any) {
    insertLoanApproved(params)
      .then((res) => {
        let data: any = res.data
        if (data && data.code == 1000) {
          this.handleReturn()
        }
      })
      .finally(() => {
        this.loading = false
        this.isClick = true
      })
  }

  //提交结清证明
  private closeOut() {
    let loanRepaymentFileArr = this.loanRepaymentFileArr
    if (!loanRepaymentFileArr || loanRepaymentFileArr.length <= 0) {
      alert('请您先填好贷款还清证明')
      return
    }
    let loanSettleProofEntities: any = []
    for (let i = 0; i < loanRepaymentFileArr.length; i++) {
      let obj = {
        proofUrl: loanRepaymentFileArr[i].fileAddr,
        proofName: loanRepaymentFileArr[i].fileName
      }
      loanSettleProofEntities.push(obj)
    }
    let params = {
      loanId: this.loanId,
      loanSettleProofEntities: loanSettleProofEntities
    }
    insertLoanSettleProof(params)
      .then((res) => {
        let data: any = res.data
        if (data && data.code == 1000) {
          this.handleReturn()
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleReturn() {
    this.$router.go(-1)
  }
}
