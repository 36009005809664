
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
import { handleEnterprisePage } from '@/api/financing'

@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class EnterpriseManagement extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private formInfo: any = {
    enterpriseName: '',
    creditCode: '',
    legalPerson: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      if (['EnterpriseManagement'].indexOf(from.name) > -1 && pageParams) {
        vim.formInfo = pageParams.formInfo
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, form: any, next: any) {
    if (['EnterpriseManagement'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo
      }
      sessionStore.set('pageParams', pageParams)
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getList() {
    let params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    this.loading = true
    handleEnterprisePage(params)
      .then((res) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleSearch() {
    this.getList()
  }
  private handleDetail(id: any) {
    this.$router.push({
      name: 'EnterpriseManagementDetail',
      params: {
        id
      }
    })
  }
}
