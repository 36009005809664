var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contents" }, [
    _c("div", { staticClass: "loginPicture" }, [
      _c("img", {
        staticClass: "loginImg",
        attrs: { src: _vm.images.loginBgImg, alt: "首页图" }
      })
    ]),
    _c("div", { staticClass: "rightBox" }, [
      _c("div", { staticClass: "rightContent" }, [
        _c("div", { staticClass: "tabText" }, [
          _c(
            "span",
            { staticClass: "loginTexts", on: { click: _vm.tabLogin } },
            [_vm._v("登录")]
          ),
          _c(
            "span",
            { staticClass: "registerTexts", on: { click: _vm.tabRegister } },
            [_c("span", [_vm._v("注册")]), _c("i")]
          )
        ]),
        _c("div", { staticClass: "inputBox" }, [
          _c(
            "div",
            { staticClass: "itemInput" },
            [
              _c("span", { staticClass: "itemInputText" }, [_vm._v("手机号")]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入手机号码" },
                model: {
                  value: _vm.formData.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "itemInput spaceBetweew" }, [
            _c(
              "div",
              { staticClass: "verifyBox" },
              [
                _c("span", { staticClass: "itemInputText" }, [
                  _vm._v("验证码")
                ]),
                _c("el-input", {
                  staticClass: "input",
                  attrs: { placeholder: "请输入验证码" },
                  model: {
                    value: _vm.formData.captcha,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "captcha", $$v)
                    },
                    expression: "formData.captcha"
                  }
                })
              ],
              1
            ),
            _vm.isVerity
              ? _c(
                  "span",
                  { staticClass: "getVerify", on: { click: _vm.handleVerity } },
                  [_vm._v("获取验证码")]
                )
              : _vm._e(),
            !_vm.isVerity
              ? _c("span", { staticClass: "getVerify length" }, [
                  _vm._v(_vm._s(_vm.countTime) + "s后重新获取")
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "itemInput" },
            [
              _c("span", { staticClass: "itemInputText" }, [_vm._v("密码")]),
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入密码" },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "btnBox", on: { click: _vm.handleRegister } },
          [_c("span", { staticClass: "registerBtn" }, [_vm._v("注册")])]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }