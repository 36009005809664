
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import filesSrc from '@/assets/images/files_btn.png'
@Component({
  components: {}
})
export default class AuthenticationBook extends Vue {
  @Prop() info: any
  private filesImg: any = filesSrc
}
