var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mainLoading,
          expression: "mainLoading"
        }
      ],
      staticClass: "organizational-Detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "机构名称", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "width-250",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "name", $$v)
                  },
                  expression: "formInfo.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属区域", prop: "cityAreaConfigId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isSuperAdmin != "1",
                    placeholder: "请选择",
                    clearable: ""
                  },
                  on: { change: _vm.selecttedCity },
                  model: {
                    value: _vm.formInfo.cityAreaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "cityAreaConfigId", $$v)
                    },
                    expression: "formInfo.cityAreaConfigId"
                  }
                },
                _vm._l(_vm.cityAreaOptions, function(item, index) {
                  return _c("el-option", {
                    key: "city" + index,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId
                    }
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "area-margin",
                  attrs: {
                    disabled: !_vm.isCity,
                    clearable: _vm.areaClearable
                  },
                  model: {
                    value: _vm.formInfo.areaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                    },
                    expression: "formInfo.areaConfigId"
                  }
                },
                _vm._l(_vm.areaOptions, function(item) {
                  return _c("el-option", {
                    key: item.areaConfigId,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构代码", prop: "code" } },
            [
              _c("el-input", {
                staticClass: "width-250",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.code,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "code", $$v)
                  },
                  expression: "formInfo.code"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人", prop: "contacts" } },
            [
              _c("el-input", {
                staticClass: "width-250",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.contacts,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "contacts", $$v)
                  },
                  expression: "formInfo.contacts"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "contactNumber" } },
            [
              _c("el-input", {
                staticClass: "width-250",
                attrs: { placeholder: "请输入" },
                on: { input: _vm.interChangeInput },
                model: {
                  value: _vm.formInfo.contactNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "contactNumber", $$v)
                  },
                  expression: "formInfo.contactNumber"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.isUpdate ? "更新" : "提交"))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }