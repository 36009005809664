import { render, staticRenderFns } from "./vipCheckDetail.vue?vue&type=template&id=2d0d90b6&scoped=true&"
import script from "./vipCheckDetail.vue?vue&type=script&lang=ts&"
export * from "./vipCheckDetail.vue?vue&type=script&lang=ts&"
import style0 from "./vipCheckDetail.vue?vue&type=style&index=0&id=2d0d90b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0d90b6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d0d90b6')) {
      api.createRecord('2d0d90b6', component.options)
    } else {
      api.reload('2d0d90b6', component.options)
    }
    module.hot.accept("./vipCheckDetail.vue?vue&type=template&id=2d0d90b6&scoped=true&", function () {
      api.rerender('2d0d90b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/vip-management/vip-check-list/vipCheckDetail.vue"
export default component.exports