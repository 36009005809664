var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "delisting-power-approval-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "part" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("所属区域")]),
            _c("span", [_vm._v(_vm._s(_vm.info.areaName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("企业名称")]),
            _c("span", [_vm._v(_vm._s(_vm.info.companyName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [
              _vm._v("统一社会信用代码")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.info.unifiedCreditCode))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("营业执照")]),
            _vm.info.businessLicense
              ? _c("img", {
                  staticClass: "item-img",
                  attrs: { src: _vm.info.businessLicense },
                  on: {
                    click: function($event) {
                      return _vm.enlargeImg(_vm.info.businessLicense)
                    }
                  }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("申请时间")]),
            _c("span", [_vm._v(_vm._s(_vm.info.createdTime))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("状态")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.info.status === 0
                    ? "待审核"
                    : _vm.info.status == 1
                    ? "已通过"
                    : _vm.info.status == 2
                    ? "已拒绝"
                    : _vm.info.status
                )
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _vm.info.status !== 0
            ? _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("返回")]
              )
            : _vm._e(),
          _vm.info.status === 0 && _vm.getInfoLength
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalFn(2)
                    }
                  }
                },
                [_vm._v("拒绝")]
              )
            : _vm._e(),
          _vm.info.status === 0 && _vm.getInfoLength
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalFn(1)
                    }
                  }
                },
                [_vm._v("通过")]
              )
            : _vm._e(),
          _vm.info.status === 1 && _vm.getInfoLength
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.cancelFn()
                    }
                  }
                },
                [_vm._v("取消通过")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showEnlarge },
          on: {
            "update:visible": function($event) {
              _vm.showEnlarge = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.nowSrc }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }