var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.type == 1 ? "转出方信息" : "转入方信息"))
        ])
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("身份类别")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("身份类别*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorIdentity
                            : _vm.info.transfereeIdentity
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorIdentity,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorIdentity", $$v)
                        },
                        expression: "info.transferorIdentity"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeIdentity,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeIdentity", $$v)
                        },
                        expression: "info.transfereeIdentity"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("名称/姓名")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("名称/姓名*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorName
                            : _vm.info.transfereeName
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorName,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorName", $$v)
                        },
                        expression: "info.transferorName"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeName,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeName", $$v)
                        },
                        expression: "info.transfereeName"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("工商注册号/身份证号")]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorRegistration
                            : _vm.info.transfereeRegistration
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorRegistration,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorRegistration", $$v)
                        },
                        expression: "info.transferorRegistration"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeRegistration,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeRegistration", $$v)
                        },
                        expression: "info.transfereeRegistration"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系地址")]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorAddress
                            : _vm.info.transfereeAddress
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorAddress", $$v)
                        },
                        expression: "info.transferorAddress"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeAddress", $$v)
                        },
                        expression: "info.transfereeAddress"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("法定代表人/负责人")]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorPrincipal
                            : _vm.info.transfereePrincipal
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorPrincipal,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorPrincipal", $$v)
                        },
                        expression: "info.transferorPrincipal"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereePrincipal,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereePrincipal", $$v)
                        },
                        expression: "info.transfereePrincipal"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("身份证号")]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorCard
                            : _vm.info.transfereeCard
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorCard,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorCard", $$v)
                        },
                        expression: "info.transferorCard"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeCard,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeCard", $$v)
                        },
                        expression: "info.transfereeCard"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系方式")]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.type == 1
                            ? _vm.info.transferorContact
                            : _vm.info.transfereeContact
                        )
                      )
                    ])
                  : _vm.type == 1
                  ? _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transferorContact,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transferorContact", $$v)
                        },
                        expression: "info.transferorContact"
                      }
                    })
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.transfereeContact,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "transfereeContact", $$v)
                        },
                        expression: "info.transfereeContact"
                      }
                    })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: { click: _vm.returnPaper }
            },
            [_vm._v("返回")]
          ),
          !_vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.editProject }
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.updateProject }
                },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }