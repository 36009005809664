// 人员管理接口管理

import request from '@/utils/request'




// 获取所有菜单路由
export function getAllRouter() {
  return request({
    url: '/admin/menu/allMenu',
    // method: 'GET'
    method: 'POST'
  })
}

// 手机查人员信息
export function getPresonInfoByPhone(data: Object) {
  return request({
    url: '/admin/person/getByPhone',
    method: 'POST',
    data,
  })
}

// 添加人员
export function addPersonnel(data: Object) {
  return request({
    url: '/admin/person/add',
    method: 'POST',
    data,
  })
}
// 人员更新
export function upDatePersonnel(data: Object) {
  return request({
    url: '/admin/person/update',
    method: 'POST',
    data,
  })
}

// 获取人员列表
export function getPersonnelList(data: Object) {
  return request({
    url: '/admin/person/list',
    method: 'POST',
    data,
  })
}

// //  查看人员
// export function checkPersonnel(data: Object) {
//   return request({
//     url: '/admin/person/add',
//     method: 'POST',
//     data,
//   })
// }


// 删除人员
export function deletePersonnel(personId: any) {
  return request({
    // url: `/admin/person/delete/${personId}`,
    url: `/admin/person/delete`,
    method: 'POST',
    data: {
      personId: personId
    }
  })
}


// 获取所有角色
export function getAllRole() {
  return request({
    url: '/admin/role/getAll',
    // method: 'GET'
    method: 'POST'
  })
}


// 角色列表
export function rolePersonnelList(data: Object) {
  return request({
    url: '/admin/role/list',
    method: 'POST',
    data,
  })
}

// 新增角色
export function addRolePersonnel(data: Object) {
  return request({
    url: '/admin/role/add',
    method: 'POST',
    data,
  })
}
// 查看角色
export function checkRolePersonnel(id: any) {
  return request({
    url: `/admin/role/view`,
    // url: `/admin/role/view/${id}`,
    // method: 'GET'
    method: 'POST',
    data: {
      roleId: id
    }
  })
}
// 更新角色
export function upDateRolePersonnel(data: Object) {
  return request({
    url: '/admin/role/update',
    method: 'POST',
    data,
  })
}
// 删除角色
export function deleteRolePersonnel(roleId: any) {
  return request({
    // url: `/admin/role/delete/${roleId}`,
    url: `/admin/role/delete`,
    method: 'POST',
    data: { roleId: roleId }
  })
}
