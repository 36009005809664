var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              _vm._l(_vm.info, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.fileType != "acceptance-notices"
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              href: item.fileAddr,
                              target: "_blank",
                              underline: false
                            }
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "item" },
              _vm._l(_vm.info, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    item.fileType == "acceptance-notices"
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              href: item.fileAddr,
                              target: "_blank",
                              underline: false
                            }
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("相关协议及合同")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }