
import { Component, Vue } from 'vue-property-decorator'
import formDetail from '../component/formDetail.vue'
import { unitReviewDetail, unitAuditDetail } from '@/api/units'
import { localStore } from '@/utils/data-management'
import SuccessIcon from '@/assets/images/success.png'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    formDetail
  },
  filters: {
    filterAuditStatus(data: any) {
      switch (data) {
        case 0:
          return '未审核'
          break
        case 1:
          return '通过'
          break
        case 2:
          return '拒绝'
          break
        case 3:
          return '审核中'
          break
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
})
export default class UnitReviewDetail extends Vue {
  private loadingTxt: string = loadingText
  private formDatass: any = {}
  private isPass: string = ''
  private parentData: any = {}
  private type: any = 0
  private companyDetail: boolean = false
  private approvalDatas: any = []
  private successIcon: string = SuccessIcon
  private rejectContent: string = ''
  // --------------------------------data---END-----------------------
  private created() {
    this.parentData = this.$route.params !== {} ? this.$route.params : {}
    if (this.$route.params.id) {
      localStore.set('uID', this.$route.params)
    }
    this.handleUnitsDetail()
  }
  private approvalData: any = {}
  private mounted() {
    this.isPass = this.parentData.isPass || localStore.get('uID').isPass
  }
  // ---------------周期函数---计算属性--数据监听等----------end--------
  // 是否通过审核
  private handleIsPass() {}
  // 查询单位审核详情
  private handleUnitsDetail() {
    let data: any = {
      id: this.parentData.id || localStore.get('uID').id
    }
    unitReviewDetail(data).then((res) => {
      if (res.data.data) {
        let data: any = {
          companyFileRels: res.data.data.companyFileRels,
          company: res.data.data.company
        }
        this.formDatass = data
        res.data.data.companyAudits && (this.approvalDatas = res.data.data.companyAudits)
        this.companyDetail = true
      }
    })
  }
  private handlePass() {
    this.rejectContent = this.rejectContent.trim()
    let data: any = {
      id: this.parentData.id || localStore.get('uID').id,
      isReject: false,
      rejectContent: this.rejectContent
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    unitAuditDetail(data)
      .then((res) => {
        this.$router.push({ name: 'UnitReview' })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleRefuse() {
    this.rejectContent = this.rejectContent.trim()
    if (this.rejectContent == '') {
      this.$message.warning('拒绝需要填写审批意见')
      return
    }
    let data: any = {
      id: this.parentData.id || localStore.get('uID').id,
      isReject: true,
      rejectContent: this.rejectContent
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    unitAuditDetail(data)
      .then((res) => {
        console.log(res.data)
        this.$router.push({ name: 'UnitReview' })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  get showSumbit() {
    let returnShow: boolean = false
    if (this.approvalDatas.length && [0, 3].indexOf(this.approvalDatas[this.approvalDatas.length - 1].auditStatus) > -1) {
      returnShow = true
    }
    return returnShow
  }
}
