
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue, Prop } from 'vue-property-decorator'
import DialogEdit from './DialogEdit.vue'
import FieldDialog from './FieldDialog.vue'
import { submitColumnInfo, submitFieldInfo, deleteColumnInfo, deleteFieldInfo } from '@/api/peasantInfoManagement'

@Component({
  components: {
    DialogEdit,
    FieldDialog
  },
  filters: {
    filterWhether(data: number) {
      switch (data) {
        case 0:
          return '否'
          break
        case 1:
          return '是'
          break
      }
    },
    filterField(data: number) {
      switch (data) {
        case 1:
          return '选项'
          break
        case 2:
          return '文本'
          break
        case 3:
          return '数字'
          break
        case 4:
          return '整数'
          break
        case 5:
          return '日期'
          break
        case 6:
          return '自动计算'
          break
      }
    }
  }
})
export default class InfoTemplateConfigTable extends Vue {
  @Prop({ default: () => ({}) }) private itemData!: any // 接收父组件传过来的数据
  @Prop() private loadingInstance!: any
  private loadingTxt: string = loadingText
  private isShowColumn = false
  private isShowField = false
  // private tableData: any = []
  private columnOption: any = {
    title: '编辑栏目',
    columnId: '',
    name: '',
    sort: '',
    isRequired: 0,
    contentIsAdd: 0
  }
  private fieldOption: any = {
    title: '',
    disabled: false,
    columnId: '',
    name: ''
  }
  // ---------------------------------------------------data----end------------------------------------------------------------------
  // created() {
  // this.tableData = this.itemData.colListResponseList
  // }
  // private confirmDialog() {
  //   console.log('1111111111111')
  // }

  // 编辑栏目
  private handleEditColumn() {
    this.isShowColumn = true
    this.columnOption.title = '编辑栏目'
    this.columnOption.columnId = this.itemData.id
    this.columnOption.name = this.itemData.name
    this.columnOption.sort = this.itemData.sort
    this.columnOption.isRequired = this.itemData.isRequired
    this.columnOption.isRuralLoan = this.itemData.isRuralLoan
    this.columnOption.contentIsAdd = this.itemData.contentIsAdd
  }
  // 删除栏目
  private handleDeletecolumn() {
    this.$confirm('确定删除该栏目吗？', '提示', {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: true,
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      showCancelButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then((res) => {
        if (res === 'confirm') {
          let data: any = {
            menuId: this.itemData.id
          }
          deleteColumnInfo(data).then((res) => {
            this.$emit('updateList', true)
          })
        }
      })
      .catch((res) => {})
  }
  // 新增字段
  private handleAddField() {
    this.isShowField = true
    this.fieldOption = {}
    this.fieldOption.disabled = true
    this.fieldOption.title = '新增字段'
    this.fieldOption.name = this.itemData.name ? this.itemData.name : ''
    this.fieldOption.columnId = this.itemData.id
  }
  // 编辑字段
  private handleEditField(value: any) {
    this.isShowField = true
    this.fieldOption.disabled = true
    this.fieldOption.title = '编辑字段'
    this.fieldOption.name = this.itemData.name ? this.itemData.name : ''
    this.fieldOption.columnId = this.itemData.id
    let newObj: any = {}
    Object.assign(newObj, this.fieldOption, value)
    this.fieldOption = newObj
  }
  // 删除字段
  private handleDeleteField(value: any) {
    let data: any = {
      colId: value.id
    }
    deleteFieldInfo(data).then((res) => {
      this.$emit('updateList')
    })
  }
  // 关闭栏目弹框
  private handleCancelDialog() {
    this.isShowColumn = false
  }
  // 关闭字段弹框
  private handleCancelFieldDialog() {
    this.isShowField = false
    this.fieldOption = {}
    this.fieldOption.disabled = true
    this.fieldOption.title = '新增字段'
    this.fieldOption.name = this.itemData.name ? this.itemData.name : ''
    this.fieldOption.columnId = this.itemData.id
    // this.$emit('updateList')
  }
  // 提交栏目数据
  handleColumnData(data: any) {
    let datas: any = data.formData,
      loadingInstance = this.loadingInstance.service({ target: document.body, text: this.loadingTxt })
    submitColumnInfo(datas)
      .then((res) => {
        this.handleCancelDialog()
        this.$emit('updateList')
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 提交字段数据
  private handleFieldData(data: any) {
    let datas: any = data.formData,
      loadingInstance = this.loadingInstance.service({ target: document.body, text: this.loadingTxt })
    submitFieldInfo(datas)
      .then((res) => {
        this.handleCancelFieldDialog()
        this.$emit('updateList')
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
