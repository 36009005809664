
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getVillageProvinceList, getVillageCityList, getVillageCountyList, getVillageTownshipList } from '@/api/peasantInfoManagement'
import { getAssessmentTeamList } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class AssessmentTeamList extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    groupName: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'AssessmentTeamDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.cityOptions = pageParams.cityOptions
        vim.countyOptions = pageParams.countyOptions
        vim.townshipOptions = pageParams.townshipOptions
        if (pageParams.provinceOptions.length) {
          vim.provinceOptions = pageParams.provinceOptions
        }
      }
      if (!vim.provinceOptions.length) {
        getVillageProvinceList({}).then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'AssessmentTeamDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getAssessmentTeamList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCityList({ province: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCountyList({ province: this.formInfo.province, city: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getVillageTownshipList({
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: value
      })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 搜索
  private handleSearch() {
    this.getList()
  }
  // 添加
  private handleAdd() {
    this.$router.push({ name: 'AssessmentTeamDetails', params: { pageType: 'add' } })
  }
  // 查看
  private handleDetails(id: any) {
    this.$router.push({
      name: 'AssessmentTeamDetails',
      params: { pageType: 'search', assessmentGroupId: id }
    })
  }
}
