var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "compete-buy-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _vm.auctionInfo && _vm.auctionInfo.auctionPersonList
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.auctionInfo.auctionPersonList,
                        "header-cell-style": {
                          textAlign: "center",
                          background: "#FAFAFA"
                        },
                        "cell-style": { textAlign: "center" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "竞拍人性质", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.userType == 1
                                        ? "个人"
                                        : scope.row.userType == 2
                                        ? "企业"
                                        : scope.row.userType == 3
                                        ? "组织"
                                        : ""
                                    )
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1495507785
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "姓名/企业名称",
                          prop: "name",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "竞买申请时间",
                          prop: "createdTime",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.qualificationStatus == "1"
                                    ? _c("span", [_vm._v("中标人")])
                                    : scope.row.qualificationStatus == "0"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "set-btn",
                                          staticStyle: {
                                            color: "#409eff",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setPerson(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("设为中标人")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#409eff",
                                        "padding-left": "6px",
                                        cursor: "pointer"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToBiddersDetail(
                                            scope.row.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("查看详情")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3122162965
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "flex-box" }, [
                _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("成交价（元）")]),
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "请输入数字",
                          disabled: !_vm.isEdit
                        },
                        on: { input: _vm.changeInput },
                        model: {
                          value: _vm.info.transactionPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "transactionPrice", $$v)
                          },
                          expression: "info.transactionPrice"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "item upload-box" }, [
                  _c("span", [_vm._v("成交确认书")]),
                  _c(
                    "div",
                    [
                      _vm.isEdit
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading"
                                }
                              ],
                              staticClass: "upload-btn",
                              on: { click: _vm.activeUpload }
                            },
                            [_vm._v("+ 上传文件（最多4份）")]
                          )
                        : _vm._e(),
                      _c("upload-base", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        attrs: {
                          showSuccessTip: false,
                          id: "cb-files-btn",
                          listShow: false,
                          uploadOption: _vm.uploadOption
                        },
                        on: {
                          fileUrl: _vm.getFileUrl,
                          beforeUpFn: _vm.beforeUpFn,
                          uploadError: _vm.uploadError
                        }
                      }),
                      _vm._l(_vm.info.confirmFile, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "file-list" },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  href: item.fileUrl,
                                  target: "_blank",
                                  underline: false
                                }
                              },
                              [_vm._v(_vm._s(item.fileName))]
                            ),
                            _vm.isEdit
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFile(index)
                                      }
                                    }
                                  },
                                  [_vm._v("x")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("竞买信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }