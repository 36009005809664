var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "need-data" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("所需资料")]),
        _vm.checkboxShow
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "content",
          attrs: { "element-loading-text": _vm.loadingTxt }
        },
        [
          _vm.info
            ? _c(
                "div",
                _vm._l(_vm.info, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "item flex-item align-c" },
                      [
                        _vm.checkboxShow
                          ? _c("el-checkbox", {
                              model: {
                                value: _vm.itemStatus[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.itemStatus, index, $$v)
                                },
                                expression: "itemStatus[index]"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: item.fileUrl,
                              target: "_blank",
                              underline: false
                            }
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        ),
                        _c(
                          "div",
                          { staticClass: "switch-div" },
                          [
                            _c("el-switch", {
                              on: {
                                change: function($event) {
                                  return _vm.handleSwitch($event, item.fileId)
                                }
                              },
                              model: {
                                value: item.isShow,
                                callback: function($$v) {
                                  _vm.$set(item, "isShow", $$v)
                                },
                                expression: "item.isShow"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "delete-file",
                            on: {
                              click: function($event) {
                                return _vm.deleteFile(item.fileId, index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("upload-base", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            attrs: {
              showSuccessTip: false,
              id: "needfiles-btn",
              listShow: false,
              uploadOption: _vm.uploadOption
            },
            on: {
              fileUrl: _vm.getFileUrl,
              beforeUpFn: _vm.beforeUpFn,
              uploadError: _vm.uploadError
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "global-dotted-btn vertical-bottom",
              staticStyle: { "margin-left": "130px" },
              attrs: { size: "small" },
              on: { click: _vm.updateFiles }
            },
            [_vm._v("上传文件")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }