
import { Component, Vue, Emit, Prop, Ref, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElUpload } from 'element-ui/types/upload'
import { uploadSignatureUrl } from '@/api/common.api'
import UploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
@Component({
  components: {
    UploadBase
  }
})
export default class dialogLaw extends Vue {
  @Prop() info: any
  // 控制显示形式
  @Prop({ default: false }) fileTag?: boolean
  // 上传按钮背景图
  private bgImg: any = bgSrc
  // 上传文件地址
  private fileAddr: any = '' //扫描件
  // 上传配置
  private uploadOption: any = {
    limit: 1,
    accept: '.pdf',
    flag: '1'
  }
  // 监听info获取上传文件地址
  @Watch('info', { deep: true, immediate: true })
  handler(newV: any) {
    if (!this.fileTag && newV.scanFile) {
      this.fileAddr = newV.scanFile
    } else if (this.fileTag && newV.legalOpinion) {
      this.fileAddr = newV.legalOpinion.files[0].fileAddr
    }
  }
  // 获取上传文件地址
  getFileUrl(v: any) {
    if (v) {
      this.fileAddr = v.url
      this.$emit('getUrl', v)
    } else {
      this.uploadOption.limit = this.uploadOption.limit + 1
    }
  }
}
