
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PersonnelForm } from '@/views/type'
// import { getAllRole, addPersonnel, upDatePersonnel } from '@/api/personnel'
import { decodeS } from 'nzh/dist/nzh.cn.min.js'
import upLoadFileImg from '@/components/upLoadFileImg/index.vue'
import uploadFileBtn from '@/components/upLoadFileBtn/index.vue'
import { getProvinceList, getCityList, getCountyList } from '@/api/peasantInfoManagement'
import { getIndustryData } from '@/api/login'
import { goAuthorize } from '@/api/center'
import { searchArea } from '@/api/noticeManagement'
import { localStore, sessionStore } from '@/utils/data-management'
import QrCode from '@/components/authQrCode.vue'
@Component({
  components: {
    upLoadFileImg,
    uploadFileBtn,
    QrCode
  }
})
export default class AuthForm extends Vue {
  // @Prop({ default: false, type: Boolean }) private disabled?: boolean
  @Prop({ default: 0, type: Number }) private types!: number
  // @Prop({}) private propertyRightTransactionCenterData!: Array<string>
  // @Prop({}) private industryData!: Array<string>
  // @Prop({}) private provinceOptions!: Array<string>

  @Prop({ default: '', type: String }) private isPass!: string
  @Prop({
    default: () => {
      {
      }
    },
    type: Object || Array
  })
  private formDatass!: Object
  @Prop({ default: '' })
  private initManagerIdentityFront?: any
  @Prop({ default: '' }) private markPng?: any
  //----------------------------- data部分------------------------------------
  private qrDiaShow: boolean = false
  private recommenderCodeValue: string = ''
  private callbackId: string = ''
  private isOrModalClose: boolean = false
  // private center: boolean = true
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    listType: 'picture-card'
    // tip: '请上传pdf格式文件',
  }
  private uploadOptionBtn: any = {
    limit: 1,
    accept: '.pdf',
    // listType: 'picture-card',
    tip: '请上传pdf格式文件'
  }
  private listShow: boolean = true
  private options: any = {
    provinceOptions: [],
    cityOptions: [],
    countyOptions: [],
    provinceOption: [],
    cityOption: [],
    countyOption: [],
    areaOptions: []
  }
  private formDatas: any = {
    companyInfo: {},
    companyFileRelList: []
  }
  private reffLinkImg: string = ''
  private refgLinkImg: string = ''
  private refhLinkImg: string = ''
  private industry: Array<string> = []
  private authorize: boolean = true
  private rules = {
    // name: { required: true, message: '请输入名字', trigger: 'blur' },
    // idCard: { required: true, message: '请输入身份证', trigger: 'blur' },
    // phone: { required: true, message: '请输入手机号', trigger: 'blur' },
    // email: { required: true, message: '请输入邮箱', trigger: 'blur' },
    // roleId: { required: true, message: '请选择角色', trigger: 'blur' },
    // recommendCode: { required: true, message: '请输入推荐人代码', trigger: 'blur' }
  }
  // private isPassStatus:string = ''
  // -------------周期 过滤 计算属性 监听等函数 --部分---------------------------
  // private get isPassStatus() {
  //   return localStore.get('ISPASSSTATUS')
  // }
  private qrData: any = {}
  @Watch('isPass', { deep: true, immediate: true })
  private handleIsPass(newValue: any) {
    if (newValue) {
      this.isPassStatus = newValue
      // sessionStore.set('did', newValue)
    }
  }
  private isPassStatus: string = ''
  @Watch('types', { deep: true, immediate: true })
  private typess(newValue: any) {
    this.formDatas.companyInfo.type = newValue ? newValue : 0
  }
  // @Watch('isPass', { deep: true, immediate: true })
  //   private isPassData(newValue: any) {
  //     if(newValue) this.isPassStatus = newValue
  //   }
  @Watch('formDatass', { deep: true, immediate: true })
  private formData(newValue: any) {
    if (newValue.companyFileRelList && newValue.companyFileRelList.length > 0) {
      this.formDatas.companyFileRelList = newValue.companyFileRelList
      this.formDatas.companyInfo = newValue.companyInfo
    } else {
      this.formDatas.companyInfo = newValue.companyInfo
    }
  }
  created() {
    searchArea({ isDelete: false, isDisable: false }).then((res) => {
      if (res.data.data && res.data.data.aeras) {
        this.options.areaOptions = this.filteAreaOptions(res.data.data.aeras)
      }
    })
    this.handleIndustry()
    this.handleProvinceList()
    if (this.types) {
      this.formDatas.companyInfo.type = this.types
    }
    this.fliterCity(this.formDatas.companyInfo.registRegionProvince)
    this.fliterCity(this.formDatas.companyInfo.workRegionProvince)
    this.fliterAera(this.formDatas.companyInfo.registRegionCity)
    this.fliterAera(this.formDatas.companyInfo.workRegionCity)
  }
  mounted() {
    let uploadList: any = this.formDatas.companyFileRelList
    let companyInfo: any = this.formDatas.companyInfo
    this.$nextTick(() => {
      if (companyInfo.managerIdentityFront) {
        ;(this as any).$refs['refb'].fileList = [{ url: companyInfo.managerIdentityFront, name: '' }]
        ;(this as any).$refs['refb'].hideUpload = true
        ;(this as any).$refs['refb'].uploadUrl = companyInfo.managerIdentityFront
        this.authorize = false
      }
      uploadList.forEach((item: any) => {
        if (item.fileCode === '001') {
          ;(this as any).$refs['refa'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refa'].hideUpload = true
          ;(this as any).$refs['refa'].uploadUrl = item.filePath
        } else if (item.fileCode === '002') {
          // ;(this as any).$refs['refb'].fileList = [{ url: item.filePath, name: item.fileName }]
          // ;(this as any).$refs['refb'].hideUpload = true
          // ;(this as any).$refs['refb'].uploadUrl = item.filePath
        } else if (item.fileCode === '003') {
          ;(this as any).$refs['refc'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refc'].hideUpload = true
          ;(this as any).$refs['refc'].uploadUrl = item.filePath
        } else if (item.fileCode === '004') {
          ;(this as any).$refs['refd'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refd'].hideUpload = true
          ;(this as any).$refs['refd'].uploadUrl = item.filePath
        } else if (item.fileCode === '005') {
          ;(this as any).$refs['refe'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refe'].hideUpload = true
          ;(this as any).$refs['refe'].uploadUrl = item.filePath
        } else if (item.fileCode === '006') {
          this.reffLinkImg = item.filePath
        } else if (item.fileCode === '007') {
          this.refgLinkImg = item.filePath
        } else if (item.fileCode === '008') {
          this.refhLinkImg = item.filePath
        }
      })
    })
  }

  // ------------------------------自定义函数部分------------------------------
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 关闭二维码弹窗
  private handleCancelDialog() {
    this.qrDiaShow = false
    // this.handleBankCardInfo()
  }
  handleClose() {
    this.qrDiaShow = false
  }
  qrCodeSuccessdata(callbackId: any) {
    this.callbackId = callbackId
  }
  notify(data: any) {
    if (data) {
      this.qrDiaShow = false
      this.authorize = false
    }
  }
  // 上传完的返回处理方法--
  getFileUrlUrl(data: any) {
    switch (data.flag) {
      case 1:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '001',
          filePath: data.url
        })
        break
      case 2:
        this.formDatas.companyInfo.managerIdentityFront = data.url
        break
      case 3:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '003',
          filePath: data.url
        })
        break
      case 4:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '004',
          filePath: data.url
        })
        break
      case 5:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '005',
          filePath: data.url
        })
        break
      case 6:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '006',
          filePath: data.url
        })
        this.reffLinkImg = data.url
        break
      case 7:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '007',
          filePath: data.url
        })
        this.refgLinkImg = data.url
        break
      case 8:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '008',
          filePath: data.url
        })

        this.refhLinkImg = data.url
        break
    }
  }
  resetAuthorize() {}
  // 认证
  handleAuthorize() {
    let data: any = {
      idCard: this.formDatas.companyInfo.managerIdentity,
      imgUrl: this.formDatas.companyInfo.managerIdentityFront,
      name: this.formDatas.companyInfo.managerName
    }
    if (!data.name) {
      this.$message.warning('负责人姓名不能为空')
      return
    }
    if (!data.idCard) {
      this.$message.warning('身份证号不能为空')
      return
    }
    if (!data.imgUrl) {
      this.$message.warning('身份证照片不能为空')
      return
    }
    if (data.imgUrl === this.markPng) {
      data.imgUrl = this.initManagerIdentityFront
    }
    this.qrData = data
    this.qrDiaShow = true
    // goAuthorize(data).then((res) => {
    //   this.qrDiaShow = true
    // }).catch()
  }
  changeInput() {
    // let values: string = this.formInfo.idCard || ''
    // this.formInfo.idCard = values.replace(/\s+/g,"");
  }
  // 选择所属行业
  selecttedIndustry(value: any) {
    this.formDatas.companyInfo.industry = value
  }
  // 获取省份数据--
  handleProvinceList() {
    getProvinceList().then((res) => {
      this.options.provinceOption = res.data.data
      this.options.provinceOptions = res.data.data
    })
  }
  // 获取所属行业下拉的数据
  handleIndustry() {
    const data: any = {
      categoryName: 'Industry'
    }
    getIndustryData(data).then((res) => {
      let data: any = res.data.data
      let arr: any = []
      data.forEach((item: any) => {
        arr.push({ label: item.value, value: item.key })
      })
      this.industry = arr
    })
  }
  // 回显映射下拉框数据（市）
  fliterCity(valueCode?: string) {
    let data: any = {
      provinceCode: valueCode
    }
    getCityList(data).then((res) => {
      this.options.cityOption = res.data.data
      this.options.cityOptions = res.data.data
    })
  }
  // 回显映射下拉框数据（区）
  fliterAera(valueCode?: string) {
    let data: any = {
      cityCode: valueCode
    }
    getCountyList(data).then((res) => {
      this.options.countyOption = res.data.data
      this.options.countyOptions = res.data.data
    })
  }
  // ---------------------------注册区域--------------------------------
  selecttedProvince(value: string) {
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      provinceCode: value
    }
    getCityList(data).then((res) => {
      this.options.cityOption = res.data.data
      this.formDatas.companyInfo.registRegionProvince = value
    })
  }
  selecttedCity(value: string) {
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      cityCode: value
    }
    getCountyList(data).then((res) => {
      this.options.countyOption = res.data.data
      this.formDatas.companyInfo.registRegionCity = value
    })
  }
  selecttedCounty(value: string) {
    this.formDatas.companyInfo.registRegionArea = value
  }
  // -----------------------------办公区域------------------------------
  selecttedProvinces(value: string) {
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      provinceCode: value
    }
    getCityList(data).then((res) => {
      this.options.cityOptions = res.data.data
      this.formDatas.companyInfo.workRegionProvince = value
    })
  }
  selecttedCitys(value: string) {
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      cityCode: value
    }
    getCountyList(data).then((res) => {
      this.options.countyOptions = res.data.data
      this.formDatas.companyInfo.workRegionCity = value
    })
  }
  selecttedCountys(value: string) {
    this.formDatas.companyInfo.workRegionArea = value
  }

  // 保存并提交
  handleUpdateForm() {
    let datas: any = this.formDatas
    datas.callbackId = this.callbackId
    this.$emit('formData', { datas, flag: 'edit' })
  }
  handleChangeIpass() {
    const isPassStatus: string = '5'
    // localStore.set('ISPASSSTATUS',isPassStatus)
    this.$emit('triggers', isPassStatus)
  }
  // ---------------提交----------------------------------
  handleSubmit() {
    let datas: any = this.formDatas
    datas.callbackId = this.callbackId
    this.$emit('formData', { datas, flag: 'add' })
  }

  filterSelect(data?: any) {
    // switch(){
    // }
  }
  handleReturn() {
    this.$router.go(-1)
  }
}
