import request, { request as http } from '@/utils/request'
import { AxiosPromise } from 'axios'


// 获取项目公告列表
export function getProjectList(data: Object) {
    return request({
        url: '/projectNotice/queryProjectNotices',
        method: 'POST',
        data: data
    })
}
// 获取项目公告列表NEW
export function getProjectList2(data: Object) {
    return request({
        url: '/projectNotice/page',
        method: 'POST',
        data: data
    })
}

// 获取项目公告详情
export function getProjectDetail(data: Object) {
    return request({
        url: '/projectNotice/queryProjectNoticeDetail',
        method: 'POST',
        data: data
    })
}

// 删除项目公告
export function deleteProject(data: Object) {
    return request({
        url: '/projectNotice/deleteProjectAnnouncement',
        method: 'POST',
        data: data
    })
}
// 下拉接口数据

export function getAeraAndType(data: Object) {
    return request({
        url: '/projectNotice/getAeraAndType',
        method: 'POST',
        data: data
    })
}
export function getSystem(data: Object) {
    return request({
        url: '/projectNotice/getSystem',
        method: 'POST',
        data: data
    })
}

// 新增项目公告
export function addProject(data: Object) {
    return request({
        url: '/projectNotice/addBatchProjectNotice',
        method: 'POST',
        data: data
    })
}

// 置项接口
export function setTopProject(data: Object) {
    return request({
        url: '/projectNotice/setTop',
        method: 'POST',
        data: data
    })
}

// 前端显示/隐藏接口
export function setShowState(data: Object) {
  return request({
    url: '/projectNotice/changeShowStatus',
    method: 'POST',
    data
  })
}

// 更新项目公告
export function uploadProject(data: Object) {
    return request({
        url: '/projectNotice/updateProjectNotice',
        method: 'POST',
        data: data
    })
}



// 获取抵押公告列表
export function getMortgageList(data: Object) {
    return request({
        url: '/mortgageNotice/queryBackendMortgageNotices',
        method: 'POST',
        data: data
    })
}

// 获取抵押公告详情
export function getMortgageDetail(data: Object) {
    return request({
        url: '/mortgageNotice/querymortgageAnnouncementDetail',
        method: 'POST',
        data: data
    })
}

// 删除抵押公告
export function deleteMortgage(data: Object) {
    return request({
        url: '/mortgageNotice/deleteMortgageAnnouncement',
        method: 'POST',
        data: data
    })
}

// 新增抵押公告
export function addMortgage(data: Object) {
    return request({
        url: '/mortgageNotice/addMortgageAnnouncement',
        method: 'POST',
        data: data
    })
}

// 更新抵押公告
export function updateMortgage(data: Object) {
    return request({
        url: '/mortgageNotice/editMortgageAnnouncement',
        method: 'POST',
        data: data
    })
}

// 查询区域
export function searchArea(data: Object) {
    return request({
        url: '/areaConfig/getAeraAndType',
        method: 'POST',
        data: data
    })
}

// 查询有权限的区域
export function searchPowerArea(data: Object) {
    return request({
        url: '/areaConfig/getAeraAndTypeByRole',
        method: 'POST',
        data: data
    })
}

// 查询区域的系统
export function searchAreaStystem(data: Object) {
    return request({
        url: '/areaConfig/getSystem',
        method: 'POST',
        data: data
    })
}

// ----------意见反馈管理---------------

// 获取来源系统
export function getSourceSystem() {
    return request({
        url: '/applicationSystem/getAll',
        method: 'POST'
    })
}

// 获取意见反馈列表
export function getFeedbackList(data: Object) {
    return request({
        url: '/suggestion/page',
        method: 'POST',
        data: data
    })
}

// 获取意见反馈详情
export function getFeedbackDetail(data: Object) {
    return request({
        url: '/suggestion/selectBySuggestionId',
        method: 'POST',
        data: data
    })
}

// banner管理
// 获取banner列表
export function getBannerList(data: Object) {
    return request({
        url: '/bannerImage/page',
        method: 'POST',
        data: data
    })
}

// banner是否启用
export function getBannerEnable(data: Object) {
    return request({
        url: '/bannerImage/isEnable',
        method: 'POST',
        data: data
    })
}

// banner删除
export function getBannerDel(data: Object) {
    return request({
        url: '/bannerImage/delete',
        method: 'POST',
        data: data
    })
}

// banner详情
export function getBannerDetail(data: Object) {
    return request({
        url: '/bannerImage/details',
        method: 'POST',
        data: data
    })
}

// banner保存
export function getBannerSave(data: Object) {
    return request({
        url: '/bannerImage/save',
        method: 'POST',
        data: data
    })
}

// banner链接内容
export function getBannerLink(data: Object) {
    return request({
        url: '/bannerImage/getLinkContent',
        method: 'POST',
        data: data
    })
}
// 获取已成交项目列表
export function getDealItemList(data: Object) {
    return request({
        url: '/back/listingApply/listingApplyPage',
        method: 'POST',
        data: data
    })
}