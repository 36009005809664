var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "main" }, [
    _vm.detailtype == 1
      ? _c("div", { staticClass: "part" }, [
          _vm._m(0),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("公司名称：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("通讯地址：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("企业法人统一社会信用代码：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("法定代表人：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("经营范围：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("办理方式：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ])
          ]),
          _vm._m(1),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("授权委托人：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("座机：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("证件类型：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("证件号：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("E-mail地址：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("个人简介：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("网站：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("授权委托人身份证：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("授权委托书：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ])
          ]),
          _vm._m(2),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("会员合同：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ])
          ])
        ])
      : _vm._e(),
    _vm.detailtype == 2
      ? _c("div", { staticClass: "part" }, [
          _vm._m(3),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("姓名：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("通讯地址：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("手机：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("证件类型：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("证件号：")]),
              _c("span", [_vm._v(_vm._s(" baseInfo.status"))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("E-mail地址：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("个人简介：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ])
          ]),
          _vm._m(4),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("会员合同：")]),
              _c("span", [_vm._v(_vm._s("baseInfo.applyTime "))])
            ])
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "part" }, [
      _vm._m(5),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("审批意见：")]),
            _c("el-input", {
              staticClass: "area",
              attrs: { type: "textarea", autosize: { minRows: 6 } },
              model: {
                value: _vm.remark,
                callback: function($$v) {
                  _vm.remark = $$v
                },
                expression: "remark"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { type: "primary", size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("拒绝")]
          ),
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { type: "danger", size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("通过")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("申请信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("委托人信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("会员合同")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("申请信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("会员合同")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("审批信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }