
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { authenticationDropdownInfo, authenticationManagementList, getAreaPowerList, getBusinessTypeList } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class AuthenticationManagement extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private areaOptions: any = []
  private businessTypeOptions: any = [] // 业务类型
  private applyPersonTypeOptions: any = [] // 申请人类型
  private statusOptions: any = [] // 状态类型
  private applyVersionOptions: any = [] // 申请模板类型
  private typeOptions: any = [] // 证书类型
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    businessType: '',
    zctUser: '',
    applyPersonType: '',
    status: '',
    // location: ''
    areaConfigId: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next(async (vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'AuthenticationApplicationDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaConfigId && (vim.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaConfigId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      if (isGetList && !vim.typeOptions.length) {
        await authenticationDropdownInfo({ menu: '鉴证书管理' }).then((res) => {
          if (res.data.data) {
            let data = res.data.data
            data.apply_certificate && data.apply_certificate.apply_version && (vim.applyVersionOptions = data.apply_certificate.apply_version)
            data.apply_certificate && data.apply_certificate.apply_person_type && (vim.applyPersonTypeOptions = data.apply_certificate.apply_person_type)
            data.apply_certificate && data.apply_certificate.type && (vim.typeOptions = data.apply_certificate.type)
            data.apply_certificate && data.apply_certificate.status && (vim.statusOptions = data.apply_certificate.status)
          }
        })
      }
      if (isGetList && !vim.businessTypeOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessTypeOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'AuthenticationApplicationDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        businessTypeOptions: this.businessTypeOptions,
        applyPersonTypeOptions: this.applyPersonTypeOptions,
        statusOptions: this.statusOptions,
        applyVersionOptions: this.applyVersionOptions,
        typeOptions: this.typeOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private bTypeVal(val: any) {
    let retuenDesc: string = ''
    if (this.businessTypeOptions.length) {
      for (let i = 0; i < this.businessTypeOptions.length; i++) {
        if (val == this.businessTypeOptions[i].businessType) {
          retuenDesc = this.businessTypeOptions[i].businessTypeDesc
        }
      }
    }
    return retuenDesc
  }
  private getOptionsVal(val: any, type: string) {
    let nowOptions = type == 'applyPersonTypeOptions' ? this.applyPersonTypeOptions : type == 'statusOptions' ? this.statusOptions : type == 'applyVersionOptions' ? this.applyVersionOptions : []
    if (nowOptions.length) {
      for (let i = 0; i < nowOptions.length; i++) {
        if (nowOptions[i].value == val) {
          return nowOptions[i].name
        }
      }
    } else {
      return val
    }
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaConfigId' || (key == 'areaConfigId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      let beginTime = new Date(this.time[0]).getTime(),
        endTime = new Date(this.time[1]).getTime()
      this.$set(this.formInfo, 'startTime', beginTime)
      this.$set(this.formInfo, 'endTime', endTime)
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    authenticationManagementList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    let pushOptions = {
      businessTypeOptions: this.businessTypeOptions,
      applyPersonTypeOptions: this.applyPersonTypeOptions,
      // statusOptions: this.statusOptions,
      applyVersionOptions: this.applyVersionOptions,
      typeOptions: this.typeOptions
    }
    sessionStorage.setItem('pushOptions', JSON.stringify(pushOptions))
    this.$router.push({
      name: 'AuthenticationApplicationDetail',
      params: {
        id: row.id
      }
    })
  }
}
