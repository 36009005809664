
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import uploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
import { PaginationParam } from '../../type'
// import { quillEditor } from 'vue-quill-editor'
import uploadTemp from '@/components/UploadFile/index.vue'
// 接口联调
import { getProjectDetail, addProject, uploadProject, searchArea, searchAreaStystem, getDealItemList } from '@/api/noticeManagement'
// import { getPropertyrightTransactionCenterData } from '@/api/login'
import timeFormat from '@/utils/timeFormat'
import { loadingText } from '@/utils/defaultGoalConfig'
const VueUeditorWrap = require('vue-ueditor-wrap')

@Component({
  components: {
    Pagination,
    // quillEditor,
    VueUeditorWrap,
    uploadBase,
    uploadTemp
  }
})
export default class ProjectNotice extends Vue {
  private loadingTxt: string = loadingText
  private pickerOptions: any = {
    disabledDate(time: any) {
      return time.getTime() > Date.now()
    }
  }
  private editorId: any
  private ueditorObj: any // 百度富文本对象
  private myConfig: any = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 240,
    // 初始容器宽度
    initialFrameWidth: '100%',
    pasteplain: true,
    filterTxtRules: function() {
      function delDom(node: any) {
        // 如果是图片、超链接 直接删除
        node.parentNode.removeChild(node, node.innerText())
      }
      return {
        img: delDom
      }
    },
    enableAutoSave: false,
    iframeCssUrl: './static/tableStyle.css',
    catchRemoteImageEnable: false, //设置是否抓取远程图片
    elementPathEnabled: false, //是否启用元素路径
    wordCount: false, //是否开启字数统计
    serverUrl: `${process.env.VUE_APP_BASE_URL}file/upload`,
    // toolbars: [['bold', 'indent', 'italic', 'underline', 'strikethrough', 'fontsize', 'forecolor', 'backcolor', 'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols']]
    toolbars: [
      [
        'undo', //撤销
        'redo', //重做
        'bold', //加粗
        'indent', //首行缩进
        'italic', //斜体
        'underline', //下划线
        'strikethrough', //删除线
        'subscript', //下标
        'fontborder', //字符边框
        'superscript', //上标
        'formatmatch', //格式刷
        'selectall', //全选
        'horizontal', //分隔线
        'removeformat', //清除格式
        'time', //时间
        'date', //日期
        'inserttable', //插入表格
        'deletetable', //删除表格
        'insertrow', //前插入行
        'insertcol', //前插入列
        'mergeright', //右合并单元格
        'mergedown', //下合并单元格
        'deleterow', //删除行
        'deletecol', //删除列
        'splittorows', //拆分成行
        'splittocols', //拆分成列
        'splittocells', //完全拆分单元格
        'deletecaption', //删除表格标题
        'inserttitle', //插入标题
        'mergecells', //合并多个单元格
        'cleardoc', //清空文档
        'insertparagraphbeforetable', //"表格前插入行"
        // 'fontfamily', //字体
        'fontsize', //字号
        'paragraph', //段落格式
        'edittable', //表格属性
        'edittd', //单元格属性
        'spechars', //特殊字符
        'justifyleft', //居左对齐
        'justifyright', //居右对齐
        'justifycenter', //居中对齐
        'justifyjustify', //两端对齐
        'forecolor', //字体颜色
        'backcolor', //背景色
        'insertorderedlist', //有序列表
        'insertunorderedlist', //无序列表
        'directionalityltr', //从左向右输入
        'directionalityrtl', //从右向左输入
        'rowspacingtop', //段前距
        'rowspacingbottom', //段后距
        'pagebreak', //分页
        'imagenone', //默认
        'imageleft', //左浮动
        'imageright', //右浮动
        'imagecenter', //居中
        'lineheight', //行间距
        'edittip ', //编辑提示
        'customstyle', //自定义标题
        'autotypeset', //自动排版
        'touppercase', //字母大写
        'tolowercase' //字母小写
      ]
    ]
  }

  private limt: number = 1
  private accept: string = 'image/*, .doc, .docx, .zip, .rar, .xls, .xlsx, .txt, .pdf'
  private fileSizeFilter: boolean = false

  private vLoading: boolean = false
  private isSuperAdmin: any = '0'
  // ------富文本-------------------
  // private editorOption = {
  //   placeholder: '编辑文章内容',
  //   modules: {
  //     toolbar: {
  //       container: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{ header: 1 }, { header: 2 }], [{ list: 'ordered' }, { list: 'bullet' }], [{ script: 'sub' }, { script: 'super' }], [{ indent: '-1' }, { indent: '+1' }], [{ direction: 'rtl' }], [{ size: ['small', false, 'large', 'huge'] }], [{ header: [1, 2, 3, 4, 5, 6, false] }], [{ color: [] }, { background: [] }], [{ font: [] }], [{ align: [] }], ['clean'], ['image']],
  //       handlers: {
  //         image: function (v: any) {
  //           if (v) {
  //             ;(document as any).querySelector('#upload-btn input').click()
  //           } else {
  //             ;(this as any).quill.format('image', false)
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  onEditorChange({ editor, html, text }: any) {
    this.formInfo.content = html
  }
  //----富文本----end----------------
  private isUpdate: boolean = false
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // private list: any = []
  private formInfo: any = {
    publicTime: '',
    origin: '',
    title: '',
    isTop: false,
    areaConfigId: '',
    configTypeKey: '',
    imgUrl: '',
    description: '',
    content: '',
    systemConfigId: '',
    systemConfigIds: [],
    name: '',
    listingApplyId: '',
    announcementProjectFiles: []
  }
  private rules: any = {
    publicTime: {
      required: true,
      message: '请选择发布时间',
      trigger: 'blur'
    },
    title: {
      required: true,
      message: '请输入项目公告标题',
      trigger: 'blur'
    },
    areaConfigId: {
      required: true,
      message: '请选择所属区域',
      trigger: 'blur'
    },
    configTypeKey: {
      required: true,
      message: '请选择类型',
      trigger: 'blur'
    },
    systemConfigId: {
      required: true,
      message: '请选择发布系统',
      trigger: 'blur'
    },
    content: {
      required: true,
      message: '请输入内容',
      trigger: 'blur'
    },
    name: {
      required: true,
      message: '请选择项目',
      trigger: 'change'
    }
  }
  private handleSelectedArea(data: any) {
    this.oldSearch = ''
    this.formInfo.name = ''
    this.formInfo.listingApplyId = ''
    this.isUpdate && (this.formInfo.systemConfigId = '')
    !this.isUpdate && (this.formInfo.systemConfigIds = [])
    this.systemData = []
    this.handleGetSystemData(data)
  }
  private bgImg: any = bgSrc
  private showEnlarge: boolean = false // 放大图片
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    // uploadUrl: ''
    flag: '1'
  }
  private uploadOption2: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    flag: '2'
  }
  private listShow: boolean = true
  private projectType: any = []
  private areaData: any = []
  private systemData: any = []

  // 请求中标志
  private requestLoanding: boolean = false
  // 展示下拉
  private showInput: boolean = false
  // 是否下拉加载禁止
  private disabled: boolean = false
  // 没有更多数据展示
  private noMore: boolean = false
  // 加载页信息
  private nowPageInfo: any = {
    pageNumber: 1,
    pageSize: 4,
    total: 0
  }
  // 下拉加载中文字显示开关
  private loadin: boolean = true
  // 标的名称查询结果
  private searchResult: any = []
  // 是否离开
  private isFocus: boolean = false
  // 旧查询记录
  private oldSearch: any = ''
  created() {
    if (process.env.VUE_APP_TITLE == 'exercise') {
      this.myConfig.UEDITOR_HOME_URL = '../ruralBg/static/UEditor/'
    }
    // let data: any = {
    //   categoryName: 'propertyright_transaction_center'
    // }
    // getPropertyrightTransactionCenterData(data).then((res) => {
    //   this.list = res.data.data
    // })
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.$route.params.id != 'null') {
      this.isUpdate = true
      delete this.formInfo.systemConfigIds
    } else {
      this.formInfo.publicTime = timeFormat(+new Date())
      if (this.isSuperAdmin != '1') {
        this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId')
        if (this.formInfo.areaConfigId) {
          searchAreaStystem({ areaConfigId: this.formInfo.areaConfigId }).then((res) => {
            if (res.data.data) {
              this.systemData = res.data.data
            }
          })
        }
      }
      delete this.formInfo.systemConfigId
    }
    this.handleGetAeraAndType()
  }
  // 退出删除自定义按钮
  beforeDestroy() {
    delete (window as any).UE._customizeUI['addimage' + this.editorId]
  }
  // 添加自定义按钮
  private addCustomButtom(editorId: any, editorConfig: any) {
    let outThis: any = this as any
    this.editorId = editorId
    ;(window as any).UE.registerUI(
      'addimage' + editorId,
      function(editor: any, uiName: any) {
        // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
        editor.registerCommand(uiName, {
          execCommand: function() {
            outThis.ueditorObj = editor
            ;(document as any).querySelector('#upload-btn input').click()
            // editor.execCommand('inserthtml', `<span style="color: #${editorId.substr(-3)};">这是一段由自定义按钮添加的文字，你点击的编辑器ID是${editorId}</span>`)
          }
        })

        // 创建一个 button
        var btn = new (window as any).UE.ui.Button({
          // 按钮的名字
          name: uiName,
          // 提示
          title: '添加图片',
          // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
          cssRules: 'background-position: -380px 0px;',
          // 点击时执行的命令
          onclick: function() {
            // 这里可以不用执行命令，做你自己的操作也可
            editor.execCommand(uiName)
          }
        })

        // 当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', function() {
          var state = editor.queryCommandState(uiName)
          if (state === -1) {
            btn.setDisabled(true)
            btn.setChecked(false)
          } else {
            btn.setDisabled(false)
            btn.setChecked(state)
          }
        })

        // 因为你是添加 button，所以需要返回这个 button
        return btn
      },
      editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
    )
  }

  //上传文件成功
  private fileUploadSuccess(event: any) {
    this.limt += 1
    if (event && event.response && event.response.code == 1000 && event.response.data) {
      this.formInfo.announcementProjectFiles.push({ fileAddr: event.response.data, fileName: event.name })
    }
  }
  private deleteDownloadUrl(ind: any) {
    this.formInfo.announcementProjectFiles.splice(ind, 1)
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 输入框值改变事件
  private changeInput() {
    this.requestLoanding = false
    this.showInput = true
    this.disabled = false
    this.noMore = false
    this.nowPageInfo.pageNumber = 1
    this.nowPageInfo.total = 0
    this.loadin = true
    this.searchResult = []
    this.upLoad()
  }
  // 滚动刷新
  private upLoad() {
    if (this.requestLoanding) {
      return
    }
    this.requestLoanding = true
    this.loadin = true
    let isFail: boolean = true,
      params: any = {
        name: this.formInfo.name,
        pageNumber: this.nowPageInfo.pageNumber,
        pageSize: this.nowPageInfo.pageSize
      }
    this.isSuperAdmin != '1' && (params.areaId = this.formInfo.areaConfigId)
    getDealItemList(params)
      .then((res) => {
        if (res.data.data) {
          let resArr = res.data.data
          if (resArr.length) {
            this.searchResult.push(...resArr)
            this.nowPageInfo.total = res.data.total
            if (this.nowPageInfo.total > this.nowPageInfo.pageSize * this.nowPageInfo.pageNumber) {
              this.nowPageInfo.pageNumber += 1
              isFail = false
            } else {
              // this.disabled = true
              this.noMore = true
            }
          }
          // else {
          //   this.disabled = true
          // }
        }
      })
      .finally(() => {
        isFail && (this.disabled = true)
        this.loadin = false
        this.requestLoanding = false
      })
  }
  // 输入框失焦事件
  private outDiv() {
    if (!this.isFocus) {
      this.showInput = false
      if (this.formInfo.listingApplyId && this.formInfo.name != this.oldSearch) {
        this.formInfo.name = this.oldSearch
      } else if (!this.formInfo.listingApplyId) {
        this.formInfo.name = ''
      }
    }
  }
  // 输入框选中获得数据
  private getSelectData(obj: any) {
    this.formInfo.name = obj.name
    this.oldSearch = obj.name
    if (this.isSuperAdmin == '1' && this.formInfo.areaConfigId != obj.areaConfigId) {
      this.formInfo.areaConfigId = obj.areaConfigId ? obj.areaConfigId : ''
      this.isUpdate && (this.formInfo.systemConfigId = '')
      !this.isUpdate && (this.formInfo.systemConfigIds = [])
      this.systemData = []
      this.formInfo.areaConfigId && this.handleGetSystemData(this.formInfo.areaConfigId)
    }
    this.formInfo.listingApplyId = obj.listingApplyId
    this.showInput = false
  }

  private filteZct(data: any) {
    let returnData: any = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (['资产通管理平台', '资产通'].indexOf(data[i].systemConfigName) == -1) {
          returnData.push(data[i])
        }
      }
    }
    return returnData
  }
  // 类型数据
  private handleGetAeraAndType() {
    this.vLoading = true
    let params: any = this.isUpdate ? {} : { isDelete: false, isDisable: false }
    searchArea(params)
      .then((res) => {
        this.projectType = res.data.data.project
        res.data.data.aeras && (this.areaData = this.filteAreaOptions(res.data.data.aeras))
      })
      .finally(() => {
        if (this.isUpdate) {
          getProjectDetail({
            id: this.$route.params.id
          })
            .then((res) => {
              if (res.data && res.data.code == 1000 && res.data.data) {
                let resData = res.data.data
                this.formInfo.description = resData.description != null ? resData.description : ''
                this.formInfo.content = resData.content != null ? resData.content : ''
                this.formInfo.title = resData.title
                this.formInfo.isTop = resData.topState != null && resData.topState != '0' ? true : false
                this.formInfo.areaConfigId = resData.areaConfigId != null ? resData.areaConfigId + '' : ''
                this.formInfo.systemConfigId = resData.systemConfigId != null ? resData.systemConfigId + '' : ''
                this.formInfo.publicTime = resData.publicTime ? resData.publicTime : ''
                this.formInfo.origin = resData.origin ? resData.origin : ''
                resData.projectFiles && (this.formInfo.announcementProjectFiles = resData.projectFiles)
                if (resData.imgUrl) {
                  this.formInfo.imgUrl = resData.imgUrl
                  this.uploadOption.limit -= 1
                }
                if (this.formInfo.areaConfigId) {
                  searchAreaStystem({ areaConfigId: this.formInfo.areaConfigId }).then((res) => {
                    if (res.data && res.data.code == 1000 && res.data.data) {
                      this.systemData = res.data.data
                    }
                  })
                }
                if (resData.configType && this.projectType) {
                  for (let i = 0; i < this.projectType.length; i++) {
                    if (this.projectType[i].value == resData.configType) {
                      this.formInfo.configTypeKey = this.projectType[i].key
                      if (this.formInfo.configTypeKey == '4') {
                        this.formInfo.name = resData.listingApplyName ? resData.listingApplyName : ''
                        this.formInfo.listingApplyId = resData.listingApplyId ? resData.listingApplyId : ''
                        this.oldSearch = this.formInfo.name
                      }
                    }
                  }
                }
              }
            })
            .finally(() => {
              this.vLoading = false
            })
        } else {
          this.vLoading = false
        }
      })
  }
  // 所属区域数据
  // private handleBelongArea() {
  //   getAeraAndType({})
  //     .then((res) => {
  //       this.projectType = res.data.data.project
  //     })
  //     .catch()
  //     .finally()
  // }
  // 发布系统
  private handleGetSystemData(id: any) {
    let data: any = {
      areaConfigId: id
    }
    searchAreaStystem(data).then((res) => {
      if (res.data && res.data.code == 1000 && res.data.data) {
        this.systemData = res.data.data
      }
    })
  }
  private getFileUrl(v: any) {
    if (v) {
      this.$set(this.formInfo, 'imgUrl', v.url)
    } else {
      this.uploadOption.limit += 1
    }
  }
  private getFileUrl2(v: any) {
    this.uploadOption2.limit += 1
    if (v) {
      // let quill: any = (this.$refs.myTextEditor as any).quill
      // // 获取光标所在位置
      // let length = quill.getSelection().index
      // // 插入图片  res.url为服务器返回的图片地址
      // quill.insertEmbed(length, 'image', v.url)
      // // 调整光标到最后
      // quill.setSelection(length + 1)
      // 图片插入百度编辑器
      this.ueditorObj.execCommand('inserthtml', '<img src=' + v.url + '>')
    }
  }
  private enlargeImg() {
    this.showEnlarge = true
  }
  private deleteImgFn(index: number, deletedItem: any) {
    this.$confirm('是否确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$set(this.formInfo, 'imgUrl', '')
        this.uploadOption.limit += 1
      })
      .catch(() => {})
  }
  submit() {
    if (!this.validatePass()) {
      return
    }
    this.vLoading = true
    const params: any = {
      publicTime: this.formInfo.publicTime,
      origin: this.formInfo.origin,
      title: this.formInfo.title,
      imgUrl: this.formInfo.imgUrl,
      areaConfigId: this.formInfo.areaConfigId,
      configTypeKey: this.formInfo.configTypeKey,
      content: this.formInfo.content,
      description: this.formInfo.description,
      announcementProjectFiles: this.formInfo.announcementProjectFiles
    }
    let nomTime: any = timeFormat(+new Date()),
      publicTimeArr: any = params.publicTime.split(' ')
    if (publicTimeArr[1] == '00:00:00' && publicTimeArr[0] == nomTime.split(' ')[0]) {
      params.publicTime = nomTime
    }
    if (this.formInfo.isTop) {
      params.topState = '1'
    } else {
      params.topState = '0'
    }
    if (this.formInfo.configTypeKey == '4') {
      params.listingApplyId = this.formInfo.listingApplyId
    }
    if (this.isUpdate) {
      params.systemConfigId = this.formInfo.systemConfigId
      params.id = this.$route.params.id
      uploadProject(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message({
              type: 'success',
              message: '更新成功!'
            })
            this.$router.go(-1)
          }
        })
        .finally(() => {
          this.vLoading = false
        })
    } else {
      params.systemConfigIds = this.formInfo.systemConfigIds
      addProject(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })
            this.$router.go(-1)
          }
        })
        .finally(() => {
          this.vLoading = false
        })
    }
  }
  validatePass() {
    if (!this.formInfo.title) {
      this.$message.error('请填写标题')
      return false
    }
    if (!this.formInfo.publicTime) {
      this.$message.error('请选择发布时间')
      return false
    }
    if (this.formInfo.configTypeKey.length <= 0) {
      this.$message.error('请选择类型')
      return false
    }
    if (!this.formInfo.areaConfigId) {
      this.$message.error('请选择所属区域')
      return false
    }
    if (!this.isUpdate && this.formInfo.systemConfigIds.length <= 0) {
      this.$message.error('请选择发布系统')
      return false
    }
    if (this.isUpdate && !this.formInfo.systemConfigId) {
      this.$message.error('请选择发布系统')
      return false
    }

    if (!this.formInfo.content) {
      this.$message.error('请填写内容')
      return false
    }
    if (this.formInfo.configTypeKey == '4' && !this.formInfo.name) {
      this.$message.error('请输入标的名称')
      return false
    }
    return true
  }
  goBack() {
    this.$router.go(-1)
  }
}
