
import { Component, Vue } from 'vue-property-decorator'
import { organizationalManagementAdd, organizationalManagementUpdate } from '@/api/countrysidePropertyTransaction'
import { powerAreaList } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class OrganizationalDetail extends Vue {
  private mainLoading: boolean = false
  private areaClearable: boolean = true
  private isCity: boolean = true
  private cityAreaOptions: any = []
  private areaOptions: any = []
  private loadingTxt: string = loadingText
  private isUpdate: boolean = false // false新增 true详情
  private isSuperAdmin: any = '0'
  private formInfo: any = {
    id: '',
    cityAreaConfigId: '',
    areaConfigId: '',
    name: '',
    code: '',
    contacts: '',
    contactNumber: ''
  }
  private rules: any = {
    cityAreaConfigId: {
      required: true,
      message: '请选择所属区域',
      trigger: 'blur'
    },
    name: {
      required: true,
      message: '请输入机构名称',
      trigger: 'blur'
    },
    code: {
      required: true,
      message: '请输入机构代码',
      trigger: 'blur'
    },
    contacts: {
      required: true,
      message: '请输入联系人',
      trigger: 'blur'
    },
    contactNumber: {
      required: true,
      message: '请输入联系电话',
      trigger: 'blur'
    }
  }
  created() {
    this.mainLoading = true
    let routeParams: any = this.$route.params
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    let nowAreaconfigInfo: any, nowAreaconfigId: any
    if (this.isSuperAdmin != '1') {
      nowAreaconfigInfo = sessionStorage.getItem('nowAreaconfigInfo')
      if (nowAreaconfigInfo) {
        nowAreaconfigInfo = JSON.parse(nowAreaconfigInfo)
        nowAreaconfigId = sessionStorage.getItem('nowAreaconfigId')
        if (nowAreaconfigInfo.areaName) {
          this.isCity = false
          if (!this.formInfo.cityAreaConfigId) {
            this.formInfo.cityAreaConfigId = nowAreaconfigInfo.superAreaConfigName ? nowAreaconfigInfo.superAreaConfigName : '无'
            nowAreaconfigId && (this.formInfo.areaConfigId = nowAreaconfigId)
          }
        } else {
          nowAreaconfigId && (this.formInfo.cityAreaConfigId = nowAreaconfigId)
        }
      }
    }
    let editAreaId: any = null
    if (routeParams.type == 'edit') {
      let row: any = null
      if (routeParams.row) {
        sessionStorage.setItem('organizationalDetail', routeParams.row)
        row = JSON.parse(routeParams.row)
      } else {
        row = JSON.parse(sessionStorage.getItem('organizationalDetail') as any)
      }
      Object.keys(this.formInfo).forEach((key: any) => {
        if (['areaConfigId', 'cityAreaConfigId'].indexOf(key) == -1) {
          this.formInfo[key] = row[key] ? row[key] : ''
        } else if (key == 'areaConfigId') {
          editAreaId = row[key]
        }
      })
      this.isUpdate = true
    }
    powerAreaList()
      .then((res) => {
        if (res.data.data && res.data.data.aeras) {
          let arrList: any = this.getAreaOptions(res.data.data.aeras)
          this.cityAreaOptions = arrList.city
          this.areaOptions = arrList.area
          this.isUpdate && this.judgeNowAreaType(editAreaId)
        }
      })
      .finally(() => {
        this.mainLoading = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStorage.removeItem('organizationalDetail')
    next()
  }
  private judgeNowAreaType(areaId: any) {
    if (!areaId) {
      return
    }
    if (this.isSuperAdmin != 1) {
      if (!this.isCity) {
        this.formInfo.areaConfigId = areaId
      } else {
        this.formInfo.cityAreaConfigId = areaId
      }
    } else {
      for (let i = 0; i < this.cityAreaOptions.length; i++) {
        let nowCityAreaOptions: any = this.cityAreaOptions[i]
        if (nowCityAreaOptions.areaConfigId == areaId) {
          this.formInfo.cityAreaConfigId = nowCityAreaOptions.areaConfigId
          this.areaOptions = nowCityAreaOptions.sonAreas
          if (nowCityAreaOptions.areaConfigName == '无') {
            this.areaClearable = false
            this.formInfo.areaConfigId = nowCityAreaOptions.sonAreas[0].areaConfigId
          }
          break
        } else if (nowCityAreaOptions.sonAreas && nowCityAreaOptions.sonAreas.length) {
          for (let l = 0; l < nowCityAreaOptions.sonAreas.length; l++) {
            if (nowCityAreaOptions.sonAreas[l].areaConfigId == areaId) {
              this.formInfo.cityAreaConfigId = nowCityAreaOptions.areaConfigId
              this.areaOptions = nowCityAreaOptions.sonAreas
              this.formInfo.areaConfigId = nowCityAreaOptions.sonAreas[l].areaConfigId
              return
            }
          }
        }
      }
    }
  }
  private getAreaOptions(arr: any) {
    let returnArrList: any = {
      city: [],
      area: []
    }
    if (this.isSuperAdmin != '1') {
      if (this.isCity) {
        returnArrList.city.push({ areaConfigId: arr[0].areaConfigId, areaConfigName: arr[0].areaConfigName })
        arr[0].sonAreas && (returnArrList.area = arr[0].sonAreas)
      } else {
        returnArrList.area.push(arr[0])
      }
    } else {
      let otherArea: any = {
        areaConfigName: '无',
        areaConfigId: '',
        sonAreas: []
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].area) {
          !otherArea.sonAreas.length && (otherArea.areaConfigId = arr[i].areaConfigId)
          otherArea.sonAreas.push(arr[i])
        } else {
          returnArrList.city.push(arr[i])
        }
      }
      otherArea.sonAreas.length && returnArrList.city.push(otherArea)
    }
    return returnArrList
  }
  private selecttedCity(value: any) {
    this.formInfo.areaConfigId = ''
    this.areaOptions = []
    let cityAreaOptions: any = this.cityAreaOptions
    for (let i = 0; i < cityAreaOptions.length; i++) {
      if (cityAreaOptions[i].areaConfigId == value) {
        if (cityAreaOptions[i].sonAreas && cityAreaOptions[i].sonAreas.length) {
          this.areaClearable = cityAreaOptions[i].areaConfigName == '无' ? false : true
          this.areaOptions = cityAreaOptions[i].sonAreas
          cityAreaOptions[i].areaConfigName == '无' && (this.formInfo.areaConfigId = this.areaOptions[0].areaConfigId)
        }
        break
      }
    }
  }
  private interChangeInput(value: any) {
    this.formInfo.contactNumber = value.replace(/[^\d]/g, '')
  }
  private submit() {
    if (!this.formInfo.name) {
      return this.$message.error('请输入机构名称')
    }
    if (!this.formInfo.areaConfigId && !this.formInfo.cityAreaConfigId) {
      return this.$message.error('请选择所属区域')
    }
    if (!this.formInfo.code) {
      return this.$message.error('请输入机构代码')
    }
    if (!this.formInfo.contacts) {
      return this.$message.error('请输入联系人')
    }
    if (!this.formInfo.contactNumber) {
      return this.$message.error('请输入联系电话')
    }
    this.mainLoading = true
    let params: any = {
      ...this.formInfo
    }
    !params.areaConfigId && (params.areaConfigId = params.cityAreaConfigId)
    delete params.cityAreaConfigId
    if (this.isUpdate) {
      organizationalManagementUpdate(params)
        .then(() => {
          this.$message.success('提交成功')
          this.goBack()
        })
        .finally(() => {
          this.mainLoading = false
        })
    } else {
      delete params.id
      organizationalManagementAdd(params)
        .then(() => {
          this.$message.success('提交成功')
          this.goBack()
        })
        .finally(() => {
          this.mainLoading = false
        })
    }
  }
  private goBack() {
    this.$router.go(-1)
  }
}
