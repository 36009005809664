var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "blacklist-detail" },
    [
      _c(
        "el-form",
        {
          ref: "formInfo",
          staticClass: "form-style",
          attrs: {
            rules: _vm.rules,
            model: _vm.formInfo,
            size: "small",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "subsidy-item",
              attrs: { label: "个人/企业：", prop: "type" }
            },
            [
              _vm.type == "add"
                ? _c(
                    "el-select",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.formInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "type", $$v)
                        },
                        expression: "formInfo.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                : _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.formInfo.type == "1"
                          ? "企业"
                          : _vm.formInfo.type == "2"
                          ? "个人"
                          : _vm.formInfo.type
                      )
                    )
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "subsidy-item",
              attrs: { label: "姓名/企业名称：", prop: "name" }
            },
            [
              _vm.type == "add"
                ? _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.formInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "name", $$v)
                      },
                      expression: "formInfo.name"
                    }
                  })
                : _c("div", [_vm._v(_vm._s(_vm.formInfo.name))])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "subsidy-item",
              attrs: { label: "身份证号/社会信用代码：", prop: "cardOrCode" }
            },
            [
              _vm.type == "add"
                ? _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入" },
                    model: {
                      value: _vm.formInfo.cardOrCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "cardOrCode", $$v)
                      },
                      expression: "formInfo.cardOrCode"
                    }
                  })
                : _c("div", [_vm._v(_vm._s(_vm.formInfo.cardOrCode))])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "subsidy-item",
              attrs: { label: "添加原因：", prop: "reason" }
            },
            [
              _vm.type == "add"
                ? _c("el-input", {
                    staticClass: "area",
                    attrs: {
                      type: "textarea",
                      size: "small",
                      placeholder: "请输入",
                      resize: "none",
                      autosize: { minRows: 4 }
                    },
                    model: {
                      value: _vm.formInfo.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "reason", $$v)
                      },
                      expression: "formInfo.reason"
                    }
                  })
                : _c("div", { staticClass: "area" }, [
                    _vm._v(_vm._s(_vm.formInfo.reason))
                  ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-style" },
        [
          _c("el-button", { on: { click: _vm.handleReturn } }, [
            _vm._v("返回")
          ]),
          _vm.type == "add"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("提交")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }