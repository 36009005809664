
import { Component, Vue } from 'vue-property-decorator'
// 接口联调
import { addMortgage, updateMortgage, searchArea, getMortgageDetail } from '@/api/noticeManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component
export default class ProjectNotice extends Vue {
  private loadingTxt: string = loadingText
  // private optionsId = [
  //   {
  //     value: '0000',
  //     label: '根据抵押人去请求项目编号'
  //   },
  //   {
  //     value: '1111',
  //     label: '根据抵押人去请求项目编号'
  //   },
  //   {
  //     value: '2222',
  //     label: '根据抵押人去请求项目编号'
  //   }
  // ]
  private isSuperAdmin: any = '0'
  private areaOptions: any = []
  private formInfo: any = {
    registrationNum: '',
    mortgagorName: '',
    projectNo: '',
    mortgageHolderName: '',
    areaConfigId: '',
    collateralName: '',
    collateralLocation: '',
    loanMoney: '',
    time: '',
    mortgageState: '1',
    mortgageStartTime: '',
    mortgageEndTime: ''
  }

  private mortgageState = [
    {
      value: '1',
      label: '在押'
    },
    {
      value: '2',
      label: '解押'
    }
  ]

  private rules = {
    registrationNum: {
      required: true,
      message: '请输入抵押登记编号',
      trigger: 'blur'
    },
    mortgagorName: {
      required: true,
      message: '请输入抵押人',
      trigger: 'blur'
    },
    projectNo: {
      required: true,
      message: '请选择项目编号',
      trigger: 'blur'
    },
    loanMoney: {
      required: true,
      message: '请输入贷款金额',
      trigger: 'blur'
    },
    mortgageHolderName: {
      required: true,
      message: '请输入抵押权人',
      trigger: 'blur'
    },
    areaConfigId: {
      required: true,
      message: '请输入区域',
      trigger: 'blur'
    },
    collateralName: {
      required: true,
      message: '请输入抵押物名称',
      trigger: 'blur'
    },
    collateralLocation: {
      required: true,
      message: '请输入抵押物所在地',
      trigger: 'blur'
    },
    time: {
      required: true,
      message: '请选择抵押期限',
      trigger: 'blur'
    }
  }

  private loading = false

  private updateId: any = ''

  created() {
    this.loading = true
    let nowId = this.$route.params.id
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (nowId != 'null') {
      this.updateId = nowId
      getMortgageDetail({
        id: nowId
      })
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            Object.keys(this.formInfo).forEach((key) => {
              key != 'time' && (this.formInfo[key] = res.data.data[key] ? res.data.data[key] + '' : '')
              if (res.data.data[key] && (key == 'mortgageStartTime' || key == 'mortgageEndTime')) {
                !this.formInfo.time && (this.formInfo.time = [])
                this.$set(this.formInfo.time, key == 'mortgageStartTime' ? 0 : 1, res.data.data[key])
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false
      this.isSuperAdmin != '1' && (this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
    }
    let params: any = nowId != 'null' ? {} : { isDelete: false, isDisable: false }
    searchArea(params).then((res) => {
      if (res.data.data && res.data.data.aeras) {
        this.areaOptions = this.filteAreaOptions(res.data.data.aeras)
      }
    })
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  submit() {
    const anyThing: any = this.$refs.formMine
    anyThing.validate((valid: any) => {
      if (valid) {
        if (this.formInfo.time[0]) {
          this.$set(this.formInfo, 'mortgageStartTime', this.formInfo.time[0])
          this.$set(this.formInfo, 'mortgageEndTime', this.formInfo.time[1])
        }
        let params: any = {
          ...this.formInfo
        }
        delete params.time
        this.loading = true
        if (this.updateId) {
          params.id = this.updateId
          updateMortgage(params)
            .then((res) => {
              if (res.data && res.data.code == 1000) {
                this.$message({
                  type: 'success',
                  message: '更新成功'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          addMortgage(params)
            .then((res) => {
              if (res.data && res.data.code == 1000) {
                // this.updateId = res.data.data.id
                this.$message({
                  type: 'success',
                  message: '添加成功'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      } else {
        return false
      }
    })
  }

  // querySearchAsync(queryString: any, cb: any) {
  //   // 根据查询内容请求接口返回 匹配的数组
  //   let aaa = [{ value: queryString }]
  //   cb(aaa)
  // }
  // handleSelect(item: any) {
  //   console.log(item)
  // }
}
