
import { Component, Vue, Prop } from 'vue-property-decorator'

interface Info {
  [key: string]: string | number
}

@Component({})
export default class TradeDetails extends Vue {
  @Prop() othserInfo: any
  @Prop() tradeState: any
  get bankCard() {
    let returnVal: string = ''
    if (this.othserInfo.payOpenBank) {
      returnVal += this.othserInfo.payOpenBank
    }
    let payBankNumber: any = this.othserInfo.payBankNumber
    if (payBankNumber) {
      if (payBankNumber.length >= 4) {
        payBankNumber = payBankNumber.slice(payBankNumber.length - 4)
      }
      returnVal += '(' + payBankNumber + ')'
    }
    return returnVal
  }

  private jumpTheirList() {
    this.$emit('tradeReceiveList')
  }

  private prev() {
    this.$emit('tradeReceipt')
  }
}
