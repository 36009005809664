
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getProvinceList, getCityList, getCountyList, getTownshipList } from '@/api/peasantInfoManagement'
import { VillagePage, VillageEditShow } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class VillageCreditList extends Vue {
  private loadingTxt: string = loadingText
  private addPower: boolean = false // 添加权限
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private villageStatusOptions: any = [
    { label: '是', value: 3 },
    { label: '否', value: 4 }
  ]
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    uname: '',
    villageStatus: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  // created() {
  //   getProvinceList().then((res) => {
  //     if (res.data.code == 1000) {
  //       this.provinceOptions = res.data.data
  //     }
  //   })
  //   this.getList()
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next(async (vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'VillageCreditDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.cityOptions = pageParams.cityOptions
        vim.countyOptions = pageParams.countyOptions
        vim.townshipOptions = pageParams.townshipOptions
        if (pageParams.provinceOptions.length) {
          vim.provinceOptions = pageParams.provinceOptions
        }
      }
      if (!vim.provinceOptions.length) {
        await getProvinceList().then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      vim.getList(this)
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'VillageCreditDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    VillagePage(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private changeFn(row: any) {
    let contentText = '是否确认允许前端展示？'
    if (!row.isShow) {
      contentText = '是否确认不允许前端展示？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        VillageEditShow({ id: row.id, isShow: row.isShow ? 1 : 0 })
          .then((res) => {
            if (res.data && res.data.code == 1000) {
              this.getList()
            }
          })
          .catch(() => {
            row.isShow = !row.isShow
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {
        row.isShow = !row.isShow
      })
  }
  // 搜索
  private handleSearch() {
    this.getList()
  }
  // 详情
  private handleDetail(row: any) {
    this.$router.push({
      name: 'VillageCreditDetails',
      params: { id: row ? row.villageId : 0 }
    })
  }
}
