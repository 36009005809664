
import { Component, Vue } from 'vue-property-decorator'
import infoCheck from './components/info-check.vue'
import financialData from './components/financial-data.vue'
import ratingResult from './components/rating-result.vue'

import { getCompanyCreditPageDetail } from '@/api/enterpriseCreditRating'

@Component({
  components: {
    infoCheck,
    financialData,
    ratingResult
  }
})
export default class RatingDetail extends Vue {
  private activeName: string = '1'
  private info: any = {
    companyCreditRateRecord: {},
    companyInformationResponse: {
      fileAddr: ''
    },
    companyCreditRateDetails: [],
    companyCreditIDResponses: []
  }

  created() {
    let params = {
      id: this.$route.params.id
    }
    getCompanyCreditPageDetail(params).then((res: any) => {
      this.info = res.data.data
    })
  }
}
