var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contents" }, [
    _c("div", { staticClass: "baseInfo" }, [_vm._v("基本信息")]),
    _vm.datas.needApply === "0" || _vm.isZctOK
      ? _c("div", { staticClass: "itemBox" }, [
          _c("div", { staticClass: "itemContent" }, [
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info" }, [_vm._v("单位名称：")])
              : _vm._e(),
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(
                    _vm._s(_vm.datas.companyName) +
                      "（" +
                      _vm._s(_vm._f("filterStatus")(_vm.datas.auditStatus)) +
                      "）"
                  )
                ])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [_vm._v("姓名：")])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(_vm._s(_vm.datas.name) + "（已认证）")
                ])
              : _vm._e(),
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c(
                  "span",
                  {
                    staticClass: "info blue",
                    on: { click: _vm.gotoCheckDetail }
                  },
                  [_vm._v("查看")]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info margin" }, [_vm._v("账号：")])
              : _vm._e(),
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(_vm._s(_vm.datas.phone))
                ])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [_vm._v("身份证：")])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(_vm._s(_vm.datas.identityCard))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info" }, [_vm._v("认证时间：")])
              : _vm._e(),
            _vm.datas.role == "2" || _vm.datas.role == "3"
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(_vm._s(_vm._f("filterTimeStamp")(_vm.datas.auditTime)))
                ])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [_vm._v("账号：")])
              : _vm._e(),
            _vm.datas.role == "1" || _vm.isZctOK
              ? _c("span", { staticClass: "info" }, [
                  _vm._v(_vm._s(_vm.datas.phone))
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }