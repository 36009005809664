var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("产品相关")]),
        _vm.openShow && _vm.info && _vm.info.publicInfo
          ? _c(
              "div",
              { staticClass: "open-box" },
              [
                _c("span", [_vm._v("是否公开")]),
                _c("el-switch", {
                  on: { change: _vm.openChange },
                  model: {
                    value: _vm.info.publicInfo.status,
                    callback: function($$v) {
                      _vm.$set(_vm.info.publicInfo, "status", $$v)
                    },
                    expression: "info.publicInfo.status"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.redTitle && (_vm.detailtype == 1 || _vm.detailtype == 5)
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("资金用途")]),
                _vm.checkboxShow && _vm.itemShow.purpose
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.purpose,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "purpose", $$v)
                        },
                        expression: "itemStatus.purpose"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.purpose
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.purpose))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("底层资产风控手段 (增信措施)")]),
                _vm.checkboxShow && _vm.itemShow.addCreditMeasures
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.addCreditMeasures,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "addCreditMeasures", $$v)
                        },
                        expression: "itemStatus.addCreditMeasures"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.addCreditMeasures
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.addCreditMeasures))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("最低成立规模 (万)")]),
                _vm.checkboxShow && _vm.itemShow.minScale
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.minScale,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "minScale", $$v)
                        },
                        expression: "itemStatus.minScale"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.minScale
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.minScale))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("还款途径")]),
                _vm.checkboxShow && _vm.itemShow.repaymentWay
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.repaymentWay,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "repaymentWay", $$v)
                        },
                        expression: "itemStatus.repaymentWay"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.repaymentWay
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.repaymentWay))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("分配方式")]),
                _vm.checkboxShow && _vm.itemShow.paymentMethod
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.paymentMethod,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "paymentMethod", $$v)
                        },
                        expression: "itemStatus.paymentMethod"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.paymentMethod
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.paymentMethod))]
                )
              ],
              1
            ),
            _vm.info.firstRepayTime && _vm.info.paymentMethod != "到期还本"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("首次还款日")]),
                    _vm.checkboxShow && _vm.itemShow.firstRepayTime
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.firstRepayTime,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "firstRepayTime", $$v)
                            },
                            expression: "itemStatus.firstRepayTime"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: {
                          "red-font":
                            _vm.isShowRed &&
                            ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                              _vm.detailtype == 0) &&
                            _vm.itemShow.firstRepayTime
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.info.firstRepayTime +
                              (_vm.info.firstRepayTime ? "号" : "")
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("入围要素")]),
                _vm.checkboxShow && _vm.itemShow.shortcutElements
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.shortcutElements,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "shortcutElements", $$v)
                        },
                        expression: "itemStatus.shortcutElements"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.shortcutElements
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.shortcutElements))]
                )
              ],
              1
            ),
            _vm.info.investmentPrice != null &&
            _vm.info.listingMode != 1 &&
            _vm.info.listingMode != 3
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("起投金额 (场内) (万)")]),
                    _vm.checkboxShow && _vm.itemShow.investmentPrice
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.investmentPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "investmentPrice", $$v)
                            },
                            expression: "itemStatus.investmentPrice"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: {
                          "red-font":
                            _vm.isShowRed &&
                            ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                              _vm.detailtype == 0) &&
                            _vm.itemShow.investmentPrice
                        }
                      },
                      [_vm._v(_vm._s(_vm.info.investmentPrice))]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }