
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PersonnelForm } from '@/views/type'
// import { getAllRole, addPersonnel, upDatePersonnel } from '@/api/personnel'
import { decodeS } from 'nzh/dist/nzh.cn.min.js'
import upLoadFileImg from '@/components/upLoadFileImg/index.vue'
import uploadFileBtn from '@/components/UploadBase64/index.vue'
import { getProvinceList, getCityList, getCountyList } from '@/api/peasantInfoManagement'
import { getIndustryData } from '@/api/login'
import { searchArea } from '@/api/noticeManagement'
import { localStore } from '@/utils/data-management'

@Component({
  components: {
    upLoadFileImg,
    uploadFileBtn
  }
})
export default class AuthForm extends Vue {
  // @Prop({ default: false, type: Boolean }) private disabled?: boolean
  @Prop({ default: 0, type: Number }) private types!: number
  // @Prop({}) private propertyRightTransactionCenterData!: Array<string>
  // @Prop({}) private industryData!: Array<string>
  // @Prop({}) private provinceOptions!: Array<string>

  @Prop({ default: '', type: String }) private isPass!: string
  @Prop({
    default: () => {
      {
      }
    },
    type: Object || Array
  })
  private formDatass!: Object
  //----------------------------- data部分------------------------------------

  private recommenderCodeValue: string = ''
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    listType: 'picture-card'
    // tip: '请上传pdf格式文件',
  }
  private uploadOptionBtn: any = {
    limit: 1,
    accept: '.pdf',
    // listType: 'picture-card',
    tip: '请上传pdf格式文件'
  }
  private listShow: boolean = true
  private options: any = {
    provinceOptions: [],
    cityOptions: [],
    countyOptions: [],
    provinceOption: [],
    cityOption: [],
    countyOption: [],
    areaOptions: []
  }
  private formDatas: any = {
    companyInfo: {},
    companyFileRelList: []
  }
  private reffLinkImg: string = ''
  private refgLinkImg: string = ''
  private refhLinkImg: string = ''
  private industry: Array<string> = []

  private rules = {
    // name: { required: true, message: '请输入名字', trigger: 'blur' },
    // idCard: { required: true, message: '请输入身份证', trigger: 'blur' },
    // phone: { required: true, message: '请输入手机号', trigger: 'blur' },
    // email: { required: true, message: '请输入邮箱', trigger: 'blur' },
    // roleId: { required: true, message: '请选择角色', trigger: 'blur' },
    // recommendCode: { required: true, message: '请输入推荐人代码', trigger: 'blur' }
  }
  // private isPassStatus:string = ''
  // -------------周期 过滤 计算属性 监听等函数 --部分---------------------------
  // get isPassStatus() {
  //   console.log(localStore.get('ISPASSSTATUS'));
  //   debugger
  //   return localStore.get('ISPASSSTATUS')
  // }
  @Watch('isPass', { deep: true, immediate: true })
  private handleIsPass(newValue: any) {
    // console.log(newValue)

    if (newValue) this.isPassStatus = newValue
  }
  private isPassStatus: string = ''
  @Watch('types', { deep: true, immediate: true })
  private typess(newValue: any) {
    // console.log('typess', newValue)

    this.formDatas.companyInfo.type = newValue ? newValue : 0
  }
  // @Watch('isPass', { deep: true, immediate: true })
  //   private isPassData(newValue: any) {
  //     console.log('isPass---newValue---》', newValue)
  //     if(newValue) this.isPassStatus = newValue
  //   }
  @Watch('formDatass', { deep: true, immediate: true })
  private formData(newValue: any) {
    // console.log('---FormDetail组件watch------newValue----->', newValue)

    if (newValue.companyFileRels && newValue.companyFileRels.length > 0) {
      this.formDatas.companyFileRelList = newValue.companyFileRels
      // console.log(newValue.company);

      this.formDatas.companyInfo = newValue.company
      this.fliterCity(newValue.company.registRegionProvince)
      this.fliterCity(newValue.company.workRegionProvince)
      this.fliterAera(newValue.company.registRegionCity)
      this.fliterAera(newValue.company.workRegionCity)
    } else {
      // console.log(newValue.company);
      this.formDatas.companyInfo = newValue.company
      this.fliterCity(newValue.company.registRegionProvince)
      this.fliterCity(newValue.company.workRegionProvince)
      this.fliterAera(newValue.company.registRegionCity)
      this.fliterAera(newValue.company.workRegionCity)
    }
  }
  created() {
    searchArea({ isDelete: false, isDisable: false }).then((res) => {
      if (res.data.data && res.data.data.aeras) {
        this.options.areaOptions = this.filteAreaOptions(res.data.data.aeras)
      }
    })
    this.handleIndustry()
    this.handleProvinceList()
    // console.log("this.types",this.types);
    // console.log('外部跳转的标识---->  ', this.$route.params.type)

    if (this.types) {
      this.formDatas.companyInfo.type = this.types
    }
  }
  mounted() {
    // console.log(this.formDatas.companyFileRelList)
    let uploadList: any = this.formDatas.companyFileRelList
    this.$nextTick(() => {
      ;(this as any).$refs['refb'].fileList = [{ url: this.formDatas.companyInfo.managerIdentityFront, name: '身份证照' }]
      ;(this as any).$refs['refb'].hideUpload = true
      ;(this as any).$refs['refb'].uploadUrl = this.formDatas.companyInfo.managerIdentityFront
      uploadList.forEach((item: any) => {
        if (item.fileCode === '001') {
          ;(this as any).$refs['refa'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refa'].hideUpload = true
          // console.log((this as any).$refs['refa'])
          ;(this as any).$refs['refa'].uploadUrl = item.filePath
        } else if (item.fileCode === '002') {
          ;(this as any).$refs['refb'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refb'].hideUpload = true
          ;(this as any).$refs['refb'].uploadUrl = item.filePath
        } else if (item.fileCode === '003') {
          ;(this as any).$refs['refc'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refc'].hideUpload = true
          ;(this as any).$refs['refc'].uploadUrl = item.filePath
        } else if (item.fileCode === '004') {
          ;(this as any).$refs['refd'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refd'].hideUpload = true
          ;(this as any).$refs['refd'].uploadUrl = item.filePath
        } else if (item.fileCode === '005') {
          ;(this as any).$refs['refe'].fileList = [{ url: item.filePath, name: item.fileName }]
          ;(this as any).$refs['refe'].hideUpload = true
          ;(this as any).$refs['refe'].uploadUrl = item.filePath
        } else if (item.fileCode === '006') {
          this.reffLinkImg = item.filePath
        } else if (item.fileCode === '007') {
          this.refgLinkImg = item.filePath
        } else if (item.fileCode === '008') {
          this.refhLinkImg = item.filePath
        }
      })
    })
    // console.log('父传过来的', this.formDatass)
  }

  // ------------------------------自定义函数部分------------------------------
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 上传完的返回处理方法--
  getFileUrlUrl(data: any) {
    // console.log(data)
    switch (data.flag) {
      case 1:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '001',
          filePath: data.url,
        })

        break
      case 2:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '002',
          filePath: data.url,
        })
        break
      case 3:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '003',
          filePath: data.url,
        })
        break
      case 4:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '004',
          filePath: data.url,
        })
        break
      case 5:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '005',
          filePath: data.url,
        })
        break
      case 6:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '006',
          filePath: data.url,
        })
        this.reffLinkImg = data.url
        break
      case 7:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '007',
          filePath: data.url,
        })
        this.refgLinkImg = data.url
        break
      case 8:
        this.formDatas.companyFileRelList.push({
          fileName: data.name,
          fileCode: '008',
          filePath: data.url,
        })
        this.refhLinkImg = data.url
        break
    }
  }
  // changeInput() {
  // let values: string = this.formInfo.idCard || ''
  // console.log(values.replace(/\s+/g,""));
  // this.formInfo.idCard = values.replace(/\s+/g,"");
  // }
  // 选择所属行业
  selecttedIndustry(value: any) {
    // console.log('Industry', value)
    this.formDatas.companyInfo.industry = value
  }
  // 获取省份数据--
  handleProvinceList() {
    getProvinceList()
      .then((res) => {
        // console.log(res.data)
        this.options.provinceOption = res.data.data
        this.options.provinceOptions = res.data.data
      })
      .catch((res) => {
        console.log(res.data.data)
      })
  }
  // 回显映射下拉框数据（市）
  fliterCity(valueCode?: string) {
    let data: any = {
      provinceCode: valueCode,
    }
    getCityList(data).then((res) => {
      // console.log(res.data)
      this.options.cityOption = res.data.data
      this.options.cityOptions = res.data.data
    })
  }
  // 回显映射下拉框数据（区）
  fliterAera(valueCode?: string) {
    let data: any = {
      cityCode: valueCode,
    }
    getCountyList(data).then((res) => {
      // console.log(res.data)
      this.options.countyOption = res.data.data
      this.options.countyOptions = res.data.data
    })
  }
  // 获取所属行业下拉的数据
  handleIndustry() {
    const data: any = {
      categoryName: 'Industry',
    }
    getIndustryData(data)
      .then((res) => {
        // console.log(res.data)
        let data: any = res.data.data
        let arr: any = []
        data.forEach((item: any) => {
          arr.push({ label: item.value, value: item.key })
        })
        this.industry = arr
      })
      .catch((res) => {
        console.log(res.data)
      })
  }
  // ---------------------------注册区域--------------------------------
  selecttedProvince(value: string) {
    // console.log('省', value)
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      provinceCode: value,
    }
    getCityList(data).then((res) => {
      // console.log(res.data)
      this.options.cityOption = res.data.data
      this.formDatas.companyInfo.registRegionProvince = value
    })
  }
  selecttedCity(value: string) {
    // console.log('市', value)
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      cityCode: value,
    }
    getCountyList(data).then((res) => {
      // console.log(res.data)
      this.options.countyOption = res.data.data
      this.formDatas.companyInfo.registRegionCity = value
    })
  }
  selecttedCounty(value: string) {
    // console.log('区', value)
    this.formDatas.companyInfo.registRegionArea = value
  }
  // -----------------------------办公区域---------------------------------
  selecttedProvinces(value: string) {
    console.log('省', value)
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      provinceCode: value,
    }
    getCityList(data).then((res) => {
      console.log(res.data)
      this.options.cityOptions = res.data.data
      this.formDatas.companyInfo.workRegionProvince = value
    })
  }
  selecttedCitys(value: string) {
    console.log('市', value)
    if (!value) {
      this.$message.error('数据有误，请重新进入页面')
      return
    }
    let data: any = {
      cityCode: value,
    }
    getCountyList(data).then((res) => {
      console.log(res.data)
      this.options.countyOptions = res.data.data
      this.formDatas.companyInfo.workRegionCity = value
    })
  }
  selecttedCountys(value: string) {
    console.log('区', value)
    this.formDatas.companyInfo.workRegionArea = value
  }
  // -----------------------------办公区域--------------------end-----------
}
