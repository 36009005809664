
import { Component, Vue, Prop } from 'vue-property-decorator'
import { offlineProjectShowFile, offlineProjectDeleteFile, offlineProjectUploadFile } from '@/api/countrysidePropertyTransaction'
import uploadBase from '@/components/UploadBase64/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    uploadBase
  }
})
export default class NeedData extends Vue {
  @Prop() info: any
  @Prop({
    default: () => {
      return []
    }
  })
  itemStatus?: any
  @Prop({ default: false }) checkboxShow?: boolean
  @Prop({ default: '' }) offlineProjectId?: any
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg,.pdf,.dox,.docx',
    flag: '1'
  }
  private handleSwitch(val: boolean, fileId: any) {
    this.loading = true
    offlineProjectShowFile({ fileId, isShow: val })
      .then((res) => {
        this.$message.success('展示切换成功')
      })
      .finally(() => {
        this.loading = false
      })
  }
  private beforeUpFn() {
    this.loading = true
  }
  private uploadError() {
    this.loading = false
  }
  private getFileUrl(v: any) {
    this.uploadOption.limit += 1
    if (v) {
      offlineProjectUploadFile({
        fileName: v.name,
        fileUrl: v.url,
        offlineProjectId: this.offlineProjectId
      })
        .then((res) => {
          let datas: any = res.data.data
          if (datas && datas.id) {
            this.$message.success('上传成功')
            this.info.push({
              fileId: datas.id,
              fileName: v.name,
              fileUrl: v.url,
              isShow: false
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.$message.error('上传失败')
      this.loading = false
    }
  }
  private updateFiles() {
    ;(document as any).querySelector('#needfiles-btn input').click()
  }
  private deleteFile(fileId: any, index: any) {
    this.$confirm('是否确认删除？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        offlineProjectDeleteFile({
          fileId
        })
          .then((res) => {
            this.$message.success('删除成功')
            this.info.splice(index, 1)
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
