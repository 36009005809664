var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "qrcode" },
    [
      _c(
        "el-radio-group",
        {
          on: {
            change: function($event) {
              return _vm.getQrcodeUrl()
            }
          },
          model: {
            value: _vm.radio,
            callback: function($$v) {
              _vm.radio = $$v
            },
            expression: "radio"
          }
        },
        [_c("el-radio", { attrs: { label: 1 } }, [_vm._v("微信扫码")])],
        1
      ),
      _c("qrcode-vue", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        class: ["img", { pointer: _vm.status === "timeout" }],
        attrs: { size: _vm.size, value: _vm.qrcodeUrl },
        nativeOn: {
          click: function($event) {
            return _vm.refreshQRCode.apply(null, arguments)
          }
        }
      }),
      _c("div", { staticClass: "result", style: { width: _vm.size + "px" } }, [
        _c("div", [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.verifyResult.icon,
                expression: "verifyResult.icon"
              }
            ],
            attrs: { src: _vm.verifyResult.icon }
          }),
          _c("span", [_vm._v(_vm._s(_vm.verifyResult.text))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }