
import { Component, Vue ,Prop, Watch, Emit } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import dia from '../components/dialog.vue'
@Component({
  components: {dia},
   filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class CompanyJoin extends Vue {
  @Prop() info: any
  public baseInfo: any = {}
  private centerDialogVisible: boolean = false
  @Watch('info', { deep: true })
  handler() {
    this.baseInfo = this.info.projectStatus
  }
  @Emit('goBack')
  goBack() {}
}
