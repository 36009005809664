
import { Component, Vue } from 'vue-property-decorator'
import { getFinancingDetail, activeDelisting, sumbitPaymentNotice, setOpenStatus, getStakeholder } from '@/api/financing'
import stakeholder from './components/stakeholder.vue'
import ProInfo from '../center-list/centers/pro-info.vue'
import ProBaseInfo from '../center-list/centers/pro-base-info.vue'
import proProductRelated from '../center-list/centers/pro-product-related.vue'
import proInfoPerson from '../center-list/centers/pro-info-person.vue'
import dialogLaw from '../center-list/centers/dialog-law.vue'
import appointInvestment from '../center-list/centers/appoint-investment.vue'
import proInfoApproval from '../center-list/centers/pro-info-approval.vue'
import investmentInfo from '../center-list/centers/investment-info.vue'
import QrCode from '@/components/QrCode.vue'
import { Loading } from 'element-ui'
import UploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
// import { isNeedFace } from '@/api/common.api'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    stakeholder,
    investmentInfo,
    ProInfo,
    ProBaseInfo,
    proProductRelated,
    proInfoPerson,
    dialogLaw,
    appointInvestment,
    proInfoApproval,
    QrCode,
    UploadBase
  }
})
export default class ProjectDetails extends Vue {
  private loadingTxt: string = loadingText
  private stakeholderLoading: boolean = false
  private stakeholderTotal: number = 0
  private stakeholderTable: any = []
  private routeParams: any = {}
  private activeName: string = '1' // 当前 tab 选项
  private proDetail: any = {}
  private qrDiaShow: boolean = false
  // 审批信息展示标志
  private approvalShow: boolean = true
  // 是否展示红色提示
  private isShowRed: boolean = false
  private isShowRegistrationNo: boolean = false // 是否展示项目备案登记号
  private eitdId: any = '' // 项目备案登记号编辑id
  private markShow: boolean = false // 弹窗展示标志
  private bgImg: any = bgSrc // 背景图片
  // 上传参数
  private uploadOption: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    flag: '1'
  }
  // 弹窗信息
  private markInfo: any = {
    amount: '',
    fileUrl: ''
  }
  created() {
    this.routeParams = { ...this.$route.params }
    this.routeParams.type = decodeURI(this.routeParams.type)
    if (this.routeParams.id) {
      sessionStorage.setItem('financialDetails', this.routeParams.id)
    } else {
      this.routeParams.id = sessionStorage.getItem('financialDetails')
    }
    if (this.routeParams.type == '预审中') {
      this.approvalShow = false
    }
    if (this.routeParams.type == '预审不通过' || this.routeParams.type == '终审不通过') {
      this.isShowRed = true
    }
    if (this.routeParams.type == '挂牌中' || this.routeParams.type == '挂牌结束' || this.routeParams.type == '主动摘牌') {
      this.isShowRegistrationNo = true
    }
    this.getDetailData()
    if (this.isShowRegistrationNo) {
      this.requestStakeholder({
        pageNumber: 1,
        pageSize: 10,
        directionalFinancingId: this.routeParams.id
      })
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('financialDetails')
    next()
  }
  get btnShow() {
    let returnFlag: boolean = false
    if (Number(this.activeName) < 4) {
      return false
    }
    if (this.approvalShow) {
      this.activeName == '12' && (returnFlag = true)
    } else if (this.proDetail.investmentColumn && this.proDetail.investmentColumn.totalPeople) {
      this.activeName == '11' && (returnFlag = true)
    } else if (this.proDetail.legalOpinion && this.proDetail.legalOpinion.files && this.proDetail.legalOpinion.files.length && this.proDetail.legalOpinion.files[0].fileAddr) {
      this.activeName == '10' && (returnFlag = true)
    } else if (this.activeName == '9') {
      returnFlag = true
    } else if (this.routeParams.listingMode == '5' && this.proDetail.designatedInvestor) {
      this.activeName == '8' && (returnFlag = true)
    } else if (this.proDetail.administrator) {
      this.activeName == '7' && (returnFlag = true)
    } else if (this.proDetail.provider) {
      this.activeName == '6' && (returnFlag = true)
    } else if (this.proDetail.guarantors && this.proDetail.guarantors.length) {
      this.activeName == '5' && (returnFlag = true)
    } else {
      this.activeName == '4' && (returnFlag = true)
    }
    return returnFlag
  }
  // 公开开关方法
  private openChange(title: string, obj: any, id: any) {
    let contentText = '是否确认公开' + title + '？'
    if (!obj.status) {
      contentText = '是否确认取消公开' + title + '？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
        let params: any = {
          dfId: this.proDetail.dfId,
          ...obj
        }
        id && (params.dfRelId = id)
        setOpenStatus(params)
          .then((res) => {
            if (!(res.data && res.data.code == 1000)) {
              obj.status = !obj.status
            }
          })
          .catch(() => {
            obj.status = !obj.status
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      })
      .catch(() => {
        obj.status = !obj.status
      })
  }
  private getDetailData() {
    getFinancingDetail({ id: this.routeParams.id }).then((res) => {
      this.proDetail = res.data.data
      if (this.proDetail.modifyProjectNum) {
        this.eitdId = this.routeParams.id
      }
    })
  }
  private getStakeholder(params: any) {
    params.directionalFinancingId = this.routeParams.id
    this.requestStakeholder(params)
  }
  private requestStakeholder(params: any) {
    this.stakeholderLoading = true
    getStakeholder(params).then((res) => {
      if (res.data.data) {
        this.stakeholderTable = res.data.data
        this.stakeholderTotal = res.data.total ? res.data.total : 0
        this.stakeholderLoading = false
      }
    })
  }
  // 返回页面
  returnPaper() {
    this.$router.go(-1)
  }
  //主动摘牌
  private Evt() {
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    //       activeDelisting({ id: this.routeParams.id, callbackId: res.data.data.callbackId })
    //         .then((res) => {
    //           this.$message.success('摘牌成功')
    //           setTimeout(() => {
    //             this.$router.go(-1)
    //           }, 300)
    //         })
    //         .finally(() => {
    //           this.$nextTick(() => {
    //             // 以服务的方式调用的 Loading 需要异步关闭
    //             loadingInstance.close()
    //           })
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      this.qrDiaShow = false
      let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
      activeDelisting({ id: this.routeParams.id, callbackId })
        .then((res) => {
          this.$message.success('摘牌成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  // 上传通知书
  private upNotice() {
    this.markShow = true
  }
  // 关闭弹窗
  private closeFn() {
    this.markInfo = {
      amount: '',
      fileUrl: ''
    }
    this.markShow = false
  }
  // 弹窗确认
  private confirm() {
    if (!this.markInfo.amount.trim() || !this.markInfo.fileUrl.trim()) {
      this.$message.error('缴费金额和缴费通知书为必填项')
      return
    }
    if (isNaN(this.markInfo.amount.trim())) {
      this.$message.error('缴费金额必须为数字')
      return
    }
    this.markShow = false
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    sumbitPaymentNotice({
      dfId: this.proDetail.dfId,
      ...this.markInfo
    })
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          if (this.proDetail.paymentNoticeList) {
            this.proDetail.paymentNoticeList.push({ ...this.markInfo })
          } else {
            this.$set(this.proDetail, 'paymentNoticeList', [
              {
                ...this.markInfo
              }
            ])
          }
          this.$message.success('提交成功')
        }
      })
      .finally(() => {
        this.markInfo = {
          amount: '',
          fileUrl: ''
        }
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 上传文件成功
  private getFileUrl(v: any) {
    if (v) {
      this.markInfo.fileUrl = v.url
    } else {
      this.uploadOption.limit = this.uploadOption.limit + 1
    }
  }
}
