import request from '@/utils/request'
// 农户信息管理

// ------------村组管理-----------------
// 获取省列表
export function getProvinceList() {
    return request({
        url: '/bsProvince/selectAllProvince',
        method: 'POST'
    })
}

// 获取市列表
export function getCityList(data: Object) {
    return request({
        url: '/bsCity/getCity',
        method: 'POST',
        data: data
    })
}

// 获取县区列表
export function getCountyList(data: Object) {
    return request({
        url: '/bsArea/getArea',
        method: 'POST',
        data: data
    })
}

// 获取乡镇街道列表
export function getTownshipList(data: Object) {
    return request({
        url: '/bsStreet/getStreet',
        method: 'POST',
        data: data
    })
}

// 获取村组管理列表
export function getVillageManageList(data: Object) {
    return request({
        url: '/villageManage/page',
        method: 'POST',
        data: data
    })
}

// 获取管理人员列表
export function getManagerList(data: Object, cancelToken: any = null) {
    let requesrParams: any = {
        url: '/admin/person/getByName',
        method: 'POST',
        data: data
    }
    if (cancelToken) {
        requesrParams.cancelToken = cancelToken
    }
    return request(requesrParams)
}

// 添加村组
export function addVillageInfo(data: Object) {
    return request({
        url: '/villageManage/add',
        method: 'POST',
        data: data
    })
}

// 更新村组
export function updateVillageInfo(data: Object) {
    return request({
        url: '/villageManage/update',
        method: 'POST',
        data: data
    })
}

// ------------农户信息列表-----------------
// 获取村组存在省列表
export function getVillageProvinceList(data: Object) {
    return request({
        url: '/villageManage/queryProvince',
        method: 'POST',
        data: data
    })
}

// 获取村组存在市列表
export function getVillageCityList(data: Object) {
    return request({
        url: '/villageManage/queryCity',
        method: 'POST',
        data: data
    })
}

// 获取村组存在县区列表
export function getVillageCountyList(data: Object) {
    return request({
        url: '/villageManage/queryCounty',
        method: 'POST',
        data: data
    })
}

// 获取村组存在乡镇街道列表
export function getVillageTownshipList(data: Object) {
    return request({
        url: '/villageManage/queryTownship',
        method: 'POST',
        data: data
    })
}

// 获取农户信息列表
export function getFarmerInfoList(data: Object) {
    return request({
        url: '/farmersInfo/page',
        method: 'POST',
        data: data
    })
}



// ------------模板配置--------------------
// 栏目列表
export function getColumnInfolList(data: Object) {
    return request({
        url: `/message/template/menu/list`,
        method: 'GET',
        data: data
    })
}

// 栏目新增
export function submitColumnInfo(data: Object) {
    return request({
        url: `/message/template/menu/save`,
        method: 'POST',
        data: data
    })
}
// 删除栏目
export function deleteColumnInfo(data: any) {
    return request({
        url: `/message/template/menu/delete/${data.menuId}`,
        method: 'GET',
    })
}
// 删除字段
export function deleteFieldInfo(data: any) {
    return request({
        url: `/message/template/menu/col/delete/${data.colId}`,
        method: 'GET',
    })
}
// 新增字段
export function submitFieldInfo(data: Object) {
    return request({
        url: `/message/template/menu/col/save`,
        method: 'POST',
        data: data
    })
}
// 获取农户信息详情
export function getFarmerInfo(data: Object) {
    return request({
        url: '/farmersInfo/detail',
        method: 'POST',
        data: data
    })
}

// 获取添加农户信息详情
export function getAddFarmerInfo() {
    return request({
        url: '/farmersInfo/getTableStructure',
        method: 'GET'
    })
}

// 提交农户详情(添加农户)
export function subimtFarmerInfo(data: Object) {
    return request({
        url: '/farmersInfo/add',
        method: 'POST',
        data: data
    })
}

// 保存更新农户详情
export function updateFarmerInfo(data: Object) {
    return request({
        url: '/farmersInfo/update',
        method: 'POST',
        data: data
    })
}

// 获取村组
export function getVillageList(data: Object) {
    return request({
        url: '/villageManage/getByPerson',
        method: 'POST',
        data: data
    })
}

// 获取不定选项列表
export function getUnsetList(data: any) {
    return request({
        url: '/message/template/menu/col/item/get/' + data,
        method: 'GET'
    })
}

// ------------待审批农户信息列表-----------------
// 获取待审批农户信息列表
export function getPendingApprovalList(data: Object) {
    return request({
        url: '/farmerApproval/page',
        method: 'POST',
        data: data
    })
}

// 审批驳回通过
export function pendingApproval(data: Object) {
    return request({
        url: '/farmerApproval/approval',
        method: 'POST',
        data: data
    })
}
// 村组导入
export function VillageManageImport(data: Object) {
    return request({
        url: '/villageManage/import',
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        data: data
    })
}

// 获取村组下载模板
export function getVillageTemplate() {
    return request({
        url: '/villageManage/export',
        method: 'GET'
    })
}

// ------------农户信息导入-----------------
// 获取农户信息下载模板
export function getFarmerInfoTemplate() {
    return request({
        url: '/farmersInfo/exportFarmersInfoTemplate',
        method: 'GET'
    })
}

// 农户信息导入列表
export function farmerExportList(data: Object) {
    return request({
        url: '/farmersInfoImportLog/page',
        method: 'POST',
        data: data
    })
}

// 删除农户导出任务
export function deleteExportWord(data: Object) {
    return request({
        url: '/farmersInfoImportLog/delete',
        method: 'POST',
        data: data
    })
}

// 获取错误文件下载地址
export function getErrorFiles(data: Object) {
    return request({
        url: '/farmersInfoImportLog/getFileUrl',
        method: 'POST',
        data: data
    })
}

// 农户信息导入解析
export function farmerAnalysis(data: Object) {
    return request({
        url: '/farmersInfo/importFarmersInfo',
        method: 'POST',
        data: data
    })
}

// 农户信息更新解析
export function farmerUpAnalysis(data: Object) {
    return request({
        url: '/farmersInfo/updateFarmersInfo',
        method: 'POST',
        data: data
    })
}

// 获取所有管理员角色
export function getAllMangerRole() {
    return request({
        url: '/admin/role/getAllContainCompany',
        method: 'POST'
    })
}

// 获取管理人员列表
export function getMangerPersonnelList(data: Object) {
    return request({
        url: '/admin/person/listContainCompany',
        method: 'POST',
        data,
    })
}
