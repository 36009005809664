import request from '@/utils/request'
// 企业信用评级

// ------------企业财务评级-----------------

// 获取企业财务列表
export function getCompanyList(data: Object) {
    return request({
        url: '/companyCreditRate/page',
        method: 'POST',
        data: data
    })
}

// 获取企业信息
export function getCompanyRatingInfo(data: Object) {
    return request({
        url: '/companyCreditRate/getCompany',
        method: 'POST',
        data: data
    })
}

// 获取企业财务列表
export function saveCompany(data: Object) {
    return request({
        url: '/companyCreditRate/saveCompany',
        method: 'POST',
        data: data
    })
}

// 财务数据模板
export function getCompanyCreditIndicateTemp() {
    return request({
        url: '/companyCreditIndicateTemp/getIndicateTempList',
        method: 'POST'
    })
}

// 评级指标分数计算
export function ratingCalculation(data: Object) {
    return request({
        url: '/companyCreditRate/ratingCalculation',
        method: 'POST',
        data: data
    })
}

// 评级等级计算
export function gradeRateScore(data: Object) {
    return request({
        url: '/companyCreditRate/gradeRateScore',
        method: 'POST',
        data: data
    })
}

// 评级结果保存
export function companyCreditRateSave(data: Object) {
    return request({
        url: '/companyCreditRate/save',
        method: 'POST',
        data: data
    })
}

// 获取企业评级记录
export function getCompanyCreditList(data: Object) {
    return request({
        url: '/companyCreditRateRecord/page',
        method: 'POST',
        data: data
    })
}

// 获取企业评级记录详情
export function getCompanyCreditPageDetail(data: Object) {
    return request({
        url: '/companyCreditRateRecord/detail',
        method: 'POST',
        data: data
    })
}
