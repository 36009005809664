
import { Component, Vue, Prop } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import AddOfflineRefund from './addEditDetail/addOfflineRefund.vue' 
import DetailOfflineRefund from './addEditDetail/detailOfflineRefund.vue' 
import { bankCardEncryption } from '../components/bankCard'
import { bondbankBackSave } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
import bgSrc from '@/assets/images/up_files.png'

@Component({
  components: {
    Pagination,
    AddOfflineRefund,
    DetailOfflineRefund
  }
})
export default class BondInfo extends Vue {
  @Prop({
    default: true
  })
  openNums?: boolean
  @Prop() paginationParam: any
  @Prop() propSelectable: any
  @Prop({
    default: false
  }) 
  needOfflineRefund: any //是否需要线下退还操作
  @Prop({default: true}) showSelection: any //是否显示复选框
  @Prop() info: any // 基本信息
  @Prop() depositInfos: any // 保证金列表
  @Prop({
    default: false
  })
  bondLoading?: boolean
  // 自行转账银行卡编辑总标志
  @Prop({
    default: false
  })
  isOpenEdit?: boolean
  // 自行转账银行卡编辑项标志数组
  @Prop({
    default: () => {
      return []
    }
  })
  isEditItem?: any
  private loadingTxt: string = loadingText
  // 保证金状态参数
  // private payStatusOptions: any = ['支付失败', '支付成功', '待确认', '退还中', '已退款', '退款审批中', '退款失败']
  private payStatusOptions: any = ['支付失败', '支付成功', '待确认', '退还中', '已退款', '退款审批中', '退款失败', '已抵扣', '线下退还']
  private bankFn: any = bankCardEncryption // 银行卡加密方法
  private multipleSelection = [] // 多选框
  private bgImg: any = bgSrc
  private uploadOption: any = {
    limit: 1,
    // accept: '.pdf',
    accept: '.png,.jpg,.jpeg,.pdf',
    flag: '1'
  }
  private payBackDialogShow = false// 线下退还弹窗
  private payBackDetailDialogShow = false // 线下退还详情弹窗
  get returnTotal() {
    let returnVal: any = this.info.marginPayCount
    if (returnVal) {
      returnVal -= this.info.usePromiseAmountCount
    }
    return returnVal
  }
  private returnPayStatus(payStatus: any, usePromiseAmount: any) {
    let returnVal: any = this.payStatusOptions[payStatus]
    if (returnVal && payStatus == 1 && usePromiseAmount !== null && usePromiseAmount < 1) {
      returnVal += '(未退还)'
    } else if (!returnVal) {
      returnVal = ''
    }
    return returnVal
  }
  private getList() {
    this.$emit('searchBondList')
  }
  //多选框
  selectable(row, index) {
    let returnVal: any = this.payStatusOptions[row.payStatus]
    if (returnVal && row.payStatus == 1 && row.usePromiseAmount !== null && row.usePromiseAmount < 1) {
      returnVal += '(未退还)'
      //支付成功（未退还）---能勾选发起退款
      return true
    } else if (!returnVal) {
      returnVal = ''
    }
    if (row.payStatus == 0 || row.payStatus == 6) {
      //支付失败、退款失败---能勾选发起退款
      return true
    }
    return false
  }
  handleSelectionChange(val) {
    console.log(val);
    this.multipleSelection = val
  }
  //多选框 END
  //线下退还
  private paybackInfo = {} // 线下退还信息
  showPayBack(row) {
    this.paybackInfo = row
    this.payBackDialogShow = true
  }
  showDetailPayBack(row){
    this.paybackInfo = row
    this.payBackDetailDialogShow = true
  }
  //线下退还 END
  // 保证金自行转账银行卡编辑
  private editFn(row: any, ind: number) {
    if (this.isEditItem[ind]) {
      if (!row.openBank || !row.bankAcount || (row.openBank && !row.openBank.trim()) || (row.bankAcount && !row.bankAcount.trim())) {
        this.$message.error('开户银行和银行卡号不能为空')
        return
      }
      this.$emit('update:bondLoading', true)
      bondbankBackSave({
        delistingBaseId: row.delistingBaseId,
        userType: row.userType,
        openBank: row.openBank,
        bankAcount: row.bankAcount,
        payMethod: row.payMethod
      })
        .then(() => {
          this.$message.success('保存成功')
          this.$set(this.isEditItem, ind, false)
        })
        .finally(() => {
          this.$emit('update:bondLoading', false)
        })
    } else {
      this.$set(this.isEditItem, ind, true)
    }
  }
}
