
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { selectItem, addbankCard, editBankCardInfo } from '@/api/center'
@Component({
  components: {},
  filters: {}
})
export default class bankCardDetailTemplate extends Vue {
  @Prop() private formDialogs!: any // 接收父组件传过来的数据
  @Prop() private formDialogss!: any // 接收父组件传过来的数据
  @Prop() private flag!: string // 判断页面是新增还是详情
  @Prop() private loadingInstance!: any
  @Watch('flag', { deep: true, immediate: true })
  private handleFlag(newValue: string) {
    this.flags = newValue
  }
  @Watch('formDialogs', { deep: true, immediate: true })
  private handleFormDialog(newValue: any) {
    this.formData.id = newValue.id ? newValue.id : ''
    this.formData.accountName = newValue.accountName ? newValue.accountName : ''
    this.formData.bankCard = newValue.bankCard ? newValue.bankCard : ''
    this.formData.bankCode = newValue.bankCode ? newValue.bankCode : ''
    this.formData.bankName = newValue.bankName ? newValue.bankName : ''
  }
  @Watch('formDialogss', { deep: true, immediate: true })
  private handleFormDialogs(newValue: any) {
    this.formData.bankCardBussinessRels = newValue && newValue.length > 0 ? newValue : []
  }
  private loadingTxt: string = loadingText
  private flags: string = ''
  private disableds: boolean = false
  private rules = {
    // columnName: { required: true, message: '请输入栏目名称', trigger: 'blur' },
    // sort: { required: true, message: '请输入栏目排序', trigger: 'blur' },
    // mustFill: { required: true, message: '栏目内容是否必填', trigger: 'blur' },
    // addOrNot: { required: true, message: '栏目内容是否允许新增', trigger: 'blur' }
  }
  private formDialog: any = {}
  private tableDatas: any = []
  private disabled: boolean = false // 是否禁用
  private formData: any = {
    bankName: '',
    bankCard: '',
    bankCode: '',
    accountName: ''
    // bankCardBussinessRels: [
    //   {
    //     bussinessType: '',
    //     feeItem: '',
    //     feeCode: '',
    //     contractCode: '',
    //     feeName: ''
    //   }
    // ]
  }
  private disabledFeeItem: boolean = false
  private feeItemValue: string = ''
  private selectedFeeValue: string = ''
  private bankCardBussinessRels: any = [{}]
  private businessItem: any = [
    { label: '实时付款', value: 1 },
    { label: '实时收款', value: 2 },
    { label: '批量代付', value: 3 },
    { label: '批量代收', value: 4 }
  ]
  private feeItem: any = [
    { label: '手动输入', value: '1' },
    { label: '用水费', value: '00002' },
    { label: '污水处理费', value: '00003' },
    { label: '工资', value: '00004' }
  ]
  // -----------------------------------------data---------end--------------------------------------
  // --------------------------vue周期等其他方法----------------
  // private created() {
  // this.handleSelectedItem('businessItem')
  // this.handleSelectedItem('feeItem')
  // }
  // --------------------------vue周期等其他方法------end-------
  private handleSelectedItem(value: string) {
    let data: any = {
      categoryName: value
    }
    if (value === 'businessItem') {
      selectItem(data).then((res) => {
        this.businessItem = res.data.data
      })
    } else {
      selectItem(data).then((res) => {
        this.feeItem = res.data.data
      })
    }
  }
  private handleSelectedFeeItem(value: string) {
    let data: any = {
      categoryName: value
    }
    selectItem(data)
      .then((res) => {
        console.log(res.data)
      })
      .catch((res) => {})
  }
  private cancelDialog() {
    this.$emit('CancelDialog', { flag: false })
  }
  // 提交
  private confirmDialog(formName?: any) {
    // ;(this.$refs[formName] as any).validate((valid: any) => {
    //   if (valid) {
    //     alert('submit!')
    //   } else {
    //     return false
    //   }
    // })
    let datas: any = {
        id: this.formData.id || '',
        bankCard: this.formData.bankCard,
        accountName: this.formData.accountName,
        bankName: this.formData.bankName,
        bankCode: this.formData.bankCode,
        type: 2
      },
      loadingInstance = this.loadingInstance.service({ target: document.body, text: this.loadingTxt })
    // let data: any = JSON.parse(JSON.stringify(this.formData.bankCardBussinessRels))
    // let data: any = this.formData.bankCardBussinessRels
    // data.forEach((item: any, index: any) => {
    //   if (item.feeItem.label === '手动输入') {
    //     item.feeItem = item.feeName || item.feeItem
    //     delete item.feeName
    //   } else {
    //     item.feeCode = item.feeItem.value || item.feeCode
    //     item.feeItem = item.feeItem.label || item.feeItem
    //   }
    // })
    // datas.bankCardBussinessRels = data
    if (this.flags === 'add') {
      addbankCard(datas)
        .then((res) => {
          this.$emit('CancelDialog', { flag: false })
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else if (this.flags === 'edit') {
      editBankCardInfo(datas)
        .then((res) => {
          this.$emit('CancelDialog', { flag: false })
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  // private selectedBusinessItem(value: any) {
  //   console.log(value)
  // }
  // private selectedFeeItem(data: any, index: number) {
  //   let datas: object = {}
  //   if (data.feeItem.label !== '手动输入') {
  //     datas = {
  //       bussinessType: data.bussinessType,
  //       feeCode: data.feeItem.value,
  //       feeItem: data.feeItem,
  //       contractCode: data.contractCode
  //     }
  //     this.formData.bankCardBussinessRels.splice(index, 1, datas)
  //   } else {
  //   }

  //   // if(data.value!=='1'){
  //   //   this.bankCardBussinessRels.selectedFeeValue = data.label
  //   //   this.bankCardBussinessRels.feeCode = data.value
  //   //   this.disabledFeeItem =true
  //   // }else{
  //   //   this.bankCardBussinessRels.feeItem = data.label
  //   //   this.feeItemValue = ''
  //   //   this.selectedFeeValue = data.label
  //   //   this.disabledFeeItem = false
  //   // }
  // }
  // 新增选项
  // private addOption() {
  //   this.formData.bankCardBussinessRels.push({
  //     bussinessType: '',
  //     feeItem: '',
  //     feeCode: '',
  //     contractCode: ''
  //   })
  // }
  // 删除选项
  // private handleDeleteOption(data: any, index: any) {
  //   if (this.formData.bankCardBussinessRels.length > 1) {
  //     this.formData.bankCardBussinessRels.splice(data, 1)
  //   } else {
  //     this.$message.warning('开通业务必需填一项')
  //   }
  // }
}
