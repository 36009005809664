var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("标的名称")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("标的名称*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.listingName))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.listingName,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "listingName", $$v)
                        },
                        expression: "info.listingName"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("标的所在地")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.listingLocation))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.listingLocation,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "listingLocation", $$v)
                        },
                        expression: "info.listingLocation"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("标的类型")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("标的类型*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.listingTypeDesc))])
                  : _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          size: "small",
                          clearable: ""
                        },
                        model: {
                          value: _vm.info.listingType,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "listingType", $$v)
                          },
                          expression: "info.listingType"
                        }
                      },
                      _vm._l(_vm.businessOptions, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: item.businessTypeDesc,
                            value: item.businessType
                          }
                        })
                      }),
                      1
                    )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("权属类型")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.ownershipType))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.ownershipType,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "ownershipType", $$v)
                        },
                        expression: "info.ownershipType"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("转出期限")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.termOfTransfer))])
                  : _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        size: "small",
                        placeholder: "选择日期"
                      },
                      on: { change: _vm.termOfTransferDate },
                      model: {
                        value: _vm.info.termOfTransfer,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "termOfTransfer", $$v)
                        },
                        expression: "info.termOfTransfer"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("用途")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.purpose))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.purpose,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "purpose", $$v)
                        },
                        expression: "info.purpose"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("流转方式")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.circulationType))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.circulationType,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "circulationType", $$v)
                        },
                        expression: "info.circulationType"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("交易方式")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("交易方式*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.info.tranferType == 1
                            ? "竞拍"
                            : _vm.info.tranferType == 2
                            ? "非竞拍"
                            : "预售"
                        )
                      )
                    ])
                  : _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          size: "small",
                          clearable: ""
                        },
                        model: {
                          value: _vm.info.tranferType,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "tranferType", $$v)
                          },
                          expression: "info.tranferType"
                        }
                      },
                      _vm._l(_vm.ruleTypeOptions, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("成交时间")]),
                !_vm.btnProject && _vm.info.finishTime
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.info.finishTime,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ])
                  : _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        size: "small",
                        placeholder: "选择日期"
                      },
                      model: {
                        value: _vm.info.finishTime,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "finishTime", $$v)
                        },
                        expression: "info.finishTime"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.btnProject
                  ? _c("span", [_vm._v("成交总金额(元)")])
                  : _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("成交总金额(元)*")
                    ]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.amount))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "amount", $$v)
                        },
                        expression: "info.amount"
                      }
                    })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: { click: _vm.returnPaper }
            },
            [_vm._v("返回")]
          ),
          !_vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.editProject }
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.updateProject }
                },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("标的信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }