var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mark-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("标的信息")]),
        _vm.checkboxShow && _vm.processStatus === 0
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _vm.type == "1" || _vm.type == "2"
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("挂牌申请人")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.applyPerson.value))])
                ])
              : _vm._e(),
            _vm.type == "1" || _vm.type == "2"
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("联系电话")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.contactNumber.value))])
                ])
              : _vm._e(),
            _vm.areaShow
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("所属区域")]),
                  _c("span", [_vm._v(_vm._s(_vm.areaName))])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("标的名称")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].name,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "name", $$v)
                        },
                        expression: "itemStatus[3].name"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].name,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[_vm.type], "name", $$v)
                        },
                        expression: "itemStatus[type].name"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.name.value))])
              ],
              1
            ),
            _vm.projectNum
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("项目编号")]),
                  _c("span", [_vm._v(_vm._s(_vm.projectNum))])
                ])
              : _vm._e(),
            _vm.type != "1"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.type == "7" ? "标的使用范围" : "标的位置")
                      )
                    ]),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    _vm.type != "2" &&
                    _vm.type != "7"
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[3].location,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus[3], "location", $$v)
                            },
                            expression: "itemStatus[3].location"
                          }
                        })
                      : _vm._e(),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    (_vm.type == "2" || _vm.type == "7")
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].location,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[_vm.type],
                                "location",
                                $$v
                              )
                            },
                            expression: "itemStatus[type].location"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.location.value))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.type != "2" && _vm.type != "1"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("标的单位")]),
                      _vm.checkboxShow &&
                      _vm.processStatus === 0 &&
                      _vm.type != "7"
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[3].unit,
                              callback: function($$v) {
                                _vm.$set(_vm.itemStatus[3], "unit", $$v)
                              },
                              expression: "itemStatus[3].unit"
                            }
                          })
                        : _vm._e(),
                      _vm.checkboxShow &&
                      _vm.processStatus === 0 &&
                      _vm.type == "7"
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].unit,
                              callback: function($$v) {
                                _vm.$set(_vm.itemStatus[_vm.type], "unit", $$v)
                              },
                              expression: "itemStatus[type].unit"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.unit.value))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("标的数量")]),
                      _vm.checkboxShow &&
                      _vm.processStatus === 0 &&
                      _vm.type != "7"
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[3].number,
                              callback: function($$v) {
                                _vm.$set(_vm.itemStatus[3], "number", $$v)
                              },
                              expression: "itemStatus[3].number"
                            }
                          })
                        : _vm._e(),
                      _vm.checkboxShow &&
                      _vm.processStatus === 0 &&
                      _vm.type == "7"
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].number,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "number",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].number"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.number.value))])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.type == "2"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("林地属性")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].forestAttr,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "forestAttr",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].forestAttr"
                            }
                          })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.info.forestAttr.value == "1"
                              ? "国有"
                              : _vm.info.forestAttr.value == "2"
                              ? "非国有"
                              : _vm.info.forestAttr.value
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.info.holdArea && _vm.info.holdArea.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [
                            _vm._v(_vm._s("占地面积（" + _vm.areaUnit + "）"))
                          ]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].holdArea,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "holdArea",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].holdArea"
                                }
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(_vm.info.holdArea.value))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.info.forestStock && _vm.info.forestStock.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("林木蓄积（立方米）")]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].forestStock,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "forestStock",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].forestStock"
                                }
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.info.forestStock.value))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.info.treeType && _vm.info.treeType.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("树种")]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].treeType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "treeType",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].treeType"
                                }
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(_vm.info.treeType.value))])
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.type == "1"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("土地类型")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].landType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "landType",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].landType"
                            }
                          })
                        : _vm._e(),
                      _vm.info.landType
                        ? _c("span", [_vm._v(_vm._s(_vm.info.landType.value))])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("用途")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].usePurpose,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "usePurpose",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].usePurpose"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.usePurpose.value))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("权属类型")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].ownershipType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "ownershipType",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].ownershipType"
                            }
                          })
                        : _vm._e(),
                      _vm.info.ownershipType
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.info.ownershipType.value))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.info.warrantName && _vm.info.warrantName.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("权证名称")]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].warrantName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "warrantName",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].warrantName"
                                }
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.info.warrantName.value))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.info.warrantTerm && _vm.info.warrantTerm.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("权证年限")]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].warrantTerm,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "warrantTerm",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].warrantTerm"
                                }
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.info.warrantTerm.value))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("转出方式")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].transferMethod,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "transferMethod",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].transferMethod"
                            }
                          })
                        : _vm._e(),
                      _vm.info.transferMethod
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.info.transferMethod.value))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("是否属于再次转出")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].isReTransfer,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "isReTransfer",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].isReTransfer"
                            }
                          })
                        : _vm._e(),
                      _vm.info.isReTransfer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.info.isReTransfer.value == "1" ? "是" : "否"
                              )
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("是否有优先受让方")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].isPriorTransfer,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "isPriorTransfer",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].isPriorTransfer"
                            }
                          })
                        : _vm._e(),
                      _vm.info.isPriorTransfer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.info.isPriorTransfer.value == "1"
                                  ? "是"
                                  : "否"
                              )
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("所有权人")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].owner,
                              callback: function($$v) {
                                _vm.$set(_vm.itemStatus[_vm.type], "owner", $$v)
                              },
                              expression: "itemStatus[type].owner"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.owner.value))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("相关人员是否放弃优先权")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value:
                                _vm.itemStatus[_vm.type].isGiveUpAdvanceRight,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "isGiveUpAdvanceRight",
                                  $$v
                                )
                              },
                              expression:
                                "itemStatus[type].isGiveUpAdvanceRight"
                            }
                          })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.info.isGiveUpAdvanceRight.value == "1"
                              ? "是"
                              : "否"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("标的位置")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].location,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "location",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].location"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.location.value))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("承包方式")]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            on: { change: _vm.contractingModeFn },
                            model: {
                              value: _vm.itemStatus[_vm.type].contractingMode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "contractingMode",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].contractingMode"
                            }
                          })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.info.contractingMode.value) +
                            _vm._s(
                              _vm.info.contractingMode.value == "其他" &&
                                _vm.info.otherContractingMode &&
                                _vm.info.otherContractingMode.value
                                ? " - " + _vm.info.otherContractingMode.value
                                : ""
                            )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.info.fourSide && _vm.info.fourSide.value
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("土地四至")]),
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[_vm.type].fourSide,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[_vm.type],
                                      "fourSide",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[type].fourSide"
                                }
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(_vm.info.fourSide.value))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [
                        _vm._v(_vm._s("持有面积（" + _vm.areaUnit + "）"))
                      ]),
                      _vm.checkboxShow && _vm.processStatus === 0
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[_vm.type].holdArea,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[_vm.type],
                                  "holdArea",
                                  $$v
                                )
                              },
                              expression: "itemStatus[type].holdArea"
                            }
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.info.holdArea.value))])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("是否存在他项权利人")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].isOtherRightsHolder,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.itemStatus[3],
                            "isOtherRightsHolder",
                            $$v
                          )
                        },
                        expression: "itemStatus[3].isOtherRightsHolder"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].isOtherRightsHolder,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.itemStatus[_vm.type],
                            "isOtherRightsHolder",
                            $$v
                          )
                        },
                        expression: "itemStatus[type].isOtherRightsHolder"
                      }
                    })
                  : _vm._e(),
                _vm.info.isOtherRightsHolder
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.info.isOtherRightsHolder.value == "1"
                            ? "是"
                            : "否"
                        )
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm.info.isOtherRightsHolder &&
            _vm.info.isOtherRightsHolder.value == "1"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("他项权利人")]),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    _vm.type != "1" &&
                    _vm.type != "2" &&
                    _vm.type != "7"
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[3].otherRightsHolder,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[3],
                                "otherRightsHolder",
                                $$v
                              )
                            },
                            expression: "itemStatus[3].otherRightsHolder"
                          }
                        })
                      : _vm._e(),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].otherRightsHolder,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[_vm.type],
                                "otherRightsHolder",
                                $$v
                              )
                            },
                            expression: "itemStatus[type].otherRightsHolder"
                          }
                        })
                      : _vm._e(),
                    _vm.info.otherRightsHolder
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.info.otherRightsHolder.value))
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.isOtherRightsHolder &&
            _vm.info.isOtherRightsHolder.value == "1"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("他项权利内容")]),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    _vm.type != "1" &&
                    _vm.type != "2" &&
                    _vm.type != "7"
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[3].otherRightsContent,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[3],
                                "otherRightsContent",
                                $$v
                              )
                            },
                            expression: "itemStatus[3].otherRightsContent"
                          }
                        })
                      : _vm._e(),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].otherRightsContent,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[_vm.type],
                                "otherRightsContent",
                                $$v
                              )
                            },
                            expression: "itemStatus[type].otherRightsContent"
                          }
                        })
                      : _vm._e(),
                    _vm.info.otherRightsContent
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.info.otherRightsContent.value))
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("转让期限")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].transferPeriod,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "transferPeriod", $$v)
                        },
                        expression: "itemStatus[3].transferPeriod"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].transferPeriod,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.itemStatus[_vm.type],
                            "transferPeriod",
                            $$v
                          )
                        },
                        expression: "itemStatus[type].transferPeriod"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.transferPeriod.value))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("转出方式")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].transferMethod,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "transferMethod", $$v)
                        },
                        expression: "itemStatus[3].transferMethod"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].transferMethod,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.itemStatus[_vm.type],
                            "transferMethod",
                            $$v
                          )
                        },
                        expression: "itemStatus[type].transferMethod"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.transferMethod.value))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("交易方式")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].tradeType,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "tradeType", $$v)
                        },
                        expression: "itemStatus[3].tradeType"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].tradeType,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[_vm.type], "tradeType", $$v)
                        },
                        expression: "itemStatus[type].tradeType"
                      }
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.tradeType[_vm.info.tradeType.value]))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("出价方式")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].bidType,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "bidType", $$v)
                        },
                        expression: "itemStatus[3].bidType"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].bidType,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[_vm.type], "bidType", $$v)
                        },
                        expression: "itemStatus[type].bidType"
                      }
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.bidType[_vm.info.bidType.value]))
                ])
              ],
              1
            ),
            _vm.type == "7"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.info.tradeType.value != "1"
                            ? "标的价格（元）"
                            : "起拍价格（元）"
                        )
                      )
                    ]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].listingPrice,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.itemStatus[_vm.type],
                                "listingPrice",
                                $$v
                              )
                            },
                            expression: "itemStatus[type].listingPrice"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.listingPrice.value))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.type == "7" &&
            _vm.info.listingPrice &&
            _vm.info.listingPrice.value
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("挂牌价格")]),
                  _c("span", [_vm._v(_vm._s(_vm.listingPriceShow))])
                ])
              : _vm._e(),
            _vm.type == "7" &&
            _vm.info.tradeType.value == "1" &&
            _vm.auctionInterval
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("竞价阶梯(元)")]),
                  _c("span", [_vm._v(_vm._s(_vm.auctionInterval))])
                ])
              : _vm._e(),
            _vm.type == "7" &&
            _vm.info.tradeType.value == "1" &&
            _vm.tranferEnsureAmount
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("交易保证金(元)")]),
                  _c("span", [_vm._v(_vm._s(_vm.tranferEnsureAmount))])
                ])
              : _vm._e(),
            _vm.type != "7"
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _vm.info.listingPrice && _vm.info.listingPrice.value
                      ? _c("span", [_vm._v("挂牌价格")])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.info.tradeType.value != "1"
                                ? "标的价格（元）"
                                : "起拍价格（元）"
                            )
                          )
                        ]),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    _vm.type != "1" &&
                    _vm.type != "2"
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[3].amount,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus[3], "amount", $$v)
                            },
                            expression: "itemStatus[3].amount"
                          }
                        })
                      : _vm._e(),
                    _vm.checkboxShow &&
                    _vm.processStatus === 0 &&
                    (_vm.type == "2" || _vm.type == "1")
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].amount,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus[_vm.type], "amount", $$v)
                            },
                            expression: "itemStatus[type].amount"
                          }
                        })
                      : _vm._e(),
                    _vm.info.listingPrice && _vm.info.listingPrice.value
                      ? _c("span", [_vm._v(_vm._s(_vm.listingPriceShow))])
                      : _c("span", [_vm._v(_vm._s(_vm.info.amount.value))])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("付款方式")]),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                _vm.type != "1" &&
                _vm.type != "2" &&
                _vm.type != "7"
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[3].paymentMethod,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus[3], "paymentMethod", $$v)
                        },
                        expression: "itemStatus[3].paymentMethod"
                      }
                    })
                  : _vm._e(),
                _vm.checkboxShow &&
                _vm.processStatus === 0 &&
                (_vm.type == "2" || _vm.type == "1" || _vm.type == "7")
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus[_vm.type].paymentMethod,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.itemStatus[_vm.type],
                            "paymentMethod",
                            $$v
                          )
                        },
                        expression: "itemStatus[type].paymentMethod"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.paymentMethod.value))])
              ],
              1
            ),
            _vm.type != "1" &&
            _vm.type != "2" &&
            _vm.type != "7" &&
            _vm.customFields &&
            _vm.customFields.length
              ? _c(
                  "div",
                  _vm._l(_vm.customFields, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "item" },
                      [
                        _c("span", [_vm._v(_vm._s(item.fieldName))]),
                        _vm.checkboxShow && _vm.processStatus === 0
                          ? _c("el-checkbox", {
                              model: {
                                value: _vm.customStatus[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.customStatus, index, $$v)
                                },
                                expression: "customStatus[index]"
                              }
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(item.fieldValue))])
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.imgList && _vm.imgList.length
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("标的图")]),
                  _c(
                    "div",
                    _vm._l(_vm.imgList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "img-item" },
                        [
                          _vm.checkboxShow && _vm.processStatus === 0
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.fileStatus[index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.fileStatus, index, $$v)
                                  },
                                  expression: "fileStatus[index]"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                href: item.imgUrl,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [_vm._v(_vm._s(item.imgName))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.type == "7" && _vm.info.remark && _vm.info.remark.value
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("备注")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[_vm.type].remark,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus[_vm.type], "remark", $$v)
                            },
                            expression: "itemStatus[type].remark"
                          }
                        })
                      : _vm._e(),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.info.remark.value) }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }