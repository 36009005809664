
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getApprovalList } from '@/api/vip' //接口
import QrCode from '@/components/QrCode.vue'
import ProInfo from '../../law-management/components/pro-info.vue'
import CompanyInfo from '../components/company-info.vue'
import PersonInfo from '../components/person-info.vue'
import Tables from '../components/table.vue'
import CompanyJoin from '../components/company-join.vue'
import PersonJoin from '../components/person-join.vue'

@Component({
  components: {
    QrCode,
    CompanyInfo,
    PersonInfo,
    Tables,
    CompanyJoin,
    PersonJoin,
  }
})
export default class CentetDetail extends Vue {
  private activeName: any = '1' // 当前 tab 选项
  private detailtype: any = '' //  //  type: '', //1-公司 2 个人
  private remark: any = '' //
  private lawPopinfo: any = { show: false }
  private proDetail: any = {} //详情数据
  //详情数据
  created() {
    this.detailtype = this.$route.params.type
    this.getList()
  }
  goBack(){
    this.activeName = '1'
  }
  //获取详情
  private async getList() {
    // getApprovalDetail({ id: Number(this.$route.params.id) }).then((res) => {
    //   this.proDetail = res.data.data
    // }).finally(() => {})
  }
  // 获取风控意见
  getRemark(v: any) {
    this.remark = v
  }
  lawPopEvt() {
    this.lawPopinfo.show = true
  }
}
