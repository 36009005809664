
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import UploadBase from '@/components/UploadBase64/index.vue'
import filesSrc from '@/assets/images/files_btn.png'
import bgSrc from '@/assets/images/up_files.png'
import { authenticationDetails, authenticationGrant, decompressionNoticeSumbit } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    UploadBase
  }
})
export default class AuthenticationApplicationDetail extends Vue {
  private loadingTxt: string = loadingText
  private loadingContent: boolean = false
  private pushOptions: any = {
    businessTypeOptions: [], // 业务类型
    applyPersonTypeOptions: [], // 申请人类型
    // statusOptions: [], // 状态类型
    applyVersionOptions: [], // 申请模板类型
    typeOptions: [] // 证书类型
  }
  private searchId: any
  private info: any = {}
  private filesImg: any = filesSrc
  private bgImg: any = bgSrc
  private uploadOption: any = {
    limit: 1,
    accept: '.pdf,.jpg,.png,.jpeg',
    flag: '1'
  }
  private formInfo: any = {
    fileAddr: '',
    expressNumber: '',
    certificateUrl: ''
  }
  // private upFileName: string = ''
  // 获取info长度
  get getInfoLength() {
    return Object.keys(this.info).length
  }
  get repaymentDesc() {
    let repaymentOptions: any = ['等额本金', '等额本息', '按月还息到期还本', '到期一次还本付息'],
      returnVal: string
    if (this.info.repayment === null) {
      returnVal = ''
    } else {
      returnVal = repaymentOptions[this.info.repayment - 1] ? repaymentOptions[this.info.repayment - 1] : ''
    }
    return returnVal
  }
  created() {
    this.loadingContent = true
    let pushOptions: any = sessionStorage.getItem('pushOptions')
    this.pushOptions = JSON.parse(pushOptions)
    if (this.$route.params.id) {
      this.searchId = this.$route.params.id
      sessionStorage.setItem('authenticationDetail', this.searchId)
    } else {
      this.searchId = sessionStorage.getItem('authenticationDetail')
    }
    authenticationDetails({ id: this.searchId })
      .then((res) => {
        res.data.data && (this.info = res.data.data)
      })
      .finally(() => {
        this.loadingContent = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时
    if (to.name != 'CountrysideProjectDetail') {
      sessionStorage.removeItem('authenticationDetail')
      sessionStorage.removeItem('pushOptions')
    }
    next()
  }
  private getOptionsVal(val: any, type: string) {
    let nowOptions = type == 'applyPersonTypeOptions' ? this.pushOptions.applyPersonTypeOptions : type == 'applyVersionOptions' ? this.pushOptions.applyVersionOptions : []
    if (nowOptions.length) {
      for (let i = 0; i < nowOptions.length; i++) {
        if (nowOptions[i].value == val) {
          return nowOptions[i].name
        }
      }
    } else {
      return val
    }
  }
  private bTypeVal(val: any) {
    let retuenDesc: string = '',
      businessTypeOptions: any = this.pushOptions.businessTypeOptions
    if (businessTypeOptions && businessTypeOptions.length) {
      for (let i = 0; i < businessTypeOptions.length; i++) {
        if (val == businessTypeOptions[i].businessType) {
          retuenDesc = businessTypeOptions[i].businessTypeDesc
        }
      }
    }
    return retuenDesc
  }
  // 返回页面
  private returnPaper() {
    this.$router.go(-1)
  }
  private intoDetails() {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: this.info.listingApplyId, isExport: '0' }
    })
  }
  private getFileUrl(v: any) {
    if (v) {
      // let endInd = v.name.indexOf('.')
      // if (endInd != -1) {
      //   this.upFileName = v.name.slice(0, endInd)
      // } else {
      //   this.upFileName = v.name
      // }
      this.info.status == '0' ? (this.formInfo.certificateUrl = v.url) : (this.formInfo.fileAddr = v.url)
    } else {
      this.uploadOption.limit = this.uploadOption.limit + 1
    }
  }
  private subimtFn() {
    this.loadingContent = true
    if (this.info.status == '0') {
      let params = {
        id: this.searchId,
        applyVersion: this.info.applyVersion,
        expressNumber: this.formInfo.expressNumber.trim(),
        certificateUrl: this.formInfo.certificateUrl
      }
      if (params.applyVersion == '2' && !params.expressNumber) {
        this.$message.error('请输入快递单号！！')
        this.loadingContent = false
        return
      }
      if (params.applyVersion == '1') {
        delete params.expressNumber
      }
      if (!params.certificateUrl) {
        this.$message.error('请上传文件后再试！！')
        this.loadingContent = false
        return
      }
      authenticationGrant(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .catch(() => {
          this.loadingContent = false
        })
    } else if (this.info.status == '3') {
      if (!this.formInfo.fileAddr) {
        this.$message.error('请上传文件后再试！！')
        this.loadingContent = false
        return
      }
      let params: any = {
        proofName: (this.info.listingApplyName ? this.info.listingApplyName : '') + '解押通知书',
        applyCertificateId: this.searchId,
        mortgageApprovalId: this.info.mortgageApprovalId,
        proofUrl: this.formInfo.fileAddr
      }
      decompressionNoticeSumbit(params)
        .then((res) => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        })
        .catch(() => {
          this.loadingContent = false
        })
    }
  }
}
