
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getFarmerInfoTemplate, farmerExportList, deleteExportWord, getErrorFiles, farmerAnalysis, farmerUpAnalysis } from '@/api/peasantInfoManagement'
import uploadMoreExcel from '@/components/UploadMoreExcel/index.vue'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips,
    uploadMoreExcel
  }
})
export default class FarmerInfoExport extends Vue {
  private loadingTxt: string = loadingText
  private isDisabled: boolean = false // 上传加载中标志
  private isDisabled2: boolean = false // 下载模板加载中标志
  private isDisabledUp: boolean = false // 更新加载中标志
  private nowDownloadUrl: string = ''
  private nowFileUrl: string = ''
  private nowFileName: string = ''
  private uploadFarmers: any = `${process.env.VUE_APP_BASE_URL}file/upload` // 上传地址
  private statusOptions: any = [
    { label: '等待中', value: 0 },
    { label: '进行中', value: 1 },
    { label: '已完成', value: 2 },
    { label: '失败', value: 3 }
  ]
  private createTime: any = '' // 创建时间
  private finalTime: any = '' // 完成时间
  private formInfo: any = {
    status: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  created() {
    this.getList()
  }
  private resetFn() {
    this.formInfo.status = ''
    this.createTime = ''
    this.finalTime = ''
  }
  private getList() {
    this.loading = true
    if (this.createTime) {
      this.$set(this.formInfo, 'startCreateTime', this.createTime[0])
      this.$set(this.formInfo, 'endCreateTime', this.createTime[1])
    } else {
      this.$set(this.formInfo, 'startCreateTime', '')
      this.$set(this.formInfo, 'endCreateTime', '')
    }
    if (this.finalTime) {
      this.$set(this.formInfo, 'startFinishTime', this.finalTime[0])
      this.$set(this.formInfo, 'endFinishTime', this.finalTime[1])
    } else {
      this.$set(this.formInfo, 'startFinishTime', '')
      this.$set(this.formInfo, 'endFinishTime', '')
    }
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && params[key] !== 0 && delete params[key]
    })
    farmerExportList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 搜索
  private handleSearch() {
    this.getList()
  }
  // 下载模板
  private downloadFn() {
    this.isDisabled2 = true
    getFarmerInfoTemplate()
      .then((res) => {
        if (res.data.data) {
          this.nowFileUrl = res.data.data
          let urlArr: any = res.data.data.split('/')
          this.nowFileName = '.' + urlArr[urlArr.length - 1].split('.')[1]
          setTimeout(() => {
            ;(this.$refs.fileDown as any).click()
          })
        } else {
          this.$message.error('没有文件可下载')
        }
      })
      .finally(() => {
        this.isDisabled2 = false
      })
  }
  // 上传开始
  private uploadStart() {
    this.isDisabled = true
  }
  // 上传开始
  private uploadStartUp() {
    this.isDisabledUp = true
  }
  // 上传失败
  private uploadError() {
    this.isDisabled = false
  }
  // 更新失败
  private uploadErrorUp() {
    this.isDisabledUp = false
  }
  // 上传成功
  private uploadSuccess(data: any) {
    farmerAnalysis({ importFileUrl: data.url })
      .then((res) => {
        this.$message.success('上传成功')
        this.getList()
      })
      .finally(() => {
        this.isDisabled = false
      })
  }
  // 更新成功
  private uploadSuccessUp(data: any) {
    farmerUpAnalysis({ importFileUrl: data.url })
      .then((res) => {
        this.$message.success('上传成功')
        this.getList()
      })
      .finally(() => {
        this.isDisabledUp = false
      })
  }
  // 删除
  private handleDel(id: any) {
    this.$confirm('是否确认删除该任务？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        deleteExportWord({ id: id }).then((res) => {
          this.getList()
        })
      })
      .catch(() => {})
  }
  // 下载失败数据
  private downloadError(id: any) {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    getErrorFiles({ id: id })
      .then((res) => {
        if (res.data.data) {
          this.nowDownloadUrl = res.data.data
          setTimeout(() => {
            ;(this.$refs.errorDown as any).click()
          })
        } else {
          this.$message.error('没有文件可下载')
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
