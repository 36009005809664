
import { Component, Vue, Prop } from 'vue-property-decorator'
import { offlineProjectUpdate } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
import { Loading } from 'element-ui'
@Component({
  components: {}
})
export default class OfflineBasicInfo extends Vue {
  @Prop() info: any
  @Prop() type: any
  btnProject: boolean = false
  private loadingTxt: string = loadingText
  // 返回页面
  private returnPaper() {
    this.$router.push({ name: 'ProjectOffline' })
  }
  private editProject() {
    this.btnProject = true
  }
  private updateProject() {
    if (this.type == 1) {
      if (this.info.transferorIdentity == null || this.info.transferorIdentity == '') {
        this.$message.error('转出方身份类别不能为空')
        return
      }
      if (this.info.transferorName == null || this.info.transferorName == '') {
        this.$message.error('转出方名称/姓名不能为空')
        return
      }
    } else {
      if (this.info.transfereeIdentity == null || this.info.transfereeIdentity == '') {
        this.$message.error('转入方身份类别不能为空')
        return
      }
      if (this.info.transfereeName == null || this.info.transfereeName == '') {
        this.$message.error('转入方名称/姓名不能为空')
        return
      }
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    this.btnProject = false
    offlineProjectUpdate(this.info).then((res) => {
      if (res.data.code == 1000) {
        this.$message.success('修改成功')
        this.$emit('updateSuccess')
      }
    }) .finally(() => {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
    })
  }
}
