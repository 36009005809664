var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contents" }, [
    _vm.companyDetail
      ? _c(
          "div",
          [
            _c("formDetail", {
              attrs: {
                types: _vm.type,
                "form-datass": _vm.formDatass,
                isPass: _vm.isPass
              },
              on: {
                handleIsPass: _vm.handleIsPass,
                "update:types": function($event) {
                  _vm.type = $event
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticStyle: { height: "100%" } }, [
      _c("div", { staticClass: "approvalTitle" }, [_vm._v("审批信息")]),
      _c(
        "div",
        { staticClass: "approvalContent" },
        _vm._l(_vm.approvalDatas, function(item, index) {
          return _c("div", { key: index, staticClass: "itemApprovalBox" }, [
            item.auditStatus === 1
              ? _c("div", { staticClass: "approvalSuccessIcon" }, [
                  _c("img", { attrs: { src: _vm.successIcon, alt: "" } })
                ])
              : _vm._e(),
            item.auditStatus === 2
              ? _c("span", {
                  staticClass: "waitApprovalIcon errorApprovalIcon"
                })
              : _vm._e(),
            item.auditStatus !== 1 && item.auditStatus !== 2
              ? _c("span", { staticClass: "waitApprovalIcon" })
              : _vm._e(),
            _c("div", { staticClass: "approvalTop" }, [
              _c("span", { staticClass: "approvalInfo" }, [
                _vm._v(_vm._s(item.auditName))
              ]),
              _c("span", { staticClass: "approvalInfo" }, [
                _vm._v(_vm._s(_vm._f("filterTimeStamp")(item.createdTime)))
              ]),
              _c("span", { staticClass: "approvalInfo" }, [
                _vm._v(_vm._s(_vm._f("filterAuditStatus")(item.auditStatus)))
              ])
            ]),
            item.auditStatus === 1 || item.auditStatus === 2
              ? _c(
                  "div",
                  { staticClass: "approvalOpinion" },
                  [
                    _c("span", [_vm._v("审批意见：")]),
                    _c("el-input", {
                      staticClass: "approvalTextarea",
                      attrs: { type: "textarea", disabled: true },
                      model: {
                        value: item.rejectContent,
                        callback: function($$v) {
                          _vm.$set(item, "rejectContent", $$v)
                        },
                        expression: "item.rejectContent"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _vm.showSumbit
        ? _c(
            "div",
            { staticClass: "handleApprovalOpinion" },
            [
              _c("span", [_vm._v("审批意见：")]),
              _c("el-input", {
                staticClass: "handleApprovalTextarea",
                attrs: { type: "textarea" },
                model: {
                  value: _vm.rejectContent,
                  callback: function($$v) {
                    _vm.rejectContent = $$v
                  },
                  expression: "rejectContent"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "block" },
      [
        _c(
          "el-button",
          { staticClass: "returnBtn", on: { click: _vm.handleReturn } },
          [_vm._v("返回")]
        ),
        _vm.showSumbit
          ? _c(
              "el-button",
              {
                staticClass: "sumbitBtn",
                attrs: { type: "warning" },
                on: { click: _vm.handleRefuse }
              },
              [_vm._v("拒绝")]
            )
          : _vm._e(),
        _vm.showSumbit
          ? _c(
              "el-button",
              {
                staticClass: "sumbitBtn",
                attrs: { type: "primary" },
                on: { click: _vm.handlePass }
              },
              [_vm._v("通过")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }