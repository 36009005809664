var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "liquidation-detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "1" } },
            [_c("liquidation-basic-info", { attrs: { info: _vm.proDetail } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "清算信息", name: "2" } },
            [
              _vm.proDetail.liquidationApply &&
              _vm.proDetail.liquidationApply.type == "3"
                ? _c(
                    "div",
                    { staticClass: "offline" },
                    [
                      _c(
                        "el-form",
                        { ref: "formData", attrs: { "label-width": "150px" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "清算方式：", prop: "userName" }
                            },
                            [_vm._v("线下清算")]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "清算金额：",
                                prop: "liquidationAmount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.proDetail.liquidationApply
                                      .liquidationAmount || 0
                                  ) +
                                  "元 "
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "清算人员：",
                                prop: "liquidationName"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.proDetail.liquidationApply
                                      .liquidationName
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "清算日期：",
                                prop: "liquidationTime"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.timeFormat(
                                      _vm.proDetail.liquidationApply
                                        .liquidationTime
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "25px" },
                              attrs: { label: "清算凭证：", prop: "fileAddr" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "img-box" },
                                _vm._l(
                                  _vm.proDetail.liquidationApply.fileList,
                                  function(item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "show-img" },
                                      [
                                        _c("img", {
                                          attrs: { src: item.fileUrl },
                                          on: {
                                            click: function($event) {
                                              return _vm.enlargeImg(
                                                item.fileUrl
                                              )
                                            }
                                          }
                                        }),
                                        _c("br"),
                                        _vm._v("退还凭证 ")
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c("liquidation-info", {
                    attrs: {
                      disableList: _vm.disableList,
                      info: _vm.proDetail.liquidationApply,
                      payeeList: _vm.proDetail.payeeList,
                      openEdit:
                        _vm.proDetail.status == 1 || _vm.proDetail.status == 4,
                      openStatus:
                        _vm.proDetail.status == 3 || _vm.proDetail.status > 4
                    }
                  })
            ],
            1
          ),
          _vm.proDetail.status > 1 &&
          !(
            _vm.proDetail.liquidationApply &&
            _vm.proDetail.liquidationApply.type == "3"
          )
            ? _c(
                "el-tab-pane",
                { attrs: { label: "审批信息", name: "3" } },
                [
                  _c("simple-approval-info", { attrs: { info: _vm.proDetail } })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.status > 4
            ? _c(
                "el-tab-pane",
                { attrs: { label: "交易记录", name: "4" } },
                [
                  _vm.tradeShow == "list"
                    ? _c("trade-list", {
                        attrs: { tableData: _vm.tradeListData },
                        on: { tradeDetails: _vm.transactionInfo }
                      })
                    : _vm.tradeShow == "details"
                    ? _c("trade-details", {
                        attrs: {
                          othserInfo: _vm.tradeDetails,
                          tradeState: _vm.tradeState
                        },
                        on: {
                          tradeReceipt: _vm.tradeReceipt,
                          tradeReceiveList: _vm.tradeReceiveList
                        }
                      })
                    : _vm.tradeShow == "receiveList"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.tableLoading,
                                  expression: "tableLoading"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                "element-loading-text": _vm.loadingTxt,
                                "header-cell-style": {
                                  textAlign: "center",
                                  background: "#FAFAFA"
                                },
                                "cell-style": { textAlign: "center" }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  index: index => index + 1
                                }
                              }),
                              _vm._l(_vm.columnData, function(item) {
                                return _c("el-table-column", {
                                  key: item.label,
                                  attrs: { prop: item.prop, label: item.label },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ row }) {
                                          return [
                                            item.prop === "status"
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("filterTrade")(
                                                          row[item.prop]
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(_vm._s(row[item.prop]))
                                                ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              })
                            ],
                            2
                          ),
                          _c("Pagination", {
                            attrs: {
                              total: _vm.paypeePaginationParam.total,
                              page: _vm.paypeePaginationParam.pageIndex,
                              limit: _vm.paypeePaginationParam.pageSize
                            },
                            on: {
                              "update:page": function($event) {
                                return _vm.$set(
                                  _vm.paypeePaginationParam,
                                  "pageIndex",
                                  $event
                                )
                              },
                              "update:limit": function($event) {
                                return _vm.$set(
                                  _vm.paypeePaginationParam,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.getList
                            }
                          })
                        ],
                        1
                      )
                    : _c("trade-receipt", {
                        attrs: {
                          tableData: _vm.tableData,
                          obj: _vm.tradeDetails
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: { click: _vm.returnPage }
            },
            [_vm._v("返回")]
          ),
          _vm.hasObj(_vm.proDetail) &&
          (_vm.proDetail.status == 1 ||
            _vm.proDetail.status == 4 ||
            _vm.proDetail.status == 5)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.refund }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.proDetail.status == 5
                        ? "继续发起清算"
                        : _vm.proDetail.status == 4
                        ? "重新发起清算"
                        : "发起清算"
                    )
                  )
                ]
              )
            : _vm._e(),
          _vm.hasObj(_vm.proDetail) &&
          (_vm.proDetail.status == 1 ||
            _vm.proDetail.status == 4 ||
            _vm.proDetail.status == 5)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.showOffLineLiqDialog = true
                    }
                  }
                },
                [_vm._v("线下清算")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "录入线下清算信息",
            visible: _vm.showOffLineLiqDialog,
            "modal-append-to-body": false,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showOffLineLiqDialog = $event
            }
          }
        },
        [
          _vm.showOffLineLiqDialog
            ? _c("AddOfflineLiq", { attrs: { info: _vm.proDetail } })
            : _vm._e()
        ],
        1
      ),
      _vm.showEnlarge
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showEnlarge,
                "modal-append-to-body": true,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.showEnlarge = $event
                }
              }
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.nowSrc }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }