var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm.detailtype == 4
            ? _c(
                "el-tab-pane",
                { attrs: { label: "项目状态", name: "1" } },
                [
                  _c("pro-info", {
                    attrs: { info: _vm.proDetail.projectStatus },
                    on: {
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "projectStatus", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "项目立项申请信息", name: "2" } },
            [
              _c("pro-base-info", {
                attrs: {
                  refuse: _vm.getItemStatus.projectDetails,
                  info: _vm.proDetail.projectDetails,
                  detailtype: _vm.detailtype,
                  isSecond: _vm.isSecond
                },
                on: {
                  addRefuseItem: _vm.addRefuseItem,
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "projectDetails", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "产品相关", name: "3" } },
            [
              _c("pro-product-related", {
                attrs: {
                  refuse: _vm.getItemStatus.productReleative,
                  info: _vm.proDetail.productReleative,
                  detailtype: _vm.detailtype,
                  isSecond: _vm.isSecond
                },
                on: {
                  addRefuseItem: _vm.addRefuseItem,
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "productReleative", $event)
                  },
                  "update:detailtype": function($event) {
                    _vm.detailtype = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "发行方", name: "4" } },
            [
              _c("pro-info-person", {
                attrs: {
                  refuse: _vm.getItemStatus.issuer,
                  info: _vm.proDetail.issuer,
                  flag: "issuer",
                  title: "发行方（备案登记人）",
                  detailtype: _vm.detailtype,
                  isSecond: _vm.isSecond
                },
                on: {
                  addRefuseItem: _vm.addRefuseItem,
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "issuer", $event)
                  },
                  "update:detailtype": function($event) {
                    _vm.detailtype = $event
                  }
                }
              })
            ],
            1
          ),
          _vm.proDetail.guarantors && _vm.proDetail.guarantors.length
            ? _c(
                "el-tab-pane",
                { attrs: { label: "担保人", name: "5" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      refuse: _vm.getItemStatus.guarantors,
                      info: _vm.proDetail.guarantors,
                      flag: "guarantors",
                      title: "担保人",
                      detailtype: _vm.detailtype,
                      isSecond: _vm.isSecond
                    },
                    on: {
                      addRefuseItem: _vm.addRefuseItem,
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "guarantors", $event)
                      },
                      "update:detailtype": function($event) {
                        _vm.detailtype = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.provider
            ? _c(
                "el-tab-pane",
                { attrs: { label: "服务商", name: "6" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      refuse: _vm.getItemStatus.provider,
                      info: _vm.proDetail.provider,
                      flag: "provider",
                      title: "服务商",
                      detailtype: _vm.detailtype,
                      isSecond: _vm.isSecond
                    },
                    on: {
                      addRefuseItem: _vm.addRefuseItem,
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "provider", $event)
                      },
                      "update:detailtype": function($event) {
                        _vm.detailtype = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.administrator
            ? _c(
                "el-tab-pane",
                { attrs: { label: "受托管理人", name: "7" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      refuse: _vm.getItemStatus.administrator,
                      info: _vm.proDetail.administrator,
                      flag: "administrator",
                      title: "受托管理人",
                      detailtype: _vm.detailtype,
                      isSecond: _vm.isSecond
                    },
                    on: {
                      addRefuseItem: _vm.addRefuseItem,
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "administrator", $event)
                      },
                      "update:detailtype": function($event) {
                        _vm.detailtype = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.routeParams.bizType == "5" && _vm.proDetail.designatedInvestor
            ? _c(
                "el-tab-pane",
                { attrs: { label: "指定投资人", name: "8" } },
                [
                  _c("appoint-investment", {
                    attrs: {
                      info: _vm.proDetail.designatedInvestor,
                      refuse: _vm.getItemStatus.designatedInvestor,
                      detailtype: _vm.detailtype,
                      isSecond: _vm.isSecond
                    },
                    on: { addRefuseItem: _vm.addRefuseItem }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "审批信息", name: "9" } },
            [
              _c("pro-info-approval", {
                attrs: {
                  allowInvest: _vm.allowInvest,
                  isSecond: _vm.isSecond,
                  refuse: _vm.getItemStatus.approval,
                  info: _vm.proDetail,
                  detailtype: _vm.detailtype
                },
                on: {
                  addRefuseItem: _vm.addRefuseItem,
                  remark: _vm.getRemark,
                  fileUploadSuccess: _vm.fileUploadSuccess,
                  fileUploadError: _vm.fileUploadError,
                  "update:info": function($event) {
                    _vm.proDetail = $event
                  },
                  "update:detailtype": function($event) {
                    _vm.detailtype = $event
                  },
                  changeIsInvest: _vm.changeIsInvest
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName == "9"
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returnPaper } },
                [_vm._v("返回")]
              ),
              _vm.detailtype == 1 ||
              _vm.detailtype == 2 ||
              _vm.detailtype == 5 ||
              _vm.detailtype == 6
                ? _c(
                    "span",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.approvalEvt(2)
                            }
                          }
                        },
                        [_vm._v("拒绝")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.approvalEvt(1)
                            }
                          }
                        },
                        [_vm._v("通过")]
                      ),
                      _vm.detailtype == 5 || _vm.detailtype == 6
                        ? _c(
                            "el-button",
                            {
                              staticClass: "global-dotted-btn",
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.approvalEvt(3)
                                }
                              }
                            },
                            [_vm._v("保留意见")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailtype == 3
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.lawPopEvt }
                    },
                    [_vm._v("确认")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "授权认证", visible: _vm.qrDiaShow },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow },
            on: { notify: _vm.notify }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }