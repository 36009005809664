
import { Component, Vue } from 'vue-property-decorator'
import tableColumn from '../components/tableColumn.vue'
import { tableFilter } from '../components/tableFilter'
import { pendingApproval, getFarmerInfo } from '@/api/peasantInfoManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    tableColumn
  }
})
export default class PendingApprovalDetail extends Vue {
  private routeParams: any = {}
  private loadingFlag: boolean = true // 加载动画开关
  private loadingText: string = loadingText // 加载动画文本
  private activeName: any = '1' // 当前 tab 选项
  private remark: string = ''
  private paneData: any = null
  private progressFlag: number = 0
  private isSelectedFalseArr: any = []
  private isPdPreserve: boolean = false
  private hasSum: any = []
  private splitData: any
  //详情数据
  created() {
    if (this.$route.params.farmersInfoId) {
      this.routeParams = this.$route.params
      sessionStorage.setItem('pendingApprovalDetail', JSON.stringify(this.routeParams))
    } else {
      let nowGet: any = sessionStorage.getItem('pendingApprovalDetail')
      this.routeParams = JSON.parse(nowGet)
    }
    getFarmerInfo({ farmersInfoId: this.routeParams.farmersInfoId })
      .then((res) => {
        if (res.data.code == 1000) {
          this.splitData = tableFilter(res.data.data, this.hasSum)
          this.paneData = res.data.data
        }
      })
      .finally(() => {
        this.loadingFlag = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('pendingApprovalDetail')
    next()
  }
  // 返回页面
  returnPaper() {
    this.$router.go(-1)
  }
  private isSelectedFalse(checkVal: boolean, columnId: any) {
    this.progressFlag = this.progressFlag + 1
    if (checkVal) {
      this.isSelectedFalseArr.push(columnId)
    }
  }
  approvalEvt(type: string) {
    if (!this.loadingFlag) {
      this.loadingFlag = true
      this.loadingText = '提交中'
    }
    let params: any = {
      approvalId: this.routeParams.approvalId,
      type: type,
      remark: this.remark,
      columnIds: []
    }
    if (type == '1') {
      this.progressFlag = 0
      this.isPdPreserve = true
      this.isSelectedFalseArr = []
      let timer = setInterval(() => {
        if (this.progressFlag == this.paneData.columnList.length) {
          clearInterval(timer)
          this.isPdPreserve = false
          if (!this.isSelectedFalseArr.length) {
            this.$message({
              message: '请勾选拒绝项',
              type: 'error'
            })
            this.loadingFlag = false
            return
          }
          if (!this.remark) {
            this.$message({
              message: '请填写拒绝意见',
              type: 'error'
            })
            this.loadingFlag = false
            return
          }
          for (let i = 0; i < this.isSelectedFalseArr.length; i++) {
            params.columnIds.push(this.isSelectedFalseArr[i])
          }
          pendingApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$router.go(-1)
              }
            })
            .finally(() => {
              this.loadingFlag = false
            })
        }
      }, 200)
    } else {
      pendingApproval(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$router.go(-1)
          }
        })
        .finally(() => {
          this.loadingFlag = false
        })
    }
  }
}
