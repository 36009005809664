var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "relation-transaction-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("请勾选设置本次成交结果")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { disabled: _vm.isDisabled, label: true },
                      model: {
                        value: _vm.info.isComplete,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "isComplete", $$v)
                        },
                        expression: "info.isComplete"
                      }
                    },
                    [_vm._v("交易完成")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { disabled: _vm.isDisabled, label: false },
                      model: {
                        value: _vm.info.isComplete,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "isComplete", $$v)
                        },
                        expression: "info.isComplete"
                      }
                    },
                    [_vm._v("交易无效")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("交易信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }