var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "my-approval-detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "标的信息", name: "1" } },
            [
              _c("offline-basic-info", {
                attrs: { info: _vm.proDetail },
                on: { updateSuccess: _vm.updateSuccess }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "转出方信息", name: "2" } },
            [
              _c("offline-transferor-info", {
                attrs: { info: _vm.proDetail, type: 1 }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "转入方信息", name: "3" } },
            [
              _c("offline-transferor-info", {
                attrs: { info: _vm.proDetail, type: 2 }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "鉴证机构", name: "4" } },
            [_c("offline-dept-info", { attrs: { info: _vm.proDetail } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "项目资料", name: "5" } },
            [
              _c("offline-need-data", {
                attrs: {
                  info: _vm.proDetail.files,
                  offlineProjectId: _vm.offlineProjectId
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "import-btn" }, [
        _c(
          "div",
          { staticClass: "switch-div" },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-family": "PingFangSC-Medium",
                  "font-weight": "600",
                  "font-size": "14px",
                  color: "#3B445C",
                  "margin-right": "15px"
                }
              },
              [_vm._v("是否公开展示")]
            ),
            _c("el-switch", {
              on: {
                change: function($event) {
                  return _vm.handleSwitch(
                    $event,
                    _vm.proDetail.offlineProjectId
                  )
                }
              },
              model: {
                value: _vm.proDetail.isShow,
                callback: function($$v) {
                  _vm.$set(_vm.proDetail, "isShow", $$v)
                },
                expression: "proDetail.isShow"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }