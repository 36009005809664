// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.hide .el-upload--picture-card {\n  display: none !important;\n}\n.uploader {\n  width: 150px;\n  height: auto;\n}\n.uploader /deep/ .el-upload .info-container {\n    cursor: pointer;\n    position: relative;\n    overflow: hidden;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff"
};
module.exports = exports;
