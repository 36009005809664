
import { Component, Vue } from 'vue-property-decorator'
import { localStore } from '@/utils/data-management'
import QrCode from '@/components/QrCodeFirst.vue'
import authForm from './component/authForm.vue'
import { getRoleMenus } from '@/api/login'
import { Loading } from 'element-ui'
import initImage from '@/assets/images/init_image.png'
import { loadingText } from '@/utils/defaultGoalConfig'
import { addOrganizationData, updateOrganizationData, getOrganizationDetail, validIdentity } from '@/api/login'
import UploadFirst from '@/components/UploadFirst/index.vue'
@Component({
  components: {
    QrCode,
    UploadFirst,
    authForm
  }
})
export default class VerifyDetail extends Vue {
  private loadingTxt: string = loadingText
  private showClose: boolean = false
  private isModalClose: boolean = false
  private imageUrl: string = ''
  private center: boolean = true
  private options: boolean = false
  private initManagerIdentityFront: any = '' // 初始授权人地址
  private initPng: any = initImage
  // 表单数据
  private formDatass: any = {}
  private authForm: boolean = false
  private type: number = 0

  private uploadOption: any = {
    accept: '.jpeg,.jpg,.png',
    maxSize: 2,
    tip: '请上传身份证照片'
  }
  private uploadImg: boolean = false
  private QrCode: boolean = false
  private isPass: string = ''
  private handleDialogTableVisible() {
    // this.dialogTableVisible = true
  }
  // 分类选项数据
  private formInfo: any = {}
  private province: any = []

  // 用户信息
  private userInfo: any = {
    name: '',
    idCard: ''
  }
  // ----------------------------------------data数据-----------end-------------------------------------------------------------》》》》》》》》》》
  created() {
    if (this.$route.params && this.$route.params.type) {
      localStore.set('types', this.$route.params.type)
    }
    if (this.$route.params && this.$route.params.isPass && this.$route.params.isPass != 'null') {
      this.isPass = this.$route.params.isPass
    }

    if (this.$route.params.type === '1' || localStore.get('types') === '1') {
      this.authForm = true
      this.type = 1
      this.formDatass = { companyInfo: {}, companyFileRelList: [] }
    } else if (this.$route.params.type === '2' || localStore.get('types') === '2') {
      this.authForm = true
      this.formDatass = { companyInfo: {}, companyFileRelList: [] }
      this.type = 2
    } else if (this.$route.params.type === '4' || localStore.get('types') === '4') {
      this.getFormData()
    } else {
      this.authForm = false
      this.uploadImg = true
    }
  }
  // mounted() {
  // this.handleProvinceData()
  // }
  // private handlePropertyRightTransactionCenter() {
  //   const data: any = {
  //     categoryName: 'propertyright_transaction_center'
  //   }
  //   getPropertyrightTransactionCenterData(data)
  //     .then((res) => {
  //       let data: any = res.data.data
  //       let arr: any = []
  //       data.forEach((item: any) => {
  //         arr.push({ label: item.value, value: item.key })
  //       })
  //       this.propertyRightTransactionCenterData = arr
  //     })
  //     .catch((res) => {
  //       console.log(res.data)
  //     })
  // }
  // private handleIndustry() {
  //   const data: any = {
  //     categoryName: 'Industry'
  //   }
  //   getIndustryData(data)
  //     .then((res) => {
  //       let data: any = res.data.data
  //       let arr: any = []
  //       data.forEach((item: any) => {
  //         arr.push({ label: item.value, value: item.key })
  //       })
  //       this.industryData = arr
  //     })
  //     .catch((res) => {
  //       console.log(res.data)
  //     })
  // }
  getTriggers(data: any) {
    this.isPass = data
  }
  // 获取详情
  private getFormData() {
    getOrganizationDetail({}).then((res) => {
      let companyData: any = res.data.data.company
      let companyFileRelList: any = res.data.data.companyFileRels
      let formDatass: any = { companyInfo: { ...companyData }, companyFileRelList }
      if (formDatass.companyInfo.managerIdentityFront) {
        this.initManagerIdentityFront = formDatass.companyInfo.managerIdentityFront
        formDatass.companyInfo.managerIdentityFront = this.initPng
      }
      this.formDatass = formDatass
      this.authForm = true
    })
  }

  // 机构认证部分--------------------------------------------------------
  // 提交认证详情---
  handleFormData(data: any) {
    let companyInfo: object = data.datas.companyInfo
    let datas: any = {
      ...companyInfo,
      callbackId: data.datas.callbackId,
      companyFileRelList: data.datas.companyFileRelList
    }
    datas.areaConfigId = datas.applyAreaConfigId
    // delete datas.applyAreaConfigId
    if (datas.managerIdentityFront === this.initPng) {
      datas.managerIdentityFront = this.initManagerIdentityFront
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (data.flag === 'edit') {
      updateOrganizationData(datas)
        .then((res) => {
          getRoleMenus()
            .then((res) => {
              let datas: any = res.data.data
              if (res.data.data) {
                datas.forEach((item: any) => {
                  item.children.unshift({
                    path: '/home',
                    name: 'Home',
                    meta: {
                      title: '首页',
                      icon: 'star'
                    }
                  })
                })
                this.$store.commit({ type: 'ROUTE_UPDATE', datas: datas[0].children })
                localStore.set('routes', datas[0].children)
              }
            })
            .finally(() => {
              setTimeout(() => {
                this.$router.push({ name: 'AccountManagement' })
              }, 300)
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        })
        .catch(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      addOrganizationData(datas)
        .then((res) => {
          this.$router.push({ name: 'Home' })
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  // 机构认证部分------------------------------------------end-----------

  // 个人认证部分-----------------------------------------------

  // 人脸二维码验证结果返回
  private resultVerify(data: any) {
    if (data) {
      // this.dialogTableVisibles = false
      this.$store.commit('MODIF_USERINFO', '1')
      this.$store.commit('CHANGE_ROUTEARR', '1')
      this.$router.push({ name: 'Home' })
    }
  }
  // 上传成功的返回
  private getFileUrlUrl(data: any) {
    if (data.flag) {
      this.imageUrl = data.url
      this.validCard()
      // this.QrCode = true
      // this.uploadImg = false
    } else {
      this.$message.warning('缺少上传图片返回的图片地址~~')
      return
    }
  }
  // 上传图片校验
  private validCard() {
    let params = {
      image: this.imageUrl
    }
    validIdentity(params)
      .then((res) => {
        if (!res.data.data) {
          return this.$message.warning('身份证识别失败，请重新上传')
        } else if (!res.data.data.name) {
          return this.$message.warning('请上传身份证正面照')
        } else if (!res.data.data.idNumber) {
          return this.$message.warning('身份证识别失败，请重新上传')
        }
        this.userInfo.idCard = res.data.data.idNumber
        this.userInfo.name = res.data.data.name
        this.QrCode = true
        this.uploadImg = false
      })
      .catch((err) => {
        // ;(this.$refs.asdf as any).handleRemove()
        this.imageUrl = ''
      })
  }

  // 个人认证部分-----------------------------------------------end--------------
  // 弹框处理
  private open(message: string, title: string, flag: string, confirmButtonText?: string, showCancelButton?: boolean, cancelButtonText?: string) {
    this.$confirm(message, title, {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: false,
      confirmButtonText: confirmButtonText || '退出',
      cancelButtonText: cancelButtonText || '',
      showCancelButton: showCancelButton || false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then(() => {
        if (flag === '1') {
          this.$store.dispatch('logout')
        } else if (flag === '0') {
          this.handleDialogTableVisible()
        }
      })
      .catch((res) => {
        this.$store.dispatch('logout')
      })
  }
}
