
import { Component, Vue } from 'vue-property-decorator'
import { quillEditor } from 'vue-quill-editor'
import { getLicensingAgreementArea, getLicensingAgreementDetail, addLicensingAgreement, saveLicensingAgreement } from '@/api/systemConfig'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    quillEditor
  }
})
export default class LicensingAgreement extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private loading: boolean = false
  private isShow: boolean = false
  private formInfo: any = {
    areaConfigId: '',
    agreementName: '',
    isShow: '0',
    agreementDetail: '',
    id: ''
  }
  private areaOptions: any = []
  private editorOption = {
    placeholder: '编辑内容',
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          // [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ align: [] }]
        ]
      }
    }
  }
  created() {
    let isGetList: boolean = true
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.isSuperAdmin != '1') {
      !this.formInfo.areaConfigId && (this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
      !this.formInfo.areaConfigId && (isGetList = false)
      isGetList && this.getDetails()
    }
    if (isGetList) {
      this.isSuperAdmin == '1' && (this.loading = true)
      getLicensingAgreementArea()
        .then((res) => {
          if (res.data.data) {
            this.areaOptions = res.data.data
            if (this.isSuperAdmin == '1') {
              this.formInfo.areaConfigId = this.areaOptions[0].id
              this.getDetails()
            }
          } else {
            this.isSuperAdmin == '1' && (this.loading = false)
          }
        })
        .catch(() => {
          this.isSuperAdmin == '1' && (this.loading = false)
        })
    }
  }
  private handleSwitch(value: any) {
    value ? (this.formInfo.isShow = '1') : (this.formInfo.isShow = '0')
  }
  private getDetails() {
    this.loading = true
    getLicensingAgreementDetail({ areaConfigId: this.formInfo.areaConfigId })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          Object.keys(this.formInfo).forEach((key) => {
            if (key == 'areaConfigId') {
              return
            }
            if (key != 'isShow') {
              this.formInfo[key] = datas[key] ? datas[key] : ''
            } else {
              this.formInfo[key] = !datas[key] ? '0' : datas[key]
              datas[key] == 1 && (this.isShow = true)
            }
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private selecttedArea(value: any) {
    if (value) {
      Object.keys(this.formInfo).forEach((key) => {
        if (key == 'areaConfigId') {
          return
        }
        if (key != 'isShow') {
          this.formInfo[key] = ''
        } else {
          this.formInfo[key] = '0'
          this.isShow = false
        }
      })
      this.getDetails()
    }
  }
  private saveFn() {
    if (!this.formInfo.areaConfigId) {
      this.$message.error('选择区域后再提交')
      return
    }
    if (!this.formInfo.agreementName || !this.formInfo.agreementDetail) {
      this.$message.error('所有必填项,不能为空')
      return
    }
    this.$confirm('确定信息无误，并保存？', '温馨提示')
      .then(() => {
        this.loading = true
        if (this.formInfo.id) {
          saveLicensingAgreement({ ...this.formInfo })
            .then(() => {
              this.$message.success('保存成功')
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          addLicensingAgreement({ ...this.formInfo })
            .then(() => {
              this.$message.success('保存成功')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
      .catch(() => {})
  }
}
