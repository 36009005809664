var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mainLoading,
          expression: "mainLoading"
        }
      ],
      staticClass: "assessment-team-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "details-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("评定小组名称")]),
        _c(
          "div",
          { staticClass: "item-content" },
          [
            _c("el-input", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.formInfo.groupName,
                callback: function($$v) {
                  _vm.$set(_vm.formInfo, "groupName", $$v)
                },
                expression: "formInfo.groupName"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "details-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("评定小组管辖区域")]),
        _c(
          "div",
          { staticClass: "item-content child-styles" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: !_vm.requestEnd,
                  placeholder: "请选择省份",
                  size: "small"
                },
                on: { change: _vm.selecttedProvince },
                model: {
                  value: _vm.formInfo.province,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "province", $$v)
                  },
                  expression: "formInfo.province"
                }
              },
              _vm._l(_vm.provinceOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: item }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  disabled: !_vm.requestEnd,
                  placeholder: "请选择城市",
                  size: "small"
                },
                on: { change: _vm.selecttedCity },
                model: {
                  value: _vm.formInfo.city,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "city", $$v)
                  },
                  expression: "formInfo.city"
                }
              },
              _vm._l(_vm.cityOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: item }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  disabled: !_vm.requestEnd,
                  placeholder: "请选择区县",
                  size: "small"
                },
                on: { change: _vm.selecttedCounty },
                model: {
                  value: _vm.formInfo.county,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "county", $$v)
                  },
                  expression: "formInfo.county"
                }
              },
              _vm._l(_vm.countyOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: item }
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "请选择街道/镇",
                  size: "small",
                  disabled: _vm.townshipDisabled
                },
                model: {
                  value: _vm.formInfo.township,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "township", $$v)
                  },
                  expression: "formInfo.township"
                }
              },
              _vm._l(_vm.townshipOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: item }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "details-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("小组人员设置")]),
        _c("div", { staticClass: "item-content item-flex" }, [
          _c(
            "div",
            { staticClass: "innerBox" },
            [
              _vm._l(_vm.personList, function(tag, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "itemInfo",
                    on: {
                      click: function($event) {
                        return _vm.handleTab(tag, index)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(index + 1) + "、 "),
                    _c(
                      "el-tag",
                      {
                        staticClass: "tag",
                        attrs: { "disable-transitions": false }
                      },
                      [_vm._v(_vm._s(tag.name))]
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn item-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.addApprovalPerson()
                    }
                  }
                },
                [_vm._v("+ 新增")]
              )
            ],
            2
          )
        ])
      ]),
      _c("div", { staticClass: "details-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("评定层级")]),
        _c(
          "div",
          { staticClass: "item-content item-flex" },
          [
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.formInfo.levels,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "levels", $$v)
                  },
                  expression: "formInfo.levels"
                }
              },
              [
                _c("el-checkbox", { attrs: { label: 1 } }, [_vm._v("信用村")]),
                _c("el-checkbox", { attrs: { label: 2 } }, [
                  _vm._v("信用乡镇")
                ]),
                _c("el-checkbox", { attrs: { label: 3 } }, [
                  _vm._v("信用县(市、区)")
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "details-option" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function($event) {
                  return _vm.sumbitFn(_vm.$route.params.pageType)
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: (_vm.flag == "0" ? "新增" : "重选") + "审批人员",
            visible: _vm.dialogTableVisible,
            width: "30%",
            center: true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "inpiutBox" },
            [
              _c("el-input", {
                staticClass: "inputVal",
                attrs: { size: "mini", placeholder: "请输入关键字搜索" },
                on: {
                  input: function($event) {
                    return _vm.handleSearch()
                  }
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tables" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.gridData,
                    "max-height": "280",
                    "show-header": false,
                    "highlight-current-row": "",
                    size: "small",
                    border: ""
                  },
                  on: { "current-change": _vm.handleIsSelect }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", align: "left" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleConfirm()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }