var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingFlag,
          expression: "loadingFlag"
        }
      ],
      staticClass: "pending-approval-detail",
      attrs: {
        "element-loading-text": _vm.loadingText,
        "element-loading-background": "rgba(255,255,255,0.5)"
      }
    },
    [
      _vm.paneData && _vm.paneData.columnList
        ? _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.paneData.columnList, function(item, index) {
              return _c(
                "el-tab-pane",
                { key: index, attrs: { name: index + 1 + "" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("div", { staticClass: "fixed-width" }, [
                      _vm._v(
                        _vm._s(item.columnName) +
                          _vm._s(item.isRequire ? "*" : "")
                      )
                    ])
                  ]),
                  _c("table-column", {
                    attrs: {
                      hasSum: _vm.hasSum[index],
                      info: item,
                      isPdPreserve: _vm.isPdPreserve
                    },
                    on: { isSelectedFalse: _vm.isSelectedFalse }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.paneData
        ? _c("div", { staticClass: "comment-box" }, [
            _c("div", { staticClass: "comment-title" }, [_vm._v("审核意见")]),
            _c(
              "div",
              { staticClass: "comment-content" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 6 },
                    resize: "none"
                  },
                  model: {
                    value: _vm.remark,
                    callback: function($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.returnPaper } },
            [_vm._v("返回")]
          ),
          _vm.paneData
            ? _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt("1")
                    }
                  }
                },
                [_vm._v("驳回")]
              )
            : _vm._e(),
          _vm.paneData
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt("2")
                    }
                  }
                },
                [_vm._v("通过")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }