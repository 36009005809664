var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mainLoading,
          expression: "mainLoading"
        }
      ],
      staticClass: "regional-approval-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _vm.isGetSuccess
        ? _c(
            "el-form",
            {
              attrs: { model: _vm.datas, size: "small", "label-width": "200px" }
            },
            [
              _c("div", { staticClass: "part relative-div" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$route.params.type
                          ? ["村组", "乡镇", "县"][_vm.$route.params.type]
                          : ""
                      ) + "基本信息"
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$route.params.type
                            ? ["村组：", "乡镇：", "县(市、区)："][
                                _vm.$route.params.type
                              ]
                            : "："
                        }
                      },
                      [
                        _vm.datas.province
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.datas.province +
                                    (_vm.datas.city
                                      ? "-" + _vm.datas.city
                                      : "") +
                                    (_vm.datas.county
                                      ? "-" + _vm.datas.county
                                      : "") +
                                    (_vm.datas.township
                                      ? "-" + _vm.datas.township
                                      : "") +
                                    (_vm.datas.villageName
                                      ? "-" + _vm.datas.villageName
                                      : "")
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c("el-form-item", { attrs: { label: "联系人：" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.datas.contactName))])
                    ]),
                    _c("el-form-item", { attrs: { label: "联系电话：" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.datas.contactPhone))])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    class: {
                      "absolute-div": true,
                      "red-div": _vm.$route.params.approvalType == "0",
                      "green-div": _vm.$route.params.approvalType == "1"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$route.params.approvalType == "0"
                          ? "取消信用"
                          : "信用评定"
                      )
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "part" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$route.params.type == "0"
                          ? "全村"
                          : _vm.$route.params.type == "1" ||
                            _vm.$route.params.type == "2"
                          ? "辖区"
                          : ""
                      ) + "农户基本情况"
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                (_vm.$route.params.type == "0"
                                  ? "全村"
                                  : _vm.$route.params.type == "1" ||
                                    _vm.$route.params.type == "2"
                                  ? "辖区"
                                  : "") + "农户总数："
                            }
                          },
                          [
                            _vm.$route.params.type == "0"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.datas.villageFarmerNumber))
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.jurisdictionFarmerNumber)
                                  )
                                ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "应采集信息农户数：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.shouldCollectedFarmerNumber)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "已采集信息农户数：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.alreadyCollectedFarmerNumber)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                (_vm.$route.params.type == "0"
                                  ? "全村"
                                  : _vm.$route.params.type == "1" ||
                                    _vm.$route.params.type == "2"
                                  ? "辖区"
                                  : "") + "信用户总数："
                            }
                          },
                          [
                            _vm.$route.params.type == "0"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.villageCreditFarmerNumber)
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.jurisdictionCreditFarmerNumber
                                    )
                                  )
                                ])
                          ]
                        ),
                        _vm.$route.params.type == "1" ||
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "辖区行政村总数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas
                                        .jurisdictionAdministrativeVillageNumber
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "1" ||
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "辖区信用村总数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.jurisdictionCreditVillageNumber
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "辖区乡(镇)总数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.jurisdictionTownshipNumber)
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "辖区信用乡(镇)总数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.jurisdictionCreditTownshipNumber
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                (_vm.$route.params.type == "0"
                                  ? "全村"
                                  : _vm.$route.params.type == "1" ||
                                    _vm.$route.params.type == "2"
                                  ? "辖区"
                                  : "") + "贷款农户总数："
                            }
                          },
                          [
                            _vm.$route.params.type == "0"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.villageGetLoanFarmerNumber)
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.jurisdictionGetLoanFarmerNumber
                                    )
                                  )
                                ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "按期归还贷款的农户数：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.scheduleBackLoanFarmerNumber)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                (_vm.$route.params.type == "0"
                                  ? "全村"
                                  : _vm.$route.params.type == "1" ||
                                    _vm.$route.params.type == "2"
                                  ? "辖区"
                                  : "") + "农户贷款余额："
                            }
                          },
                          [
                            _vm.$route.params.type == "0"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.villageLoanBalance) +
                                      "万元"
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.jurisdictionLoanBalance) +
                                      "万元"
                                  )
                                ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                (_vm.$route.params.type == "0"
                                  ? "全村"
                                  : _vm.$route.params.type == "1" ||
                                    _vm.$route.params.type == "2"
                                  ? "辖区"
                                  : "") + "农户不良贷款余额："
                            }
                          },
                          [
                            _vm.$route.params.type == "0"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.villageBadLoanBalance) +
                                      "万元"
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.jurisdictionBadLoanBalance
                                    ) + "万元"
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.$route.params.type == "1"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label:
                                "辖区自然人、法人被列入行业管理严重失信名单情况(占比)："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isJurisdictionNoCreditFarmer,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isJurisdictionNoCreditFarmer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "datas.isJurisdictionNoCreditFarmer"
                                }
                              },
                              [_vm._v("有")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isJurisdictionNoCreditFarmer,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isJurisdictionNoCreditFarmer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "datas.isJurisdictionNoCreditFarmer"
                                }
                              },
                              [_vm._v("无")]
                            ),
                            _vm.datas.isJurisdictionNoCreditFarmer
                              ? _c("span", [
                                  _vm._v(
                                    "占比：" +
                                      _vm._s(
                                        _vm.datas.jurisdictionNoCreditFarmerRate
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "2"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label:
                                "辖区金融机构近三年涉农贷款占全县(市、区)金融机构贷款总量："
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.threeYearLoanFinancialRate) +
                                  "%"
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "2"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label:
                                "辖区自然人、法人被列入各行业严重失信名单、黑名单情况(占比)："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isJurisdictionNoCreditFarmer,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isJurisdictionNoCreditFarmer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "datas.isJurisdictionNoCreditFarmer"
                                }
                              },
                              [_vm._v("有")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isJurisdictionNoCreditFarmer,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isJurisdictionNoCreditFarmer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "datas.isJurisdictionNoCreditFarmer"
                                }
                              },
                              [_vm._v("无")]
                            ),
                            _vm.datas.isJurisdictionNoCreditFarmer
                              ? _c("span", [
                                  _vm._v(
                                    "占比：" +
                                      _vm._s(
                                        _vm.datas.jurisdictionNoCreditFarmerRate
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "part" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("创新评定覆盖率情况")])
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "农户信用信息入库率：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.farmerCreditEntryRate) + "%"
                              )
                            ])
                          ]
                        ),
                        _vm.$route.params.type == "1"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "信用户占全乡（镇）采集入库农户总数："
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.creditInCollectedFarmerRate
                                    ) + "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    "信用农户占全县（市、区）采集入库农户总数："
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.creditInCollectedFarmerRate
                                    ) + "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "1" ||
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "信用村占行政村总数：" } },
                              [
                                _vm.$route.params.type == "1"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.datas
                                            .creditVillageInAdministrativeRate
                                        ) + "%"
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.datas
                                            .creditVillageInAdministrativeVillageRate
                                        ) + "%"
                                      )
                                    ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "0"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "信用户占比：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.creditFarmerInFarmerRate) +
                                      "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "信用乡镇占全县（市、区）乡镇总数："
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas
                                        .creditTownshipInAdministrativeTownshipRate
                                    ) + "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "农村中小金融机构总数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.ruralSmallFinancialRate) +
                                      "个"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "按期归还贷款农户占比：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.scheduleBackInGetFarmerRate) +
                                  "%"
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "涉农贷款不良率：" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.datas.badCreditFarmerRate) + "%"
                              )
                            ])
                          ]
                        ),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "农户信用贷款覆盖率：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.datas.farmerCreditCoverageLoanRate
                                    ) + "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "农户贷款满足率：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.farmerLoanMeetRate) + "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.params.type == "2"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "新型农村合作医疗保险参保率：" }
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.datas.newMedicalInsuranceRate) +
                                      "%"
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.$route.params.type == "0"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: { label: "是否设立三农金融服务室：" }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isSetFinancial,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isSetFinancial",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isSetFinancial"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isSetFinancial,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isSetFinancial",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isSetFinancial"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label: "“两委”班子是否团结、群组威信高："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isHonesty,
                                      callback: function($$v) {
                                        _vm.$set(_vm.datas, "isHonesty", $$v)
                                      },
                                      expression: "datas.isHonesty"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isHonesty,
                                      callback: function($$v) {
                                        _vm.$set(_vm.datas, "isHonesty", $$v)
                                      },
                                      expression: "datas.isHonesty"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "1"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "乡镇所属部门、单位及创办经济实体是否有逃废债行为："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isTownshipDefaulted,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isTownshipDefaulted",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isTownshipDefaulted"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isTownshipDefaulted,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isTownshipDefaulted",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isTownshipDefaulted"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "乡镇所属部门、单位及创办经济实体有无不良贷款："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isTownshipBadLoan,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isTownshipBadLoan",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isTownshipBadLoan"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isTownshipBadLoan,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isTownshipBadLoan",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isTownshipBadLoan"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: { label: "班子是否团结务实、诚实守信：" }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isHonesty,
                                      callback: function($$v) {
                                        _vm.$set(_vm.datas, "isHonesty", $$v)
                                      },
                                      expression: "datas.isHonesty"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isHonesty,
                                      callback: function($$v) {
                                        _vm.$set(_vm.datas, "isHonesty", $$v)
                                      },
                                      expression: "datas.isHonesty"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "0" ||
                    _vm.$route.params.type == "1"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: { label: "是否关心和支持金融相关工作：" }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isCareFinancial,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isCareFinancial", $$v)
                                  },
                                  expression: "datas.isCareFinancial"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isCareFinancial,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isCareFinancial", $$v)
                                  },
                                  expression: "datas.isCareFinancial"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "0"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label:
                                "农户被列入行业管理严重失信名单情况(占比)："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isBlacklist,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isBlacklist", $$v)
                                  },
                                  expression: "datas.isBlacklist"
                                }
                              },
                              [_vm._v("有")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isBlacklist,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isBlacklist", $$v)
                                  },
                                  expression: "datas.isBlacklist"
                                }
                              },
                              [_vm._v("无")]
                            ),
                            _vm.datas.isBlacklist
                              ? _c("span", [
                                  _vm._v(
                                    "占比：" +
                                      _vm._s(_vm.datas.blacklistRate) +
                                      "%"
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "2"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "辖区金融机构近三年涉农贷款占全县（市、区）金融机构贷款总量是否高于全区平均水平："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isThreeBetterThanAverage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isThreeBetterThanAverage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isThreeBetterThanAverage"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isThreeBetterThanAverage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isThreeBetterThanAverage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isThreeBetterThanAverage"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "金融机构服务点行政村覆盖率达到1个/万人以上："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value:
                                        _vm.datas.isServiceMoreThanTenThousand,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isServiceMoreThanTenThousand",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isServiceMoreThanTenThousand"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value:
                                        _vm.datas.isServiceMoreThanTenThousand,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isServiceMoreThanTenThousand",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isServiceMoreThanTenThousand"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "县（市、区）产品监督抽查合格率是否居全区平均水平以上："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value:
                                        _vm.datas.isSamplingPassRateThanAverage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isSamplingPassRateThanAverage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isSamplingPassRateThanAverage"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value:
                                        _vm.datas.isSamplingPassRateThanAverage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isSamplingPassRateThanAverage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "datas.isSamplingPassRateThanAverage"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "long-item",
                                attrs: {
                                  label:
                                    "工业品和食品生产许可企业是否100%建立企业质量信用档案："
                                }
                              },
                              [
                                _c("div", { staticClass: "marks" }),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 1 },
                                    model: {
                                      value: _vm.datas.isQualityOneHundred,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isQualityOneHundred",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isQualityOneHundred"
                                    }
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: 0 },
                                    model: {
                                      value: _vm.datas.isQualityOneHundred,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.datas,
                                          "isQualityOneHundred",
                                          $$v
                                        )
                                      },
                                      expression: "datas.isQualityOneHundred"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.type == "1" ||
                    _vm.$route.params.type == "2"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: { label: "是否积极推动农村信用体系建设：" }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isActivePromote,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isActivePromote", $$v)
                                  },
                                  expression: "datas.isActivePromote"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isActivePromote,
                                  callback: function($$v) {
                                    _vm.$set(_vm.datas, "isActivePromote", $$v)
                                  },
                                  expression: "datas.isActivePromote"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm.$route.params.type == "2"
                ? _c("div", { staticClass: "part" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v("其他补充（参考数据）")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label:
                                "金融机构近三年资产利润率是否高于全区平均水平："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isThreeDepositThanAverage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeDepositThanAverage",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeDepositThanAverage"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isThreeDepositThanAverage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeDepositThanAverage",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeDepositThanAverage"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label: "金融机构近三年存款增幅高于全区水平："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isThreeProfitThanAll,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeProfitThanAll",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeProfitThanAll"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isThreeProfitThanAll,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeProfitThanAll",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeProfitThanAll"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "long-item",
                            attrs: {
                              label: "金融机构近三年贷款增幅高于全区平均水平："
                            }
                          },
                          [
                            _c("div", { staticClass: "marks" }),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.datas.isThreeLoanAddThanAverage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeLoanAddThanAverage",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeLoanAddThanAverage"
                                }
                              },
                              [_vm._v("是")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.datas.isThreeLoanAddThanAverage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.datas,
                                      "isThreeLoanAddThanAverage",
                                      $$v
                                    )
                                  },
                                  expression: "datas.isThreeLoanAddThanAverage"
                                }
                              },
                              [_vm._v("否")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.apprvalGroups.length > 1
                ? _c(
                    "div",
                    _vm._l(
                      _vm.apprvalGroups.slice(0, _vm.apprvalGroups.length - 1),
                      function(item, index) {
                        return _c("div", { key: index, staticClass: "part" }, [
                          _c("div", { staticClass: "title" }, [
                            _c("span", [
                              _vm._v(_vm._s(item.assessmentGroupName))
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              item.creditAssessApprovalPersons &&
                              item.creditAssessApprovalPersons.length
                                ? _c(
                                    "el-timeline",
                                    _vm._l(
                                      item.creditAssessApprovalPersons,
                                      function(activity, index) {
                                        return _c(
                                          "el-timeline-item",
                                          {
                                            key: index,
                                            attrs: {
                                              icon:
                                                _vm.icon[
                                                  activity.processStatus
                                                ],
                                              type:
                                                _vm.iconType[
                                                  activity.processStatus
                                                ],
                                              size: "large",
                                              hideTimestamp: true
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "min-width": "100px",
                                                    display: "inline-block"
                                                  }
                                                },
                                                [_vm._v(_vm._s(activity.uname))]
                                              ),
                                              activity.processStatus != 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        activity.updatedTime
                                                      )
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm.$route.params.approvalType ==
                                                "1" &&
                                              activity.processStatus > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "paddingl-40"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.assessStatus ==
                                                            1
                                                            ? "符合"
                                                            : activity.assessStatus ==
                                                              2
                                                            ? "基本符合"
                                                            : activity.assessStatus ==
                                                              3
                                                            ? "不符合"
                                                            : ""
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              activity.processStatus == 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "success-status paddingl-40"
                                                    },
                                                    [_vm._v("同意")]
                                                  )
                                                : _vm._e(),
                                              activity.processStatus == 2
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "error-status paddingl-40"
                                                    },
                                                    [_vm._v("拒绝")]
                                                  )
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              item.creditAssessApprovalPersons &&
                              item.creditAssessApprovalPersons.length
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "审批结果：" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "success-status": item.status == 1,
                                            "error-status": item.status == 2
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.status == 1
                                                ? "同意"
                                                : item.status == 2
                                                ? "拒绝"
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "padding-r",
                                  attrs: {
                                    label:
                                      item.creditAssessApprovalPersons &&
                                      item.creditAssessApprovalPersons.length
                                        ? "审批意见："
                                        : "意见："
                                  }
                                },
                                [_c("span", [_vm._v(_vm._s(item.opinion))])]
                              ),
                              !(
                                item.creditAssessApprovalPersons &&
                                item.creditAssessApprovalPersons.length
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "time-status",
                                      attrs: { label: "时间：" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.updatedTime))
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "status-span": true,
                                            "success-status": item.status == 1,
                                            "error-status": item.status == 2
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.status == 1
                                                ? "同意"
                                                : item.status == 2
                                                ? "拒绝"
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.apprvalGroups.length &&
              _vm.apprvalGroups[_vm.apprvalGroups.length - 1]
                ? _c("div", { staticClass: "part" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.apprvalGroups[_vm.apprvalGroups.length - 1]
                              .assessmentGroupName
                          )
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _vm.apprvalGroups[_vm.apprvalGroups.length - 1]
                          .creditAssessApprovalPersons
                          ? _c(
                              "el-timeline",
                              _vm._l(
                                _vm.apprvalGroups[_vm.apprvalGroups.length - 1]
                                  .creditAssessApprovalPersons,
                                function(activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        icon: _vm.icon[activity.processStatus],
                                        type:
                                          _vm.iconType[activity.processStatus],
                                        size: "large",
                                        hideTimestamp: true
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "min-width": "100px",
                                              display: "inline-block"
                                            }
                                          },
                                          [_vm._v(_vm._s(activity.uname))]
                                        ),
                                        activity.processStatus != 0
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(activity.updatedTime)
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.$route.params.approvalType == "1" &&
                                        activity.processStatus > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "paddingl-40" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    activity.assessStatus == 1
                                                      ? "符合"
                                                      : activity.assessStatus ==
                                                        2
                                                      ? "基本符合"
                                                      : activity.assessStatus ==
                                                        3
                                                      ? "不符合"
                                                      : ""
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        activity.processStatus == 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "success-status paddingl-40"
                                              },
                                              [_vm._v("同意")]
                                            )
                                          : _vm._e(),
                                        activity.processStatus == 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "error-status paddingl-40"
                                              },
                                              [_vm._v("拒绝")]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm.$route.params.approvalType == "1"
                          ? _c(
                              "div",
                              { staticClass: "paddingT-40 situation-box" },
                              [
                                _c("div", [
                                  _c("span", [_vm._v("*")]),
                                  _vm._v("符合情况：")
                                ]),
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.assessStatus,
                                      callback: function($$v) {
                                        _vm.assessStatus = $$v
                                      },
                                      expression: "assessStatus"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("符合")
                                    ]),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("基本符合")
                                    ]),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("不符合")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "paddingT-40",
                            attrs: { label: "审批意见：" }
                          },
                          [
                            _c("el-input", {
                              staticClass: "area",
                              attrs: {
                                type: "textarea",
                                rows: 4,
                                placeholder: "请输入审批意见",
                                resize: "none"
                              },
                              model: {
                                value: _vm.options,
                                callback: function($$v) {
                                  _vm.options = $$v
                                },
                                expression: "options"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.isGetSuccess
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalSubmit(2)
                    }
                  }
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalSubmit(1)
                    }
                  }
                },
                [_vm._v("通过")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }