var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "enterprise-info"
    },
    [
      _c(
        "el-form",
        { staticClass: "form" },
        [
          _c("el-form-item", { attrs: { label: "企业名称" } }, [
            _vm._v(_vm._s(_vm.info.enterpriseName))
          ]),
          _c("el-form-item", { attrs: { label: "统一信用代码" } }, [
            _vm._v(_vm._s(_vm.info.creditCode))
          ]),
          _c("el-form-item", { attrs: { label: "注册地址" } }, [
            _vm._v(_vm._s(_vm.info.registAddress))
          ]),
          _c("el-form-item", { attrs: { label: "法人代表" } }, [
            _vm._v(_vm._s(_vm.info.legalPerson))
          ]),
          _c("el-form-item", { attrs: { label: "联系电话" } }, [
            _vm._v(_vm._s(_vm.info.contactPhone))
          ]),
          _c("el-form-item", { attrs: { label: "联系地址" } }, [
            _vm._v(_vm._s(_vm.info.contactAddress))
          ]),
          _c("el-form-item", { attrs: { label: "联系人" } }, [
            _vm._v(_vm._s(_vm.info.contactMember))
          ]),
          _c("el-form-item", { attrs: { label: "行业" } }, [
            _vm._v(_vm._s(_vm.info.configKeyIndustry))
          ]),
          _c("el-form-item", { attrs: { label: "企业所属行业" } }, [
            _vm._v(_vm._s(_vm.info.configkeyIndustryType))
          ]),
          _c("el-form-item", { attrs: { label: "企业资料" } }, [
            _c(
              "div",
              { staticClass: "files" },
              _vm._l(_vm.info.reportResponseList, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          href: item.fileAddr,
                          target: "_blank",
                          underline: false
                        }
                      },
                      [_vm._v(_vm._s(item.fileName))]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }