var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.vLoading,
          expression: "vLoading"
        }
      ],
      staticClass: "add-notice",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "one-input",
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.formInfo.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "title", $$v)
                  },
                  expression: "formInfo.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "configTypeKey" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formInfo.configTypeKey,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "configTypeKey", $$v)
                    },
                    expression: "formInfo.configTypeKey"
                  }
                },
                _vm._l(_vm.projectType, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.value, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.formInfo.configTypeKey == "4"
            ? _c(
                "el-form-item",
                {
                  staticClass: "position-relative",
                  attrs: { label: "标的名称", prop: "name" }
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "select-input",
                        on: {
                          mouseenter: function($event) {
                            _vm.isFocus = true
                          },
                          mouseleave: function($event) {
                            _vm.isFocus = false
                          }
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入",
                            "prefix-icon": "el-icon-search"
                          },
                          on: {
                            focus: _vm.changeInput,
                            input: _vm.changeInput,
                            blur: _vm.outDiv
                          },
                          model: {
                            value: _vm.formInfo.name,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "name", $$v)
                            },
                            expression: "formInfo.name"
                          }
                        }),
                        _vm.showInput
                          ? _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "infinite-scroll",
                                    rawName: "v-infinite-scroll",
                                    value: _vm.upLoad,
                                    expression: "upLoad"
                                  }
                                ],
                                staticClass: "input-list",
                                attrs: {
                                  "infinite-scroll-disabled": _vm.disabled,
                                  "infinite-scroll-immediate": true,
                                  "infinite-scroll-distance": "28"
                                }
                              },
                              [
                                !_vm.loadin && !_vm.searchResult.length
                                  ? _c(
                                      "li",
                                      { staticClass: "list-item tips-item" },
                                      [_vm._v("无数据")]
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.searchResult, function(item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass: "list-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.getSelectData(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                _vm.loadin
                                  ? _c(
                                      "li",
                                      { staticClass: "list-item tips-item" },
                                      [_vm._v("加载中...")]
                                    )
                                  : _vm._e(),
                                _vm.noMore
                                  ? _c(
                                      "li",
                                      { staticClass: "list-item tips-item" },
                                      [_vm._v("没有更多了")]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "所属区域", prop: "areaConfigId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isSuperAdmin != "1",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.handleSelectedArea },
                  model: {
                    value: _vm.formInfo.areaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                    },
                    expression: "formInfo.areaConfigId"
                  }
                },
                _vm._l(_vm.areaData, function(item) {
                  return _c("el-option", {
                    key: item.areaConfigId,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId,
                      disabled: item.isDelete || item.isDisable
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布系统", prop: "systemConfigId" } },
            [
              _vm.isUpdate
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formInfo.systemConfigId,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "systemConfigId", $$v)
                        },
                        expression: "formInfo.systemConfigId"
                      }
                    },
                    _vm._l(_vm.filteZct(_vm.systemData), function(item) {
                      return _c("el-option", {
                        key: item.systemConfigId,
                        attrs: {
                          label: item.systemConfigName,
                          value: item.systemConfigId
                        }
                      })
                    }),
                    1
                  )
                : _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.formInfo.systemConfigIds,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "systemConfigIds", $$v)
                        },
                        expression: "formInfo.systemConfigIds"
                      }
                    },
                    _vm._l(_vm.filteZct(_vm.systemData), function(item) {
                      return _c("el-option", {
                        key: item.systemConfigId,
                        attrs: {
                          label: item.systemConfigName,
                          value: item.systemConfigId
                        }
                      })
                    }),
                    1
                  )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "release-date",
              attrs: { label: "发布日期", prop: "publicTime" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "date",
                  placeholder: "选择日期",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.formInfo.publicTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "publicTime", $$v)
                  },
                  expression: "formInfo.publicTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "来源" } },
            [
              _c("el-input", {
                staticClass: "one-input",
                attrs: { placeholder: "请输入来源" },
                model: {
                  value: _vm.formInfo.origin,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "origin", $$v)
                  },
                  expression: "formInfo.origin"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "封面图片" } }, [
            _c("div", { staticClass: "img-box" }, [
              _vm.formInfo.imgUrl
                ? _c("div", { staticClass: "show-img" }, [
                    _c(
                      "span",
                      {
                        staticClass: "close-btn",
                        on: {
                          click: function($event) {
                            return _vm.deleteImgFn()
                          }
                        }
                      },
                      [_vm._v("x")]
                    ),
                    _c("img", {
                      attrs: { src: _vm.formInfo.imgUrl },
                      on: {
                        click: function($event) {
                          return _vm.enlargeImg()
                        }
                      }
                    })
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "up-files",
                      style: { "background-image": "url(" + _vm.bgImg + ")" }
                    },
                    [
                      _c("upload-base", {
                        attrs: {
                          listShow: false,
                          uploadOption: _vm.uploadOption
                        },
                        on: { fileUrl: _vm.getFileUrl }
                      })
                    ],
                    1
                  )
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否置顶" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formInfo.isTop,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "isTop", $$v)
                  },
                  expression: "formInfo.isTop"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "上传附件" } }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "upload-annex" },
                [
                  _c("uploadTemp", {
                    attrs: {
                      limt: _vm.limt,
                      resultShow: false,
                      accept: _vm.accept,
                      fileSizeFilter: _vm.fileSizeFilter
                    },
                    on: { resultMore: _vm.fileUploadSuccess }
                  }),
                  _c("span", { staticClass: "annex-tips" }, [
                    _vm._v("附件将显示在文章末尾")
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filesL-box" },
                _vm._l(_vm.formInfo.announcementProjectFiles, function(
                  item,
                  index
                ) {
                  return _c(
                    "div",
                    { key: index, staticClass: "files-list" },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "files-link",
                          attrs: {
                            href: item.fileAddr,
                            target: "_blank",
                            underline: false
                          }
                        },
                        [_vm._v(_vm._s(item.fileName))]
                      ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.deleteDownloadUrl(index)
                            }
                          }
                        },
                        [_vm._v("x")]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "概述" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.formInfo.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "description", $$v)
                  },
                  expression: "formInfo.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "is-required", attrs: { label: "内容" } },
            [
              _c(
                "div",
                {
                  staticStyle: { background: "#fff", "line-height": "normal" }
                },
                [
                  _c("upload-base", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    attrs: {
                      id: "upload-btn",
                      listShow: false,
                      uploadOption: _vm.uploadOption2
                    },
                    on: { fileUrl: _vm.getFileUrl2 }
                  }),
                  _c("vue-ueditor-wrap", {
                    attrs: { destroy: true, config: _vm.myConfig },
                    on: { "before-init": _vm.addCustomButtom },
                    model: {
                      value: _vm.formInfo.content,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "content", $$v)
                      },
                      expression: "formInfo.content"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.isUpdate ? "更新" : "提交"))]
          ),
          _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showEnlarge },
          on: {
            "update:visible": function($event) {
              _vm.showEnlarge = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.formInfo.imgUrl }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }