var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.homes
      ? _c(
          "div",
          { staticClass: "homes" },
          [
            _c("el-image", {
              staticClass: "no-move-img",
              staticStyle: { width: "750px" },
              attrs: { src: _vm.image, fit: "scale-down" }
            }),
            _c(
              "p",
              { staticStyle: { "font-size": "24px", "margin-top": "60px" } },
              [
                _vm._v(
                  "欢迎使用" +
                    _vm._s(
                      _vm.systemType == "rural"
                        ? "农村信息综合管理系统"
                        : "企业信息披露服务管理后台"
                    )
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.options
      ? _c("div", { staticClass: "authTitile" }, [_vm._v("认证")])
      : _vm._e(),
    _vm.options
      ? _c("div", { staticClass: "options" }, [
          _c(
            "div",
            {
              staticClass: "optionItem",
              style: {
                "background-image":
                  "url(" +
                  (_vm.imgActive[0]
                    ? _vm.hPresonActiveImg
                    : _vm.hPresonDefaultImg) +
                  ")"
              },
              on: {
                mouseover: function($event) {
                  return _vm.changeActive(true, 0)
                },
                mouseout: function($event) {
                  return _vm.changeActive(false, 0)
                },
                click: _vm.gotoFaceRecognition
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "text",
                  style: { color: _vm.imgActive[0] ? "#FFFFFF" : "#333333" }
                },
                [_vm._v("个人用户")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "optionItem",
              style: {
                "background-image":
                  "url(" +
                  (_vm.imgActive[1]
                    ? _vm.hGovernmentActiveImg
                    : _vm.hGovernmentDefaultImg) +
                  ")"
              },
              on: {
                mouseover: function($event) {
                  return _vm.changeActive(true, 1)
                },
                mouseout: function($event) {
                  return _vm.changeActive(false, 1)
                },
                click: _vm.gotoGovernmentInstitution
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "text",
                  style: { color: _vm.imgActive[1] ? "#FFFFFF" : "#333333" }
                },
                [_vm._v("政府机构")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "optionItem",
              style: {
                "background-image":
                  "url(" +
                  (_vm.imgActive[2]
                    ? _vm.hFinanceActiveImg
                    : _vm.hFinanceDefaultImg) +
                  ")"
              },
              on: {
                mouseover: function($event) {
                  return _vm.changeActive(true, 2)
                },
                mouseout: function($event) {
                  return _vm.changeActive(false, 2)
                },
                click: _vm.gotoFinancialInstitution
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "text",
                  style: { color: _vm.imgActive[2] ? "#FFFFFF" : "#333333" }
                },
                [_vm._v("金融机构")]
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }