
import { Component, Vue, Prop } from 'vue-property-decorator'
import request from '@/utils/request'
import bgSrc from '@/assets/images/up_files.png'
import UploadBase from '@/components/UploadBase64/index.vue'
import { num2Fixed } from '@/utils/validate'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
    components: {
        UploadBase,
    }
})
export default class AddOfflineRefund extends Vue {
    @Prop({
        default: () => {
            return {}
        }
    })
    rowInfo?: any
    @Prop() info: any // 基本信息
    bgImg: any = bgSrc
    uploadOption: any = {
        limit: 1,
        // accept: '.pdf',
        accept: '.png,.jpg,.jpeg,.pdf',
        flag: '1'
    }
    formData = {} as any // 线下退还信息
    files = [] as any

    get rules() {
        let validateAddr = (rule, value, callback) => {
            if (!this.files[0].url) {
                callback(new Error('请上传凭证'));
            } else {
                callback();
            }
        }
        let validateMoney = (rule, value, callback) => {
            if (!num2Fixed(this.formData.liquidationAmount)) {
                callback(new Error('只能输入数字，且小数点保留2位'));
            } else {
                callback();
            }
        }
        return {
            userName: [
                { required: true, message: '必填项', trigger: 'blur' },
            ],
            liquidationAmount: [
                { required: true, message: '必填项', trigger: 'blur' },
                {
                    required: true, validator: validateMoney, trigger: 'blur'
                },
            ], liquidationName: [
                { required: true, message: '必填项', trigger: 'blur' },
                { max: 8, message: '最多8位字符', trigger: 'blur' }
            ], liquidationTime: [
                { required: true, message: '必填项', trigger: 'blur' },
            ], fileAddr: [
                {
                    required: true, validator: validateAddr, trigger: 'blur'
                },
            ],

        }
    }
    created() {
        const doms = document.querySelectorAll('#liqTable tbody tr .cell span')
        this.formData.user = doms[0].innerHTML
        this.formData.userName = doms[1].innerHTML
    }
    confirmDialog() {
        (this.$refs['formData'] as any).validate((valid) => {
            if (valid) {
                console.log(this.files);
                
                let fileList = this.files.map((item)=>{
                    return {
                        fileName: item.name,
                        fileUrl: item.url,
                    }
                })
                let data = {
                    listingApplyId: this.info.id,
                    fileList: fileList,
                    ...this.formData
                }
                console.log(data);

                request({
                    url: '/liquidationApply/offlineClearing',
                    method: 'POST',
                    data: data
                }).then(res => {
                    console.log(res);
                    if (res.data.code == 1000) {
                        this.$message.success('提交成功')
                        // this.$parent.hide()
                        sessionStorage.setItem('liquidationDetail', '2')
                        location.reload()
                    }
                })
            } else {
                return false;
            }
        });
    }
    //上传图片
    getFileUrl(v: any) {
        this.uploadOption.limit = this.uploadOption.limit + 1
        if (v) {
            this.files.push(v)
            // this.$emit('getUrl', v)
            console.log(this.files);
            this.loadingInstance.close()
        }
    }
    //上传图片加载
    private loadingInstance: any
    private loadingTxt: string = loadingText
    private beforeUpFn() {
        this.loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    }
    private uploadError() {
        this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close()
        })
    }
    //删除图片
    private deleteImgFn(index: number, deletedItem: any) {
        this.$confirm('是否确认删除？', '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                this.files.splice(index, 1)
            })
            .catch(() => { })
    }
    //预览
    private showEnlarge: boolean = false // 放大图片
    private nowSrc: any = '' // 当前凭证图片路径
    private enlargeImg(src: any) {
        this.nowSrc = src
        this.showEnlarge = true
    }

}
