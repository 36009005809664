
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class SubjectInfo extends Vue {
  @Prop() info: any
  @Prop({ default: false }) checkboxShow?: boolean
  @Prop({
    default: () => {}
  })
  itemStatus?: any
  get getIdentityType() {
    let returnText: string = '',
      identityType: any = this.info ? this.info.identityType : '',
      text: any = ['', '企业', '组织', '个人']
    if (identityType) {
      returnText = text[identityType.value]
    }
    return returnText
  }
  @Prop({}) processStatus: number
}
