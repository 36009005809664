
import { Component, Vue } from 'vue-property-decorator'
import projectNotice from '../components/project-notice.vue'
import proInfo from '@/views/project-management/center-list/centers/pro-info.vue'
import markInfo from '../components/mark-info.vue'
import needData from '../components/need-data.vue'
import biddingAnnouncement from '../components/bidding-announcement.vue'
import approvalInfo from '../components/approval-info.vue'
import transactionInfo from '../components/transaction-info.vue'
import auctionInfo from '../components/auction-info.vue'
import authenticationBook from '../components/authentication-book.vue'
import subjectInfo from '../components/subject-info.vue'
import competeBuyInfo from '../components/compete-buy-info.vue'
import relationTransactionInfo from '../components/relation-transaction-info.vue'
import QrCode from '@/components/QrCode.vue'
import { Loading } from 'element-ui'
import { getCountrysideProject, activeDelisting, settingResult, uploadPDF, arSetResult, setBidder } from '@/api/countrysidePropertyTransaction'
// import { isNeedFace } from '@/api/common.api'
import { loadingText } from '@/utils/defaultGoalConfig'
import * as secret from '@/utils/secret'
import { localStore } from '@/utils/data-management'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
@Component({
  components: {
    proInfo,
    markInfo,
    needData,
    approvalInfo,
    biddingAnnouncement,
    transactionInfo,
    auctionInfo,
    authenticationBook,
    subjectInfo,
    competeBuyInfo,
    relationTransactionInfo,
    QrCode,
    projectNotice
  }
})
export default class ProjectListsDetails extends Vue {
  private loadingTxt: string = loadingText
  private isUpFile: boolean = false
  // private fileDisabled: any = [] // 固定文件禁用状态
  // private otherFileDisabled: any = [] // 其它文件禁用状态

  private updateTimeout: any = null // 更新定时器
  private printLoading: boolean = false
  private exportLoading: boolean = false
  // 增减类型交易信息
  private relationTransactionInfo: any = {
    isComplete: true
  }
  private time: any = '' // 时间
  private pickerOptions: any = {
    // 限制日期选择器不让选择今天以前的
    disabledDate(time: any) {
      return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
    }
  }
  // 竞买信息
  private competeBuyInfo: any = {
    delistingBaseId: '',
    transactionPrice: '',
    confirmFile: []
  }

  private listingApplyId: any
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {}
  private markInfo: any = {}
  private qrDiaShow: boolean = false // 验证码展示
  private isSetPerson: boolean = false // 是否设置中标人
  private markShow: boolean = false // 弹窗显示标志位
  private markShow2: boolean = false //重新挂牌弹窗
  private isSuccess: any = '1' // 成交选项值
  private isDelisting: boolean = true
  private qrCodeType: string = ''
  // 设置成交结果按钮显示标志
  private isSetResult: boolean = false
  // 获取proDetail长度
  get getProLength() {
    return Object.keys(this.proDetail).length
  }
  get markHeight() {
    return this.proDetail.tranferType == '1' ? '450px' : '280px'
  }

  private watchExport: boolean = false
  private watchPdf: boolean = false
  private firstGetPdf: boolean = true
  private pdf: any
  private delistingType: any = ['', '全额付款', '预付款', '交付后再付', '其他']
  private tradeType: any = {
    '1': '竞拍',
    '2': '非竞拍 ',
    '3': '预售'
  }
  get areaUnit() {
    let info: any = this.proDetail.bidInfo
    return info && info.holdAreaUnit && info.holdAreaUnit.value ? info.holdAreaUnit.value : ''
  }
  get listingPriceShow() {
    if (!this.proDetail.bidInfo) {
      return ''
    }
    let returnVal: string = '',
      bidType: number = this.proDetail.bidInfo.bidType && this.proDetail.bidInfo.bidType.value ? this.proDetail.bidInfo.bidType.value : 0
    if (this.proDetail.bidInfo.listingPrice && this.proDetail.bidInfo.listingPrice.value) {
      if (this.proDetail.businessType != '1' && this.proDetail.businessType != '2') {
        let unit: string = this.proDetail.bidInfo.unit && this.proDetail.bidInfo.unit.value ? this.proDetail.bidInfo.unit.value : '',
          numberVal: any = this.proDetail.bidInfo.number && this.proDetail.bidInfo.number.value ? this.proDetail.bidInfo.number.value : ''
        returnVal = bidType === 0 ? this.proDetail.bidInfo.listingPrice.value + ' 元/' + numberVal + unit : this.proDetail.bidInfo.listingPrice.value + ' 元/' + unit
      } else {
        let holdArea: string = this.proDetail.bidInfo.holdArea && this.proDetail.bidInfo.holdArea.value ? this.proDetail.bidInfo.holdArea.value : ''
        returnVal = bidType === 0 ? this.proDetail.bidInfo.listingPrice.value + ' 元/' + holdArea + this.areaUnit : this.proDetail.bidInfo.listingPrice.value + ' 元/' + this.areaUnit
      }
    }
    return returnVal
  }
  private filterFiles(arr: any) {
    let returnArr: any = arr
    if (arr && arr.length) {
      returnArr = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].fileUrl && arr[i].fileUrl.length) {
          returnArr.push(arr[i])
        }
      }
    }
    return returnArr
  }
  private isImg(str: any) {
    let flag: boolean = false
    if (str) {
      let indFlag: any = str.lastIndexOf('.')
      if (indFlag > -1 && ['.png', '.jpeg', '.jpg'].indexOf(str.slice(indFlag)) > -1) {
        flag = true
      }
    }
    return flag
  }
  // 循环遍历打印转账方式
  private forPayWay(objName: any, fnParams: any) {
    let obj: any = this.proDetail.tradeInfo[objName],
      // 获取对象名对应数值
      mapKey: any = {
        paymentInfo: 16,
        promiseAmountInfo: 19,
        finalPaymentInfo: 22
      },
      preHeight: number = fnParams.preHeight,
      position: number = fnParams.position
    if (obj && obj.fileUrl) {
      for (let i = 0; i < obj.fileUrl.length; i++) {
        let imgTarget: any = (this.$refs['print3-' + mapKey[objName] + '-img' + i] as any)[0]
        if (imgTarget) {
          let returnParams: any = this.addPageContent(fnParams.pdf, fnParams.pageData, fnParams.pageHeight, fnParams.pageWhite, fnParams.totalImgHeight, imgTarget, preHeight, position)
          preHeight = returnParams.preHeight
          position = returnParams.position
        }
      }
    }
    return { preHeight, position }
  }
  // 循环遍历打印交易信息资料
  private forTradeInfoFiles(fnParams: any) {
    let obj: any = this.proDetail.tradeInfo,
      preHeight: number = fnParams.preHeight,
      position: number = fnParams.position
    // 进入相关资料遍历
    if (obj.delistingFiles) {
      for (let i = 0; i < obj.delistingFiles.length; i++) {
        let returnParams: any
        if (this.isImg(obj.delistingFiles[i].url)) {
          returnParams = this.addPageContent(fnParams.pdf, fnParams.pageData, fnParams.pageHeight, fnParams.pageWhite, fnParams.totalImgHeight, (this.$refs['print3-23-a-img' + i] as any)[0], preHeight, position)
        } else {
          returnParams = this.addPageContent(fnParams.pdf, fnParams.pageData, fnParams.pageHeight, fnParams.pageWhite, fnParams.totalImgHeight, (this.$refs['print3-23-a-' + i] as any)[0], preHeight, position)
        }
        preHeight = returnParams.preHeight
        position = returnParams.position
      }
    }
    // 进入相关合同遍历
    if (obj.contractFiles) {
      for (let i = 0; i < obj.contractFiles.length; i++) {
        if (obj.contractFiles[i].fileUrl) {
          for (let l = 0; l < obj.contractFiles[i].fileUrl.length; l++) {
            let returnParams: any
            if (this.isImg(obj.contractFiles[i].fileUrl[l])) {
              returnParams = this.addPageContent(fnParams.pdf, fnParams.pageData, fnParams.pageHeight, fnParams.pageWhite, fnParams.totalImgHeight, (this.$refs['print3-23-b-img' + i + '-' + l] as any)[0], preHeight, position)
            } else {
              returnParams = this.addPageContent(fnParams.pdf, fnParams.pageData, fnParams.pageHeight, fnParams.pageWhite, fnParams.totalImgHeight, (this.$refs['print3-23-b-' + i + '-' + l] as any)[0], preHeight, position)
            }
            preHeight = returnParams.preHeight
            position = returnParams.position
          }
        }
      }
    }
    return { preHeight, position }
  }
  private addPageContent(pdf: any, pageData: any, pageHeight: number, pageWhite: number, totalImgHeight: number, nowDom: any, preHeight: number, position: number) {
    let nowDomH: number = (595.28 / nowDom.offsetWidth) * nowDom.offsetHeight,
      mergeH: number = preHeight + nowDomH
    // pdf可见部分与到前元素截止高不等进入
    if (mergeH > pageHeight) {
      //尾部留白
      this.addWhiteImg(pdf, pageHeight - preHeight + pageWhite, preHeight)
      // 添加新页
      pdf.addPage()
      pdf.addImage(pageData, 'JPEG', 0, position, 595.28, totalImgHeight)
      preHeight = nowDomH
    } else {
      preHeight = mergeH
    }
    position -= nowDomH
    return { preHeight, position }
  }
  private addWhiteImg(pdf: any, imgH: number, position: number) {
    let cans: any = document.createElement('canvas'),
      ctx: any = cans.getContext('2d')
    cans.width = 595.28
    cans.height = imgH
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, 595.28, imgH)
    pdf.addImage(cans.toDataURL('image/jpeg', 1.0), 'JPEG', 0, position, 595.28, imgH)
  }
  private async getPdf() {
    let printPdf: any = this.$refs.printPdf
    if (!printPdf) {
      return
    }
    // 纵向单位pt格式a4的pdf
    let pdf = new jsPDF('p', 'pt', 'a4', true),
      canvasOut: any = document.createElement('canvas')
    canvasOut.width = printPdf.offsetWidth * 2
    canvasOut.height = printPdf.offsetHeight * 2
    // 设置pdf打开网页的标题
    pdf.setProperties({
      title: '项目情况'
    })
    let cnvasOpt: any = { scale: 2, useCORS: true, canvas: canvasOut, width: printPdf.offsetWidth, height: printPdf.offsetHeight }
    await html2canvas(printPdf, cnvasOpt).then(async (canvas: any) => {
      let context: any = canvas.getContext('2d')
      context.mozImageSmoothingEnabled = false
      context.webkitImageSmoothingEnabled = false
      context.msImageSmoothingEnabled = false
      context.imageSmoothingEnabled = false

      let totalWidth: number = printPdf.offsetWidth,
        totalHeight: number = printPdf.offsetHeight,
        // 页面留白
        pageWhite: number = (595.28 / totalWidth) * 20,
        // 一页pdf高度
        pageHeight: number = 841.89 - pageWhite,
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        totalImgHeight: number = (595.28 / totalWidth) * totalHeight,
        pageData: any = canvas.toDataURL('image/jpeg', 1.0)
      pdf.addImage(pageData, 'JPEG', 0, 0, 595.28, totalImgHeight)
      if (pageHeight < totalImgHeight) {
        let totalPrintNums: number = 2,
          // 上部分确认的高
          preHeight: number = 0,
          position: number = 0
        // 判断有无交易信息
        if (this.proDetail.status && [4, 6, 8].indexOf(this.proDetail.status) != -1 && this.proDetail.tradeInfo) {
          totalPrintNums += 1
        }
        // 循环移动图片插入实现分页
        for (let i = 1; i <= totalPrintNums; i++) {
          let nowDom: any = this.$refs['print' + i]
          switch (i) {
            case 1:
              preHeight = (595.28 / nowDom.offsetWidth) * nowDom.offsetHeight
              position = -preHeight
              let returnParams1: any
              for (let l = 1; l < 9; l++) {
                let forObj: any = this.$refs['print1-' + l]
                if (forObj) {
                  returnParams1 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, forObj, preHeight, position)
                  preHeight = returnParams1.preHeight
                  position = returnParams1.position
                }
                if (l == 7 && [1, 2].indexOf(this.proDetail.businessType) == -1 && this.proDetail.customFields && this.proDetail.customFields.length) {
                  let customFields: any = this.proDetail.customFields
                  for (let n = 0; n < customFields.length; n++) {
                    let forCus: any = (this.$refs['print1-custom' + n] as any)[0]
                    if (forCus) {
                      returnParams1 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, forCus, preHeight, position)
                      preHeight = returnParams1.preHeight
                      position = returnParams1.position
                    }
                  }
                }
              }
              let imgList: any = this.proDetail.imgList
              if (imgList && imgList.length) {
                for (let l = 0; l < imgList.length; l++) {
                  if (imgList[l].imgUrl) {
                    returnParams1 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, (this.$refs['print1-img' + l] as any)[0], preHeight, position)
                    preHeight = returnParams1.preHeight
                    position = returnParams1.position
                  }
                }
              }
              break
            case 2:
              let returnParams2: any = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, nowDom, preHeight, position)
              preHeight = returnParams2.preHeight
              position = returnParams2.position
              let proFiles: any = this.filterFiles(this.proDetail.files)
              if (proFiles) {
                for (let l = 0; l < proFiles.length; l++) {
                  returnParams2 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, (this.$refs['print2-' + l] as any)[0], preHeight, position)
                  preHeight = returnParams2.preHeight
                  position = returnParams2.position
                }
              }
              if (this.proDetail.otherFileList) {
                for (let l = 0; l < this.proDetail.otherFileList.length; l++) {
                  returnParams2 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, (this.$refs['print2-other' + l] as any)[0], preHeight, position)
                  preHeight = returnParams2.preHeight
                  position = returnParams2.position
                }
              }
              break
            case 3:
              let returnParams3: any = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, nowDom, preHeight, position)
              preHeight = returnParams3.preHeight
              position = returnParams3.position
              for (let l = 1; l < 24; l++) {
                let nowItem: any = this.$refs['print3-' + l]
                if (nowItem) {
                  returnParams3 = this.addPageContent(pdf, pageData, pageHeight, pageWhite, totalImgHeight, nowItem, preHeight, position)
                  preHeight = returnParams3.preHeight
                  position = returnParams3.position
                  let fnParams: any = { pdf, pageData, pageHeight, pageWhite, preHeight, position, totalImgHeight }
                  // 添加转账图片
                  if ([16, 19, 22].indexOf(l) > -1) {
                    let mapKey: any = {
                      16: 'paymentInfo',
                      19: 'promiseAmountInfo',
                      22: 'finalPaymentInfo'
                    }
                    returnParams3 = this.forPayWay(mapKey[l], fnParams)
                    preHeight = returnParams3.preHeight
                    position = returnParams3.position
                  } else if (l == 23) {
                    returnParams3 = this.forTradeInfoFiles(fnParams)
                    preHeight = returnParams3.preHeight
                    position = returnParams3.position
                  }
                }
              }
          }
        }
      }
      this.pdf = pdf
      if (this.watchExport) {
        this.exportFile()
      }
      if (this.watchPdf) {
        this.watchPdf = false
        window.open(this.pdf.output('bloburl') as any, '_blank')
        this.printLoading = false
      }
    })
  }

  created() {
    if (this.$route.params.listingApplyId) {
      this.listingApplyId = this.$route.params.listingApplyId
      sessionStorage.setItem('countrysideProjectDetail', this.listingApplyId)
    } else {
      this.listingApplyId = sessionStorage.getItem('countrysideProjectDetail')
    }
    this.getList()
  }

  updated() {
    if (this.$route.params.isExport == '1' && this.firstGetPdf) {
      this.firstGetPdf = false
      this.updateTimeout = setTimeout(() => {
        this.getPdf()
      }, 1000)
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    if (to.name != 'BiddersDetail') {
      sessionStorage.removeItem('countrysideProjectDetail')
    }
    this.updateTimeout && clearTimeout(this.updateTimeout)
    next()
  }
  get btnShow() {
    let returnFlag: boolean = false
    if (this.proDetail.status && this.proDetail.businessType == '7' && this.proDetail.tranferType == '1') {
      if ([5, 6, 11, 12, 13].indexOf(this.proDetail.status) > -1) {
        this.activeName == '8' && (returnFlag = true)
      } else {
        this.activeName == '7' && (returnFlag = true)
      }
    } else if (this.proDetail.authenticationBook) {
      this.activeName == '11' && (returnFlag = true)
    } else if (this.proDetail.status && [4, 6, 8].indexOf(this.proDetail.status) != -1 && this.proDetail.tradeInfo) {
      this.activeName == '10' && (returnFlag = true)
    } else if (this.proDetail.auctionInfo && this.proDetail.tranferType == '1') {
      this.activeName == '9' && (returnFlag = true)
    } else {
      this.activeName == '6' && (returnFlag = true)
    }
    return returnFlag
  }
  private updateFilesFn() {
    !this.isUpFile && (this.isUpFile = true)
    this.pdf && (this.pdf = null)
  }
  private seeFileGet(filePath: any) {
    let data: any = {
      filePath: filePath,
      timestamp: Date.parse(new Date() as any),
      token: localStore.get('zctToken')
    }
    data = JSON.stringify(data)
    let url = secret.default.encrypt(data)
    url = encodeURI(url)
    url = url.replace(/\+/g, '%2B')
    return `/zct/api/file/changeFile?ec=${url}`
    // return `http://ruraladmin.gov.vpclub.cn/zct/api/file/changeFile?ec=${url}`
  }

  // 打印项目信息
  private printInfo() {
    this.printLoading = true
    if (this.pdf) {
      window.open(this.pdf.output('bloburl') as any, '_blank')
      this.printLoading = false
    } else {
      if (this.isUpFile) {
        this.updateTimeout && clearTimeout(this.updateTimeout)
        this.updateTimeout = setTimeout(() => {
          this.getPdf()
        }, 500)
      }
      this.watchPdf = true
    }
  }
  // 导出项目信息
  private exportInfo() {
    this.exportLoading = true
    if (this.pdf) {
      this.exportFile()
    } else {
      this.watchExport = true
    }
  }
  private exportFile() {
    let formData = new window.FormData()
    formData.append('file', this.pdf.output('blob'), this.proDetail.bidInfo.name.value + '.pdf')
    formData.append('listingApplyId', this.listingApplyId)
    uploadPDF(formData)
      .then((res) => {
        if (res.data && (res.data.type == 'application/octet-stream' || res.data.type == 'application/octet-stream;charset=utf-8')) {
          const content = res.data
          const blob = new Blob([content], { type: 'application/zip' })
          let fileStr: any = res.headers['content-disposition'].split('filename=')[1]
          const fileName = decodeURI(fileStr)
          if (navigator && typeof navigator.msSaveBlob === 'function') {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          } else {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = window.URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            window.URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }
        } else {
          this.$message.error('下载失败')
        }
      })
      .finally(() => {
        this.exportLoading = false
      })
  }
  // 设置中标人成功方法
  private setPersonSuccess() {
    this.isDelisting = false
  }
  // 返回页面
  private returnPaper() {
    this.$router.push({ name: 'CountrysideProjectList' })
  }
  //获取详情
  private getList() {
    getCountrysideProject({ listingApplyId: this.listingApplyId }).then((res) => {
      if (res.data.data) {
        let data = res.data.data
        // needFiles = data.files,
        // otherFileList = data.otherFileList
        // if (needFiles) {
        //   for (let i = 0; i < needFiles.length; i++) {
        //     this.fileDisabled.push(false)
        //   }
        // }
        // if (otherFileList) {
        //   for (let i = 0; i < otherFileList.length; i++) {
        //     this.otherFileDisabled.push(false)
        //   }
        // }
        if (data.tranferType == '1' && data.auctionInfo && data.auctionInfo.auctionPersonList && data.auctionInfo.auctionPersonList.length) {
          let auctionPersonList = data.auctionInfo.auctionPersonList,
            isSetPerson = true
          if (data.businessType == '7') {
            for (let i = 0; i < auctionPersonList.length; i++) {
              if (auctionPersonList[i].qualificationStatus == '1') {
                isSetPerson = false
                break
              }
            }
          } else {
            for (let i = 0; i < auctionPersonList.length; i++) {
              if (auctionPersonList[i].status == '1') {
                isSetPerson = false
                break
              }
            }
          }
          if (isSetPerson) {
            if (data.businessType == '7' && data.status != '3') {
              this.isSetPerson = false
            } else {
              this.isSetPerson = true
            }
          } else {
            this.isDelisting = false
          }
        }
        if (data.tradeInfo && !data.tradeInfo.contractFiles) {
          data.tradeInfo.contractFiles = []
        }
        if (data.tradeInfo && data.tradeInfo.delistingType == '4') {
          this.isSetResult = true
        }
        if (data.businessType == '7' && data.tranferType == '1' && [1, 2, 7, 9].indexOf(data.status) == -1) {
          if (data.status != 3) {
            let auctionInfo: any = data.auctionInfo
            if (auctionInfo && auctionInfo.winnerPerson) {
              let winnerPerson: any = auctionInfo.winnerPerson
              this.competeBuyInfo.delistingBaseId = winnerPerson.name ? winnerPerson.name : ''
              this.competeBuyInfo.transactionPrice = !winnerPerson.amount && winnerPerson.amount !== 0 ? '' : winnerPerson.amount
              this.competeBuyInfo.confirmFile = auctionInfo.confirmFile ? auctionInfo.confirmFile : []
            }
          }
        }
        if (data.businessType == '7' && data.tranferType == '1' && [12, 13].indexOf(data.status) > -1) {
          this.relationTransactionInfo.isComplete = data.tradeInfo && data.tradeInfo.isComplete ? true : false
        }
        this.proDetail = data
      }
    })
  }
  // 设置成交结果
  private setResult() {
    this.qrCodeType = 'setResult'
    this.markShow = true
  }
  // 主动摘牌
  private activeDelisting() {
    this.qrCodeType = 'delisting'
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let loadingInstance: any = Loading.service({ target: document.body, text: this.loadingTxt })
    //       activeDelisting({ listingApplyId: this.listingApplyId, callbackId: res.data.data.callbackId })
    //         .then((res) => {
    //           this.$message.success('提交成功')
    //           setTimeout(() => {
    //             this.returnPaper()
    //           }, 300)
    //         })
    //         .finally(() => {
    //           this.$nextTick(() => {
    //             // 以服务的方式调用的 Loading 需要异步关闭
    //             loadingInstance.close()
    //           })
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
  }
  //二维码认证成功
  private notify(v: any, callback: any) {
    if (v) {
      this.qrDiaShow = false
      let loadingInstance: any = Loading.service({ target: document.body, text: this.loadingTxt })
      if (this.qrCodeType == 'delisting') {
        activeDelisting({ listingApplyId: this.listingApplyId, callback })
          .then((res) => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.returnPaper()
            }, 300)
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      } else if (this.qrCodeType == 'setResult') {
        settingResult({
          listingApplyId: this.listingApplyId,
          result: this.isSuccess == '1' ? true : false,
          callback
        })
          .then((res) => {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.returnPaper()
            }, 300)
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      } else {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      }
    }
  }
  // 关闭弹窗
  private closeFn() {
    this.markShow = false
  }
  // 弹窗确定方法
  private confirm() {
    this.markShow = false
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let loadingInstance: any = Loading.service({ target: document.body, text: this.loadingTxt })
    //       settingResult({
    //         listingApplyId: this.listingApplyId,
    //         result: this.isSuccess == '1' ? true : false,
    //         callback: res.data.data.callbackId
    //       })
    //         .then((res) => {
    //           this.$message.success('提交成功')
    //           setTimeout(() => {
    //             this.returnPaper()
    //           }, 300)
    //         })
    //         .finally(() => {
    //           this.$nextTick(() => {
    //             // 以服务的方式调用的 Loading 需要异步关闭
    //             loadingInstance.close()
    //           })
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
  }
  // 增减挂钩提交
  private sumbitFn() {
    let loadingInstance = Loading.service({ target: document.body })
    // 设置中标
    if (this.proDetail.status == 3||this.proDetail.status == 10) {
      if (!this.competeBuyInfo.delistingBaseId) {
        this.$nextTick(() => {
          this.$message.error('请选择中标人')
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      } else if (!this.competeBuyInfo.transactionPrice) {
        this.$nextTick(() => {
          this.$message.error('请输入成交价')
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      } else if (!this.competeBuyInfo.confirmFile.length) {
        this.$nextTick(() => {
          this.$message.error('请上传成交确认书')
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      }
      setBidder({ ...this.competeBuyInfo })
        .then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.returnPaper()
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
    // 设置交易结果
    else if ([5, 10, 11].indexOf(this.proDetail.status) > -1) {
      let params: any = {
        listingApplyId: this.listingApplyId,
        isComplete: this.relationTransactionInfo.isComplete
      }
      arSetResult(params)
        .then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.returnPaper()
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
}
