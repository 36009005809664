
import TinymceEditor from '@/components/TinymceEditor/index.vue'
import ProjectInfo from '@/views/countryside-property-transaction/components/project-info.vue'
import { editBidNotice } from '@/api/countrysidePropertyTransaction'
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator'

const VueUeditorWrap = require('vue-ueditor-wrap')
@Component({
  components: {
    ProjectInfo,
    VueUeditorWrap,
    TinymceEditor
  }
})
export default class projectNotice extends Vue{
  @Prop() info: any
  @Prop() listingApplyId: string
  private projectShow: boolean = false
  private projectName:string = ''
  private projectId: string = ''
  private isDisabled:boolean = true
  private myConfig: any = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 100,
    // 初始容器宽度
    initialFrameWidth: '100%',
    pasteplain: true,
    filterTxtRules: function () {
      function delDom(node: any) {
        // 如果是图片、超链接 直接删除
        node.parentNode.removeChild(node, node.innerText())
      }
      return {
        img: delDom
      }
    },
    enableAutoSave: false,
    iframeCssUrl: './static/tableStyle.css',
    catchRemoteImageEnable: false, //设置是否抓取远程图片
    elementPathEnabled: false, //是否启用元素路径
    wordCount: false, //是否开启字数统计
    serverUrl: `${process.env.VUE_APP_BASE_URL}file/upload`,
    toolbars: [['bold', 'indent', 'italic', 'underline', 'strikethrough', 'fontsize', 'forecolor', 'backcolor', 'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols']]
  }
  mounted(){
    this.init()
  }
  @Emit('projectId')
  changeProjectId() {
    return this.projectId
  }
  @Watch('info', { immediate: true })
  private watchInfo(newValue: any) {
    if(newValue.bidNotice){
      if(newValue.projectId){
        this.projectId = this.info.projectId
        this.projectName = this.info.projectName
      }
    }

  }
  private handleEditor(){
    this.isDisabled = false
  }
  private goBack(){
    this.$router.push({ name: 'CountrysideProjectList' })
  }
  private init(){
    this.projectId = this.info.projectId
    this.projectName = this.info.projectName
  }
  private projectEvt() {
    this.projectShow = true
  }
  // 获取项目id
  private getProjectId(projectId: string) {
    this.projectId = projectId.trim()
    this.projectShow = false
    this.changeProjectId()
  }
  // 获取项目标题
  private getProjectName(projectName: string) {
    this.projectName = projectName.trim()
  }
  private doSave(){
    let params = {
      listingApplyId: this.listingApplyId,
      isShowProject: this.info.isShowProject,
      bidNotice: this.info.bidNotice,
      projectId: ''
    }
    if(this.info.isShowProject==1){
      params.projectId = ''
    }else {
      params.projectId = this.projectId
      this.info.bidNotice.bidNoticDetail.forEach(item=>{
        item.value = ''
      })
    }
    editBidNotice(params).then(res=>{
      if(res.data.code===1000){
        this.$message.success('保存成功')
        this.isDisabled = true
      }
    })
  }
}

