var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "licensing-agreement",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "area-item li-item" }, [
        _c("span", { staticClass: "left-li" }, [_vm._v("区域：")]),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.isSuperAdmin != "1",
                  placeholder: "请选择",
                  size: "small"
                },
                on: { change: _vm.selecttedArea },
                model: {
                  value: _vm.formInfo.areaConfigId,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                  },
                  expression: "formInfo.areaConfigId"
                }
              },
              _vm._l(_vm.areaOptions, function(item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c("el-input", {
              staticClass: "name-input",
              attrs: { placeholder: "请输入", size: "small" },
              model: {
                value: _vm.formInfo.agreementName,
                callback: function($$v) {
                  _vm.$set(_vm.formInfo, "agreementName", $$v)
                },
                expression: "formInfo.agreementName"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "right-li flex-li" },
          [
            _c("el-switch", {
              on: { change: _vm.handleSwitch },
              model: {
                value: _vm.isShow,
                callback: function($$v) {
                  _vm.isShow = $$v
                },
                expression: "isShow"
              }
            }),
            _vm._v(" " + _vm._s(_vm.formInfo.isShow == "1" ? "是" : "否") + " ")
          ],
          1
        )
      ]),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c("quill-editor", {
              staticClass: "contnt-editor",
              attrs: { options: _vm.editorOption },
              model: {
                value: _vm.formInfo.agreementDetail,
                callback: function($$v) {
                  _vm.$set(_vm.formInfo, "agreementDetail", $$v)
                },
                expression: "formInfo.agreementDetail"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "control-box" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.isSuperAdmin != "1" && !_vm.formInfo.areaConfigId
              },
              on: { click: _vm.saveFn }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("协议名称：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("用户端展示：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("协议内容：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }