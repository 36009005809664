var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm.redTitle &&
        (_vm.detailtype == 1 ||
          _vm.detailtype == 2 ||
          _vm.detailtype == 5 ||
          _vm.detailtype == 6)
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.flag != "guarantors" && _vm.info
        ? _c("div", [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "item item-open" },
                [
                  _vm.openShow && _vm.info.personPublicInfo
                    ? _c(
                        "div",
                        { staticClass: "open-box" },
                        [
                          _c("span", [_vm._v("是否公开")]),
                          _c("el-switch", {
                            on: {
                              change: function($event) {
                                return _vm.openChange(
                                  "",
                                  _vm.info.personPublicInfo,
                                  _vm.info.dfRelId
                                )
                              }
                            },
                            model: {
                              value: _vm.info.personPublicInfo.status,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.personPublicInfo,
                                  "status",
                                  $$v
                                )
                              },
                              expression: "info.personPublicInfo.status"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("span", [_vm._v("企业名称")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.enterpriseName
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.enterpriseName,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "enterpriseName", $$v)
                          },
                          expression: "itemStatus.enterpriseName"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.enterpriseName
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.enterpriseName))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("统一社会信用代码")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.creditCode
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.creditCode,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "creditCode", $$v)
                          },
                          expression: "itemStatus.creditCode"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.creditCode
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.creditCode))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("注册地址")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.registAddress
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.registAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "registAddress", $$v)
                          },
                          expression: "itemStatus.registAddress"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.registAddress
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.registAddress))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("法人代表")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.legalPerson
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.legalPerson,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "legalPerson", $$v)
                          },
                          expression: "itemStatus.legalPerson"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.legalPerson
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.legalPerson))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("联系电话")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.contactPhone
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.contactPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "contactPhone", $$v)
                          },
                          expression: "itemStatus.contactPhone"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.contactPhone
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.contactPhone))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("联系地址")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.contactAddress
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.contactAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "contactAddress", $$v)
                          },
                          expression: "itemStatus.contactAddress"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.contactAddress
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.contactAddress))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("联系人")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.contactMember
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.contactMember,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "contactMember", $$v)
                          },
                          expression: "itemStatus.contactMember"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.contactMember
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.contactMember))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("行业")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.configKeyIndustry
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.configKeyIndustry,
                          callback: function($$v) {
                            _vm.$set(_vm.itemStatus, "configKeyIndustry", $$v)
                          },
                          expression: "itemStatus.configKeyIndustry"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.configKeyIndustry
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.configKeyIndustry))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("span", [_vm._v("企业所属行业")]),
                  _vm.detailtype != 2 &&
                  _vm.detailtype != 6 &&
                  _vm.checkboxShow &&
                  _vm.itemShow.configKeyIndustryType
                    ? _c("el-checkbox", {
                        model: {
                          value: _vm.itemStatus.configKeyIndustryType,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.itemStatus,
                              "configKeyIndustryType",
                              $$v
                            )
                          },
                          expression: "itemStatus.configKeyIndustryType"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      class: {
                        "red-font":
                          _vm.isShowRed &&
                          ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                            _vm.detailtype == 0) &&
                          _vm.itemShow.configKeyIndustryType
                      }
                    },
                    [_vm._v(_vm._s(_vm.info.configKeyIndustryType))]
                  )
                ],
                1
              ),
              _vm.flag == "issuer"
                ? _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("项目介绍")]),
                      _vm.detailtype != 2 &&
                      _vm.detailtype != 6 &&
                      _vm.checkboxShow &&
                      _vm.itemShow.introduction
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus.introduction,
                              callback: function($$v) {
                                _vm.$set(_vm.itemStatus, "introduction", $$v)
                              },
                              expression: "itemStatus.introduction"
                            }
                          })
                        : _vm._e(),
                      _vm.info.introduction
                        ? _c("div", {
                            class: {
                              "red-board":
                                ((_vm.detailtype == 1 &&
                                  _vm.isSecond == "true") ||
                                  _vm.detailtype == 0) &&
                                _vm.itemShow.introduction
                            },
                            staticStyle: {
                              border: "1px solid #e3e3e3",
                              flex: "auto",
                              padding: "6px"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.info.introduction)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fileL1[0]
                ? _c("div", { staticClass: "item item-open" }, [
                    _vm.openShow && _vm.info.requirePublicInfo
                      ? _c(
                          "div",
                          { staticClass: "open-box" },
                          [
                            _c("span", [_vm._v("是否公开")]),
                            _c("el-switch", {
                              on: {
                                change: function($event) {
                                  return _vm.openChange(
                                    "所需资料",
                                    _vm.info.requirePublicInfo,
                                    _vm.info.dfRelId
                                  )
                                }
                              },
                              model: {
                                value: _vm.info.requirePublicInfo.status,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.requirePublicInfo,
                                    "status",
                                    $$v
                                  )
                                },
                                expression: "info.requirePublicInfo.status"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.flag != "issuer" ? _vm.title : "备案人") +
                            "所需资料"
                        )
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "right fileslist" },
                      _vm._l(_vm.info.files, function(items, index) {
                        return _c(
                          "div",
                          { key: items.fileId + _vm.flag },
                          [
                            items.process != 3 &&
                            _vm.detailtype != 2 &&
                            _vm.detailtype != 6 &&
                            _vm.checkboxShow &&
                            (_vm.detailtype == 1 && _vm.isSecond == "true"
                              ? !items.fileStatus
                              : true)
                              ? _c("el-checkbox", {
                                  model: {
                                    value: _vm.infoFiles[index].fileStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.infoFiles[index],
                                        "fileStatus",
                                        $$v
                                      )
                                    },
                                    expression: "infoFiles[index].fileStatus"
                                  }
                                })
                              : _vm._e(),
                            items.process != 3
                              ? _c(
                                  "el-link",
                                  {
                                    class: {
                                      "red-font":
                                        _vm.isShowRed &&
                                        ((_vm.detailtype == 1 &&
                                          _vm.isSecond == "true") ||
                                          _vm.detailtype == 0) &&
                                        !items.fileStatus
                                    },
                                    attrs: {
                                      href: items.fileAddr,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(items.fileName) + " ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.fileL2[0] && _vm.detailtype != 1 && _vm.detailtype != 5
                ? _c("div", { staticClass: "item item-open" }, [
                    _vm.openShow && _vm.info.furtherPublicInfo
                      ? _c(
                          "div",
                          { staticClass: "open-box" },
                          [
                            _c("span", [_vm._v("是否公开")]),
                            _c("el-switch", {
                              on: {
                                change: function($event) {
                                  return _vm.openChange(
                                    "补充数据及合同",
                                    _vm.info.furtherPublicInfo,
                                    _vm.info.dfRelId
                                  )
                                }
                              },
                              model: {
                                value: _vm.info.furtherPublicInfo.status,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.furtherPublicInfo,
                                    "status",
                                    $$v
                                  )
                                },
                                expression: "info.furtherPublicInfo.status"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: {
                          "yellow-title":
                            _vm.detailtype == 2 || _vm.detailtype == 6
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            (_vm.flag != "issuer" ? _vm.title : "备案人") +
                              "补充数据及合同"
                          )
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "right fileslist" },
                      _vm._l(_vm.info.files, function(items, index) {
                        return _c(
                          "div",
                          { key: items.fileId + _vm.flag },
                          [
                            items.process == 3 &&
                            _vm.checkboxShow &&
                            (_vm.detailtype == 2 && _vm.isSecond == "true"
                              ? !items.fileStatus
                              : true)
                              ? _c("el-checkbox", {
                                  model: {
                                    value: _vm.infoFiles[index].fileStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.infoFiles[index],
                                        "fileStatus",
                                        $$v
                                      )
                                    },
                                    expression: "infoFiles[index].fileStatus"
                                  }
                                })
                              : _vm._e(),
                            items.process == 3
                              ? _c(
                                  "el-link",
                                  {
                                    class: {
                                      "red-font":
                                        _vm.isShowRed &&
                                        ((_vm.detailtype == 2 &&
                                          _vm.isSecond == "true") ||
                                          _vm.detailtype == 0) &&
                                        !items.fileStatus
                                    },
                                    attrs: {
                                      href: items.fileAddr,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(items.fileName) + " ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm.flag == "guarantors" && _vm.info && !_vm.isFirst
        ? _c(
            "div",
            _vm._l(_vm.info, function(item, index) {
              return _c(
                "div",
                { key: index + _vm.flag, staticClass: "content hr" },
                [
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item item-open" },
                        [
                          _vm.openShow && item.personPublicInfo
                            ? _c(
                                "div",
                                { staticClass: "open-box" },
                                [
                                  _c("span", [_vm._v("是否公开")]),
                                  _c("el-switch", {
                                    on: {
                                      change: function($event) {
                                        return _vm.openChange(
                                          "",
                                          item.personPublicInfo,
                                          item.dfRelId
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.personPublicInfo.status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item.personPublicInfo,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "item.personPublicInfo.status"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("span", [_vm._v("企业名称")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].enterpriseName
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].enterpriseName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "enterpriseName",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].enterpriseName"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].enterpriseName
                              }
                            },
                            [_vm._v(_vm._s(item.enterpriseName))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("统一社会信用代码")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].creditCode
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].creditCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "creditCode",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].creditCode"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].creditCode
                              }
                            },
                            [_vm._v(_vm._s(item.creditCode))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 2
                    ? _c(
                        "div",
                        { staticClass: "item item-open" },
                        [
                          _vm.openShow && item.personPublicInfo
                            ? _c(
                                "div",
                                { staticClass: "open-box" },
                                [
                                  _c("span", [_vm._v("是否公开")]),
                                  _c("el-switch", {
                                    on: {
                                      change: function($event) {
                                        return _vm.openChange(
                                          "",
                                          item.personPublicInfo,
                                          item.dfRelId
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.personPublicInfo.status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item.personPublicInfo,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "item.personPublicInfo.status"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("span", [_vm._v("名字")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].enterpriseName
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].enterpriseName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "enterpriseName",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].enterpriseName"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].enterpriseName
                              }
                            },
                            [_vm._v(_vm._s(item.enterpriseName))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 2
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("身份证号")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].identityCode
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].identityCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "identityCode",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].identityCode"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].identityCode
                              }
                            },
                            [_vm._v(_vm._s(item.identityCode))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("注册地址")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].registAddress
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].registAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "registAddress",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].registAddress"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].registAddress
                              }
                            },
                            [_vm._v(_vm._s(item.registAddress))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("法人代表")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].legalPerson
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].legalPerson,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "legalPerson",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].legalPerson"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].legalPerson
                              }
                            },
                            [_vm._v(_vm._s(item.legalPerson))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("联系电话")]),
                      _vm.detailtype != 2 &&
                      _vm.detailtype != 6 &&
                      _vm.checkboxShow &&
                      _vm.itemShow[index] &&
                      _vm.itemShow[index].contactPhone
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[index].contactPhone,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[index],
                                  "contactPhone",
                                  $$v
                                )
                              },
                              expression: "itemStatus[index].contactPhone"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          class: {
                            "red-font":
                              _vm.isShowRed &&
                              ((_vm.detailtype == 1 &&
                                _vm.isSecond == "true") ||
                                _vm.detailtype == 0) &&
                              _vm.itemShow[index] &&
                              _vm.itemShow[index].contactPhone
                          }
                        },
                        [_vm._v(_vm._s(item.contactPhone))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("联系地址")]),
                      _vm.detailtype != 2 &&
                      _vm.detailtype != 6 &&
                      _vm.checkboxShow &&
                      _vm.itemShow[index] &&
                      _vm.itemShow[index].contactAddress
                        ? _c("el-checkbox", {
                            model: {
                              value: _vm.itemStatus[index].contactAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.itemStatus[index],
                                  "contactAddress",
                                  $$v
                                )
                              },
                              expression: "itemStatus[index].contactAddress"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          class: {
                            "red-font":
                              _vm.isShowRed &&
                              ((_vm.detailtype == 1 &&
                                _vm.isSecond == "true") ||
                                _vm.detailtype == 0) &&
                              _vm.itemShow[index] &&
                              _vm.itemShow[index].contactAddress
                          }
                        },
                        [_vm._v(_vm._s(item.contactAddress))]
                      )
                    ],
                    1
                  ),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("联系人")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].contactMember
                            ? _c("el-checkbox", {
                                model: {
                                  value: _vm.itemStatus[index].contactMember,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "contactMember",
                                      $$v
                                    )
                                  },
                                  expression: "itemStatus[index].contactMember"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].contactMember
                              }
                            },
                            [_vm._v(_vm._s(item.contactMember))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("行业")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].configKeyIndustry
                            ? _c("el-checkbox", {
                                model: {
                                  value:
                                    _vm.itemStatus[index].configKeyIndustry,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "configKeyIndustry",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "itemStatus[index].configKeyIndustry"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].configKeyIndustry
                              }
                            },
                            [_vm._v(_vm._s(item.configKeyIndustry))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  item.userType == 1
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [_vm._v("企业所属行业")]),
                          _vm.detailtype != 2 &&
                          _vm.detailtype != 6 &&
                          _vm.checkboxShow &&
                          _vm.itemShow[index] &&
                          _vm.itemShow[index].configKeyIndustryType
                            ? _c("el-checkbox", {
                                model: {
                                  value:
                                    _vm.itemStatus[index].configKeyIndustryType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.itemStatus[index],
                                      "configKeyIndustryType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "itemStatus[index].configKeyIndustryType"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              class: {
                                "red-font":
                                  _vm.isShowRed &&
                                  ((_vm.detailtype == 1 &&
                                    _vm.isSecond == "true") ||
                                    _vm.detailtype == 0) &&
                                  _vm.itemShow[index] &&
                                  _vm.itemShow[index].configKeyIndustryType
                              }
                            },
                            [_vm._v(_vm._s(item.configKeyIndustryType))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.fileL1[index]
                    ? _c("div", { staticClass: "item item-open" }, [
                        _vm.openShow && item.requirePublicInfo
                          ? _c(
                              "div",
                              { staticClass: "open-box" },
                              [
                                _c("span", [_vm._v("是否公开")]),
                                _c("el-switch", {
                                  on: {
                                    change: function($event) {
                                      return _vm.openChange(
                                        "所需资料",
                                        item.requirePublicInfo,
                                        item.dfRelId
                                      )
                                    }
                                  },
                                  model: {
                                    value: item.requirePublicInfo.status,
                                    callback: function($$v) {
                                      _vm.$set(
                                        item.requirePublicInfo,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "item.requirePublicInfo.status"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("span", [_vm._v("担保人所需资料")]),
                        _c(
                          "div",
                          { staticClass: "right fileslist" },
                          _vm._l(item.files, function(items, indexs) {
                            return _c(
                              "div",
                              { key: items.fileId + _vm.flag },
                              [
                                items.process != 3 &&
                                _vm.detailtype != 2 &&
                                _vm.detailtype != 6 &&
                                _vm.checkboxShow &&
                                (_vm.detailtype == 1 && _vm.isSecond == "true"
                                  ? !items.fileStatus
                                  : true)
                                  ? _c("el-checkbox", {
                                      model: {
                                        value:
                                          _vm.infoFiles[index][indexs]
                                            .fileStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.infoFiles[index][indexs],
                                            "fileStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "infoFiles[index][indexs].fileStatus"
                                      }
                                    })
                                  : _vm._e(),
                                items.process != 3
                                  ? _c(
                                      "el-link",
                                      {
                                        class: {
                                          "red-font":
                                            _vm.isShowRed &&
                                            ((_vm.detailtype == 1 &&
                                              _vm.isSecond == "true") ||
                                              _vm.detailtype == 0) &&
                                            !items.fileStatus
                                        },
                                        attrs: {
                                          href: items.fileAddr,
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(items.fileName) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm.fileL2[index] &&
                  _vm.detailtype != 1 &&
                  _vm.detailtype != 5
                    ? _c("div", { staticClass: "item item-open" }, [
                        _vm.openShow && item.furtherPublicInfo
                          ? _c(
                              "div",
                              { staticClass: "open-box" },
                              [
                                _c("span", [_vm._v("是否公开")]),
                                _c("el-switch", {
                                  on: {
                                    change: function($event) {
                                      return _vm.openChange(
                                        "补充数据及合同",
                                        item.furtherPublicInfo,
                                        item.dfRelId
                                      )
                                    }
                                  },
                                  model: {
                                    value: item.furtherPublicInfo.status,
                                    callback: function($$v) {
                                      _vm.$set(
                                        item.furtherPublicInfo,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "item.furtherPublicInfo.status"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            class: {
                              "yellow-title":
                                _vm.detailtype == 2 || _vm.detailtype == 6
                            }
                          },
                          [_vm._v("担保人补充数据及合同")]
                        ),
                        _c(
                          "div",
                          { staticClass: "right fileslist" },
                          _vm._l(item.files, function(items, indexs) {
                            return _c(
                              "div",
                              { key: items.fileId + _vm.flag },
                              [
                                items.process == 3 &&
                                _vm.checkboxShow &&
                                (_vm.detailtype == 2 && _vm.isSecond == "true"
                                  ? !items.fileStatus
                                  : true)
                                  ? _c("el-checkbox", {
                                      model: {
                                        value:
                                          _vm.infoFiles[index][indexs]
                                            .fileStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.infoFiles[index][indexs],
                                            "fileStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "infoFiles[index][indexs].fileStatus"
                                      }
                                    })
                                  : _vm._e(),
                                items.process == 3
                                  ? _c(
                                      "el-link",
                                      {
                                        class: {
                                          "red-font":
                                            _vm.isShowRed &&
                                            ((_vm.detailtype == 2 &&
                                              _vm.isSecond == "true") ||
                                              _vm.detailtype == 0) &&
                                            !items.fileStatus
                                        },
                                        attrs: {
                                          href: items.fileAddr,
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(items.fileName) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }