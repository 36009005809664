
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mortgageApprovalStatus } from '@/api/loans'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class DialogMortgage extends Vue {
  // @Prop({ default: '', type: String, required: true }) private ids!: string
  // @Prop({ default: '', type: String, required: true }) private textarea?: string
  @Prop({ required: true }) option!: any
  @Watch('option', { deep: true, immediate: true })
  private watchOptions(newValue: any) {
    if (newValue) this.optionData = newValue
  }
  private loadingTxt: string = loadingText
  private repaymentOptions: any = [
    { label: '等额本金', value: '1' },
    { label: '等额本息', value: '2' },
    { label: '按月还息到期还本', value: '3' },
    { label: '到期一次还本付息', value: '4' }
  ]
  private rules: any = {
    approvalAmount: { required: true, message: '请输入', trigger: 'blur' },
    deadline: { required: true, message: '请输入', trigger: 'blur' },
    interestRate: { required: true, message: '请输入', trigger: 'blur' },
    repayment: { required: true, message: '请选择', trigger: 'change' },
    issuingMethod: { required: true, message: '请选择', trigger: 'change' },
    address: { required: true, message: '请输入办理地址', trigger: 'blur' }
  }
  private formInfo: any = {
    approvalAmount: '',
    deadline: '',
    interestRate: '',
    repayment: '',
    issuingMethod: '1',
    address: ''
  }
  private optionData: any = {}
  // ---------------------------------------data------end------------------------------------------------------
  private cancelDialog() {
    this.$emit('close')
  }
  private changeInput(value: any, name: string) {
    this.formInfo[name] = value.replace(/[^\d\.]/g, '')
  }
  // 提交
  private handleDialog() {
    for (let key in this.formInfo) {
      if (key != 'address' || (this.formInfo.issuingMethod == '2' && key == 'address')) {
        if (this.formInfo[key] == '' || this.formInfo[key] === null) {
          this.$message.error('有选项没有输入或选择！！')
          return
        }
      }
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let datas: any = {
      id: this.option.id,
      demandId: this.option.demandId,
      ...this.formInfo,
      status: 4,
      approvalComments: this.option.textArea
    }
    mortgageApprovalStatus(datas)
      .then((res) => {
        setTimeout(() => {
          this.$router.push({ name: 'MortgageApproval' })
        }, 300)
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
