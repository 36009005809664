
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class ApplicationMaterials extends Vue {
  @Prop() info: any
  @Prop({
    default: () => {
      return []
    }
  })
  checkboxValue?: any
  @Prop({ default: null }) checkboxValueShow?: any
  private getFileSuffix(url: any, length: number, ind: number) {
    let returnArr: any = url.split('/')
    returnArr = returnArr[returnArr.length - 1].split('.')
    return returnArr[1] ? (length > 1 ? ind + 1 : '') + '.' + returnArr[1] : ''
  }
}
