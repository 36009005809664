
import { Component, Vue } from 'vue-property-decorator'
import aboutInfo from '../components/aboutInfo.vue'
import settlementInfo from '../components/settlementInfo.vue'
import applicationMaterials from '../components/applicationMaterials.vue'
import approvalInfo from '../components/approvalInfo.vue'
import { getSettlementApprovalDetails, sumbitSettlementApproval } from '@/api/settlementBusiness'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    aboutInfo,
    settlementInfo,
    applicationMaterials,
    approvalInfo
  }
})
export default class SettlementApprovalDetails extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private nowId: any = '' // 当前审批id
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {} // 展示数据
  // 字段勾选框展示
  private checkboxValueShow: any = {
    enterpriseName: false,
    creditCode: false,
    legalName: false,
    contactAddress: false,
    contactMember: false,
    contactPhone: false,
    relativeName: false,
    relativePhone: false,
    productName: false,
    settlementFile: false,
    serviceAmountType: false,
    amount: false,
    personNumber: false,
    serviceAmount: false
  }
  private fileCheckboxValueShow: any = []
  private checkboxValue: any = {
    // 字段勾选项状态
    selectMap: {
      enterpriseName: false,
      creditCode: false,
      legalName: false,
      contactAddress: false,
      contactMember: false,
      contactPhone: false,
      relativeName: false,
      relativePhone: false,
      productName: false,
      settlementFile: false,
      serviceAmountType: false,
      amount: false,
      personNumber: false,
      serviceAmount: false
    },
    files: []
  }
  created() {
    // 获取审批id
    if (this.$route.params.approvalId) {
      this.nowId = this.$route.params.approvalId
      sessionStorage.setItem('settlementApprovalDetails', this.$route.params.approvalId)
    } else {
      this.nowId = sessionStorage.getItem('settlementApprovalDetails')
    }
    getSettlementApprovalDetails({ approvalId: this.nowId })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          // 初始化文件勾选项
          if (datas.files && datas.files.length) {
            for (let i = 0; i < datas.files.length; i++) {
              this.fileCheckboxValueShow.push(datas.files[i].notPass ? true : false)
              this.checkboxValue.files.push(false)
            }
          }
          // 初始化字段勾选项
          if (datas.fieldList && datas.fieldList.length) {
            for (let i = 0; i < datas.fieldList.length; i++) {
              this.checkboxValueShow[datas.fieldList[i]] = true
            }
          }
          let opinion: string = '' // 审批意见
          // 获取上一次审批意见
          if (datas.approvalPersonInfos && datas.approvalPersonInfos.length) {
            let indexSign: any = -1
            for (let i = 0; i < datas.approvalPersonInfos.length; i++) {
              if (datas.approvalPersonInfos[i].status === 0) {
                indexSign = i - 1
                break
              }
            }
            if (indexSign > -1) {
              let remark: any = datas.approvalPersonInfos[indexSign].remark
              opinion = remark ? remark : ''
            }
          }
          // 配置审批信息
          datas.approvalInfo = {
            approvalPersonInfos: datas.approvalPersonInfos,
            opinion: opinion
          }
          this.proDetail = datas
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 清除id缓存
    sessionStorage.removeItem('settlementApprovalDetails')
    next()
  }
  private approvalEvt(type: any) {
    this.loading = true
    let params: any = {
      approvalId: this.nowId,
      isAgree: type,
      opinion: this.proDetail.approvalInfo.opinion
    }
    if (type == 2) {
      if (!params.opinion) {
        this.$message.error('请填写审批意见')
        this.loading = false
        return
      }
      let checkboxValue: any = this.checkboxValue,
        hasCheck: boolean = false
      params.files = this.proDetail.files
      params.selectMap = {}
      if (checkboxValue.files.length) {
        for (let i = 0; i < checkboxValue.files.length; i++) {
          !hasCheck && checkboxValue.files[i] && (hasCheck = true)
          params.files[i].selected = checkboxValue.files[i] ? 1 : 0
        }
      }
      for (let key in checkboxValue.selectMap) {
        checkboxValue.selectMap[key] && (params.selectMap[key] = true)
      }
      if (!hasCheck && !Object.keys(params.selectMap).length) {
        this.$message.error('请勾选拒绝项！！')
        this.loading = false
        return
      }
    }
    sumbitSettlementApproval(params)
      .then((res) => {
        this.$message.success('提交成功！！')
        setTimeout(() => {
          this.$router.go(-1)
        }, 300)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
