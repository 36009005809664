
import { Component, Vue } from 'vue-property-decorator'

import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '@/views/type'

import bgSrc from '@/assets/images/up_files.png'
import uploadBase from '@/components/UploadBase64/index.vue'
// 接口联调
import { searchArea, searchAreaStystem, getBannerDetail, getBannerLink, getBannerSave } from '@/api/noticeManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    uploadBase
  }
})
export default class BannerManagementDetail extends Vue {
  private loadingTxt: string = loadingText
  private showEnlarge: boolean = false // 放大图片
  private isUpdate: boolean = false // false新增 true详情
  private loading: boolean = false
  private isSuperAdmin: any = '0'
  private formInfo: any = {
    imgUrl: '',
    isEnable: false,
    isJump: false
  }
  private rules: any = {
    imgUrl: {
      required: true,
      message: '请上传banner图片',
      trigger: 'blur'
    },
    areaConfigId: {
      required: true,
      message: '请选择所属区域',
      trigger: 'blur'
    },
    systemConfigId: {
      required: true,
      message: '请选择发布系统',
      trigger: 'blur'
    },
    link: {
      required: true,
      message: '请选择链接内容',
      trigger: 'blur'
    }
  }
  private projectType: any = []
  private areaData: any = []
  private systemData: any = []
  private bgImg: any = bgSrc
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    flag: '1'
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private linkShow: boolean = false
  private linkInfo: any = {}
  private tableData: any = []
  private linkContent: any = {
    title: '',
    id: ''
  }

  created() {
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.$route.params.id != 'add') {
      this.isUpdate = true
    } else {
      if (this.isSuperAdmin != '1') {
        this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId')
        if (this.formInfo.areaConfigId) {
          searchAreaStystem({ areaConfigId: this.formInfo.areaConfigId }).then((res) => {
            this.systemData = res.data.data
          })
        }
      }
    }
    this.handleGetAeraAndType()
  }

  private handleGetAeraAndType() {
    let params: any = this.isUpdate ? {} : { isDelete: false, isDisable: false }
    searchArea(params)
      .then((res) => {
        this.projectType = res.data.data.project
        res.data.data.aeras && (this.areaData = this.filteAreaOptions(res.data.data.aeras))
      })
      .finally(() => {
        if (this.isUpdate) {
          this.getDetail()
        }
      })
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  private getDetail() {
    let params = {
      id: this.$route.params.id
    }
    getBannerDetail(params).then((res) => {
      let data = res.data.data
      this.formInfo.imgUrl = data.imgUrl
      this.formInfo.areaConfigId = data.areaConfigId ? data.areaConfigId + '' : ''
      this.formInfo.systemConfigId = data.systemConfigId ? data.systemConfigId + '' : ''
      this.formInfo.isEnable = data.isEnable == 1 ? true : false
      this.formInfo.isJump = data.isJump == 1 ? true : false
      this.linkContent.title = data.linkContent
      this.linkContent.id = data.noticeProjectId
      if (this.formInfo.areaConfigId) {
        searchAreaStystem({ areaConfigId: this.formInfo.areaConfigId }).then((res) => {
          if (res.data && res.data.code == 1000 && res.data.data) {
            this.systemData = res.data.data
          }
        })
      }
    })
  }
  private getFileUrl(v: any) {
    if (v) {
      this.$set(this.formInfo, 'imgUrl', v.url)
    } else {
      this.uploadOption.limit += 1
    }
  }
  private filteZct(data: any) {
    let returnData: any = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (['资产通管理平台', '资产通'].indexOf(data[i].systemConfigName) == -1) {
          returnData.push(data[i])
        }
      }
    }
    return returnData
  }
  private handleSelectedArea(data: any) {
    this.handleGetSystemData(data)
  }
  // 发布系统
  private handleGetSystemData(id: any) {
    let data: any = {
      areaConfigId: id
    }
    searchAreaStystem(data).then((res) => {
      if (res.data && res.data.code == 1000 && res.data.data) {
        this.systemData = res.data.data
      }
    })
  }
  private enlargeImg() {
    this.showEnlarge = true
  }
  private deleteImgFn(index: number, deletedItem: any) {
    this.$confirm('是否确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.formInfo.imgUrl = ''
        this.uploadOption.limit += 1
      })
      .catch(() => {})
  }
  // 弹窗显示文章
  private linkNotice() {
    if (!this.formInfo.areaConfigId || !this.formInfo.systemConfigId) {
      this.$message.error('请先选择区域和系统')
      return
    }
    this.linkShow = true
    this.handleSearch()
  }
  private handleSearch() {
    this.loading = true
    let params = {
      ...this.linkInfo,
      areaConfigId: this.formInfo.areaConfigId,
      systemConfigId: this.formInfo.systemConfigId,
      pageNum: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    getBannerLink(params)
      .then((res) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleChange(val: any) {
    this.linkContent = val
    this.linkShow = false
  }
  private submit() {
    if (!this.formInfo.imgUrl) {
      return this.$message.error('请上传banner图')
    }
    if (!this.formInfo.areaConfigId) {
      return this.$message.error('请选择所属区域')
    }
    if (!this.formInfo.systemConfigId) {
      return this.$message.error('请选择发布系统')
    }
    if (this.formInfo.isJump && !this.linkContent.title && !this.formInfo.linkContent) {
      return this.$message.error('请选择链接的内容')
    }
    let params: any = {
      imgUrl: this.formInfo.imgUrl,
      areaConfigId: this.formInfo.areaConfigId,
      systemConfigId: this.formInfo.systemConfigId,
      isEnable: this.formInfo.isEnable ? 1 : 0,
      isJump: this.formInfo.isJump ? 1 : 0,
      linkContent: this.linkContent.title,
      noticeProjectId: this.linkContent.id
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    let msg = '新增成功'
    if (this.isUpdate) {
      params.id = this.$route.params.id
      msg = '更新成功'
    }
    console.log(params)
    getBannerSave(params).then((res) => {
      this.$message({
        type: 'success',
        message: msg
      })
      this.goBack()
    })
  }
  goBack() {
    this.$router.go(-1)
  }
}
