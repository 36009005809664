var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "authentication-book" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("申请人类型")]),
              _c("span", [_vm._v(_vm._s(_vm.info.type))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("申请版本")]),
              _c("span", [_vm._v(_vm._s(_vm.info.pane))])
            ]),
            _vm.info.pane == "纸质版"
              ? _c("div", [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("收件人姓名")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.name))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("收件人电话")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.phone))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("收件人地址")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.address))])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("申请时间")]),
              _c("span", [_vm._v(_vm._s(_vm.info.startTime))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("发放时间")]),
              _c("span", [_vm._v(_vm._s(_vm.info.putTime))])
            ]),
            _vm.info.pane == "纸质版"
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("快递单号")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.oddNumbers))])
                ])
              : _vm._e(),
            _vm.info.pane != "纸质版"
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("鉴证书")]),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "files-box",
                        style: {
                          "background-image": "url(" + _vm.filesImg + ")"
                        }
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: _vm.info.fileAddr,
                              target: "_blank",
                              underline: false
                            }
                          },
                          [_vm._v("鉴证书")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [_c("span", [_vm._v("鉴证书")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }