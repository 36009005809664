
import { Component, Prop, Vue } from 'vue-property-decorator'
import { saveCompany } from '@/api/enterpriseCreditRating'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class InfoCheck extends Vue {
  @Prop() private type!: string
  @Prop() private info!: any
  private loadingTxt: string = loadingText
  // private info: any = {}
  private title: any = '企业信息核对'
  private loading: boolean = false
  private code: string = ''
  private creditCode: boolean = true

  created() {
    this.init()
  }

  private init() {
    if (this.type == 'detail') {
      this.title = '企业信息'
      return
    } else {
      if (!this.info.creditCode) {
        this.creditCode = false
      }
    }
  }
  private next() {
    if (!this.info.creditCode && !this.code) {
      return this.$message.error('请先填写社会信用代码')
    }
    if (!this.info.creditCode) {
      let params = {
        id: this.$route.params.id,
        creditCode: this.code
      }
      this.loading = true
      saveCompany(params)
        .then((res) => {
          this.$message.success('保存社会信用代码成功')
          this.info.creditCode = this.code
          this.$emit('next')
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.$emit('next')
    }
  }
}
