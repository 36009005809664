import request from '@/utils/request'
// 农村产权交易

// ------------项目列表-----------------
// 获取竞买人信息
export function getBiddersInfo(data: Object) {
    return request({
        url: '/back/listingApply/getDelistingDetail',
        method: 'POST',
        data: data
    })
}
// 补上传文件
export function patchUpLoad(data: Object) {
    return request({
        url: '/back/listingApply/uploadFile',
        method: 'POST',
        data: data
    })
}

// 删除文件
export function deleteFiles(data: Object) {
    return request({
        url: '/back/listingApply/deleteFile',
        method: 'POST',
        data: data
    })
}

// 修改文件展示
export function editFileShow(data: Object) {
    return request({
        url: '/back/listingApply/showFile',
        method: 'POST',
        data: data
    })
}

// 获取业务类型列表
export function getBusinessTypeList() {
    return request({
        url: '/listing/apply/getBusinessTypeInfo',
        method: 'GET'
    })
}

// 显示隐藏项目
export function showListingApply(data: Object) {
  return request({
    url: '/back/listingApply/showListingApply',
    method: 'POST',
    data: data
  })
}

// 编辑项目公告
export function editBidNotice(data: Object) {
  return request({
    url: '/back/listingApply/editBidNotice',
    method: 'POST',
    data: data
  })
}

// 删除项目
export function deleteListingApply(data: Object) {
  return request({
    url: '/back/listingApply/deleteListingApply',
    method: 'POST',
    data: data
  })
}

// 申请中止审批  /listing/apply/pause/approve/{applyId}
export function pauseApprove(params) {
  return request({
    url: `/listing/apply/pause/approve/${params.id}`,
    method: 'GET'
  })
}

// 上传pdf,导出压缩包
export function uploadPDF(data: Object) {
    return request({
        url: '/back/listingApply/exportZip',
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
    })
}

// 获取项目列表
export function getCountrysideProjectList(data: Object, cancelToken: any = null) {
    let params: any = {
        url: '/back/listingApply/page',
        method: 'POST',
        data: data
    }
    cancelToken && (params.cancelToken = cancelToken)
    return request(params)
}

// 获取项目详情
export function getCountrysideProject(data: Object) {
    return request({
        url: '/back/listingApply/detail',
        method: 'POST',
        data: data
    })
}

// 主动摘牌
export function activeDelisting(data: Object) {
    return request({
        url: '/back/listingApply/delisting',
        method: 'POST',
        data: data
    })
}

// 设置成交结果
export function settingResult(data: Object) {
    return request({
        url: '/back/listingApply/settingResult',
        method: 'POST',
        data: data
    })
}

// 设置中标人
export function setSuccessfulBidder(data: Object) {
    return request({
        url: '/back/listingApply/setup/winner',
        method: 'POST',
        data: data
    })
}

// 设置成交价款
export function setTransactionPrice(data: Object) {
  return request({
    url: '/back/listingApply/setTransactionPrice',
    method: 'POST',
    data: data
  })
}

// ------------我的审批-----------------
// 获取审批列表
export function getMyApprovalList(data: Object) {
    return request({
        url: '/ruralApproval/page',
        method: 'POST',
        data: data
    })
}

// 获取审批详情
export function getMyApprovalDetail(data: Object) {
    return request({
        url: '/ruralApproval/detail',
        method: 'POST',
        data: data
    })
}

// 挂牌审批接口
export function listingApproval(data: Object) {
    return request({
        url: '/ruralApproval/listing',
        method: 'POST',
        data: data
    })
}

// 中止挂牌审批接口
export function discontinueApproval(data: Object) {
    return request({
        url: '/ruralApproval/terminationListing',
        method: 'POST',
        data: data
    })
}

// ------------补贴管理-----------------
// 地区选择
export function areaAll(data: Object) {
    return request({
        url: '/areaConfig/getAll',
        method: 'GET',
        data: data
    })
}
// 补贴列表
export function subsidyList(data: Object) {
    return request({
        url: '/subsidy/some/list/condition',
        method: 'POST',
        data: data
    })
}
// 新增补贴资格
export function subsidyAdd(data: Object) {
    return request({
        url: '/subsidy/qualifications/one/add',
        method: 'POST',
        data: data
    })
}
// 补贴资格详情
export function subsidyApplyDetail(data: Object) {
    return request({
        url: '/subsidy/one/detail',
        method: 'POST',
        data: data
    })
}
// 修改补贴资格
export function subsidyUpdate(data: Object) {
    return request({
        url: '/subsidy/one/update',
        method: 'POST',
        data: data
    })
}
// 删除补贴资格
export function subsidyDel(data: Object) {
    return request({
        url: '/subsidy/one/delete',
        method: 'POST',
        data: data
    })
}
// 更新导入模板
export function subsidyTemplate(data: Object) {
    return request({
        url: '/subsidy/template/upload',
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        data: data
    })
}
// 导入补贴资格
export function subsidyInport(data: Object) {
    return request({
        url: '/subsidy/qualifications/some/import',
        method: 'POST',
        headers: { "Content-Type": "multipart/form-data" },
        data: data
    })
}
// 补贴详情-申请人信息
export function subsidyDetail(data: Object) {
    return request({
        url: '/subsidy/request/one/detail',
        method: 'POST',
        data: data
    })
}
// 补贴-农户信息
export function farmersInfo(data: Object) {
    return request({
        url: '/farmersInfo/getFarmersInfoByPhone',
        method: 'POST',
        data: data
    })
}
// 补贴审批
export function subsidyApply(data: Object) {
    return request({
        url: '/subsidy/request/one/examine',
        method: 'POST',
        data: data
    })
}
// 补贴详情-补贴发放信息
export function subsidyGrantDetail(data: any) {
    return request({
        url: '/subsidy/transfer/detail/one/full/applyId',
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data
    })
}
// 补贴详情-补贴发放选择卡
export function subsidyGrantPre(data: Object) {
    return request({
        url: '/subsidy/transfer/pre/info',
        method: 'POST',
        data: data
    })
}
// 补贴详情-补贴发放提交
export function subsidyGrantSumbit(data: Object) {
    return request({
        url: '/subsidy/transfer/batch/execute',
        method: 'POST',
        data: data
    })
}
// 补贴发放列表
export function subsidyTransferList(data: Object) {
    return request({
        url: '/subsidy/transfer/some/condition',
        method: 'POST',
        data: data
    })
}
// 发放详情-列表
export function subsidyTransferDetailOne(data: Object) {
    return request({
        url: '/subsidy/transfer/one',
        method: 'POST',
        data: data
    })
}
// 发放详情-线下处理信息
export function subsidyTransferDetailInfo(data: Object) {
    return request({
        url: '/subsidy/transfer/detail/one/id',
        method: 'POST',
        data: data
    })
}
// 发放详情-线下处理提交
export function subsidyTransferDetailSumbit(data: Object) {
    return request({
        url: '/subsidy/transfer/detail/one/offline/execute',
        method: 'POST',
        data: data
    })
}
// 发放详情-继续发放
export function subsidyRetrySumbit(data: Object) {
    return request({
        url: '/subsidy/transfer/details/one/retry',
        method: 'POST',
        data: data
    })
}
// 发放详情-详情
export function subsidyTransferDetail(data: Object) {
    return request({
        url: '/subsidy/transfer/detail/some/transferId',
        method: 'POST',
        // headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data
    })
}
// 黑名单列表
export function getBlackList(data: Object) {
    return request({
        url: '/blacklistManagement/selectPage',
        method: 'POST',
        data: data
    })
}
// 删除黑名单
export function deleteBlack(data: Object) {
    return request({
        url: '/blacklistManagement/deleteById',
        method: 'POST',
        data: data
    })
}
// 查询黑名单的详情
export function getBlackInfo(data: Object) {
    return request({
        url: '/blacklistManagement/selectById',
        method: 'POST',
        data: data
    })
}
// 添加黑名单信息
export function addBlackInfo(data: Object) {
    return request({
        url: '/blacklistManagement/insertBlacklist',
        method: 'POST',
        data: data
    })
}
// 补贴项目列表
export function subsidyProjectList(data: Object) {
    return request({
        url: '/subsidyProject/page',
        method: 'POST',
        data: data
    })
}
// 补贴项目默认图标
export function subsidyProjectIcon(data: Object) {
    return request({
        url: '/imagePathManager/page',
        method: 'POST',
        data: data
    })
}
// 新增补贴项目列表
export function subsidyProjectAdd(data: Object) {
    return request({
        url: '/subsidyProject/insert',
        method: 'POST',
        data: data
    })
}
// 修改补贴项目列表
export function subsidyProjectUpdate(data: Object) {
    return request({
        url: '/subsidyProject/update',
        method: 'POST',
        data: data
    })
}
// 删除补贴项目列表
export function subsidyProjectDelete(data: Object) {
    return request({
        url: '/subsidyProject/delete',
        method: 'POST',
        data: data
    })
}
// 获取当前用户补贴项目列表
export function subsidyProjectListUser(data: Object) {
    return request({
        url: '/subsidyProject/getSubsidyProjectByUser',
        method: 'POST',
        data: data
    })
}
// 新-补贴申请
export function subsidyApplySumbit(data: Object) {
    return request({
        url: '/subsidyApply/doneProvide',
        method: 'POST',
        data: data
    })
}
// 新-补贴导入
export function subsidyImportSumbit(data: Object) {
    return request({
        url: '/subsidyApply/doneBatch',
        method: "POST",
        data: data
    })
}
// 查看补贴导入
export function subsidyReadExcel(data: Object) {
    return request({
        url: '/subsidyApply/readExcel',
        method: 'POST',
        data: data
    })
}
// 删除补贴
export function subsidyApplyDel(data: Object) {
    return request({
        url: '/subsidyApply/deleteSubsidy',
        method: 'POST',
        data: data
    })
}
// 编辑补贴详情
export function subsidyApplyUpdateDetail(data: Object) {
    return request({
        url: '/subsidyApply/getSubsidyDetail',
        method: 'POST',
        data: data
    })
}
// 编辑补贴
export function subsidyApplyUpdate(data: Object) {
    return request({
        url: '/subsidyApply/updateSubsidyInfos',
        method: 'POST',
        data: data
    })
}

// 补贴交易凭证信息
export function subsidyVoucherDetail(data: Object) {
    return request({
        url: '/subsidy/getTransactionRecord',
        method: 'POST',
        data: data
    })
}

// ------------鉴证书管理-----------------
// 鉴证书管理获取下拉信息
export function authenticationDropdownInfo(data: Object) {
    return request({
        url: '/tablePropertyConfigInfo/drop',
        method: 'POST',
        data: data
    })
}

// 鉴证书管理列表
export function authenticationManagementList(data: Object) {
    return request({
        url: '/applyCertificate/page',
        method: 'POST',
        data: data
    })
}

// 鉴证书详情(用于已抵押后状态)
export function authenticationDetails(data: Object) {
    return request({
        url: '/applyCertificate/detail',
        method: 'POST',
        data: data
    })
}

// 鉴证书发放
export function authenticationGrant(data: Object) {
    return request({
        url: '/applyCertificate/submitCertificate',
        method: 'POST',
        data: data
    })
}

// 提交解压通知书
export function decompressionNoticeSumbit(data: Object) {
    return request({
        url: '/mortgageSettleProof/add',
        method: 'POST',
        data: data
    })
}

// ------------保证金-----------------
// 保证金管理列表
export function marginManagementList(data: Object) {
    return request({
        // url: '/deposit/list',
        url: '/tradeMargin/back/page',
        method: 'POST',
        data: data
    })
}

// 保证金信息详情
export function marginManagementDetails(data: Object) {
    return request({
        // url: '/deposit/depositDetail',
        url: '/tradeMargin/detail/page',
        method: 'POST',
        data: data
    })
}

// 发起保证金退款审批
export function launchBondApproval(data: Object) {
    return request({
        url: '/tradeMargin/apply/submit',
        method: 'POST',
        data: data
    })
}

// 保证金退款审批详情
export function bondApprovalDetail(data: Object) {
    return request({
        url: '/tradeMargin/apply/detail',
        method: 'POST',
        data: data
    })
}

// 保证金退款审批
export function bondApproval(data: Object) {
    return request({
        url: '/tradeMargin/apply/doApply',
        method: 'POST',
        data: data
    })
}

// 保证金交易记录列表
export function bondRecordList(data: Object) {
    return request({
        url: '/delistingTransaction/getRecordListByApplyId',
        method: 'POST',
        data: data
    })
}

// 保证金交易详情
export function bondRecordDetail(data: Object) {
    return request({
        url: '/delistingTransaction/getDetailById',
        method: 'POST',
        data: data
    })
}

// 保证金收款人列表
export function bondPayeeList(data: Object) {
    return request({
        url: '/delistingTransaction/getPayeeListById',
        method: 'POST',
        data: data
    })
}

// 退款人银行卡修改保存
export function bondbankBackSave(data: Object) {
    return request({
        url: '/tradeMargin/bankCard/edit',
        method: 'POST',
        data: data
    })
}

// ------------财务管理-----------------
// 财务管理列表
export function countrysideFinancialList(data: Object) {
    return request({
        url: '/ruralFinance/list',
        method: 'POST',
        data: data
    })
}

// 财务管理详情
export function countrysideFinancialDetails(data: Object) {
    return request({
        url: '/ruralFinance/detail',
        method: 'POST',
        data: data
    })
}

// 上传缴费凭证
export function uploadVoucher(data: Object) {
    return request({
        url: '/ruralFinance/uploadVoucher',
        method: 'POST',
        data: data
    })
}

// 删除缴费凭证
export function deleteVoucher(data: Object) {
    return request({
        url: '/ruralFinance/deleteVoucher',
        method: 'POST',
        data: data
    })
}

// 确认是否到账
export function confirmFinance(data: Object) {
    return request({
        url: '/ruralFinance/confirmFinance',
        method: 'POST',
        data: data
    })
}

// ------------项目限制管理-----------------
// 项目限制列表
export function projectLimitList(data: Object) {
    return request({
        url: '/listingApplyLimit/listingApplyList',
        method: 'POST',
        data: data
    })
}

// 删除项目限制
export function deleteProjectLimit(data: Object) {
    return request({
        url: '/listingApplyLimit/deleteAll',
        method: 'POST',
        data: data
    })
}

// 项目限制详情
export function projectLimitDetails(data: Object) {
    return request({
        url: '/listingApplyLimit/listingApplyLimitList',
        method: 'POST',
        data: data
    })
}

// 保存项目限制
export function saveProjectLimit(data: Object) {
    return request({
        url: '/listingApplyLimit/saveLimitList',
        method: 'POST',
        data: data
    })
}

// 区域下拉
export function getAreaPowerList() {
    return request({
        url: '/areaConfig/getPersonArea',
        // method: 'GET'
        method: 'POST'
    })
}

// 获取区域相关的审批人
export function getAreaApprovalPerson(areaConfigId: string) {
    return request({
        // url: '/admin/person/getByArea/' + areaConfigId,
        url: '/admin/person/getByArea',
        // method: 'GET',
        method: 'POST',
        data: {
            areaId: areaConfigId
        }
    })
}

// ------------摘牌方式授权审批-----------------
// 摘牌方式授权审批列表
export function delistingPowerApprovalList(data: Object) {
    return request({
        url: '/delistingAuthApproval/page',
        method: 'POST',
        data: data
    })
}

// 摘牌方式授权审批详情
export function delistingPowerApprovalDetails(data: Object) {
    return request({
        url: '/delistingAuthApproval/detail',
        method: 'POST',
        data: data
    })
}

// 摘牌方式授权审批
export function delistingPowerApproval(data: Object) {
    return request({
        url: '/delistingAuthApproval/approval',
        method: 'POST',
        data: data
    })
}

// 权限过滤（区分上下级）区域列表
export function powerAreaList() {
  return request({
    url: '/areaConfig/getAeraAndTypeByPerson',
    method: 'POST'
  })
}

// ------------清算管理-----------------
// 清算列表
export function liquidationList(data: Object) {
    return request({
        url: '/liquidationApply/page',
        method: 'POST',
        data: data
    })
}

// 清算详情
export function liquidationDetail(data: Object) {
    return request({
        url: '/liquidationApply/detail',
        method: 'POST',
        data: data
    })
}

// 发起清算
export function launchLiquidation(data: Object) {
    return request({
        url: '/liquidationApply/launchApply',
        method: 'POST',
        data: data
    })
}

// 查询已填写的收款方信息
export function searchPayeeInfo(data: Object) {
    return request({
        url: '/liquidationApply/getLiquidationPayee',
        method: 'POST',
        data: data
    })
}

// 清算审批
export function liquidationApproval(data: Object) {
    return request({
        url: '/ruralApproval/liquidationApproval',
        method: 'POST',
        data: data
    })
}

// 获取清算审批节点信息
export function getIsLastPerson(data: Object) {
    return request({
        url: '/ruralApproval/getLiquidationApproval',
        method: 'POST',
        data: data
    })
}

// 清算交易记录列表
export function liquidationRecordList(data: Object) {
    return request({
        url: '/liquidationTransaction/getRecordListByApplyId',
        method: 'POST',
        data: data
    })
}

// 清算交易详情
export function liquidationRecordDetail(data: Object) {
    return request({
        url: '/liquidationTransaction/getDetailById',
        method: 'POST',
        data: data
    })
}

// 清算收款人列表
export function liquidationPayeeList(data: Object) {
    return request({
        url: '/liquidationTransaction/getPayeeListById',
        method: 'POST',
        data: data
    })
}

// ------------费项银行卡-----------------
// 获取清算审批中付款银行卡及费项信息
export function getBankAndCost() {
    return request({
        url: '/bankCard/getBackExpenditure',
        method: 'POST'
    })
}

// ------------增减挂牌接口------------
// 资格审批
export function qualificationApproval(data: object) {
    return request({
        url: '/ruralApproval/qualificationApproval',
        method: 'POST',
        data
    })
}
// 资格审批-新的摘牌
export function actionApproval(data: object) {
    return request({
        url: '/ruralApproval/actionApproval',
        method: 'POST',
        data
    })
}
// 增减挂钩类型设置中标人
export function setBidder(data: object) {
    return request({
        url: '/back/listingApply/changeLinkWinner',
        method: 'POST',
        data
    })
}

// 增减挂牌类型设置成交结果
export function arSetResult(data: object) {
    return request({
        url: '/back/listingApply/setTransactionResult',
        method: 'POST',
        data
    })
}

// 获取增减类型中标人列表
export function arBidderList(data: object) {
    return request({
        url: '/back/listingApply/getQualificationPerson',
        method: 'POST',
        data
    })
}

// ------------线下项目导入-----------------
// 获取项目信息下载模板
export function getOfflineProjectTemplate() {
    return request({
        url: '/offlineProjectImportLog/exportOfflineProjectTemplate',
        method: 'GET'
    })
}

// 线下项目导入列表
export function offlineProjectExportList(data: Object) {
    return request({
        url: '/offlineProjectImportLog/page',
        method: 'POST',
        data: data
    })
}

// 删除项目导出任务
export function deleteExportWord(data: Object) {
    return request({
        url: '/offlineProjectImportLog/delete',
        method: 'POST',
        data: data
    })
}

// 获取错误文件下载地址
export function getErrorFiles(data: Object) {
    return request({
        url: '/offlineProjectImportLog/getFileUrl',
        method: 'POST',
        data: data
    })
}

// 项目信息导入解析
export function offlineProjectAnalysis(data: Object) {
    return request({
        url: '/offlineProjectImportLog/importOfflineProject',
        method: 'POST',
        data: data
    })
}

// ------------线下项目-----------------
// 线下项目详情列表
export function offlineProjectList(data: Object) {
  return request({
    url: '/offlineProject/page',
    method: 'POST',
    data: data
  })
}

// 线下项目详情
export function getOfflineProject(data: Object) {
  return request({
    url: '/offlineProject/detail',
    method: 'POST',
    data: data
  })
}

// 是否前端展示项目资料
export function offlineProjectShowFile(data: Object) {
  return request({
    url: '/offlineProject/showFile',
    method: 'POST',
    data: data
  })
}

// 是否前端展示线下项目
export function showOfflineProject(data: Object) {
  return request({
    url: '/offlineProject/showOfflineProject',
    method: 'POST',
    data: data
  })
}

// 删除线下项目
export function offlineProjectDelete(data: Object) {
  return request({
    url: '/offlineProject/delete',
    method: 'POST',
    data: data
  })
}

// 项目资料上传文件
export function offlineProjectUploadFile(data: Object) {
  return request({
    url: '/offlineProject/uploadFile',
    method: 'POST',
    data: data
  })
}

// 项目资料删除文件
export function offlineProjectDeleteFile(data: Object) {
  return request({
    url: '/offlineProject/deleteFile',
    method: 'POST',
    data: data
  })
}

// 编辑线下项目
export function offlineProjectUpdate(data: Object) {
  return request({
    url: '/offlineProject/update',
    method: 'POST',
    data: data
  })
}
// ------------交易机构管理-----------------
// 交易机构管理列表
export function organizationalManagementList(data: Object) {
  return request({
    url: '/tradingInstitutions/page',
    method: 'POST',
    data: data
  })
}
// 交易机构管理添加
export function organizationalManagementAdd(data: Object) {
  return request({
    url: '/tradingInstitutions/insert',
    method: 'POST',
    data: data
  })
}
// 交易机构管理更新
export function organizationalManagementUpdate(data: Object) {
  return request({
    url: '/tradingInstitutions/update',
    method: 'POST',
    data: data
  })
}
// 交易机构管理删除
export function organizationalManagementDelete(data: Object) {
  return request({
    url: '/tradingInstitutions/delete',
    method: 'POST',
    data: data
  })
}



