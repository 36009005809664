
import { Component, Vue } from 'vue-property-decorator'
import { getlawList } from '@/api/law' //接口
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import { listingWayOptions } from '../options'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class LawList extends Vue {
  private loadingTxt: string = loadingText
  private time: any = ''
  // 业务类型选择参数
  private listingWayOptions = listingWayOptions
  // 状态选择参数
  private statusOptions: any = [
    { label: '未上传', value: '0' },
    { label: '已上传', value: '1' }
  ]
  private formInfo: any = {
    demandName: '',
    listingMode: '',
    status: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'LawDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'LawDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getlawList(params).then((res) => {
      const data = res.data
      if (data.data) {
        this.tableData = data.data
        this.paginationParam.total = data.total
        this.loading = false
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({ name: 'LawDetail', params: { id: row.id, type: row.status } })
  }
}
