var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "add-notice",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-form",
        {
          ref: "formMine",
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "抵押登记编号：", prop: "registrationNum" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入抵押登记编号" },
                model: {
                  value: _vm.formInfo.registrationNum,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "registrationNum", $$v)
                  },
                  expression: "formInfo.registrationNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押人：", prop: "mortgagorName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入抵押人" },
                model: {
                  value: _vm.formInfo.mortgagorName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "mortgagorName", $$v)
                  },
                  expression: "formInfo.mortgagorName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目编号：", prop: "projectNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入项目编号" },
                model: {
                  value: _vm.formInfo.projectNo,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "projectNo", $$v)
                  },
                  expression: "formInfo.projectNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押权人：", prop: "mortgageHolderName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入抵押权人" },
                model: {
                  value: _vm.formInfo.mortgageHolderName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "mortgageHolderName", $$v)
                  },
                  expression: "formInfo.mortgageHolderName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区域：", prop: "areaConfigId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: _vm.isSuperAdmin != "1" },
                  model: {
                    value: _vm.formInfo.areaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                    },
                    expression: "formInfo.areaConfigId"
                  }
                },
                _vm._l(_vm.areaOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId,
                      disabled: item.isDelete || item.isDisable
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押物名称：", prop: "collateralName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入抵押物名称" },
                model: {
                  value: _vm.formInfo.collateralName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "collateralName", $$v)
                  },
                  expression: "formInfo.collateralName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押物所在地：", prop: "collateralLocation" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入抵押物所在地" },
                model: {
                  value: _vm.formInfo.collateralLocation,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "collateralLocation", $$v)
                  },
                  expression: "formInfo.collateralLocation"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "贷款金额(万元)：", prop: "loanMoney" } },
            [
              _c("el-input", {
                attrs: {
                  min: "1",
                  type: "number",
                  placeholder: "请输入贷款金额(万元)"
                },
                model: {
                  value: _vm.formInfo.loanMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "loanMoney", $$v)
                  },
                  expression: "formInfo.loanMoney"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押期限：", prop: "time" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.formInfo.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "time", $$v)
                  },
                  expression: "formInfo.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "抵押状态：" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.formInfo.mortgageState,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "mortgageState", $$v)
                    },
                    expression: "formInfo.mortgageState"
                  }
                },
                _vm._l(_vm.mortgageState, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.updateId ? "更新" : "提交"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }