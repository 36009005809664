<template>
  <el-upload
    class="uploader"
    action
    accept="image/*"
    :on-change="onUploadChange"
    :auto-upload="false"
    :show-file-list="false"
  >
    <img slot="trigger" v-if="value" :src="value" class="image" />
    <div slot="trigger" v-loading="loading" v-else class="info-container">
      <div>
        <i class="el-icon-plus image-uploader-icon"></i>
        <div v-if="signType === '1'">上传公司印章</div>
        <div v-if="signType === '2'">上传法人印章</div>
        <div v-if="signType === '3'">上传营业执照</div>
      </div>
    </div>
    <!-- <h4>{{ signType === '1' ? '公司' : '法人' }}印章</h4> -->
  </el-upload>
</template>
<script>
export default {
  props: {
    value: String,
    signType: String,
    userId: String
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onUploadChange(file) {
      this.loading = true
      const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
      const isLt1M = file.size / 1024 / 1024 < 5
      if (!isIMAGE) {
        this.loading = false
        this.$message.error('上传签章只能是 JPG 或 PNG 格式!')
        return false
      }
      if (!isLt1M) {
        this.loading = false
        this.$message.error('上传签章大小不能超过 5MB!')
        return false
      }

      let formData = new FormData()
      formData.append('file', file.raw)
      formData.append('userId', this.userId)
      formData.append('type', this.signType)
      this.$emit('option', formData)
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = async (e) => {
        this.loading = false
        this.$emit('imgBase', e.currentTarget.result)
      }
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
.uploader {
  width: 150px;
  height: auto;
  /deep/ .el-upload {
    .info-container,
    img {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 150px;
      height: 150px;
      background: rgba(255, 255, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(235, 236, 239, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: #f65248;
      }
    }
  }
  h4 {
    margin: 0;
    text-align: center;
  }
}
</style>
