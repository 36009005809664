
import { Component, Vue } from 'vue-property-decorator'
import tableColumn from '../components/tableColumn.vue'
import { tableFilter } from '../components/tableFilter'
import { getFarmerInfo, getAddFarmerInfo, subimtFarmerInfo, updateFarmerInfo, getVillageProvinceList, getVillageCityList, getVillageCountyList, getVillageTownshipList, getVillageList } from '@/api/peasantInfoManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    tableColumn
  }
})
export default class FarmerInfoDetail extends Vue {
  private routeParams: any = {}
  private loadingFlag: boolean = true // 加载动画开关
  private loadingText: string = loadingText // 加载动画文本
  private activeName: any = '1' // 当前 tab 选项
  private paneData: any = null // 模板数据
  private sumbitType: any // 提交类型
  private options: any = {
    provinceOptions: [],
    cityOptions: [],
    countyOptions: [],
    townshipOptions: [],
    villageOptions: []
  }
  private splitData: any
  private isPdPreserve: boolean = false
  private isOpenPreserveArr: any = {}
  private progressFlag: number = 0
  private hasSum: any = []
  private getVillagesInd: any = []
  private requestEnd: boolean = true
  created() {
    this.routeParams = this.$route.params
    if (this.routeParams.farmersInfoId) {
      sessionStorage.setItem('farmerInfoDetail', this.routeParams.farmersInfoId)
    } else {
      this.routeParams.farmersInfoId = sessionStorage.getItem('farmerInfoDetail')
    }
    this.sumbitType = this.routeParams.type
    getVillageProvinceList({ flag: true }).then((res) => {
      if (res.data.data) {
        this.options.provinceOptions = res.data.data
      }
    })
    if (this.routeParams.type == '1') {
      getFarmerInfo({ farmersInfoId: this.routeParams.farmersInfoId })
        .then((res) => {
          if (res.data.code == 1000) {
            this.splitData = tableFilter(res.data.data, this.hasSum, this.getVillagesInd)
            this.paneData = res.data.data
          }
        })
        .finally(() => {
          this.loadingFlag = false
        })
    } else {
      getAddFarmerInfo()
        .then((res) => {
          if (res.data.code == 1000) {
            this.splitData = tableFilter(res.data.data, null, this.getVillagesInd)
            this.paneData = res.data.data
          }
        })
        .finally(() => {
          this.loadingFlag = false
        })
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('farmerInfoDetail')
    next()
  }
  // 返回页面
  returnPaper() {
    this.$router.go(-1)
  }
  // 提交保存
  sumbitFn(type: any) {
    this.progressFlag = 0
    this.isPdPreserve = true
    if (!this.loadingFlag) {
      this.loadingFlag = true
      this.loadingText = '提交中'
    }
    let timer = setInterval(() => {
      if (this.progressFlag == this.paneData.columnList.length) {
        clearInterval(timer)
        this.sumbitAjax(type)
        this.isPdPreserve = false
      }
    }, 200)
  }
  private mergeItem(data: any) {
    if (!this.splitData) {
      return
    }
    let returnData: any = JSON.stringify(data)
    returnData = JSON.parse(returnData)
    for (let key in this.splitData) {
      let nowArrList = this.splitData[key]
      for (let i = 0; i < nowArrList.length; i++) {
        let nowFiledList = returnData[key].fieldList,
          getName = nowFiledList[nowArrList[i]].fieldName + ',' + nowFiledList[nowArrList[i] + 1].fieldName + ',' + nowFiledList[nowArrList[i] + 2].fieldName
        nowFiledList[nowArrList[i]].fieldName = getName
        nowFiledList.splice(nowArrList[i] + 1, 2)
        for (let l = 0; l < returnData[key].valueList.length; l++) {
          let nowValeData = returnData[key].valueList[l].valueData,
            getVal = nowValeData[nowArrList[i]].value + ',' + nowValeData[nowArrList[i] + 1].value + ',' + nowValeData[nowArrList[i] + 2].value
          nowValeData[nowArrList[i]].value = getVal
          nowValeData.splice(nowArrList[i] + 1, 2)
        }
      }
    }
    return returnData
  }
  private sumbitAjax(type: any) {
    let isNullColumnArr: any = []
    for (let key in this.isOpenPreserveArr) {
      if (this.isOpenPreserveArr[key]) {
        let nowColumnList: any = this.paneData.columnList[key]
        if (nowColumnList.isRequire || nowColumnList.canNew) {
          this.$message({
            message: '请保存后再提交',
            type: 'error'
          })
          this.loadingFlag = false
          return
        } else {
          let isNull: boolean = true,
            forValueData: any = nowColumnList.valueList[0].valueData
          for (let k in forValueData) {
            if (forValueData[k].value || forValueData[k].value === 0) {
              isNull = false
              break
            }
          }
          if (isNull) {
            isNullColumnArr.push(key)
          } else {
            this.$message({
              message: '请保存后再提交',
              type: 'error'
            })
            this.loadingFlag = false
            return
          }
        }
      }
    }
    let columnList = this.paneData.columnList
    for (let i = 0; i < columnList.length; i++) {
      if (columnList[i].isRequire && !columnList[i].valueList.length) {
        this.$message({
          message: columnList[i].columnName + '为必填栏目',
          type: 'error'
        })
        this.loadingFlag = false
        return
      }
    }
    let params: any = {
      villagePermissionId: this.paneData.villagePermissionId,
      columnList: this.mergeItem(this.paneData.columnList)
    }
    for (let i = 0; i < isNullColumnArr.length; i++) {
      params.columnList[isNullColumnArr[i]].valueList = []
    }
    if (type == '0') {
      subimtFarmerInfo(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$router.go(-1)
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.loadingFlag = false
        })
    } else {
      params.farmersInfoId = this.paneData.farmersInfoId
      updateFarmerInfo(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.loadingFlag = false
        })
    }
  }
  private isOpenPreserve(flag: boolean, columnId: any) {
    this.progressFlag = this.progressFlag + 1
    if (!flag) {
      this.isOpenPreserveArr[columnId] = true
    } else {
      this.isOpenPreserveArr[columnId] = false
    }
  }
  // 选择省
  private selecttedProvince(value: any) {
    getVillageCityList({ province: value, flag: true })
      .then((res) => {
        if (res.data.data) {
          this.options.cityOptions = res.data.data
        }
      })
      .finally(() => {
        this.requestEnd = true
      })
  }
  // 选择城市
  private selecttedCity(province: any, value: any) {
    getVillageCountyList({ province: province, city: value, flag: true })
      .then((res) => {
        if (res.data.data) {
          this.options.countyOptions = res.data.data
        }
      })
      .finally(() => {
        this.requestEnd = true
      })
  }
  // 选择区
  private selecttedCounty(province: any, city: any, value: any) {
    getVillageTownshipList({
      province: province,
      city: city,
      county: value,
      flag: true
    })
      .then((res) => {
        if (res.data.data) {
          this.options.townshipOptions = res.data.data
        }
      })
      .finally(() => {
        this.requestEnd = true
      })
  }
  // 选择街
  private selecttedTownship(fixedSelection: any) {
    let params = {
      ...fixedSelection
    }
    delete params.villageName
    params.province = params.province
    params.city = params.city
    params.county = params.county
    getVillageList(params)
      .then((res) => {
        if (res.data.data) {
          this.options.villageOptions = res.data.data
        }
      })
      .finally(() => {
        this.requestEnd = true
      })
  }
  // 选择村组
  private selecttedVillage(villageId: any) {
    this.paneData.villagePermissionId = villageId
  }
}
