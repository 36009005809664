
import { Component, Vue } from 'vue-property-decorator'
import { getFinancingDetails, activeDelisting, handleConfirmReceipt, handleConfirmClarify, handleUpdateCertificate, goReject, settlementInfoPage, settlementPayInfo, replaceVoucher, settlemCentConfirm, transactionList } from '@/api/financing'
// import QrCode from '@/components/QrCode.vue'
import UploadBase from '@/components/UploadBase64/index.vue'
import upLoadFileBtn from '@/components/upLoadFileBtn/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import timeFormat from '@/utils/timeFormat'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
const tradeStatusList = [
  {
    label: '审批中',
    value: '0'
  },
  {
    label: '银行处理中',
    value: '1'
  },
  {
    label: '部分成功',
    value: '2'
  },
  {
    label: '全部成功',
    value: '3'
  },
  {
    label: '交易失败',
    value: '4'
  },
  {
    label: '审批驳回',
    value: '5'
  },
  {
    label: '已撤销',
    value: '6'
  }
]
@Component({
  components: {
    numsTips,
    Pagination,
    // QrCode,
    UploadBase,
    upLoadFileBtn
  },
  filters: {
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    },
    filterTrade(val: number) {
      if (val === undefined || val === null) return
      const value = val.toString()
      return tradeStatusList.find((v) => v.value == value)?.label
    },
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class ProjectDetails extends Vue {
  private loadingTxt: string = loadingText
  private columnData: any = [
    { label: '交易编号', prop: 'tradeNo' },
    { label: '服务类型', prop: 'type' },
    { label: '用途', prop: 'resultMsg' },
    { label: '发起时间', prop: 'createdTime' },
    { label: '交易发起人', prop: 'userName' },
    { label: '发起人部门', prop: 'userDept' },
    { label: '交易金额', prop: 'amount' },
    { label: '付款账号', prop: 'payBankNumber' },
    { label: '收款账号', prop: 'receiveBankNumber' },
    { label: '交易状态', prop: 'tradeState' }
  ]
  private typeObj: any = {
    status: -1,
    name: ''
  }
  private loadingOpt1: any = {
    openLoading: true,
    loading: false
  }
  private loadingOpt2: any = {
    openLoading: true,
    loading: false
  }
  private loadingOpt3: any = [
    {
      openLoading: true,
      loading: false
    },
    {
      openLoading: true,
      loading: false
    },
    {
      openLoading: true,
      loading: false
    },
    {
      openLoading: true,
      loading: false
    },
    {
      openLoading: true,
      loading: false
    },
    {
      openLoading: true,
      loading: false
    }
  ]
  private loading: boolean = false
  private tableLoading: boolean = false
  private tableLoading2: boolean = false
  private nowId: any
  private nowSrc: any = ''
  private paginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // private paginationParam2: any = {
  //   pageIndex: 1,
  //   pageSize: 10,
  //   total: 0
  // }
  private settlementInfo: any = []
  private transaction: any = []
  private title: string = '财务驳回'
  private detailtype: any = '' //  type: '',1.非公开发行计划（场外）2.非公开发行计划（场内）3.收益权（场外）4.收益权（场内）5通道类（场内）
  private listShow: boolean = false
  private financArr: any = []
  // private qrDiaShow: boolean = false
  private fileAddr: any = '' //扫描件
  private imgUrl: any = '' //照片
  private dialogVisible: boolean = false
  private handleDialog(src: any) {
    this.nowSrc = src
    this.dialogVisible = true
  }
  private dialogInput: string = ''
  private dialogTableVisibles: boolean = false
  private isOrModalClose: boolean = false
  private uploadOptionBtn: any = {
    limit: 1,
    accept: '.jpg,.jpge,.png',
    // listType: 'picture-card',
    // tip: '请上传pdf格式文件'
    btnText: '点击修改'
  }
  private proDetail: any = {
    brokerProviderResponse: {
      brokerProvider: '',
      fileName: '',
      fileAddress: '',
      fileType: 'broker-provider-contract',
      confirmationName: '',
      confirmationAddress: '',
      fileId: ''
    },
    channelProviderResponse: {
      channelProvider: '',
      fileName: '',
      fileAddress: '',
      fileType: 'consulting-provider-contract',
      confirmationName: '',
      confirmationAddress: '',
      fileId: ''
    },
    consultingProviderResponse: {
      consultingProvider: '',
      fileName: '',
      fileAddress: '',
      fileType: 'channel-provider-contract',
      confirmationName: '',
      confirmationAddress: '',
      fileId: ''
    }
    // certificate1: 'http://kf.vpclub.cn/group1/M00/0B/E2/rBAFJF8RX3uAFNM4AACjxGnch1Y811.jpg',
    // certificate2: null,
    // cost1: '123,000,000.00',
    // changeCost1: '壹亿贰仟叁佰万圆整',
    // cost2: '20.0',
    // changeCost2: '壹亿贰仟叁佰万圆整',
    // demandName: '测试321',
    // name: '2',
    // status: 1,
    // payer: '深圳微品',
    // reason: '就是这样怎么滴'
  }
  private uploadOption0: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    flag: '1'
  }
  private uploadOptions1: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    flag: '2'
  }
  private uploadOption2: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    // listType: 'picture',
    // tip: '请上传pdf格式文件',
    flag: '3'
  }
  private uploadOptions3: any = {
    accept: '.pdf,.jpeg,.jpg,.png',
    // listType:'picture',
    // tip: '请上传 JPG 或 PNG 格式',
    flag: '4'
  }
  private uploadOption4: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    flag: '5'
  }
  private uploadOptions5: any = {
    limit: 1,
    accept: '.pdf,.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    flag: '6'
  }
  // 上传完图片的回调
  private getFileUrlUrl(v: any) {
    switch (v.flag) {
      case '1':
        this.proDetail.brokerProviderResponse.fileName = v.name
        this.proDetail.brokerProviderResponse.fileAddress = v.url
        this.proDetail.brokerProviderResponse.fileType = 'broker-provider-contract'
        this.loadingOpt3[0].loading = false
        break
      case '2':
        this.proDetail.brokerProviderResponse.confirmationName = v.name
        this.proDetail.brokerProviderResponse.confirmationAddress = v.url
        this.loadingOpt3[1].loading = false
        break
      case '3':
        this.proDetail.channelProviderResponse.fileName = v.name
        this.proDetail.channelProviderResponse.fileAddress = v.url
        this.proDetail.channelProviderResponse.fileType = 'consulting-provider-contract'
        this.loadingOpt3[2].loading = false
        break
      case '4':
        this.proDetail.channelProviderResponse.confirmationName = v.name
        this.proDetail.channelProviderResponse.confirmationAddress = v.url
        this.loadingOpt3[3].loading = false
        break
      case '5':
        this.proDetail.consultingProviderResponse.fileName = v.name
        this.proDetail.consultingProviderResponse.fileAddress = v.url
        this.proDetail.consultingProviderResponse.fileType = 'channel-provider-contract'
        this.loadingOpt3[4].loading = false
        break
      case '6':
        this.proDetail.consultingProviderResponse.confirmationName = v.name
        this.proDetail.consultingProviderResponse.confirmationAddress = v.url
        this.loadingOpt3[5].loading = false
        break
    }
    // this.$emit('getUrl', v)
  }
  // 驳回
  private handleReject() {
    this.dialogTableVisibles = true
  }
  private handleDialogClose() {
    this.dialogTableVisibles = false
  }
  private cancelDialog() {
    this.dialogTableVisibles = false
  }
  // 确认--驳回
  handleComfireDialog() {
    if (!this.dialogInput.trim()) {
      this.$message.error('请填写驳回理由！！')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let data: any = {
      id: this.nowId,
      reason: this.dialogInput
    }
    if (this.typeObj.name != '2') {
      goReject(data)
        .then((res) => {
          this.$message.success('驳回成功')
          // this.dialogTableVisibles = false
          setTimeout(() => {
            this.$router.push({ name: 'FinancialList' })
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      data.status = 4
      settlemCentConfirm(data)
        .then((res) => {
          this.$message.success('驳回成功')
          setTimeout(() => {
            this.$router.push({ name: 'FinancialList' })
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  private getFileUrlUrls1(data: any) {
    this.getFileUrlUrls(data, 'payments1')
  }
  private getFileUrlUrls2(data: any) {
    this.getFileUrlUrls(data, 'payments2', this.proDetail.settlementApplyPays[0].id)
  }
  private getFileUrlUrls3(data: any) {
    this.getFileUrlUrls(data, 'payments3', this.proDetail.settlementApplyPays[1].id, 1)
  }
  // 上传完图片的回调
  private getFileUrlUrls(data: any, objStr: string, nowId: any = '', nowIndex: number = 0) {
    if (this.typeObj.name != '2') {
      handleUpdateCertificate({
        id: this.nowId,
        certificate: data.url
      })
        .then((res) => {
          this.$message.success('修改缴费凭证成功')
          this.proDetail.certificate = data.url
          ;(this as any).$refs[objStr].$children[0].clearFiles()
        })
        .catch((res) => {
          this.$message.error('修改缴费凭证失败')
        })
        .finally(() => {
          this.loadingOpt1.loading = false
        })
    } else {
      replaceVoucher({
        id: nowId,
        filePath: data.url
      })
        .then((res) => {
          this.$message.success('修改缴费凭证成功')
          this.proDetail.settlementApplyPays[nowIndex].filePath = data.url
          ;(this as any).$refs[objStr].$children[0].clearFiles()
        })
        .catch((res) => {
          this.$message.error('修改缴费凭证失败')
        })
        .finally(() => {
          objStr == 'payments2' ? (this.loadingOpt1.loading = false) : (this.loadingOpt2.loading = false)
        })
    }
    // this.$confirm('确认修改凭证么？', '提示', {
    //   distinguishCancelAndClose: false,
    //   type: 'warning',
    //   showClose: true,
    //   confirmButtonText: '确认',
    //   cancelButtonText: '取消',
    //   showCancelButton: true,
    //   closeOnClickModal: false,
    //   closeOnPressEscape: false,
    //   center: true,
    //   lockScroll: true
    // })
    //   .then((res) => {
    //     if (res === 'confirm') {

    //     }
    //   })
    //   .catch((res) => {
    //   })
  }
  // private created() {
  //   this.typeObj.name = this.$route.params.name
  //   this.typeObj.status = this.$route.params.status
  //   if (this.typeObj.status !== null && typeof this.typeObj.status === 'string') {
  //     this.typeObj.status = Number(this.typeObj.status)
  //   }
  //   if (this.$route.params.id) {
  //     this.nowId = this.$route.params.id
  //     sessionStorage.setItem('financialDetail', this.$route.params.id)
  //   } else {
  //     this.nowId = sessionStorage.getItem('financialDetail')
  //   }
  //   this.typeObj.name == '2' && this.query()
  //   this.typeObj.name == '2' && this.typeObj.status >= 5 && this.query2()
  //   this.getDetailData()
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      if (vim.$route.params.id) {
        vim.nowId = vim.$route.params.id
        vim.typeObj.name = vim.$route.params.name
        vim.typeObj.status = vim.$route.params.status
        let financialDetail: any = {
          id: vim.nowId,
          name: vim.typeObj.name,
          status: vim.typeObj.status
        }
        sessionStorage.setItem('financialDetail', JSON.stringify(financialDetail))
      } else {
        let financialDetail: any = JSON.parse(sessionStorage.getItem('financialDetail') as any)
        vim.nowId = financialDetail.id
        vim.typeObj.name = financialDetail.name
        vim.typeObj.status = financialDetail.status
      }
      if (vim.typeObj.status !== null && typeof vim.typeObj.status === 'string') {
        vim.typeObj.status = Number(vim.typeObj.status)
      }
      let pageParams: any = sessionStorage.getItem('financialParams')
      if (from.name == 'TradeDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.typeObj.name == '2' && vim.query()
      vim.typeObj.name == '2' && vim.typeObj.status >= 5 && vim.query2()
      vim.getDetailData()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'TradeDetails') {
      if (this.typeObj.name == '2' && this.typeObj.status >= 5) {
        let pageParams: any = {
          paginationParam: this.paginationParam
          // paginationParam2: this.paginationParam2
        }
        sessionStorage.setItem('financialParams', JSON.stringify(pageParams))
      }
    } else {
      sessionStorage.getItem('financialParams') && sessionStorage.removeItem('financialParams')
      sessionStorage.removeItem('financialDetail')
    }
    next()
  }
  private query() {
    this.tableLoading = true
    let params = {
      id: this.nowId,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    settlementInfoPage(params)
      .then((res) => {
        if (res.data.data) {
          this.settlementInfo = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.tableLoading = false
      })
  }
  private query2() {
    this.tableLoading2 = true
    transactionList({ settlementApplyId: this.nowId })
      .then((res) => {
        if (res.data.data) {
          this.transaction = res.data.data
        }
      })
      .finally(() => {
        this.tableLoading2 = false
      })
  }
  private getDetailData() {
    this.loading = true
    const data = {
      id: this.nowId
    }
    if (this.typeObj.name != '2') {
      getFinancingDetails(data)
        .then((res) => {
          res.data.data && (this.proDetail = res.data.data)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      settlementPayInfo(data)
        .then((res) => {
          let datas: any = res.data.data
          if (datas) {
            if (datas.settlementApplyPays && datas.settlementApplyPays.length) {
              let firestItem: any = null,
                lastItem: any = null
              for (let i = 0; i < datas.settlementApplyPays.length; i++) {
                if (datas.settlementApplyPays[i] && datas.settlementApplyPays[i].type == 1) {
                  firestItem = datas.settlementApplyPays[i]
                } else if (datas.settlementApplyPays[i] && datas.settlementApplyPays[i].type == 2) {
                  lastItem = datas.settlementApplyPays[i]
                }
              }
              datas.settlementApplyPays = [firestItem, lastItem]
            }
            this.proDetail = datas
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
  //确认结算多选操作
  private Evt() {
    if (this.typeObj.name == '1') {
      this.tabConfirmClarify()
    } else if (this.typeObj.name == '2') {
      this.tabConfirmReceipt(6)
    }
  }
  // 确认收款
  private tabConfirmReceipt(status: any) {
    let title: string = '是否确认收款？'
    if (status == 6) {
      title = '是否确认收款并发起结算？'
    }
    this.$confirm(title, '提示', {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: true,
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      showCancelButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then((res) => {
        if (res === 'confirm') {
          this.loading = true
          let data: any = {
            id: this.nowId
          }
          if (this.typeObj.name != '2') {
            handleConfirmReceipt(data)
              .then((res) => {
                this.$message.success('提交成功！')
                this.$router.push({ name: 'FinancialList' })
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            data.status = status == 6 ? 7 : 5
            settlemCentConfirm(data)
              .then((res) => {
                this.$message.success('提交成功！')
                setTimeout(() => {
                  this.$router.push({ name: 'FinancialList' })
                }, 300)
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
      .catch((res) => {})
  }
  // 确认结算
  private tabConfirmClarify() {
    let datas: any = {
      id: this.nowId
    }
    if (this.typeObj.name == '1') {
      datas.brokerProviderResponse = this.handleDeleteObj(this.proDetail.brokerProviderResponse)
      datas.channelProviderResponse = this.handleDeleteObj(this.proDetail.channelProviderResponse)
      datas.consultingProviderResponse = this.handleDeleteObj(this.proDetail.consultingProviderResponse)
      // 校验经纪服务商参数
      if (!this.checkObj(datas.brokerProviderResponse)) {
        this.$message.warning('经纪服务商信息不全')
        return
      }
      // 校验渠道服务商参数
      if (!this.checkObj(datas.channelProviderResponse)) {
        this.$message.warning('渠道服务商信息不全')
        return
      }
      // 校验咨询服务商参数
      if (!this.checkObj(datas.consultingProviderResponse)) {
        this.$message.warning('咨询服务商信息不全')
        return
      }
    }
    this.$confirm('是否确认收款并结算？', '提示', {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: true,
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      showCancelButton: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then((res) => {
        if (res === 'confirm') {
          this.loading = true
          handleConfirmClarify(datas)
            .then((res) => {
              this.$message.success('提交成功！')
              this.$router.push({ name: 'FinancialList' })
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
      .catch((res) => {})
  }
  checkObj(obj: any) {
    let flag = true
    for (let key in obj) {
      if (obj[key] === '' || obj[key] === null) {
        flag = false
        return false
      }
    }
    return flag
  }
  handleDeleteObj(obj: any) {
    delete obj.fileId
    return obj
  }
  private transactionInfo(rowData: any) {
    this.$router.push({ name: 'TradeDetails', params: { id: rowData.id, tradeState: rowData.tradeState } })
  }
  //二维码认证成功
  // notify(v: any) {
  //   if (v) {
  //     activeDelisting({ id: Number(this.nowId) })
  //       .then((res) => {
  //         this.$message.success('操作成功')
  //         setTimeout(() => {
  //           this.$router.push({ name: 'FinancialList' })
  //         }, 1500)
  //       })
  //       .finally(() => {})
  //   }
  // }
}
