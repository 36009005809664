
import { Component, Vue ,Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class CompanyJoin extends Vue {
  @Prop({ default: false}) centerDialogVisible!: boolean
  private remark: any = ''
  private show: boolean = false
  @Watch('centerDialogVisible', { deep: true })
  handler() {
    this.show = this.centerDialogVisible
  }
  private diaSure() {
    if(!this.remark){
      this.$message.warning('请填写注销情况说明') 
      return  false
    } 
    //授权认证
    this.show = false
  }
   
}
