var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("名称")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.authenticateName))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.authenticateName,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "authenticateName", $$v)
                        },
                        expression: "info.authenticateName"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("地址")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.authenticateAddress))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.authenticateAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "authenticateAddress", $$v)
                        },
                        expression: "info.authenticateAddress"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系方式")]),
                !_vm.btnProject
                  ? _c("span", [_vm._v(_vm._s(_vm.info.authenticateContact))])
                  : _c("el-input", {
                      staticStyle: { width: "40%" },
                      attrs: { size: "small" },
                      model: {
                        value: _vm.info.authenticateContact,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "authenticateContact", $$v)
                        },
                        expression: "info.authenticateContact"
                      }
                    })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: { click: _vm.returnPaper }
            },
            [_vm._v("返回")]
          ),
          !_vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.editProject }
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.btnProject
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.updateProject }
                },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("鉴证机构")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }