
import { hasIdCard } from '@/utils/validate'
import { Component, Vue } from 'vue-property-decorator'
import QrCode from '@/components/QrCode.vue'
import { sessionStore } from '../../../utils/data-management'

import { subsidyApplyDetail, subsidyProjectList, subsidyApplySumbit, subsidyProjectListUser, subsidyApplyUpdate, subsidyApplyUpdateDetail } from '@/api/countrysidePropertyTransaction'
import { checkBankCardInfo } from '@/api/center'
// import { isNeedFace } from '@/api/common.api'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    QrCode
  }
})
export default class SubsidyAdd extends Vue {
  private loadingTxt: string = loadingText
  private rules = {
    name: { required: true, message: '请输入姓名', trigger: 'blur' },
    identifyCode: { required: true, message: '请输入身份证号', trigger: 'blur' },
    phone: { required: true, message: '请输入手机号', trigger: 'blur' },
    bankcard: { required: true, message: '请输入银行账户', trigger: 'blur' },
    openBank: { required: true, message: '请输入开户行', trigger: 'blur' },
    amount: { required: true, message: '请输入补贴金额', trigger: 'blur' },
    subsidyProjectId: { required: true, message: '请选择补贴项目', trigger: 'blur' }
  }
  private isClick: boolean = true
  //22321313123123123 建设银行
  private formInfo: any = {
    name: '',
    identifyCode: '',
    phone: '',
    bankcard: '',
    openBank: '',
    amount: '',
    subsidyProjectId: '',
    address: ''
    // type: this.$route.params.type
  }

  private projectList: any = []
  private banks: any = []
  private fee: any = ''
  private grantInfo: any = {
    payBankId: '',
    bussinessId: '',
    payRemark: ''
  }
  private grantRules: any = {
    payBankId: { required: true, message: '请选择支付银行卡', trigger: 'blur' },
    bussinessId: { required: true, message: '请选择费项', trigger: 'blur' }
  }
  private qrDiaShow: boolean = false // 授权认证标志
  private loading: boolean = false
  private usif: string = ''
  private callbackId: string = ''
  private uniqueKey: string = ''

  created() {
    let params: any = this.$route.params
    if (params.id != 'add') {
      this.getDetail()
    }
    if (params.type != '3') {
      this.getBankList()
    }
    if (params.type != '1') {
      delete this.rules.bankcard
      delete this.rules.openBank
    }
    this.getProject()
    this.getUuid()
    this.usif = sessionStore.get('UsIF')
  }

  private getUuid() {
    if (sessionStore.get('uniqueKey')) {
      this.uniqueKey = sessionStore.get('uniqueKey')
      return
    }
    var s: any = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'
    var uuid = s.join('')
    sessionStore.set('uniqueKey', uuid)
    this.uniqueKey = uuid
  }

  // 项目列表
  getProject() {
    let data = {
      // pageNumber: 1,
      // pageSize: 100
    }
    subsidyProjectList(data).then((res) => {
      this.projectList = res.data.data
    })
  }
  // 银行卡列表
  private getBankList() {
    checkBankCardInfo({ type: 2 }).then((res) => {
      this.banks = res.data.data
    })
  }
  // 选择银行
  handleBank(val: any) {
    this.grantInfo.bussinessId = ''
    let data = this.banks.filter((item: any) => item.id == val)
    this.fee = (data[0].bankCardBussinessRels || []).filter((item: any) => item.bussinessType == 1)
  }
  // 详情
  private getDetail() {
    let params = {
      applyId: this.$route.params.id
    }
    // subsidyApplyDetail
    subsidyApplyUpdateDetail(params).then((res) => {
      let data = res.data.data
      this.formInfo = {
        name: data.applyRealName,
        identifyCode: data.applyIdCardNumber,
        phone: data.applyPhoneNumber,
        bankcard: data.applyBankAccountNumber,
        openBank: data.applyBankName,
        amount: data.subsidyAmount,
        subsidyProjectId: data.subsidyId,
        address: data.applyAddress
      }
      this.formInfo.amount = this.formInfo.amount / 100
    })
  }
  // 输入数字，小数点后2位
  private oneDecimal(value: any) {
    let e = value
    e = e.replace(/[^\d.]/g, '')
    e = e.replace(/^\./g, '')
    e = e.replace(/\.{2,}/g, '')
    e = e.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
    e = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    if (e.indexOf('.') < 0 && e != '') {
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      e = parseFloat(e)
    }
    this.formInfo.amount = e
  }
  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }
  private handleSubmit() {
    ;(this.$refs.formInfo as any).validate((v: boolean) => {
      if (v) {
        if (this.repeatT()) {
          return
        }
        if (!hasIdCard(this.formInfo.identifyCode)) {
          return this.$message.error('身份证输入错误')
        }
        // 直接发放，确认信息发放
        if (this.$route.params.type != '3') {
          ;(this.$refs.grantInfo as any).validate((v: Boolean) => {
            if (v) {
              // isNeedFace({})
              //   .then((res) => {
              //     if (res.data.data && res.data.data.result) {
              //       this.callbackId = res.data.data.callbackId
              //       this.handleSubsidyApply()
              //     } else {
              //       this.qrDiaShow = true
              //     }
              //   })
              //   .catch(() => {
              this.qrDiaShow = true
              // })
            }
          })
          return
        }
        // 信息采集发放
        // 编辑
        if (this.$route.params.id != 'add') {
          this.handleSubsidyApply()
          return
        }
        // isNeedFace({})
        //   .then((res) => {
        //     if (res.data.data && res.data.data.result) {
        //       this.callbackId = res.data.data.callbackId
        //       this.handleSubsidyApply()
        //     } else {
        //       this.qrDiaShow = true
        //     }
        //   })
        //   .catch(() => {
        this.qrDiaShow = true
        // })
      }
    })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  // 提交
  private handleSubsidyApply() {
    this.isClick = false
    this.loading = true
    let params = {
      ...this.formInfo,
      type: this.$route.params.type,
      callbackId: this.callbackId
    }
    let data = {}
    if (params.type != '3') {
      params = {
        ...params,
        ...this.grantInfo
      }
    }
    params.amount = params.amount * 100
    // 编辑
    if (params.type == '3' && this.$route.params.id != 'add') {
      let data = {
        applyId: this.$route.params.id,
        name: params.name,
        identityCode: params.identifyCode,
        phone: params.phone,
        bankCard: params.bankcard,
        openbank: params.openBank,
        amount: params.amount,
        subsidyProjectId: params.subsidyProjectId,
        address: params.address
      }
      if (!data.address) {
        delete data.address
      }
      subsidyApplyUpdate(data)
        .then((res) => {
          this.$message.success('修改成功')
          this.$router.go(-1)
        })
        .finally(() => {
          this.isClick = true
          this.loading = false
        })
      return
    }
    if (!params.address) {
      delete params.address
    }
    params.uniqueKey = this.uniqueKey
    subsidyApplySumbit(params)
      .then((res) => {
        let msg = '直接发放成功'
        if (params.type == '2') {
          msg = '添加确认信息发放成功'
        } else if (params.type == '3') {
          msg = '添加信息采集发放补贴成功'
        }
        sessionStore.set('uniqueKey', '')
        this.$message.success(msg)
        this.$router.go(-2)
      })
      .finally(() => {
        this.isClick = true
        this.loading = false
      })
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    this.callbackId = callbackId
    if (v) {
      this.qrDiaShow = false
      this.handleSubsidyApply()
    }
  }
}
