var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "bidding-announcement" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("竞买公告")]),
        _vm.checkboxShow && _vm.processStatus === 0
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.info, function(item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c(
                  "div",
                  { staticClass: "item-left" },
                  [
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus[index],
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, index, $$v)
                            },
                            expression: "itemStatus[index]"
                          }
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "left-title" }, [
                      _vm._v(_vm._s(item.labelName))
                    ])
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "item-right",
                  domProps: { innerHTML: _vm._s(item.value) }
                })
              ])
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }