
import { Component, Vue } from 'vue-property-decorator'
import infoCheck from './components/info-check.vue'
import dataExtract from './components/data-extract.vue'
import ratingCount from './components/rating-count.vue'
import ratingStandard from './components/rating-standard.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getCompanyRatingInfo } from '@/api/enterpriseCreditRating'

@Component({
  components: {
    infoCheck,
    dataExtract,
    ratingCount,
    ratingStandard
  }
})
export default class CreditRating extends Vue {
  private loadingTxt: string = loadingText
  private activeName: number = 1
  private show: boolean = false
  private loading: boolean = false
  private info: any = {
    companyInformationResponse: {
      fileAddr: '',
      creditCode: ''
    },
    companyReportResponseList: []
  }

  created() {
    this.init()
  }

  private init() {
    let params = {
      id: this.$route.params.id
    }
    this.loading = true
    getCompanyRatingInfo(params)
      .then((res: any) => {
        if (res.data.code == 1000) {
          this.info = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private prev() {
    this.activeName--
  }
  private next() {
    this.activeName++
  }
}
