
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { creditAssessorList, creditAssessorDel } from '@/api/creditRatingManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips
  }
})
export default class CreditRatingPower extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    assessorName: '',
    phone: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (['EditAssessor', 'AddAssessor'].indexOf(from.name) > -1 && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['EditAssessor', 'AddAssessor'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    creditAssessorList(params).then((res) => {
      this.tableData = res.data.data
      this.paginationParam.total = res.data.total
      this.loading = false
    })
  }
  // 搜索评定人
  private handleSearch() {
    this.getList()
  }
  // 添加
  private handleAdd() {
    this.$router.push({
      name: 'AddAssessor',
      params: { id: 'add' }
    })
  }
  // 删除
  private handleDel(row: any) {
    this.$confirm('确认删除？')
      .then(() => {
        this.loading = true
        creditAssessorDel({ id: row.id }).then((res) => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
      .catch(() => {})
  }
  // 查看详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'EditAssessor',
      params: { id: row.id, userId: row.userId }
    })
  }
}
