
import { Component, Vue, Prop } from 'vue-property-decorator'
import { signContract } from '@/api/api'
import uploadTemp from '@/components/UploadFile/index.vue'
@Component({
  components: {
    uploadTemp
  }
})
export default class ApprovalInfo extends Vue {
  @Prop() info: any
  @Prop({ default: false }) isApprovaling?: boolean
  // 时间线状态logo
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  // 时间线状态
  private iconType: object = ['primary', 'success', 'danger']
}
