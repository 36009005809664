import { render, staticRenderFns } from "./AddOfflineLiq.vue?vue&type=template&id=2fe0b3c6&scoped=true&"
import script from "./AddOfflineLiq.vue?vue&type=script&lang=ts&"
export * from "./AddOfflineLiq.vue?vue&type=script&lang=ts&"
import style0 from "./AddOfflineLiq.vue?vue&type=style&index=0&id=2fe0b3c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe0b3c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fe0b3c6')) {
      api.createRecord('2fe0b3c6', component.options)
    } else {
      api.reload('2fe0b3c6', component.options)
    }
    module.hot.accept("./AddOfflineLiq.vue?vue&type=template&id=2fe0b3c6&scoped=true&", function () {
      api.rerender('2fe0b3c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/countryside-property-transaction/liquidation-management/addEditDetail/AddOfflineLiq.vue"
export default component.exports