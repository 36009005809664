
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
  filters: {}
})
export default class InfoTemplateConfigDialog extends Vue {
  @Prop() private columnOption!: any // 接收父组件传过来的数据
  @Prop({ default: false }) private isShow!: Boolean

  private title: string = ''
  private columnId: any = '' // 栏目id
  // private limit: any = 1 // 文件个数限制
  // private tip: any = '' // 提示语
  // private listType: any = '' // 上传成功之后的显示模式
  // private flag: any = '' // 标识
  // private maxSize: any = 5 // 文件大小
  // private uploadUrl: any = ''

  @Watch('isShow')
  private handleIsShow(newValue: Boolean) {
    this.dialogTableVisible = newValue
    // if (newValue) {
    //   this.dialogTableVisible = true
    // } else {
    //   this.dialogTableVisible = false
    // }
  }
  @Watch('columnOption', { deep: true, immediate: true })
  private handleDialogOption(newValue: any) {
    if (newValue.title) this.title = newValue.title
    if (newValue.columnId) this.columnId = newValue.columnId
    if (newValue.name) this.formDatas.name = newValue.name
    if (newValue.sort) this.formDatas.sort = newValue.sort
    if (newValue.isRequired) this.formDatas.isRequired = newValue.isRequired
    if (newValue.isRuralLoan) this.formDatas.isRuralLoan = newValue.isRuralLoan
    if (newValue.contentIsAdd) this.formDatas.contentIsAdd = newValue.contentIsAdd
  }
  private dialogTableVisible: Boolean = false
  private rules = {
    columnName: { required: true, message: '请输入栏目名称', trigger: 'blur' },
    columnSort: { required: true, message: '请输入栏目排序', trigger: 'blur' }
    // mustFill: { required: true, message: '栏目内容是否必填', trigger: 'blur' },
    // contentIsAdd: { required: true, message: '栏目内容是否允许新增', trigger: 'blur' }
  }

  private isOrModalClose: boolean = false
  // private center: boolean = true
  private formDatas: any = {
    name: '',
    sort: '',
    isRequired: 0,
    isRuralLoan: 0,
    contentIsAdd: 0
  }

  private confirmDialog(formName: any) {
    // ;(this.$refs[formName] as any).validate((valid: any) => {
    //   if (valid) {
    //     alert('submit!')
    //   } else {
    //     return false
    //   }
    // })
    let forms: any = {}
    if (this.title === '新增字段') {
      forms = this.formDatas
    } else {
      forms = JSON.parse(JSON.stringify(this.formDatas))
      forms.id = this.columnId
    }
    this.$emit('ConfirmDialog', { formData: forms })
    // this.$emit('CancelDialog', { flag: false })
  }

  // 取消弹框
  private cancelDialog() {
    this.$emit('CancelDialog', { flag: false })
  }
  // 右上角关闭弹框
  private handleDialogClose(done: any) {
    this.$emit('CancelDialog', { flag: false })
  }
}
