var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费类型")]),
              _c("span", [_vm._v(_vm._s(_vm.statusFn(_vm.info.payType)))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费方式")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.info.payMethod == 1
                      ? "代缴代付"
                      : _vm.info.payMethod == 2
                      ? "自行转账"
                      : "--"
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费方")]),
              _c("span", [_vm._v(_vm._s(_vm.info.payUser || _vm.info.name))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("金额（元）")]),
              _c("span", [_vm._v(_vm._s(_vm.info.payAmount))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费时间")]),
              _c("span", [_vm._v(_vm._s(_vm.timeFormat(_vm.info.payTime)))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费状态")]),
              _c("span", [_vm._v(_vm._s(_vm.statusFn1(_vm.info.payStatus)))])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("缴费情况")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }