var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "appoint-investment" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("指定投资人")]),
        _vm.redTitle && (_vm.detailtype == 1 || _vm.detailtype == 5)
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("企业名称")]),
                _vm.checkboxShow && _vm.itemShow.companyName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.companyName,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "companyName", $$v)
                        },
                        expression: "itemStatus.companyName"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.companyName
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.companyName))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("统一社会信用代码")]),
                _vm.checkboxShow && _vm.itemShow.creditCode
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.creditCode,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "creditCode", $$v)
                        },
                        expression: "itemStatus.creditCode"
                      }
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "red-font":
                        _vm.isShowRed &&
                        ((_vm.detailtype == 1 && _vm.isSecond == "true") ||
                          _vm.detailtype == 0) &&
                        _vm.itemShow.creditCode
                    }
                  },
                  [_vm._v(_vm._s(_vm.info.creditCode))]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }