var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "会员基本信息", name: "1" } },
            [
              _vm.detailtype == 1
                ? _c("company-info", {
                    attrs: { info: _vm.proDetail },
                    on: {
                      "update:info": function($event) {
                        _vm.proDetail = $event
                      }
                    }
                  })
                : _c("person-info", {
                    attrs: { info: _vm.proDetail },
                    on: {
                      "update:info": function($event) {
                        _vm.proDetail = $event
                      }
                    }
                  })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "入会信息", name: "2" } },
            [
              _vm.detailtype == 1
                ? _c("company-join", { on: { goBack: _vm.goBack } })
                : _c("person-join", { on: { goBack: _vm.goBack } })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "参与项目", name: "3" } },
            [_c("tables")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }