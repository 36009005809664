
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { getFeedbackDetail } from '@/api/noticeManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {},
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class FeedbackManagementDetail extends Vue {
  private loadingTxt: string = loadingText
  private searchId: any
  private loading: boolean = false
  private info: any = {}
  private showEnlarge: boolean = false // 放大图片
  private nowSrc: any = '' // 当前凭证图片路径
  created() {
    if (this.$route.params.id) {
      this.searchId = this.$route.params.id
      sessionStorage.setItem('feedbackManagementDetail', this.searchId)
    } else {
      this.searchId = sessionStorage.getItem('feedbackManagementDetail')
    }
    this.getData()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('feedbackManagementDetail')
    next()
  }
  private getData() {
    this.loading = true
    getFeedbackDetail({
      id: this.searchId
    })
      .then((res) => {
        if (res.data.data && res.data.data.length) {
          this.info = res.data.data[0]
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private enlargeImg(src: any) {
    this.nowSrc = src
    this.showEnlarge = true
  }
}
