
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class InvestmentInfo extends Vue {
  @Prop() info?: any
  private isFirst: boolean = true
  // 已获投资总额
  private total: string = '0.00'
  // 监听info处理金额数据
  @Watch('info', { immediate: true })
  infoWatch(newV: any) {
    if (this.isFirst && newV) {
      let total = 0
      for (let i = 0; i < newV.investmentDetails.length; i++) {
        let nowPrice: any = Number(newV.investmentDetails[i].investmentPrice)
        nowPrice = nowPrice.toFixed(2)
        total += Number(nowPrice)
        newV.investmentDetails[i].investmentPrice = nowPrice
      }
      if (total != 0) {
        this.total = total.toFixed(2)
      }
    }
  }
}
