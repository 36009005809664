
import { Component, Prop, Vue } from 'vue-property-decorator'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
import { ratingCalculation, gradeRateScore, companyCreditRateSave } from '@/api/enterpriseCreditRating'

@Component({})
export default class RatingCount extends Vue {
  @Prop() private creditCode!: any
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private info: any = {}
  private radioList: any = [{ value: 'AAA' }, { value: 'AA' }, { value: 'A' }, { value: 'BBB' }, { value: 'BB' }, { value: 'B' }, { value: 'CCC' }, { value: 'CC' }, { value: 'C' }, { value: 'D' }]
  // numerator分子,denominator分母,standard标志值,fullMarks满分,difference差值,or 1上升减2下降减
  private ratingParams: any = [
    {
      numerator: 'current_total_liabilities',
      denominator: 'current_total_assets',
      standard: 60,
      fullMarks: 12,
      difference: 2,
      or: 1
    },
    {
      numerator: 'current_flow_assets',
      denominator: 'current_flow_liabilities',
      standard: 130,
      fullMarks: 10,
      difference: 5,
      or: 2
    },
    {
      numerator: 'current_cash_balance',
      denominator: 'current_flow_liabilities',
      standard: 30,
      fullMarks: 8,
      difference: 2,
      or: 2
    },
    {
      numerator: 'current_sales_profit',
      denominator: 'current_sales_revenue',
      standard: 8,
      fullMarks: 6,
      difference: 1.5,
      or: 2
    },
    {
      numerator: 'current_term_profit',
      denominator: 'current_owner_equity',
      standard: 8,
      fullMarks: 4,
      difference: 2,
      or: 2
    },
    {
      numerator: 'current_sales_product_or_cash',
      denominator: 'current_sales_revenue',
      standard: 80,
      fullMarks: 6,
      difference: 5,
      or: 2
    },
    {
      numerator: 'current_sales_revenue',
      denominator: 'current_average_receivable',
      standard: 400,
      fullMarks: 6,
      difference: 20,
      or: 2
    },
    {
      numerator: 'current_sales_cost',
      denominator: 'current_average_inventory',
      standard: 300,
      fullMarks: 6,
      difference: 20,
      or: 2
    },
    {
      numerator: 'current_end_fix_assets',
      denominator: 'current_fix_origin_assets',
      standard: 65,
      fullMarks: 4,
      difference: 3,
      or: 2
    }
  ]
  // 各项百分比
  private percentage: any = ['']
  // 各项分数
  private mark: any = []
  // 分类分数
  private classifyMark: any = []
  // 输入分数
  private inputMark: any = ['', '', '', '']
  // 计算结果
  private rating: any = {
    validityMark: null,
    level: null,
    validityDate: '',
    remark: ''
  }
  private remarkList: any = ['信用很好，整体业务稳固发展，经营状况和财务状况良好，资产负债结构合理，经营过程中现金流量较为充足，偿债能力强，授信风险较小', '信用较好，现金周转和资产负债状况可为债务偿还提供保证，授信有一定风险，需落实有效的担保规避授信风险', '信用较差，整体经营状况和财务状况不佳，授信风险较大，应采取措施改善债务人的偿债能力和偿还意愿，以确保银行债权的安全']
  // 建议评级
  private creditLevel: string = ''

  created() {
    // this.init()
    this.ratingCalculation()
  }

  // 各评级分数
  private init() {
    let ratingData = sessionStore.get('FinancialRatingData')
    let info: any = {}
    ratingData.map((item: any) => {
      info[item.code] = item.value
    })
    this.info = JSON.parse(JSON.stringify(info))
    Object.keys(info).forEach((key) => {
      info[key] = String(info[key]).replace(/,/g, '')
    })
    // 各项百分比
    let percentage: any = []
    // 各项分数
    let mark: any = []

    this.ratingParams.map((item: any) => {
      if (info[item.numerator] == 0 || info[item.denominator] == 0) {
        percentage.push(0)
      } else {
        let value = Number(info[item.numerator]) / Number(info[item.denominator])
        value = Math.round(value * Math.pow(10, 4)) / Math.pow(10, 4)
        percentage.push(value)
      }
    })
    let percentageSpecial = (Number(info.current_sales_revenue) - Number(info.pre_sales_revenue)) / Number(info.pre_sales_revenue)
    percentageSpecial = Math.round(percentageSpecial * Math.pow(10, 4)) / Math.pow(10, 4)
    percentage.push(percentageSpecial)
    percentageSpecial = (Number(info.current_term_profit) - Number(info.pre_term_profit)) / Number(info.pre_term_profit)
    percentageSpecial = Math.round(percentageSpecial * Math.pow(10, 4)) / Math.pow(10, 4)
    percentage.push(percentageSpecial)
    percentage = percentage.map((item: any) => {
      let index = String(item)
      if (index.indexOf('.') != -1) {
        let length = index.length
        let dit = index.indexOf('.')
        if (length - dit == 2) {
          return Number(index.replace('.', '')) * 10
        } else if (length - dit == 3) {
          return Number(index.replace('.', ''))
        } else if (length - dit == 4) {
          return Number(index.replace('.', '')) / 10
        } else {
          return Number(index.replace('.', '')) / 100
        }
      } else {
        return item * 100
      }
    })
    this.percentage = percentage.map((item: any) => `${item}%`)

    // standard标志值,fullMarks满分,difference差值,or 1上升减2下降减
    mark = this.ratingParams.map((item: any, index: number) => {
      if ((item.or == 1 && percentage[index] <= item.standard) || (item.or == 2 && percentage[index] >= item.standard)) {
        return item.fullMarks
      } else {
        let value = parseInt(String((percentage[index] - item.standard) / item.difference))
        if (item.or == 2) {
          value = -value
        }
        let fullMarks = item.fullMarks - value
        if (fullMarks < 0) {
          fullMarks = 0
        }
        return fullMarks
      }
    })
    if (percentage[9] >= 8) {
      mark.push(6)
    } else {
      let fullMarks = 6 - parseInt(String((8 - percentage[10]) / 2))
      if (fullMarks < 0) fullMarks = 0
      mark.push(fullMarks)
    }
    if (percentage[10] >= 10) {
      if (info.current_term_profit < 0 && info.pre_term_profit < 0) {
        return mark.push(0)
      }
      mark.push(4)
    } else {
      if (info.current_term_profit > 0 && info.pre_term_profit < 0) {
        mark.push(2)
      } else if (info.current_term_profit < 0 && info.pre_term_profit < 0) {
        mark.push(0)
      } else {
        let fullMarks = 4 - parseInt(String((10 - percentage[10]) / 2))
        if (fullMarks < 0) fullMarks = 0
        mark.push(fullMarks)
      }
    }
    let markSpecial = 10 - info.loan_overdue_more_than_1_month * 4
    if (info.repayment_overdue_more_then_3_month > 0) {
      markSpecial = 0
    }
    if (markSpecial < 0) {
      markSpecial = 0
    }
    mark.push(markSpecial)
    if (info.whether_debt_interebt == '是') {
      markSpecial = 0
    }
    markSpecial = 6
    if (info.interest_overdue_more_than_10_days > 0) {
      markSpecial -= 3
    }
    if (markSpecial < 0) {
      markSpecial = 0
    }
    mark.push(markSpecial)
    this.mark = mark
    this.classifyMark = [mark[0] + mark[1] + mark[2], mark[3] + mark[4], mark[5] + mark[6] + mark[7], mark[11] + mark[12], mark[8] + mark[9] + mark[10]]
  }
  private ratingCalculation() {
    let ratingData = sessionStore.get('FinancialRatingData')

    let info: any = {}
    ratingData.map((item: any) => {
      info[item.code] = item.value
    })
    this.info = JSON.parse(JSON.stringify(info))

    let params = {
      companyCreditIndicates: []
    }
    params.companyCreditIndicates = ratingData.map((item: any) => {
      let obj = {
        code: item.code,
        name: item.description,
        value: item.value.replace(/,/g, '')
      }
      if (obj.value == '是') {
        obj.value = '1'
      }
      if (obj.value == '否') {
        obj.value = '0'
      }
      return obj
    })
    let markList = ['assetsLiabilitiesRate', 'flowRate', 'cashRate', 'salesProfitRate', 'assetsReget', 'salesGetCashFlow', 'turnoverRateOfAccountReceivale', 'inventoryTurnover', 'fixedAssetsNetValueRate', 'growthRateOfSalesRevenue', 'profitGrowthRate', 'repaymentRecordOfPrincipalOfCreditAssets', 'repaymentRecordOfProfitOfCreditAssets']
    let percentageList = ['assetsLiabilitiesRatio', 'flowRatio', 'cashRatio', 'salesProfitRatio', 'assetsRegetRatio', 'salesGetCashFlowRatio', 'turnoverRatioOfAccountReceivale', 'inventoryTurnoverRatio', 'fixedAssetsNetValueRatio', 'growthRatioOfSalesRevenue', 'profitGrowthRatio']
    this.loading = true
    ratingCalculation(params)
      .then((res: any) => {
        let data = res.data.data
        let mark: any = []
        let percentage: any = []
        Object.keys(data).forEach((key) => {
          if (markList.indexOf(key) > -1) {
            mark[markList.indexOf(key)] = data[key]
          }
          if (percentageList.indexOf(key) > -1) {
            percentage[percentageList.indexOf(key)] = `${data[key]}%`
          }
        })
        this.mark = mark
        this.percentage = percentage
        this.classifyMark = [mark[0] + mark[1] + mark[2], mark[3] + mark[4], mark[5] + mark[6] + mark[7], mark[11] + mark[12], mark[8] + mark[9] + mark[10]]
      })
      .finally(() => {
        this.loading = false
      })
  }

  private maxInput(value: any, max: number, index: number, num: number) {
    value = Number(value.replace(/[^\d]/g, ''))
    if (!value) {
      value = 0
      this.inputMark[index] = 0
    }
    if (value > max) {
      this.$message.error(`该指标名称满分为${max}分`)
      this.inputMark[index] = max
    }
    let mark = this.mark
    if (num == 2) {
      let value1 = this.inputMark[0] ? Number(this.inputMark[0]) : 0
      let value2 = this.inputMark[1] ? Number(this.inputMark[1]) : 0
      this.classifyMark[num] = mark[5] + mark[6] + mark[7] + value1 + value2
    } else {
      let value1 = this.inputMark[2] ? Number(this.inputMark[2]) : 0
      let value2 = this.inputMark[3] ? Number(this.inputMark[3]) : 0
      this.classifyMark[num] = mark[8] + mark[9] + mark[10] + value1 + value2
    }
  }
  private prev() {
    this.$confirm('确定回到前页？回到前页后已填写数据将清空').then(() => {
      this.$emit('prev')
    })
  }

  // 计算评级等级
  private ratingCount() {
    let params = {
      totalScore: 0,
      companyCreditIndicates: ''
    }
    this.classifyMark.map((item: any) => {
      params.totalScore += item
    })
    let info = sessionStore.get('FinancialRatingData')
    params.companyCreditIndicates = info.map((item: any) => {
      let obj = {
        code: item.code,
        name: item.description,
        value: item.value.replace(/,/g, '')
      }
      if (obj.value == '是') {
        obj.value = '1'
      }
      if (obj.value == '否') {
        obj.value = '0'
      }
      return obj
    })
    this.loading = true
    gradeRateScore(params)
      .then((res: any) => {
        let data = res.data.data
        let level = data.creditLevel
        let remark = ''
        if (level.indexOf('A') > -1) {
          remark = this.remarkList[0]
        } else if (level.indexOf('B') > -1) {
          remark = this.remarkList[1]
        } else if (level.indexOf('C') > -1) {
          remark = this.remarkList[2]
        }
        let date: any = new Date()
        date.setFullYear(date.getFullYear() + 1)
        date = new Date(date.toLocaleDateString()).getTime()
        this.rating = {
          validityMark: data.creditRate,
          level: level,
          validityDate: date,
          remark: remark
        }
        this.creditLevel = data.creditLevel
        console.log(this.rating)
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleChange(e: any) {
    let remark = ''
    if (e.indexOf('A') > -1) {
      remark = this.remarkList[0]
    } else if (e.indexOf('B') > -1) {
      remark = this.remarkList[1]
    } else if (e.indexOf('C') > -1) {
      remark = this.remarkList[2]
    }
    this.rating.remark = remark
  }

  // 评级结果保存
  private ratingSave() {
    if (!this.rating.validityMark) {
      this.$message.error('请先进行评级计算')
      return
    }
    let params = {
      companyCreditIndicateDetails: [],
      companyCreditRate: {},
      companyCreditRateDetails: []
    }
    let mark = JSON.parse(JSON.stringify(this.mark))
    mark.splice(8, 0, this.inputMark[0], this.inputMark[1])
    mark.push(this.inputMark[2], this.inputMark[3])
    let group = ['偿债能力指标', '获利能力指标', '经营管理指标', '履约指标', '发展能力和潜力']
    let name = ['资产负债率', '流动比率', '现金比率', '销售利润率', '资本回报率', '销售收入现金流量', '应收账款周转率', '应收账款周转率', '管理水平', '商誉', '授信资产本金偿还记录', '授信资产利息偿还记录', '固定资产净值率', '销售收入增长比率', '利润增长率', '领导者素质', '市场前景、发展规划与实施条件']
    params.companyCreditIndicateDetails = mark.map((item: any, index: number) => {
      let obj = {
        group: group[4],
        name: name[index],
        value: mark[index] === '' ? 0 : mark[index]
      }
      if (index < 3) {
        obj.group = group[0]
      } else if (index < 5) {
        obj.group = group[1]
      } else if (index < 10) {
        obj.group = group[2]
      } else if (index < 12) {
        obj.group = group[3]
      }
      return obj
    })

    let rating = this.rating
    rating.validityDate += 24 * 60 * 60 * 1000 - 1
    params.companyCreditRate = {
      ...rating,
      creditCode: this.creditCode
    }

    let ratingData = sessionStore.get('FinancialRatingData')
    params.companyCreditRateDetails = ratingData.map((item: any) => {
      let obj = {
        code: item.code,
        name: item.description,
        sort: item.sort,
        value: item.value.replace(/,/g, '')
      }
      if (obj.value == '是') {
        obj.value = '1'
      }
      if (obj.value == '否') {
        obj.value = '0'
      }
      return obj
    })
    this.loading = true
    companyCreditRateSave(params)
      .then((res: any) => {
        if (res.data.code == 1000) {
          this.$message.success('评级结果保存成功')
          this.$router.go(-1)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
