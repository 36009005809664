
import { Component, Vue } from 'vue-property-decorator'
import QrCode from '@/components/QrCode.vue'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import {
  subsidyTransferDetailOne,
  subsidyTransferDetail,
  subsidyTransferDetailInfo, // 线下处理信息
  subsidyTransferDetailSumbit, // 线下提交，
  subsidyRetrySumbit
} from '@/api/countrysidePropertyTransaction'
import { checkBankCardInfo } from '@/api/center'
import UploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
import { stat } from 'fs'
// import { isNeedFace } from '@/api/common.api'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    UploadBase,
    QrCode
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class SubsidyGrantDetail extends Vue {
  private mainLoading: any = false
  private loadingTxt: string = loadingText
  private tableData: any = []
  private info: any = {}
  private show: boolean = false
  private shows: boolean = false
  private showDetail: boolean = false
  private offlineDetail: any = {}
  private offline: any = {}
  private form: any = {
    id: '',
    offlineBankName: '',
    offlineBankAccountNumber: '',
    fileAddr: ''
  }
  private retry: any = {}
  private loading = false
  private bgImg: any = bgSrc
  private uploadOption: any = {
    limit: 1,
    // accept: '.pdf',
    accept: '.png,.jpg,.jpeg',
    flag: '1'
  }
  private qrDiaShow: boolean = false // 授权认证标志
  private faceType: Number = 0
  // 继续发放选择
  private formInfo: any = {
    bankId: '',
    bussinessId: ''
  }
  private banks: any = []
  private fee: any = []
  getFileUrl(v: any) {
    if (v) {
      this.form.fileAddr = v.url
      this.$emit('getUrl', v)
    } else {
      this.uploadOption.limit = this.uploadOption.limit + 1
    }
  }
  created() {
    this.mainLoading = true
    this.getList()
  }

  private getList() {
    let params: any = {
      transferId: this.$route.params.id
    }
    subsidyTransferDetailOne(params).then((res) => {
      this.info = res.data.data
    })

    // let data = new URLSearchParams()
    // data.append('transferId', this.$route.params.id)
    subsidyTransferDetail(params)
      .then((res) => {
        this.tableData = res.data.data
      })
      .finally(() => {
        this.mainLoading = false
      })
  }
  // 过滤状态
  private filterStatus(val: any, name: any) {
    if (name) {
      return '发放成功'
    }
    if (val == 1) {
      return '发放成功'
    } else if (val == 2) {
      return '发放失败'
    } else if (val == 3) {
      return '转账中'
    } else {
      return '未处理'
    }
  }
  private handleReturn() {
    this.$router.go(-1)
  }

  // 继续发放选择银行
  private getBankList() {
    checkBankCardInfo({ type: 2 }).then((res: any) => {
      this.banks = res.data.data
    })
  }

  // 继续发放选择银行
  handleBank(val: any) {
    this.formInfo.bussinessId = ''
    let data = this.banks.filter((item: any) => item.id == val)
    this.fee = (data[0].bankCardBussinessRels || []).filter((item: any) => item.bussinessType == 1)
  }

  // 线下处理详情
  private handleDetail(row: any) {
    this.showDetail = true
    this.offlineDetail = row
  }
  // 继续发放
  private handleGrant(row: any) {
    this.shows = true
    this.retry = row
    this.getBankList()
  }
  // 线下信息
  private handleDia(row: any) {
    this.show = true
    this.offline = row
    this.form.id = row.id
    // subsidyTransferDetailInfo({ id: row.id }).then((res) => {
    //   this.offline = res.data.data
    //   this.form.id = this.offline.applyid
    // })
  }
  // 线下取消
  private cancelDialog() {
    this.show = false
    this.shows = false
  }
  // 线下提交
  private confirmDialog() {
    let params: any = {
      // id: this.$route.params.id,
      id: this.form.id,
      offlineBankName: this.form.offlineBankName,
      offlineBankAccountNumber: this.form.offlineBankAccountNumber,
      offlineBankProof: this.form.fileAddr
    }
    if (!params.offlineBankName) {
      return this.$message.error('请输入转出银行')
    }
    if (!params.offlineBankAccountNumber) {
      return this.$message.error('请输入银行卡号')
    }
    if (!params.offlineBankProof) {
      return this.$message.error('请上传转账凭证')
    }
    this.faceType = 1
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let params: any = {
    //         id: this.form.id,
    //         offlineBankName: this.form.offlineBankName,
    //         offlineBankAccountNumber: this.form.offlineBankAccountNumber,
    //         offlineBankProof: this.form.fileAddr,
    //         callbackId: res.data.data.callbackId
    //       }
    //       subsidyTransferDetailSumbit(params)
    //         .then((res) => {
    //           this.show = false
    //           this.$message.success('离线发放成功')
    //           this.getList()
    //         })
    //         .finally(() => {
    //           this.faceType = 0
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    //  })
    return
    subsidyTransferDetailSumbit(params).then((res) => {
      this.show = false
      this.$message.success('离线发放成功')
      this.getList()
    })
  }
  // 继续发放
  private confirmDialogs() {
    if (!this.formInfo.bankId) {
      return this.$message.error('请选择银行卡')
    }
    if (!this.formInfo.bussinessId) {
      return this.$message.error('请选择银行卡费项')
    }
    this.faceType = 2
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let params: any = {
    //         transferRecordDetailId: this.retry.id,
    //         callbackId: res.data.data.callbackId,
    //         bankId: this.formInfo.bankId,
    //         bussinessId: this.formInfo.bussinessId
    //       }
    //       subsidyRetrySumbit(params)
    //         .then((res) => {
    //           this.shows = false
    //           this.$message.success('继续发放成功')
    //           setTimeout(() => {
    //             this.getList()
    //           }, 300)
    //         })
    //         .finally(() => {
    //           this.faceType = 0
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      // 离线发放
      if (this.faceType == 1) {
        let params: any = {
          id: this.form.id,
          offlineBankName: this.form.offlineBankName,
          offlineBankAccountNumber: this.form.offlineBankAccountNumber,
          offlineBankProof: this.form.fileAddr,
          callbackId
        }
        subsidyTransferDetailSumbit(params)
          .then((res) => {
            this.show = false
            this.$message.success('离线发放成功')
            this.getList()
          })
          .finally(() => {
            this.qrDiaShow = false
            this.faceType = 0
          })
      }
      // 继续发放
      if (this.faceType == 2) {
        let params: any = {
          transferRecordDetailId: this.retry.id,
          callbackId,
          bankId: this.formInfo.bankId,
          bussinessId: this.formInfo.bussinessId
        }
        subsidyRetrySumbit(params)
          .then((res) => {
            this.shows = false
            this.$message.success('继续发放成功')
            setTimeout(() => {
              this.getList()
            }, 300)
          })
          .finally(() => {
            this.qrDiaShow = false
            this.faceType = 0
          })
      }
    }
  }
  private lookVoucher() {
    this.$router.push({
      name: 'SubsidyVoucher',
      params: {
        id: this.$route.params.id
      }
    })
  }
}
