
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class AppointInvestment extends Vue {
  // 展示信息
  @Prop() info: any
  // 审批状态
  @Prop() detailtype: any
  // 是否第二次审批
  @Prop({ default: 'false' }) isSecond: any
  // 是否返回变动值
  @Prop({ default: false }) refuse?: boolean
  // 是否展示红色字体效果
  @Prop({ default: true }) isShowRed?: boolean
  // 是否第一次info赋值
  private isFirst: boolean = true
  // 选择框展示总开关
  private checkboxShow: boolean = false
  // 红色提示标题开关
  private redTitle: boolean = true
  // 选择框状态
  private itemStatus: any = {
    companyName: false,
    creditCode: false
  }
  // 选择框展示单项开关
  private itemShow: any = {
    companyName: true,
    creditCode: true
  }
  // 监听info对象控制选择框和提醒效果展示
  @Watch('info', { deep: true })
  infoWatch(newVal: any) {
    if (this.isFirst && newVal) {
      this.isFirst = false
      let nameShow = false
      if ((this.detailtype == 1 && this.isSecond == 'true') || this.detailtype == 0) {
        let fieldList = this.info.fieldList
        for (let i = 0; i < fieldList.length; i++) {
          if (fieldList[i].status) {
            this.$set(this.itemShow, fieldList[i].fieldName, false)
          } else {
            nameShow = true
          }
        }
        if (this.detailtype != 0 && !nameShow) {
          this.redTitle = false
        }
      }
    }
  }
  // 监听refuse返回改变值
  @Watch('refuse')
  handler(newValue: any) {
    if (newValue) {
      this.$emit('addRefuseItem', 'designatedInvestor', this.itemStatus)
    }
  }
  // 页面渲染前确定选择框展示总开关状态
  created() {
    if (this.detailtype == 1 || this.detailtype == 5) {
      this.checkboxShow = true
    }
  }
}
