var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "village-credit-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-form",
        {
          ref: "formInfoRef",
          attrs: {
            model: _vm.formInfo,
            size: "small",
            rules: _vm.rules,
            "label-width": "230px",
            disabled: _vm.isEdit
          }
        },
        [
          _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("村组基本信息")])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isEdit && _vm.formInfo.villageStatus == 3,
                    expression: "isEdit && formInfo.villageStatus == 3"
                  }
                ],
                staticClass: "info_title"
              },
              [
                _c("span", { staticClass: "a" }, [_vm._v("信用村")]),
                _c("span", { staticClass: "b" }, [
                  _vm._v(_vm._s(_vm.formInfo.isShow ? "已公示" : "未公示"))
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "村组：", prop: "province" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "select_width",
                        attrs: { placeholder: "请选择省份", size: "small" },
                        on: { change: _vm.selecttedProvince },
                        model: {
                          value: _vm.formInfo.province,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfo, "province", $$v)
                          },
                          expression: "formInfo.province"
                        }
                      },
                      _vm._l(_vm.provinceOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.province, value: item.province }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_width",
                        attrs: { placeholder: "请选择城市", size: "small" },
                        on: { change: _vm.selecttedCity },
                        model: {
                          value: _vm.formInfo.city,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfo, "city", $$v)
                          },
                          expression: "formInfo.city"
                        }
                      },
                      _vm._l(_vm.cityOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.city, value: item.city }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_width",
                        attrs: { placeholder: "请选择区县", size: "small" },
                        on: { change: _vm.selecttedCounty },
                        model: {
                          value: _vm.formInfo.county,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfo, "county", $$v)
                          },
                          expression: "formInfo.county"
                        }
                      },
                      _vm._l(_vm.countyOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.county, value: item.county }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_width",
                        attrs: { placeholder: "请选择街道/镇", size: "small" },
                        on: { change: _vm.selecttedTownship },
                        model: {
                          value: _vm.formInfo.township,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfo, "township", $$v)
                          },
                          expression: "formInfo.township"
                        }
                      },
                      _vm._l(_vm.townshipOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.township, value: item.township }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_width",
                        attrs: { placeholder: "请选择村组", size: "small" },
                        on: { change: _vm.selecttedVillage },
                        model: {
                          value: _vm.formInfo.villageName,
                          callback: function($$v) {
                            _vm.$set(_vm.formInfo, "villageName", $$v)
                          },
                          expression: "formInfo.villageName"
                        }
                      },
                      _vm._l(_vm.villageOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.villageName, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.detailShow,
                  expression: "detailShow"
                }
              ],
              staticClass: "part"
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("全村农户基本情况")])
              ]),
              _c(
                "div",
                { staticClass: "content flex" },
                [
                  _c("el-form-item", { attrs: { label: "全村农户总数：" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formInfo.villageFarmerNumber))
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "应采集信息农户数：" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formInfo.shouldCollectedFarmerNumber))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "已采集信息农户数：" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.formInfo.alreadyCollectedFarmerNumber)
                        )
                      ])
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "全村信用户总数：" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formInfo.villageCreditFarmerNumber))
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "全村贷款农户总数：" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formInfo.villageGetLoanFarmerNumber))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "按期归还贷款的农户数：" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.formInfo.scheduleBackLoanFarmerNumber)
                        )
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "全村农户贷款余额：" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.formInfo.villageLoanBalance) + "万元")
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "全村农户不良贷款余额：",
                        prop: "villageBadLoanBalance"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "inputItem",
                          attrs: { size: "small", placeholder: "请输入" },
                          on: { blur: _vm.changeInput },
                          nativeOn: {
                            keyup: function($event) {
                              return _vm.changeCode("villageBadLoanBalance")
                            }
                          },
                          model: {
                            value: _vm.formInfo.villageBadLoanBalance,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formInfo,
                                "villageBadLoanBalance",
                                $$v
                              )
                            },
                            expression: "formInfo.villageBadLoanBalance"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("万元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.detailShow,
                  expression: "detailShow"
                }
              ],
              staticClass: "part"
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("创新评定覆盖率情况")])
              ]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "农户信用信息入库率：" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formInfo.farmerCreditEntryRate) + "%"
                            )
                          ])
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "信用户占比：" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formInfo.creditFarmerInFarmerRate) + "%"
                          )
                        ])
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "按期归还贷款农户占比：" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formInfo.scheduleBackInGetFarmerRate) +
                                "%"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "涉农贷款不良率：" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formInfo.badCreditFarmerRate) + "%"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "label_width",
                      attrs: {
                        label: "是否设立三农金融服务室：",
                        prop: "isSetFinancial"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInfo.isSetFinancial,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isSetFinancial", $$v)
                            },
                            expression: "formInfo.isSetFinancial"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formInfo.isSetFinancial,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isSetFinancial", $$v)
                            },
                            expression: "formInfo.isSetFinancial"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "label_width",
                      attrs: {
                        label: "“两委”班子是否团结、群组威信高：",
                        prop: "isHonesty"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInfo.isHonesty,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isHonesty", $$v)
                            },
                            expression: "formInfo.isHonesty"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formInfo.isHonesty,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isHonesty", $$v)
                            },
                            expression: "formInfo.isHonesty"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "label_width",
                      attrs: {
                        label: "是否关心和支持金融相关工作：",
                        prop: "isCareFinancial"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInfo.isCareFinancial,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isCareFinancial", $$v)
                            },
                            expression: "formInfo.isCareFinancial"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formInfo.isCareFinancial,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isCareFinancial", $$v)
                            },
                            expression: "formInfo.isCareFinancial"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "label_width",
                      attrs: {
                        label: "农户被列入行业管理严重失信名单情况(占比)：",
                        prop: "isBlacklist"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInfo.isBlacklist,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isBlacklist", $$v)
                            },
                            expression: "formInfo.isBlacklist"
                          }
                        },
                        [_vm._v("有")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formInfo.isBlacklist,
                            callback: function($$v) {
                              _vm.$set(_vm.formInfo, "isBlacklist", $$v)
                            },
                            expression: "formInfo.isBlacklist"
                          }
                        },
                        [_vm._v("无")]
                      ),
                      _vm.formInfo.isBlacklist
                        ? _c(
                            "el-input",
                            {
                              staticClass: "inputItem",
                              attrs: {
                                size: "small",
                                prop: "blacklistRate",
                                placeholder: "请输入"
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  return _vm.changeCode("blacklistRate")
                                }
                              },
                              model: {
                                value: _vm.formInfo.blacklistRate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInfo, "blacklistRate", $$v)
                                },
                                expression: "formInfo.blacklistRate"
                              }
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm.detailShow &&
          (!_vm.isEdit ||
            _vm.formInfo.villageStatus == 3 ||
            _vm.formInfo.villageStatus == 4)
            ? _c(
                "div",
                _vm._l(_vm.formInfo.creditAssessGroupOpinionResponse, function(
                  item
                ) {
                  return _c("div", { key: item.id, staticClass: "part" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [_vm._v(_vm._s(item.groupName))])
                    ]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "意见：" } },
                          [
                            _c("el-input", {
                              staticClass: "area",
                              attrs: {
                                type: "textarea",
                                rows: 4,
                                placeholder: "请输入意见"
                              },
                              model: {
                                value: item.opinion,
                                callback: function($$v) {
                                  _vm.$set(item, "opinion", $$v)
                                },
                                expression: "item.opinion"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间：" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期"
                              },
                              model: {
                                value: item.time,
                                callback: function($$v) {
                                  _vm.$set(item, "time", $$v)
                                },
                                expression: "item.time"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detailShow,
              expression: "detailShow"
            }
          ],
          staticClass: "operate"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          ),
          !_vm.isEdit
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.submitInfo()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _vm.isEdit && _vm.formInfo.villageStatus == 3
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.submitInfos()
                    }
                  }
                },
                [_vm._v("申请摘牌")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }