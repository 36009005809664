
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { PersonnelForm } from '@/views/type'

// 接口联调
// import { getProjectList, deleteProject } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
import { unitList, enterpriseDisable } from '@/api/units'
import { loadingText } from '@/utils/defaultGoalConfig'
import numsTips from '@/components/numsTips/index.vue'

@Component({
  components: {
    numsTips
  },
  filters: {
    filterStatus: (status: any) => {
      if (status === 1) {
        return '政府部门'
      } else if (status === 2) {
        return '金融机构'
      } else {
        return '无数据'
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class UnitList extends Vue {
  private loadingTxt: string = loadingText
  // private roleOption: string = ''
  // private statusOption: string = ''
  // private disabled: boolean = false
  // private roleOptions: any = []
  // private statusOptions: any = [
  //   { label: '正常', value: '0' },
  //   { label: '禁用', value: '-1' }
  // ]
  private formInfo: any = {
    pageNumber: 1,
    pageSize: 10,
    name: '',
    managerName: '',
    type: ''
  }
  private companyType: any = [
    { label: '政府部门', value: 1 },
    { label: '金融机构', value: 2 }
  ]
  private pageSizesList: any = [10, 20, 40, 80]
  private tableData: any = []
  private loading = false
  private totalCount: number = 0
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'UnitListDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.totalCount = pageParams.totalCount
      }
      vim.handleUnitList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'UnitListDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        totalCount: this.totalCount
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'pageNumber' && key != 'pageSize') {
        this.formInfo[key] = ''
      }
    })
  }
  // mounted() {
  // getAllRole()
  //   .then((res) => {
  //     // console.log(res.data)
  //     let datas = res.data
  //     this.roleOptions = datas.data
  //   })
  //   .catch(() => {})
  // }

  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  // 分页--改变每页条数触发 -----
  handleSizeChange(pageSize: number) {
    console.log(`每页${pageSize}条`)
    this.formInfo.pageSize = pageSize
    this.handleUnitList()
  }
  // 分页--改变页码数触发 -----
  handleCurrentChange(currentPage: number) {
    console.log(`当前页:${currentPage}`)
    this.formInfo.pageNumber = currentPage
    this.handleUnitList()
  }

  selecttedRole(value: number) {
    // console.log(value)
    // this.formInfo.roleId = value
  }

  // selecttedStatus(value: string) {
  // this.statusOption = value
  // }
  // 获取单位审核列表数据
  handleUnitList() {
    // let checkPersonData: any = this.formInfo
    this.loading = true
    let data: any = {
      ...this.formInfo
    }
    unitList(data)
      .then((res) => {
        let datas: any = res.data
        this.tableData = res.data.data
        this.totalCount = datas.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 搜索审核列表数据
  private handleSearch() {
    this.handleUnitList()
  }

  // 查看审核列表数据详情
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'UnitListDetail',
      params: { id: datas.id, isPass: '0', flag: 'check' }
    })
  }
  // // 删除人员
  // handleDelete(row: any) {
  //   // console.log(row)
  //   let personId: number = row.personId
  //   this.$confirm('确定删除该人员？', '温馨提示')
  //     .then(() => {
  //       // deletePersonnel(personId).then((res) => {
  //       //   this.handleUnitList()
  //       //   this.$message({
  //       //     type: 'success',
  //       //     message: '删除成功!'
  //       //   })
  //       // })
  //     })
  //     .catch(() => {})
  // }
  private handleDisable(row: any) {
    let contentText = '是否确认禁用？'
    if (row.isDisable) {
      contentText = '是否确认取消禁用？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        enterpriseDisable({ id: row.id, isDisable: !row.isDisable })
          .then(() => {
            this.handleUnitList()
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
