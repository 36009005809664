var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "about-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("申请人信息")]),
        _vm.checkboxValueShow
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("企业名称")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.enterpriseName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.enterpriseName,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "enterpriseName", $$v)
                        },
                        expression: "checkboxValue.enterpriseName"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.enterpriseName))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("统一社会信用代码")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.creditCode
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.creditCode,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "creditCode", $$v)
                        },
                        expression: "checkboxValue.creditCode"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.creditCode))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("法人代表")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.legalName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.legalName,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "legalName", $$v)
                        },
                        expression: "checkboxValue.legalName"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.legalName))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系地址")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.contactAddress
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.contactAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "contactAddress", $$v)
                        },
                        expression: "checkboxValue.contactAddress"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.contactAddress))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系人")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.contactMember
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.contactMember,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "contactMember", $$v)
                        },
                        expression: "checkboxValue.contactMember"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.contactMember))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系电话")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.contactPhone
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.contactPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "contactPhone", $$v)
                        },
                        expression: "checkboxValue.contactPhone"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.contactPhone))])
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    _vm.bankShow
      ? _c("div", { staticClass: "part first" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v("银行人员信息")]),
            _vm.checkboxValueShow
              ? _c("span", { staticClass: "red-Tips" }, [
                  _vm._v("审核不通过请选择不合格项")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系人")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.relativeName
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.relativeName,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "relativeName", $$v)
                        },
                        expression: "checkboxValue.relativeName"
                      }
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.info.bankPersonnelInfos[0].name || "无"))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系电话")]),
                _vm.checkboxValueShow && _vm.checkboxValueShow.relativePhone
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.checkboxValue.relativePhone,
                        callback: function($$v) {
                          _vm.$set(_vm.checkboxValue, "relativePhone", $$v)
                        },
                        expression: "checkboxValue.relativePhone"
                      }
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(_vm.info.bankPersonnelInfos[0].phone || "无"))
                ])
              ],
              1
            ),
            !_vm.checkboxValueShow &&
            _vm.info.bankPersonnelInfos[0].settlementRelativeFiles
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("上传文件")]),
                  _c(
                    "div",
                    _vm._l(
                      _vm.info.bankPersonnelInfos[0].settlementRelativeFiles,
                      function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            item && item.filePath
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      href: item.filePath,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fileName) + " ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }