// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.project-noticess .addBtn {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin-top: 50px;\n}\n.project-noticess .downLoadBtn {\n  margin-left: 20px;\n  margin-bottom: 30px;\n}\n.project-noticess .downLoadBtn .el-button--mini {\n    height: 30px !important;\n}\n.project-noticess .downLoadBtn .el-button--mini .downLoadTemplate {\n      color: #fff !important;\n      font-size: 12px;\n      line-height: 16px;\n}\n.project-noticess .downLoadBtn .global-dotted-btn .el-link.el-link--default {\n    color: #3b445c;\n}\n.project-noticess .downLoadBtn .global-dotted-btn .el-divider__text,\n  .project-noticess .downLoadBtn .global-dotted-btn .el-link {\n    font-weight: 600;\n}\n.project-noticess .downLoadBtn .global-dotted-btn:focus .el-link.el-link--default,\n  .project-noticess .downLoadBtn .global-dotted-btn:hover .el-link.el-link--default {\n    color: #409eff;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff"
};
module.exports = exports;
