
import { Component, Vue } from 'vue-property-decorator'

/**
 * 仅用作子页面路由容器
 */
@Component
export default class ZCTEntry extends Vue {
  created() {}
}
