var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "addBtn" },
        [
          _c(
            "el-button",
            {
              staticClass: "global-dotted-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleAddcolumn }
            },
            [_vm._v("新增栏目")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { "element-loading-text": _vm.loadingTxt }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabPane, function(item, index) {
              return _c(
                "el-tab-pane",
                { key: index, attrs: { name: index + "" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("div", { staticClass: "fixed-width" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ]),
                  _c("ItemColumn", {
                    attrs: {
                      itemData: item,
                      loadingInstance: _vm.loadingInstance
                    },
                    on: { updateList: _vm.updateList }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("DialogEdit", {
        attrs: { isShow: _vm.isShow, columnOption: _vm.columnOption },
        on: {
          "update:isShow": function($event) {
            _vm.isShow = $event
          },
          "update:is-show": function($event) {
            _vm.isShow = $event
          },
          "update:columnOption": function($event) {
            _vm.columnOption = $event
          },
          "update:column-option": function($event) {
            _vm.columnOption = $event
          },
          CancelDialog: _vm.handleCancelDialog,
          ConfirmDialog: _vm.handleConfirmData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }