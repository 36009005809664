
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { getApprovalList } from '@/api/vip' //接口
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import { roleOptions} from '../options'
@Component({
  components: {
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    },
  }
})
export default class CenterList extends Vue {
  private roleOptions = roleOptions
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = [
    {
     id: 2222,
     type: 1,
     productName: '公司',
     productNames: '通道类(场内)',
     applyPerson: '2022-02-02',
   },
    {
      id: 1111,
      type: 2,
      productName: '个人',
      productNames: '通道类(场内)',
      applyPerson: '2022-02-02',
    },
  ]
  private loading = false
  private formInfo: any = {
    productName: '',
    applyPerson: '',
    type: '', //1-风控 2受理3成立
  }
  created() {
    // this.getList()
  }
  private handleSearch() {
    this.getList()
  }
  private async getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize,

    }
    Object.keys(params).forEach(key => {
      !params[key] && delete params[key]
    })
    await getApprovalList(params).then((res) => {
        this.tableData =  res.data.data
        this.paginationParam.total =  res.data.total
      }).finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    // if(row.ruleType ){
    //   if(row.ruleType == 5){
        this.$router.push({ name: 'vipCheckDetail', params: {type: row.type , id: row.id || 1} })
      // } else {
      //   this.$router.push({ name: 'CenterDetail', params: {type:row.ruleType, id: row.id || 1} })
      // }
    // }
   
  }
}
