export type PaginationParam = {
  pageIndex: number
  pageSize: number
  total?: number
}

export type IdataList = {
  entityParam: object
  iPage: {
    records: object[]
    total: number
    size: number
    current: number
    orders: []
    searchCount: boolean
    pages: number
  }
}

interface commonSignature {
  contractSignId: number // 合同签署id
  signatory: string // 签署方 1-甲方 2-乙方
  partyAId?: string // 甲方id signatory为1时必传
  partyBId?: string // 乙方id signatory为2时必传
  contractType?: string // 合同类型 1-挂牌服务协议 2-受托管理协议 3-服务商协议 4-产权抵押委托合同 5-产权交易（受让）委托合同
  listingServicesData?: object
}

// 挂牌服务协议参数
export interface listingServicesParams extends commonSignature {
  companyName?: string // 公司名称
  companyAddress?: string // 公司地址
  legalPerson?: string // 法定代表人
  accountName?: string // 账户名
  openBank?: string // 开户行
  accountNo?: string // 账号
  contactPerson?: string // 联系人
  email?: string //电子邮箱
  phoneNumber?: string // 电话
  contactAddress?: string // 联系地址
  serviceCharge?: string // 挂牌服务费率
  productName?: string // 产品名称（XXXX非公开发行计划
}

/*   项目管理 start   */
// 挂牌方式
export enum ListingWay {
  inside = '场内',
  outside = '场外',
  lease = '租赁权'
}

// 挂牌状态
export enum ListingStatus {
  checkPending = '待审核',
  failed = '审核不通过',
  listing = '挂牌中',
  endListing = '挂牌结束',
  delisting = '已摘牌'
}

// 竞价状态
export enum AuctionStatus {
  waitEnroll = '待报名',
  enrolling = '报名中',
  waitBid = '待竞价',
  bidding = '竞价中',
  pendingPayment = '待付款',
  concludeTransaction = '已成交',
  passIn = '流拍'
}

// 审核状态
export enum AduitStatus {
  wait = '待审核',
  pass = '审核通过',
  reject = '审核不通过'
}

// 保证金缴纳
export enum Deposit {
  paid = '已缴纳',
  unpaid = '未缴纳'
}

// 服务费缴纳规则
export enum ServiceFeePaidRules {
  highScore = '评分最高',
  highBidPrice = '竞价最高'
}

// 交易规则
export enum TradeRules {
  before = '交易前',
  after = '交易后'
}

export namespace PropertyRight {
  export interface PropertyRightForm { }
}

export interface DelistingParams {
  id: string | number
  businessType: '非公开发行计划' | '农村产权交易'
}
/*   项目管理 end     */

// ------人员管理 start-----------------

export type PersonnelForm = {
  personId?: string
  name?: string
  idCard?: string
  phone?: string | number
  email?: string
  roleId?: number | string
  recommendCode?: string
  status?: number | string
}

// ------人员管理 end----------------

export type ConfigAddFile = {
  id?: string,
  type?: number,
  fileName?: string
  fileType?: string
  role?: string;
  requireNumber?: number
  maxNumber?: number
  isRequire?: boolean
  sort?: number
  generatePosition?: number
  canDownload?: boolean
  downloadUrl?: string
}
