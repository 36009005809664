
import { Component, Vue } from 'vue-property-decorator'
import { evaluationInfo, evaluationSave } from '@/api/creditRatingManagement'
import { array } from 'js-md5'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {},
  filters: {
    sum: (arr: any) => {
      if (arr && arr.length > 0) {
        const sum: any = arr.reduce(function (prev: any, current: any) {
          return prev + current
        }, 0)
        return sum
      }
    }
  }
})
export default class CreditRatingTemplateConfig extends Vue {
  private loadingTxt: string = loadingText
  private Loading: boolean = false
  private sum: any = ''
  private arrScore: any = []
  private tableDatas: any = []
  private tableData: any = []
  private datas: any = []
  private formData: any = {
    score: '',
    indicatesGroups: [],
    indicateSupplement: []
  }
  created() {
    this.getList()
  }
  //获取最大分值
  private getMaxScore(data: any) {
    let arr: any = []
    this.datas.map((element: any) => {
      if (element.name == data.name && element.score != undefined) {
        arr.push(Number(element.score))
      }
    })
    var a = arr.sort(function (a: any, b: any) {
      return a - b
    })
    this.datas.map((element: any) => {
      if (element.name == data.name) {
        element.maxScore = a[arr.length - 1]
      }
    })
    this.getSum()
    return a[arr.length - 1]
  }
  //计算满分
  private getSum() {
    const arr: any = []
    this.datas.map((element: any, index: any) => {
      if (!element.type) {
        arr.push(element.maxScore)
      }
    })
    this.arrScore = arr
  }
  private save() {
    this.Loading = true
    const param = JSON.parse(JSON.stringify(this.tableDatas))
    const params = JSON.parse(JSON.stringify(this.datas))
    param.forEach((item: any, index: any) => {
      const arr: any = []
      if (item.valLength * 1 > 0) {
        for (let i = 0; i < item.valLength; i++) {
          arr.push({ val: item['val' + i] })
        }
      }
      item.values.forEach((k: any, i: any) => {
        k.score = item.score
        k.value = arr
      })
      Object.keys(item).forEach((key) => {
        if (key != 'name' && key != 'type' && key != 'values' && key != 'description') {
          delete item[key]
        }
      })
    })

    let arrRes: any = []
    params.forEach((item: any, index: any) => {
      if (item.hasOwnProperty('row')) {
        arrRes.push(item)
      }
      if (item.hasOwnProperty('type')) {
        this.$set(item, 'values', [])
        const arr: any = []
        if (item.valLength * 1 > 0) {
          for (let i = 0; i < item.valLength; i++) {
            arr.push({ val: item['val' + i] })
          }
        }
        item.values.push({ score: item.score, remark: item.remark, value: arr })
      }
      arrRes.forEach((v: any, i: any) => {
        if (item.name == v.name) {
          if (item.hasOwnProperty('type')) {
            let arrs: any = []
            arrs.push(...item.values[0])
            arrRes[i].values.push(item.values[0])
          } else {
            this.$set(arrRes[i], 'description', item.description)
          }
        }
        // console.log(v)
      })
    })
    let sumScore: any = []
    arrRes.forEach((item: any, index: any) => {
      item.values.splice(0, 1)
      sumScore.push(item.maxScore)
      Object.keys(item).forEach((key) => {
        if (key != 'name' && key != 'type' && key != 'values' && key != 'description' && key != 'maxScore') {
          delete item[key]
        }
      })
    })
    this.formData.score = sumScore.reduce(function (prev: any, current: any) {
      return prev + current
    }, 0)
    console.log(arrRes, this.formData.score)
    this.formData.indicatesGroups = arrRes
    this.formData.indicateSupplement = param
    evaluationSave(this.formData)
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message.success('保存成功')
          setTimeout(() => {
            this.Loading = false
          }, 500)
        }
      })
      .catch((error) => {
        this.Loading = false
      })
  }
  private getList() {
    this.Loading = true
    evaluationInfo({})
      .then((res) => {
        let datas = res.data.data
        console.log(datas)
        this.formData.score = datas.score
        this.tableData = datas.indicatesGroups
        this.tableDatas = datas.indicateSupplement
        this.tableDatas.forEach((item: any) => {
          item.values.forEach((v: any, index: any) => {
            this.$set(item, 'remark', v.remark)
            this.$set(item, 'score', v.score)
            if (v.value.length > 1) {
              v.value.forEach((k: any, i: any) => {
                this.$set(item, `val${i}`, k.val)
                this.$set(item, `valLength`, v.value.length)
              })
            }
          })
        })
        this.tableData.forEach((item: any) => {
          let arr: any = []
          item.values.forEach((v: any, index: any) => {
            const obj: any = {}
            this.$set(obj, 'name', item.name)
            this.$set(obj, 'type', item.type)
            this.$set(obj, 'maxScore', item.maxScore)
            this.$set(obj, 'remark', '')
            this.$set(obj, 'score', v.score)
            if (item.name == '家庭净资产情况') {
              if (index == 0) {
                this.$set(obj, `valFont`, '元（含）以下')
              } else if (index == item.values.length - 1) {
                this.$set(obj, `valFont`, '元（含）以上')
              } else {
                this.$set(obj, `valFont`, '元（含）至')
              }
            }
            if (item.name == '与当地农户年人均纯收入比例' || item.name == '债偿能力') {
              if (index == 0) {
                this.$set(obj, `valFont`, '倍以下')
              } else if (index == item.values.length - 1) {
                this.$set(obj, `valFont`, '倍以上')
              } else {
                this.$set(obj, `valFont`, '倍（含）至')
              }
            }
            if (index == 0) {
              this.$set(obj, 'row', item.values.length + 1)
            }
            if (v.value && v.value.length > 0) {
              v.value.forEach((k: any, i: any) => {
                this.$set(obj, `val${i}`, k.val)
                this.$set(obj, `valLength`, v.value.length)
              })
            } else {
              this.$set(obj, 'remark', v.remark)
            }
            arr.push(obj)
          })
          const obj1 = {
            name: item.name,
            description: item.description || 1
          }
          arr.push(obj1)
          this.datas.push(...arr)
        })
      })
      .finally(() => {
        this.Loading = false
      })
  }
  //合并表格
  private objectSpanMethods({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex == 1 && row.name == '参加保险情况') {
      return {
        rowspan: 1,
        colspan: 2
      }
    }
  }
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    // 当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性。
    // rowspan: 1,    //合并一行
    // colspan: 2    //合并两列
    // console.log(row,column,rowIndex, columnIndex ,"===")
    if (columnIndex == 0 || columnIndex == 3) {
      return {
        rowspan: row.row,
        colspan: 1
      }
    }
    if ((columnIndex == 1 || columnIndex == 2) && row.description) {
      if (row.description != 1) {
        return {
          rowspan: 1,
          colspan: 2
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
