var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "simple-approval-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info && _vm.info.approvalRecordStatuses
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.bondLoading,
                      expression: "bondLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-text": _vm.loadingTxt,
                    data: _vm.info.approvalRecordStatuses,
                    "header-cell-style": {
                      textAlign: "center",
                      background: "#FAFAFA"
                    },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "姓名/企业名称",
                      prop: "userName",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.userName || scope.row.name) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3732845260
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "审批时间", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.timeFormat(scope.row.updatedTime)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      872334890
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批状态",
                      prop: "status",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.status[scope.row.status]) + " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2064386939
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "审批意见", prop: "opinion" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("审批信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }