
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { delistingPowerApprovalList, delistingPowerApproval } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class DelistingPowerApproval extends Vue {
  private loadingTxt: string = loadingText
  // 状态选择参数
  private statusOptions: any = [
    { label: '待审核', value: 0 },
    { label: '已通过', value: 1 },
    { label: '已拒绝', value: 2 }
  ]
  // 搜索的时间对象
  private time: any = ''
  // 分页参数
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 表格数据
  private tableData: any = []
  // 表格加载中标志位
  private loading = false
  // 提交数据
  private formInfo: any = {
    status: '',
    companyName: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'DelistingPowerApprovalDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'DelistingPowerApprovalDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  // 查询
  private handleSearch() {
    this.getList()
  }
  // 获取表格数据
  private getList() {
    if (this.time) {
      let beginTime = new Date(this.time[0]).getTime(),
        endTime = new Date(this.time[1]).getTime()
      this.$set(this.formInfo, 'beginTime', beginTime)
      this.$set(this.formInfo, 'endTime', endTime)
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && params[key] !== 0 && delete params[key]
    })
    delistingPowerApprovalList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 跳转查看详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'DelistingPowerApprovalDetails',
      params: {
        id: row.id
      }
    })
  }
  // 授权审批
  private approvalFn(id: any, type: number) {
    let textVal: string = type == 1 ? '是否通过该企业的其他摘牌方式申请？' : '是否拒绝该企业的其他摘牌方式申请？'
    this.$confirm(textVal, '授权审批', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        delistingPowerApproval({
          approvalId: id,
          type: type
        })
          .then((res) => {
            this.$message.success('提交成功')
            this.getList()
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
