
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
  import Pagination from '@/components/Pagination/index.vue'
  import { PaginationParam } from '@/views/type'
  // 接口联调
  import {
    // getProjectList,
    getProjectList2,
    deleteProject,
    setTopProject,
    searchArea,
    searchAreaStystem
  } from '@/api/noticeManagement'
  // import { getPropertyrightTransactionCenterData } from '@/api/login'
  import numsTips from '@/components/numsTips/index.vue'
  import { loadingText } from '@/utils/defaultGoalConfig'
  @Component({
    components: {
      numsTips,
      Pagination
    }
  })
  export default class ProjectNotice extends Vue {
    private loadingTxt: string = loadingText
    private isSuperAdmin: any = '0'
    private paginationParam: PaginationParam = {
      pageIndex: 1,
      pageSize: 10,
      total: 0
    }
    private topStatus = [
      {
        value: '',
        label: '全部'
      },
      {
        value: '1',
        label: '是'
      },
      {
        value: '0',
        label: '否'
      }
    ]
    private projectType: any = [
      {
        key: '',
        value: '全部'
      }
    ]
    private areaData: any = [
      {
        areaConfigId: '',
        areaConfigName: '全部'
      }
    ]
    private systemData: any = []
    // private list: any = []
    private time: any = ''
    private formInfo: any = {
      title: '',
      topState: '',
      areaConfigId: '',
      configTypeKey: '2',
      systemConfigId: '3'
      // area: '',
      // time: ''
    }
    private projectId: string = ''
    private projectName: String = ''
    private tableData: any = []
    private loading = false
    created() {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (this.isSuperAdmin != '1') {
        !this.formInfo.areaConfigId && (this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !this.formInfo.areaConfigId && (isGetList = false)
      }
      if (isGetList && (this.projectType.length == 1 || this.areaData.length == 1)) {
        searchArea({}).then((res) => {
          if (res.data.data) {
            if (this.projectType.length == 1 && res.data.data.project && res.data.data.project.length) {
              this.projectType = this.projectType.concat(res.data.data.project)
            }
            if (this.areaData.length == 1 && res.data.data.aeras && res.data.data.aeras.length) {
              this.areaData = this.areaData.concat(this.filteAreaOptions(res.data.data.aeras))
            }
          }
        })
      }
      if (isGetList && this.isSuperAdmin != '1' && !this.systemData.length) {
        searchAreaStystem({ areaConfigId: this.formInfo.areaConfigId }).then((res) => {
          if (res.data.data) {
            this.systemData = res.data.data
          }
        })
      }
      isGetList && this.query()
    }
    private filteAreaOptions(arr: any) {
      let returnArr: any = []
      for (let i = 0; i < arr.length; i++) {
        returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
        if (arr[i].sonAreas && arr[i].sonAreas.length) {
          for (let l = 0; l < arr[i].sonAreas.length; l++) {
            returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
          }
        }
      }
      return returnArr
    }
    private handleSelectedArea(id: any) {
      if (!this.formInfo.areaConfigId) {
        return
      }
      this.formInfo.systemConfigId = ''
      let data: any = {
        areaConfigId: id
      }
      searchAreaStystem(data).then((res) => {
        if (res.data && res.data.code == 1000 && res.data.data) {
          this.systemData = res.data.data
        }
      })
    }
    private query() {
      if (this.time) {
        this.$set(this.formInfo, 'beginTime', this.time[0])
        this.$set(this.formInfo, 'endTime', this.time[1])
      } else {
        this.$set(this.formInfo, 'beginTime', '')
        this.$set(this.formInfo, 'endTime', '')
      }
      this.loading = true
      const params: any = {
        ...this.formInfo,
        pageNum: this.paginationParam.pageIndex,
        pageSize: this.paginationParam.pageSize
      }
      Object.keys(params).forEach((key) => {
        if (!params[key]) {
          delete params[key]
        }
      })
      getProjectList2(params).then((res) => {
        if (res.data.data) {
          //处理置顶状态 字段 String => Boolean
          res.data.data.forEach((item: any) => {
            if (!item.topState || item.topState === '0') {
              item.booleanTopState = false
            } else {
              item.booleanTopState = true
            }
          })
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
          this.loading = false
        }
      })
    }
    private handleSwitch(data: any, id: any) {
      let datas: any = {
        topState: data ? '1' : '0',
        id
      }
      setTopProject(datas).then((res) => {
        this.query()
      })
    }
    handleDetails(row: any) {
      this.projectId = row.id
      this.projectName = row.title
      this.changeProjectId()
      this.changeProjectName()
    }
    handleDelete(row: any) {
      this.projectId = ''
      this.projectName = ''
      this.changeProjectId()
      this.changeProjectName()
    }
    @Emit('projectId')
    changeProjectId() {
      return this.projectId
    }
    @Emit('projectName')
    changeProjectName() {
      return this.projectName
    }
  }
