
import { Component, Vue, Prop } from 'vue-property-decorator'
import uploadBase from '@/components/UploadBase64/index.vue'
@Component({
  components: {
    uploadBase
  }
})
export default class CompeteBuyInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: false
  })
  isEdit?: boolean
  @Prop() auctionInfo: any
  @Prop({ default: false }) isSetPerson?: any
  private loading: boolean = false
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg,.pdf,.doc,.docx',
    flag: '1'
  }
  private totalUploadNum: number = 4
  private nowUploadNum: number = 0
  private setPerson(row: any) {
    this.info.delistingBaseId = row.id
    row.qualificationStatus = 1
    this.$emit('update:isSetPerson', false)
  }
  private goToBiddersDetail(id: any) {
    this.$router.push({ name: 'BiddersDetail', params: { id } })
  }
  private activeUpload() {
    if (this.loading) {
      return
    }
    if (this.totalUploadNum == this.nowUploadNum) {
      this.$message.error('最多只能上传' + this.totalUploadNum + '份')
      return
    }
    ;(document as any).querySelector('#cb-files-btn input').click()
  }
  private deleteFile(ind: number) {
    if (this.loading) {
      this.$message.error('上传文件中请不要操作文件')
      return
    }
    this.info.confirmFile.splice(ind, 1)
    this.nowUploadNum -= 1
    this.$forceUpdate()
  }
  private beforeUpFn() {
    this.loading = true
  }
  private getFileUrl(v: any) {
    this.totalUploadNum != this.nowUploadNum && (this.uploadOption.limit += 1)
    if (v) {
      this.info.confirmFile.push({
        fileUrl: v.url,
        fileName: v.name
      })
      this.nowUploadNum += 1
      this.$message.success('上传成功')
    } else {
      this.$message.error('上传失败')
    }
    this.loading = false
  }
  private uploadError() {
    this.loading = false
  }
  private changeInput(value: any) {
    this.info.transactionPrice = value.replace(/[^\d\.]/g, '')
  }
}
