import request, { request as http } from '@/utils/request'
import { AxiosPromise } from 'axios'
// 个人中心

//审批列表
export function getApprovalList(data: Object) {
  return request({
    url: '/admin/myApproval/list',
    method: 'POST',
    data: data
  })
}
//审批详情
export function getApprovalDetail(data: Object) {
  return request({
    url: '/risk/approval/getDetail',
    method: 'POST',
    data: data
  })
}
//预审批
export function goToApproval(data: Object) {
  return request({
    url: '/risk/approval/preApproval',
    method: 'POST',
    data: data
  })
}
//终审批
export function finalApproval(data: Object) {
  return request({
    url: '/risk/approval/finalApproval',
    method: 'POST',
    data: data
  })
}
//决策委员会审批
export function decisionApproval(data: Object) {
  return request({
    url: '/risk/approval/decisionApproval',
    method: 'POST',
    data: data
  })
}
//受理审批
export function acceptanceApproval(data: Object) {
  return request({
    url: '/acceptApproval/approvalFinancing',
    method: 'POST',
    data: data
  })
}
//风控审批
export function doneApproval(data: Object) {
  return request({
    url: '/risk/approval/doneApproval',
    method: 'POST',
    data: data
  })
}
//受理审批-财务
export function approvalFinancing(data: Object) {
  return request({
    url: '/acceptApproval/approvalFinancing',
    method: 'POST',
    data: data
  })
}
//受理审批-用章
export function approvalSign(data: Object) {
  return request({
    url: '/acceptApproval/sign',
    method: 'POST',
    data: data
  })
}

// 银行卡管理表单下拉框内容
export function selectItem(data: Object) {
  return request({
    url: '/dictionary/getByCategoryName',
    method: 'POST',
    data: data
  })
}

// 银行卡管理
export function addbankCard(data: Object) {
  return request({
    url: '/bankCard/buildCompanyCardInfos',
    method: 'POST',
    data: data
  })
}

// 修改机构号和节点号

export function updateOrganizationOrNode(data: Object) {
  return request({
    url: '/company/updateOrganizationOrNode',
    method: 'POST',
    data
  })
}

// 修改机构号和节点号

export function updateOrganizationOrNode2(data: Object) {
  return request({
    url: '/person/bank/savePersonOrgan',
    method: 'POST',
    data
  })
}

// 查询银行卡列表及详情
export function checkBankCardInfo(data: Object) {
  return request({
    url: '/bankCard/listMyCard',
    method: 'POST',
    data
  })
}

// 银行卡编辑
export function editBankCardInfo(data: Object) {
  return request({
    url: '/bankCard/update',
    method: 'POST',
    data
  })
}
// 银行卡删除
export function deleteBankCardInfo(data: Object) {
  return request({
    url: '/bankCard/delete',
    method: 'POST',
    data
  })
}
// ------------------------------银行业务管理-----------------------------------------------
//查询银行业务管理列表
export function getCompanyBankBusinessList(data: Object) {
  return request({
    url: '/bankCardBusinessRel/listUserCardBusiness',
    method: 'POST',
    data: data
  })
}

//添加银行业务
export function addCompanyBankBusiness(data: Object) {
  return request({
    url: '/bankCardBusinessRel/insert',
    method: 'POST',
    data: data
  })
}

//删除银行业务
export function deleteCompanyBankBusiness(data: Object) {
  return request({
    url: '/bankCardBusinessRel/delete',
    method: 'POST',
    data: data
  })
}


//更新银行业务
export function updateCompanyBankBusiness(data: Object) {
  return request({
    url: '/bankCardBusinessRel/update',
    method: 'POST',
    data: data
  })
}

// 导入模板
export function importTemplate(data: Object) {
  return request({
    url: '/bankCardBusinessRel/importBankCardRel',
    method: 'POST',
    data: data
  })
}
// // 下载模板
// export function downloadTemplate(data: Object) {
//   return request({
//     url: '/bankCardBusinessRel/download',
//     method: 'POST',
//     data: data
//   })
// }

// 企业 政府认证
export function goAuthorize(data: Object) {
  return request({
    url: '/faceVerify/sendIdentity',
    method: 'POST',
    data: data
  })
}

