
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import { roleOption, StatusOptions } from '../options'
import { listingWayOptions, listingStatusOptions } from '../../project-management/options'
@Component({
  components: {
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class Tables extends Vue {
  @Prop() info: any
  private listingWayOptions = listingWayOptions
  private roleOption = roleOption
  private StatusOptions = StatusOptions
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private formInfo: any = {
    productName: '',
    applyPerson: '',
    type: '' //1-风控 2受理3成立
  }
  private loading = false
  private tableData: any = []
  public baseInfo: any = {}
  @Watch('info', { deep: true })
  handler() {
    this.baseInfo = this.info.projectStatus
  }
  private handleSearch() {
    this.getList()
  }
  private async getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    // await getApprovalList(params).then((res) => {
    //     this.tableData =  res.data.data
    //     this.paginationParam.total =  res.data.total
    //   }).finally(() => {
    //     this.loading = false
    //   })
  }
  private handleDetails(row: any) {
    // if(row.ruleType ){
    //   if(row.ruleType == 5){
    this.$router.push({ name: 'VipDetail', params: { type: row.type, id: row.id || 1 } })
    // } else {
    //   this.$router.push({ name: 'CenterDetail', params: {type:row.ruleType, id: row.id || 1} })
    // }
    // }
  }
}
