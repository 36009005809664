
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { PersonnelForm } from '@/views/type'

// 接口联调
// import { getProjectList, deleteProject } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
import {
  addCompanyBankBusiness,
  getCompanyBankBusinessList,
  deleteCompanyBankBusiness,
  updateCompanyBankBusiness,
  checkBankCardInfo
} from '@/api/center'

import numsTips from '@/components/numsTips/index.vue'
import { localStore } from '../../../utils/data-management'

@Component({
  components: {
    numsTips
  },
  filters: {
    filterStatus: (status: any) => {
      if (status === 1) {
        return '政府部门'
      } else if (status === 2) {
        return '金融机构'
      } else {
        return '无数据'
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class CompanyBankBusiness extends Vue {
  private disabled: boolean = false
  private businessItem: any = [
    { label: '实时付款', value: 1 },
    { label: '实时收款', value: 2 },
    { label: '批量代付', value: 3 },
    { label: '批量代收', value: 4 }
  ]
  private rules: any = {
    bankName: { required: true, message: '请选择开户行', trigger: 'blur' },
    bankCard: { required: true, message: '请选择账户', trigger: 'blur' },
    bussinessType: { required: true, message: '请选择业务种类', trigger: 'blur' },
    feeItem: { required: true, message: '请输入开通费项', trigger: 'blur' },
    feeCode: { required: true, message: '请输入费项编码', trigger: 'blur' }
  }
  private formData: any = {
    bankCardId: '',
    bankCard: '',
    bankName: '',
    bussinessType: '',
    feeItem: '',
    feeCode: '',
    contractCode: ''
  }
  private bankList: Array<any> = []
  private bankNameArr: Array<any> = []
  private bankAccountArr: Array<any> = []
  private get isShow() {
    return (
      (this.$route.params && this.$route.params.flag === 'check') ||
      localStore.get('dt').flag === 'check'
    )
  }

  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  private created(): void {
    if (this.$route.params.flag === 'check') {
      localStore.remove('dt')
      localStore.set('dt', this.$route.params)
      this.formData = this.$route.params.datas
    } else if (this.$route.params.flag === 'add') {
      localStore.remove('dt')
      localStore.set('dt', this.$route.params)
    } else {
      if (localStore.get('dt') && localStore.get('dt').flag === 'check') {
        this.formData = localStore.get('dt').datas
        debugger
      }
    }

    this.getBankList()
  }
  private mounted(): void {}
  // 获取银行业务列表详情
  // private handleDetail(): void {
  //   let data: any = {
  //     ...this.formData
  //   }
  //   getCompanyBankBusinessList(data)
  //     .then((res) => {
  //       let datas: any = res.data
  //     })
  // }
  // 开户行下拉数据
  private selectedBankName(data: any) {
    this.formData.bankCard = ''
    let arr = this.bankList.filter((item, index) => {
      return item.bankName === data
    })
    let accountArr: Array<any> = []
    arr.forEach((item: any, index: any) => {
      accountArr.push({ bankCard: item.bankCard, key: index, id: item.id })
    })
    this.bankAccountArr = accountArr
  }
  private handleSelected(data: any) {
    if (data === 2 || data === 4) {
      this.formData.contractCode = ''
    }
  }
  // 账号下拉数据
  private selectedBankAccount(data: any) {
    this.formData.bankCardId = data
  }
  // 获取银行列表
  private getBankList(): void {
    let data: any = {
      type: 1
    }
    checkBankCardInfo(data)
      .then((res) => {
        let dataList: Array<any> = res.data.data
        let bankNameArr: Array<any> = []
        // let bankAccountArr: Array<any> = []
        dataList.forEach((item: any, index: any) => {
          bankNameArr.push(item.bankName)
        })
        let arr = Array.from(new Set(bankNameArr))
        let bankNameArrs: Array<any> = []
        arr.forEach((item: any, index: any) => {
          bankNameArrs.push({ bankName: item, key: index })
        })
        this.bankList = res.data.data
        this.bankNameArr = bankNameArrs
      })
      .catch((err) => {})
  }
  // 返回
  private handleReturn(): void {
    this.$router.go(-1)
  }
  // 提交
  private handleFormData(): void {
    const param = (({ bankCardId, bussinessType, feeItem, feeCode, contractCode }) => ({
      bankCardId,
      bussinessType,
      feeItem,
      feeCode,
      contractCode
    }))(this.formData)
    let data: any = {
      ...this.formData
    }
    addCompanyBankBusiness(data)
      .then((res) => {
        this.$router.push({
          name: 'SystemBankManagement'
        })
      })
      .catch(() => {})
      .finally()
  }
  // 更新
  private handleUpdateFormData(): void {
    let data = this.bankList.filter((item: any) => {
      return item.bankCard === this.formData.bankCard && item.bankName === this.formData.bankName
    })
    this.formData.bankCardId = data[0].id
    const param = (({
      id,
      bankCard,
      bankCardId,
      bussinessType,
      feeItem,
      feeCode,
      contractCode
    }) => ({ id, bankCard, bankCardId, bussinessType, feeItem, feeCode, contractCode }))(
      this.formData
    )
    // let data: any = {
    //   ...this.formData
    // }
    updateCompanyBankBusiness(param)
      .then((res) => {
        this.$router.push({
          name: 'CompanyBankManagement'
        })
      })
      .catch(() => {})
      .finally()
  }
}
