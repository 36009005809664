
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class TradeList extends Vue {
  @Prop({
    default: () => []
  })
  tableData: any
  @Prop({
    default: 1
  })
  nowType?: number
  private columnData: any = [
    { label: '交易编号', prop: 'tradeNo' },
    { label: '服务类型', prop: 'type' },
    { label: '用途', prop: 'resultMsg' },
    { label: '发起时间', prop: 'createdTime' },
    { label: '交易发起人', prop: 'userName' },
    { label: '交易金额', prop: 'amount' },
    { label: '付款账号', prop: 'payBankNumber' },
    { label: '收款账号', prop: 'receiveBankNumber' },
    { label: '交易状态', prop: 'status' }
  ]
  private filterTrade(val: any) {
    if (val === undefined || val === null) return
    let tradeStatusList: any = [
      {
        label: this.nowType == 1 ? '清算中' : '退款中',
        value: '0'
      },
      {
        label: '失败',
        value: '1'
      },
      {
        label: '成功',
        value: '2'
      },
      {
        label: '部分失败',
        value: '3'
      }
    ]
    const value = val.toString()
    return tradeStatusList.find((v: any) => v.value == value)?.label
  }
  private transactionInfo(rowData: any) {
    this.$emit('tradeDetails', rowData.id, rowData.status)
  }
}
