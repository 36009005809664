
import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from '@/components/Pagination/index.vue'
import * as areaApi from '@/api/area-config'
import timeFormat from '@/utils/timeFormat'

@Component({
  components: {
    Pagination
  }
})
export default class dictionaryIndexView extends Vue {
  public search: any = {
    pageNumber: 1,
    pageSize: 10,
    total: 0
  }
  public list: Array<any> = []
  doneDisable(row: any) {
    let text: string = '确定禁用该条数据'
    if (row.isDisable) {
      text = '确定启用该条数据'
    }
    this.$confirm(text).then((_) => {
      areaApi
        .doneDisable({
          id: row.id,
          isDisable: !row.isDisable
        })
        .then((data) => {
          const responseData = data.data
          if (responseData.code === 1000) {
            this.doSearch()
          }
        })
    })
  }
  deleteRow(row: any) {
    this.$confirm('确定删除该条数据').then((_) => {
      areaApi
        .deleteData({
          id: row.id
        })
        .then((data) => {
          const responseData = data.data
          if (responseData.code === 1000) {
            this.doSearch()
          }
        })
    })
  }
  skipToAddPage(isUpdate: Boolean, params: any) {
    const newObject: any = { ...params }
    newObject.isUpdate = isUpdate ? '1' : '0'
    newObject.saveModel = params
    this.$router.push({ name: 'AreaInsert', params: newObject })
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'AreaInsert' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.search = pageParams.search
      }
      vim.doSearch()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'AreaInsert') {
      let pageParams: any = {
        search: this.search
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  doSearch() {
    let params: any = {
      ...this.search
    }
    delete params.total
    areaApi
      .page(params)
      .then((data) => {
        const responseData = data.data
        this.list = responseData.data
        this.search.total = responseData.total
      })
      .catch((err) => {})
  }
}
