var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pubUpload" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            disabled: _vm.disabled,
            action: _vm.uploadFileUrl,
            accept: _vm.accept,
            "on-success": _vm.handleUploadSuccess,
            "on-error": _vm.handleUploadError,
            "before-upload": _vm.beforeUpload,
            "show-file-list": false,
            headers: _vm.myHeaders
          }
        },
        [
          _vm.tooltipObj
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.tooltipObj.text,
                    placement: _vm.tooltipObj.placement
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingFlag,
                          expression: "loadingFlag"
                        }
                      ],
                      staticClass: "global-dotted-btn vertical-bottom",
                      class: { isdisabled: _vm.disabled },
                      attrs: { size: "small" }
                    },
                    [_vm._v(_vm._s(_vm.btnText))]
                  )
                ],
                1
              )
            : _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingFlag,
                      expression: "loadingFlag"
                    }
                  ],
                  staticClass: "global-dotted-btn vertical-bottom",
                  class: { isdisabled: _vm.disabled },
                  attrs: { size: "small" }
                },
                [_vm._v(_vm._s(_vm.btnText))]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }