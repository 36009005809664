var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogTableVisible,
            width: "800px",
            center: _vm.center,
            "close-on-click-modal": _vm.isOrModalClose,
            "before-close": _vm.handleDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formStyle",
              attrs: { model: _vm.formData, rules: _vm.rules, size: "small" }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "inputBox",
                  attrs: { label: "栏目名称：", prop: "colName" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      placeholder: "请输入栏目名称",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.columnName,
                      callback: function($$v) {
                        _vm.columnName = $$v
                      },
                      expression: "columnName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "字段:", prop: "valueType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字段" },
                      on: { change: _vm.handleFieldOptions },
                      model: {
                        value: _vm.formData.valueType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "valueType", $$v)
                        },
                        expression: "formData.valueType"
                      }
                    },
                    _vm._l(_vm.fieldOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inputBox",
                  attrs: { label: "字段名称：", prop: "colName" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: {
                      size: "small",
                      disabled: _vm.disableds,
                      placeholder: "请输入字段名称"
                    },
                    model: {
                      value: _vm.formData.colName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "colName", $$v)
                      },
                      expression: "formData.colName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否必填：", prop: "isRequired" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.isRequired,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "isRequired", $$v)
                        },
                        expression: "formData.isRequired"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.formData.valueType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "贷款条件：", prop: "loanLimit" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.loanLimit,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "loanLimit", $$v)
                            },
                            expression: "formData.loanLimit"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是")
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.valueType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "字段类型：", prop: "colType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择字段类型" },
                          model: {
                            value: _vm.formData.colType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "colType", $$v)
                            },
                            expression: "formData.colType"
                          }
                        },
                        _vm._l(_vm.fieldTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.valueType === 1 && _vm.formData.colType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.addOption }
                        },
                        [_vm._v("添加选项")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                data: _vm.formData.itemSaveRequestList,
                                "show-header": false
                              }
                            },
                            [
                              _c(
                                "el-table-column",
                                { attrs: { label: "选项", width: "60" } },
                                [
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("选项 ")
                                  ]
                                ],
                                2
                              ),
                              _c("el-table-column", {
                                attrs: { label: "姓名", width: "180" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.itemValue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "itemValue",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.itemValue"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1382249783
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", width: "60" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "text"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleDeleteOption(
                                                    scope.$index,
                                                    _vm.formData
                                                      .itemSaveRequestList
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  877746566
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("el-button", { on: { click: _vm.cancelDialog } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDialog("formData")
                        }
                      }
                    },
                    [_vm._v("提 交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }