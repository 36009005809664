var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "settlement-approval-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "相关方信息", name: "1" } },
            [
              _c("about-info", {
                attrs: {
                  info: _vm.proDetail.relatedPartyInfo,
                  checkboxValueShow: _vm.checkboxValueShow,
                  checkboxValue: _vm.checkboxValue.selectMap
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "结算明细", name: "2" } },
            [
              _c("settlement-info", {
                attrs: {
                  info: _vm.proDetail.settlementInfo,
                  checkboxValueShow: _vm.checkboxValueShow,
                  checkboxValue: _vm.checkboxValue.selectMap
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "申请材料", name: "3" } },
            [
              _c("application-materials", {
                attrs: {
                  info: _vm.proDetail.files,
                  checkboxValue: _vm.checkboxValue.files,
                  checkboxValueShow: _vm.fileCheckboxValueShow
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "审批信息", name: "4" } },
            [
              _c("approval-info", {
                attrs: { info: _vm.proDetail.approvalInfo, isApprovaling: true }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName == "4"
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt(2)
                    }
                  }
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt(1)
                    }
                  }
                },
                [_vm._v("通过")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }