
import { Component, Vue } from 'vue-property-decorator'
import { getCollectionFeeConfig, saveCollectionFeeConfig } from '@/api/systemConfig'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class CollectionFeeConfig extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private isSumbit: boolean = false
  private itemList: any = []
  private bankCardOptions: any = []
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  created() {
    this.systemType == 'zct' &&
      getCollectionFeeConfig({})
        .then((res) => {
          let data: any = res.data.data
          if (data) {
            this.itemList = data.feeDetails ? data.feeDetails : []
            if (data.bankCards && data.bankCards.length) {
              this.bankCardOptions = data.bankCards
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
  }
  private getBankCardIdTail(stratVal: any, cardId: any) {
    let returnVal: string = ''
    if (stratVal) {
      returnVal = stratVal
      if (cardId) {
        let cardIdArr: any = cardId.split('')
        returnVal += '（' + (cardIdArr.length >= 4 ? cardId.slice(cardIdArr.length - 4) : cardId) + '）'
      } else {
        returnVal += '()'
      }
    }
    return returnVal
  }
  private getFeedItems(bankCardId: any) {
    if (!bankCardId || !this.bankCardOptions.length) {
      return []
    } else {
      for (let i = 0; i < this.bankCardOptions.length; i++) {
        if (this.bankCardOptions[i].bankCardId == bankCardId) {
          return this.bankCardOptions[i].feeItems ? this.bankCardOptions[i].feeItems : []
        }
      }
    }
  }
  private selecttedbankCard(val: any, index: number) {
    this.itemList[index].bankBussinessId = ''
  }
  private verificationFn() {
    let returnFlag: boolean = true
    for (let i = 0; i < this.itemList.length; i++) {
      if (!this.itemList[i].bankCardId || !this.itemList[i].bankBussinessId) {
        returnFlag = false
        break
      }
    }
    return returnFlag
  }
  private saveFn() {
    this.$confirm('确认保存？')
      .then(() => {
        this.loading = true
        this.isSumbit = true
        if (!this.verificationFn()) {
          this.$message.error('有选项没有选择！！')
          this.loading = false
          return
        } else {
          saveCollectionFeeConfig({ feeDetails: this.itemList })
            .then(() => {
              this.$message.success('保存成功！！')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
      .catch(() => {})
  }
}
