
import { Component, Vue } from 'vue-property-decorator'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getBusinessTypeDetail, businessTypeAdd, businessTypeUpdate } from '@/api/systemConfig'

@Component({})
export default class BusinessTypeDetail extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    properties: '1',
    type: '',
    fieldList: []
  }
  private list: any = [{ label: '农村土地' }, { label: '林权' }, { label: '涉农企业股权' }, { label: '实物资产' }, { label: '农业知识产权' }, { label: '农业经济事项' }]
  private loading: boolean = false

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      if (to.params.id == 'add') {
        to.meta.title = '新增业务类型配置'
      } else {
        to.meta.title = '业务类型配置详情'
        vim.getDetail()
      }
    })
  }

  private getDetail() {
    let params = {
      id: this.$route.params.id
    }
    this.loading = true
    getBusinessTypeDetail(params)
      .then((res) => {
        res.data.data.fieldList.map((item: any) => {
          item.required = item.required == 1 ? true : false
        })
        this.formInfo = res.data.data
        this.formInfo.properties = `${this.formInfo.properties}`
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleAdd() {
    let data = {
      field: '',
      required: false
    }
    this.formInfo.fieldList.splice(this.formInfo.fieldList.length, 0, data)
  }
  private handleDelete(index: number) {
    this.formInfo.fieldList.splice(index, 1)
  }
  private goBack() {
    this.$router.go(-1)
  }
  private handleSubmit(type: string) {
    let params = JSON.parse(JSON.stringify(this.formInfo))
    if (!params.properties) {
      return this.$message.error('请选择性质')
    } else if (!params.type) {
      return this.$message.error('业务类型不能为空')
    }
    // else if (params.fieldList.length < 1) {
    //   return this.$message.error('字段不能为空')
    // }
    let msg = false
    params.fieldList.length &&
      params.fieldList.map((item: any) => {
        if (!item.field) {
          msg = true
        }
        item.required = item.required ? 1 : 0
        item.businessTypeConfigId && delete item.businessTypeConfigId
        item.id && delete item.id
      })
    params.createdTime && delete params.createdTime
    if (msg) {
      return this.$message.error('字段不能为空')
    }
    if (type == 'add') {
      this.loading = true
      businessTypeAdd(params)
        .then((res) => {
          this.$message.success('提交成功')
          this.goBack()
        })
        .catch(() => {
          this.loading = false
        })
    } else {
      params.id = this.$route.params.id
      this.loading = true
      businessTypeUpdate(params)
        .then((res) => {
          this.$message.success('修改成功')
          this.goBack()
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
