
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import formDialog from './component/formDialog.vue'
import { Loading } from 'element-ui'
import {
  checkBankCardInfo,
  deleteBankCardInfo,
  // updateOrganizationOrNode,
  updateOrganizationOrNode2
} from '@/api/center'
import {
  // getOrganizationDetail,
  getOrganizationDetail2
} from '@/api/login'
import bankCardImg from '@/assets/images/login_bg.png'
import ChinaBankCardImg from '@/assets/images/zh_icon.png'
@Component({
  components: {
    formDialog
  },
  filters: {
    filtersBussinessType(data: any) {
      switch (data) {
        case 1:
          return '实时付款'
          break
        case 2:
          return '实时收款'
          break
        case 3:
          return '批量代付'
          break
        case 4:
          return '批量代收'
          break
      }
    },
    filterString(data: any) {
      return data.substring(data.length - 4)
    }
  }
})
export default class BankCard extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private loadingInstance: any
  private rules: any = {}
  private formData: any = {}
  private formDatas: any = {}
  private formDatass: any = []
  private dialogTableVisible: boolean = false
  private institutionNumber = ''
  private nodeNumber = ''
  private isShowForm: boolean = false
  private flag: any = ''
  private bankCardList: any = []
  private isOrModalClose: boolean = false
  private center: boolean = true
  private title: string = ''
  // private isModifyData: boolean = true
  private isModify: boolean = true
  // -------------------------------------------------data-----end---------------------------------------
  private created() {
    this.loadingInstance = Loading
    this.handleBankCardInfo()
    this.handleOrganizationDetail()
  }
  // ------------------------------------vue--周期方法------------------end------------------------------
  private handleOrganizationDetail() {
    getOrganizationDetail2({}).then((res) => {
      // getOrganizationDetail({}).then((res) => {
      // if (res.data.data.company) {
      //   this.institutionNumber = res.data.data.company.organizationCode || '无'
      //   this.nodeNumber = res.data.data.company.nodeCode || '无'
      if (res.data.data) {
        this.institutionNumber = res.data.data.organizationCode || ''
        this.nodeNumber = res.data.data.nodeCode || ''
        // this.isModifyData = true
        // this.isModify = true
      } else {
        this.institutionNumber = ''
        this.nodeNumber = ''
        // (this.isModifyData = false)
        // this.isModify = true
      }
    })
  }
  private openFormDialog() {
    if (!this.institutionNumber || !this.nodeNumber) {
      this.$message.error('请添加机构号和节点号再来添加银行卡！！')
      return
    }
    this.dialogTableVisible = true
    this.$nextTick(() => {
      this.flag = 'add'
      this.formDatas = {}
      this.formDatass = [
        {
          bussinessType: '',
          feeItem: '',
          feeCode: '',
          contractCode: '',
          feeName: ''
        }
      ]
    })
  }
  private modifyDialog() {
    // if (this.isModifyData) {
    this.isModify = false
    // } else {
    //   this.$message.warning('无法修改')
    // }
  }
  private saveData() {
    if (!this.institutionNumber) {
      this.$message.warning('机构号不能为空')
      return
    }
    if (!this.nodeNumber) {
      this.$message.warning('节点号不能为空')
      return
    }
    let data: any = {
        organizationCode: this.institutionNumber,
        nodeCode: this.nodeNumber
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    // updateOrganizationOrNode(data)
    updateOrganizationOrNode2(data)
      .then((res) => {
        // this.handleBankCardInfo()
        // this.handleOrganizationDetail()
        this.isModify = true
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private getBankCardInfo(data: any, index: any) {
    this.dialogTableVisible = true
    // this.$nextTick(() => {
    this.flag = 'edit'
    //  (this as ref)
    let datas: any = JSON.parse(JSON.stringify(data))
    delete datas.bankCardBussinessRels
    this.formDatas = datas
    this.formDatass = data.bankCardBussinessRels
    // })
  }
  private bankCardImg = bankCardImg
  private chinaBankCardImg = ChinaBankCardImg
  private handleCancelDialog() {
    this.dialogTableVisible = false
    this.handleBankCardInfo()
  }
  private handleCommand(id: any) {
    let data: object = {
        id
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    deleteBankCardInfo(data)
      .then((res) => {
        this.handleBankCardInfo()
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleBankCardInfo() {
    this.loading = true
    let data: any = {
      type: 2
    }
    checkBankCardInfo(data)
      .then((res) => {
        this.bankCardList = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
