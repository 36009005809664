var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "subsidy-detail" }, [
    _c("div", { staticClass: "info person" }, [
      _c(
        "div",
        [
          _c("div", { staticClass: "title" }, [_vm._v("申请人信息")]),
          _c(
            "el-form",
            [
              _c("el-form-item", { attrs: { label: "申请人姓名：" } }, [
                _vm._v(_vm._s(_vm.info.applyRealName))
              ]),
              _c("el-form-item", { attrs: { label: "申请人身份证号：" } }, [
                _vm._v(_vm._s(_vm.info.applyIdCardNumber))
              ]),
              _c("el-form-item", { attrs: { label: "申请人联系电话：" } }, [
                _vm._v(_vm._s(_vm.info.applyPhoneNumber))
              ]),
              _vm.info.applyAddress
                ? _c("el-form-item", { attrs: { label: "申请人家庭住址：" } }, [
                    _vm._v(_vm._s(_vm.info.applyAddress))
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.info.proxyRealName
        ? _c(
            "div",
            [
              _c("div", { staticClass: "title" }, [_vm._v("代填人信息")]),
              _c(
                "el-form",
                [
                  _c("el-form-item", { attrs: { label: "代填人姓名：" } }, [
                    _vm._v(_vm._s(_vm.info.proxyRealName))
                  ]),
                  _c("el-form-item", { attrs: { label: "代填人身份证号：" } }, [
                    _vm._v(_vm._s(_vm.info.proxyIdCardNumber))
                  ]),
                  _c("el-form-item", { attrs: { label: "代填人联系电话：" } }, [
                    _vm._v(_vm._s(_vm.info.proxyPhoneNumber))
                  ]),
                  _c("el-form-item", { attrs: { label: "代填人家庭住址：" } }, [
                    _vm._v(_vm._s(_vm.info.proxyAddress))
                  ]),
                  _c("el-form-item", { attrs: { label: "与申请人关系：" } }, [
                    _vm._v(_vm._s(_vm.info.proxyRelationApply))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("补贴信息")]),
        _c(
          "el-form",
          { staticClass: "form-style", attrs: { size: "small" } },
          [
            _c("el-form-item", { attrs: { label: "补贴金额（元）：" } }, [
              _vm._v(_vm._s(_vm.info.subsidyAmount / 100))
            ]),
            _c("el-form-item", { attrs: { label: "补贴项目：" } }, [
              _vm._v(_vm._s(_vm.info.subsidyProject))
            ]),
            _vm.$route.params.type != "3"
              ? _c("el-form-item", { attrs: { label: "备注：" } }, [
                  _vm._v(_vm._s(_vm.info.subsidyExamineRemarks || "无"))
                ])
              : _vm._e(),
            _c("el-form-item", { attrs: { label: "状态：" } }, [
              _vm._v(
                _vm._s(
                  _vm.getStatus(
                    _vm.info.subsidyExamineStatus,
                    _vm.info.payTaskQueueStatus
                  )
                )
              )
            ]),
            _vm.$route.params.type == "3"
              ? _c("el-form-item", { attrs: { label: "所需资料" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "farmersInfo",
                      attrs: { "element-loading-text": _vm.loadingTxt }
                    },
                    _vm._l(_vm.tableData.columnList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list" },
                        [
                          _c("div", { staticClass: "list-title" }, [
                            _vm._v(_vm._s(item.columnName))
                          ]),
                          _c(
                            "el-table",
                            { attrs: { data: item.valueList } },
                            _vm._l(item.fieldList, function(list, num) {
                              return _c("el-table-column", {
                                key: num,
                                attrs: {
                                  label: list.fieldName,
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.valueData[num].value
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm.$route.params.index == 5
      ? _c(
          "div",
          { staticClass: "info" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("补贴发放信息")]),
            _c(
              "el-form",
              [
                _c("el-form-item", { attrs: { label: "发放金额（元）：" } }, [
                  _vm._v(_vm._s(_vm.grantInfo.transferAmount / 100))
                ]),
                _c("el-form-item", { attrs: { label: "发放银行：" } }, [
                  _vm._v(_vm._s(_vm.grantInfo.bankName))
                ]),
                _c("el-form-item", { attrs: { label: "发放银行账号：" } }, [
                  _vm._v(_vm._s(_vm.grantInfo.bankAccountNumber))
                ]),
                _c("el-form-item", { attrs: { label: "接收银行：" } }, [
                  _vm._v(_vm._s(_vm.grantInfo.applyBankName))
                ]),
                _c("el-form-item", { attrs: { label: "接收银行账号：" } }, [
                  _vm._v(_vm._s(_vm.grantInfo.applyBankAccountNumber))
                ]),
                _c("el-form-item", { attrs: { label: "发放时间：" } }, [
                  _vm._v(
                    _vm._s(_vm._f("formatTime")(_vm.grantInfo.createDatetime))
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.$route.params.index == 4 || _vm.info.subsidyExamineStatus == 2
      ? _c("div", { staticClass: "comment-box" }, [
          _c("div", { staticClass: "comment-title" }, [_vm._v("审批意见")]),
          _c(
            "div",
            { staticClass: "comment-content" },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6 },
                  resize: "none",
                  disabled: _vm.info.subsidyExamineStatus == 2
                },
                model: {
                  value: _vm.subsidyExamineRemarks,
                  callback: function($$v) {
                    _vm.subsidyExamineRemarks = $$v
                  },
                  expression: "subsidyExamineRemarks"
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "btn-style" },
      [
        _vm.$route.params.index == 4
          ? _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleRefuse(2)
                      }
                    }
                  },
                  [_vm._v("拒绝")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleSubmit(1)
                      }
                    }
                  },
                  [_vm._v("通过")]
                )
              ],
              1
            )
          : _c("el-button", { on: { click: _vm.handleReturn } }, [
              _vm._v("返回")
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }