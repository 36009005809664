var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "area-item" },
      [
        _c("span", [_vm._v("所属区域")]),
        _c(
          "el-select",
          {
            attrs: { disabled: _vm.isSuperAdmin != "1", size: "small" },
            model: {
              value: _vm.areaConfigId,
              callback: function($$v) {
                _vm.areaConfigId = $$v
              },
              expression: "areaConfigId"
            }
          },
          _vm._l(_vm.areaData, function(item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.areaName, value: item.areaId }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "contentBox",
        attrs: { "element-loading-text": _vm.loadingTxt }
      },
      _vm._l(_vm.dataList, function(item, index) {
        return _c("div", { key: index, staticClass: "innerBox" }, [
          _c(
            "div",
            {
              staticClass: "items",
              on: {
                click: function($event) {
                  return _vm.handleSetting(item)
                }
              }
            },
            [
              item.ruleName === "挂牌审批"
                ? _c("img", { attrs: { src: _vm.image.iconPretrial } })
                : item.ruleName === "中止挂牌审批"
                ? _c("img", { attrs: { src: _vm.image.iconFinalJudgment } })
                : item.ruleName === "保证金退款审批"
                ? _c("img", { attrs: { src: _vm.image.iconAcceptance } })
                : item.ruleName === "清算审批"
                ? _c("img", { attrs: { src: _vm.image.iconEstablish } })
                : item.ruleName === "资格审批"
                ? _c("img", { attrs: { src: _vm.image.iconPretrial } })
                : _c("img", { attrs: { src: _vm.image.iconPretrial } }),
              _c("span", { staticClass: "names" }, [
                _vm._v(_vm._s(item.ruleName))
              ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }