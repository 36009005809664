
import { Component, Vue } from 'vue-property-decorator'
import DialogEdit from '@/views/peasant-info-management/info-template-config/components/DialogEdit.vue'
import ItemColumn from './components/itemColumn.vue'
import { Loading } from 'element-ui'
import { getColumnInfolList, submitColumnInfo } from '@/api/peasantInfoManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    DialogEdit,
    ItemColumn
  },
  filters: {}
})
export default class InfoTemplateConfig extends Vue {
  private loadingTxt: string = loadingText
  private loading = false
  private activeName = '0'
  private isShow = false
  private tabPane: any = []
  private loadingInstance: any
  // 配置弹框信息和所需传递的参数
  private columnOption: any = {
    title: '新增栏目'
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    // flag: '1'
  }
  // ------------------------------------------------------data---end---------------------------------------------------
  private created() {
    this.loadingInstance = Loading
    this.handleGetColumnList()
  }
  private handleGetColumnList() {
    this.loading = true
    getColumnInfolList({})
      .then((res) => {
        this.tabPane = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleAddcolumn() {
    this.isShow = true
  }
  private handleCancelDialog() {
    this.isShow = false
  }
  private handleConfirmData(value: any) {
    // columnName: '',
    // columnSort: '',
    // isRequired: 0,
    // contentIsAdd: 0
    let data: any = {
        name: value.formData.name,
        sort: value.formData.sort,
        isRequired: value.formData.isRequired,
        contentIsAdd: value.formData.contentIsAdd,
        isRuralLoan: value.formData.isRuralLoan
      },
      loadingInstance = this.loadingInstance.service({ target: document.body, text: this.loadingTxt })
    submitColumnInfo(data)
      .then((res) => {
        this.handleGetColumnList()
        this.handleCancelDialog()
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private updateList(isDeletedCloumn: boolean = false) {
    if (isDeletedCloumn) {
      this.activeName = '0'
    }
    this.handleGetColumnList()
  }
}
