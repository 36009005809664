var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingContent,
          expression: "loadingContent"
        }
      ],
      staticClass: "authentication-application-detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "part" }, [
        _vm._m(0),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("标的类型")]),
            _c("span", [_vm._v(_vm._s(_vm.bTypeVal(_vm.info.businessType)))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("标的名称")]),
            _c(
              "span",
              { staticClass: "a-span", on: { click: _vm.intoDetails } },
              [_vm._v(_vm._s(_vm.info.listingApplyName))]
            )
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("所属区域")]),
            _c("span", [_vm._v(_vm._s(_vm.info.areaConfigName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("鉴证书申请人")]),
            _c("span", [_vm._v(_vm._s(_vm.info.zctUserName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("申请人类型")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.getOptionsVal(
                    _vm.info.applyPersonType,
                    "applyPersonTypeOptions"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("申请版本")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.getOptionsVal(
                    _vm.info.applyVersion,
                    "applyVersionOptions"
                  )
                )
              )
            ])
          ]),
          _vm.info.applyVersion == "2"
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("收件人姓名")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.receiverName))])
              ])
            : _vm._e(),
          _vm.info.applyVersion == "2"
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("收件人电话")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.receiverPhone))])
              ])
            : _vm._e(),
          _vm.info.applyVersion == "2"
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("收件人地址")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.receiverAddress))])
              ])
            : _vm._e(),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("申请时间")]),
            _c("span", [_vm._v(_vm._s(_vm.info.applyTime))])
          ])
        ])
      ]),
      _c("div", { staticClass: "part" }, [
        _vm._m(1),
        _vm.info
          ? _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "item-left" }, [
                  _vm.info.status == "0"
                    ? _c("span", { staticClass: "title-dot" }, [_vm._v("*")])
                    : _vm._e(),
                  _vm._v("鉴证书")
                ]),
                _c("div", [
                  _vm.info.status == "0" && !_vm.formInfo.certificateUrl
                    ? _c(
                        "div",
                        {
                          staticClass: "up-files",
                          style: {
                            "background-image": "url(" + _vm.bgImg + ")"
                          }
                        },
                        [
                          _c("UploadBase", {
                            attrs: {
                              listShow: false,
                              uploadOption: _vm.uploadOption
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOption = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOption = $event
                              },
                              fileUrl: _vm.getFileUrl
                            }
                          })
                        ],
                        1
                      )
                    : _vm.info.status == "0" && _vm.formInfo.certificateUrl
                    ? _c("div", { staticClass: "show-file" }, [
                        _c(
                          "div",
                          {
                            staticClass: "files-box",
                            style: {
                              "background-image": "url(" + _vm.filesImg + ")"
                            }
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  href: _vm.formInfo.certificateUrl,
                                  target: "_blank",
                                  underline: false
                                }
                              },
                              [_vm._v("鉴证书")]
                            )
                          ],
                          1
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.info.listingApplyName) + "交易鉴证书.PDF"
                          )
                        ])
                      ])
                    : _vm.info.status != "0" && _vm.info.certificateUrl
                    ? _c("div", { staticClass: "show-file" }, [
                        _c(
                          "div",
                          {
                            staticClass: "files-box",
                            style: {
                              "background-image": "url(" + _vm.filesImg + ")"
                            }
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  href: _vm.info.certificateUrl,
                                  target: "_blank",
                                  underline: false
                                }
                              },
                              [_vm._v("鉴证书")]
                            )
                          ],
                          1
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.info.listingApplyName) + "交易鉴证书.PDF"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "tip" }, [
                    _vm._v("支持pdf,png,jpg,jpeg格式，文件大小不超过5M")
                  ])
                ])
              ]),
              _vm.info.applyVersion == "2"
                ? _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "item-left" }, [
                      _vm.info.status == "0"
                        ? _c("span", { staticClass: "title-dot" }, [
                            _vm._v("*")
                          ])
                        : _vm._e(),
                      _vm._v("快递单号")
                    ]),
                    _c(
                      "div",
                      [
                        _vm.info.status == "0"
                          ? _c("el-input", {
                              attrs: { size: "small", placeholder: "请输入" },
                              model: {
                                value: _vm.formInfo.expressNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.formInfo, "expressNumber", $$v)
                                },
                                expression: "formInfo.expressNumber"
                              }
                            })
                          : _c("span", [_vm._v(_vm._s(_vm.info.expressNumber))])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm.info.status != "0" && _vm.info.status != "1"
        ? _c("div", { staticClass: "part" }, [
            _vm._m(2),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("贷款银行")]),
                _c("span", [_vm._v(_vm._s(_vm.info.loanBank))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("贷款额度(万)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.approvalAmount))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("贷款期限(年)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.deadline))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("贷款利率(%)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.interestRate))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("还款方式")]),
                _c("span", [_vm._v(_vm._s(_vm.repaymentDesc))])
              ]),
              _vm.info.status == "2" || _vm.info.status == "3"
                ? _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-left" }, [
                      _vm._v("贷款通过时间")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.info.approvedCreatedTime))])
                  ])
                : _vm._e(),
              _vm.info.status == "4"
                ? _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-left" }, [
                      _vm._v("申请时间")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.info.approvalCreatedTime))])
                  ])
                : _vm._e(),
              _vm.info.status == "3" || _vm.info.status == "4"
                ? _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "item-left" }, [
                      _vm._v("贷款还清证明")
                    ]),
                    _c("div", [
                      _vm.info.mortgageSettleProof &&
                      _vm.info.mortgageSettleProof.proofUrl
                        ? _c("div", { staticClass: "show-file" }, [
                            _c(
                              "div",
                              {
                                staticClass: "files-box",
                                style: {
                                  "background-image":
                                    "url(" + _vm.filesImg + ")"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      href:
                                        _vm.info.mortgageSettleProof.proofUrl,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v("证明")]
                                )
                              ],
                              1
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.mortgageSettleProof.proofName +
                                    ".PDF"
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.info.status == "3" || _vm.info.status == "4"
                ? _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "item-left" }, [
                      _vm.info.status == "3"
                        ? _c("span", { staticClass: "title-dot" }, [
                            _vm._v("*")
                          ])
                        : _vm._e(),
                      _vm._v("解押通知书")
                    ]),
                    _c("div", [
                      _vm.info.status == "3" && !_vm.formInfo.fileAddr
                        ? _c(
                            "div",
                            {
                              staticClass: "up-files",
                              style: {
                                "background-image": "url(" + _vm.bgImg + ")"
                              }
                            },
                            [
                              _c("UploadBase", {
                                attrs: {
                                  listShow: false,
                                  uploadOption: _vm.uploadOption
                                },
                                on: {
                                  "update:uploadOption": function($event) {
                                    _vm.uploadOption = $event
                                  },
                                  "update:upload-option": function($event) {
                                    _vm.uploadOption = $event
                                  },
                                  fileUrl: _vm.getFileUrl
                                }
                              })
                            ],
                            1
                          )
                        : _vm.info.status == "3" && _vm.formInfo.fileAddr
                        ? _c("div", { staticClass: "show-file" }, [
                            _c(
                              "div",
                              {
                                staticClass: "files-box",
                                style: {
                                  "background-image":
                                    "url(" + _vm.filesImg + ")"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      href: _vm.formInfo.fileAddr,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v("通知书")]
                                )
                              ],
                              1
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.info.listingApplyName) +
                                  "解押通知书.PDf"
                              )
                            ])
                          ])
                        : _vm.info.status == "4" &&
                          _vm.info.mortgageNotificProof &&
                          _vm.info.mortgageNotificProof.proofUrl
                        ? _c("div", { staticClass: "show-file" }, [
                            _c(
                              "div",
                              {
                                staticClass: "files-box",
                                style: {
                                  "background-image":
                                    "url(" + _vm.filesImg + ")"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      href:
                                        _vm.info.mortgageNotificProof.proofUrl,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v("通知书")]
                                )
                              ],
                              1
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.info.mortgageNotificProof.proofName +
                                    ".PDF"
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: { click: _vm.returnPaper }
            },
            [_vm._v("返回")]
          ),
          _vm.getInfoLength &&
          (_vm.info.status == "0" || _vm.info.status == "3")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.subimtFn }
                },
                [_vm._v("提交")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("申请信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("鉴证书发放")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("抵押贷款")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }