var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "need-data" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.ruleType == "16" ? "相关资料" : "所需资料"))
        ]),
        _vm.checkboxShow && _vm.processStatus === 0
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "content",
          attrs: { "element-loading-text": _vm.loadingTxt }
        },
        [
          _vm.info
            ? _c(
                "div",
                _vm._l(_vm.info, function(item, index) {
                  return _c("div", { key: index }, [
                    item.fileUrl && item.fileUrl.length == 1
                      ? _c(
                          "div",
                          { staticClass: "item flex-item align-c" },
                          [
                            _vm.checkboxShow && _vm.processStatus === 0
                              ? _c("el-checkbox", {
                                  model: {
                                    value: _vm.itemStatus[index],
                                    callback: function($$v) {
                                      _vm.$set(_vm.itemStatus, index, $$v)
                                    },
                                    expression: "itemStatus[index]"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  href: item.fileUrl[0],
                                  target: "_blank",
                                  download: item.fileName,
                                  underline: false
                                }
                              },
                              [_vm._v(_vm._s(item.fileName))]
                            ),
                            _vm.ruleType != "13"
                              ? _c(
                                  "div",
                                  { staticClass: "switch-div" },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: _vm.processStatus !== 0
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleSwitch(
                                            $event,
                                            item.fileId,
                                            index,
                                            0
                                          )
                                        }
                                      },
                                      model: {
                                        value: item.isShow,
                                        callback: function($$v) {
                                          _vm.$set(item, "isShow", $$v)
                                        },
                                        expression: "item.isShow"
                                      }
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.isShow
                                            ? "前端展示"
                                            : "前端不展示"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    item.fileUrl && item.fileUrl.length > 1
                      ? _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-item align-c" },
                              [
                                _vm.checkboxShow && _vm.processStatus === 0
                                  ? _c("el-checkbox", {
                                      model: {
                                        value: _vm.itemStatus[index],
                                        callback: function($$v) {
                                          _vm.$set(_vm.itemStatus, index, $$v)
                                        },
                                        expression: "itemStatus[index]"
                                      }
                                    })
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.fileName +
                                        (item.fileName ? "(可多份)" : "")
                                    )
                                  )
                                ]),
                                _vm.ruleType != "13"
                                  ? _c(
                                      "div",
                                      { staticClass: "switch-div" },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            disabled: _vm.processStatus !== 0
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleSwitch(
                                                $event,
                                                item.fileId,
                                                index,
                                                0
                                              )
                                            }
                                          },
                                          model: {
                                            value: item.isShow,
                                            callback: function($$v) {
                                              _vm.$set(item, "isShow", $$v)
                                            },
                                            expression: "item.isShow"
                                          }
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.isShow
                                                ? "前端展示"
                                                : "前端不展示"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._l(item.fileUrl, function(ite, ind) {
                              return _c(
                                "div",
                                { key: ind, staticClass: "link-list" },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: ite,
                                        target: "_blank",
                                        download: item.fileName,
                                        underline: false
                                      }
                                    },
                                    [_vm._v(_vm._s(item.fileName))]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.otherFileList
            ? _c(
                "div",
                _vm._l(_vm.otherFileList, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "item flex-item align-c" },
                      [
                        _vm.checkboxShow && _vm.processStatus === 0
                          ? _c("el-checkbox", {
                              model: {
                                value: _vm.otherStatus[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.otherStatus, index, $$v)
                                },
                                expression: "otherStatus[index]"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: item.fileUrl,
                              target: "_blank",
                              download: item.fileName,
                              underline: false
                            }
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        ),
                        _vm.ruleType != "13"
                          ? _c(
                              "div",
                              { staticClass: "switch-div" },
                              [
                                _c("el-switch", {
                                  attrs: { disabled: _vm.processStatus !== 0 },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleSwitch(
                                        $event,
                                        item.fileId,
                                        index,
                                        item.type == 2 ? 3 : 2
                                      )
                                    }
                                  },
                                  model: {
                                    value: item.isShow,
                                    callback: function($$v) {
                                      _vm.$set(item, "isShow", $$v)
                                    },
                                    expression: "item.isShow"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.isShow ? "前端展示" : "前端不展示"
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.ruleType != "13" &&
                        _vm.ruleType != "16" &&
                        item.type == 2
                          ? _c(
                              "span",
                              {
                                staticClass: "delete-file",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteFile(item.fileId, index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("upload-base", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            attrs: {
              showSuccessTip: false,
              id: "needfiles-btn",
              listShow: false,
              uploadOption: _vm.uploadOption
            },
            on: {
              fileUrl: _vm.getFileUrl,
              beforeUpFn: _vm.beforeUpFn,
              uploadError: _vm.uploadError
            }
          }),
          _vm.isUpdateFiles
            ? _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn vertical-bottom",
                  staticStyle: { "margin-left": "130px" },
                  attrs: { size: "small" },
                  on: { click: _vm.updateFiles }
                },
                [_vm._v("上传文件")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }