
import { Component, Vue } from 'vue-property-decorator'
import { getVillagePermission, VillageDetail, VillageSubmit, cancelvillage } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class VillageCreditDetails extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private villageOptions: any = []
  private rules = {
    province: { required: true, message: '请输入', trigger: 'blur' },
    villageBadLoanBalance: { required: true, message: '请输入', trigger: 'blur' },
    isSetFinancial: { required: true, message: '请选择', trigger: 'blur' },
    isHonesty: { required: true, message: '请选择', trigger: 'blur' },
    isCareFinancial: { required: true, message: '请选择', trigger: 'blur' },
    isBlacklist: { required: true, message: '请选择', trigger: 'blur' },
    blacklistRate: { required: true, message: '请选择', trigger: 'blur' }
  }
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    contactsName: '',
    contactPhone: '',
    villageFarmerNumber: '',
    villageBadLoanBalance: '',
    alreadyCollectedFarmerNumber: '',
    badCreditFarmerRate: '',
    creditFarmerInFarmerRate: '',
    creditInCollectedFarmerRate: '',
    farmerCreditEntryRate: '',
    id: '',
    blacklistRate: '',
    isBlacklist: 0,
    isCareFinancial: 0,
    isHonesty: 0,
    isSetFinancial: 0,
    isShow: 0,
    scheduleBackInGetFarmerRate: '',
    scheduleBackLoanFarmerNumber: '',
    shouldCollectedFarmerNumber: '',
    villageGetLoanFarmerNumber: '',
    villageId: '',
    villageLoanBalance: '',
    villageStatus: 0, //1-未评定 2-评定中 3-信用区 4-非信用
    creditAssessGroupOpinionResponse: []
  }
  private loading = false
  private detailShow: boolean = false
  private isEdit: boolean = false
  created() {
    if (this.$route.params.id != '0') {
      this.isEdit = true
      this.formInfo.villageId = this.$route.params.id
      this.getDetailData(this.$route.params.id)
    } else {
      this.isEdit = false
      this.getProvince()
    }
  }
  private changeInput() {
    if (Number(this.formInfo.villageBadLoanBalance) > this.formInfo.villageLoanBalance) {
      this.$message.error('农户不良贷款余额不能大于农户贷款余额')
      this.formInfo.villageBadLoanBalance = 0
      return
    }
    if (!Number(this.formInfo.villageBadLoanBalance)) {
      this.formInfo.badCreditFarmerRate = 0
    } else {
      this.formInfo.badCreditFarmerRate = Number(((Number(this.formInfo.villageBadLoanBalance) / this.formInfo.villageLoanBalance) * 100).toFixed(2))
    }
  }
  private submitInfos() {
    this.$confirm('是否确认取消该行政区域的信用称号？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        cancelvillage({ villagePermissionId: this.$route.params.id })
          .then((res: any) => {
            if (res.data.code == 1000) {
              this.$message.success('摘牌申请已提交')
              setTimeout(() => {
                this.$router.push({ name: 'VillageCreditList' })
              }, 100)
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
  private checkOption(list: Array<any>) {
    return (
      list &&
      list.every((item) => {
        if (!item.opinion || !item.time) {
          this.$message.error(`请将${item.groupName}的相关意见和时间信息完善`)
          return false
        } else {
          return true
        }
      })
    )
  }
  private submitInfo() {
    ;(this.$refs.formInfoRef as any).validate((v: boolean) => {
      if (v) {
        if (this.formInfo.isBlacklist == 1 && !this.formInfo.blacklistRate) {
          this.$message.error('请输入农户被列入行业管理严重失信名单情况(占比)')
          return
        }
        let optionArr = this.checkOption(this.formInfo.creditAssessGroupOpinionResponse)
        if (optionArr) {
          this.loading = true
          VillageSubmit(this.formInfo)
            .then((res: any) => {
              if (res.data.code == 1000) {
                this.$message.success('提交成功')
                setTimeout(() => {
                  this.$router.push({ name: 'VillageCreditList' })
                }, 100)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      } else {
        this.$message.error('请完善输入内容后再提交')
      }
    })
  }
  private getDetailData(id: any) {
    this.loading = true
    VillageDetail({ villageId: id, functionType: 1, showType: this.$route.params.id ? 0 : 1 })
      .then((res) => {
        if (res.data.code == 1000) {
          this.detailShow = true
          this.formInfo = res.data.data
          this.formInfo.villageId = id
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getProvince() {
    this.loading = true
    getVillagePermission({ type: 1 })
      .then((res) => {
        if (res.data.code == 1000) {
          this.provinceOptions = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private selecttedProvince(value: any) {
    if (value) {
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.villageOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      this.formInfo.villageName = ''
      getVillagePermission({ type: 1, province: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //城市
  private selecttedCity(value: any) {
    if (value) {
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      this.villageOptions = []
      this.formInfo.villageName = ''
      getVillagePermission({ type: 1, province: this.formInfo.province, city: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //区县
  private selecttedCounty(value: any) {
    if (value) {
      this.townshipOptions = []
      this.formInfo.township = ''
      this.villageOptions = []
      this.formInfo.villageName = ''
      getVillagePermission({
        type: 1,
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: value
      })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //乡镇
  private selecttedTownship(value: any) {
    if (value) {
      this.villageOptions = []
      this.formInfo.villageName = ''
      getVillagePermission({
        type: 1,
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: this.formInfo.county,
        township: value
      })
        .then((res) => {
          if (res.data.code == 1000) {
            this.villageOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //村组
  private selecttedVillage(value: any) {
    if (value) {
      this.getDetailData(value)
    }
  }
  private changeCode(name: any) {
    this.$nextTick(() => {
      if (this.formInfo[`${name}`] != null) {
        this.formInfo[`${name}`] = this.formInfo[`${name}`].replace(/[^\d.]/g, '')
      }
    })
  }
}
