var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "subsidy-add-type" }, [
    _c("div", { staticClass: "title" }, [_vm._v("请选择补贴发放方式")]),
    _c("div", { staticClass: "type" }, [
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.toSubsidyAdd(1)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/subsidy/subsidy-1.png") }
          }),
          _c("div", [
            _vm._v(
              "直接发放是指直接将补贴发放到农户银行账户中，无需后台审核，农户也无需任何操作。"
            )
          ]),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.toSubsidyAdd(2)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/subsidy/subsidy-2.png") }
          }),
          _c("div", [
            _vm._v(
              "确认信息发放是指后台录入农户基本信息后，需要农户确认信息是否有误，如果无误，补贴将直接发放到农户银行账户中。"
            )
          ]),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.toSubsidyAdd(3)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/subsidy/subsidy-3.png") }
          }),
          _c("div", [
            _vm._v(
              "采集信息发放是指后台录入农户基本信息后，需要农户确认信息无误后，还需要农户录入诸如家庭基本情况、家庭成员、家庭支出情况等信息后，由村组审核通过后 再由财政部门进行审核，审核通过后由财政部门进行发放。"
            )
          ]),
          _vm._m(2)
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "next" }, [
      _c("span", [_vm._v("直接发放")]),
      _c("img", { attrs: { src: require("@/assets/images/subsidy/next.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "next" }, [
      _c("span", [_vm._v("确认信息发放")]),
      _c("img", { attrs: { src: require("@/assets/images/subsidy/next.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "next" }, [
      _c("span", [_vm._v("采集信息发放")]),
      _c("img", { attrs: { src: require("@/assets/images/subsidy/next.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }