
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getVillageProvinceList, getVillageCityList, getVillageCountyList, getVillageTownshipList, getPendingApprovalList } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class PendingApprovalList extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    householder: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'PendingApprovalDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.provinceOptions.length) {
        getVillageProvinceList({}).then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'PendingApprovalDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCityList({ province: value })
        .then((res) => {
          if (res.data.data) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCountyList({ province: this.formInfo.province, city: value })
        .then((res) => {
          if (res.data.data) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getVillageTownshipList({
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: value
      })
        .then((res) => {
          if (res.data.data) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private async getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getPendingApprovalList(params).then((res) => {
      if (res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 搜索待审批列表
  private handleSearch() {
    this.getList()
  }
  // 查看待审批详情
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'PendingApprovalDetail',
      params: { approvalId: datas.approvalId, farmersInfoId: datas.farmersInfoId }
    })
  }
}
