
import { Component, Vue } from 'vue-property-decorator'
import { handleEnterpriseDetail } from '@/api/financing'
@Component({
  components: {}
})
export default class BaseInfo extends Vue {
  private loading: boolean = false
  private info: any = {}

  created() {
    this.getBaseInfo()
  }

  private getBaseInfo() {
    let params = {
      id: this.$route.params.id
    }
    this.loading = true
    handleEnterpriseDetail(params)
      .then((res) => {
        this.info = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
