var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settlement-aplmanagement-list" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "contentBox",
        attrs: { "element-loading-text": _vm.loadingTxt }
      },
      _vm._l(_vm.dataList, function(item, index) {
        return _c("div", { key: index, staticClass: "innerBox" }, [
          _c(
            "div",
            {
              staticClass: "items",
              on: {
                click: function($event) {
                  return _vm.handleSetting(item)
                }
              }
            },
            [
              _vm.imgUrlArr[index]
                ? _c("img", { attrs: { src: _vm.imgUrlArr[index] } })
                : _vm._e(),
              _c("span", { staticClass: "names" }, [
                _vm._v(_vm._s(item.ruleName))
              ])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }