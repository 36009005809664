
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getUnsetList } from '@/api/peasantInfoManagement'
@Component({
  components: {}
})
export default class TableColumn extends Vue {
  @Prop() info: any // 展示编辑数据
  @Prop({ default: false }) isControl?: boolean // 是否可编辑
  // 所属村组参数
  @Prop({
    default: function() {
      return {
        provinceOptions: [],
        cityOptions: [],
        countyOptions: [],
        townshipOptions: [],
        villageOptions: []
      }
    }
  })
  options?: any
  @Prop({ default: false }) needVillage?: boolean
  @Prop({ default: false }) isPdPreserve?: boolean
  @Prop({ default: false }) hasSum?: boolean
  @Prop({ default: true }) requestEnd?: boolean
  @Prop({ default: 0 }) columnIndex?: any

  // 所属村组选择项
  private fixedSelection: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: ''
  }
  private onlyOne: boolean = true
  private unsetOptions: any = {} // 不确定选项参数
  private checkVal: boolean = false // 不合格标志位
  private isEitd: any = [] // 可编辑标志位数组
  private isFirst: boolean = true // 是否第一次
  private loading: boolean = false // 加载标志位
  private total: any = ''
  private isAddRow: boolean = false
  private oldRowCountVal: any = {}
  private totalTile: any = ''
  private filterVal(val: any) {
    let returnVal = ''
    if (val) {
      returnVal = val.replace(/\//g, '')
    }
    return returnVal
  }
  // 选择省
  private selecttedProvince(value: any, index: any, row: any) {
    if (value && this.requestEnd) {
      this.$emit('update:requestEnd', false)
      this.options.cityOptions = []
      this.options.countyOptions = []
      this.options.townshipOptions = []
      this.fixedSelection.city = ''
      this.fixedSelection.county = ''
      this.fixedSelection.township = ''
      this.$emit('selecttedProvince', value)
    }
  }
  // 选择城市
  private selecttedCity(value: any, index: any, row: any) {
    if (value && this.requestEnd) {
      this.$emit('update:requestEnd', false)
      this.options.countyOptions = []
      this.options.townshipOptions = []
      this.fixedSelection.county = ''
      this.fixedSelection.township = ''
      this.$emit('selecttedCity', this.fixedSelection.province, value)
    }
  }
  // 选择区
  private selecttedCounty(value: any, index: any, row: any) {
    if (value && this.requestEnd) {
      this.$emit('update:requestEnd', false)
      this.options.townshipOptions = []
      this.fixedSelection.township = ''
      this.$emit('selecttedCounty', this.fixedSelection.province, this.fixedSelection.city, value)
    }
  }
  // 选择街
  private selecttedTownship(value: any, index: any, row: any) {
    if (value && this.requestEnd) {
      this.$emit('update:requestEnd', false)
      this.$emit('selecttedTownship', this.fixedSelection)
    }
  }
  // 选择村组
  private selecttedVillage(value: any, index: any, row: any) {
    if (value) {
      let selectV = this.fixedSelection
      row.valueData[index].value =
        selectV.province +
        '/' +
        selectV.city +
        '/' +
        selectV.county +
        '/' +
        selectV.township +
        '/' +
        value.split(',')[1]
      this.$emit('selecttedVillage', value.split(',')[0])
    }
  }
  // 新增一行数据
  private addOneInfo() {
    this.onlyOne = false
    this.isAddRow = true
    if (!this.info.fieldList || (this.info.fieldList && !this.info.fieldList.length)) {
      return
    }
    let newValueList: any = {
        rowId: null,
        valueData: []
      },
      fieldList = this.info.fieldList
    for (let i = 0; i < fieldList.length; i++) {
      newValueList.valueData.push({
        fieldId: fieldList[i].fieldId,
        value: ''
      })
      let nowUnsetOptions = this.unsetOptions[fieldList[i].fieldId]
      if (fieldList[i].fieldName == '所属村组(合作社/社区)') {
        this.fixedSelection.province = ''
        this.fixedSelection.city = ''
        this.fixedSelection.county = ''
        this.fixedSelection.township = ''
        this.fixedSelection.villageName = ''
        this.options.cityOptions = []
        this.options.countyOptions = []
        this.options.townshipOptions = []
        this.options.villageOptions = []
      } else if (
        fieldList[i].fieldType == '1' &&
        (!nowUnsetOptions || (nowUnsetOptions && !nowUnsetOptions.length))
      ) {
        !nowUnsetOptions && this.$set(this.unsetOptions, fieldList[i].fieldId, [])
        getUnsetList(fieldList[i].fieldId).then((res) => {
          if (res.data.code == 1000) {
            this.$set(this.unsetOptions, fieldList[i].fieldId, res.data.data)
          }
        })
      }
    }
    this.isEitd.push(true)
    this.info.valueList.push(newValueList)
  }
  // 新增方法
  private addFn() {
    if (!this.onlyOne) {
      return
    }
    this.addOneInfo()
  }
  // 编辑方法
  private editFn(rowIndex: number) {
    if (!this.onlyOne) {
      return
    }
    this.onlyOne = false
    let fieldList = this.info.fieldList,
      valueRow = this.info.valueList[rowIndex]
    for (let i = 0; i < fieldList.length; i++) {
      let nowUnsetOptions = this.unsetOptions[fieldList[i].fieldId]
      if (fieldList[i].fieldName == '所属村组(合作社/社区)') {
        this.fixedSelection.province = ''
        this.fixedSelection.city = ''
        this.fixedSelection.county = ''
        this.fixedSelection.township = ''
        this.fixedSelection.villageName = ''
        this.options.cityOptions = []
        this.options.countyOptions = []
        this.options.townshipOptions = []
        this.options.villageOptions = []
      } else if (
        fieldList[i].fieldType == '1' &&
        (!nowUnsetOptions || (nowUnsetOptions && !nowUnsetOptions.length))
      ) {
        !nowUnsetOptions && this.$set(this.unsetOptions, fieldList[i].fieldId, [])
        getUnsetList(fieldList[i].fieldId).then((res) => {
          if (res.data.code == 1000) {
            this.$set(this.unsetOptions, fieldList[i].fieldId, res.data.data)
          }
        })
      }
      if (!this.isAddRow && (fieldList[i].valueType == '3' || fieldList[i].valueType == '2sum')) {
        this.oldRowCountVal[i] = Number(valueRow.valueData[i].value)
      }
    }
    this.$set(this.isEitd, rowIndex, true)
  }
  // 保存方法
  private preserveFn(row: any, index: number) {
    let fieldList = this.info.fieldList
    // 判断是否必填项为null
    for (let i = 0; i < row.valueData.length; i++) {
      if (fieldList[i].isRequire && !row.valueData[i].value.trim()) {
        if (
          fieldList[i].fieldName != '所属村组(合作社/社区)' &&
          fieldList[i].fieldType == '1' &&
          !this.unsetOptions[fieldList[i].fieldId].length
        ) {
          getUnsetList(fieldList[i].fieldId).then((res) => {
            if (res.data.code == 1000) {
              this.$set(this.unsetOptions, fieldList[i].fieldId, res.data.data)
            }
          })
        }
        this.$message({
          message: fieldList[i].fieldName + '为必填字段',
          type: 'error'
        })
        return
      }
      if (
        fieldList[i].fieldName != '所属村组(合作社/社区)' &&
        (fieldList[i].fieldType == '3' ||
          fieldList[i].fieldType == '4' ||
          fieldList[i].valueType == '2' ||
          fieldList[i].valueType == '3' ||
          fieldList[i].valueType == '4' ||
          fieldList[i].valueType == '5') &&
        row.valueData[i].value !== ''
      ) {
        if (isNaN(row.valueData[i].value)) {
          this.$message({
            message:
              fieldList[i].fieldName +
              (fieldList[i].fieldType == '3' ||
              fieldList[i].valueType == '2' ||
              fieldList[i].valueType == '3' ||
              fieldList[i].valueType == '4' ||
              fieldList[i].valueType == '5'
                ? '为数字'
                : '为整数'),
            type: 'error'
          })
          return
        }
        if (fieldList[i].fieldType == '4') {
          let newValue = Number(row.valueData[i].value)
          row.valueData[i].value = Math.round(newValue) + ''
        }
        if (this.total === '' && fieldList[i].valueType == '3') {
          this.total = Number(row.valueData[i].value)
        } else if (this.total !== '' && fieldList[i].valueType == '3') {
          if (this.isAddRow) {
            this.total = this.total + Number(row.valueData[i].value)
          } else {
            let addVal = Number(row.valueData[i].value) - this.oldRowCountVal[i]
            this.total = this.total + addVal
          }
        }
      }
      if (this.total === '' && fieldList[i].valueType == '2sum') {
        this.total = Number(row.valueData[i].value)
      } else if (this.total !== '' && fieldList[i].valueType == '2sum') {
        if (this.isAddRow) {
          this.total = this.total + Number(row.valueData[i].value)
        } else {
          let addVal = Number(row.valueData[i].value) - this.oldRowCountVal[i]
          this.total = this.total + addVal
        }
      }
    }
    if (this.isAddRow) {
      this.isAddRow = false
    } else {
      this.oldRowCountVal = {}
    }
    this.$set(this.isEitd, index, false)
    this.onlyOne = true
  }
  // 删除方法
  private deleteFn(index: any) {
    if (!this.needVillage && this.info.isRequire && this.info.valueList.length == 1) {
      this.$message({
        message: '此栏目为必填栏目',
        type: 'error'
      })
    } else if (!this.onlyOne) {
      if (this.isEitd[index]) {
        this.$confirm('确认删除？')
          .then(() => {
            this.info.valueList.splice(index, 1)
            this.isEitd.splice(index, 1)
            this.onlyOne = true
          })
          .catch(() => {})
      }
    } else {
      this.$confirm('确认删除？')
        .then(() => {
          this.info.valueList.splice(index, 1)
          this.isEitd.splice(index, 1)
        })
        .catch(() => {})
    }
  }
  // input值改变方法
  private changeValue(evt: any, row: any, columnInd: any, field: any) {
    if (field.valueType == '2') {
      let nowValue = row.valueData[columnInd].value,
        secondValue =
          field.fieldName == '数量'
            ? row.valueData[columnInd + 1].value
            : row.valueData[columnInd - 1].value,
        sumInd = field.fieldName == '数量' ? columnInd + 2 : columnInd + 1
      if (!nowValue.trim() || !secondValue.trim()) {
        row.valueData[sumInd].value = ''
      } else if (isNaN(nowValue) || isNaN(secondValue)) {
        row.valueData[sumInd].value = ''
      } else {
        row.valueData[sumInd].value = Number(nowValue) * Number(secondValue) + ''
      }
    }
  }
  @Watch('info', { immediate: true })
  watchTnfo(newV: any) {
    if (this.isFirst && newV && newV.valueList) {
      this.isFirst = false
      if (this.hasSum) {
        for (let i = 0; i < newV.fieldList.length; i++) {
          if (
            !this.totalTile &&
            (newV.fieldList[i].valueType == '2sum' || newV.fieldList[i].valueType == '3')
          ) {
            this.totalTile = newV.fieldList[i].fieldName
            break
          }
        }
        for (let i = 0; i < newV.valueList.length; i++) {
          let valueData = newV.valueList[i].valueData
          for (let l = 0; l < valueData.length; l++) {
            if (
              valueData[l].value &&
              (newV.fieldList[l].valueType == '2sum' || newV.fieldList[l].valueType == '3')
            ) {
              this.total =
                this.total !== ''
                  ? this.total + Number(valueData[l].value)
                  : Number(valueData[l].value)
            }
          }
        }
      }
      if (!this.isControl) {
        return
      }
      for (let i = 0; i < newV.valueList.length; i++) {
        // 初始化标志位
        this.isEitd.push(false)
      }
      if (this.isControl && !newV.valueList.length && !newV.canNew) {
        this.addOneInfo()
      }
    }
  }
  @Watch('isPdPreserve')
  watchPreserve(newV: boolean) {
    if (newV) {
      if (this.isControl) {
        this.$emit('isOpenPreserve', this.onlyOne, this.columnIndex)
      } else {
        this.$emit('isSelectedFalse', this.checkVal, this.info.columnId)
      }
    }
  }
}
