import {
  AduitStatus,
  AuctionStatus,
  Deposit,
  ServiceFeePaidRules,
  TradeRules
} from '../type'

// 挂牌方式
export const listingWayOptions = [
  { label: '非公开发行计划(场外)', value: '1' },
  { label: '非公开发行计划(场内)', value: '2' },
  { label: '收益权(场外)', value: '3' },
  { label: '收益权(场内)', value: '4' },
  { label: '通道类(场内)', value: '5' }
]

// 挂牌状态
export const listingStatusOption = [
  { label: '预审中', value: '预审中' },
  { label: '预审不通过', value: '预审不通过' },
  { label: '预审通过', value: '预审通过' },
  { label: '终审中', value: '终审中' },
  { label: '终审不通过', value: '终审不通过' },
  { label: '受理中', value: '受理中' },
  { label: '挂牌中', value: '挂牌中' },
  { label: '挂牌结束', value: '挂牌结束' },
  { label: '主动摘牌', value: '主动摘牌' },
  { label: '决策委员会审批中', value: '决策委员会审批中' }
]
// 挂牌状态
export const listingStatusOptions = [
  { label: '待风控审核', value: '0' },
  { label: '风控拒绝', value: '1' },
  { label: '代缴费', value: '2' },
  { label: '待财务确认', value: '4' },
  { label: '待受理', value: '4' },
  { label: '挂牌中', value: '4' },
  { label: '挂牌结束', value: '4' },
  { label: '主动摘牌', value: '4' }
]
// 缴费类型
export const paymentTypeOption = [
  { label: '挂牌服务费', value: 1 },
  { label: '会员费', value: 0 },
  { label: '结算费用', value: 2 }
]
// 财务管理状态下拉数据
export const financialStatusOptions = [
  { label: '已结算', value: '5' },
  { label: '待结算', value: '4' },
  { label: '结算中', value: '6' },
  { label: '结算失败', value: '7' },
  { label: '待确认', value: '1' },
  { label: '已确认', value: '3' },
  { label: '已驳回', value: '2' }
]

// 竞拍状态
export const auctionStatusOptions = [
  { label: '全部', value: '' },
  { label: AuctionStatus['waitEnroll'], value: '0' },
  { label: AuctionStatus['enrolling'], value: '1' },
  { label: AuctionStatus['waitBid'], value: '2' },
  { label: AuctionStatus['bidding'], value: '3' },
  { label: AuctionStatus['pendingPayment'], value: '4' },
  { label: AuctionStatus['concludeTransaction'], value: '5' },
  { label: AuctionStatus['passIn'], value: '6' }
]

// 审核状态
export const auditStatusOptions = [
  { label: '全部', value: '' },
  { label: AduitStatus['wait'], value: '0' },
  { label: AduitStatus['pass'], value: '1' },
  { label: AduitStatus['reject'], value: '2' }
]

// 保证金支付状态
export const depositOptions = [
  { label: '全部', value: '' },
  { label: Deposit['paid'], value: '0' },
  { label: Deposit['unpaid'], value: '1' }
]

// 交易规则
export const tradeRulesOptions = [
  { label: '请选择', value: '' },
  { label: ServiceFeePaidRules['highScore'], value: '0' },
  { label: ServiceFeePaidRules['highBidPrice'], value: '1' }
]

// 服务费缴纳规则
export const serviceFeePaidRulesOptions = [
  { label: '请选择', value: '' },
  { label: TradeRules['before'], value: '0' },
  { label: TradeRules['after'], value: '1' }
]

// 产权类型
export const rojectSortOptions = [
  { label: '全部', value: '' },
  { label: '林木交易', value: '林木交易' },
  { label: '林权抵押', value: '林权抵押' },
  { label: '土地经营', value: '土地经营' },
  { label: '土地流转', value: '土地流转' }
]

// 拍卖状态
export const projectPropertiesOptions = [
  { label: '全部', value: '' },
  { label: '受让', value: '受让' },
  { label: '转让', value: '转让' },
  { label: '合股', value: '合股' },
  { label: '租赁', value: '租赁' },
  { label: '出租', value: '出租' },
  { label: '土地信托', value: '土地信托' },
  { label: '出售', value: '出售' },
  { label: '拍卖', value: '拍卖' }
]

// 条件
export const conditionOptions = [
  { label: '>', value: '1' },
  { label: '<', value: '2' },
  { label: '>=', value: '3' },
  { label: '<=', value: '4' },
  { label: '=', value: '5' },
  { label: '≠', value: '6' }
]

// 项目
export const projectsOptions = [
  { label: '家庭总收入', value: '1' },
  { label: '林地面积（亩）', value: '2' },
  { label: '婚姻状况', value: '3' },
  { label: '民族', value: '4' }
]

// 是否
export const yesOrNoOptions = [
  { label: 'Y', value: '1' },
  { label: 'N', value: '2' }
]

//状态
export const statusOptions: any = [
  { label: '全部', value: '' },
  { label: '待审批', value: 1 },
  { label: '已拒绝', value: 2 },
  { label: '待补充资料', value: 3 },
  { label: '已通过', value: 4 },
  { label: '已还清', value: 5 }
]

//角色
export const roleOptions: any = [
  { label: '全部', value: '' },
  { label: '发行商/备案人', value: 'issuer' },
  { label: '服务商', value: 'provider' },
  { label: '受托管理人', value: 'admin' },
  { label: '担保人', value: 'guarantor' }
]

//文字识别状态
export const ocrStatusOptions: any = [
  { label: '全部', value: '' },
  { label: '未识别', value: 0 },
  { label: '已识别', value: 1 }
]

