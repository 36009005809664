
import { Component, Vue } from 'vue-property-decorator'
import { sessionStore } from '@/utils/data-management'
import QrCode from '@/components/QrCode.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { checkBankCardInfo } from '@/api/center'
import { subsidyGrantPre, subsidyGrantSumbit } from '@/api/countrysidePropertyTransaction'
// import { isNeedFace } from '@/api/common.api'

@Component({
  components: {
    QrCode // 认证二维码
  }
})
export default class SubsidyGrant extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    bankAccountNumber: '',
    bussinessId: '',
    remarks: ''
  }
  private banks: any = []
  private fee: any = []
  private rules: any = {
    bankAccountNumber: { required: true, message: '请选择银行卡', trigger: 'blur' },
    bussinessId: { required: true, message: '请选择费项', trigger: 'blur' }
  }
  private loading = false
  private subLoading = false
  private isClick: boolean = true

  private qrDiaShow: boolean = false // 授权认证标志
  private info: any = {
    subsidyTransferPreInfoDetailResults: [],
    totalPeople: 0,
    totalAmount: 0
  }
  created() {
    this.getList()
  }

  private getList() {
    if (sessionStore.get('grantList')) {
      let ids = {
        applyIds: sessionStore.get('grantList')
      }
      this.getBankList()
      subsidyGrantPre(ids).then((res) => {
        if (res.data.code == 1000) {
          this.info = res.data.data
        }
      })
    } else {
      this.handleReturn()
    }
  }

  private getBankList() {
    checkBankCardInfo({ type: 2 }).then((res) => {
      this.banks = res.data.data
    })
  }

  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }
  // 删除
  private handleDel(row: any, index: number) {
    this.$confirm('确认删除？').then(() => {
      this.info.subsidyTransferPreInfoDetailResults.splice(index, 1)
      this.info.totalPeople--
      this.info.totalAmount -= row.transferAmount
    })
  }

  // 选择银行
  handleBank(val: any) {
    this.formInfo.bussinessId = ''
    let data = this.banks.filter((item: any) => item.bankCard == val)
    this.fee = (data[0].bankCardBussinessRels || []).filter((item: any) => item.bussinessType == 1)
  }

  private handleSubmit() {
    ;(this.$refs.formInfo as any).validate((v: boolean) => {
      if (v) {
        if (this.info.subsidyTransferPreInfoDetailResults.length < 1) {
          return this.$message.error('无补贴发放农户请重新选择')
        }
        if (this.repeatT()) {
          return
        }
        sessionStore.remove('grantList')
        // isNeedFace({})
        //   .then((res) => {
        //     if (res.data.data && res.data.data.result) {
        //       let ids: any = this.info.subsidyTransferPreInfoDetailResults.map((item: any) => item.applyId)
        //       let params = {
        //         applyIds: ids,
        //         ...this.formInfo,
        //         callbackId: res.data.data.callbackId
        //       }
        //       this.isClick = false
        //       subsidyGrantSumbit(params)
        //         .then((res) => {
        //           if (res.data.code == 1000) {
        //             this.$message.success('补贴发放成功')
        //             setTimeout(() => {
        //               this.handleReturn()
        //             }, 500)
        //           }
        //         })
        //         .finally(() => {
        //           this.isClick = true
        //           this.subLoading = false
        //         })
        //     } else {
        //       this.qrDiaShow = true
        //     }
        //   })
        //   .catch(() => {
        this.qrDiaShow = true
        // })
      }
    })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      let ids: any = this.info.subsidyTransferPreInfoDetailResults.map((item: any) => item.applyId)
      let params = {
        applyIds: ids,
        ...this.formInfo,
        callbackId
      }
      this.isClick = false
      subsidyGrantSumbit(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message.success('补贴发放成功')
            setTimeout(() => {
              this.handleReturn()
            }, 500)
          }
        })
        .finally(() => {
          this.qrDiaShow = false
          this.isClick = true
          this.subLoading = false
        })
    }
  }
}
