
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
// import { getProjectList } from '@/api/law' //接口
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    },
    formatTime2: (time: any) => {
      if (time) return timeFormat(new Date(time), 'YYYY-MM-DD')
    }
  }
})
export default class ExaminationApprovalList extends Vue {
  private loadingTxt: string = loadingText
  private disabled: boolean = false
  private roleOptions: any = [
    { label: '风控审批', value: '1' },
    { label: '受理审批', value: '2' },
    { label: '成立审批', value: '3' }
  ]
  private formInfo: any = {
    name: '',
    phoneNumber: '',
    time: '',
    status: ''
  }

  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = [
    {
      id: 1,
      name: '荒野1',
      phone: '通道类(场内)',
      time: 1562654894000,
      status: 1
    },
    {
      id: 1,
      name: '荒野333',
      phone: '通道类(场内)',
      time: 1562654894000,
      status: 2
    },
    {
      id: 1,
      name: '荒野33',
      phone: '通道类(场内)',
      time: 1562654894000,
      status: 2
    },
    {
      id: 1,
      name: '荒野3',
      phone: '通道类(场内)',
      time: 1562654894000,
      status: 3
    }
  ]
  private loading = false

  created() {
    // this.getList()
  }
  private handleSearch() {
    this.getList()
  }
  private async getList() {
    if (this.formInfo.time) {
      this.$set(this.formInfo, 'startTime', this.formInfo.time[0])
      this.$set(this.formInfo, 'endTime', this.formInfo.time[1])
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNum: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    // getProjectList(params)
    //   .then((res) => {
    //     const data = res.data
    //     this.tableData = data.data
    //     this.paginationParam.total = data.total
    //   })
    //   .finally(() => {
    //     this.loading = false
    //   })
  }
  private handleDetails(row: any) {
    this.$router.push({ name: 'ApprovalDetail', params: { id: row.id || 1 } })
  }
}
