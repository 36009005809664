var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "项目状态", name: "1" } },
            [
              _c("pro-info", {
                attrs: { info: _vm.proDetail.projectStatus },
                on: {
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "projectStatus", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "项目立项申请信息", name: "2" } },
            [
              _c("pro-base-info", {
                attrs: {
                  info: _vm.proDetail.projectDetails,
                  detailtype: 0,
                  isShowRed: false
                },
                on: {
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "projectDetails", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "产品相关", name: "3" } },
            [
              _c("pro-product-related", {
                attrs: {
                  info: _vm.proDetail.productReleative,
                  detailtype: 0,
                  isShowRed: false
                },
                on: {
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "productReleative", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "发行方", name: "4" } },
            [
              _c("pro-info-person", {
                attrs: {
                  info: _vm.proDetail.issuer,
                  flag: "issuer",
                  title: "发行方（备案登记人）",
                  detailtype: 0,
                  isShowRed: false
                },
                on: {
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "issuer", $event)
                  }
                }
              })
            ],
            1
          ),
          _vm.proDetail.guarantors && _vm.proDetail.guarantors.length
            ? _c(
                "el-tab-pane",
                { attrs: { label: "担保人", name: "5" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      info: _vm.proDetail.guarantors,
                      flag: "guarantors",
                      title: "担保人",
                      detailtype: 0,
                      isShowRed: false
                    },
                    on: {
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "guarantors", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.provider
            ? _c(
                "el-tab-pane",
                { attrs: { label: "服务商", name: "6" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      info: _vm.proDetail.provider,
                      flag: "provider",
                      title: "服务商",
                      detailtype: 0,
                      isShowRed: false
                    },
                    on: {
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "provider", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.administrator
            ? _c(
                "el-tab-pane",
                { attrs: { label: "受托管理人", name: "7" } },
                [
                  _c("pro-info-person", {
                    attrs: {
                      info: _vm.proDetail.administrator,
                      flag: "administrator",
                      title: "受托管理人",
                      detailtype: 0,
                      isShowRed: false
                    },
                    on: {
                      "update:info": function($event) {
                        return _vm.$set(_vm.proDetail, "administrator", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "相关协议及合同", name: "8" } },
            [
              _c("pro-info-contract", {
                attrs: { info: _vm.proDetail.files },
                on: {
                  "update:info": function($event) {
                    return _vm.$set(_vm.proDetail, "files", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "法律意见书", name: "9" } },
            [
              _c("dialog-law", {
                attrs: { info: _vm.proDetail },
                on: {
                  "update:info": function($event) {
                    _vm.proDetail = $event
                  },
                  getUrl: _vm.getUrl
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName == "9"
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  staticClass: "goback",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _vm.detailtype == 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.lawPopEvt }
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "授权认证", visible: _vm.qrDiaShow },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow },
            on: { notify: _vm.notify }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }