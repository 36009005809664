
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class ProBaseInfo extends Vue {
  @Prop() info: any
  @Prop() detailtype: any
  @Prop({ default: 'false' }) isSecond: any
  @Prop({ default: false }) refuse?: boolean
  @Prop({ default: true }) isShowRed?: boolean
  // 公开开关
  @Prop({ default: false }) openShow?: boolean
  // 服务商文件url
  private providerPontract: any = {
    // 经纪服务商文件url
    broker_provider_contract: '',
    // 咨询服务商文件url
    consulting_provider_contract: '',
    // 渠道服务商文件url
    channel_provider_contract: ''
  }
  private isFirst: boolean = true
  private checkboxShow: boolean = false
  private redTitle: boolean = true
  private itemStatus: any = {
    recommendCode: false,
    userName: false,
    demandTel: false,
    demandName: false,
    listingMode: false,
    expectedAmount: false,
    productDeadline: false,
    expectedRate: false,
    providerRate: false
  }
  private itemShow: any = {
    recommendCode: true,
    userName: true,
    demandTel: true,
    demandName: true,
    listingMode: true,
    expectedAmount: true,
    productDeadline: true,
    expectedRate: true,
    providerRate: true
  }
  // 监听info控制选择框展示和提示样式展示
  @Watch('info', { deep: true })
  infoWatch(newVal: any) {
    if (this.isFirst && newVal) {
      this.isFirst = false
      let nameShow = false
      if ((this.detailtype == 1 && this.isSecond == 'true') || this.detailtype == 0) {
        let fieldList = this.info.fieldList
        for (let i = 0; i < fieldList.length; i++) {
          if (fieldList[i].status) {
            this.$set(this.itemShow, fieldList[i].fieldName, false)
          } else {
            nameShow = true
          }
        }
        if (this.detailtype != 0 && !nameShow) {
          this.redTitle = false
        }
      }
      let filesArr: any = newVal.files
      if (filesArr && filesArr.length) {
        for (let i = 0; i < filesArr.length; i++) {
          if (filesArr[i].fileType == 'broker-provider-contract') {
            this.$set(this.providerPontract, 'broker_provider_contract', filesArr[i].fileAddr ? filesArr[i].fileAddr : '')
          } else if (filesArr[i].fileType == 'consulting-provider-contract') {
            this.$set(this.providerPontract, 'consulting_provider_contract', filesArr[i].fileAddr ? filesArr[i].fileAddr : '')
          } else if (filesArr[i].fileType == 'channel-provider-contract') {
            this.$set(this.providerPontract, 'channel_provider_contract', filesArr[i].fileAddr ? filesArr[i].fileAddr : '')
          }
        }
      }
    }
  }
  @Watch('refuse')
  handler(newValue: any) {
    if (newValue) {
      this.$emit('addRefuseItem', 'projectDetails', this.itemStatus)
    }
  }
  created() {
    if (this.detailtype == 1 || this.detailtype == 5) {
      this.checkboxShow = true
    }
  }
  // 公开开关状态改变返回对应数据
  private openChange() {
    this.$emit('openChange', '项目立项申请信息', this.info.publicInfo)
  }
}
