var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.othserInfo
    ? _c("div", { staticClass: "trade-details" }, [
        _c("div", { staticClass: "block" }, [
          _vm._m(0),
          _vm.othserInfo && (_vm.tradeState == 3 || _vm.tradeState == 2)
            ? _c(
                "div",
                { staticClass: "printing" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.prev }
                    },
                    [_vm._v("预览凭证")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("交易编号：")]),
              _c("span", [_vm._v(_vm._s(_vm.othserInfo.tradeNo))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("交易发起时间：")]),
              _c("span", [_vm._v(_vm._s(_vm.othserInfo.createdTime))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("交易发起人：")]),
              _c("span", [_vm._v(_vm._s(_vm.othserInfo.userName))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("服务类型：")]),
              _c("span", [_vm._v(_vm._s(_vm.othserInfo.type))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("用途：")]),
              _c("span", [_vm._v(_vm._s(_vm.othserInfo.resultMsg))])
            ])
          ])
        ]),
        _c("div", { staticClass: "block" }, [
          _vm._m(1),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v(" 计划收款人数目： ")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.othserInfo.transMemberCount
                      ? _vm.othserInfo.transMemberCount + "人"
                      : ""
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("实际成功数目：")]),
              _c(
                "span",
                {
                  staticStyle: { color: "#409eff", cursor: "pointer" },
                  on: { click: _vm.jumpTheirList }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.othserInfo.successCount
                          ? _vm.othserInfo.successCount + "人"
                          : "0人"
                      ) +
                      " "
                  )
                ]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "block" }, [
          _vm._m(2),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("付款金额：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.othserInfo.amount ? _vm.othserInfo.amount + "元" : ""
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("付款银行卡：")]),
              _c("span", [_vm._v(" " + _vm._s(_vm.bankCard) + " ")])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("备注：")]),
              _c("span", [_vm._v(" " + _vm._s(_vm.othserInfo.remark) + " ")])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v("交易发起信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v(" 收款人信息 ")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "vertical-line" }),
      _c("span", { staticClass: "text" }, [_vm._v("付款信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }