
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { PaginationParam } from '@/views/type'
import { paymentTypeOption, financialStatusOptions } from '@/views/project-management/options'
import { getFinancingLists } from '@/api/financing'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class Financial extends Vue {
  private loadingTxt: string = loadingText
  private tableData: any[] = []
  private loading = false
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private paymentTypeOptions = paymentTypeOption
  private financialStatusOptions = financialStatusOptions
  private formInfo: any = {
    demandName: '',
    payer: '',
    name: '',
    status: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (['FinancialDetail', 'TradeDetails', 'TradeReceiveList', 'TradePayList', 'TradeReceipt'].indexOf(from.name) > -1 && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'FinancialDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private query() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    // this.formInfo.name = Number(this.formInfo.name)
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    // Object.keys(params).forEach(key => {
    //   !params[key] && delete params[key]
    // })
    getFinancingLists(params as any)
      .then((res) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'FinancialDetail',
      params: { id: row.name == '2' ? row.dfId : row.id, name: row.name, status: row.status }
    })
  }
}
