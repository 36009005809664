
import { Component, Vue } from 'vue-property-decorator'
import { sessionStore } from '@/utils/data-management'
import upLoadFileBtn from '@/components/upLoadFileBtn/index.vue'

import {
  subsidyProjectAdd,
  subsidyProjectUpdate,
  areaAll,
  subsidyProjectIcon
} from '@/api/countrysidePropertyTransaction'

@Component({
  components: {
    upLoadFileBtn
  }
})
export default class SubsidyProjectDetail extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.params.type == 'add') {
      to.meta.title = '添加项目'
    } else if (to.params.type == 'edit') {
      to.meta.title = '修改项目'
    } else {
      to.meta.title = '查看项目'
    }
    next((vim: any) => {
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaConfigId &&
          (vim.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
      }
    })
  }

  private isSuperAdmin: any = '0'
  private formInfo: any = {
    areaConfigId: '',
    name: '',
    remark: '',
    icon: ''
  }
  private rules: any = {
    areaConfigId: { required: true, message: '请选择区县', trigger: 'blur' },
    name: { required: true, message: '请输入项目名称', trigger: 'blur' },
    remark: { required: true, message: '请输入项目描述', trigger: 'blur' },
    icon: { required: true, message: '请选择图标', trigger: 'blur' }
  }
  private statusArea: any = []

  private show: boolean = false

  private listShow: boolean = false
  private uploadOptionBtn: any = {
    limit: 1,
    accept: '.jpg,.jpge,.png',
    btnText: '上传'
  }
  private isClick: boolean = true
  private iconList: any = []

  created() {
    this.getArea()
    this.init()
    this.getIcon()
  }

  private getArea() {
    areaAll({}).then((res) => {
      this.statusArea = res.data.data
    })
  }
  private init() {
    if (this.$route.params.type != 'add') {
      let data = sessionStore.get('subsidyProject')
      this.formInfo = {
        areaConfigId: data.areaConfigId,
        name: data.name,
        remark: data.remark,
        icon: data.icon
      }
    }
  }
  private getIcon() {
    let params = {
      type: 1
    }
    subsidyProjectIcon(params).then((res) => {
      if (res.data.code == 1000) {
        this.iconList = res.data.data
      }
    })
  }
  // 上传完图片的回调
  private getFileUrlUrls(data: any) {
    let dataObj: any = JSON.parse(JSON.stringify(data))
    this.formInfo.icon = data.url
  }
  private handleChangeIcon(url: any) {
    this.show = false
    this.formInfo.icon = url
  }
  // 防重复点击
  private repeatT() {
    if (!this.isClick) {
      this.$message({
        message: '服务正在响应，请稍后再试',
        type: 'error'
      })
      return true
    }
    return false
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  // 新增项目
  private handleSubmit() {
    ;(this.$refs.formInfo as any).validate((v: boolean) => {
      if (v) {
        if (this.repeatT()) {
          return
        }
        this.isClick = false
        if (this.$route.params.type == 'add') {
          subsidyProjectAdd(this.formInfo)
            .then((res) => {
              this.$message.success('新增项目成功')
              setTimeout(() => {
                this.handleReturn()
              }, 300)
            })
            .finally(() => {
              this.isClick = true
            })
        } else {
          let params = {
            ...this.formInfo,
            id: sessionStore.get('subsidyProject').id
          }
          subsidyProjectUpdate(params)
            .then((res) => {
              this.$message.success('修改项目成功')
              setTimeout(() => {
                this.handleReturn()
              }, 300)
            })
            .finally(() => {
              this.isClick = true
            })
        }
      }
    })
  }
}
