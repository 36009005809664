var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.info
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "item-box" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("项目公告：")]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.isDisabled },
                  model: {
                    value: _vm.info.isShowProject,
                    callback: function($$v) {
                      _vm.$set(_vm.info, "isShowProject", $$v)
                    },
                    expression: "info.isShowProject"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("自定义")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("选择链接")])
                ],
                1
              )
            ],
            1
          ),
          _vm.info.isShowProject == 2
            ? _c(
                "div",
                { staticClass: "item-box" },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("项目公告链接：")
                  ]),
                  _c("el-select", {
                    attrs: { disabled: _vm.isDisabled, placeholder: "请选择" },
                    on: {
                      focus: function($event) {
                        return _vm.projectEvt()
                      }
                    },
                    model: {
                      value: _vm.projectName,
                      callback: function($$v) {
                        _vm.projectName = $$v
                      },
                      expression: "projectName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.info.isShowProject == 1 && _vm.info.bidNotice
            ? _c(
                "div",
                _vm._l(_vm.info.bidNotice.bidNoticDetail, function(
                  item,
                  index
                ) {
                  return _c(
                    "div",
                    { key: "edit" + index, staticClass: "item-box flex-row" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.labelName) + "：")
                      ]),
                      _c(
                        "div",
                        { staticClass: "quill-text" },
                        [
                          _c("Tinymce-editor", {
                            attrs: { disabled: _vm.isDisabled },
                            model: {
                              value: item.value,
                              callback: function($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")]),
              _vm.isDisabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEditor }
                    },
                    [_vm._v("编辑")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.doSave } },
                    [_vm._v("保存")]
                  )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "项目公告",
                visible: _vm.projectShow,
                width: "80%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.projectShow = $event
                }
              }
            },
            [
              _c("project-info", {
                on: {
                  projectId: _vm.getProjectId,
                  projectName: _vm.getProjectName
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }