
import { Component, Vue, Prop } from 'vue-property-decorator'
import numsTips from '@/components/numsTips/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class Stakeholder extends Vue {
  // 展示信息
  @Prop({
    default: () => {
      return []
    }
  })
  tableData?: any
  @Prop({ default: 0 }) total?: number
  @Prop({ default: false }) loading?: boolean
  private paginationParam: any = {
    pageIndex: 1,
    pageSize: 10
  }
  private loadingTxt: string = loadingText
  private query() {
    this.$emit('getStakeholder', {
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    })
  }
}
