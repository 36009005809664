var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogTableVisible,
            width: "600px",
            center: true,
            "close-on-click-modal": _vm.isOrModalClose,
            "before-close": _vm.handleDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formStyle",
              attrs: { model: _vm.formDatas, rules: _vm.rules, size: "small" }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "inputBox",
                  attrs: { label: "栏目名称：", prop: "name" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: { size: "small", placeholder: "请输入栏目名称" },
                    model: {
                      value: _vm.formDatas.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "name", $$v)
                      },
                      expression: "formDatas.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inputBox",
                  attrs: { label: "栏目排序：", prop: "sort" }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: { size: "small", placeholder: "请输入栏目排序" },
                    model: {
                      value: _vm.formDatas.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "sort", $$v)
                      },
                      expression: "formDatas.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否农户贷栏目：", prop: "isRuralLoan" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.formDatas.isRuralLoan,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "isRuralLoan", $$v)
                      },
                      expression: "formDatas.isRuralLoan"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否必填：", prop: "isRequired" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.formDatas.isRequired,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "isRequired", $$v)
                      },
                      expression: "formDatas.isRequired"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "栏目内容是否允许新增：",
                    prop: "contentIsAdd"
                  }
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.formDatas.contentIsAdd,
                      callback: function($$v) {
                        _vm.$set(_vm.formDatas, "contentIsAdd", $$v)
                      },
                      expression: "formDatas.contentIsAdd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("el-button", { on: { click: _vm.cancelDialog } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDialog("formData")
                        }
                      }
                    },
                    [_vm._v("提 交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }