var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _vm.isShowRegistrationNo
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("信用信息登记编号")]),
                  _c(
                    "div",
                    { staticClass: "eitd-box" },
                    [
                      !_vm.isEitd
                        ? _c("span", { staticClass: "show-text" }, [
                            _vm._v(_vm._s(_vm.info.projectNum))
                          ])
                        : _vm._e(),
                      _vm.isEitd
                        ? _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.info.projectNum,
                              callback: function($$v) {
                                _vm.$set(_vm.info, "projectNum", $$v)
                              },
                              expression: "info.projectNum"
                            }
                          })
                        : _vm._e(),
                      _vm.info.projectNum && _vm.modifyProjectNum
                        ? _c("span", {
                            staticClass: "eitd-btn",
                            style: {
                              "background-image": "url(" + _vm.bgImg + ")"
                            },
                            on: { click: _vm.eitdFn }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("状态")]),
              _c("span", [_vm._v(_vm._s(_vm.info.status))])
            ]),
            _vm.areaShow
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("所属区域")]),
                  _c("span", [_vm._v(_vm._s(_vm.areaName))])
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("挂牌申请时间")]),
              _c("span", [_vm._v(_vm._s(_vm.info.applyTime))])
            ]),
            _vm.isShowKey || _vm.info.endTime
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("挂牌截止时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.endTime))])
                ])
              : _vm._e(),
            _vm.isShowKey || _vm.info.delistingTime
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("摘牌时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.delistingTime))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }