
import { Component, Vue } from 'vue-property-decorator'
import { sessionStore } from '@/utils/data-management'
import Pagination from '@/components/Pagination/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { subsidyProjectList, subsidyProjectDelete, areaAll } from '@/api/countrysidePropertyTransaction'
import { getVerifyStatus } from '@/api/login'

@Component({
  components: {
    Pagination
  }
})
export default class SubsidyProjectManagement extends Vue {
  private loadingTxt: string = loadingText
  private statusArea: any = []
  private formInfo: any = {
    name: '',
    areaConfigId: ''
  }
  private paginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []

  private loading: boolean = false
  private role: any = ''
  private roleStatus: boolean = true

  created() {
    this.getArea()
    this.verifyStatus()
    if (sessionStore.get('subsidyProject')) {
      sessionStore.remove('subsidyProject')
    }
  }

  private verifyStatus() {
    getVerifyStatus({})
      .then((res) => {
        if (res.data.code == 1000) {
          this.role = res.data.data.role
          if (res.data.data.permissions.indexOf('subsidy-manager') != -1) {
            this.role = 2
          }
        }
      })
      .finally(() => {
        this.roleStatus = false
      })
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      if (from.name == 'SubsidyProjectDetail' && pageParams) {
        vim.formInfo = pageParams.formInfo
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'SubsidyProjectDetail') {
      let pageParams: any = {
        formInfo: this.formInfo
      }
      sessionStore.set('pageParams', pageParams)
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    subsidyProjectList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => (this.loading = false))
  }

  private getArea() {
    this.loading = true
    areaAll({})
      .then((res) => {
        this.statusArea = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleSearch() {
    this.getList()
  }

  private handleAdd() {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    this.$router.push({
      name: 'SubsidyProjectDetail',
      params: { type: 'add', id: 'add' }
    })
  }

  private handleDetails(row: any) {
    sessionStore.set('subsidyProject', row)
    this.$router.push({
      name: 'SubsidyProjectDetail',
      params: { type: 'detail', id: row.id }
    })
  }
  private handleEdit(row: any) {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    sessionStore.set('subsidyProject', row)
    this.$router.push({
      name: 'SubsidyProjectDetail',
      params: { type: 'edit', id: row.id }
    })
  }
  private handleDel(row: any) {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    this.$confirm('确认删除？')
      .then(() => {
        subsidyProjectDelete({ id: row.id }).then((res) => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
      .catch(() => {})
  }
}
