
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class LiquidationBasicInfo extends Vue {
  @Prop() info: any
  private intoDetails() {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: this.info.id, isExport: '0' }
    })
  }
  get filterSocialCode() {
    let returnVal: string = ''
    if (this.info && this.info.socialCode) {
      let socialCodeLength: number = this.info.socialCode.length
      if (socialCodeLength >= 10) {
        returnVal = this.info.socialCode.slice(0, socialCodeLength - 10) + '******' + this.info.socialCode.slice(socialCodeLength - 4)
      } else if (socialCodeLength < 10 && socialCodeLength > 6) {
        returnVal = '******' + this.info.socialCode.slice(6)
      } else {
        returnVal = '******'
      }
    }
    return returnVal
  }
  get liquidationStatusDesc() {
    let returnDesc: string = '',
      statusDesc: any = [
        { label: '待清算', value: 1 },
        { label: '审批中', value: 2 },
        { label: '已驳回', value: 4 },
        { label: '清算中', value: 3 },
        { label: '清算失败', value: 5 },
        { label: '已清算', value: 6 }
      ]
    if (this.info && this.info.status) {
      for (let i = 0; i < statusDesc.length; i++) {
        if (statusDesc[i].value == this.info.status) {
          returnDesc = statusDesc[i].label
          break
        }
      }
    }
    return returnDesc
  }
}
