
import { Component, Prop, Vue } from 'vue-property-decorator'
import { sessionStore } from '@/utils/data-management'
// import PDFJS from './pdf/build/pdf.js'
// PDFJS.PDFJS.workerSrc = require('./pdf/build/pdf.worker.js')
import { getCompanyCreditIndicateTemp } from '@/api/enterpriseCreditRating'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class DataExtract extends Vue {
  @Prop() private fileList!: any
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private formInfo: any = []
  // private fileList: any = []
  private file: string = ''
  private pickerOptions: any = {
    disabledDate: (time: any) => {
      return time.getTime() > Date.now() - 8.64e7
    }
  }

  created() {
    this.getTemplate()
    // this.loadFile()
  }

  private loadFile() {
    // let url = 'https://testapi.fadada.com:8443/api//viewdocs.action?app_id=402937&timestamp=20200828183310&v=2.0&msg_digest=REVCRThGNzM3OTU3NDI2QTlENjMwOEUzQzIyODg3NEFCNDE0MDQ2RA==&transaction_id=20200828000018330943&send_app_id=null'
    // url = 'http://image.cache.timepack.cn/nodejs.pdf'
    // url = 'https://fdfs.zct.gjsc.info/group1/M00/00/35/wKgECl-GpTKAbIf0AAX3IiBKBT8647.pdf'
    // PDFJS.getDocument(url).promise.then((res: any) => {
    //   this.$nextTick(() => {
    //     res.getPage(1).then((page: any) => {
    //       let canvas: any = document.getElementById('canvas')
    //       let ctx = canvas.getContext('2d')
    //       canvas.style.width = '500px'
    //       canvas.style.height = '500px'
    //       ctx.setTransform(1, 0, 0, 1, 0, 0)
    //       var renderContext = {
    //         canvasContext: ctx,
    //         viewport: 1
    //       }
    //       page.render(renderContext)
    //     })
    //   })
    // })
  }
  private getTemplate() {
    this.loading = true
    getCompanyCreditIndicateTemp()
      .then((res: any) => {
        let data = res.data.data
        data.map((item: any) => (item.value = ''))
        this.formInfo = data
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 输入限制转化
  private inputLimit(value: any, name: any, index: number) {
    value = value.replace(/,/g, '')
    if (isNaN(Number(value)) && !/^-$/.test(value)) {
      value = ''
    }
    if (!/^[+-]?\d*\.{0,1}$/.test(value)) {
      value = value.replace(/\.\d{2,}$/, value.substr(value.indexOf('.'), 3))
      // value = value.replace(/^/)
    }
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (value.indexOf('.') < 0 && value != '') {
      if (value.indexOf('-') < 0) {
        value = parseFloat(value)
      } else {
        if (!value.slice(1)) return value
        value = -parseFloat(value.slice(1))
      }
    }
    if (value === 0 && 1 / value < 0) {
      // 1与+0的商为Infinite，1与-0的商为-Infinite
      return
    }
    // this.formInfo[name] = this.priceFormat(value)
    this.formInfo[index].value = this.priceFormat(value)
  }
  private priceFormat(value: any) {
    value = String(value)
    let sign = ''
    if (value.indexOf('-') > -1) {
      sign = '-'
      value = value.slice(1)
    }
    let num = '',
      tail = ''
    if (value.indexOf('.') > 0) {
      num = value.slice(0, value.indexOf('.'))
      tail = value.slice(value.indexOf('.'))
    } else {
      num = value.replace(/[^\d^\.]+/g, '')
    }
    let oldNum = num.split('').reverse()
    let newNum: any = []
    oldNum.map((item: any, index: number) => {
      newNum.push(item)
      if ((index + 1) % 3 == 0 && index + 1 != oldNum.length) {
        newNum.push(',')
      }
    })
    return `${sign}${newNum.reverse().join('')}${tail}`
  }

  private prev() {
    this.$confirm('确定回到前页？回到前页后已填写数据将清空').then(() => {
      this.$emit('prev')
    })
  }
  private next() {
    let params = this.formInfo
    let status = false
    params.map((item: any) => {
      if (item.value == '-' || item.value === 0) {
        item.value = 0
      }
      if (status) return
      if (item.value === '') {
        status = true
        this.$message.error(`${item.description}不能为空`)
        return
      }
    })
    if (status) return
    sessionStore.set('FinancialRatingData', params)
    this.$emit('next')
  }
}
