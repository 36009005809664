
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getLoanApprovalList, getBankList, getPropertyrightTransactionCenterData } from '@/api/loans'
import { statusOptions } from '@/views/project-management/options'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips
  }
})
export default class ProductsApproval extends Vue {
  private loadingTxt: string = loadingText
  private bankOptions: any = []
  // private areaOptions: any = []
  private statusOptions = statusOptions
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: Array<any> = []
  // private tradeCenterOptions: Array<any> = []
  private loading = false
  private formInfo: any = {
    lenderName: '',
    // area: '',
    bankId: '',
    loanProductName: '',
    status: '',
    loanBank: ''
  }
  // created() {
  //   // this.getTradeCenterData()
  //   getBankList().then((res) => {
  //     if (res.data.code === 1000) {
  //       let companyInfo: any = res.data.data.company
  //       this.bankOptions.push({ name: '全部', id: '' })
  //       companyInfo && this.bankOptions.push({ name: companyInfo.name, id: companyInfo.id })
  //       this.bankOptions.push({ name: '其他', id: '其他' })
  //     }
  //   })
  //   this.query()
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'ApprovalDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.bankOptions = pageParams.bankOptions
      }
      if (vim.bankOptions.length < 3) {
        vim.bankOptions = []
        getBankList().then((res) => {
          if (res.data.data) {
            let companyInfo: any = res.data.data.company
            vim.bankOptions.push({ name: '全部', id: '' })
            companyInfo && vim.bankOptions.push({ name: companyInfo.name, id: companyInfo.id })
            vim.bankOptions.push({ name: '其他', id: '其他' })
          }
        })
      }
      vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'ApprovalDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        bankOptions: this.bankOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private bankSelect(str: string) {
    if (str == '其他') {
      this.formInfo.loanBank = str
    } else {
      this.formInfo.loanBank == '其他' && (this.formInfo.loanBank = '')
    }
  }
  // getTradeCenterData() {
  //   const data: any = {
  //     categoryName: 'propertyright_transaction_center'
  //   }
  //   getPropertyrightTransactionCenterData(data)
  //     .then((res) => {
  //       let data: any = res.data.data
  //       let arr: any = []
  //       data.forEach((item: any) => {
  //         arr.push({ label: item.value, value: item.key })
  //       })
  //       arr.unshift({ label: '全部', value: '' })
  //       this.tradeCenterOptions = arr
  //     })
  // }

  private getStatusVal(k: any) {
    let returnVal: any = ''
    for (let i = 0; i < this.statusOptions.length; i++) {
      if (k == this.statusOptions[i].value) {
        returnVal = this.statusOptions[i].label
        break
      }
    }
    return returnVal
  }

  private async query() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    if (params.bankId == '其他') {
      params.bankId = ''
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getLoanApprovalList(params as any)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          // this.filterData(res.data.data)
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // filterData(arr: Array<any>) {
  //   let _tradeCenterOptions = this.tradeCenterOptions
  //   arr.forEach((item) => {
  //     _tradeCenterOptions.forEach((it) => {
  //       if (it.value == item.propertyTradingCenter) {
  //         item.propertyTradingCenter = it.label
  //       }
  //     })
  //   })
  //   this.tableData = arr
  // }

  // 搜索发放列表
  private handleSearch() {
    this.query()
  }

  // 查看详情
  private handleDetails(row: any) {
    // let _companyOptions: any = this.tradeCenterOptions
    // _companyOptions.shift()
    this.$router.push({
      name: 'ApprovalDetail',
      params: {
        id: row.id,
        permission: row.permission
        // tradeCenterOptions: _companyOptions
      }
    })
  }
}
