
import { Component, Vue, Watch } from 'vue-property-decorator'
import ZQTWelcome from '@/assets/images/welcome.png'
// import { getRoleMenus, getPersonInfo, firstFaceVerify } from '@/api/login'
import { localStore, sessionStore } from '@/utils/data-management'
// import QrCode from '@/components/QrCodeFirst.vue'
// import uploadTemp from '@/components/UploadFile/index.vue'
import { getLoginAreaId, getLoginAreaInfo, getVerifyStatus, validPersonnelUser, reviseForPerson } from '@/api/login'
// import UploadFirst from '@/components/UploadFirst/index.vue'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getSearch } from '@/utils/getSearch'
import hPresonActive from '@/assets/images/h_person_active.png'
import hPresonDefault from '@/assets/images/h_person_default.png'
import hGovernmentActive from '@/assets/images/h_government_active.png'
import hGovernmentDefault from '@/assets/images/h_government_default.png'
import hFinanceActive from '@/assets/images/h_finance_active.png'
import hFinanceDefault from '@/assets/images/h_finance_default.png'

@Component({
  components: {
    // QrCode,
    // uploadTemp,
    // UploadFirst
  }
})
export default class ZQTHome extends Vue {
  private loadingTxt: string = loadingText
  // 背景图片-start
  private hPresonActiveImg: any = hPresonActive
  private hPresonDefaultImg: any = hPresonDefault
  private hGovernmentActiveImg: any = hGovernmentActive
  private hGovernmentDefaultImg: any = hGovernmentDefault
  private hFinanceActiveImg: any = hFinanceActive
  private hFinanceDefaultImg: any = hFinanceDefault
  // 背景触发标志
  private imgActive: any = [false, false, false]
  // 背景图片-end
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  @Watch('$store.state.hasToken')
  private hasToken(newValue: string) {
    if (newValue) {
      this.verifyStatus()
    }
  }
  private image: string = ZQTWelcome
  // private showClose: boolean = false
  // private isModalClose: boolean = false
  // private imageUrl: string = ''
  // private QrCodeUrl: string = ''
  private callbackId: string = ''
  // private center: boolean = true
  private options: boolean = false
  // private formData: any = {}
  // private uploadOption: any = {
  //   accept: 'jpeg,jpg,png',
  //   maxSize: 2,
  //   tip: '请上传身份证照片'
  // }
  private homes: boolean = true
  // private uploadImg: boolean = false
  // private QrCode: boolean = false
  created() {
    // let searchValue = window.location.search
    // // let searchValue = '?ticket=TLsUjM4OCFaPNE1RS0ed'
    // if (searchValue.indexOf('ticket=') === 1) {
    //   //从同一平台进来首页

    //     this.$router.push({ name: 'Home' })

    // } else {
    //   //从独立登录页面进入到  首页
    //   this.$router.push({ name: 'Login' })
    // }
    if (this.$route.params.bID && this.$route.params.bID === 'LOGIN001') {
      sessionStore.set('bID', this.$route.params.bID)
    } else if (this.$route.params.bID && this.$route.params.bID === 'P002') {
      sessionStore.set('bID', this.$route.params.bID)
    }
    if (localStore.get('zctToken')) {
      this.verifyStatus()
    }
    if (this.$route.params.flag === '1') {
      this.options = true
    }
    // this.handleFaceVerify()   //测试调用
  }
  // 按钮背景图片切换函数
  private changeActive(flag: boolean, index: number) {
    this.$set(this.imgActive, index, flag)
  }
  private verifyStatus(verLoading: any = null) {
    getVerifyStatus({})
      .then((res) => {
        let isAuth = res.data.data.needApply
        if (isAuth === '1') {
          let needAuth: boolean = false
          if (this.systemType != 'zct') {
            needAuth = true
          }
          if (needAuth) {
            if (verLoading) {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                verLoading.close()
                this.open('首次使用本系统，请完善信息并认证！', '提示', '1', '去认证', true, '退出')
                this.$store.commit('CHANGE_ROUTEARR', '0')
                this.$store.commit('MODIF_USERINFO', '0') // 修改为未认证
              })
            } else {
              this.open('首次使用本系统，请完善信息并认证！', '提示', '1', '去认证', true, '退出')
              this.$store.commit('CHANGE_ROUTEARR', '0')
              this.$store.commit('MODIF_USERINFO', '0') // 修改为未认证
            }
          } else {
            let userName: any = res.data.data.name
            validPersonnelUser()
              .then((res) => {
                if (res.data.data && res.data.data.isValid) {
                  reviseForPerson()
                    .then(() => {
                      sessionStorage.setItem('isSuperAdmin', '1')
                      this.homes = true
                      this.options = false
                      this.$store.commit('CHANGE_ROUTEARR', '1')
                      this.$store.commit('MODIF_USERINFO', '1') // 修改为已认证
                      sessionStorage.setItem('isZctOK', '1')
                      this.$store.commit('CHANGE_USERNAME', userName)
                      sessionStore.set('UsIF', userName)
                    })
                    .finally(() => {
                      if (verLoading) {
                        this.$nextTick(() => {
                          // 以服务的方式调用的 Loading 需要异步关闭
                          verLoading.close()
                        })
                      }
                    })
                } else {
                  this.open('首次使用本系统，请完善信息并认证！', '提示', '1', '去认证', true, '退出')
                  this.$store.commit('CHANGE_ROUTEARR', '0')
                  this.$store.commit('MODIF_USERINFO', '0') // 修改为未认证
                }
              })
              .catch(() => {
                if (verLoading) {
                  this.$nextTick(() => {
                    // 以服务的方式调用的 Loading 需要异步关闭
                    verLoading.close()
                  })
                }
              })
          }
        } else {
          if (this.systemType != 'zct') {
            sessionStorage.getItem('isSuperAdmin') && sessionStorage.setItem('isSuperAdmin', '0')
            sessionStorage.getItem('nowAreaconfigId') && sessionStorage.setItem('nowAreaconfigId', '')
            sessionStorage.getItem('nowAreaconfigInfo') && sessionStorage.setItem('nowAreaconfigInfo', '')
            getLoginAreaId().then((res) => {
              let datas: any = res.data.data
              if (datas) {
                sessionStorage.setItem('isSuperAdmin', datas.isSuperAdmin === null || datas.isSuperAdmin === undefined ? '' : datas.isSuperAdmin + '')
                sessionStorage.setItem('nowAreaconfigId', !datas.areaConfigId || datas.areaConfigId == -1 ? '' : datas.areaConfigId)
                if (datas.isSuperAdmin != '1' && datas.areaConfigId && datas.areaConfigId != -1) {
                  getLoginAreaInfo({ areaConfigId: datas.areaConfigId }).then((res) => {
                    let dataVal: any = res.data.data
                    if (dataVal) {
                      Object.keys(dataVal).forEach((key) => {
                        !dataVal[key] && (dataVal[key] = '')
                      })
                      sessionStorage.setItem('nowAreaconfigInfo', JSON.stringify(dataVal))
                    }
                  })
                }
              }
            })
          } else {
            sessionStorage.setItem('isSuperAdmin', '1')
          }
          this.homes = true
          this.options = false
          this.$store.commit('CHANGE_ROUTEARR', '1')
          this.$store.commit('MODIF_USERINFO', '1') // 修改为已认证
          if (verLoading) {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              verLoading.close()
            })
          }
        }
        if (res.data.data.role === '1') {
          this.$store.commit('CHANGE_USERNAME', res.data.data.name)
          sessionStore.set('UsIF', res.data.data.name)
        } else {
          this.$store.commit('CHANGE_USERNAME', res.data.data.companyName)
          sessionStore.set('UsIF', res.data.data.companyName)
        }
        sessionStore.get('bID') != 'LOGIN001' && res.data.data.phone && sessionStore.set('userPhone', res.data.data.phone)
      })
      .catch(() => {
        if (verLoading) {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            verLoading.close()
          })
        }
      })
  }

  // 个人识别
  private gotoFaceRecognition() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    validPersonnelUser()
      .then((res) => {
        if (res.data.data) {
          this.$confirm('确认为个人用户？', '系统提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              if (res.data.data.isValid) {
                let loadingInstance2 = Loading.service({ target: document.body, text: this.loadingTxt })
                reviseForPerson()
                  .then(() => {
                    this.$message.success('确认成功')
                    this.verifyStatus(loadingInstance2)
                  })
                  .catch((err) => {
                    this.$nextTick(() => {
                      // 以服务的方式调用的 Loading 需要异步关闭
                      loadingInstance2.close()
                    })
                    if (err.data.code == 1012) {
                      this.$router.push({ name: 'VerifyDetail', params: { type: '0', isPass: 'null' } })
                    }
                  })
              } else {
                this.$router.push({ name: 'VerifyDetail', params: { type: '0', isPass: 'null' } })
              }
            })
            .catch(() => {})
        } else {
          this.$message.error('验证返回异常')
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 政府机构
  private gotoGovernmentInstitution() {
    this.$router.push({ name: 'VerifyDetail', params: { type: '1', isPass: '6' } })
  }
  // 金融机构
  private gotoFinancialInstitution() {
    this.$router.push({ name: 'VerifyDetail', params: { type: '2', isPass: '6' } })
  }
  // 进来后查询用户信息进行拦截
  // private handlePersonInfo() {
  //   getPersonInfo({})
  //     .then((res) => {
  //       let datas: any = res.data.data
  //       if (datas.commonRealName === null) {
  //         this.open('未检测到您的身份信息，请完善身份信息再进入本系统！', '提示', '1')
  //         this.$store.commit('CHANGE_ROUTEARR', '0')
  //         this.$store.commit('MODIF_USERINFO', '0')
  //       } else {
  //         if (datas.isFirstLogin) {
  //           this.open('首次使用本系统，请进行人脸识别认证！', '提示', '0', '去认证', true, '退出')
  //           this.$store.commit('CHANGE_ROUTEARR', '0')
  //           this.$store.commit('MODIF_USERINFO', '0') //修改为未认证
  //         } else {
  //           this.$store.commit('CHANGE_ROUTEARR', '1')
  //           this.$store.commit('MODIF_USERINFO', '1') //修改为已认证
  //         }
  //       }
  //     })
  //     .catch((res) => {
  //       if (res.data.code === 1010) {
  //         this.open('对不起，您非本系统用户，无操作权限！', '提示', '1')
  //         this.$store.commit('CHANGE_ROUTEARR', '0')
  //         this.$store.commit('MODIF_USERINFO', '0') //修改为未认证
  //       }
  //     })
  // }
  // // 机构认证部分--------------------------------------------------------
  // getFormData(data: any) {
  //   addOrganizationData(data)
  //     .then((res) => {
  //       console.log(res.data)
  //     })
  //     .catch((res) => {})
  // }
  // 机构认证部分------------------------------------------end-----------

  // 个人认证部分-----------------------------------------------

  // // 人脸二维码验证结果返回
  // private resultVerify(data: any) {
  //   if (data) {
  //     // this.dialogTableVisibles = false
  //     this.$store.commit('MODIF_USERINFO', '1')
  //     this.$store.commit('CHANGE_ROUTEARR', '1')
  //   }
  // }
  // // 上传成功的返回
  // private getFileUrlUrl(data: any) {
  //   if (data.flag) {
  //     this.imageUrl = data.url
  //     // this.dialogTableVisible = false
  //     this.handleFaceVerify()
  //   } else {
  //     this.$message.warning('缺少上传图片返回的图片地址~~')
  //     return
  //   }
  // }
  // // 首次人脸接口
  // private handleFaceVerify() {
  //   if (this.imageUrl === '') {
  //     this.$message.warning('缺少上传图片返回的图片地址~~')
  //     return
  //   }
  //   let data: any = {
  //     imageUrl: this.imageUrl
  //   }
  //   firstFaceVerify(data)
  //     .then((res) => {
  //       if (res.data.data.callbackId && res.data.data.verifyFaceUrl) {
  //         this.callbackId = res.data.data.callbackId
  //         this.QrCodeUrl = res.data.data.verifyFaceUrl
  //         setTimeout(() => {
  //           this.QrCode = true
  //           this.uploadImg = false
  //         }, 1000)
  //       } else {
  //         this.$message.warning('缺少callbackId or verifyFaceUrl的数据')
  //         return
  //       }
  //     })
  //     .catch((res) => {
  //       console.log(res.data)
  //     })
  // }
  // 个人认证部分-----------------------------------------------end--------------
  // 弹框处理
  private open(message: string, title: string, flag: string, confirmButtonText?: string, showCancelButton?: boolean, cancelButtonText?: string) {
    this.$confirm(message, title, {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: false,
      confirmButtonText: confirmButtonText || '退出',
      cancelButtonText: cancelButtonText || '',
      showCancelButton: showCancelButton || false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then((res) => {
        if (res === 'confirm') {
          if (this.systemType != 'zct') {
            this.options = true
            this.homes = false
          } else {
            this.$router.push({ name: 'VerifyDetail', params: { type: '0', isPass: 'null' } })
          }
        }
      })
      .catch((res) => {
        let searchValue = getSearch()
        // let searchValue = '?ticket=TLsUjM4OCFaPNE1RS0ed'
        if (searchValue.indexOf('ticket=') === 1) {
          this.$store.dispatch('logout')
        } else {
          this.$router.push({ name: 'Login' })
        }
      })
  }
}
