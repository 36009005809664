
import { Component, Vue, Watch } from 'vue-property-decorator'
import loginBgImg from '@/assets/images/admin/loginBgImg.png'
import { forgetMessage, validPhone, resetforgetpwd } from '@/api/login'
@Component({})
export default class ZqtRegister extends Vue {
  private images: Object = {
    loginBgImg: loginBgImg
  }
  private img: string = ''

  private isVerity: boolean = true
  private formData: any = {
    phone: '',
    code: ''
  }
  private formDatas: any = {
    npassword: '',
    cfpassword: ''
  }

  private isShow: boolean = true
  private countTime: number = 0
  private timer: any = null
  // --------------------------------data---------end-----------------------------------------
  // 下一步
  private handleNextStep(): void {
    if (this.formData.phone === '') {
      this.$message.warning('手机号码不能为空')
      return
    }
    if (this.formData.code === '') {
      this.$message.warning('验证码不能为空')
      return
    }
    let datas: any = {
      code: this.formData.code,
      mobile: this.formData.phone
    }
    validPhone(datas).then((res) => {
      this.isShow = false
    })
  }
  // 重置密码
  private handleReset(): void {
    if (this.formDatas.npassword === '') {
      this.$message.warning('密码不能为空')
      return
    }
    if (this.formDatas.cfpassword === '') {
      this.$message.warning('密码不能为空')
      return
    }
    if (!(this.formDatas.npassword === this.formDatas.cfpassword)) {
      this.$message.warning('两次输入密码不同，请重新输入')
      return
    }
    let datas: any = {
      mobile: this.formData.phone,
      newPassword: this.formDatas.cfpassword
    }
    resetforgetpwd(datas).then((res) => {
      this.$router.push({ name: 'Login' })
    })
  }

  // private handleRegister() {
  //   let data: any = {
  //     mobile: this.formData.phone,
  //     password: this.formData.password,
  //     captcha: this.formData.captcha
  //   }
  //   singleRegiter(data)
  //     .then((res) => {
  //       this.$router.push({ name: 'Login' })
  //     })
  // }
  // 获取短信验证码
  private handleVerity() {
    if (this.formData.phone === '') {
      this.$message.warning('手机号不能为空')
      return
    }
    if (this.formData.phone.length < 11 || this.formData.phone.length > 11) {
      this.$message.warning('请输入正确的手机号码')
      return
    }
    let data: any = {
      mobile: this.formData.phone
    }
    forgetMessage(data).then((res) => {
      this.countDown()
    })
  }
  // 强制刷新
  // private handleChang(e:DragEvent){
  //   this.$forceUpdate()
  // }
  private returnLogin() {
    this.$router.push({ name: 'Login' })
  }
  // 倒计时
  private countDown() {
    clearInterval(this.timer)
    const TIME_COUNT = 60
    this.countTime = TIME_COUNT
    this.isVerity = false
    this.timer = setInterval(() => {
      if (this.countTime > 1 && this.countTime <= TIME_COUNT) {
        this.countTime = this.countTime - 1
      } else {
        this.isVerity = true
        clearInterval(this.timer)
        this.timer = null
      }
    }, 1000)
  }
}
