
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '../../type'
// 接口联调
import { getMortgageList, deleteMortgage } from '@/api/noticeManagement'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    mStatus: (str: number | string) => {
      if (str == 1) {
        return '在押'
      }
      if (str == 2) {
        return '解押'
      }
      return ''
    }
  }
})
export default class ProjectNotice extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private nowAreaconfigId: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }

  private mortgageStatus = [
    {
      value: '',
      label: '全部'
    },
    {
      value: '1',
      label: '在押'
    },
    {
      value: '2',
      label: '解押'
    }
  ]

  private formInfo: any = {
    projectNo: '',
    registrationNum: '',
    mortgagorName: '',
    mortgageHolderName: '',
    collateralName: '',
    mortgageState: '',
    time: ''
  }

  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'AddMortgageNotice' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        vim.nowAreaconfigId = sessionStorage.getItem('nowAreaconfigId')
        !vim.nowAreaconfigId && (isGetList = false)
      }
      isGetList && vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'AddMortgageNotice') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  toAddPage() {
    this.$router.push({ name: 'AddMortgageNotice', params: { id: 'null' } })
    // this.$router.push({ name: 'AddMortgageNotice' })
  }
  private query() {
    if (this.formInfo.time) {
      this.$set(this.formInfo, 'beginTime', this.formInfo.time[0])
      this.$set(this.formInfo, 'endTime', this.formInfo.time[1])
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }

    getMortgageList(params).then((res) => {
      const data = res.data
      this.tableData = data.data
      this.paginationParam.total = data.total
      this.loading = false
    })
  }
  private filteTime(str: any) {
    let returnStr: any = str
    if (str) {
      let nowStr: any = str.split(' ')[0]
      returnStr = nowStr.replace(/-/g, '/')
    }
    return returnStr
  }
  handleDetails(row: any) {
    this.$router.push({ name: 'AddMortgageNotice', params: { id: row.id } })
  }
  handleDelete(row: any) {
    this.$confirm('确定要删除项目编号为' + row.projectNo + '的抵押公告吗？', '温馨提示').then(() => {
      deleteMortgage({
        id: row.id
      }).then((res) => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.query()
      })
    })
  }
}
