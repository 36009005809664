
import { Component, Vue } from 'vue-property-decorator'

import numsTips from '@/components/numsTips/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '@/views/type'
import { loadingText } from '@/utils/defaultGoalConfig'
// 接口联调
import { searchArea, searchAreaStystem, getBannerList, getBannerEnable, getBannerDel } from '@/api/noticeManagement'

@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class BannerManagement extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private loading: boolean = false
  private isGetList: boolean = false
  private formInfo: any = {
    areaConfigId: '',
    systemConfigId: '',
    isEnable: ''
  }
  private projectType: any = [
    {
      key: '',
      value: '全部'
    }
  ]
  private areaData: any = [
    {
      areaConfigId: '',
      areaConfigName: '全部'
    }
  ]
  private systemData: any = []
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private systemOptions: any = []
  private filteZct(data: any) {
    let returnData: any = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (['资产通管理平台', '资产通'].indexOf(data[i].systemConfigName) == -1) {
          returnData.push(data[i])
        }
      }
    }
    return returnData
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'BannerManagementDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaConfigId && (vim.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaConfigId && (isGetList = false)
      }
      if (isGetList && (vim.projectType.length == 1 || vim.areaData.length == 1)) {
        searchArea({}).then((res) => {
          if (res.data.data) {
            if (vim.projectType.length == 1 && res.data.data.project && res.data.data.project.length) {
              vim.projectType = vim.projectType.concat(res.data.data.project)
            }
            if (vim.areaData.length == 1 && res.data.data.aeras && res.data.data.aeras.length) {
              vim.areaData = vim.areaData.concat(vim.filteAreaOptions(res.data.data.aeras))
            }
          }
        })
      }
      if (isGetList && vim.isSuperAdmin != '1' && !vim.systemData.length) {
        searchAreaStystem({ areaConfigId: vim.formInfo.areaConfigId }).then((res) => {
          if (res.data.data) {
            vim.systemData = res.data.data
          }
        })
      }
      isGetList && vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'BannerManagementDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        projectType: this.projectType,
        areaData: this.areaData,
        systemData: this.systemData,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 根据区域获取系统
  private handleSelectedArea(id: any) {
    if (!this.formInfo.areaConfigId) {
      return
    }
    this.formInfo.systemConfigId = ''
    let data: any = {
      areaConfigId: id
    }
    searchAreaStystem(data).then((res) => {
      if (res.data && res.data.code == 1000 && res.data.data) {
        this.systemData = res.data.data
      }
    })
  }
  private query() {
    if (this.time) {
      this.$set(this.formInfo, 'beginTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params: any = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    getBannerList(params).then((res) => {
      if (res.data.data) {
        //处理置顶状态 字段 String => Boolean
        res.data.data.forEach((item: any) => {
          if (!item.isEnable || item.isEnable === '0') {
            item.booleanTopState = false
          } else {
            item.booleanTopState = true
          }
        })
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private handleSwitch(value: boolean, id: any) {
    let params: any = {
      isEnable: value ? '1' : '0',
      id
    }
    getBannerEnable(params).then((res) => {
      this.query()
    })
  }
  private handleSearch() {
    this.query()
  }
  handleDelete(row: any) {
    this.$confirm('确定要删除此banner吗？', '温馨提示').then(() => {
      getBannerDel({
        id: row.id
      }).then((res) => {
        this.query()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    })
  }
  private handleDetails(id: any) {
    this.$router.push({
      name: 'BannerManagementDetail',
      params: {
        id
      }
    })
  }
}
