
import { Component, Vue } from 'vue-property-decorator'
import { standardDetail, standardAdd } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class StandardOption extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private formInfo: any = {
    village: '',
    township: '',
    county: ''
  }
  created() {
    standardDetail()
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          Object.keys(this.formInfo).forEach((key) => {
            this.formInfo[key] = datas[key] ? datas[key] : ''
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private changeInput(value: any, name: string) {
    if (value > 100) {
      this.formInfo[name] = '100'
    } else {
      this.formInfo[name] = value.replace(/[^\d\.]/g, '')
    }
  }
  private vaildFn() {
    for (let key in this.formInfo) {
      if (!this.formInfo[key]) {
        this.$message.error('存在必填项未填')
        return true
      }
    }
  }
  private saveFn() {
    if (this.vaildFn()) {
      return
    }
    this.loading = true
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = parseFloat(this.formInfo[key]) + ''
    })
    standardAdd({
      creditStandardConfigs: [
        {
          key: 'village',
          value: this.formInfo.village
        },
        {
          key: 'township',
          value: this.formInfo.township
        },
        {
          key: 'county',
          value: this.formInfo.county
        }
      ]
    })
      .then(() => {
        this.$message.success('提交成功')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
