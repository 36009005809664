
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { setSuccessfulBidder, setTransactionPrice } from '@/api/countrysidePropertyTransaction'
import { Loading } from 'element-ui'
@Component({
  components: {}
})
export default class AuctionInfo extends Vue {
  @Prop() info?: any
  @Prop({ default: false }) isSetPerson?: any
  @Prop({ default: '' }) listingApplyId?: any
  @Prop({ default: false }) transactionAmountShow?: any
  @Prop({ default: false }) transactionPriceShow?: any
  private loadingTxt: string = loadingText
  private isCost: any = '0' // 保证金抵扣(1:是,0:否)
  private isCostFinal: any = '0' // 保证金抵扣(1:是,0:否)
  private markShow: boolean = false
  private markShowFinal: boolean = false //成交价款弹窗显示
  private transactionAmount: string = ''
  private transactionPrice: string = ''
  private nowRow: any
  unitTimeOption = {
    '0': '无',
    '1': '年 ',
    '2': '季度 ',
    '3': '月 '
  }
  unitAppend(info) {
    if (info && info.bidType == 1) {
      return `${(info.unitNum == '无' || !info.unitNum ? '' : '/' + info.unitNum) + (info.unitTime == '0' || !info.unitTime ? '' : '/' + this.unitTimeOption[info.unitTime])}`
    } else {
      return ''
    }
  }
  private setPerson(row: any) {
    this.markShow = true
    this.nowRow = row
  }
  //设置成交价款
  private setFinalPrice(row: any) {
    this.markShowFinal = true
    this.nowRow = row
  }
  private closeFn() {
    this.markShow = false
  }
  private confirm() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (!this.transactionAmount) {
      this.$message({
        message: '请输入服务费金额',
        type: 'error'
      })
      loadingInstance.close()
      return
    } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2}))|0)$/.test(this.transactionAmount)) {
      this.$message({
        message: '请输入正确的交易服务费，最多保留2位小数',
        type: 'error'
      })
      loadingInstance.close()
      return
    }
    this.markShow = false
    setSuccessfulBidder({
      applyId: this.listingApplyId || '',
      delistingId: this.nowRow.id,
      transactionAmount: this.transactionAmount
    })
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message.success('设置成功')
          this.$set(this.nowRow, 'status', 1)
          this.$emit('update:isSetPerson', false)
          this.$emit('setPersonSuccess')
          window.location.reload()
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  //设置成交价款
  private confirmFinal() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (!this.transactionPrice) {
      this.$message({
        message: '请输入成交价款金额',
        type: 'error'
      })
      loadingInstance.close()
      return
    } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2}))|0)$/.test(this.transactionPrice)) {
      this.$message({
        message: '请输入正确的成交价款，最多保留2位小数',
        type: 'error'
      })
      loadingInstance.close()
      return
    }
    this.markShow = false
    setTransactionPrice({
      applyId: this.listingApplyId || '',
      delistingId: this.nowRow.id,
      use_promise_amount: this.isCostFinal == 1 ? true : false,
      transactionPrice: this.transactionPrice
    })
      .then((res) => {
        if (res.data.code == 1000) {
          this.$message.success('设置成功')
          this.$set(this.nowRow, 'status', 1)
          this.$emit('update:isSetPerson', false)
          this.$emit('setPersonSuccess')
          window.location.reload()
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
