var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-limit-detail" }, [
    _c("div", { staticClass: "form-style" }, [
      _c("div", { staticClass: "item position-relative" }, [
        _c("div", [
          _vm.pageType == "add" ? _c("span", [_vm._v("*")]) : _vm._e(),
          _vm._v("项目：")
        ]),
        _c("div", [
          _vm.pageType != "add"
            ? _c("span", [_vm._v(_vm._s(_vm.showInfo.name))])
            : _c(
                "div",
                {
                  staticClass: "select-input",
                  on: {
                    mouseenter: function($event) {
                      _vm.isFocus = true
                    },
                    mouseleave: function($event) {
                      _vm.isFocus = false
                    }
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请选择",
                      "suffix-icon": "el-icon-search",
                      disabled: _vm.isSuperAdmin != "1" && !_vm.nowAreaconfigId
                    },
                    on: {
                      focus: _vm.changeInput,
                      input: _vm.changeInput,
                      blur: _vm.outDiv
                    },
                    model: {
                      value: _vm.showInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.showInfo, "name", $$v)
                      },
                      expression: "showInfo.name"
                    }
                  }),
                  _vm.showInput
                    ? _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.upLoad,
                              expression: "upLoad"
                            }
                          ],
                          staticClass: "input-list",
                          attrs: {
                            "infinite-scroll-disabled": _vm.disabled,
                            "infinite-scroll-immediate": true,
                            "infinite-scroll-distance": "28"
                          }
                        },
                        [
                          !_vm.loadin && !_vm.searchResult.length
                            ? _c("li", { staticClass: "list-item tips-item" }, [
                                _vm._v("无数据")
                              ])
                            : _vm._e(),
                          _vm._l(_vm.searchResult, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "list-item",
                                on: {
                                  click: function($event) {
                                    return _vm.getSelectData(item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          _vm.loadin
                            ? _c("li", { staticClass: "list-item tips-item" }, [
                                _vm._v("加载中...")
                              ])
                            : _vm._e(),
                          _vm.noMore
                            ? _c("li", { staticClass: "list-item tips-item" }, [
                                _vm._v("没有更多了")
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
        ])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", [_vm._v("所属区域：")]),
        _c("div", [_c("span", [_vm._v(_vm._s(_vm.showInfo.areaConfigName))])])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", [_vm._v("业务类型：")]),
        _c("div", [_vm._v(_vm._s(_vm.showInfo.businessTypeDesc))])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", [
          _vm.pageType == "add" ? _c("span", [_vm._v("*")]) : _vm._e(),
          _vm._v("限制参与个人/企业：")
        ]),
        _c(
          "div",
          { staticClass: "table-item" },
          [
            _c(
              "el-button",
              {
                staticClass: "global-dotted-btn vertical-bottom",
                attrs: { size: "small" },
                on: { click: _vm.addFn }
              },
              [_vm._v("添加")]
            ),
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.showInfo.list,
                  "header-cell-style": {
                    textAlign: "center",
                    background: "#FAFAFA"
                  },
                  "cell-style": { textAlign: "center" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    index: index => index + 1,
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "类型",
                    align: "center",
                    "min-width": "106px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.noEitd
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.type == 2
                                      ? "个人"
                                      : scope.row.type == 1
                                      ? "企业"
                                      : scope.row.type
                                  )
                                )
                              ])
                            : _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small"
                                  },
                                  model: {
                                    value: scope.row.type,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "type", $$v)
                                    },
                                    expression: "scope.row.type"
                                  }
                                },
                                _vm._l(_vm.typeOptions, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "姓名/企业名称",
                    "min-width": "120px",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.noEitd
                            ? _c("span", [_vm._v(_vm._s(scope.row.name))])
                            : _c("el-input", {
                                attrs: { size: "small" },
                                model: {
                                  value: scope.row.name,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "name", $$v)
                                  },
                                  expression: "scope.row.name"
                                }
                              })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "身份证号/社会信用代码",
                    "min-width": "200px",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.noEitd
                            ? _c("span", [_vm._v(_vm._s(scope.row.idCode))])
                            : _c("el-input", {
                                attrs: { size: "small" },
                                model: {
                                  value: scope.row.idCode,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "idCode", $$v)
                                  },
                                  expression: "scope.row.idCode"
                                }
                              })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    "min-width": "80px",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteDetails(scope.$index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "btn-style" },
      [
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.handleReturn } },
          [_vm._v("返回")]
        ),
        _c(
          "el-button",
          {
            attrs: {
              disabled: _vm.isSuperAdmin != "1" && !_vm.nowAreaconfigId,
              size: "small",
              type: "primary"
            },
            on: { click: _vm.handleSubmit }
          },
          [_vm._v("提交")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }