var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "print",
      staticClass: "trade-receipt",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-button",
        {
          ref: "btn",
          staticClass: "btn",
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.clickprinting }
        },
        [_vm._v("打印凭证")]
      ),
      _c("div", { staticClass: "personal_receipt" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("全国综合业务服务平台交易凭证")
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [_vm._v("交易日期：" + _vm._s(_vm.obj.createdTime))])
          ]),
          _c("div", { staticClass: "receipt_item_left" })
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [_vm._v("交易流水号：" + _vm._s(_vm.obj.approvalId))])
          ]),
          _c("div", { staticClass: "receipt_item_left" })
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [
              _vm._v(" 付款方名称：" + _vm._s(_vm.obj.payName) + " ")
            ])
          ]),
          _vm._m(0)
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [
              _vm._v(" 付款方开户行：" + _vm._s(_vm.obj.payOpenBank) + " ")
            ])
          ]),
          _vm._m(1)
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [
              _vm._v(" 付款方账号：" + _vm._s(_vm.obj.payAccount) + " ")
            ])
          ]),
          _vm._m(2)
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [_vm._v("金额(元)：" + _vm._s(_vm.obj.totalAmount))])
          ]),
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [
              _vm._v(
                "金额(大写)：" +
                  _vm._s(_vm._f("numMoneyToChinese")(_vm.obj.totalAmount))
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "receipt_item" }, [
          _c("div", { staticClass: "receipt_item_left" }, [
            _c("span", [_vm._v("用途：" + _vm._s(_vm.obj.resultMsg))])
          ])
        ]),
        _c("img", { staticClass: "receipt_icon", attrs: { src: _vm.seal } }),
        _c("div", { staticClass: "receipt_people" }, [
          _c("span", { staticClass: "interval" }, [
            _vm._v("打印时间：" + _vm._s(_vm.date))
          ]),
          _c("span", { staticClass: "interval" }, [
            _vm._v("打印人员：" + _vm._s(_vm.userName))
          ])
        ])
      ]),
      _vm.tableData.length
        ? _c(
            "div",
            { staticClass: "receipt_table" },
            [
              _c("p", { staticClass: "title2" }, [
                _vm._v("全国综合业务服务平台交易凭证(附表)")
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "header-cell-style": { textAlign: "center" },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序列号" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_vm._v(_vm._s(scope.$index))]
                          }
                        }
                      ],
                      null,
                      false,
                      3789870691
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "收款方名字" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "openBank", label: "收款方开户行" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bankAccount", label: "收款方账号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "amount", label: "金额(元)" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "receipt_people" }, [
                _c("span", { staticClass: "interval" }, [
                  _vm._v("打印时间：" + _vm._s(_vm.date))
                ]),
                _c("span", { staticClass: "interval" }, [
                  _vm._v("打印人员：" + _vm._s(_vm.userName))
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "receipt_item_left" }, [
      _c("span", [_vm._v(" 收款方名称：见附表")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "receipt_item_left" }, [
      _c("span", [_vm._v(" 收款方开户行：见附表")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "receipt_item_left" }, [
      _c("span", [_vm._v(" 收款方账号：见附表")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }