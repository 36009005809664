
import { Component, Vue } from 'vue-property-decorator'
import numsTips from '@/components/numsTips/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '@/views/type'

import { handleEnterpriseFinancingPage } from '@/api/financing'
import { listingWayOptions, listingStatusOption } from '../../options'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    listingWay: (val: any) => {
      let label = ''
      listingWayOptions.map((item: any) => {
        if (item.value == val) {
          label = item.label
        }
      })
      return label
    }
  }
})
export default class ProjectList extends Vue {
  private loading: boolean = false
  // 业务类型选择参数
  private listingWayOptions = listingWayOptions
  // 状态选择参数
  private listingStatusOption = listingStatusOption
  private formInfo: any = {
    demandName: '',
    listingMode: '',
    status: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []

  created() {
    this.getList()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getList() {
    let params = {
      id: this.$route.params.id,
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    this.loading = true
    handleEnterpriseFinancingPage(params)
      .then((res) => {
        this.tableData = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'FinancialDetails',
      params: { type: encodeURI(row.status), id: row.id, listingMode: row.listingMode }
    })
  }
}
