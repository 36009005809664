
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { PersonnelForm } from '@/views/type'

// 接口联调
// import { getProjectList, deleteProject } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
import { getCompanyBankBusinessList, deleteCompanyBankBusiness } from '@/api/center'

import numsTips from '@/components/numsTips/index.vue'

@Component({
  components: {
    numsTips
  },
  filters: {
    filterStatus: (status: any) => {
      switch (status) {
        case 1:
          return '实时付款'
          break
        case 2:
          return '实时收款'
          break
        case 3:
          return '批量付款'
          break
        case 4:
          return '批量代收'
          break
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class UnitList extends Vue {
  // private paginationParam: any = {
  //   pageNumber: 1,
  //   pageSize: 10,
  //   phone: '',
  //   name: ''
  // }
  private roleOption: string = ''
  private statusOption: string = ''

  private disabled: boolean = false

  private roleOptions: any = []
  private businessItem: any = [
    { label: '实时付款', value: 1 },
    { label: '实时收款', value: 2 },
    { label: '批量代付', value: 3 },
    { label: '批量代收', value: 4 }
  ]
  private formInfo: any = {
    pageNumber: 1,
    pageSize: 10,
    type: 2,
    bankCard: '',
    bussinessType: '',
    bankName: '',
    feeCode: ''
  }
  private companyType: any = [
    { label: '政府部门', value: 1 },
    { label: '金融机构', value: 2 }
  ]
  private currentPage: number = 1

  private pagerCount: number = 11
  private pageSize: number = 10
  private pageSizesList: any = [10, 20, 40, 80]
  private tableData: any = []
  private loading = false
  private totalCount: number = 0
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (['CompanyBankBusiness', 'CompanyImportBankBusiness'].indexOf(from.name) > -1 && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.totalCount = pageParams.totalCount
      }
      vim.handleBankBusinessList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['CompanyBankBusiness', 'CompanyImportBankBusiness'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo,
        totalCount: this.totalCount
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'pageNumber' && key != 'pageSize' && key != 'type') {
        this.formInfo[key] = ''
      }
    })
  }
  private mounted() {
    // getAllRole()
    //   .then((res) => {
    //     let datas = res.data
    //     this.roleOptions = datas.data
    //   })
  }

  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  // 分页--改变每页条数触发 -----
  private handleSizeChange(pageSize: number): void {
    this.formInfo.pageSize = pageSize
    this.handleBankBusinessList()
  }
  // 分页--改变页码数触发 -----
  private handleCurrentChange(currentPage: number): void {
    this.formInfo.pageNumber = currentPage
    this.handleBankBusinessList()
  }

  selecttedRole(value: number): void {
    // this.formInfo.roleId = value
  }

  selecttedStatus(value: string): void {
    // this.statusOption = value
  }
  // 获取银行业务列表数据
  private handleBankBusinessList(): void {
    // let checkPersonData: any = this.formInfo
    if (this.formInfo.bussinessType === '') {
      this.formInfo.bussinessType = null
    }
    let data: any = {
      ...this.formInfo
    }
    getCompanyBankBusinessList(data)
      .then((res) => {
        let datas: any = res.data
        this.tableData = res.data.data
        this.totalCount = datas.total
      })
      .catch(() => {})
      .finally()
  }
  // // 搜索银行业务列表
  private handleSearch() {
    this.handleBankBusinessList()
  }

  // 查看列表详情
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'CompanyBankBusiness',
      params: { id: datas.id, flag: 'check', datas }
    })
  }
  private handleImportBusiness(): void {
    this.$router.push({
      name: 'CompanyImportBankBusiness'
    })
  }
  // 新增
  private handleAddBusiness(): void {
    this.$router.push({
      name: 'CompanyBankBusiness',
      params: { id: '', flag: 'add' }
    })
  }

  // 删除
  handleDelete(row: any) {
    let data: any = {
      id: row.id
    }
    this.$confirm('确定删除？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        deleteCompanyBankBusiness(data).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.handleBankBusinessList()
        })
      })
      .catch(() => {})
  }
}
