
import Vue from 'vue'
import * as areaConfigApi from '@/api/area-config'
import Component from 'vue-class-component'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'

@Component({})
export default class AreaConfigInsert extends Vue {
  private loadingTxt: string = loadingText
  public saveModel: any = {
    name: '',
    province: '',
    city: '',
    area: '',
    applicationSystemConfig: [],
    applicationSystemConfigs: [],
    address: '',
    contactNumber: '',
    postcode: '',
    websiteFiling: ''
  }
  public isUpdate: Boolean = false

  public applicationSystem: Array<any> = []
  public applicationMap: any = {}

  mounted() {
    if (this.$route.params) {
      this.isUpdate = this.$route.params.isUpdate == '1'
      if (this.$route.params.saveModel) {
        let result: any = this.$route.params.saveModel
        this.saveModel.id = result.id
      }
    }

    areaConfigApi.getAllSystem().then((data: any) => {
      data = data.data
      if (data.code === 1000) {
        // this.applicationSystem = data.data
        this.$set(this, 'applicationSystem', data.data)
        for (var index in data.data) {
          var result = data.data[index]
          this.applicationMap[result.name] = result.id
        }

        if (this.isUpdate) {
          areaConfigApi.selectById(this.saveModel.id).then((data) => {
            const responseData = data.data
            if (responseData.code === 1000) {
              const defaultData = responseData.data
              let applicationSystemConfig: any = []

              defaultData.applicationSystemConfigs.forEach((element: any) => {
                applicationSystemConfig.push(element.name)
              })

              defaultData.applicationSystemConfigs = applicationSystemConfig
              this.saveModel = defaultData
            }
          })
        }
      }
    })
  }
  get filterApplicationSystem() {
    let returnObj: any = []
    if (this.applicationSystem && this.applicationSystem.length) {
      for (let i = 0; i < this.applicationSystem.length; i++) {
        if (['zct', 'zctUser'].indexOf(this.applicationSystem[i].code) == -1) {
          returnObj.push(this.applicationSystem[i])
        }
      }
    }
    return returnObj
  }
  goBack() {
    this.$router.push({ name: 'AreaConfig' })
  }

  confirm() {
    let result,
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    this.saveModel.applicationSystemConfig = []
    this.saveModel.applicationSystemConfigs.forEach((element: string) => {
      this.saveModel.applicationSystemConfig.push(this.applicationMap[element])
    })
    if (this.isUpdate) {
      result = areaConfigApi.update(this.saveModel)
    } else {
      result = areaConfigApi.insert(this.saveModel)
    }
    result
      .then((data) => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.goBack()
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
