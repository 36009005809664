var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.formInfo, "label-width": "150px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "业务类型：" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formInfo.properties == 1,
                    expression: "formInfo.properties == 1"
                  }
                ],
                model: {
                  value: _vm.formInfo.type,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "type", $$v)
                  },
                  expression: "formInfo.type"
                }
              }),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInfo.properties != 1,
                      expression: "formInfo.properties != 1"
                    }
                  ],
                  attrs: { disabled: _vm.formInfo.properties != 0 },
                  model: {
                    value: _vm.formInfo.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "type", $$v)
                    },
                    expression: "formInfo.type"
                  }
                },
                _vm._l(_vm.list, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字段：" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("添加字段")]
              ),
              _vm._l(_vm.formInfo.fieldList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "list" },
                  [
                    _c("el-input", {
                      attrs: { maxlength: 15 },
                      model: {
                        value: item.field,
                        callback: function($$v) {
                          _vm.$set(item, "field", $$v)
                        },
                        expression: "item.field"
                      }
                    }),
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: item.required,
                          callback: function($$v) {
                            _vm.$set(item, "required", $$v)
                          },
                          expression: "item.required"
                        }
                      },
                      [_vm._v("必填")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          ),
          _vm.$route.params.id != "add"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit("update")
                    }
                  }
                },
                [_vm._v("修改")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit("add")
                    }
                  }
                },
                [_vm._v("提交")]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }