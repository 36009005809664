
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class SettlementInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: () => {
      return {}
    }
  })
  checkboxValue?: any
  @Prop({ default: null }) checkboxValueShow?: any
}
