var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-upload",
    {
      staticClass: "uploader",
      attrs: {
        action: _vm.uploadFileUrl,
        accept: _vm.accept,
        limit: _vm.limit,
        "list-type": _vm.listType,
        "on-exceed": _vm.handleExceed,
        "on-preview": _vm.handlePreview,
        "on-remove": _vm.handleRemove,
        "on-success": _vm.handleUploadSuccess,
        "on-error": _vm.handleUploadError,
        "before-upload": _vm.beforeUpload,
        "show-file-list": _vm.listShow
      }
    },
    [
      _vm.uploadUrl
        ? _c("img", {
            staticClass: "image",
            attrs: { slot: "trigger", src: _vm.uploadUrl },
            slot: "trigger"
          })
        : _c(
            "div",
            {
              staticClass: "info-container",
              attrs: { slot: "trigger" },
              slot: "trigger"
            },
            [
              _c("div", [
                _c("i", { staticClass: "el-icon-plus image-uploader-icon" }),
                !_vm.uploadUrl ? _c("div", [_vm._v("上传营业执照")]) : _vm._e()
              ])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }