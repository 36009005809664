
import { Component, Vue } from 'vue-property-decorator'
import { getBlackInfo, addBlackInfo } from '@/api/countrysidePropertyTransaction'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class BlacklistDetail extends Vue {
  private loadingTxt: string = loadingText
  private rules: any = {
    type: { required: true, message: '请选择个人/企业', trigger: 'change' },
    name: { required: true, message: '请输入姓名/企业名称', trigger: 'blur' },
    cardOrCode: { required: true, message: '请输入身份证号/社会信用代码', trigger: 'blur' },
    reason: { required: true, message: '请输入添加原因', trigger: 'blur' }
  }
  private typeOptions: any = [
    { label: '个人', value: '2' },
    { label: '企业', value: '1' }
  ]
  private formInfo: any = {
    type: '',
    name: '',
    cardOrCode: '',
    reason: ''
  }
  private type: any = ''
  created() {
    if (this.$route.params.businessType) {
      this.type = 'add'
    } else {
      for (let key in this.rules) {
        this.rules[key].required = false
      }
      this.getDetail()
    }
  }
  // 详情
  private getDetail() {
    getBlackInfo({ id: this.$route.params.id }).then((res) => {
      if (res.data && res.data.data && res.data.code == 1000) {
        this.formInfo = res.data.data
      }
    })
  }
  // 添加黑名单
  private handleSubmit() {
    for (let key in this.formInfo) {
      if (!this.formInfo[key].trim()) {
        this.$message.error('请确保选择或输入了所以字段，再提交!!')
        return
      }
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let params: any = {
      businessType: this.$route.params.businessType,
      ...this.formInfo
    }
    addBlackInfo(params)
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          this.$message.success('添加成功')
          this.$router.go(-1)
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
}
