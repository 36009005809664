
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getRoleMenus, getPersonInfo, firstFaceVerify } from '@/api/login'
import { localStore, sessionStore } from '@/utils/data-management'
// import { Message } from 'element-ui'
// import QrCode from '@/components/QrCodeFirst.vue'
// import authForm from '@/components/authForm/index.vue'
import { uploadFileUrl } from '@/api/common.api'
import { getVerifyStatus } from '@/api/login'
import { getProvinceList, getCityList, getCountyList } from '@/api/peasantInfoManagement'

@Component({
  components: {},
  filters: {
    filterStatus(data: any) {
      switch (data) {
        case '0':
          return '审核中'
          break
        case '1':
          return '已通过审核'
          break
        case '2':
          return '审核不通过'
          break
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }
      }
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      let D = date.getDate() + ' '
      let h = date.getHours() + ':'
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      let s = date.getSeconds()
      return Y + M + D + h + m + s
    }
  }
})
export default class AccountManagement extends Vue {
  private isZctOK: any = null
  private showClose: boolean = false
  private isModalClose: boolean = false
  private imageUrl: string = ''
  private QrCodeUrl: string = ''
  private callbackId: string = ''

  private type: number = 0
  private datas: any = {}

  created() {
    this.isZctOK = sessionStorage.getItem('isZctOK')
    this.verifyStatus()
  }
  mounted() {
    // this.handlePropertyRightTransactionCenter()
    // this.handleIndustry()
    // this.handleProvinceData()
  }

  // 查询当前用户的身份
  private verifyStatus() {
    getVerifyStatus({}).then((res) => {
      this.datas = res.data.data
      localStore.remove('ISPASSSTATUS')
      localStore.set('ISPASSSTATUS', res.data.data.auditStatus)
      sessionStore.get('bID') != 'LOGIN001' && res.data.data.phone && sessionStore.set('userPhone', res.data.data.phone)
    })
  }

  private gotoCheckDetail() {
    this.$router.push({
      name: 'VerifyDetail',
      params: { type: '4', isPass: this.datas.auditStatus }
    })
  }

  // 机构认证部分------------------------------------------end-----------

  // 个人认证部分-----------------------------------------------

  // 人脸二维码验证结果返回
  // private resultVerify(data: any) {
  //   if (data) {
  //     this.dialogTableVisibles = false
  //     this.$store.commit('MODIF_USERINFO', '1')
  //     this.$store.commit('CHANGE_ROUTEARR', '1')
  //   }
  // }
  // 上传成功的返回
  // private getFileUrlUrl(data: any) {
  //   if (data.flag) {
  //     this.imageUrl = data.url
  //     // this.dialogTableVisible = false
  //     this.handleFaceVerify()
  //   } else {
  //     this.$message.warning('缺少上传图片返回的图片地址~~')
  //     return
  //   }
  // }

  // 首次人脸接口
  // private handleFaceVerify() {
  //   if (this.imageUrl === '') {
  //     this.$message.warning('缺少上传图片返回的图片地址~~')
  //     return
  //   }
  //   let data: any = {
  //     imageUrl: this.imageUrl
  //   }
  //   firstFaceVerify(data)
  //     .then((res) => {
  //       if (res.data.data.callbackId && res.data.data.verifyFaceUrl) {
  //         this.callbackId = res.data.data.callbackId
  //         this.QrCodeUrl = res.data.data.verifyFaceUrl
  //         setTimeout(() => {
  //           this.QrCode = true
  //           this.uploadImg = false
  //         }, 1000)
  //       } else {
  //         this.$message.warning('缺少callbackId or verifyFaceUrl的数据')
  //         return
  //       }
  //     })
  //     .catch((res) => {
  //       console.log(res.data)
  //     })
  // }
  // 个人认证部分-----------------------------------------------end--------------
  // 弹框处理
  // private open(
  //   message: string,
  //   title: string,
  //   flag: string,
  //   confirmButtonText?: string,
  //   showCancelButton?: boolean,
  //   cancelButtonText?: string
  // ) {
  //   this.$confirm(message, title, {
  //     distinguishCancelAndClose: false,
  //     type: 'warning',
  //     showClose: false,
  //     confirmButtonText: confirmButtonText || '退出',
  //     cancelButtonText: cancelButtonText || '',
  //     showCancelButton: showCancelButton || false,
  //     closeOnClickModal: false,
  //     closeOnPressEscape: false,
  //     center: true,
  //     lockScroll: true
  //   })
  //     .then(() => {
  //       if (flag === '1') {
  //         this.$store.dispatch('logout')
  //       } else if (flag === '0') {
  //         this.handleDialogTableVisible()
  //       }
  //     })
  //     .catch((res) => {
  //       this.$store.dispatch('logout')
  //     })
  // }
}
