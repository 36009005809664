
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { creditRatingPage, delRating } from '@/api/creditRatingManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class FarmerCreditList extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    name: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'CreditRatingConfigDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'CreditRatingConfigDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    creditRatingPage(params).then((res) => {
      if (res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 搜索农户信用列表
  private handleSearch() {
    this.getList()
  }
  // 查看农户信用详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'CreditRatingConfigDetail',
      query: { id: row.id || '' }
    })
  }
  // 删除
  private del(row: any) {
    this.$confirm('删除后将无法恢复，确认删除该条数据吗？', '删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        delRating({ id: row.id })
          .then((res) => {
            if (res.data.code == 1000) {
              this.$message.success('删除成功')
              this.getList()
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
