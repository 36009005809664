
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import IconRefresh from '@/assets/images/icon_refresh.png'
import IconSuccess from '@/assets/images/success.png'
import { getQRCodeInfo, checkQRCodeStatus, valid, checkIsOk, jsAppQR, checkJSAppQR } from '@/api/common.api'
import { QRCodeCheckParam, QRCodeInfoParam, QRCode } from '@/utils/types'
import { firstFaceVerify } from '@/api/login'
import { Verify } from 'crypto'
import jsonbigStringParse from '@/utils/jsonbigStringParse'
import * as secret from '@/utils/secret'

enum QRCodeCheckResult {
  Waiting = '未过期',
  Timeout = '已过期',
  Success = '成功'
}
type VerifyResult = {
  icon: string
  text: string
}
const INTERVAL: number = 5000

@Component({
  components: {
    QrcodeVue
  }
})
export default class QrCode extends Vue {
  @Prop({ default: 160 }) private size!: number
  @Prop({ default: '' }) private imageurl!: string
  @Prop({ required: false }) private visible?: boolean
  @Prop() private defaultText?: string
  @Prop({
    default: () => {
      return {
        name: '',
        idCard: ''
      }
    }
  })
  private userInfo?: any
  private radio: number = 1
  private icons = {
    refresh: IconRefresh,
    success: IconSuccess
  }
  private loading: boolean = false
  private status: QRCode.VerifyStatus = QRCode.VerifyStatus.Waiting
  private timer: number = -1
  private btnDisabled: boolean = false
  private callbackId: string = ''
  private QrCodeUrl: string = ''

  @Watch('visible')
  private visibleHandler(newVal: boolean, oldVal: boolean) {
    if (newVal === true) {
      this.getQrcodeUrl()
    } else {
      if (this.timer) clearInterval(this.timer)
    }
  }
  mounted() {
    this.getQrcodeUrl()
  }
  beforeDestroy() {
    window.clearInterval(this.timer)
  }

  private get verifyResult(): VerifyResult {
    switch (this.status) {
      case QRCode.VerifyStatus.Timeout:
        return { icon: this.icons.refresh, text: '请点击二维码刷新' }
      case QRCode.VerifyStatus.Success:
        return { icon: this.icons.success, text: '认证成功!' }
      default:
        return { icon: '', text: this.defaultText || '请用手机扫码确认' }
    }
  }
  // 刷新
  private refreshQRCode(): void {
    if (this.status === QRCode.VerifyStatus.Timeout) {
      this.getQrcodeUrl()
    }
  }
  private getQrcodeUrl(): void {
    this.loading = true
    window.clearInterval(this.timer)
    if (this.imageurl === '') {
      this.$message.warning('缺少上传图片返回的图片地址~~')
      return
    }
    let params: any = null
    if (this.radio == 1) {
      params = {
        imageUrl: this.imageurl
      }
      firstFaceVerify(params)
        .then((res) => {
          if (this.radio == 2) {
            return
          }
          if (res.data.data.callbackId && res.data.data.verifyFaceUrl) {
            this.callbackId = res.data.data.callbackId
            this.QrCodeUrl = res.data.data.verifyFaceUrl
            this.status = QRCode.VerifyStatus.Waiting
            this.timer = window.setInterval(() => {
              this.checkQrcode()
            }, INTERVAL)
          } else {
            this.$message.warning('缺少callbackId or verifyFaceUrl的数据')
            return
          }
        })
        .finally(() => {
          if (this.radio == 2) {
            return
          }
          this.loading = false
        })
    } else {
      if (this.userInfo) {
        if (!this.userInfo.name) {
          this.$message.warning('找不到用户名~~')
          return
        } else if (!this.userInfo.idCard) {
          this.$message.warning('找不到身份证号~~')
          return
        }
      } else {
        this.$message.warning('找不到用户信息~~')
        return
      }
      params = {
        ...this.userInfo,
        identityFront: this.imageurl
      }
      jsAppQR(params)
        .then((res) => {
          if (this.radio == 1) {
            return
          }
          if (res.data.data) {
            let data = jsonbigStringParse(secret.default.decrypt(res.data.data.data, 'GoxZ3fT0Xnmc0yAu', 'uvz37k2WLPZgUES4'))
            this.QrCodeUrl = JSON.stringify(res.data.data)
            this.callbackId = data.callbackId
            this.status = QRCode.VerifyStatus.Waiting
            this.timer = window.setInterval(() => {
              this.checkQrcode()
            }, INTERVAL)
          }
        })
        .finally(() => {
          if (this.radio == 1) {
            return
          }
          this.loading = false
        })
    }
  }
  private checkQrcode(): void {
    const param: any = {
      callbackId: this.callbackId
    }
    if (this.radio == 1) {
      checkIsOk(param)
        .then((res: any) => {
          if (this.radio == 2) {
            return
          }
          if (res.data.data) {
            switch (res.data.data.status) {
              case 0:
                this.status = QRCode.VerifyStatus.Waiting
                break
              case 1:
                this.status = QRCode.VerifyStatus.Success
                clearInterval(this.timer)
                this.$emit('notify', true)
                break
              case 2:
                this.status = QRCode.VerifyStatus.Timeout
                clearInterval(this.timer)
                this.$message.error('认证失败，请刷新二维码重试！')
                break
            }
          }
        })
        .catch((error) => {
          if (this.radio == 2) {
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
    } else {
      checkJSAppQR(param)
        .then((res) => {
          if (this.radio == 1) {
            return
          }
          if (res.data.data) {
            switch (res.data.data.status) {
              case 0:
                this.status = QRCode.VerifyStatus.Waiting
                break
              case 1:
                this.status = QRCode.VerifyStatus.Success
                clearInterval(this.timer)
                this.$emit('notify', true)
                break
              case 2:
                this.status = QRCode.VerifyStatus.Timeout
                clearInterval(this.timer)
                this.$message.error(res.data.data.result ? res.data.data.result : '认证失败，请刷新二维码重试！')
                break
            }
          }
        })
        .catch(() => {
          if (this.radio == 1) {
            return
          }
          this.status = QRCode.VerifyStatus.Timeout
          clearInterval(this.timer)
        })
    }
  }
}
