
import { Component, Vue } from 'vue-property-decorator'
// 接口联调
import { addFile, modifyFile, updateFile } from '@/api/config'
//其它
import { ConfigAddFile } from '@/views/type'
import uploadTemp from '@/components/UploadFile/index.vue'
import * as dictionaryService from '@/api/dictionary'
import { AxiosResponse } from 'axios'

// @Prop({
//     default: () => {
//       ;[]
//     },
//     type: Object || Array
//   }) private dataDetail!: Object
@Component({
  name: 'Layout',
  components: {
    uploadTemp
  }
})
export default class ProjectNotice extends Vue {
  private loading: boolean = false
  private businessTypeList = []
  private productTypeList = []
  private processTypeList = []
  private roleList = []
  private listingModeList = []
  private generatePositionList = []
  private rules = {
    // bizType: { required: true, message: '请选择业务类型', trigger: 'change' },
    // roleType: { required: true, message: '请选择角色', trigger: 'change' },
    // processType: { required: true, message: '请选择流程分类', trigger: 'change' },
    // directionalFinancingType: { required: true, message: '请选择产品分类', trigger: 'change' },
    type: { required: true, message: '请输入文件类型', trigger: 'blur' },
    fileName: { required: true, message: '请输入文件名称', trigger: 'blur' },
    fileType: { required: true, message: '请输入文件唯一标识', trigger: 'blur' },
    role: { required: true, message: '请选择角色', trigger: 'blur' },
    requireNumber: { required: true, message: '请输入最少需要数量', trigger: 'blur' },
    maxNumber: { required: true, message: '请输入最大可上传数量', trigger: 'blur' },
    isRequire: { required: true, message: '请选择', trigger: 'blur' },
    sort: { required: true, message: '请输入排序', trigger: 'blur' },
    generatePosition: { required: true, message: '请选择生成位置', trigger: 'blur' },
    listingMode: { required: true, message: '请选择类型', trigger: 'blur' }
  }

  public deleteDownloadUrl() {
    delete this.formInfo.downloadUrl
    this.formInfo.canDownload = false
  }

  private formInfo: ConfigAddFile = {
    isRequire: true,
    canDownload: false,
    requireNumber: 1,
    maxNumber: 1,
    sort: 0
  }
  // private isEdit: number = 1
  private disabled: boolean = false
  private isUpdate: boolean = false
  private fileList: Array<any> = []
  get maxMin() {
    let returnVal: any = 0
    if (this.formInfo.requireNumber) {
      returnVal = Number(this.formInfo.requireNumber)
    }
    return returnVal
  }
  created() {
    if (!sessionStorage.getItem('configAddFile')) {
      sessionStorage.setItem('configAddFile', JSON.stringify(this.$route.params))
    }
    let configAddFile: any = sessionStorage.getItem('configAddFile')
    const params = configAddFile ? JSON.parse(configAddFile as any) : this.$route.params
    if (params != null && params.dataDetail) {
      const dataDetail: any = params.dataDetail
      this.formInfo = dataDetail
    }
    if (params != null && params.isUpdate == '1') {
      this.isUpdate = true
    } else if (params != null) {
      delete this.formInfo.id
    }
    if (this.formInfo.downloadUrl) {
      this.$set(this.fileList, 0, {
        name: this.formInfo.fileName,
        url: this.formInfo.downloadUrl
      })
    }
  }
  mounted() {
    this.getConfig()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('configAddFile')
    next()
  }
  private requireNumberBlur() {
    if (!(this.formInfo as any).maxNumber && (this.formInfo as any).maxNumber != 0) {
      this.$set(this.formInfo, 'maxNumber', this.formInfo.requireNumber ? this.formInfo.requireNumber : 0)
      return
    }
    if ((this.formInfo as any).requireNumber > (this.formInfo as any).maxNumber) {
      this.$set(this.formInfo, 'maxNumber', this.formInfo.requireNumber ? this.formInfo.requireNumber : 0)
    }
  }
  private isRequireChange(value: any) {
    if (!value) {
      this.formInfo.requireNumber = 1
      this.formInfo.maxNumber = 1
    }
  }
  //获取配置信息
  private async getConfig() {
    this.loading = true

    dictionaryService
      .getDictionaryByName('listing_mode')
      .then((res: AxiosResponse<any>) => {
        let result = res.data
        if (result.code === 1000) {
          this.listingModeList = res.data.data
          return dictionaryService.getDictionaryByName('role')
        }
        throw result.message
      })
      .then((res: AxiosResponse<any>) => {
        let result = res.data
        if (result.code === 1000) {
          this.roleList = res.data.data
          return dictionaryService.getDictionaryByName('generate_position')
        }
        throw result.message
      })
      .then((res: AxiosResponse<any>) => {
        let result = res.data
        if (result.code === 1000) {
          this.generatePositionList = result.data
        }
      })
      .catch((e) => {
        this.$message(e)
      })
      .finally(() => {
        this.loading = false
      })
  }

  //上传文件成功
  fileUploadSuccess(event: any) {
    if (event) {
      this.formInfo.downloadUrl = event
      this.formInfo.canDownload = true
    }
  }

  checkFile(url: string) {
    if (url.length > 0) {
      return true
    }
    return
  }

  submit(formName: string): any {
    this.loading = true
    if (!this.formInfo.type) {
      this.$message({
        type: 'error',
        message: '请选择类型'
      })
      return
    }
    if (this.formInfo.type == 2) {
      this.formInfo.requireNumber = 1
      this.formInfo.maxNumber = 1
      delete this.formInfo.downloadUrl
      this.formInfo.canDownload = false
    }
    ;(this as any).$refs[formName].validate((valid: any, data: object) => {
      if (valid) {
        if (this.isUpdate) {
          this.updateData()
        } else {
          this.insertData()
        }
      } else {
        return false
      }
    })
  }

  updateData() {
    let params = {
      ...this.formInfo
    }
    if (!params.isRequire) {
      params.requireNumber = 0
      params.maxNumber = 0
    }
    updateFile(params)
      .then((res) => {
        this.$message({
          type: 'success',
          message: '成功'
        })
        this.goBack()
      })
      .finally(() => {
        this.loading = false
      })
  }

  insertData() {
    let params = {
      ...this.formInfo
    }
    if (!params.isRequire) {
      params.requireNumber = 0
      params.maxNumber = 0
    }
    addFile(params)
      .then((res) => {
        this.$message({
          type: 'success',
          message: '成功'
        })
        this.goBack()
      })
      .finally(() => {
        this.loading = false
      })
  }
  handleModifyFile() {
    let params = {
      ...this.formInfo
    }
    if (!params.isRequire) {
      params.requireNumber = 0
      params.maxNumber = 0
    }
    modifyFile(params)
      .then((res) => {
        this.$message({
          type: 'success',
          message: '成功'
        })
        this.goBack()
      })
      .finally(() => {
        this.loading = false
      })
  }

  goBack() {
    this.$router.push({ name: 'FileConfig' })
  }

  querySearchAsync(queryString: any, cb: any) {
    // 根据查询内容请求接口返回 匹配的数组
    let aaa = [{ value: queryString }]
    cb(aaa)
  }
}
