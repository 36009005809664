
import { Component, Vue } from 'vue-property-decorator'
import { VillageDetail, TownshipDetail, CountyDetail, creditApprovalSubmit } from '@/api/regionalCreditManagement'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class RegionalApprovalDetails extends Vue {
  private loadingTxt: string = loadingText
  private mainLoading: boolean = false
  private assessStatus: number = 1
  // 时间线状态logo
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  // 时间线状态
  private iconType: object = ['primary', 'success', 'danger']
  private options: string = ''
  private isGetSuccess = false
  private villageId: any
  private approvalId: any
  private datas: any = {}
  private apprvalGroups: any = []
  created() {
    this.mainLoading = true
    let routeParams = this.$route.params
    if (routeParams.id) {
      this.approvalId = routeParams.id
      this.villageId = routeParams.villageId
      sessionStorage.setItem('regionalApprovalDetails', this.approvalId + ',' + this.villageId)
    } else {
      let idArr: any = sessionStorage.getItem('regionalApprovalDetails')
      idArr = idArr.split(',')
      this.approvalId = idArr[0]
      this.villageId = idArr[1]
    }
    let params: any = {
      assessApprovalId: this.approvalId,
      villageId: this.villageId,
      // isApproval: true
      functionType: 2
    }
    if (routeParams.type == '0') {
      VillageDetail(params)
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            let creditAssessApproval: any = res.data.data.creditAssessApproval
            if (creditAssessApproval && creditAssessApproval.creditAssessApprovalGroups) {
              let creditAssessApprovalGroups: any = creditAssessApproval.creditAssessApprovalGroups
              this.apprvalGroups = creditAssessApprovalGroups
              if (creditAssessApprovalGroups.length && creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion) {
                this.options = creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion
              }
            }
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    } else if (routeParams.type == '1') {
      TownshipDetail(params)
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            let creditAssessApproval: any = res.data.data.creditAssessApproval
            if (creditAssessApproval && creditAssessApproval.creditAssessApprovalGroups) {
              let creditAssessApprovalGroups: any = creditAssessApproval.creditAssessApprovalGroups
              this.apprvalGroups = creditAssessApprovalGroups
              if (creditAssessApprovalGroups.length && creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion) {
                this.options = creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion
              }
            }
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    } else {
      CountyDetail(params)
        .then((res) => {
          if (res.data.data) {
            this.datas = res.data.data
            let creditAssessApproval: any = res.data.data.creditAssessApproval
            if (creditAssessApproval && creditAssessApproval.creditAssessApprovalGroups) {
              let creditAssessApprovalGroups: any = creditAssessApproval.creditAssessApprovalGroups
              this.apprvalGroups = creditAssessApprovalGroups
              if (creditAssessApprovalGroups.length && creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion) {
                this.options = creditAssessApprovalGroups[creditAssessApprovalGroups.length - 1].opinion
              }
            }
            this.isGetSuccess = true
          }
        })
        .finally(() => {
          this.mainLoading = false
        })
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('regionalApprovalDetails')
    next()
  }
  private approvalSubmit(isAgree: number) {
    if (!this.options.trim()) {
      this.$message.error('请填写审批意见！！')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
      params: any = { id: this.approvalId, isAgree: isAgree, opinion: this.options }
    this.$route.params.approvalType == '1' && (params.assessStatus = this.assessStatus)
    creditApprovalSubmit(params)
      .then((res) => {
        this.$message.success('提交成功')
        setTimeout(() => {
          this.$router.go(-1)
        }, 300)
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
