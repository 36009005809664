
import { Component, Vue } from 'vue-property-decorator'
import ProInfo from '../center-list/centers/pro-info.vue'
import ProBaseInfo from '../center-list/centers/pro-base-info.vue'
import proProductRelated from '../center-list/centers/pro-product-related.vue'
import proInfoPerson from '../center-list/centers/pro-info-person.vue'
import proInfoContract from '../center-list/centers/pro-info-contract.vue'
import dialogLaw from '../center-list/centers/dialog-law.vue'
import QrCode from '@/components/QrCode.vue'
import { getLawDetail, lawSubmit } from '@/api/law'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
// import { isNeedFace } from '@/api/common.api'
@Component({
  components: {
    ProInfo,
    ProBaseInfo,
    proProductRelated,
    proInfoPerson,
    proInfoContract,
    QrCode,
    dialogLaw
  }
})
export default class LawDetail extends Vue {
  private loadingTxt: string = loadingText
  private routeParams: any = {}
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {}
  private submitInfo: any = {
    id: '',
    scanFile: ''
  }
  private qrDiaShow: boolean = false
  private detailtype: any = '' //  type: '',1.已上传 0 未上传
  created() {
    this.routeParams = this.$route.params
    if (this.routeParams.id) {
      sessionStorage.setItem('lawDetail', this.routeParams.id)
    } else {
      this.routeParams.id = sessionStorage.getItem('lawDetail')
    }
    this.detailtype = this.routeParams.type
    this.getList()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('lawDetail')
    next()
  }
  //获取详情
  private async getList() {
    getLawDetail({ id: this.routeParams.id })
      .then((res) => {
        this.proDetail = res.data.data
        if (res.data.data.scanFile) this.submitInfo.scanFile = res.data.data.scanFile
      })
      .finally(() => {})
  }
  // 提交法律意见书
  lawPopEvt() {
    if (!this.submitInfo.scanFile) {
      this.$message.warning('请上传扫描件')
      this.activeName = '9'
      return false
    }
    this.submitInfo.id = this.routeParams.id
    // isNeedFace({})
    //   .then((res) => {
    //     if (res.data.data && res.data.data.result) {
    //       let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
    //         params: any = {
    //           ...this.submitInfo,
    //           callbackId: res.data.data.callbackId
    //         }
    //       lawSubmit(params)
    //         .then((res) => {
    //           this.$message.success('提交成功')
    //           setTimeout(() => {
    //             this.$router.push({ name: 'LawList' })
    //           }, 300)
    //         })
    //         .finally(() => {
    //           this.$nextTick(() => {
    //             // 以服务的方式调用的 Loading 需要异步关闭
    //             loadingInstance.close()
    //           })
    //         })
    //     } else {
    //       this.qrDiaShow = true
    //     }
    //   })
    //   .catch(() => {
    this.qrDiaShow = true
    // })
  }
  // 获取文件路径
  getUrl(v: any) {
    if (v.flag == 1) this.submitInfo.scanFile = v.url
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      this.qrDiaShow = false
      let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
        params: any = {
          ...this.submitInfo,
          callbackId
        }
      lawSubmit(params)
        .then((res) => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.push({ name: 'LawList' })
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
}
