var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "main",
    { staticClass: "dictionary-insert" },
    [
      _c(
        "el-form",
        {
          ref: "saveModel",
          attrs: { model: _vm.saveModel, "label-width": "150px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "区域名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入区域名称" },
                model: {
                  value: _vm.saveModel.name,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "name", $$v)
                  },
                  expression: "saveModel.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "省份：", prop: "province" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入对应的省份" },
                model: {
                  value: _vm.saveModel.province,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "province", $$v)
                  },
                  expression: "saveModel.province"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "城市：", prop: "city" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入对应的城市" },
                model: {
                  value: _vm.saveModel.city,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "city", $$v)
                  },
                  expression: "saveModel.city"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "区/县：", prop: "area" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入对应的区/县" },
                model: {
                  value: _vm.saveModel.area,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "area", $$v)
                  },
                  expression: "saveModel.area"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "系统：", prop: "applicationSystemConfig" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.saveModel.applicationSystemConfigs,
                    callback: function($$v) {
                      _vm.$set(_vm.saveModel, "applicationSystemConfigs", $$v)
                    },
                    expression: "saveModel.applicationSystemConfigs"
                  }
                },
                _vm._l(_vm.filterApplicationSystem, function(item) {
                  return _c("el-checkbox", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "small-title" }, [_vm._v("网站版权信息：")]),
          _c(
            "el-form-item",
            { attrs: { label: "地址：", prop: "address" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.saveModel.address,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "address", $$v)
                  },
                  expression: "saveModel.address"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "contactNumber" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.saveModel.contactNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "contactNumber", $$v)
                  },
                  expression: "saveModel.contactNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮编：", prop: "postcode" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.saveModel.postcode,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "postcode", $$v)
                  },
                  expression: "saveModel.postcode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "网站备案号：", prop: "websiteFiling" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.saveModel.websiteFiling,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "websiteFiling", $$v)
                  },
                  expression: "saveModel.websiteFiling"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirm()
                }
              }
            },
            [_vm._v(_vm._s(_vm.isUpdate ? "修改" : "新增"))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }