
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
// import { getPersonnelList, getAllRole } from '@/api/personnel'
import { Loading } from 'element-ui'
import { getProvinceList, getCityList, getCountyList, getTownshipList, addVillageInfo, updateVillageInfo, getAllMangerRole, getMangerPersonnelList } from '@/api/peasantInfoManagement'
@Component({
  components: {
    Pagination
  }
})
export default class VillageDetail extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private nowAreaconfigInfo: any
  private areaId: any = ''
  private isChange: boolean = false
  private firstIntoManager: boolean = true
  private loading: boolean = false
  private managerBox: boolean = false
  private managerList: any = []
  private copyManagerList: any = []
  private tableData: any = []
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private markInfo: any = {
    name: '',
    roleId: ''
  }
  private roleOptions: any = []
  private rules: any = {
    province: { required: true, message: '请选择省份', trigger: 'change' },
    city: { required: true, message: '请选择城市', trigger: 'change' },
    county: { required: false },
    township: { required: false },
    villageName: { required: false },
    ruralFarmerAverageIncome: { required: false, message: '请输入', trigger: 'blur' },
    villageFarmerNumber: { required: false, message: '请输入', trigger: 'blur' },
    shouldCollectFarmerNumber: { required: false, message: '请输入', trigger: 'blur' }
  }
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private provinceCode: string = ''
  private cityCode: string = ''
  private countyCode: string = ''
  private townshipCode: string = ''
  private requestEnd: boolean = false
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    villageId: '',
    ruralFarmerAverageIncome: '',
    villageFarmerNumber: '',
    shouldCollectFarmerNumber: ''
  }
  created() {
    let type = this.$route.params.type,
      info: any = sessionStorage.getItem('villageDetail'),
      isGetList: boolean = true
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    this.nowAreaconfigInfo = sessionStorage.getItem('nowAreaconfigInfo') ? JSON.parse(sessionStorage.getItem('nowAreaconfigInfo') as any) : ''
    this.isSuperAdmin != '1' && sessionStorage.getItem('nowAreaconfigId') && (this.areaId = sessionStorage.getItem('nowAreaconfigId'))
    if (type == 'up') {
      info = JSON.parse(info)
      for (let k in this.formInfo) {
        this.formInfo[k] = info[k] || info[k] === 0 ? info[k] : ''
      }
      info.managePeopleList && (this.managerList = info.managePeopleList)
      if (this.formInfo.villageName) {
        this.rules.villageFarmerNumber.required = true
        this.rules.shouldCollectFarmerNumber.required = true
      }
    } else {
      if (this.isSuperAdmin != '1') {
        if (this.nowAreaconfigInfo) {
          !this.formInfo.province && (this.formInfo.province = this.nowAreaconfigInfo.provinceCode && this.nowAreaconfigInfo.provinceName ? this.nowAreaconfigInfo.provinceName + ',' + this.nowAreaconfigInfo.provinceCode : '')
          !this.formInfo.city && (this.formInfo.city = this.nowAreaconfigInfo.cityCode && this.nowAreaconfigInfo.cityName ? this.nowAreaconfigInfo.cityName + ',' + this.nowAreaconfigInfo.cityCode : '')
          !this.formInfo.county && (this.formInfo.county = this.nowAreaconfigInfo.areaCode && this.nowAreaconfigInfo.areaName ? this.nowAreaconfigInfo.areaName + ',' + this.nowAreaconfigInfo.areaCode : '')
          ;(!this.formInfo.province || !this.formInfo.city) && (isGetList = false)
        } else {
          isGetList = false
        }
      }
    }
    isGetList &&
      getProvinceList()
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.provinceOptions = res.data.data
            if (type == 'up') {
              for (let i = 0; i < this.provinceOptions.length; i++) {
                let nowObj = this.provinceOptions[i]
                if (nowObj.provinceName == info.province) {
                  this.provinceCode = nowObj.provinceCode
                  break
                }
              }
              getCityList({ provinceCode: this.provinceCode })
                .then((res) => {
                  if (res.data && res.data.code == 1000) {
                    this.cityOptions = res.data.data
                    for (let i = 0; i < this.cityOptions.length; i++) {
                      let nowObj = this.cityOptions[i]
                      if (nowObj.cityName == info.city) {
                        this.cityCode = nowObj.cityCode
                        break
                      }
                    }
                    getCountyList({ cityCode: this.cityCode })
                      .then((res) => {
                        if (res.data && res.data.code == 1000) {
                          this.countyOptions = res.data.data
                          if (info.county) {
                            for (let i = 0; i < this.countyOptions.length; i++) {
                              let nowObj = this.countyOptions[i]
                              if (nowObj.areaName == info.county) {
                                this.countyCode = nowObj.areaCode
                                break
                              }
                            }
                            getTownshipList({ areaCode: this.countyCode })
                              .then((res) => {
                                if (res.data && res.data.code == 1000) {
                                  this.townshipOptions = res.data.data
                                  for (let i = 0; i < this.townshipOptions.length; i++) {
                                    let nowObj = this.townshipOptions[i]
                                    if (nowObj.streetName == info.township) {
                                      this.townshipCode = nowObj.streetCode
                                      break
                                    }
                                  }
                                }
                              })
                              .finally(() => {
                                this.requestEnd = true
                              })
                          } else {
                            this.requestEnd = true
                          }
                        } else {
                          this.requestEnd = true
                        }
                      })
                      .catch(() => {
                        this.requestEnd = true
                      })
                  } else {
                    this.requestEnd = true
                  }
                })
                .catch(() => {
                  this.requestEnd = true
                })
            } else {
              this.requestEnd = true
            }
          } else {
            this.requestEnd = true
          }
        })
        .catch(() => {
          this.requestEnd = true
        })
    if (type == 'add' && this.isSuperAdmin != '1' && isGetList) {
      getCityList({ provinceCode: this.formInfo.province.split(',')[1] }).then((res) => {
        if (res.data.data) {
          this.cityOptions = res.data.data
        }
      })
      getCountyList({ cityCode: this.formInfo.city.split(',')[1] }).then((res) => {
        if (res.data.data) {
          this.countyOptions = res.data.data
        }
      })
      this.formInfo.county &&
        getTownshipList({ areaCode: this.formInfo.county.split(',')[1] }).then((res) => {
          if (res.data.data) {
            this.townshipOptions = res.data.data
          }
        })
    }
  }
  beforeDestroy() {
    sessionStorage.removeItem('villageDetail')
  }
  private hasCounty() {
    if (this.isSuperAdmin != '1' && this.nowAreaconfigInfo && this.nowAreaconfigInfo.areaCode) {
      return true
    } else {
      return false
    }
  }
  private loadRole() {
    if (this.firstIntoManager) {
      getAllMangerRole().then((res) => {
        if (res.data.data) {
          this.roleOptions = res.data.data
          this.firstIntoManager = false
        }
      })
    }
  }
  private initManagerSelect() {
    this.markInfo = {
      name: '',
      roleId: ''
    }
    this.paginationParam = {
      pageIndex: 1,
      pageSize: 10,
      total: 0
    }
  }
  private closeManagerBox() {
    this.managerBox = false
    this.initManagerSelect()
  }
  private cancleItem(personId: any) {
    !this.isChange && (this.isChange = true)
    let deleteInd: number = 0
    for (let i = 0; i < this.copyManagerList.length; i++) {
      if (personId == this.copyManagerList[i].personInfoId) {
        deleteInd = i
        break
      }
    }
    this.copyManagerList.splice(deleteInd, 1)
  }
  private isSelected(personId: string) {
    let returnFlag: boolean = false
    for (let i = 0; i < this.copyManagerList.length; i++) {
      if (personId == this.copyManagerList[i].personInfoId) {
        returnFlag = true
        break
      }
    }
    return returnFlag
  }
  private selectedManager(row: any) {
    !this.isChange && (this.isChange = true)
    this.copyManagerList.push({
      personInfoId: row.personId,
      managerName: row.name,
      phone: row.phone
    })
  }
  private comfirmFn() {
    if (this.isChange) {
      if (this.copyManagerList.length) {
        let nowManagerList: any = JSON.stringify(this.copyManagerList)
        this.managerList = JSON.parse(nowManagerList)
      } else {
        this.managerList = []
      }
    }
    this.managerBox = false
    this.initManagerSelect()
  }
  private managerShow() {
    if (this.managerList.length) {
      let nowManager: any = JSON.stringify(this.managerList)
      this.copyManagerList = JSON.parse(nowManager)
    } else {
      this.copyManagerList = []
    }
    this.managerBox = true
    this.loadRole()
    this.getManagerList()
  }
  private getManagerList() {
    this.loading = true
    let params: any = {
      ...this.markInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    this.areaId && (params.areaId = this.areaId)
    getMangerPersonnelList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[1] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[1] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[1] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private villageNameBlur() {
    if (this.formInfo.villageName.trim() && !this.rules.villageFarmerNumber.required) {
      this.rules.villageFarmerNumber.required = true
      this.rules.shouldCollectFarmerNumber.required = true
    } else if (!this.formInfo.villageName.trim() && this.rules.villageFarmerNumber.required) {
      this.rules.villageFarmerNumber.required = false
      this.rules.shouldCollectFarmerNumber.required = false
    }
  }
  private async handleSubmit() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let params = {
      ...this.formInfo
    }
    for (let key in params) {
      if (params[key] && ['province', 'city', 'county', 'township'].indexOf(key) != -1) {
        params[key] = params[key].split(',')[0]
      } else if (params[key] !== null && !(params[key] + '').trim()) {
        params[key] = null
      }
    }
    if (!params.province || !params.city) {
      this.$message({
        message: '省份和城市为必选字段',
        type: 'error'
      })
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
      return
    }
    if (params.township) {
      let isNoPass: boolean = false,
        errorTitle: string = ''
      if (!params.ruralFarmerAverageIncome && params.ruralFarmerAverageIncome !== 0) {
        // isNoPass = true
        // errorTitle = '乡镇农户年人均纯收入不能为空！！'
      } else if (isNaN(params.ruralFarmerAverageIncome)) {
        isNoPass = true
        errorTitle = '乡镇农户年人均纯收入为数字！！'
      }
      if (isNoPass) {
        this.$message({
          message: errorTitle,
          type: 'error'
        })
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      }
    }
    if (params.villageName) {
      let isNoPass: boolean = false,
        errorTitle: string = ''
      if ((!params.villageFarmerNumber && params.villageFarmerNumber !== 0) || (!params.shouldCollectFarmerNumber && params.shouldCollectFarmerNumber !== 0)) {
        isNoPass = true
        errorTitle = '全村农户总数和应采集农户信息数不能为空！！'
      } else if (isNaN(params.villageFarmerNumber) || isNaN(params.shouldCollectFarmerNumber)) {
        isNoPass = true
        errorTitle = '全村农户总数和应采集农户信息数为数字！！'
      }
      if (isNoPass) {
        this.$message({
          message: errorTitle,
          type: 'error'
        })
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
        return
      }
    }
    if (!this.managerList.length) {
      this.$message({
        message: '管理员必选',
        type: 'error'
      })
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
      return
    } else {
      params.personInfoIds = []
      for (let i = 0; i < this.managerList.length; i++) {
        params.personInfoIds.push(this.managerList[i].personInfoId)
      }
    }
    if (this.$route.params.type == 'up') {
      updateVillageInfo(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            setTimeout(() => {
              this.handleReturn()
            }, 300)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      addVillageInfo(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            setTimeout(() => {
              this.handleReturn()
            }, 300)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }
  private handleReturn() {
    this.$router.go(-1)
  }
  // @Watch('formInfo.township', { immediate: true })
  // handler(newV: any) {
  //   newV
  //     ? (this.rules.ruralFarmerAverageIncome.required = true)
  //     : (this.rules.ruralFarmerAverageIncome.required = false)
  // }
}
