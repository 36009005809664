
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  getApprovalDetail, //审批详情接口
  goToApproval, // 预审批接口
  finalApproval, // 终审批接口
  decisionApproval, // 决策委员会审批接口
  acceptanceApproval // 受理审批接口
} from '@/api/center' //接口
import ProInfo from './centers/pro-info.vue'
import ProBaseInfo from './centers/pro-base-info.vue'
import proProductRelated from './centers/pro-product-related.vue'
import proInfoPerson from './centers/pro-info-person.vue'
import appointInvestment from './centers/appoint-investment.vue'
import proInfoApproval from './centers/pro-info-approval.vue'
import QrCode from '@/components/QrCode.vue'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
// import { isNeedFace } from '@/api/common.api'
@Component({
  components: {
    ProInfo, // 产品相关
    ProBaseInfo, // 项目立项
    proProductRelated, // 产品相关
    proInfoPerson, // 相关人
    appointInvestment, // 指定投资人
    proInfoApproval, // 审批信息
    QrCode // 授权认证二维码
  }
})
export default class CentetDetail extends Vue {
  private loadingTxt: string = loadingText
  private routeParams: any = {}
  private activeName: any = '1' // 当前 tab 选项
  private detailtype: any = '' //  type: '', //1-预审批 2-终审批 3-受理审批 4-成立审批 5-决策委员会审批(预) 6-决策委员会审批(终)
  private isSecond: any = 'false' // 二次审批标志
  private remark: any = '' // 审批信息可编辑文本
  private isInvest: any = '1'
  private proDetail: any = {} //详情数据
  private qrDiaShow: boolean = false //授权认证标志
  private syncTag: number = 0 //返回状态模块数记录
  private refuseItem: any = [] //返回字段名
  private refuseFileItem: any = [] //返回文件
  private refuseApproval: any = {} //返回审批信息字段集合
  private filesUrlArr: any = {} //返回上传文件路径
  private getItemStatus: any = {
    //模块返回信息控制
    projectDetails: false,
    productReleative: false,
    issuer: false,
    guarantors: false,
    provider: false,
    administrator: false,
    designatedInvestor: false,
    approval: false
  }
  // 是否允许投资展示标志
  private allowInvest: any = false
  // 相关人模块null的数量
  private isNullNums: number = 0
  //详情数据
  created() {
    this.routeParams = this.$route.params
    if (this.routeParams.id) {
      sessionStorage.setItem('centerDetail', this.routeParams.id)
    } else {
      this.routeParams.id = sessionStorage.getItem('centerDetail')
    }
    this.detailtype = this.routeParams.type
    this.isSecond = this.routeParams.secondApproval + ''
    if (['1', '3'].indexOf(this.routeParams.bizType) == -1) {
      this.allowInvest = true
    }
    if (this.detailtype == 4) {
      this.activeName = '1'
    } else {
      this.activeName = '2'
    }
    this.getList()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('centerDetail')
    next()
  }
  // 文件上传成功方法
  private fileUploadSuccess(url: any, title: any) {
    if (url) {
      this.$message({
        message: '上传成功',
        type: 'success'
      })
      this.filesUrlArr[title] = url
    } else {
      this.$message({
        message: '上传失败，请重试',
        type: 'error'
      })
    }
  }
  // 文件上传失败方法
  private fileUploadError(err: any, title: any) {
    this.$message({
      message: err,
      type: 'error'
    })
  }
  // 获取返回状态字段
  private addRefuseItem(title: string, obj: any, infoFiles: any = []) {
    if (title == 'approval') {
      this.refuseApproval = obj
    } else if (title != 'guarantors') {
      if (this.detailtype != 2 || this.detailtype != 6) {
        let idArry = this.proDetail[title].fieldList
        for (let key in obj) {
          if (obj[key]) {
            for (let i = 0; i < idArry.length; i++) {
              if (idArry[i].fieldName == key) {
                let addObj = {
                  id: idArry[i].id,
                  status: false
                }
                this.refuseItem.push(addObj)
              }
            }
          }
        }
      }
      this.refuseFileItem = this.refuseFileItem.concat(infoFiles)
    } else {
      if (infoFiles.length == 1) {
        this.refuseFileItem = this.refuseFileItem.concat(infoFiles[0])
      } else {
        for (let i = 0; i < infoFiles.length - 1; i++) {
          this.refuseFileItem = this.refuseFileItem.concat(infoFiles[i], infoFiles[i + 1])
        }
      }
      if (this.detailtype == 2 || this.detailtype == 6) {
        this.syncTag++
        return
      }
      for (let l = 0; l < this.proDetail[title].length; l++) {
        let idArry = this.proDetail[title][l].fieldList
        for (let key in obj[l]) {
          if (obj[l][key]) {
            for (let i = 0; i < idArry.length; i++) {
              if (idArry[i].fieldName == key) {
                let addObj = {
                  id: idArry[i].id,
                  status: false
                }
                this.refuseItem.push(addObj)
              }
            }
          }
        }
      }
    }
    this.syncTag++
  }
  //获取详情
  private getList() {
    getApprovalDetail({ id: this.routeParams.id })
      .then((res) => {
        if (res.data.code == 1000) {
          this.proDetail = res.data.data
          this.remark = res.data.data.remark
          if (!this.proDetail.guarantors || (this.proDetail.guarantors && !this.proDetail.guarantors.length)) {
            this.isNullNums += 1
          }
          !this.proDetail.provider && (this.isNullNums += 1)
          !this.proDetail.administrator && (this.isNullNums += 1)
        }
      })
      .finally(() => {})
  }
  // 更改投资状态方法
  private changeIsInvest(flag: boolean) {
    this.isInvest = flag
  }
  // 获取审批意见
  getRemark(v: any) {
    this.remark = v
  }
  // 返回页面
  returnPaper() {
    this.$router.go(-1)
  }
  // 同意，拒绝，保留
  approvalEvt(flag: any) {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let params: any = {
      remark: this.remark,
      isAgree: flag,
      id: this.routeParams.id,
      approvalFiles: []
    }
    if (this.detailtype == 1) {
      params.vote = 0
      params.payType = this.proDetail.payType
      // params.limitDay = this.proDetail.limitDay
      params.setPayTypePermission = this.proDetail.setPayTypePermission
    } else if (this.detailtype == 2) {
      params.vote = 0
    }
    if ((flag == 2 || flag == 3) && (this.detailtype == 1 || this.detailtype == 5)) {
      this.refuseItem = []
      this.refuseFileItem = []
      params.fieldStatuses = []
      for (let key in this.getItemStatus) {
        this.getItemStatus[key] = true
      }
      let maxTag = this.routeParams.bizType == '5' ? 8 - this.isNullNums : 7 - this.isNullNums
      let timer: any = setInterval(() => {
        if (this.syncTag < maxTag) {
          return
        }
        this.syncTag = 0
        clearInterval(timer)
        for (let key in this.getItemStatus) {
          this.getItemStatus[key] = false
        }
        let isChecked = false
        if (Object.keys(this.refuseApproval).length) {
          for (let key in this.refuseApproval) {
            params[key] = this.refuseApproval[key]
          }
        }
        let refuseFileItem: any = JSON.stringify(this.refuseFileItem)
        refuseFileItem = JSON.parse(refuseFileItem)
        for (let i = 0; i < refuseFileItem.length; i++) {
          refuseFileItem[i].fileStatus = !refuseFileItem[i].fileStatus
          if (!isChecked && !refuseFileItem[i].fileStatus) {
            isChecked = true
          }
        }
        if (!this.refuseItem.length && !isChecked) {
          this.$message({
            message: '请勾选拒绝项',
            type: 'error'
          })
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        if (!this.remark || !this.remark.trim()) {
          this.$message({
            message: '请填写审批意见',
            type: 'error'
          })
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        params.approvalFiles = refuseFileItem
        if (this.refuseItem.length) {
          params.fieldStatuses = this.refuseItem
        }
        if (this.detailtype == 1) {
          goToApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        } else {
          decisionApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        }
      }, 200)
    } else if (flag == 1 && (this.detailtype == 1 || this.detailtype == 5)) {
      params.fieldStatuses = []
      if (this.detailtype == 1) {
        if (!this.proDetail.last && !this.proDetail.setPayTypePermission) {
          goToApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
          return
        }
        this.getItemStatus.approval = true
        let timer: any = setInterval(() => {
          if (this.syncTag < 1) {
            return
          }
          this.syncTag = 0
          clearInterval(timer)
          this.getItemStatus.approval = false
          if (Object.keys(this.refuseApproval).length) {
            for (let key in this.refuseApproval) {
              params[key] = this.refuseApproval[key]
            }
          }
          goToApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        }, 200)
      } else {
        decisionApproval(params)
          .then((res) => {
            if (res.data.code == 1000) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .catch((res) => {
            if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      }
    } else if (flag == 1 && (this.detailtype == 2 || this.detailtype == 6)) {
      if (this.detailtype == 2) {
        if (!this.proDetail.last) {
          finalApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
          return
        }
        this.getItemStatus.approval = true
        let timer: any = setInterval(() => {
          if (this.syncTag < 1) {
            return
          }
          this.syncTag = 0
          clearInterval(timer)
          this.getItemStatus.approval = false
          params.vote = this.refuseApproval.vote
          finalApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        }, 200)
      } else {
        params.fieldStatuses = []
        decisionApproval(params)
          .then((res) => {
            if (res.data.code == 1000) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .catch((res) => {
            if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      }
    } else if ((flag == 2 || flag == 3) && (this.detailtype == 2 || this.detailtype == 6)) {
      this.refuseFileItem = []
      for (let key in this.getItemStatus) {
        if (key != 'projectDetails' && key != 'productReleative' && key != 'designatedInvestor') {
          this.getItemStatus[key] = true
        }
      }
      let timer: any = setInterval(() => {
        if (this.syncTag < 5 - this.isNullNums) {
          return
        }
        this.syncTag = 0
        clearInterval(timer)
        for (let key in this.getItemStatus) {
          this.getItemStatus[key] = false
        }
        let isChecked = false
        if (Object.keys(this.refuseApproval).length) {
          for (let key in this.refuseApproval) {
            params[key] = this.refuseApproval[key]
          }
        }
        let refuseFileItem: any = JSON.stringify(this.refuseFileItem)
        refuseFileItem = JSON.parse(refuseFileItem)
        for (let i = 0; i < refuseFileItem.length; i++) {
          refuseFileItem[i].fileStatus = !refuseFileItem[i].fileStatus
          if (!isChecked && !refuseFileItem[i].fileStatus) {
            isChecked = true
          }
        }
        if (!isChecked) {
          this.$message({
            message: '请勾选拒绝项',
            type: 'error'
          })
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        if (!this.remark || !this.remark.trim()) {
          this.$message({
            message: '请填写审批意见',
            type: 'error'
          })
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        params.approvalFiles = refuseFileItem
        if (this.detailtype == 2) {
          finalApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        } else {
          params.fieldStatuses = []
          decisionApproval(params)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch((res) => {
              if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close()
              })
            })
        }
      }, 200)
    } else {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
    }
  }
  // 受理
  lawPopEvt() {
    if (this.detailtype == 3) {
      let nowFilesLength: number = 4
      !this.proDetail.administrator && (nowFilesLength -= 1)
      !this.proDetail.provider && (nowFilesLength -= 1)
      if (Object.keys(this.filesUrlArr).length < nowFilesLength) {
        this.$message({
          message: '请确保上传了所有文件,再进行确定',
          type: 'error'
        })
        return
      } else {
        // isNeedFace({})
        //   .then((res) => {
        //     if (res.data.data && res.data.data.result) {
        //       let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt }),
        //         params: any = {
        //           approvalRecordId: this.routeParams.id,
        //           allowInvestment: this.isInvest == '1' && this.allowInvest ? true : false,
        //           files: [
        //             {
        //               fileAddr: this.filesUrlArr.listingServices,
        //               fileType: 'listing-services'
        //             },
        //             {
        //               fileAddr: this.filesUrlArr.acceptanceNotices,
        //               fileType: 'acceptance-notices'
        //             }
        //           ],
        //           callbackId: res.data.data.callbackId
        //         }
        //       this.proDetail.administrator &&
        //         params.files.push({
        //           fileAddr: this.filesUrlArr.trustee,
        //           fileType: 'trustee'
        //         })
        //       this.proDetail.provider &&
        //         params.files.push({
        //           fileAddr: this.filesUrlArr.serviceProvider,
        //           fileType: 'service-provider'
        //         })
        //       acceptanceApproval(params)
        //         .then((res) => {
        //           if (res.data.code == 1000) {
        //             this.$message({
        //               message: '提交成功',
        //               type: 'success'
        //             })
        //             setTimeout(() => {
        //               this.$router.go(-1)
        //             }, 300)
        //           }
        //         })
        //         .catch((res) => {
        //           if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
        //             setTimeout(() => {
        //               this.$router.go(-1)
        //             }, 300)
        //           }
        //         })
        //         .finally(() => {
        //           this.$nextTick(() => {
        //             // 以服务的方式调用的 Loading 需要异步关闭
        //             loadingInstance.close()
        //           })
        //         })
        //     } else {
        //       this.qrDiaShow = true
        //     }
        //   })
        //   .catch(() => {
        this.qrDiaShow = true
        // })
      }
    }
  }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      if (this.detailtype == 3) {
        this.qrDiaShow = false
        let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
        let params: any = {
          approvalRecordId: this.routeParams.id,
          allowInvestment: this.isInvest == '1' && this.allowInvest ? true : false,
          files: [
            {
              fileAddr: this.filesUrlArr.listingServices,
              fileType: 'listing-services'
            },
            {
              fileAddr: this.filesUrlArr.acceptanceNotices,
              fileType: 'acceptance-notices'
            }
          ],
          callbackId
        }
        this.proDetail.administrator &&
          params.files.push({
            fileAddr: this.filesUrlArr.trustee,
            fileType: 'trustee'
          })
        this.proDetail.provider &&
          params.files.push({
            fileAddr: this.filesUrlArr.serviceProvider,
            fileType: 'service-provider'
          })
        acceptanceApproval(params)
          .then((res) => {
            if (res.data.code == 1000) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .catch((res) => {
            if (res.data && (res.data.code == 2009 || res.data.message == '该审批已被处理')) {
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      }
    }
  }
}
