
import { Component, Vue } from 'vue-property-decorator'
import { allApprovalPerson, handleApprovalRule, checkApprovalSetting } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({ components: {} })
export default class SettlementAplmanagementDetails extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private dynamicTags: any = []
  private inputValue: any = []
  private datas: any = []
  private dialogTableVisible: boolean = false
  private search: string = ''
  private gridData: any = []
  private copyData: any = []
  private center: boolean = true
  private isSelectData: any = {}
  private flag: string = ''
  private ruleType: string = ''
  created() {
    this.loading = true
    this.ruleType = this.$route.params.ruleType
    allApprovalPerson().then((res) => {
      this.gridData = res.data.data ? res.data.data : []
      this.copyData = res.data.data ? res.data.data : []
    })
    checkApprovalSetting({ ruleType: this.ruleType })
      .then((res) => {
        if (res.data.data) {
          this.dynamicTags = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 新增方法
  private addApprovalPerson() {
    this.flag = '0'
    this.dialogTableVisible = true
  }
  // 每个tab的点击事件---------
  private handleTab(tag?: any, index?: any) {
    this.isSelectData = { tag, index }
    this.$confirm('', '请选择操作', {
      confirmButtonText: '编辑',
      cancelButtonText: '删除',
      center: true,
      distinguishCancelAndClose: true
    })
      .then(() => {
        this.flag = '1'
        this.dialogTableVisible = true
      })
      .catch((res) => {
        if (res === 'cancel') {
          this.dynamicTags.splice(this.isSelectData.index, 1)
          this.$message({
            type: 'info',
            message: '已删除'
          })
        }
      })
  }
  // 搜索方法--------
  private handleSearch() {
    let gridData = this.copyData
    this.gridData = gridData.filter((data: any) => (this.search ? (data.name ? data.name.includes(this.search) : false) : this.copyData))
  }
  // 弹框选中人员获取人员信息
  private handleIsSelect(val: any) {
    let select: any = val
    this.inputValue = select
  }
  // 确定获取选中人员信息加到现有列详情数据
  private handleConfirm() {
    let isSelectData: any = this.isSelectData
    if (!this.inputValue.personId) {
      this.dialogTableVisible = false
      return
    }
    if (this.flag === '1') {
      this.dynamicTags.splice(isSelectData.index, 1, {
        ...this.inputValue,
        sort: isSelectData.tag.sort
      })
      this.dialogTableVisible = false
    } else {
      this.dynamicTags.push({
        sort: null,
        name: this.inputValue.name,
        personId: this.inputValue.personId
      })
      this.dialogTableVisible = false
    }
  }
  // 提交变更的信息
  private undateApprovalRule() {
    let personIdArr: any = []
    this.dynamicTags.forEach((item: any) => {
      personIdArr.push(item.personId)
    })
    let data: any = {
      ruleType: this.ruleType,
      personIds: personIdArr
    }
    this.loading = true
    handleApprovalRule(data)
      .then((res) => {
        setTimeout(() => {
          this.$router.go(-1)
        }, 300)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
