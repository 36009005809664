var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "banner-text-box", attrs: { prop: "imgUrl" } },
            [
              _c(
                "span",
                {
                  staticClass: "banner-text",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [
                  _vm._v(" banner图片"),
                  _c("span", [_vm._v("(建议尺寸:1920x440)")])
                ]
              ),
              _c("div", { staticClass: "img-box" }, [
                _vm.formInfo.imgUrl
                  ? _c("div", { staticClass: "show-img" }, [
                      _c(
                        "span",
                        {
                          staticClass: "close-btn",
                          on: {
                            click: function($event) {
                              return _vm.deleteImgFn()
                            }
                          }
                        },
                        [_vm._v("x")]
                      ),
                      _c("img", {
                        attrs: { src: _vm.formInfo.imgUrl },
                        on: {
                          click: function($event) {
                            return _vm.enlargeImg()
                          }
                        }
                      })
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "up-files",
                        style: { "background-image": "url(" + _vm.bgImg + ")" }
                      },
                      [
                        _c("upload-base", {
                          attrs: {
                            listShow: false,
                            uploadOption: _vm.uploadOption
                          },
                          on: { fileUrl: _vm.getFileUrl }
                        })
                      ],
                      1
                    )
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属区域", prop: "areaConfigId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isSuperAdmin != "1",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.handleSelectedArea },
                  model: {
                    value: _vm.formInfo.areaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                    },
                    expression: "formInfo.areaConfigId"
                  }
                },
                _vm._l(_vm.areaData, function(item) {
                  return _c("el-option", {
                    key: item.areaConfigId,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId,
                      disabled: item.isDelete || item.isDisable
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布系统", prop: "systemConfigId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formInfo.systemConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "systemConfigId", $$v)
                    },
                    expression: "formInfo.systemConfigId"
                  }
                },
                _vm._l(_vm.filteZct(_vm.systemData), function(item) {
                  return _c("el-option", {
                    key: item.systemConfigId,
                    attrs: {
                      label: item.systemConfigName,
                      value: item.systemConfigId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formInfo.isEnable,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "isEnable", $$v)
                  },
                  expression: "formInfo.isEnable"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否跳转" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formInfo.isJump,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "isJump", $$v)
                  },
                  expression: "formInfo.isJump"
                }
              })
            ],
            1
          ),
          _vm.formInfo.isJump
            ? _c(
                "el-form-item",
                { staticClass: "must", attrs: { label: "链接内容" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.linkNotice }
                    },
                    [_vm._v("选择")]
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.linkContent.title))])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.isUpdate ? "更新" : "提交"))]
          ),
          _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showEnlarge },
          on: {
            "update:visible": function($event) {
              _vm.showEnlarge = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.formInfo.imgUrl }
          })
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.linkShow,
            title: "选择链接的内容",
            width: "1000px",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.linkShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "text-align": "left" },
              attrs: { inline: "", model: _vm.linkInfo, size: "small" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.linkInfo.configTypeKey,
                        callback: function($$v) {
                          _vm.$set(_vm.linkInfo, "configTypeKey", $$v)
                        },
                        expression: "linkInfo.configTypeKey"
                      }
                    },
                    _vm._l(_vm.projectType, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.linkInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.linkInfo, "title", $$v)
                      },
                      expression: "linkInfo.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  index: index => index + 1
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题", "min-width": "250" }
              }),
              _c("el-table-column", {
                attrs: { prop: "configType", label: "类型" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "publicTime",
                  label: "发布时间",
                  "min-width": "190"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleChange(row)
                              }
                            }
                          },
                          [_vm._v("选择")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.paginationParam.total,
              page: _vm.paginationParam.pageIndex,
              limit: _vm.paginationParam.pageSize
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.paginationParam, "pageIndex", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.paginationParam, "pageSize", $event)
              },
              pagination: _vm.handleSearch
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }