var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "data" },
    [
      _vm._m(0),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v(_vm._s(_vm.financialInfo[0].name) + "：")]),
            _c("span", [_vm._v(_vm._s(_vm.financialInfo[0].value))])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        _vm._l(_vm.financialInfo[1], function(item, index) {
          return _c(
            "el-col",
            {
              key: index,
              attrs: { span: index % 3 == 0 ? 8 : index % 3 == 1 ? 7 : 9 }
            },
            [
              _c("span", [_vm._v(_vm._s(item.name) + "：")]),
              _c("span", [_vm._v(_vm._s(item.value))])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("财务数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }