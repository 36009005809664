
import { Component, Vue } from 'vue-property-decorator'

import BaseInfo from './components/base-info.vue'
import ProjectList from './components/project-list.vue'
@Component({
  components: {
    BaseInfo,
    ProjectList
  }
})
export default class EnterpriseManagementDetil extends Vue {
  private activeName: string = '1'
}
