
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getVillageProvinceList, getVillageCityList, getVillageCountyList, getVillageTownshipList, getFarmerInfoList } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class FarmerInfoList extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  // 查询表单
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    householder: ''
  }
  // 分页数据
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 表数据
  private tableData: any = []
  // 表加载中动画开关
  private loading = false
  private requestEnd: boolean = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'FarmerInfoDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.provinceOptions.length) {
        getVillageProvinceList({}).then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'FarmerInfoDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  // 选择省
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCityList({ province: value })
        .then((res) => {
          this.cityOptions = res.data.data ? res.data.data : []
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 选择城市
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillageCountyList({ province: this.formInfo.province, city: value })
        .then((res) => {
          this.countyOptions = res.data.data ? res.data.data : []
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 选择区
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getVillageTownshipList({
        province: this.formInfo.province,
        city: this.formInfo.city,
        county: value
      })
        .then((res) => {
          this.townshipOptions = res.data.data ? res.data.data : []
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 获取表格列表
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getFarmerInfoList(params).then((res) => {
      this.tableData = res.data.data ? res.data.data : []
      this.paginationParam.total = res.data.total ? res.data.total : 0
      this.loading = false
    })
  }
  // 搜索人员列表
  private handleSearch() {
    this.getList()
  }
  // 添加农户信息
  handleAddPersonnal(row: any) {
    this.$router.push({ name: 'FarmerInfoDetail', params: { type: '0', farmersInfoId: 'null' } })
  }
  // 查看农户信息
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'FarmerInfoDetail',
      params: { type: '1', farmersInfoId: datas.farmersInfoId }
    })
  }
}
