
import { Component, Vue } from 'vue-property-decorator'
import { getOfflineProject, showOfflineProject } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
import OfflineBasicInfo from '@/views/countryside-property-transaction/components/offline-basic-info.vue'
import OfflineTransferorInfo from '@/views/countryside-property-transaction/components/offline-transferor-info.vue'
import OfflineDeptInfo from '@/views/countryside-property-transaction/components/offline-dept-info.vue'
import OfflineNeedData from '@/views/countryside-property-transaction/components/offline-need-data.vue'
@Component({
  components: {
    OfflineNeedData,
    OfflineDeptInfo,
    OfflineTransferorInfo,
    OfflineBasicInfo,

  }
})
export default class projectOfflineDetail extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true // 主loading
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {}
  private offlineProjectId: any
  created() {
    if (this.$route.params.offlineProjectId) {
      this.offlineProjectId = this.$route.params.offlineProjectId
      sessionStorage.setItem('ProjectOfflineDetail', this.offlineProjectId)
    } else {
      this.offlineProjectId = sessionStorage.getItem('ProjectOfflineDetail')
    }
    this.getList()
  }
  // 获取详情
  private getList() {
    getOfflineProject({ offlineProjectId: this.offlineProjectId }).then((res) => {
      if (res.data.data) {
        let data = res.data.data
        this.proDetail = data
      }
    }) .finally(() => {
      this.loading = false
    })
  }
  private updateSuccess() {
    this.getList()
  }
  private handleSwitch(val: boolean, offlineProjectId: any) {
    this.loading = true
    showOfflineProject({ offlineProjectId, isShow: val })
      .then((res) => {
        this.$message.success('展示切换成功')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
