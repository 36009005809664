

import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {

  },
  filters: {

  }

})


export default class InfoTemplateConfigDetail extends Vue {

}
