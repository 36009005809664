
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { signContract } from '@/api/api'
import uploadTemp from '@/components/UploadFile/index.vue'
@Component({
  components: {
    uploadTemp
  }
})
export default class proInfoApproval extends Vue {
  @Prop() info: any
  @Prop() detailtype: any
  @Prop({ default: false }) refuse?: boolean
  @Prop() isSecond: any
  // 是否允许投资展示标志
  @Prop({ default: false }) allowInvest?: boolean
  @Prop({ default: false }) noticeShow?: boolean //缴费通知书展示
  // 上传文件类型
  private accept: string = '.pdf,.jpeg,.png,.jpg'
  // 一票否决与否
  private oneFalse: any = '0'
  // 是否允许投资
  private isInvest: any = '1'
  private firstInto: boolean = true
  // info的copy数据
  private infoCopy: any = null
  // 审批意见文本
  public remark: any = ''
  // 上传文件状态
  private upSuccess: any = {
    listingServices: false,
    trustee: false,
    serviceProvider: false,
    acceptanceNotices: false
  }
  // 上传文件地址
  private upSuccessUrl: any = {
    listingServices: '',
    trustee: '',
    serviceProvider: '',
    acceptanceNotices: ''
  }
  // 收费方式
  private payType: object = ['前置收费', '后置收费']
  // 时间线状态logo
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  // 时间线状态
  private iconType: object = ['primary', 'success', 'danger']
  // 文件上传成功方法
  fileUploadSuccess(event: any, title: any) {
    if (event) {
      this.$set(this.upSuccess, title, true)
      this.$set(this.upSuccessUrl, title, event)
    }
    this.$emit('fileUploadSuccess', event, title)
  }
  // 文件上传失败方法
  fileUploadError(event: any) {
    this.$emit('fileUploadError', event)
  }
  // 监听info获取infoCopy和remark
  @Watch('info', { deep: true, immediate: true })
  handler(newV: any) {
    if (this.firstInto && newV.projectDetails) {
      this.firstInto = false
      let newInfo: any = JSON.stringify(newV)
      this.infoCopy = JSON.parse(newInfo)
      this.remark = newV.remark
    }
  }
  @Watch('refuse')
  getRefuseInfo(newValue: any) {
    if (newValue) {
      let obj: any = {}
      if ((this.detailtype == 1 || this.detailtype == 2) && this.info.last) {
        obj.vote = this.oneFalse
      }
      if (this.detailtype == 1 && this.info.setPayTypePermission) {
        obj.payType = this.infoCopy.payType
        // obj.limitDay = this.infoCopy.limitDay
      }
      this.$emit('addRefuseItem', 'approval', obj)
    }
  }
  // 监听isInvest返回投资状态改变
  @Watch('isInvest')
  getIsInvest(newV: any) {
    this.$emit('changeIsInvest', newV)
  }
  // 返回审批意见文本
  @Emit('remark')
  changeremark() {
    return this.remark
  }
}
