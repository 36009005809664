
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '../../type'

// 接口联调
// import { getFileList, deleteById, getBusinessTypeList } from '@/api/config'
import { getFileList, deleteById } from '@/api/config'
import * as dictionaryService from '@/api/dictionary'
import timeFormat from '@/utils/timeFormat'
import { AxiosResponse } from 'axios'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    },
    filterIsEdit(row: any) {
      switch (row) {
        case 1:
          return '是'
          break
        case 0:
          return '否'
          break
      }
    }
  }
})
export default class ProjectNotice extends Vue {
  private loadingTxt: string = loadingText
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // private typeList = [{ name: '全部', value: '' }]
  // private timeObj = ''
  private formInfo: any = {
    bizType: '',
    fileName: ''
  }
  private tableData: any = []
  private loading = false
  private roleList: any = []
  private generatePositionList: any = []

  private listingModeList: any = []

  convertListingModeToName(name: string) {
    for (let roleData in this.listingModeList) {
      const role = this.listingModeList[roleData]

      if (role.key == name) {
        return role.value
      }
    }
    return name
  }
  convertRoleToName(name: string) {
    for (let roleData in this.roleList) {
      const role = this.roleList[roleData]

      if (role.key == name) {
        return role.value
      }
    }
    return name
  }

  convertGeneratePostion(name: string) {
    for (let pos in this.generatePositionList) {
      const position = this.generatePositionList[pos]
      if (position.key === name + '') {
        return position.value
      }
    }
    return name
  }
  // created() {
  // getBusinessTypeList()
  //   .then((res: AxiosResponse<any>) => {
  //     let result = res.data
  //     if (result.code === 1000) {
  //       this.typeList = this.typeList.concat(res.data.data)
  //     }
  //   })
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'configAddFile' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.listingModeList.length) {
        dictionaryService.getDictionaryByName('listing_mode').then((res: AxiosResponse<any>) => {
          if (res.data.data) {
            vim.listingModeList = res.data.data
          }
        })
      }
      if (!vim.generatePositionList.length) {
        dictionaryService.getDictionaryByName('generate_position').then((res: AxiosResponse<any>) => {
          if (res.data.data) {
            vim.generatePositionList = res.data.data
          }
        })
      }
      if (!vim.roleList.length) {
        dictionaryService.getDictionaryByName('role').then((res: AxiosResponse<any>) => {
          let result = res.data
          if (res.data.data) {
            vim.roleList = res.data.data
          }
        })
      }
      vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'configAddFile') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        roleList: this.roleList,
        generatePositionList: this.generatePositionList,
        listingModeList: this.listingModeList
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  toAddPage() {
    this.$router.push({ name: 'configAddFile', params: { disabled: '0' } })
  }
  private query() {
    // if (this.timeObj) {
    //   this.$set(this.formInfo, 'startTime', this.timeObj[0])
    //   this.$set(this.formInfo, 'endTime', this.timeObj[1])
    // } else {
    //   this.$set(this.formInfo, 'startTime', '')
    //   this.$set(this.formInfo, 'endTime', '')
    // }
    this.loading = true
    let params: any = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getFileList(params)
      .then((res) => {
        const data = res.data
        this.tableData = data.data
        this.paginationParam.total = data.total
      })
      .finally(() => {
        this.loading = false
      })
  }

  handleDetails(row: any, isUpdate: string) {
    this.$router.push({ name: 'configAddFile', params: { dataDetail: row, isUpdate: isUpdate } })
  }

  handleDelete(row: any) {
    this.$confirm('确定要删除此文档吗？', '温馨提示').then(() => {
      deleteById(row.id).then((data) => {
        if (data.data.code == 1000) {
          this.query()
        }
      })
    })
  }
}
