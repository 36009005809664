
import { Component, Vue } from 'vue-property-decorator'
import formDetail from '../component/formDetail.vue'
import { unitListDetail } from '@/api/units'
import { localStore } from '@/utils/data-management'
import SuccessIcon from '@/assets/images/success.png'
@Component({
  components: {
    formDetail
  },
  filters: {
    filterAuditStatus(data: any) {
      switch (data) {
        case 0:
          return '未审核'
          break
        case 1:
          return '通过'
          break
        case 2:
          return '拒绝'
          break
        case 3:
          return '审核中'
          break
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
})
export default class UnitListDetail extends Vue {
  private formDatass: any = {}
  private isPass: string = ''
  private isShowBtn: string = ''
  private parentData: any = {}
  private type: any = 0
  private companyDetail: boolean = false
  private approvalDatas: any = []
  private successIcon: string = SuccessIcon
  private rejectContent: string = ''
  // --------------------------------data---END-----------------------
  private created() {
    this.parentData = this.$route.params !== {} ? this.$route.params : {}
    if (this.$route.params.id) {
      localStore.set('uID', this.$route.params)
      this.parentData = this.$route.params
    }
    this.handleUnitsDetail()
  }
  private approvalData: any = {}
  private mounted() {
    this.isPass = this.parentData.isPass || localStore.get('uID').isPass
    this.isShowBtn = this.parentData.flag || localStore.get('uID').flag
  }
  // ---------------周期函数---计算属性--数据监听等----------end--------
  // 是否通过审核
  private handleIsPass() {}
  // 查询单位审核详情
  private handleUnitsDetail() {
    let data: any = {
      id: this.parentData.id || localStore.get('uID').id
    }
    unitListDetail(data).then((res) => {
      let data: any = {
        companyFileRels: res.data.data.companyFileRels,
        company: res.data.data.company
      }
      this.formDatass = data
      this.approvalDatas = res.data.data.companyAudits

      this.companyDetail = true
    })
  }
  private handleReturn() {
    this.$router.go(-1)
  }
}
