
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { delistingPowerApprovalDetails, delistingPowerApproval } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {}
})
export default class DelistingPowerApprovalDetails extends Vue {
  private loadingTxt: string = loadingText
  private nowId: any
  private info: any = {}
  private showEnlarge: boolean = false // 放大图片
  private loading: boolean = false
  private nowSrc: any = '' // 当前凭证图片路径
  // 获取info长度
  get getInfoLength() {
    return Object.keys(this.info).length
  }
  created() {
    if (this.$route.params.id) {
      this.nowId = this.$route.params.id
      sessionStorage.setItem('delistingPowerApprovalDetails', this.nowId)
    } else {
      this.nowId = sessionStorage.getItem('delistingPowerApprovalDetails')
    }
    this.getData()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    sessionStorage.removeItem('delistingPowerApprovalDetails')
    next()
  }
  private getData() {
    this.loading = true
    delistingPowerApprovalDetails({
      approvalId: this.nowId
    })
      .then((res) => {
        if (res.data.data) {
          this.info = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private enlargeImg(src: any) {
    this.nowSrc = src
    this.showEnlarge = true
  }
  private approvalFn(type: number) {
    let textVal: string = type == 1 ? '是否通过该企业的其他摘牌方式申请？' : '是否拒绝该企业的其他摘牌方式申请？'
    this.$confirm(textVal, '授权审批', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        delistingPowerApproval({
          approvalId: this.nowId,
          type: type
        })
          .then((res) => {
            this.$message({
              type: 'success',
              message: '提交成功'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
  private cancelFn() {
    this.$confirm('是否确认取消该企业的通过状态？', '取消通过', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        delistingPowerApproval({
          approvalId: this.nowId,
          type: 3
        })
          .then((res) => {
            this.$message({
              type: 'success',
              message: '取消成功'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
