var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "stakeholder" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("nums-tips", { attrs: { nums: _vm.total } }),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "element-loading-text": _vm.loadingTxt,
                    "header-cell-style": {
                      textAlign: "center",
                      background: "#FAFAFA"
                    },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      index: index => index + 1,
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", prop: "name" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "电话", prop: "phone" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "添加时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.createdTime))])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.paginationParam.pageIndex,
              limit: _vm.paginationParam.pageSize
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.paginationParam, "pageIndex", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.paginationParam, "pageSize", $event)
              },
              pagination: _vm.query
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [_c("span", [_vm._v("干系人")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }