
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { subsidyReadExcel } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    Pagination,
    numsTips
  }
})
export default class SubsidyImportList extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private paginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private all: any = []
  private tableData: any = []

  created() {
    this.getList()
  }

  private getList() {
    let url = this.$route.params.url
    let params: any = {
      fileUrl: url
    }
    this.loading = true
    subsidyReadExcel(params)
      .then((res) => {
        this.all = res.data.data
        this.paginationParam.total = this.all.length
        let index = this.paginationParam.pageIndex * this.paginationParam.pageSize
        this.tableData = this.all.slice(index - this.paginationParam.pageSize, index)
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getListComputed() {
    if (this.all.length > 0) {
      let index = this.paginationParam.pageIndex * this.paginationParam.pageSize
      this.tableData = this.all.slice(index - this.paginationParam.pageSize, index)
    }
  }
}
