var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "企业信息", name: "1" } },
            [
              _c("info-check", {
                attrs: {
                  type: "detail",
                  info: _vm.info.companyInformationResponse
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "财务数据", name: "2" } },
            [
              _c("financial-data", {
                attrs: { info: _vm.info.companyCreditRateDetails }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "评级结果", name: "3" } },
            [_c("rating-result", { attrs: { info: _vm.info } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }