
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { countrysideFinancialList, getAreaPowerList } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class CountrysideFinancialManagement extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private areaOptions: any = []
  private payMethodOptions: any = [
    { label: '自行转账', value: 2 },
    { label: '代收代付', value: 1 },
    //2022-9-8新增
    { label: '自行转给出让方', value: 4 },
  ]
  private typeOptions: any = [
    { label: '保证金', value: 8 },
    { label: '标的全款', value: 1 },
    // { label: '标的预付款', value: 2 },
    // { label: '标的尾款', value: 6 },
    //2022-9-8新增
    { label: '交易服务费', value: 9 },
  ]
  private statusOptions: any = [
    { label: '待确认', value: 2 },
    { label: '未到账', value: 0 },
    { label: '已到账', value: 1 }
  ]
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    name: '',
    status: '',
    areaId: '',
    payParty: '',
    payMethod: '',
    type: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'CountrysideFinancialDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'CountrysideFinancialDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    this.loading = true
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] === '' && delete params[key]
    })
    countrysideFinancialList(params).then((res) => {
      if (res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'CountrysideFinancialDetails',
      params: {
        delistingAmountFlowId: row.delistingAmountFlowId
      }
    })
  }
}
