
import { Component, Vue } from 'vue-property-decorator'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { subsidyTransferList, areaAll } from '@/api/countrysidePropertyTransaction'
import { PaginationParam } from '@/views/type'
import { sessionStore } from '@/utils/data-management'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    },
    status: (val: any) => {
      if (val.transferStatus == 1) {
        return '发放成功'
      }
      if (val.payTaskQueueStatus == 1) {
        return '发放成功'
      } else if (val.payTaskQueueStatus == 2) {
        return '发放失败'
      } else if (val.payTaskQueueStatus == 3) {
        return '转账中'
      } else {
        return '未处理'
      }
    }
  }
})
export default class SubsidyGrantList extends Vue {
  private loadingTxt: string = loadingText
  private statusOptions: any = [
    { label: '全部', value: '' },
    { label: '待申请', value: 1 },
    { label: '待审核', value: 2 },
    { label: '已通过', value: 3 },
    { label: '已发放', value: 4 }
  ]
  private statusArea: any = []
  private formInfo: any = {
    bankAccountNumber: '',
    transferStatus: '',
    areaId: ''
  }
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  created() {
    this.getBank()
    this.getArea()
  }
  private getArea() {
    this.loading = true
    areaAll({})
      .then((res) => {
        this.statusArea = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      if (from.name == 'SubsidyGrantDetail' && pageParams) {
        vim.formInfo = pageParams.formInfo
        vim.time = pageParams.time
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'SubsidyGrantDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        time: this.time
      }
      sessionStore.set('pageParams', pageParams)
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getBank() {}
  private getList() {
    this.loading = true
    if (this.time) {
      let beginTime = new Date(this.time[0]).getTime(),
        endTime = new Date(this.time[1]).getTime()
      this.$set(this.formInfo, 'datetimeStart', beginTime)
      this.$set(this.formInfo, 'datetimeEnd', endTime)
    } else {
      this.$set(this.formInfo, 'datetimeStart', '')
      this.$set(this.formInfo, 'datetimeEnd', '')
    }
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    subsidyTransferList(params).then((res: any) => {
      this.tableData = res.data.data
      this.paginationParam.total = res.data.total
      this.loading = false
    })
  }
  // 搜索发放列表
  private handleSearch() {
    this.getList()
  }
  // 查看详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'SubsidyGrantDetail',
      params: { id: row.id, grantSuccess: row.transferStatus == 1 || row.payTaskQueueStatus == 1 ? '1' : '0' }
    })
  }
}
