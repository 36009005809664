import request from '@/utils/request'
import axios from 'axios'

// Parse the time to string
export const parseTime = (time?: object | string | number, cFormat?: string): string | null => {
  if (time === undefined) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date: Date
  if (typeof time === 'object') {
    date = time as Date
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: { [key: string]: number } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) =>
    filterKeys.map((key: string) => {
      if (key === 'timestamp') {
        return parseTime(data[key])
      } else {
        return data[key]
      }
    })
  )

// Check if an element has a class
export const hasClass = (ele: HTMLElement, className: string) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
}

// Add class to element
export const addClass = (ele: HTMLElement, className: string) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className
}

// Remove class from element
export const removeClass = (ele: HTMLElement, className: string) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// Toggle class for the selected element
export const toggleClass = (ele: HTMLElement, className: string) => {
  if (!ele || !className) {
    return
  }
  let classString = ele.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  ele.className = classString
}

// export const download = (url,fileName) => {
//   console.log(url);
//   const x = new window.XMLHttpRequest();
//   x.open('GET', url, true);
//   x.responseType = 'blob';
//   x.onload = () => {
//       const url = window.URL.createObjectURL(x.response);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = fileName;
//       a.click();
//   };
//   x.send();
// }

export const download = (url, fileName) => {
  console.log(url);
  const link = document.createElement('a')
  axios.get('/group1/M00/01/2F/wKgACmMijvaAS61fAAArTF7semw17.docx', { responseType: 'blob' }).then((res) => {
    const blob = new Blob([res.data], {
      type: 'application/vnd.ms-excel'
    }) // 构造一个blob对象来处理数据，并设置文件类型
    if (window.navigator.msSaveOrOpenBlob) {
      // 兼容IE10
      navigator.msSaveBlob(blob, fileName)
    } else {
      const href = URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href // 指定下载链接
      a.download = fileName // 指定下载文件名
      a.click()
      URL.revokeObjectURL(a.href) // 释放URL对象
    }
  })
}
