
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
// 接口联调
import { searchArea } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
import { getPersonnelList, getAllRole, deletePersonnel } from '@/api/personnel'
import numsTips from '@/components/numsTips/index.vue'

@Component({
  components: {
    numsTips
    // Pagination
  },
  filters: {
    filterStatus: (status: number) => {
      if (status === 0) {
        return '正常'
      } else {
        return '禁用'
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class PersonnelList extends Vue {
  private loadingTxt: string = loadingText
  // private paginationParam: any = {
  //   pageNumber: 1,
  //   pageSize: 10,
  //   phone: '',
  //   name: ''
  // }
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  private isSuperAdmin: any = '0'
  private roleOption: string = ''
  private statusOption: string = ''
  private disabled: boolean = false
  private areaOptions: any = []
  private roleOptions: any = []
  private statusOptions: any = [
    { label: '正常', value: '0' },
    { label: '禁用', value: '-1' }
  ]
  private formInfo: any = {
    pageNumber: 1,
    pageSize: 10,
    name: '',
    phone: '',
    roleId: '',
    status: '',
    areaId: ''
  }
  private pageSizesList: any = [10, 20, 40, 80]
  private tableData: any = []
  private loading = false
  private totalCount: number = 0
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'PersonnelListDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.totalCount = pageParams.totalCount
        if (pageParams.areaOptions.length) {
          vim.areaOptions = pageParams.areaOptions
        }
        if (pageParams.roleOptions.length) {
          vim.roleOptions = pageParams.roleOptions
        }
      }
      vim.systemType != 'zct' && (vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin'))
      if (vim.systemType != 'zct' && vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (vim.systemType != 'zct' && isGetList && !vim.areaOptions.length) {
        searchArea({}).then((res) => {
          if (res.data.data && res.data.data.aeras) {
            vim.areaOptions = vim.filteAreaOptions(res.data.data.aeras)
          }
        })
      }
      if (isGetList && !vim.roleOptions.length) {
        getAllRole().then((res) => {
          if (res.data.data) {
            vim.roleOptions = res.data.data
          }
        })
      }
      isGetList && vim.handlePersonnelList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'PersonnelListDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        areaOptions: this.areaOptions,
        roleOptions: this.roleOptions,
        totalCount: this.totalCount
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'pageNumber' && key != 'pageSize' && (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1'))) {
        this.formInfo[key] = ''
      }
    })
  }

  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  // 分页--改变每页条数触发 -----
  handleSizeChange(pageSize: number) {
    this.formInfo.pageSize = pageSize
    this.handlePersonnelList()
  }
  // 分页--改变页码数触发 -----
  handleCurrentChange(currentPage: number) {
    this.formInfo.pageNumber = currentPage
    this.handlePersonnelList()
  }

  selecttedRole(value: number) {
    this.formInfo.roleId = value
  }

  selecttedStatus(value: string) {
    this.statusOption = value
  }
  // 获取人员列表数据
  handlePersonnelList() {
    this.loading = true
    let checkPersonData: any = this.formInfo
    getPersonnelList(checkPersonData).then((res) => {
      let datas: any = res.data
      this.tableData = res.data.data
      this.totalCount = datas.total
      this.loading = false
    })
  }
  // 搜索人员列表
  private handleSearch() {
    this.handlePersonnelList()
    // get routes() {
    // // 目前仅对路由进行外层过滤, 对于复杂路由逻辑需要递归遍历初始化.
  }

  // 添加人员
  handleAddPersonnal(row: any) {
    this.$router.push({ name: 'PersonnelListDetail', params: { disabled: 'false', flag: '0' } })
  }
  // 查看人员
  private handleDetails(datas: any) {
    this.$router.push({
      name: 'PersonnelListDetail',
      params: { dataDetail: datas, disabled: 'true', flag: '1' }
    })
  }
  // 删除人员
  handleDelete(row: any) {
    let personId: number = row.personId
    this.$confirm('确定删除该人员？', '温馨提示').then(() => {
      this.loading = true
      deletePersonnel(personId)
        .then((res) => {
          this.handlePersonnelList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.loading = false
        })
    })
  }
}
