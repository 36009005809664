
import { Component, Vue } from 'vue-property-decorator'
import { getCollectionFeeConfig, saveCollectionFeeConfig } from '@/api/systemConfig'
import { getAreaPowerList } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class RuralCollectionFeeConfig extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  // private merchantId: any = ''
  private areaOptions: any = []
  private areaVal: any = ''
  private loading: boolean = true
  private isSumbit: boolean = false
  private areaName: string = ''
  private itemList: any = []
  private bankCardOptions: any = []
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
  // private idTips: string = ''
  // private oldMerchantId: any = ''
  // private environment: any = process.env.VUE_APP_TITLE
  // get tipsUrl() {
  //   return ['fusui', 'production'].indexOf(this.environment) > -1 ? 'http://baise-admin-site.baise.vpclub.cn/#/home' : 'http://baise-admin-site.baise.whdev.vpclub.cn/#/home'
  // }
  async created() {
    if (this.systemType == 'zct') {
      this.loading = false
      return
    }
    let isRequest: boolean = false
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.isSuperAdmin == '1') {
      await getAreaPowerList()
        .then((res) => {
          if (res.data.data) {
            this.areaOptions = res.data.data
            if (this.areaOptions[0]) {
              isRequest = true
              this.areaVal = this.areaOptions[0].areaId + ',' + this.areaOptions[0].areaName
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    } else {
      isRequest = true
    }
    isRequest && this.getDetails()
  }
  private getDetails(isLoading: boolean = false) {
    // this.idTips = ''
    isLoading && (this.loading = true)
    let params: any = {}
    if (this.isSuperAdmin == '1') {
      let areaArr: any = this.areaVal.split(',')
      params.areaConfigId = areaArr[0]
      params.areaConfigName = areaArr[1]
    }
    this.itemList = []
    this.bankCardOptions = []
    // this.merchantId = ''
    getCollectionFeeConfig(params)
      .then((res) => {
        let data: any = res.data.data
        if (data) {
          // if (data.merchantId) {
          //   this.merchantId = data.merchantId
          //   this.oldMerchantId = data.merchantId
          // }
          this.isSuperAdmin != '1' && (this.areaName = data.areaName)
          data.feeDetails && (this.itemList = data.feeDetails)
          if (data.bankCards && data.bankCards.length) {
            this.bankCardOptions = data.bankCards
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getBankCardIdTail(stratVal: any, cardId: any) {
    let returnVal: string = ''
    if (stratVal) {
      returnVal = stratVal
      if (cardId) {
        let cardIdArr: any = cardId.split('')
        returnVal += '（' + (cardIdArr.length >= 4 ? cardId.slice(cardIdArr.length - 4) : cardId) + '）'
      } else {
        returnVal += '()'
      }
    }
    return returnVal
  }
  private getFeedItems(bankCardId: any) {
    if (!bankCardId || !this.bankCardOptions.length) {
      return []
    } else {
      for (let i = 0; i < this.bankCardOptions.length; i++) {
        if (this.bankCardOptions[i].bankCardId == bankCardId) {
          return this.bankCardOptions[i].feeItems ? this.bankCardOptions[i].feeItems : []
        }
      }
    }
  }
  private selecttedbankCard(val: any, index: number) {
    this.itemList[index].bankBussinessId = ''
  }
  private verificationFn() {
    let returnFlag: boolean = true
    for (let i = 0; i < this.itemList.length; i++) {
      if (!this.itemList[i].bankCardId || !this.itemList[i].bankBussinessId) {
        returnFlag = false
        break
      }
    }
    // if (returnFlag && !this.merchantId) {
    //   returnFlag = false
    // }
    return returnFlag
  }
  private saveFn() {
    this.$confirm('确认保存？')
      .then(() => {
        this.loading = true
        this.isSumbit = true
        if (!this.verificationFn()) {
          this.$message.error('有选项没有选择或填写！！')
          this.loading = false
          return
        } else {
          let params: any = {
            feeDetails: this.itemList
            // merchantId: this.merchantId
          }
          if (this.isSuperAdmin == '1') {
            if (this.areaVal) {
              let areaValArr: any = this.areaVal.split(',')
              params.areaConfigId = areaValArr[0]
              params.areaConfigName = areaValArr[1]
            } else {
              this.$message.error('请选择区域！！')
              this.loading = false
              return
            }
          }
          saveCollectionFeeConfig(params)
            .then(() => {
              // this.idTips = '有效id'
              // this.oldMerchantId = params.merchantId
              this.$message.success('保存成功！！')
            })
            // .catch((err) => {
            //   if (err.data && err.data.message == '未查询到商户信息') {
            //     this.idTips = '无效id'
            //     this.merchantId = this.oldMerchantId
            //   }
            // })
            .finally(() => {
              this.loading = false
            })
        }
      })
      .catch(() => {})
  }
}
