import request, { request as http } from '@/utils/request'

//贷款产品
export function getLoanProductsList(data: Object) {
  return request({
    url: '/loanProducts/selectPage',
    method: 'POST',
    data: data
  })
}

// 获取产权交易中心数据
export function getPropertyrightTransactionCenterData(data: Object) {
  return request({
    url: '/dictionary/getByCategoryName',
    method: 'POST',
    data: data
  })
}

//银行列表
export function getBankList() {
  return request({
    url: '/company/getPersonCompany',
    method: 'POST',
    data: {}
  })
}

// 添加贷款产品
export function addProduct(data: Object) {
  return request({
    url: '/loanProducts/insertLoanProducts',
    method: 'POST',
    data,
  })
}

// 查询贷款产品详情
export function queryDetail(data: Object) {
  return request({
    url: '/loanProducts/selectById',
    method: 'POST',
    data,
  })
}

// 删除贷款产品
export function deleProduct(data: Object) {
  return request({
    url: '/loanProducts/deleteLoanProducts',
    method: 'POST',
    data,
  })
}

// 编辑贷款产品
export function updateProduct(data: Object) {
  return request({
    url: '/loanProducts/updateLoanProducts',
    method: 'POST',
    data,
  })
}

// 贷款条件项目list
export function getLoanItems(data: Object) {
  return request({
    url: '/message/template/menu/col/getLoanItems',
    method: 'POST',
    data,
  })
}

//贷款审批列表
export function getLoanApprovalList(data: Object) {
  return request({
    url: '/loanApproval/selectPage',
    method: 'POST',
    data: data
  })
}

//贷款审批详情
export function getLoanApprovalDetail(data: Object) {
  return request({
    url: '/loanApproval/selectById',
    method: 'POST',
    data: data
  })
}

//添加农户贷款审批通过记录
export function insertLoanApproved(data: Object) {
  return request({
    url: '/loanApproved/insertLoanApproved',
    method: 'POST',
    data: data
  })
}

//添加农户贷款结清证明记录
export function insertLoanSettleProof(data: Object) {
  return request({
    url: '/loanSettleProof/insertLoanSettleProof',
    method: 'POST',
    data: data
  })
}



// ------抵押贷款审批------------------
// 抵押贷款列表
export function mortgageApprovalList(data: Object) {
  return request({
    url: '/mortgageApproval/selectPage',
    method: 'POST',
    data: data
  })
}

// 抵押贷款详情
export function mortgageApprovalDetail(data: Object) {
  return request({
    url: '/mortgageApproval/selectById',
    method: 'POST',
    data: data
  })
}
// 抵押贷款通过、补充资料、拒绝、提交结清证明
export function mortgageApprovalStatus(data: Object) {
  return request({
    url: '/mortgageApproval/updateMortgageApproval',
    method: 'POST',
    data: data
  })
}
// 抵押贷款 提交结清证明
export function mortgageApprovalHandle(data: Object) {
  return request({
    url: '/loanSettleProof/insertLoanSettleProof',
    method: 'POST',
    data: data
  })
}


// 抵押贷款补充资料
export function mortgageApprovalSupplyData(data: Object) {
  return request({
    url: '/loanAddFile/insertLoanAddFile',
    method: 'POST',
    data: data
  })
}

// 导出抵押贷款详情资料
export function exportByMortgageApproval(data: Object) {
  return request({
    url: '/mortgageApproval/exportById',
    method: 'POST',
    data: data,
    params: {
      date: Date.parse((new Date() as any)) //将时间戳拼接到url后边
    },
    headers: {
      'Content-Type': 'application/json; application/octet-stream',
      'Cache-Control': 'no-cache'
    },
    responseType: 'blob'
  })
}

// 导出贷款产品审批详情资料
export function exportLoanProdPuctApproval(data: Object) {
  return request({
    url: '/loanApproval/exportById',
    method: 'POST',
    data: data,
    params: {
      date: Date.parse((new Date() as any)) //将时间戳拼接到url后边
    },
    headers: {
      'Content-Type': 'application/json; application/octet-stream',
      'Cache-Control': 'no-cache'
    },
    responseType: 'blob'
  })
}
