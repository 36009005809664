var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "organizational-management-list" },
    [
      _c(
        "el-form",
        { attrs: { inline: "", size: "small" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: {
                        disabled:
                          _vm.isSuperAdmin != "1" && !_vm.nowAreaconfigId,
                        size: "small"
                      },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: {
                        disabled:
                          _vm.isSuperAdmin != "1" && !_vm.nowAreaconfigId,
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleDetails()
                        }
                      }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("nums-tips", { attrs: { nums: _vm.paginationParam.total } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "机构名称", prop: "name", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "机构代码", prop: "code", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { label: "联系人", prop: "contacts", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "联系电话",
                  prop: "contactNumber",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "48px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteFn(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.paginationParam.total,
          page: _vm.paginationParam.pageIndex,
          limit: _vm.paginationParam.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.paginationParam, "pageIndex", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.paginationParam, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }