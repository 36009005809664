var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "countryside-financial-details" },
    [
      _c("div", { staticClass: "part" }, [
        _vm._m(0),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("标的名称")]),
            _c(
              "span",
              { staticClass: "a-span", on: { click: _vm.intoDetails } },
              [_vm._v(_vm._s(_vm.info.name))]
            )
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("所属区域")]),
            _c("span", [_vm._v(_vm._s(_vm.info.areaName))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("缴费类型")]),
            _c("span", [_vm._v(_vm._s(_vm.info.typeDesc))])
          ]),
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("缴费方式")]),
                _c("span", [_vm._v(_vm._s(_vm.info.payMethodDesc))])
              ])
            : _vm._e(),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-left" }, [_vm._v("缴费方")]),
            _c("span", [_vm._v(_vm._s(_vm.info.payParty))])
          ]),
          _vm.info.typeDesc != "标的全款" &&
          _vm.info.typeDesc != "保证金" &&
          _vm.info.type != 9 &&
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("金额 (元)")]),
                _c("span", [_vm._v(_vm._s(_vm.info.amount))])
              ])
            : _vm._e(),
          _vm.info.typeDesc == "标的全款" &&
          _vm.info.payMethod != 4 &&
          _vm.info.tranferType == 1
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("竞拍终价")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.info.amount) + "元" + _vm._s(_vm.unitAppend)
                  )
                ])
              ])
            : _vm._e(),
          _vm.info.typeDesc == "标的全款"
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("成交价款 (元)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.transactionPrice))])
              ])
            : _vm._e(),
          _vm.info.transactionAmount &&
          _vm.info.typeDesc != "标的尾款" &&
          (_vm.info.typeDesc != "标的全款" || _vm.info.tranferType != 1) &&
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("交易服务费 (元)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.transactionAmount))])
              ])
            : _vm._e(),
          (_vm.info.typeDesc == "标的全款" &&
            _vm.info.payMethod != 4 &&
            _vm.info.tranferType == 1) ||
          _vm.info.typeDesc == "保证金"
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("保证金 (元)")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.info.tranferEnsureAmount))])
              ])
            : _vm._e(),
          _vm.info.typeDesc == "标的全款" &&
          _vm.info.payMethod != 4 &&
          _vm.info.tranferType == 1
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [
                  _vm._v("是否抵扣保证金")
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.info.usePromiseAmount ? "是" : "否"))
                ])
              ])
            : _vm._e(),
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("缴费时间")]),
                _c("span", [_vm._v(_vm._s(_vm.info.payTime))])
              ])
            : _vm._e(),
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("缴费凭证")]),
                _c(
                  "div",
                  { staticClass: "img-box" },
                  [
                    _vm._l(_vm.info.paymentVouchers, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "show-img" },
                        [
                          _vm.info.status == "2"
                            ? _c(
                                "span",
                                {
                                  staticClass: "close-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteImgFn(index, item)
                                    }
                                  }
                                },
                                [_vm._v("x")]
                              )
                            : _vm._e(),
                          _c("img", {
                            attrs: { src: item.url },
                            on: {
                              click: function($event) {
                                return _vm.enlargeImg(item.url)
                              }
                            }
                          }),
                          _c("br"),
                          _vm._v("缴费凭证 ")
                        ]
                      )
                    }),
                    _vm.info.status == "2"
                      ? _c(
                          "div",
                          {
                            staticClass: "up-files",
                            style: {
                              "background-image": "url(" + _vm.bgImg + ")"
                            }
                          },
                          [
                            _c("UploadBase", {
                              attrs: {
                                listShow: false,
                                showSuccessTip: false,
                                uploadOption: _vm.uploadOption
                              },
                              on: {
                                "update:uploadOption": function($event) {
                                  _vm.uploadOption = $event
                                },
                                "update:upload-option": function($event) {
                                  _vm.uploadOption = $event
                                },
                                fileUrl: _vm.getFileUrl,
                                beforeUpFn: _vm.beforeUpFn,
                                uploadError: _vm.uploadError
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.info.payMethod != 4
            ? _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "item-left" }, [_vm._v("状态")]),
                _c("span", [_vm._v(_vm._s(_vm.info.statusDesc))])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.returnPaper } },
            [_vm._v("返回")]
          ),
          _vm.info.status == "2" && _vm.getInfoLength && _vm.info.payMethod != 4
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.accountFn(1)
                    }
                  }
                },
                [_vm._v("未到账 ")]
              )
            : _vm._e(),
          _vm.info.status == "2" && _vm.getInfoLength && _vm.info.payMethod != 4
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.accountFn(2)
                    }
                  }
                },
                [_vm._v("确认到账 ")]
              )
            : _vm._e(),
          _vm.showEndBtn
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.accountFn(3)
                    }
                  }
                },
                [_vm._v("未到账、继续挂牌")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showEnlarge },
          on: {
            "update:visible": function($event) {
              _vm.showEnlarge = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.nowSrc }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("缴费信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }