
import { Component, Vue } from 'vue-property-decorator'
import { getVillagePermission, TownshipDetail, TownshipSubmit, cancelvillage } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class TownshipCreditDetails extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private rules = {
    province: { required: true, message: '请输入', trigger: 'blur' },
    isJurisdictionNoCreditFarmer: { required: true, message: '请选择', trigger: 'blur' },
    time: { required: true, message: '请输入', trigger: 'blur' },
    opinion: { required: true, message: '请输入', trigger: 'blur' },
    isTownshipDefaulted: { required: true, message: '请选择', trigger: 'blur' },
    isTownshipBadLoan: { required: true, message: '请选择', trigger: 'blur' },
    isHonesty: { required: true, message: '请选择', trigger: 'blur' },
    isCareFinancial: { required: true, message: '请选择', trigger: 'blur' },
    isActivePromote: { required: true, message: '请选择', trigger: 'blur' }
  }
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    contactsName: '',
    contactPhone: '',
    creditAssessGroupOpinionResponse: []
  }
  private loading = false
  private detailShow: boolean = false
  private isEdit: boolean = false
  created() {
    if (this.$route.params.id != '0') {
      this.isEdit = true
      this.formInfo.villageId = this.$route.params.id
      this.getDetailData(this.$route.params.id)
    } else {
      this.isEdit = false
      this.getProvince()
    }
  }
  private submitInfos() {
    this.$confirm('是否确认取消该行政区域的信用称号？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        cancelvillage({ villagePermissionId: this.$route.params.id })
          .then((res: any) => {
            if (res.data.code == 1000) {
              this.$message.success('摘牌申请已提交')
              setTimeout(() => {
                this.$router.push({ name: 'TownshipCreditList' })
              }, 100)
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
  private checkOption(list: Array<any>) {
    return (
      list &&
      list.every((item) => {
        if (!item.opinion || !item.time) {
          this.$message.error(`请将${item.groupName}的相关意见和时间信息完善`)
          return false
        } else {
          return true
        }
      })
    )
  }
  private submitInfo() {
    ;(this.$refs.formInfoRef as any).validate((v: boolean) => {
      if (v) {
        if (this.formInfo.isJurisdictionNoCreditFarmer == 1 && !this.formInfo.jurisdictionNoCreditFarmerRate) return this.$message.error('请输入农户被列入行业管理严重失信名单情况(占比)')
        let optionArr = this.checkOption(this.formInfo.creditAssessGroupOpinionResponse)
        if (optionArr) {
          this.loading = true
          TownshipSubmit(this.formInfo)
            .then((res: any) => {
              if (res.data.code == 1000) {
                this.$message.success('提交成功')
                setTimeout(() => {
                  this.$router.push({ name: 'TownshipCreditList' })
                }, 100)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      } else {
        this.$message.error('请完善输入内容后再提交')
      }
    })
  }
  private getDetailData(id: any) {
    this.loading = true
    TownshipDetail({ villageId: id, functionType: 1, showType: this.$route.params.id ? 0 : 1 })
      .then((res) => {
        if (res.data.code == 1000) {
          this.detailShow = true
          this.formInfo = res.data.data
          this.formInfo.villageId = id
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getProvince() {
    this.loading = true
    getVillagePermission({ type: 2 })
      .then((res) => {
        if (res.data.code == 1000) {
          this.provinceOptions = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private selecttedProvince(value: any) {
    if (value) {
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillagePermission({ type: 2, province: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //城市
  private selecttedCity(value: any) {
    if (value) {
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getVillagePermission({ type: 2, province: this.formInfo.province, city: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //区县
  private selecttedCounty(value: any) {
    if (value) {
      this.townshipOptions = []
      this.formInfo.township = ''
      getVillagePermission({ type: 2, province: this.formInfo.province, city: this.formInfo.city, county: value })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {})
    }
  }
  //乡镇
  private selecttedTownship(value: any) {
    if (value) {
      this.getDetailData(value)
    }
  }
  private changeCode(name: any) {
    this.$nextTick(() => {
      if (this.formInfo[`${name}`] != null) {
        this.formInfo[`${name}`] = this.formInfo[`${name}`].replace(/[^\d.]/g, '')
      }
    })
  }
}
