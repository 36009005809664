
import { Component, Vue } from 'vue-property-decorator'
import { subsidyVoucherDetail } from '@/api/countrysidePropertyTransaction'
import timeFormat from '@/utils/timeFormat'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class tradeReceipt extends Vue {
  private loadingTxt: string = loadingText
  private tableData: any = []
  private interval: number = 0
  private seal = require('@/assets/images/certificate_seal.png')
  private obj: object = {}
  private loading = false
  private userName: String = this.$store.state.userName || sessionStorage.getItem('UsIF')
  private date: String = timeFormat(+new Date())
  created() {
    if (this.userName) {
      this.userName = this.userName.replace(/\"/g, '')
    }
    this.query()
  }
  async query() {
    this.loading = true
    subsidyVoucherDetail({ id: this.$route.params.id })
      .then((res) => {
        if (res.data.data) {
          this.obj = res.data.data
          res.data.data.benePersons && (this.tableData = res.data.data.benePersons)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  clickprinting() {
    ;(this.$refs.btn as any).$el.style.display = 'none'
    let print = this.$refs.print
    //获取目标区域的HTML结构
    document.body.innerHTML = (print as any).innerHTML //替换网页的html内容
    this.interval = window.setInterval(this.printOperation, 500)
  }

  private printOperation() {
    window.print() //打印
    window.location.reload() //刷新页面，因为网页的html被替换后很丑
    window.clearInterval(this.interval)
  }
}
