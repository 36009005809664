
import { Component, Vue, Prop } from 'vue-property-decorator'
import request from '@/utils/request'
import moment from 'moment'
@Component
export default class DetailOfflineRefund extends Vue {
    @Prop({
        default: () => {
            return {}
        }
    })
    rowInfo?: any
    paybackInfo = {} as any // 线下退还信息
    file = {} as any

    timeFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
    created() {
        let data = {
            flowId: this.rowInfo.delistingAmountFlowId
        }
        console.log(this.rowInfo);
        request({
            url: '/tradeMargin/apply/offlineDetail',
            method: 'POST',
            data: data
        }).then(res => {
            console.log(res);
            if (res.data.code == 1000) {
                this.paybackInfo = res.data.data
            }
            this.paybackInfo.userName = this.rowInfo.userName
        })
    }
    //预览
    private showEnlarge: boolean = false // 放大图片
    private nowSrc: any = '' // 当前凭证图片路径
    private enlargeImg(src: any) {
        this.nowSrc = src
        this.showEnlarge = true
    }
}
