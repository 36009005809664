var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("账号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("身份证号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("授权认证：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ])
      ])
    ]),
    _c("div", { staticClass: "part first" }, [
      _vm._m(1),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("户名：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("开户银行：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("银行账号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("银行信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }