
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { addRating, creditRatingDetails, editRating, creditRatingDrop } from '@/api/creditRatingManagement'
@Component({
  components: {}
})
export default class CreditRatingConfigDetail extends Vue {
  private loadingTxt: string = loadingText
  private addDetail: any = {
    creditIndicatorId: '',
    score: '',
    type: 1
  } //详情数据
  private loading = false
  private switchOr: any = false
  private formData: any = {
    name: '',
    score: '', //分值
    checkeds: false,
    isCredit: false, // 是信用级别 1是 0否
    type: 1,
    conditions: []
  }
  private rules: any = {
    name: { required: true, message: '请输入', trigger: 'blur' },
    score: { required: true, message: '请输入', trigger: 'blur' }
    // switchOr: { required: true, message: '请选择', trigger: 'blur' },
    // checkeds: { required: true, message: '请选择', trigger: 'blur' },
    // scoreBasis: { required: true, message: '请输入', trigger: 'blur' }
  }
  private optionList: any = [
    { label: '农户信用总体情况', value: '1' },
    { label: '农户综合素质', value: '2' },
    { label: '信用环境', value: '3' }
  ]
  private scoreOption: any = [
    { label: '>=', value: 1 },
    { label: '<=', value: 2 }
  ]
  created() {
    this.getOption()
    if (this.$route.query.id) {
      this.getDetailData()
    }
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.query.id) {
      to.meta.title = '编辑信用等级'
    } else {
      to.meta.title = '添加信用等级'
    }
    next()
  }
  private getOption() {
    this.loading = true
    creditRatingDrop({})
      .then((res) => {
        if (res.data.code == 1000) {
          this.optionList = res.data.data.other
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  //获取详情
  private getDetailData() {
    this.loading = true
    creditRatingDetails({ id: this.$route.query.id })
      .then((res) => {
        if (res.data.code == 1000) {
          this.formData = res.data.data
          this.switchOr = Boolean(this.formData.isCredit)
          // console.log( this.switchOr)
          this.formData.type = 1
          this.$set(this.formData, 'checkeds', false)
          // if (res.data.data.conditions && res.data.data.conditions.length) {
          //   this.formData.checkeds = false
          //   this.addDetail = res.data.data.conditions[0]
          // } else {
          //   this.formData.checkeds = true
          // }
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private add() {
    ;(this.$refs.ruleForms as any).validate((v: boolean) => {
      if (v) {
        this.formData.conditions = []
        // if (!this.formData.checkeds) {
        //   if (!this.addDetail.creditIndicatorId) return this.$message.error('请选择指标类别')
        //   if (!this.addDetail.score) return this.$message.error('请输入指标分值')
        //   this.formData.conditions.push(this.addDetail)
        // } else {
        //   this.addDetail.creditIndicatorId = ''
        //   this.addDetail.score = ''
        // }
        this.formData.isCredit = this.switchOr ? 1 : 0
        this.loading = true
        if (this.$route.query.id) {
          this.$set(this.formData, 'id', this.$route.query.id)
          editRating(this.formData)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message.success('提交成功')
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          addRating(this.formData)
            .then((res) => {
              if (res.data.code == 1000) {
                this.$message.success('提交成功')
                setTimeout(() => {
                  this.$router.go(-1)
                }, 300)
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    })
  }
  private changeCode(name: any) {
    this.$nextTick(() => {
      if (this.formData[`${name}`] != null) {
        this.formData[`${name}`] = this.formData[`${name}`].replace(/[^\d.]/g, '')
      }
    })
  }
  private changeCodes(name: any) {
    this.$nextTick(() => {
      if (this.addDetail[`${name}`] != null) {
        this.addDetail[`${name}`] = this.addDetail[`${name}`].replace(/[^\d.]/g, '')
      }
    })
  }
}
