var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                !_vm.fileAddr && !_vm.fileTag
                  ? _c("div", [
                      _c("span", [_vm._v("* ")]),
                      _vm._v("法律意见书扫描件上传： ")
                    ])
                  : _vm._e(),
                _vm.fileTag && _vm.info.legalOpinion
                  ? _c(
                      "div",
                      { staticClass: "item-true" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.info.legalOpinion.name))
                        ]),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "50px" } },
                          [_vm._v(_vm._s(_vm.info.legalOpinion.approvalTime))]
                        ),
                        _c("br"),
                        _vm.fileAddr
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  href: _vm.fileAddr,
                                  target: "_blank",
                                  underline: false
                                }
                              },
                              [_vm._v("法律意见书")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.fileTag && _vm.fileAddr
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          href: _vm.fileAddr,
                          target: "_blank",
                          underline: false
                        }
                      },
                      [_vm._v("法律意见书")]
                    )
                  : !_vm.fileAddr && !_vm.fileTag
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "up-files",
                          style: {
                            "background-image": "url(" + _vm.bgImg + ")"
                          }
                        },
                        [
                          _c("UploadBase", {
                            attrs: {
                              listShow: false,
                              uploadOption: _vm.uploadOption
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOption = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOption = $event
                              },
                              fileUrl: _vm.getFileUrl
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("法律意见书")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }