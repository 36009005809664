import request, { request as http } from '@/utils/request'
import { AxiosResponseForZCT, GetUserAccountInfoRes, Res, ZCTAPI } from '@/utils/types'
import { DelistingParams } from '@/views/type'
import { AxiosPromise, AxiosResponse } from 'axios'

export function page(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/areaConfig/page',
        method: 'POST',
        data: data
    });
}

export function insert(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/areaConfig/insert',
        method: 'POST',
        data: data
    });
}

export function update(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/areaConfig/update',
        method: 'POST',
        data: data
    });
}

export function deleteData(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/areaConfig/delete',
        method: 'POST',
        data: data
    });
}

export function doneDisable(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/areaConfig/doneDisable',
        method: 'POST',
        data: data
    });
}

export function getAllSystem() {
    return http<AxiosResponseForZCT>({
        url: "/applicationSystem/getAll",
        method: 'POST',
        data: {}
    });
}

export function selectById(id: string) {
    return http<AxiosResponseForZCT>({
        url: "/areaConfig/selectById",
        method: "POST",
        data: {
            id: id
        }
    })
}