var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "columnBox" },
    [
      _c(
        "div",
        { staticClass: "headers" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.itemData.name))
          ]),
          _c("span", { staticClass: "isEditTip" }, [
            _vm._v(
              "内容" +
                _vm._s(_vm.itemData.contentIsAdd === 1 ? "允许" : "不允许") +
                "新增"
            )
          ]),
          _c(
            "el-button",
            {
              staticClass: "btnDialog",
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleEditColumn }
            },
            [_vm._v("编辑栏目")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btnDialog",
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.handleDeletecolumn }
            },
            [_vm._v("删除栏目")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btnDialog global-dotted-btn",
              attrs: { size: "mini" },
              on: { click: _vm.handleAddField }
            },
            [_vm._v("新增字段")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableBox" },
        [
          _c(
            "el-table",
            {
              staticClass: "tables",
              attrs: {
                data: _vm.itemData.colListResponseList,
                center: "",
                border: "",
                fit: true,
                size: "mini",
                "highlight-current-row": true
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "colName", label: "字段名称", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "colType", label: "字段类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("filterField")(scope.row.colType)))
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isRequired",
                  label: "是否必填",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("filterWhether")(scope.row.isRequired))
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        !(scope.row.colName === "所属村组(合作社/社区)")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEditField(scope.row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        !(scope.row.colName === "所属村组(合作社/社区)")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteField(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("DialogEdit", {
        attrs: { isShow: _vm.isShowColumn, columnOption: _vm.columnOption },
        on: {
          "update:isShow": function($event) {
            _vm.isShowColumn = $event
          },
          "update:is-show": function($event) {
            _vm.isShowColumn = $event
          },
          "update:columnOption": function($event) {
            _vm.columnOption = $event
          },
          "update:column-option": function($event) {
            _vm.columnOption = $event
          },
          CancelDialog: _vm.handleCancelDialog,
          ConfirmDialog: _vm.handleColumnData
        }
      }),
      _c("FieldDialog", {
        attrs: { isShow: _vm.isShowField, fieldOption: _vm.fieldOption },
        on: {
          "update:isShow": function($event) {
            _vm.isShowField = $event
          },
          "update:is-show": function($event) {
            _vm.isShowField = $event
          },
          "update:fieldOption": function($event) {
            _vm.fieldOption = $event
          },
          "update:field-option": function($event) {
            _vm.fieldOption = $event
          },
          CancelDialog: _vm.handleCancelFieldDialog,
          ConfirmDialog: _vm.handleFieldData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }