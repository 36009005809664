
import { Component, Vue } from 'vue-property-decorator'
import { quillEditor } from 'vue-quill-editor'
import { getAreaPowerList } from '@/api/countrysidePropertyTransaction'
import { getAreaTradingRulesConfig, saveTradingRulesConfig } from '@/api/systemConfig'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    quillEditor
  }
})
export default class TradingRulesConfig extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private loading: boolean = false
  private formInfo: any = {
    areaConfigId: '',
    ruleType: 2,
    transactionViolate: '',
    transactionName: '',
    transactionContent: '',
    id: ''
  }
  private areaOptions: any = []
  private ruleTypeOptions: any = [
    // {
    //   name: '交易规则',
    //   value: 1
    // },
    {
      name: '摘牌协议',
      value: 2
    }
  ]
  private editorOption = {
    placeholder: '编辑内容',
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          // [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ align: [] }]
        ]
      }
    }
  }
  created() {
    let isGetList: boolean = true
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.isSuperAdmin != '1') {
      !this.formInfo.areaConfigId && (this.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
      !this.formInfo.areaConfigId && (isGetList = false)
      isGetList && this.getDetails()
    }
    if (isGetList) {
      this.isSuperAdmin == '1' && (this.loading = true)
      getAreaPowerList()
        .then((res) => {
          if (res.data.data) {
            this.areaOptions = res.data.data
            if (this.isSuperAdmin == '1') {
              this.formInfo.areaConfigId = this.areaOptions[0].areaId
              this.getDetails()
            }
          } else {
            this.isSuperAdmin == '1' && (this.loading = false)
          }
        })
        .catch(() => {
          this.isSuperAdmin == '1' && (this.loading = false)
        })
    }
  }
  private getDetails() {
    this.loading = true
    getAreaTradingRulesConfig({ areaConfigId: this.formInfo.areaConfigId, ruleType: this.formInfo.ruleType })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          Object.keys(this.formInfo).forEach((key) => {
            if (key == 'areaConfigId' || key == 'ruleType') {
              return
            }
            if (key != 'transactionViolate') {
              this.formInfo[key] = datas[key] ? datas[key] : ''
            } else {
              this.formInfo[key] = !datas[key] && datas[key] !== 0 ? '' : datas[key]
            }
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private selecttedArea(value: any) {
    if (value) {
      Object.keys(this.formInfo).forEach((key) => {
        if (key == 'areaConfigId' || key == 'ruleType') {
          return
        }
        this.formInfo[key] = ''
      })
      this.getDetails()
    }
  }

  private selecttedRuleType(value: any) {
    Object.keys(this.formInfo).forEach((key) => {
      if (key == 'areaConfigId' || key == 'ruleType') {
        return
      }
      this.formInfo[key] = ''
    })
    this.getDetails()
  }

  private changeInput(value: any) {
    this.formInfo.transactionViolate = value.replace(/[^\d\.]/g, '')
  }
  private saveFn() {
    if (!this.formInfo.areaConfigId) {
      this.$message.error('选择区域后再提交！！')
      return
    }
    if ((this.formInfo.ruleType == 1 && !this.formInfo.transactionViolate && this.formInfo.transactionViolate !== 0) || !this.formInfo.transactionName || !this.formInfo.transactionContent) {
      this.$message.error('所有必填项,不能为空！！')
      return
    }
    this.$confirm('确定信息无误，并保存？', '温馨提示')
      .then(() => {
        this.loading = true
        let params: any = {
          ...this.formInfo
        }
        this.formInfo.ruleType == 2 && delete params.transactionViolate
        saveTradingRulesConfig(params)
          .then(() => {
            this.$message.success('保存成功！！')
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
