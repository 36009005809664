
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { PaginationParam } from '@/views/type'
import { ocrStatusOptions, roleOptions } from '@/views/project-management/options'
import numsTips from '@/components/numsTips/index.vue'
import { downloadTemplate, selectPageOCR } from '@/api/filing'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class Ocr extends Vue {
  private loadingTxt: string = loadingText
  private tableData: any[] = []
  private loading = false
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private ocrStatusOptions = ocrStatusOptions
  private roleOptions = roleOptions
  private formInfo: any = {
    names: '',
    demandName: '',
    role: '',
    ocrStatus: ''
  }
  private downloadUrlArr: any = []

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'OcrDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      vim.query()
      vim.getDownloadData()
    })
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'OcrDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }

  private getTimestamp(data: any): any {
    let newdate = data.substring(0, 19)
    newdate = newdate.replace(/-/g, '/') //必须把日期'-'转为'/'
    return new Date(newdate).getTime()
  }

  private async query() {
    if (this.time) {
      this.$set(this.formInfo, 'beginTime', this.getTimestamp(this.time[0]))
      this.$set(this.formInfo, 'endTime', this.getTimestamp(this.time[1]))
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    selectPageOCR(params as any)
      .then((res) => {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleDetails(row: any) {
    this.$router.push({
      name: 'OcrDetail',
      params: { id: row.id, downloadUrlArr: this.downloadUrlArr }
    })
  }

  private getDownloadData() {
    this.loading = true
    const params = {
      role: 'admin',
      listingMode: 21,
      generatePosition: 12
    }
    downloadTemplate(params as any)
      .then((res) => {
        this.downloadUrlArr = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
