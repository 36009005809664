
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { Component, Vue } from 'vue-property-decorator'
import { mortgageApprovalDetail, mortgageApprovalSupplyData, mortgageApprovalStatus, mortgageApprovalHandle, exportByMortgageApproval } from '@/api/loans'
// import uploadFileBtn from '@/components/upLoadFileBtn/index.vue'
import upLoadFileImg from '@/components/upLoadFileImg/index.vue'
import dialogMortgage from './components/dialogmortgage.vue'
import { sessionStore } from '@/utils/data-management'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    // uploadFileBtn,
    dialogMortgage,
    upLoadFileImg
  },
  filters: {
    filterDelisting(data: number) {
      switch (data) {
        case 1:
          return '全额付款'
          break
        case 2:
          return '预付款'
          break
        case 3:
          return '交付后付款'
          break
        case 4:
          return '其他'
          break
        case 0:
          return '自行转账'
          break
      }
    },
    filterTranferType(data: number) {
      switch (data) {
        case 1:
          return '竞拍'
          break
        case 2:
          return '非竞拍'
          break
        case 3:
          return '预售'
          break
      }
    },
    filterRepayment(data: any) {
      switch (data) {
        case '1':
          return '等额本金'
          break
        case '2':
          return '等额本息'
          break
        case '3':
          return '按月还息到期还本'
          break
        case '4':
          return '到期一次还本付息'
          break
        default:
          return ''
      }
    },
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class MortgageDetail extends Vue {
  private loadingTxt: string = loadingText
  private showImg: boolean = false
  private nowSrc: string = ''
  private loadings: boolean = false
  private activeName = '1'
  private isShow = false
  private isOrModalClose: boolean = false
  private paneDatas: any = {}
  private optionData: any = {
    loanId: '',
    id: '',
    demandId: '',
    statusNb: '',
    textArea: '',
    dialogTableVisible: false
  }
  private proveUrl: string = ''
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    listType: 'picture-card'
    // tip: '请上传pdf格式文件',
  }
  private settlementData: any = {}
  private uploadOptionBtn: any = {
    limit: 1,
    accept: '.pdf',
    // listType: 'picture-card',
    tip: '请上传pdf格式文件'
  }
  private listShow: boolean = true
  // 审批
  private title: any = ''
  // ------------------------------------------------------data---end---------------------------------------------------
  private created() {
    if (this.$route.params.id || sessionStore.get('deda')) {
      this.getMortgageDetail()
      if (this.$route.params.id || this.$route.params.status) {
        sessionStore.set('deda', this.$route.params)
      }
    }
    this.optionData.loanId = this.$route.params.id || sessionStore.get('deda').id
    this.optionData.statusNb = this.$route.params.status || sessionStore.get('deda').status
  }
  // -----------生命周期--------------监听等--------------------
  private handleDialogClose(): void {
    this.optionData.dialogTableVisible = false
  }
  private getFileUrl(datas: any): void {
    // let settlementData: object = {
    //   proofUrl: datas.url,
    //   proofName: datas.name
    // }
    // this.settlementData = settlementData
    this.proveUrl = datas.url
  }
  private showBig() {
    if (this.paneDatas.mortgageBasicResponse.companyInfo.officeImageUrl) {
      this.showImg = true
      !this.nowSrc && (this.nowSrc = this.paneDatas.mortgageBasicResponse.companyInfo.officeImageUrl)
    }
  }
  get btnShow() {
    let returnFlag: boolean = false,
      statusNb: any = ['4', '3', '2', '5']
    if (this.activeName == '5' && statusNb.indexOf(this.optionData.statusNb) == -1) {
      returnFlag = true
    } else if (this.activeName == '6' && statusNb.indexOf(this.optionData.statusNb) != -1) {
      returnFlag = true
    }
    return returnFlag
  }
  // ------查看详情--------------
  private getMortgageDetail() {
    this.loadings = true
    let datas: any = {
      id: this.$route.params.id || sessionStore.get('deda').id
    }
    mortgageApprovalDetail(datas)
      .then((res) => {
        if (res.data.data) {
          this.paneDatas = res.data.data
          if (res.data.data.mortgageBasicResponse && res.data.data.mortgageBasicResponse.mortgageApprovalEntity) {
            this.optionData.id = res.data.data.mortgageBasicResponse.mortgageApprovalEntity.id
            this.optionData.demandId = res.data.data.mortgageBasicResponse.mortgageApprovalEntity.demandId
          }
        }
      })
      .finally(() => {
        this.loadings = false
      })
  }
  private handleExportData() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let datas: any = {
      id: this.$route.params.id || this.optionData.id || sessionStore.get('deda').id
    }
    exportByMortgageApproval(datas)
      .then((res) => {
        if (res.data && (res.data.type == 'application/octet-stream' || res.data.type == 'application/octet-stream;charset=utf-8')) {
          const content = res.data
          const blob = new Blob([content], { type: 'application/zip' })
          let fileStr: any = res.headers['content-disposition'].split('filename=')[1]
          const fileName = decodeURI(fileStr)
          // if ('download' in document.createElement('a')) {
          //   // 非IE下载
          //   const elink = document.createElement('a')
          //   elink.download = fileName
          //   elink.style.display = 'none'
          //   elink.href = window.URL.createObjectURL(blob)
          //   document.body.appendChild(elink)
          //   elink.click()
          //   window.URL.revokeObjectURL(elink.href) // 释放URL 对象
          //   document.body.removeChild(elink)
          // } else {
          //   // IE10+下载
          //   navigator.msSaveBlob(blob, fileName)
          // }
          if (navigator && typeof navigator.msSaveBlob === 'function') {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          } else {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = window.URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            window.URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }
        } else {
          this.$message.error('下载失败')
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 补充资料
  private handleSupplyData() {
    if (this.optionData.textArea === '' || this.optionData.textArea == null) {
      this.$message.warning('补充资料需填写审批内容')
      return
    }
    let datas: any = {
        id: this.optionData.id,
        status: 3,
        approvalComments: this.optionData.textArea
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    mortgageApprovalStatus(datas)
      .then((res) => {
        this.$router.go(-1)
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 拒绝
  private handleRefuse() {
    if (!this.optionData.textArea || (this.optionData.textArea && !this.optionData.textArea.trim())) {
      this.$message.error('请填写审批意见！！')
      return
    }
    let datas: any = {
        id: this.optionData.id,
        status: 2,
        approvalComments: this.optionData.textArea
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    mortgageApprovalStatus(datas)
      .then((res) => {
        this.$router.push({ name: 'MortgageApproval' })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 通过
  private handlePass() {
    this.optionData.dialogTableVisible = true
  }
  // 提交结清证明
  private handleSettlemented() {
    if (!this.proveUrl) {
      this.$message.warning('请上传还清证明')
    }
    let datas: any = {
        // loanId: this.optionData.loanId,
        // settlementData: [{ ...this.settlementData }]
        id: this.optionData.id,
        status: 5,
        proveUrl: this.proveUrl
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    mortgageApprovalStatus(datas)
      .then((res) => {
        this.$router.push({ name: 'MortgageApproval' })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleAddcolumn() {
    this.isShow = true
  }
  private handleCancelDialog() {
    this.isShow = false
  }
  // private getFileUrlUrl(data: any) {
  //   console.log(data)
  // }
}
