
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { getFeedbackList, getSourceSystem } from '@/api/noticeManagement'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class FeedbackList extends Vue {
  private loadingTxt: string = loadingText
  private systemOptions: any = []
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    opinion: '',
    name: '',
    phone: '',
    type: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'FeedbackManagementDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.systemOptions.length) {
        getSourceSystem().then((res) => {
          if (res.data.data) {
            vim.systemOptions = res.data.data
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'FeedbackManagementDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time,
        systemOptions: this.systemOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private filteZct(data: any) {
    let returnData: any = data
    if (data && data.length) {
      for (let i = 0; i < returnData.length; i++) {
        if (returnData[i].code == 'zct') {
          returnData.splice(i, 1)
          break
        }
      }
    }
    return returnData
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      let beginTime = new Date(this.time[0]).getTime(),
        endTime = new Date(this.time[1]).getTime()
      this.$set(this.formInfo, 'beginTime', beginTime)
      this.$set(this.formInfo, 'endTime', endTime)
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getFeedbackList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'FeedbackManagementDetail',
      params: {
        id: row.id
      }
    })
  }
}
