
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { uploadFileUrl } from '@/api/common.api'
import jsonbigStringParse from '@/utils/jsonbigStringParse'
import * as secret from '@/utils/secret'
interface FileInfo {
  [key: string]: string | object | number | bigint
}
@Component({
  components: {}
})
export default class UploadBase extends Vue {
  @Prop() private uploadOption!: any // 接收父组件传过来的数据
  @Prop({ default: true }) listShow?: boolean
  @Prop({ default: 0, type: Number }) private flag!: number
  @Prop({ default: false, type: Boolean }) private disabled!: boolean
  private uploadFileUrl = uploadFileUrl // 图片上传地址
  private accept: any = '' // 上传文件类型
  private limit: any = 1 // 文件个数限制
  // private tip: any = '' // 提示语
  private listType: any = '' // 上传成功之后的显示模式
  // private flags: any = this.flag // 标识
  private maxSize: any = 5 // 文件大小
  private uploadUrl: any = '' //上传成功后返回的图片url
  private hideUpload: boolean = false
  private fileList: any = [] //回显图片
  private dialogVisible: boolean = false
  @Watch('uploadOption', { deep: true, immediate: true })
  private watchUploadOption(newValue: any) {
    if (newValue.limit) this.limit = newValue.limit
    if (newValue.accept) this.accept = newValue.accept
    // if (newValue.tip) this.tip = newValue.tip
    if (newValue.listType) this.listType = newValue.listType
    if (newValue.flag) this.flag = newValue.flag
    if (newValue.maxSize) this.maxSize = newValue.maxSize
    if (newValue.uploadUrl) this.uploadUrl = newValue.uploadUrl
  }
  // 上传图片成功
  private handleUploadSuccess(res: any, file: FileInfo, fileList: []) {
    let fileInfo: any = file.raw
    if (res && Object.prototype.toString.call(res) === '[object String]') {
      res = jsonbigStringParse(secret.default.decrypt(res))
    }
    if (res.code == 1000) {
      this.uploadUrl = res.data
      this.$emit('fileUrl', {
        url: this.uploadUrl,
        flag: this.flag,
        name: fileInfo.name,
        type: fileInfo.type
      })
      this.$message.success('上传成功！')
    } else {
      this.$message.error('上传失败')
    }
  }
  private handleUploadError(err: Error, file: FileInfo, fileList: FileInfo[]) {
    this.$message.error('上传失败')
  }
  // 上传图片之前的校验
  private beforeUpload(file: FileInfo) {
    if (this.accept) {
      let accept = JSON.parse(JSON.stringify(this.accept))
      accept = [...accept.split(',')]
      let str: any = file.name
      let fileNameAll = str.split('.')
      fileNameAll = '.' + fileNameAll[fileNameAll.length - 1]
      if (accept.indexOf(fileNameAll) == -1) {
        this.$message.error('上传文件格式有误!')
        return false
      }
    }
    const isLt5M = (file.size as number) / 1024 / 1024 < Number(this.maxSize)
    if (!isLt5M) {
      this.$message.error(`上传文件大小不能超过 ${Number(this.maxSize)}MB!`)
      return false
    }
  }
  private handleChange(files: any, fileList: []) {
    this.hideUpload = fileList.length >= this.limit
  }
  // 文件超过限制
  private handleExceed(files: any, fileList: []) {
    this.$message.warning(`最多只能上传${fileList.length}个文件！`)
  }
  // 预览图片
  private handlePreview(file: FileInfo) {
    this.dialogVisible = true
  }
  // 删除图片
  private handleRemove(file: FileInfo, fileList: FileInfo[]) {
    this.hideUpload = fileList.length >= this.limit
    this.$emit('fileUrl', {
      url: '',
      flag: this.flag,
      name: '',
      type: ''
    })
  }
}
