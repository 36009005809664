
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class MarkInfo extends Vue {
  @Prop() info: any
  @Prop() type: any
  @Prop({ default: false }) checkboxShow?: boolean
  @Prop({ default: false }) isRefuse?: boolean
  // 所属区域展示标志
  @Prop({ default: false }) areaShow?: boolean
  @Prop({ default: '' }) areaName?: any
  @Prop() imgList: any
  @Prop({
    default: () => {
      return []
    }
  })
  fileStatus?: any
  @Prop({default:()=>[]}) customFields: any
  @Prop({
    default: () => {
      return []
    }
  })
 customStatus?: any
  @Prop({}) processStatus:number
  @Prop({
    default: ''
  })
  projectNum?: any
  @Prop({
    default: ''
  })
  tranferEnsureAmount?: any
  @Prop({
    default: ''
  })
  auctionInterval?: any
  private isFirst: boolean = true
  private tradeType: any = {
    '1': '竞拍',
    '2': '非竞拍 ',
    '3': '预售'
  }
  private bidType: any = {
    '0': '一口价',
    '1': '单价 ',
  }
  private unitTimeOption: any = {
    '0': '无',
    '1': '年 ',
    '2': '季度 ',
    '3': '月 ',
  }
  created(){
    console.log(this.customStatus);
    console.log(this.customFields);
  }
  private itemStatus: any = {
    '1': {
      name: false,
      landType: false,
      usePurpose: false,
      ownershipType: false,
      warrantName: false,
      warrantTerm: false,
      transferMethod: false,
      isReTransfer: false,
      isPriorTransfer: false,
      owner: false,
      isOtherRightsHolder: false,
      otherRightsHolder: false,
      otherRightsContent: false,
      isGiveUpAdvanceRight: false,
      location: false,
      contractingMode: false,
      otherContractingMode: false,
      fourSide: false,
      holdArea: false,
      transferPeriod: false,
      tradeType: false,
      amount: false,
      // sellWay: false,
      // sharesNums: false,
      advancePaymentAmount: false,
      bidType: false,
      paymentMethod: false,
      listingPrice: false
    },
    '2': {
      isOtherRightsHolder: false,
      otherRightsHolder: false,
      otherRightsContent: false,
      name: false,
      location: false,
      forestAttr: false,
      holdArea: false,
      forestStock: false,
      treeType: false,
      transferPeriod: false,
      tradeType: false,
      amount: false,
      // sellWay: false,
      // sharesNums: false,
      advancePaymentAmount: false,
      bidType: false,
      paymentMethod: false,
      transferMethod: false,
      listingPrice: false
    },
    '3': {
      name: false,
      location: false,
      unit: false,
      number: false,
      isOtherRightsHolder: false,
      otherRightsHolder: false,
      otherRightsContent: false,
      transferPeriod: false,
      tradeType: false,
      amount: false,
      advancePaymentAmount: false,
      bidType: false,
      paymentMethod: false,
      transferMethod: false,
      listingPrice: false
    },
    '7': {
      name: false,
      location: false,
      unit: false,
      number: false,
      isOtherRightsHolder: false,
      otherRightsHolder: false,
      otherRightsContent: false,
      transferPeriod: false,
      tradeType: false,
      amount: false,
      remark: false,
      bidType: false,
      paymentMethod: false,
      transferMethod: false,
      listingPrice: false
    }
  }
  get areaUnit() {
    return this.info && this.info.holdAreaUnit && this.info.holdAreaUnit.value ? this.info.holdAreaUnit.value : ''
  }
  get listingPriceShow() {
    let returnVal: string = '',
      bidType: number = this.info.bidType && this.info.bidType.value ? this.info.bidType.value : 0
    if (this.info.listingPrice && this.info.listingPrice.value) {
      var unitAppend = bidType == 0 ?'':' 元'+((this.info.unitNum.value=='无'||!this.info.unitNum.value)?'':'/'+this.info.unitNum.value)+
        ((this.info.unitTime.value=='0'||!this.info.unitTime.value)?'':'/'+this.unitTimeOption[this.info.unitTime.value])
      if (this.type != '1' && this.type != '2') {
        let unit: string = this.info.unit && this.info.unit.value ? this.info.unit.value : '',
          numberVal: any = this.info.number && this.info.number.value ? this.info.number.value : ''
        returnVal = bidType == 0 ? this.info.listingPrice.value + ' 元/' + numberVal + unit : this.info.listingPrice.value  + unitAppend
      } else {
        let holdArea: string = this.info.holdArea && this.info.holdArea.value ? this.info.holdArea.value : ''
        returnVal = bidType == 0 ? this.info.listingPrice.value + ' 元/' + holdArea + this.areaUnit : this.info.listingPrice.value  + unitAppend
      }
    }
    return returnVal
  }
  private contractingModeFn(val: any) {
    this.itemStatus['1'].otherContractingMode = val
  }
  @Watch('isRefuse')
  isRefuseWatch(newV: boolean) {
    if (newV) {
      this.$emit('markStatus', this.itemStatus[this.type == '1' || this.type == '2' || this.type == '7' ? this.type : '3'])
    }
  }
}
