<template>
  <el-upload class="uploader" :action="FileUploadURL" :show-file-list="resultShow" :accept="accept" :on-success="uploadSuccess" :on-error="uploadError" :before-upload="beforeUpload" :limit="limt" :file-list="fileslist" :on-remove="removeTrigger" :on-exceed="overLimit">
    <el-button class="global-dotted-btn" size="small">{{ text }}</el-button>
  </el-upload>
</template>
<script>
import axios from 'axios'
import { sessionStore } from '@/utils/data-management'
import { uploadFileUrl } from '@/api/common.api'
import jsonbigStringParse from '@/utils/jsonbigStringParse'
import * as secret from '@/utils/secret'
export default {
  props: {
    limt: Number,
    text: {
      default: '点击上传',
      type: String
    },
    resultShow: {
      type: Boolean,
      default: true
    },
    fileslist: {
      type: Array
    },
    accept: {
      type: String,
      default: 'image/*, .doc, .docx, .zip, .rar'
    },
    fileSizeFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      FileUploadURL: uploadFileUrl,
      loading: false,
      fileReader: ''
    }
  },
  methods: {
    overLimit() {
      this.$message('最多只能上传' + this.limt + '份文件')
    },
    removeTrigger(file, filleList) {
      this.$emit('remove', file)
    },
    uploadSuccess(res, file, fileList) {
      if (res && Object.prototype.toString.call(res) === '[object String]') {
        res = jsonbigStringParse(secret.default.decrypt(res))
      }
      this.loading = false
      this.$emit('result', res.data)
      this.$emit('resultMore', file)
    },
    uploadError(err) {
      if (err.message && Object.prototype.toString.call(err.message) === '[object String]') {
        err.message = jsonbigStringParse(secret.default.decrypt(err.message))
      }
      let returnErrAny = err.message.body
      this.$emit('error', returnErrAny ? (returnErrAny.message ? returnErrAny.message : '上传失败') : '上传失败')
    },
    beforeUpload(file) {
      //各种限制
      if (this.fileSizeFilter) {
        const isLt10M = file.size / 1024 / 1024 < 10
        if (!isLt10M) {
          this.$message.error('上传文件大小不能超过 10MB!')
          return
        }
      }
      this.loading = true
    }
  },
  mounted() {
    this.fileReader = new FileReader()
  }
}
</script>

<style scoped lang="scss">
.uploader {
  width: 150px;
  height: auto;
  /deep/ .el-upload {
    .info-container,
    img {
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 150px;
      height: 150px;
      background: rgba(255, 255, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(235, 236, 239, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: #f65248;
      }
    }
  }
  h4 {
    margin: 0;
    text-align: center;
  }
}
</style>
