
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { PaginationParam } from '@/views/type'
// 接口联调
import {
  // getProjectList,
  getProjectList2,
  deleteProject,
  setTopProject,
  setShowState,
  searchArea,
  searchAreaStystem
} from '@/api/noticeManagement'
// import { getPropertyrightTransactionCenterData } from '@/api/login'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class ProjectNotice extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private topStatus = [
    {
      value: '',
      label: '全部'
    },
    {
      value: '1',
      label: '是'
    },
    {
      value: '0',
      label: '否'
    }
  ]
  private projectType: any = [
    {
      key: '',
      value: '全部'
    }
  ]
  private areaData: any = [
    {
      areaConfigId: '',
      areaConfigName: '全部'
    }
  ]
  private systemData: any = []
  // private list: any = []
  private time: any = ''
  private formInfo: any = {
    title: '',
    topState: '',
    areaConfigId: '',
    configTypeKey: '',
    systemConfigId: ''
    // area: '',
    // time: ''
  }

  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'DetailsProjectNotice' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaConfigId && (vim.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaConfigId && (isGetList = false)
      }
      if (isGetList && (vim.projectType.length == 1 || vim.areaData.length == 1)) {
        searchArea({}).then((res) => {
          if (res.data.data) {
            if (vim.projectType.length == 1 && res.data.data.project && res.data.data.project.length) {
              vim.projectType = vim.projectType.concat(res.data.data.project)
            }
            if (vim.areaData.length == 1 && res.data.data.aeras && res.data.data.aeras.length) {
              vim.areaData = vim.areaData.concat(vim.filteAreaOptions(res.data.data.aeras))
            }
          }
        })
      }
      if (isGetList && vim.isSuperAdmin != '1' && !vim.systemData.length) {
        searchAreaStystem({ areaConfigId: vim.formInfo.areaConfigId }).then((res) => {
          if (res.data.data) {
            vim.systemData = res.data.data
          }
        })
      }
      isGetList && vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'DetailsProjectNotice') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        projectType: this.projectType,
        areaData: this.areaData,
        systemData: this.systemData,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaConfigId' || (key == 'areaConfigId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
    this.isSuperAdmin == '1' && (this.systemData = [])
  }
  private filteZct(data: any) {
    let returnData: any = []
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (['资产通管理平台', '资产通'].indexOf(data[i].systemConfigName) == -1) {
          returnData.push(data[i])
        }
      }
    }
    return returnData
  }
  private handleSelectedArea(id: any) {
    if (!this.formInfo.areaConfigId) {
      return
    }
    this.formInfo.systemConfigId = ''
    let data: any = {
      areaConfigId: id
    }
    searchAreaStystem(data).then((res) => {
      if (res.data && res.data.code == 1000 && res.data.data) {
        this.systemData = res.data.data
      }
    })
  }
  private toAddPage(): void {
    // this.$router.push({ name: 'AddProjectNotice', params: { id: 'null' } })
    this.$router.push({ name: 'DetailsProjectNotice', params: { id: 'null' } })
  }
  private query() {
    if (this.time) {
      this.$set(this.formInfo, 'beginTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'beginTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params: any = {
      ...this.formInfo,
      pageNum: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    getProjectList2(params).then((res) => {
      let datas: any = res.data.data
      if (datas) {
        //处理置顶状态 字段 String => Boolean
        datas.forEach((item: any) => {
          if (!item.topState || item.topState == '0') {
            item.booleanTopState = false
          } else {
            item.booleanTopState = true
          }
          if (!item.isShow || item.isShow == '0') {
            item.isShowBoolean = false
          } else {
            item.isShowBoolean = true
          }
        })
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // private select(): void {
  //   let data: any = {
  //     categoryName: 'propertyright_transaction_center'
  //   }
  // getPropertyrightTransactionCenterData(data).then((res) => {
  //   this.list = res.data.data
  // })
  // }
  private handleSwitch(data: any, row: any) {
    let datas: any = {
      topState: data ? '1' : '0',
      id: row.id
    }
    this.loading = true
    setTopProject(datas)
      .then(() => {
        this.query()
      })
      .catch(() => {
        this.$set(row, 'booleanTopState', !data)
        this.$set(row, 'topState', data ? '0' : '1')
        this.loading = false
      })
  }
  private handleShow(data: any, row: any) {
    let datas: any = {
      isShow: data ? 1 : 0,
      id: row.id
    }
    this.loading = true
    setShowState(datas)
      .then(() => {
        this.query()
      })
      .catch(() => {
        this.$set(row, 'isShow', data ? 0 : 1)
        this.$set(row, 'isShowBoolean', !data)
        this.loading = false
      })
  }
  handleDetails(row: any) {
    this.$router.push({ name: 'DetailsProjectNotice', params: { id: row.id } })
  }
  handleDelete(row: any) {
    this.$confirm('确定要删除此项目公告吗？', '温馨提示').then(() => {
      deleteProject({
        id: row.id
      }).then((res) => {
        this.query()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    })
  }
}
