var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "project-notice" },
    [
      _c(
        "el-form",
        { attrs: { inline: "", model: _vm.formInfo, size: "small" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "单位名称：" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formInfo.companyName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "companyName", $$v)
                      },
                      expression: "formInfo.companyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        size: "small",
                        clearable: ""
                      },
                      on: { change: _vm.selecttedRole },
                      model: {
                        value: _vm.formInfo.companyType,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "companyType", $$v)
                        },
                        expression: "formInfo.companyType"
                      }
                    },
                    _vm._l(_vm.companyType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位负责人：" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formInfo.managerName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "managerName", $$v)
                      },
                      expression: "formInfo.managerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small" },
                      on: { click: _vm.resetFn }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("nums-tips", { attrs: { nums: _vm.totalCount } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  index: index => index + 1,
                  "min-width": "80px",
                  width: "80px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位名称",
                  prop: "companyName",
                  "min-width": "100px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位类型",
                  "min-width": "100px",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(_vm._s(_vm._f("filterStatus")(row.companyType)))
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位负责人",
                  prop: "managerName",
                  "min-width": "150px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "提交时间",
                  prop: "commitedTime",
                  "min-width": "100px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("filterTimeStamp")(row.commitedTime))
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.formInfo.pageNumber,
              "page-sizes": _vm.pageSizesList,
              "page-size": _vm.formInfo.pageSize,
              layout: "sizes, prev, pager, next,total,jumper,slot",
              total: _vm.totalCount
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }