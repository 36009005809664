
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { mortgageApprovalList, getBankList, getPropertyrightTransactionCenterData } from '@/api/loans'
import { statusOptions } from '@/views/project-management/options'
import { searchArea } from '@/api/noticeManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips
  },
  filters: {
    getStatusVal(data: any) {
      let returnVal: any = ''
      for (let i = 0; i < statusOptions.length; i++) {
        if (data == statusOptions[i].value) {
          returnVal = statusOptions[i].label
        }
      }
      return returnVal
    }
  }
})
export default class ProductsApproval extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private bankOptions: any = []
  private areaOptions: any = [
    {
      areaConfigId: '',
      areaConfigName: '全部'
    }
  ]
  private statusOptions = statusOptions
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: Array<any> = []
  // private tradeCenterOptions: Array<any> = []
  private loading = false
  private formInfo: any = {
    // lenderName: '',
    demandName: '',
    loanBank: '',
    area: '',
    status: ''
  }
  // created() {
  //   searchArea({ isDelete: false, isDisable: false }).then((res) => {
  //     if (res.data && res.data.code == 1000) {
  //       this.areaOptions = this.areaOptions.concat(res.data.data.aeras)
  //     }
  //   })
  //   // this.getTradeCenterData()
  //   this.handleBankList()
  //   this.query()
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'MortgageDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.area && (vim.formInfo.area = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.area && (isGetList = false)
      }
      if (isGetList && vim.areaOptions.length < 2) {
        searchArea({ isDelete: false, isDisable: false }).then((res) => {
          if (res.data.data && res.data.data.aeras) {
            vim.areaOptions = vim.areaOptions.concat(vim.filteAreaOptions(res.data.data.aeras))
          }
        })
      }
      if (isGetList && vim.bankOptions.length < 3) {
        vim.bankOptions = []
        vim.handleBankList()
      }
      isGetList && vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'MortgageDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        bankOptions: this.bankOptions,
        areaOptions: this.areaOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private filteAreaOptions(arr: any) {
    let returnArr: any = []
    for (let i = 0; i < arr.length; i++) {
      returnArr.push({ areaConfigId: arr[i].areaConfigId, areaConfigName: arr[i].areaConfigName })
      if (arr[i].sonAreas && arr[i].sonAreas.length) {
        for (let l = 0; l < arr[i].sonAreas.length; l++) {
          returnArr.push({ areaConfigId: arr[i].sonAreas[l].areaConfigId, areaConfigName: arr[i].sonAreas[l].areaConfigName })
        }
      }
    }
    return returnArr
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'area' || (key == 'area' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
  // 下拉银行列表数据
  handleBankList() {
    getBankList().then((res) => {
      let companyInfo = res.data.data.company
      this.bankOptions.push({ name: '全部', id: '' })
      companyInfo && this.bankOptions.push({ name: companyInfo.name, id: companyInfo.id })
      this.bankOptions.push({ name: '其他', id: '其他' })
    })
  }
  private bankSelect(str: string) {
    if (str == '其他') {
      this.formInfo.loanBank = str
    } else {
      this.formInfo.loanBank == '其他' && (this.formInfo.loanBank = '')
    }
  }
  // getTradeCenterData() {
  //   const data: any = {
  //     categoryName: 'propertyright_transaction_center'
  //   }
  //   getPropertyrightTransactionCenterData(data)
  //     .then((res) => {
  //       let data: any = res.data.data
  //       let arr: any = []
  //       data.forEach((item: any) => {
  //         arr.push({ label: item.value, value: item.key })
  //       })
  //       arr.unshift({ label: '全部', value: '' })
  //       this.tradeCenterOptions = arr
  //     })
  // }

  private async query() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    if (params.bankId == '其他') {
      params.bankId = ''
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    mortgageApprovalList(params)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // filterData(arr: Array<any>) {
  //   let _tradeCenterOptions = this.tradeCenterOptions
  //   arr.forEach((item) => {
  //     _tradeCenterOptions.forEach((it) => {
  //       if (it.value == item.propertyTradingCenter) {
  //         item.propertyTradingCenter = it.label
  //       }
  //     })
  //   })
  //   this.tableData = arr
  // }

  // 搜索发放列表
  private handleSearch() {
    this.query()
  }

  // 查看详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'MortgageDetail',
      params: {
        id: row.id,
        status: row.status,
        permission: row.permission
      }
    })
  }
}
