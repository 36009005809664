
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import {
  getCountrysideProjectList,
  getAreaPowerList,
  getBusinessTypeList,
  showListingApply,
  deleteListingApply,
  pauseApprove
} from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class CountrysideProjectList extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private businessOptions: any = []
  private areaOptions: any = []
  private statusOptions: any = [
    { label: '审核中', value: 1 },
    { label: '未通过', value: 2 },
    { label: '挂牌中', value: 3 },
    { label: '已摘牌', value: 4 },
    { label: '未成交', value: 5 },
    { label: '已成交', value: 6 },
    { label: '已中止', value: 7 },
    { label: '主动摘牌', value: 8 },
    { label: '中止审核中', value: 9 },
    { label: '竞拍结束', value: 10 },
    { label: '已完成', value: 11 },
    { label: '交易完成', value: 12 },
    { label: '交易取消', value: 13 }
  ]
  private delistingWayOptions: any = [
    { label: '全额付款', value: 1 },
    { label: '预付款', value: 2 },
    { label: '交付后再付', value: 3 },
    { label: '其他', value: 4 }
  ]
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    businessType: '',
    name: '',
    applyPerson: '',
    status: '',
    delistingWay: '',
    areaId: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'CountrysideProjectDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      if (isGetList && !vim.businessOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'CountrysideProjectDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        businessOptions: this.businessOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private handleChange(val,listingApplyId){
    showListingApply({
      listingApplyId,
      isShow: val
    }).then(res=>{
      if(res.data.code===1000){
        this.$message.success('操作成功')
        this.getList()
      }
    })
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    params.areaConfigIds=[params.areaId]
    console.log(params);
    getCountrysideProjectList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private suspensionListing(row:any){
    this.$confirm('确定申请中止挂牌', '申请中止挂牌后,需管理员进行审批', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      pauseApprove({
        id: row.listingApplyId
      }).then(res=>{
        if(res.data.code===1000){
          this.$message.success('操作成功')
          this.getList()
        }
      })
    }).catch(() => {
    });
  }
  private handleDelete(row: any){
    this.$confirm('删除后将不可恢复,你还要继续吗？', '确认删除当前产权项目？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      deleteListingApply({
        idList: [row.listingApplyId]
      }).then(res=>{
        if(res.data.code===1000){
          this.$message.success('删除成功')
          this.getList()
        }
      })
    }).catch(() => {
    });
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: row.listingApplyId, isExport: row.businessType != '7' ? '1' : '0' }
    })
  }
}
