export const roleOptions = [
  { label: '企业', value: '1' },
  { label: '个人', value: '2' },
]

export const InvestmentMemberOptions = [
  { label: '正常', value: '1' },
  { label: '注销', value: '2' },
  { label: '非会员', value: '3' },
]

export const NOInvestmentMemberOptions = [
  { label: '正常', value: '1' },
  { label: '注销', value: '2' },
  { label: '代缴费', value: '3' },
  { label: '非会员', value: '4' },
]

export const PaymentStatusOptions = [
  { label: '代缴费', value: '1' },
  { label: '已缴费', value: '2' },
]

export const roleOption = [
  { label: '挂牌方', value: '1' },
  { label: '担保方', value: '2' },
  { label: '服务商', value: '3' },
  { label: '受托管理人', value: '4' },
  { label: '投资人', value: '5' },
]
export const StatusOptions = [
  { label: '挂牌中', value: '1' },
  { label: '挂牌结束', value: '2' },
  { label: '主动摘牌', value: '3' },
]