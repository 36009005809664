
import { Component, Vue } from 'vue-property-decorator'
import { approvalManagementList } from '@/api/financing'
import iconPretrial from '@/assets/images/admin/selected_pretrial.png'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({ components: {} })
export default class SettlementAplmanagementList extends Vue {
  private loadingTxt: string = loadingText
  private dataList: any = []
  private imgUrlArr: any = [iconPretrial]
  private loading = true
  created() {
    this.getRuleList()
  }
  private getRuleList() {
    const value: string = 'zct_settlement'
    approvalManagementList(value).then((res) => {
      if (res.data.data) {
        this.dataList = res.data.data
        this.loading = false
      }
    })
  }
  private handleSetting(data: any) {
    this.$router.push({
      name: 'SettlementAplmanagementDetails',
      params: {
        ruleType: data.ruleType
      }
    })
  }
}
