
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import timeFormat from '@/utils/timeFormat' //时间过滤器
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getBlackList, deleteBlack } from '@/api/countrysidePropertyTransaction'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination,
    numsTips
  },
  filters: {
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class BlacklistManagement extends Vue {
  private loadingTxt: string = loadingText
  private typeOptions: any = [
    { label: '全部', value: '' },
    { label: '个人', value: 2 },
    { label: '企业', value: 1 }
  ]
  // private areaOptions: any = [
  //   { label: '全部', value: '' },
  //   { label: '百色县', value: 1 },
  //   { label: '田东县', value: 2 },
  //   { label: '田阳县', value: 3 }
  // ]
  private time: any = ''
  private formInfo: any = {
    type: '',
    name: '',
    businessType: '1'
    // area: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (pageParams && ((['BlacklistAdd', 'BlacklistDetail'].indexOf(from.name) > -1 && to.name == 'BlacklistManagement') || (['LoanBlacklistAdd', 'LoanBlacklistDetail'].indexOf(from.name) > -1 && to.name == 'LoanBlacklistManagement'))) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      if (to.name == 'BlacklistManagement' && vim.formInfo.businessType != '2') {
        vim.formInfo.businessType = '2'
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['BlacklistAdd', 'LoanBlacklistAdd', 'BlacklistDetail', 'LoanBlacklistDetail'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      key != 'businessType' && (this.formInfo[key] = '')
    })
  }
  private getList() {
    this.loading = true
    if (this.time) {
      let startTime = new Date(this.time[0]).getTime(),
        endTime = new Date(this.time[1]).getTime()
      this.$set(this.formInfo, 'startTime', startTime)
      this.$set(this.formInfo, 'endTime', endTime)
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    getBlackList(params).then((res) => {
      if (res.data && res.data.data && res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  // 搜索发放列表
  private handleSearch() {
    this.getList()
  }
  // 添加
  private handleAdd() {
    this.$router.push({
      name: this.formInfo.businessType == '2' ? 'BlacklistAdd' : 'LoanBlacklistAdd',
      params: { businessType: this.formInfo.businessType }
    })
  }
  // 查看详情
  private handleDetails(row: any) {
    this.$router.push({
      name: this.formInfo.businessType == '2' ? 'BlacklistDetail' : 'LoanBlacklistDetail',
      params: { id: row.id }
    })
  }
  // 删除
  private handleDel(id: any) {
    this.$confirm('是否确认删除该黑名单？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        deleteBlack({ id: id })
          .then((res) => {
            if (res.data && res.data.code == 1000) {
              this.getList()
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
