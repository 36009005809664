var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      staticClass: "uploader",
      attrs: {
        action: "",
        accept: "image/*",
        "on-change": _vm.onUploadChange,
        "auto-upload": false,
        "show-file-list": false
      }
    },
    [
      _vm.value
        ? _c("img", {
            staticClass: "image",
            attrs: { slot: "trigger", src: _vm.value },
            slot: "trigger"
          })
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "info-container",
              attrs: { slot: "trigger" },
              slot: "trigger"
            },
            [
              _c("div", [
                _c("i", { staticClass: "el-icon-plus image-uploader-icon" }),
                _vm.signType === "1"
                  ? _c("div", [_vm._v("上传公司印章")])
                  : _vm._e(),
                _vm.signType === "2"
                  ? _c("div", [_vm._v("上传法人印章")])
                  : _vm._e(),
                _vm.signType === "3"
                  ? _c("div", [_vm._v("上传营业执照")])
                  : _vm._e()
              ])
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }