
import { Component, Vue } from 'vue-property-decorator'
import { approvalManagementList, checkApprovalSetting } from '@/api/financing'
import { getAreaPowerList } from '@/api/countrysidePropertyTransaction'
import iconAcceptance from '@/assets/images/admin/selected_acceptance.png'
import iconEstablish from '@/assets/images/admin/selected_establish.png'
import iconFinalJudgment from '@/assets/images/admin/selected_final_judgment.png'
import iconPretrial from '@/assets/images/admin/selected_pretrial.png'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  name: 'ApprovalManagement',
  components: {}
})
export default class ApprovalManagement extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private dataList: any = []
  private image: any = {
    iconAcceptance,
    iconEstablish,
    iconFinalJudgment,
    iconPretrial
  }
  private areaData: any = []
  private areaConfigId: string = ''
  private loading = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next(async (vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'CountrysideApprovalDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.areaConfigId = pageParams.areaConfigId
        vim.areaData = pageParams.areaData
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.areaConfigId && (vim.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.areaConfigId && (isGetList = false)
      }
      if (isGetList && !vim.areaData.length) {
        await getAreaPowerList()
          .then((res) => {
            if (res.data.data) {
              vim.areaData = res.data.data
              if (!vim.areaConfigId) {
                vim.areaConfigId = res.data.data[0].areaId
              }
            }
          })
          .catch(() => {
            isGetList = false
          })
      }
      if (isGetList) {
        vim.getRuleList()
      } else {
        vim.loading = false
      }
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'CountrysideApprovalDetail') {
      let pageParams: any = {
        areaData: this.areaData,
        areaConfigId: this.areaConfigId
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private handleSetting(data?: any) {
    this.loading = true
    let params: any = {
      ruleType: data.ruleType,
      areaConfigId: this.areaConfigId
    }
    checkApprovalSetting(params)
      .then((res) => {
        if (res.data.data) {
          setTimeout(() => {
            this.$router.push({
              name: 'CountrysideApprovalDetail',
              params: {
                ruleType: data.ruleType,
                dataDetail: res.data.data,
                areaConfigId: this.areaConfigId
              }
            })
          }, 300)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getRuleList() {
    this.loading = true
    const value: string = 'rural_property'
    approvalManagementList(value)
      .then((res) => {
        if (res.data.data) {
          this.dataList = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
