
import { Component, Vue, Prop } from 'vue-property-decorator'
import { loadingText } from '@/utils/defaultGoalConfig'
import moment from 'moment'

@Component
export default class SimpleApprovalInfo extends Vue {
  @Prop() info: any
  // 老格式
  @Prop({
    default: true
  }) oldType?: boolean
  @Prop({
    default: false
  })
  bondLoading?: boolean
  // @Prop({ default: false }) selectPay?: boolean
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  private iconType: object = ['primary', 'success', 'danger']
  private loadingTxt: string = loadingText
  status = ['待审核', '已同意', '已拒绝']
  timeFormat(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
  // 获取银行卡拼接名
  private getBankCardIdTail(stratVal: any, cardId: any) {
    let returnVal: string = ''
    if (stratVal) {
      returnVal = stratVal
      if (cardId) {
        let cardIdArr: any = cardId.split('')
        returnVal += '（' + (cardIdArr.length >= 4 ? cardId.slice(cardIdArr.length - 4) : cardId) + '）'
      } else {
        returnVal += '()'
      }
    }
    return returnVal
  }
}
