var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formInfo",
          staticClass: "formBox",
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            size: "small",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "手机号码：", prop: "phone" }
            },
            [
              _c("el-input", {
                staticClass: "inputItem",
                attrs: {
                  size: "small",
                  placeholder: "请输入手机号",
                  disabled: _vm.inputDisabled
                },
                on: { blur: _vm.getInfo },
                model: {
                  value: _vm.formInfo.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "phone", $$v)
                  },
                  expression: "formInfo.phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "姓名：", prop: "name" }
            },
            [
              _c("el-input", {
                staticClass: "inputItem",
                attrs: {
                  size: "small",
                  placeholder: "请输入姓名",
                  disabled: _vm.inputDisabled
                },
                model: {
                  value: _vm.formInfo.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "name", $$v)
                  },
                  expression: "formInfo.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "身份证号码：", prop: "idCard" }
            },
            [
              _c("el-input", {
                staticClass: "inputItem",
                attrs: {
                  size: "small",
                  placeholder: "请输入身份证",
                  maxlength: 18,
                  disabled: _vm.inputDisabled
                },
                on: { change: _vm.changeInput },
                model: {
                  value: _vm.formInfo.idCard,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "idCard", $$v)
                  },
                  expression: "formInfo.idCard"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "邮箱：", prop: "email" }
            },
            [
              _c("el-input", {
                staticClass: "inputItem",
                attrs: {
                  size: "small",
                  placeholder: "请输入邮箱",
                  disabled: _vm.inputDisabled
                },
                model: {
                  value: _vm.formInfo.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "email", $$v)
                  },
                  expression: "formInfo.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "角色：", prop: "roleId" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.selecttedRole },
                  model: {
                    value: _vm.formInfo.roleId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "roleId", $$v)
                    },
                    expression: "formInfo.roleId"
                  }
                },
                _vm._l(_vm.roleOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.systemType != "zct"
            ? _c(
                "el-form-item",
                { staticClass: "formItems", attrs: { label: "所属区域：" } },
                [_vm._v(_vm._s(_vm.areaConfigName))]
              )
            : _vm._e(),
          _vm.system
            ? _c(
                "el-form-item",
                {
                  staticClass: "formItems",
                  attrs: {
                    label: "推荐人代码：",
                    prop: _vm.recommenderCode ? "recommendCode" : ""
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "inputItem",
                    attrs: { size: "small", placeholder: "请输入推荐人代码" },
                    model: {
                      value: _vm.formInfo.recommendCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "recommendCode", $$v)
                      },
                      expression: "formInfo.recommendCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticClass: "formItems",
              attrs: { label: "状态：", prop: "status" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formInfo.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "status", $$v)
                    },
                    expression: "formInfo.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-button",
            { staticClass: "returnBtn", on: { click: _vm.handleReturn } },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              staticClass: "sumbitBtn",
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }