var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "farmer-credit-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "div",
        { staticClass: "credit-mock" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForms",
              staticClass: "formStyle",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "信用等级：", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "390px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "得分：", prop: "score" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80px", "margin-right": "10px" },
                      attrs: { placeholder: "请选择", disabled: "" },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.scoreOption, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          return _vm.changeCode("score")
                        }
                      },
                      model: {
                        value: _vm.formData.score,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "score", $$v)
                        },
                        expression: "formData.score"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("分")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否信用户：" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": _vm.switchOr ? "是" : "否" },
                    model: {
                      value: _vm.switchOr,
                      callback: function($$v) {
                        _vm.switchOr = $$v
                      },
                      expression: "switchOr"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.add } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }