var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settlement-aplmanagement-details" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "contentBox",
        attrs: { "element-loading-text": _vm.loadingTxt }
      },
      [
        _c("div", { staticClass: "contents" }, [
          _c(
            "div",
            { staticClass: "innerBox" },
            [
              _vm._l(_vm.dynamicTags, function(tag, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "itemInfo",
                    on: {
                      click: function($event) {
                        return _vm.handleTab(tag, index)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(index + 1) + "、 "),
                    _c(
                      "el-tag",
                      {
                        staticClass: "tag",
                        attrs: { "disable-transitions": false }
                      },
                      [_vm._v(_vm._s(tag.name))]
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn item-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.addApprovalPerson()
                    }
                  }
                },
                [_vm._v("+ 新增")]
              )
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                staticClass: "button-new-tag",
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [_vm._v("返回")]
            ),
            _c(
              "el-button",
              {
                staticClass: "button-new-tag",
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function($event) {
                    return _vm.undateApprovalRule()
                  }
                }
              },
              [_vm._v("提交")]
            )
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "选择审批人员",
              visible: _vm.dialogTableVisible,
              width: "30%",
              center: _vm.center
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogTableVisible = $event
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "inpiutBox" },
              [
                _c("el-input", {
                  staticClass: "inputVal",
                  attrs: { size: "mini", placeholder: "请输入关键字搜索" },
                  on: {
                    input: function($event) {
                      return _vm.handleSearch()
                    }
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tables" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.gridData,
                      "max-height": "280",
                      "show-header": false,
                      "highlight-current-row": "",
                      size: "small",
                      border: ""
                    },
                    on: { "current-change": _vm.handleIsSelect }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "name", align: "left" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleConfirm()
                      }
                    }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }