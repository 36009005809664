var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basic-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("标的名称")]),
              _c(
                "span",
                { staticClass: "a-span", on: { click: _vm.intoDetails } },
                [_vm._v(_vm._s(_vm.info.listingApplyName))]
              )
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("业务类型")]),
              _c("span", [_vm._v(_vm._s(_vm.info.businessTypeDesc))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("交易方式")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.info.tranferType == 1
                      ? "竞拍"
                      : _vm.info.tranferType == 2
                      ? "非竞拍"
                      : _vm.info.tranferType == 3
                      ? "预售"
                      : "--"
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("挂牌价格")]),
              _c("span", [_vm._v(_vm._s(_vm.info.amount))])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("基本信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }