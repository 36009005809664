import request, { request as http } from '@/utils/request'

// 单位审核列表
export function unitReviewList(data: Object) {
  return request({
    url: '/companyAudit/page',
    method: 'POST',
    data,
  })
}
// 单位审核详情
export function unitReviewDetail(data: any) {
  return request({
    url: `/companyAudit/detail`,
    method: 'POST',
    data,
  })
}

// 单位列表
export function unitList(data: Object) {
  return request({
    url: '/company/pageAudited',
    method: 'POST',
    data,
  })
}

// 单位详情
export function unitListDetail(data: any) {
  return request({
    url: `/company/detailAudited`,
    method: 'POST',
    data,
  })
}

// 单位审核接口
export function unitAuditDetail(data: any) {
  return request({
    url: `/companyAudit/doneAudit`,
    method: 'POST',
    data,
  })
}

// 企业禁用（切换为个人）
export function enterpriseDisable(data: any) {
  return request({
    url: `/company/limitCompany`,
    method: 'POST',
    data,
  })
}
