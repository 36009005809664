
import { Component, Vue } from 'vue-property-decorator'
import { sessionStore } from '../../../utils/data-management'
@Component({
  components: {}
})
export default class SubsidyAddType extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.params.type != 'add') {
      to.meta.title = '批量发放'
    } else {
      to.meta.title = '添加补贴'
    }
    next()
  }

  mounted() {
    if (sessionStore.get('importExcel')) {
      sessionStore.remove('importExcel')
    }
    if (sessionStore.get('uniqueKey')) {
      sessionStore.set('uniqueKey', '')
    }
  }

  private toSubsidyAdd(type: string) {
    if (this.$route.params.type == 'add') {
      this.$router.push({
        name: 'SubsidyAdd',
        params: {
          id: 'add',
          type: type
        }
      })
    } else {
      this.$router.push({
        name: 'SubsidyImport',
        params: {
          type: type
        }
      })
    }
  }
}
