var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "project-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("账号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("企业名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.applyTime))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("所属行业：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.endTime))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("成立时间：")]),
          _c("span", [
            _vm._v(_vm._s(_vm._f("formatTime")(_vm.baseInfo.delistingTime)))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("统一社会信用代码：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("股权架构：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("注册资本：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("实缴资本：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("注册区域：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("注册地址：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("办公区域：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("办公地址：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("营业执照扫描件：")]),
            _c("el-image", { attrs: { src: _vm.logoUrl } })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "part first" }, [
      _vm._m(1),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("户名：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("开户银行：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("银行账号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("开户许可证：")]),
            _c("el-image", { attrs: { src: _vm.logoUrl } })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "part first" }, [
      _vm._m(2),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("法人代表姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("身份证号码：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("身份证扫描件：")]),
            _c("el-image", { attrs: { src: _vm.logoUrl } })
          ],
          1
        ),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("法人授权认证：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ])
      ])
    ]),
    _c("div", { staticClass: "part first" }, [
      _vm._m(3),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("联系人姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("联系人邮箱：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("联系人手机号码：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("span", [_vm._v("座机号：")]),
          _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
        ])
      ])
    ]),
    _c("div", { staticClass: "part first" }, [
      _vm._m(4),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("公司章程/修正案：")]),
            _c(
              "el-link",
              { attrs: { href: "#", target: "_blank", underline: false } },
              [_vm._v(_vm._s(`items.fileName`))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("征信报告：")]),
            _c(
              "el-link",
              { attrs: { href: "#", target: "_blank", underline: false } },
              [_vm._v(_vm._s(`items.fileName`))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("近三年审计报告：")]),
            _c(
              "el-link",
              { attrs: { href: "#", target: "_blank", underline: false } },
              [_vm._v(_vm._s(`items.fileName`))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("span", [_vm._v("评级报告：")]),
            _c(
              "el-link",
              { attrs: { href: "#", target: "_blank", underline: false } },
              [_vm._v(_vm._s(`items.fileName`))]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("机构信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("银行信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("企业法人代表信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("联系人信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("联系人信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }