import request from '@/utils/request'
// -------收款费项配置--------

// 获取收款费项配置
export function getCollectionFeeConfig(data: Object) {
    return request({
        url: '/admin/collectionFeeItem/list',
        method: 'POST',
        data: data
    })
}

// 保存收款费项配置
export function saveCollectionFeeConfig(data: Object) {
    return request({
        url: '/admin/collectionFeeItem/save',
        method: 'POST',
        data: data
    })
}

// -------交易规则配置--------
// 获取某区域交易规则配置详情
export function getAreaTradingRulesConfig(data: Object) {
    return request({
        url: '/transactionRule/detail',
        method: 'POST',
        data: data
    })
}

// 保存交易规则配置信息
export function saveTradingRulesConfig(data: Object) {
    return request({
        url: '/transactionRule/save',
        method: 'POST',
        data: data
    })
}

// -------业务类型配置--------
// 列表
export function getBusinessTypePage(data: Object) {
    return request({
        url: '/businessTypeConfig/list',
        method: 'POST',
        data: data
    })
}

// 详情
export function getBusinessTypeDetail(data: Object) {
    return request({
        url: '/businessTypeConfig/getOne',
        method: 'POST',
        data: data
    })
}

// 新增
export function businessTypeAdd(data: Object) {
    return request({
        url: '/businessTypeConfig/add',
        method: 'POST',
        data: data
    })
}

// 编辑
export function businessTypeUpdate(data: Object) {
    return request({
        url: '/businessTypeConfig/update',
        method: 'POST',
        data: data
    })
}

// 删除
export function businessTypeDelete(data: Object) {
    return request({
        url: '/businessTypeConfig/delete',
        method: 'POST',
        data: data
    })
}

// -------挂牌在线授权协议--------
// 挂牌在线授权协议管理人员区域
export function getLicensingAgreementArea() {
    return request({
        url: '/listingAuthorizationAgreement/getUserArea',
        method: 'POST'
    })
}

// 挂牌在线授权协议详情
export function getLicensingAgreementDetail(data: Object) {
    return request({
        url: '/listingAuthorizationAgreement/getUserAgreement',
        method: 'POST',
        data: data
    })
}

// 挂牌在线授权协议添加
export function addLicensingAgreement(data: Object) {
    return request({
        url: '/listingAuthorizationAgreement/add',
        method: 'POST',
        data: data
    })
}

// 挂牌在线授权协议保存修改
export function saveLicensingAgreement(data: Object) {
    return request({
        url: '/listingAuthorizationAgreement/edit',
        method: 'POST',
        data: data
    })
}