import request, { request as http } from '@/utils/request'
import { AxiosPromise } from 'axios'

// 获取业务分类列表
export function getBusinessTypeList() {
  return request({
    url: '/fileConfig/businessType',
    method: 'GET'
  })
}

// 获取产品分类列表
export function getProductClassifyList() {
  return request({
    url: '/fileConfig/productClassify',
    method: 'GET'
  })
}

export function deleteById(id: number) {
  return request({
    url: '/roleFileTypeConfig/delete',
    method: 'POST',
    data: {
      id
    }
  })
}

// 获取 流程分类列表
export function getProcessClassifyList() {
  return request({
    url: '/fileConfig/processClassify',
    method: 'GET'
  })
}

// 获取角色分类列表
export function getRoleConfigList() {
  return request({
    url: '/fileConfig/role',
    method: 'GET'
  })
}

// 添加文件
export function addFile(data: Object) {
  return request({
    url: '/roleFileTypeConfig/insert',
    method: 'POST',
    data: data
  })
}




// 获取配置文件列表
export function getFileList(data: Object) {
  return request({
    url: '/roleFileTypeConfig/page',
    method: 'POST',
    data: data
  })
}

// 编辑文件详情

export function modifyFile(data: Object) {
  return request({
    url: '/fileConfig/update',
    method: 'POST',
    data: data
  })
}

export function updateFile(data: Object) {
  return request({
    url: '/roleFileTypeConfig/update',
    method: 'POST',
    data: data
  })
}

// ---------------------------------------------------------------------------------

// 签章配置添加和修改(保存)
export function saveSignatureConfig(data: Object) {
  return request({
    url: '/admin/signConfig/save',
    method: 'POST',
    data: data
  })
}

//查询企业列表信息
export function quaryCompanyList(data: Object) {
  // return request({
  //   url: '/jst/sys/user/companyInfoList',
  //   method: 'POST',
  //   data: data
  // })
  return request({
    url: '/enterprise/getEnterpriseList',
    method: 'POST',
    data: data
  })
}

//查询签章配置的接口
export function quarySignatureConfig(data: Object) {
  return request({
    url: '/admin/signConfig/get',
    method: 'GET',
    data: data
  })
}

//查询银行业务管理列表
export function systemBankBusinessList(data: Object) {
  return request({
    url: '/admin/signConfig/get',
    method: 'POST',
    data: data
  })
}

//添加银行业务
export function addBankBusiness(data: Object) {
  return request({
    url: '/admin/signConfig/get',
    method: 'POST',
    data: data
  })
}

//删除银行业务
export function deleteBankBusiness(data: Object) {
  return request({
    url: '/admin/signConfig/get',
    method: 'POST',
    data: data
  })
}

//查看银行业务详情
export function getBankBusinessDetail(data: Object) {
  return request({
    url: '/admin/signConfig/get',
    method: 'POST',
    data: data
  })
}



