var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "my-approval-detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm.ruleType == "9" || _vm.ruleType == "12" || _vm.ruleType == "16"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "基本信息", name: "1" } },
                [
                  _vm.ruleType == "9"
                    ? _c("basic-info", { attrs: { info: _vm.bondBasic } })
                    : _vm.ruleType == "16"
                    ? _c("new-basic-info", {
                        attrs: { info: _vm.newBondBasic }
                      })
                    : _c("liquidation-basic-info", {
                        attrs: { info: _vm.proDetail }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType == "9"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "保证金信息", name: "2" } },
                [
                  _c("bond-info", {
                    ref: "bondInfo",
                    attrs: {
                      info: _vm.bondBasic,
                      bondLoading: _vm.bondLoading,
                      propSelectable: _vm.selectable,
                      showSelection: _vm.processStatus == 0,
                      paginationParam: _vm.bondPaginationParam,
                      depositInfos: _vm.depositInfos
                    },
                    on: {
                      "update:bondLoading": function($event) {
                        _vm.bondLoading = $event
                      },
                      "update:bond-loading": function($event) {
                        _vm.bondLoading = $event
                      },
                      searchBondList: _vm.searchBondList
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType == "12"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "清算信息", name: "3" } },
                [
                  _c("liquidation-info", {
                    attrs: {
                      disableList: _vm.disableList,
                      info: _vm.proDetail.liquidationApply,
                      payeeList: _vm.proDetail.payeeList,
                      openEdit: _vm.proDetail.lastPerson
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType != "9" &&
          _vm.ruleType != "12" &&
          _vm.ruleType != "13" &&
          _vm.ruleType != "16"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "标的信息", name: "4" } },
                [
                  _c("mark-info", {
                    attrs: {
                      areaShow: true,
                      areaName: _vm.proDetail.areaName,
                      info: _vm.proDetail.bidInfo,
                      imgList: _vm.proDetail.imgList,
                      fileStatus: _vm.imgListStatus,
                      customFields: _vm.proDetail.customFields,
                      customStatus: _vm.customStatus,
                      type: _vm.proDetail.businessType,
                      status: _vm.proDetail.status,
                      checkboxShow: _vm.checkboxShow,
                      isRefuse: _vm.isRefuse,
                      processStatus: _vm.processStatus
                    },
                    on: { markStatus: _vm.getMarkStatus }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType != "9" &&
          _vm.ruleType != "12" &&
          _vm.ruleType != "13" &&
          _vm.ruleType != "16"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "标的物所属主体信息", name: "5" } },
                [
                  _c("subject-info", {
                    attrs: {
                      info: _vm.proDetail.listingMainPartInfo,
                      isRemarkShow: true,
                      checkboxShow: _vm.checkboxShow,
                      itemStatus: _vm.listingMainPartStatus,
                      processStatus: _vm.processStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType == "13"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "竞买人信息", name: "6" } },
                [
                  _c("bidder-info", {
                    attrs: {
                      info: _vm.proDetail,
                      checkboxShow: _vm.checkboxShow,
                      itemStatus: _vm.bidderInfoStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType == "16"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "申请方信息", name: "6" } },
                [
                  _c("applicant-info", {
                    attrs: {
                      info: { ..._vm.proDetail },
                      checkboxShow: _vm.checkboxShow,
                      processStatus: _vm.processStatus,
                      itemStatus: _vm.bidderInfoStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.payTime
            ? _c(
                "el-tab-pane",
                { attrs: { label: "缴费情况", name: "10" } },
                [
                  _c("new-pay-info", {
                    attrs: {
                      info: { ..._vm.proDetail },
                      checkboxShow: true,
                      itemStatus: _vm.bidderInfoStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ruleType != "9" && _vm.ruleType != "12"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.ruleType == "16" ? "相关资料" : "所需资料",
                    name: "7"
                  }
                },
                [
                  _c("need-data", {
                    attrs: {
                      ruleType: _vm.ruleType,
                      info:
                        _vm.ruleType != "13" && _vm.ruleType != "16"
                          ? _vm.proDetail.files
                          : _vm.proDetail.delistingFiles,
                      itemStatus: _vm.needFilesStatus,
                      otherFileList:
                        _vm.ruleType != "13" && _vm.ruleType != "16"
                          ? _vm.proDetail.otherFileList
                          : _vm.proDetail.delistingOtherFiles,
                      otherStatus: _vm.otherFileStatus,
                      checkboxShow: _vm.checkboxShow,
                      processStatus: _vm.processStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.proDetail.businessType == "7" &&
          (_vm.ruleType == "7" || _vm.ruleType == "8")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "竞买公告", name: "8" } },
                [
                  _c("bidding-announcement", {
                    attrs: {
                      info:
                        _vm.proDetail.bidNotice &&
                        _vm.proDetail.bidNotice.bidNoticDetail
                          ? _vm.proDetail.bidNotice.bidNoticDetail
                          : null,
                      checkboxShow: _vm.checkboxShow,
                      itemStatus: _vm.bidNoticeStatus,
                      processStatus: _vm.processStatus
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "审批信息", name: "9" } },
            [
              _vm.ruleType == "9"
                ? _c(
                    "div",
                    {
                      staticClass: "rowInfo",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "r1",
                          staticStyle: { "margin-right": "100px" }
                        },
                        [
                          _vm._v(
                            "交易保证金（元）：" +
                              _vm._s(_vm.bondBasic.tradeMarginMoney)
                          )
                        ]
                      ),
                      _c("div", { staticClass: "r2" }, [
                        _vm._v(
                          "退还竞拍人：" + _vm._s(_vm.chooseListPersonName)
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c("approval-info", {
                attrs: {
                  fileList: _vm.applFileList,
                  projectDetailShow: true,
                  showTitle: true,
                  showTimeline: false,
                  transactionAmountShow:
                    _vm.ruleType == "16" &&
                    !_vm.proDetail.projectStatus &&
                    _vm.proDetail.lastPerson &&
                    _vm.proDetail.businessType != "7" &&
                    _vm.proDetail.tranferType != "1",
                  qualificationsShow:
                    !_vm.proDetail.projectStatus &&
                    _vm.proDetail.lastPerson &&
                    _vm.proDetail.businessType == "7",
                  qualificationsEdit: true,
                  oldType: _vm.ruleType != "9",
                  oldRecord: _vm.oldRecord,
                  bankCardOptions: _vm.bankCardOptions,
                  selectPay:
                    (_vm.ruleType == "9" || _vm.ruleType == "12") &&
                    _vm.proDetail.lastPerson,
                  info: _vm.proDetail,
                  processStatus: _vm.processStatus,
                  isRemarkShow: true
                },
                on: {
                  cardSelected: _vm.cardSelected,
                  remark: _vm.getRemark,
                  remarks: _vm.getRemarks,
                  projectId: _vm.getProjectId,
                  transactionAmount: _vm.getTransactionAmount,
                  transactionPrice: _vm.getTransactionPrice
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "审批记录", name: "10" } },
            [
              _c("simple-approval-info", {
                attrs: {
                  oldType: false,
                  info: _vm.proDetail.approvalInfos,
                  bondLoading: _vm.bondLoading2
                },
                on: {
                  "update:bondLoading": function($event) {
                    _vm.bondLoading2 = $event
                  },
                  "update:bond-loading": function($event) {
                    _vm.bondLoading2 = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.getProLength && _vm.activeName == "9" && _vm.processStatus == 0
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt(2)
                    }
                  }
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.approvalEvt(1)
                    }
                  }
                },
                [_vm._v("通过")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.processStatus == 0 && _vm.ruleType == "9" && _vm.activeName == "2"
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.goApproval()
                    }
                  }
                },
                [_vm._v("去审批")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "授权认证", visible: _vm.qrDiaShow },
          on: {
            "update:visible": function($event) {
              _vm.qrDiaShow = $event
            }
          }
        },
        [
          _c("QrCode", {
            attrs: { visible: _vm.qrDiaShow },
            on: { notify: _vm.notify }
          })
        ],
        1
      ),
      _vm.markShow
        ? _c("div", { staticClass: "marks" }, [
            _c("div", { staticClass: "mark-main", style: { width: "800px" } }, [
              _c("div", { staticClass: "mark-title" }, [
                _vm._v(" 通过挂牌申请 "),
                _c(
                  "span",
                  { staticClass: "close-btn", on: { click: _vm.closeFn } },
                  [_vm._v("x")]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "mark-content",
                  style: { height: _vm.markHeight }
                },
                [
                  [1, 2].indexOf(_vm.proDetail.businessType) > -1
                    ? _c("div", { staticClass: "mark-item" }, [
                        _c("div", [
                          _c("span", { staticClass: "must" }, [_vm._v("*")]),
                          _vm._v(_vm._s("面积(" + _vm.areaUnit + ")"))
                        ]),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "width-200",
                              attrs: {
                                type: "number",
                                size: "small",
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.markInfo.holdArea,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "holdArea", $$v)
                                },
                                expression: "markInfo.holdArea"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  [1, 2].indexOf(_vm.proDetail.businessType) == -1
                    ? _c("div", { staticClass: "mark-item" }, [
                        _c(
                          "div",
                          { staticStyle: { "word-break": "break-all" } },
                          [
                            _c("span", { staticClass: "must" }, [_vm._v("*")]),
                            _vm._v(
                              _vm._s(
                                "标的数量(" +
                                  (_vm.proDetail.bidInfo.unit &&
                                  _vm.proDetail.bidInfo.unit.value
                                    ? _vm.proDetail.bidInfo.unit.value
                                    : "") +
                                  ")"
                              )
                            )
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "width-200",
                              attrs: {
                                type: "number",
                                size: "small",
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.markInfo.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "number", $$v)
                                },
                                expression: "markInfo.number"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mark-item" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.markInfo.bidType,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "bidType", $$v)
                              },
                              expression: "markInfo.bidType"
                            }
                          },
                          [_vm._v("一口价")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.markInfo.bidType,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "bidType", $$v)
                              },
                              expression: "markInfo.bidType"
                            }
                          },
                          [_vm._v("单价")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "mark-item listingPrice-item" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "listingPrice-content" },
                      [
                        _c("el-input", {
                          staticClass: "width-200",
                          attrs: {
                            type: "number",
                            size: "small",
                            placeholder: "请输入"
                          },
                          model: {
                            value: _vm.markInfo.listingPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.markInfo, "listingPrice", $$v)
                            },
                            expression: "markInfo.listingPrice"
                          }
                        }),
                        _vm.markInfo.bidType == 1
                          ? _c(
                              "el-select",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.markInfo.unitNum,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "unitNum", $$v)
                                  },
                                  expression: "markInfo.unitNum"
                                }
                              },
                              _vm._l(_vm.unitNumOpiton, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm.markInfo.bidType == 1
                          ? _c(
                              "el-select",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.markInfo.unitTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "unitTime", $$v)
                                  },
                                  expression: "markInfo.unitTime"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: 1, label: "年" }
                                }),
                                _c("el-option", {
                                  attrs: { value: 2, label: "季度" }
                                }),
                                _c("el-option", {
                                  attrs: { value: 3, label: "月" }
                                }),
                                _c("el-option", {
                                  attrs: { value: 0, label: "无" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "mark-item" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          staticClass: "width-200",
                          attrs: {
                            size: "small",
                            maxlength: "200",
                            placeholder: "请输入"
                          },
                          model: {
                            value: _vm.markInfo.paymentMethod,
                            callback: function($$v) {
                              _vm.$set(_vm.markInfo, "paymentMethod", $$v)
                            },
                            expression: "markInfo.paymentMethod"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.proDetail.tranferType == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(3),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "width-200",
                              attrs: {
                                type: "number",
                                size: "small",
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.markInfo.tranferEnsureAmount,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.markInfo,
                                    "tranferEnsureAmount",
                                    $$v
                                  )
                                },
                                expression: "markInfo.tranferEnsureAmount"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tranferType == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetimerange",
                                size: "small",
                                "range-separator": "至",
                                "start-placeholder": "开始时间",
                                "end-placeholder": "结束时间",
                                "default-time": ["12:00:00", "12:00:00"],
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.pickerOptions
                              },
                              model: {
                                value: _vm.applyTime,
                                callback: function($$v) {
                                  _vm.applyTime = $$v
                                },
                                expression: "applyTime"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tranferType == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetimerange",
                                size: "small",
                                "range-separator": "至",
                                "start-placeholder": "开始时间",
                                "end-placeholder": "结束时间",
                                "default-time": ["12:00:00", "12:00:00"],
                                format: "yyyy-MM-dd HH:mm:ss",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.pickerOptions
                              },
                              model: {
                                value: _vm.time,
                                callback: function($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tranferType == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(6),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "width-200",
                              attrs: {
                                type: "number",
                                size: "small",
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.markInfo.auctionInterval,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "auctionInterval", $$v)
                                },
                                expression: "markInfo.auctionInterval"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mark-item" }, [
                    _c("div", [
                      _c("span", { staticClass: "must" }, [_vm._v("*")]),
                      _vm._v(
                        _vm._s(
                          _vm.proDetail.tranferType != "1"
                            ? "竞买信息展示"
                            : "竞拍信息展示"
                        )
                      )
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.markInfo.isShowBidInfo,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "isShowBidInfo", $$v)
                              },
                              expression: "markInfo.isShowBidInfo"
                            }
                          },
                          [_vm._v("展示")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.markInfo.isShowBidInfo,
                              callback: function($$v) {
                                _vm.$set(_vm.markInfo, "isShowBidInfo", $$v)
                              },
                              expression: "markInfo.isShowBidInfo"
                            }
                          },
                          [_vm._v("不展示")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.markInfo.isShowBidInfo == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _c("div", [
                          _c("span", { staticClass: "must" }, [_vm._v("*")]),
                          _vm._v(
                            _vm._s(
                              _vm.proDetail.tranferType != "1"
                                ? "竞买人信息"
                                : "竞拍人信息"
                            )
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.markInfo.isShowBidder,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "isShowBidder", $$v)
                                  },
                                  expression: "markInfo.isShowBidder"
                                }
                              },
                              [_vm._v("直接展示")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 2 },
                                model: {
                                  value: _vm.markInfo.isShowBidder,
                                  callback: function($$v) {
                                    _vm.$set(_vm.markInfo, "isShowBidder", $$v)
                                  },
                                  expression: "markInfo.isShowBidder"
                                }
                              },
                              [_vm._v("掩码展示")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tranferType != "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(7),
                        _c(
                          "div",
                          [
                            _c("el-date-picker", {
                              staticClass: "width-200",
                              attrs: {
                                type: "date",
                                size: "small",
                                "value-format": "yyyy-MM-dd 23:59:59",
                                placeholder: "选择日期",
                                "picker-options": _vm.pickerOptions
                              },
                              model: {
                                value: _vm.markInfo.endTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "endTime", $$v)
                                },
                                expression: "markInfo.endTime"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.proDetail.tranferType == "1"
                    ? _c("div", { staticClass: "mark-item" }, [
                        _vm._m(8),
                        _c(
                          "div",
                          [
                            _c("el-input", {
                              staticClass: "width-200",
                              attrs: {
                                type: "number",
                                size: "small",
                                maxlength: "10",
                                placeholder: "请输入"
                              },
                              model: {
                                value: _vm.markInfo.delayedCycle,
                                callback: function($$v) {
                                  _vm.$set(_vm.markInfo, "delayedCycle", $$v)
                                },
                                expression: "markInfo.delayedCycle"
                              }
                            }),
                            _c("p", [
                              _vm._v(
                                "1.报名期满，如未征集到意向受让方，按照特定周期延长报名与竞拍时间；"
                              )
                            ]),
                            _c("p", [
                              _vm._v(
                                "2.当设为“0”时，则代表不允许延长报名与竞拍时间。"
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                { staticClass: "mark-btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v("确定通过")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("出价方式")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("挂牌价格(元)")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("付款方式：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("交易保证金(元)")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("报名时间")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("竞拍时间")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("竞价阶梯(元)")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("挂牌截止日期")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", { staticClass: "must" }, [_vm._v("*")]),
      _vm._v("延拍周期（天）")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }