
import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
@Component({
  components: {}
})
export default class BasicInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: false
  })
  openStatus?: boolean
  private intoDetails() {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: this.info.listingApplyId, isExport: '0' }
    })
  }
  get filteStatus() {
    let returnDesc: string = '',
      marginStatus: any = this.info.marginStatus
    if (marginStatus || marginStatus === 0) {
      let statusOptions: any = ['竞价中', '待退款', '退款审批中', '已驳回', '退款中', '退款成功', '退款失败']
      returnDesc = statusOptions[marginStatus]
    }
    return returnDesc
  }
  created(){

  }
  timeFormat(time){
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
  statusFn = (num) => {
    var status
    switch (Number(num)) {
      case 1:
        status = '全款'
        break
      case 2:
        status = '预付款'
        break
      case 3:
        status = '成交后付款'
        break
      case 4:
        status = '非竞拍其他付款方式'
        break
      case 5:
        status = '其他'
        break
      case 6:
        status = '预付款尾款'
        break
      case 7:
        status = '保证金抵扣'
        break
      case 8:
        status = '竞拍保证金'
        break
    }
    return status
  }

  statusFn1(num) {
    var status;
    switch (Number(num)) {
      case -1:
        status = "待支付";
        break;
      case 0:
        status = "支付失败";
        break;
      case 1:
        status = "支付成功（未退还）";
        break;
      case 2:
        status = "待确认";
        break;
      case 3:
        status = "退还中";
        break;
      case 4:
        status = "已退款";
        break;
      case 5:
        status = "退款审批中";
        break;
      case 6:
        status = "退款失败";
        break;
      case 7:
        status = "已抵扣";
        break;
      case 8:
        status = "线下退款";
        break;
    }
    return status;
  }
}
