
import { Component, Vue } from 'vue-property-decorator'
import { dataStatisticsArea, dataStatisticsList } from '@/api/regionalCreditManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class DataStatistics extends Vue {
  private loadingTxt: string = loadingText
  private info: any = null // 展示数据
  private areaConfig: any = []
  private areaOptions: any = []
  private isSuperAdmin: any = '0'
  private nowAreaconfigInfo: any = ''
  private loading: boolean = true
  get getDisabled() {
    let returnVal: boolean = false
    if (this.loading || (this.isSuperAdmin != '1' && (!this.nowAreaconfigInfo || this.nowAreaconfigInfo.areaName))) {
      returnVal = true
    }
    return returnVal
  }
  async created() {
    let isGetList: boolean = true,
      isCity: boolean = true
    this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
    if (this.isSuperAdmin != '1') {
      let areaConfigId: any = sessionStorage.getItem('nowAreaconfigId')
      if (!areaConfigId) {
        isGetList = false
      } else {
        this.nowAreaconfigInfo = sessionStorage.getItem('nowAreaconfigInfo') ? JSON.parse(sessionStorage.getItem('nowAreaconfigInfo') as any) : ''
        !this.nowAreaconfigInfo && (isGetList = false)
        this.nowAreaconfigInfo && this.nowAreaconfigInfo.areaName && (isCity = false)
      }
    }
    !isGetList && (this.loading = false)
    if (isGetList && !isCity) {
      let provinceName: any = this.nowAreaconfigInfo.provinceName,
        cityName: any = this.nowAreaconfigInfo.cityName,
        areaName: any = this.nowAreaconfigInfo.areaName
      this.areaConfig = [provinceName + ',' + cityName, provinceName + ',' + cityName + ',' + areaName]
      this.areaOptions = [
        {
          value: provinceName + ',' + cityName,
          label: cityName,
          children: [
            {
              value: provinceName + ',' + cityName + ',' + areaName,
              label: areaName
            }
          ]
        }
      ]
    } else if (isGetList && isCity) {
      await dataStatisticsArea()
        .then((res) => {
          if (res.data.data) {
            this.areaOptions = res.data.data.length ? this.getAreaOptions(res.data.data) : []
            if (!this.areaOptions.length) {
              isGetList = false
              this.loading = false
            } else {
              let areaVal: any = this.areaOptions[0].children[0].value
              this.areaConfig = [areaVal.split(',')[1], this.areaOptions[0].children[0].value]
            }
          }
        })
        .catch(() => {
          isGetList = false
          this.loading = false
        })
    }
    if (isGetList) {
      let areaArr: any = this.areaConfig[1].split(','),
        params: any = {
          province: areaArr[0],
          city: areaArr[1]
        }
      if (!isCity) {
        params.county = areaArr[2]
      }
      dataStatisticsList(params)
        .then((res) => {
          if (res.data.data) {
            this.info = res.data.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
  private getData(value: any) {
    this.loading = true
    let areaArr: any = value[1].split(','),
      params: any = {
        province: areaArr[0],
        city: areaArr[1]
      }
    areaArr[2] && (params.county = areaArr[2])
    dataStatisticsList(params)
      .then((res) => {
        if (res.data.data) {
          this.info = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getAreaOptions(arr: any) {
    let returnArr: any = []
    if (this.isSuperAdmin != '1') {
      let provinceName: any = this.nowAreaconfigInfo.provinceName,
        cityName: any = this.nowAreaconfigInfo.cityName
      returnArr = [
        {
          value: cityName,
          label: cityName,
          children: [
            {
              value: provinceName + ',' + cityName,
              label: '全部'
            }
          ]
        }
      ]
      for (let i = 0; i < arr.length; i++) {
        returnArr[0].children.push({
          value: provinceName + ',' + cityName + ',' + arr[i].county,
          label: arr[i].county
        })
      }
    } else {
      let mapping: any = []
      for (let i = 0; i < arr.length; i++) {
        if (!i) {
          mapping.push(arr[i].province + ',' + arr[i].city)
          returnArr.push({
            value: arr[i].city,
            label: arr[i].city,
            children: [
              {
                value: arr[i].province + ',' + arr[i].city,
                label: '全部'
              },
              {
                value: arr[i].province + ',' + arr[i].city + ',' + arr[i].county,
                label: arr[i].county
              }
            ]
          })
        } else {
          let isNew: boolean = true,
            nowInd: number = 0
          for (let l = 0; l < mapping.length; l++) {
            if (mapping[l] == arr[i].province + ',' + arr[i].city) {
              nowInd = l
              isNew = false
            }
          }
          if (isNew) {
            mapping.push(arr[i].province + ',' + arr[i].city)
            returnArr.push({
              value: arr[i].city,
              label: arr[i].city,
              children: [
                {
                  value: arr[i].province + ',' + arr[i].city,
                  label: '全部'
                },
                {
                  value: arr[i].province + ',' + arr[i].city + ',' + arr[i].county,
                  label: arr[i].county
                }
              ]
            })
          } else {
            returnArr[nowInd].children.push({
              value: arr[i].province + ',' + arr[i].city + ',' + arr[i].county,
              label: arr[i].county
            })
          }
        }
      }
    }
    return returnArr
  }
}
