var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "village-detail" },
    [
      _c(
        "el-form",
        {
          ref: "formInfo",
          staticClass: "form-style",
          attrs: {
            rules: _vm.rules,
            model: _vm.formInfo,
            size: "small",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属省份：", prop: "province" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isSuperAdmin != "1" || !_vm.requestEnd,
                    placeholder: "请选择省份",
                    size: "small"
                  },
                  on: { change: _vm.selecttedProvince },
                  model: {
                    value: _vm.formInfo.province,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "province", $$v)
                    },
                    expression: "formInfo.province"
                  }
                },
                _vm._l(_vm.provinceOptions, function(item) {
                  return _c("el-option", {
                    key: item.provinceCode,
                    attrs: {
                      label: item.provinceName,
                      value: item.provinceName + "," + item.provinceCode
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属城市：", prop: "city" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isSuperAdmin != "1" || !_vm.requestEnd,
                    placeholder: "请选择城市",
                    size: "small"
                  },
                  on: { change: _vm.selecttedCity },
                  model: {
                    value: _vm.formInfo.city,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "city", $$v)
                    },
                    expression: "formInfo.city"
                  }
                },
                _vm._l(_vm.cityOptions, function(item) {
                  return _c("el-option", {
                    key: item.cityCode,
                    attrs: {
                      label: item.cityName,
                      value: item.cityName + "," + item.cityCode
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属区县：", prop: "county" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.hasCounty() || !_vm.requestEnd,
                    placeholder: "请选择区县",
                    size: "small"
                  },
                  on: { change: _vm.selecttedCounty },
                  model: {
                    value: _vm.formInfo.county,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "county", $$v)
                    },
                    expression: "formInfo.county"
                  }
                },
                _vm._l(_vm.countyOptions, function(item) {
                  return _c("el-option", {
                    key: item.areaCode,
                    attrs: {
                      label: item.areaName,
                      value: item.areaName + "," + item.areaCode
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属街道/乡镇：", prop: "township" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择街道/镇", size: "small" },
                  model: {
                    value: _vm.formInfo.township,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "township", $$v)
                    },
                    expression: "formInfo.township"
                  }
                },
                _vm._l(_vm.townshipOptions, function(item) {
                  return _c("el-option", {
                    key: item.streetCode,
                    attrs: {
                      label: item.streetName,
                      value: item.streetName + "," + item.streetCode
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "village-item",
              attrs: { label: "所属村组(合作社/社区)：", prop: "villageName" }
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                on: { blur: _vm.villageNameBlur },
                model: {
                  value: _vm.formInfo.villageName,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "villageName", $$v)
                  },
                  expression: "formInfo.villageName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "village-item",
              attrs: {
                label: "乡镇农户年人均纯收入(元)：",
                prop: "ruralFarmerAverageIncome"
              }
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.ruralFarmerAverageIncome,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "ruralFarmerAverageIncome", $$v)
                  },
                  expression: "formInfo.ruralFarmerAverageIncome"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "village-item",
              attrs: { label: "全村农户总数：", prop: "villageFarmerNumber" }
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.villageFarmerNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "villageFarmerNumber", $$v)
                  },
                  expression: "formInfo.villageFarmerNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "village-item",
              attrs: {
                label: "应采集农户信息数：",
                prop: "shouldCollectFarmerNumber"
              }
            },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入" },
                model: {
                  value: _vm.formInfo.shouldCollectFarmerNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "shouldCollectFarmerNumber", $$v)
                  },
                  expression: "formInfo.shouldCollectFarmerNumber"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "管理人员：" } }, [
            _c(
              "div",
              { staticClass: "manager-box" },
              [
                _vm._l(_vm.managerList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "manager-item" },
                    [
                      _c("div", [_vm._v(_vm._s(item.managerName))]),
                      _c("div", [_vm._v(_vm._s(item.phone))])
                    ]
                  )
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "global-dotted-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.managerShow }
                  },
                  [_vm._v("选择")]
                )
              ],
              2
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-style" },
        [
          _c("el-button", { on: { click: _vm.handleReturn } }, [
            _vm._v("返回")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _vm.managerBox
        ? _c("div", { staticClass: "marks" }, [
            _c("div", { staticClass: "mark-main" }, [
              _c("div", { staticClass: "mark-title" }, [
                _vm._v(" 选择人员 "),
                _c(
                  "span",
                  {
                    staticClass: "close-btn",
                    on: { click: _vm.closeManagerBox }
                  },
                  [_vm._v("x")]
                )
              ]),
              _c(
                "div",
                { staticClass: "mark-item item-flex item-padding" },
                [
                  _c("span", [_vm._v("姓名")]),
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.markInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.markInfo, "name", $$v)
                      },
                      expression: "markInfo.name"
                    }
                  }),
                  _c("span", [_vm._v("角色")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: "请选择",
                        size: "mini"
                      },
                      model: {
                        value: _vm.markInfo.roleId,
                        callback: function($$v) {
                          _vm.$set(_vm.markInfo, "roleId", $$v)
                        },
                        expression: "markInfo.roleId"
                      }
                    },
                    _vm._l(_vm.roleOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.getManagerList }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mark-item padding-1px" },
                [
                  _c(
                    "div",
                    { staticClass: "table" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          attrs: {
                            data: _vm.tableData,
                            "element-loading-text": _vm.loadingTxt,
                            size: "mini",
                            "header-cell-style": {
                              textAlign: "center",
                              background: "#FAFAFA"
                            },
                            "cell-style": { textAlign: "center" },
                            "max-height": "396px"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              index: index => index + 1,
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "姓名",
                              prop: "name",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "手机号",
                              prop: "phone",
                              "min-width": "100px",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "角色",
                              prop: "roleName",
                              "min-width": "100px",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "所属区域", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.areaName
                                            ? scope.row.areaName
                                            : "-"
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3877267605
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm.isSelected(scope.row.personId)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "disabled-span",
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancleItem(
                                                    scope.row.personId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("取消选择")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "blue-span",
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectedManager(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("选择")]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              568825177
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "comfirm-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: { click: _vm.comfirmFn }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      total: _vm.paginationParam.total,
                      page: _vm.paginationParam.pageIndex,
                      limit: _vm.paginationParam.pageSize
                    },
                    on: {
                      "update:page": function($event) {
                        return _vm.$set(
                          _vm.paginationParam,
                          "pageIndex",
                          $event
                        )
                      },
                      "update:limit": function($event) {
                        return _vm.$set(_vm.paginationParam, "pageSize", $event)
                      },
                      pagination: _vm.getManagerList
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }