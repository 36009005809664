var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "result" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c(
          "el-form",
          [
            _c("el-form-item", { attrs: { label: "企业信用等级：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.level))
            ]),
            _c("el-form-item", { attrs: { label: "偿债能力指标：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.creditGroup0))
            ]),
            _c("el-form-item", { attrs: { label: "获利能力指标：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.creditGroup1))
            ]),
            _c("el-form-item", { attrs: { label: "经营管理指标：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.creditGroup2))
            ]),
            _c("el-form-item", { attrs: { label: "履约指标：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.creditGroup3))
            ]),
            _c("el-form-item", { attrs: { label: "发展能力和潜力指标：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.creditGroup4))
            ]),
            _c(
              "el-form-item",
              { staticClass: "remark", attrs: { label: "评级说明：" } },
              [_c("div", [_vm._v(_vm._s(_vm.ratingRes.remark))])]
            ),
            _c("el-form-item", { attrs: { label: "评级结果有效期：" } }, [
              _vm._v(_vm._s(_vm.ratingRes.validityDate))
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("评级结果")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }