
import { Component, Vue } from 'vue-property-decorator'
import { getBankList, addProduct, queryDetail, getLoanItems, updateProduct } from '@/api/loans'
import { conditionOptions, yesOrNoOptions } from '@/views/project-management/options'
import { Loading } from 'element-ui'
// import { searchArea } from '@/api/noticeManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class ProductsDetail extends Vue {
  private loadingTxt: string = loadingText
  private mainLoading: any = false
  // @Prop({ default: false, type: Boolean }) private disabled!: boolean
  // @Prop({ default: '', type: String }) private flag!: string
  // @Prop({
  //   default: () => {
  //     ;[]
  //   },
  //   type: Object || Array
  // })
  // private areaDatas: any = []
  private taDetail!: Object
  // private tradeCenterOptions: any = []
  //----------------------------- data部分------------------------------------
  private disableds: boolean = false
  private formInfo: any = {
    // area: '',
    // areaConfigId: '',
    // propertyTradingCenter: '',
    productName: '',
    maxAmount: '',
    bankId: '',
    bankName: '',
    loanTermsEntities: {}
  }
  private conditionInfo: any = {
    id: '',
    itemId: '',
    item: '',
    condition: '',
    value: '',
    isNecessary: ''
  }

  private rules = {
    // bankName: { required: true, message: '请输入银行名称', trigger: 'blur' },
    // propertyTradingCenter: { required: true, message: '请输入产权交易中心', trigger: 'blur' },
    productName: { required: true, message: '请输入产品名称', trigger: 'blur' },
    maxAmount: { required: true, message: '请输入最高额度', trigger: 'blur' }
  }

  private conditionData: any = []

  private conditionOptions = conditionOptions
  private yesOrNoOptions = yesOrNoOptions
  private projectsOptions: any = []
  private showAdd: boolean = false
  private deletedProjects: any = []
  private id: any = ''

  created() {
    this.mainLoading = true
    // this.tradeCenterOptions = this.$route.params.tradeCenterOptions
    let disabledStr = this.$route.params.disabled
    if (disabledStr == 'false') {
      this.disableds = false
    } else if (disabledStr == 'true') {
      this.disableds = true
    }
    if (this.$route.params.flag == '1') {
      //查看
      let getData: any
      if (this.$route.params.dataDetail) {
        getData = this.$route.params.dataDetail
        sessionStorage.setItem('LoanProductsDetail', JSON.stringify(getData))
      } else {
        let nowGet: any = sessionStorage.getItem('LoanProductsDetail')
        getData = JSON.parse(nowGet)
      }
      this.showAdd = true
      this.id = getData.id
      getLoanItems({})
        .then((res) => {
          let projectArr: any = res.data.data
          let arr: any = []
          for (let i = 0; i < projectArr.length; i++) {
            let projectInfo: any = projectArr[i]
            let newProjecInfo: any = {}
            newProjecInfo['label'] = projectInfo.colName
            newProjecInfo['value'] = projectInfo.id
            arr.push(newProjecInfo)
          }
          this.projectsOptions = arr
          this.querDetailData(getData.id)
        })
        .catch(() => {
          this.mainLoading = false
        })
    } else {
      // searchArea({ isDelete: false, isDisable: false }).then((res) => {
      //   if (res.data && res.data.code == 1000) {
      //     this.areaDatas = res.data.data.aeras
      //   }
      // })
      // 添加
      this.showAdd = true
      this.initOptionsData()
    }
  }

  async initOptionsData() {
    await getBankList().then((res) => {
      if (this.$route.params.flag == '0' && res.data.data && res.data.data.company) {
        let formInfo: any = {}
        formInfo.bankId = res.data.data.company.id
        formInfo.bankName = res.data.data.company.name
        this.formInfo = formInfo
      }
    })

    getLoanItems({})
      .then((res) => {
        let projectArr: any = res.data.data
        let arr: any = []
        for (let i = 0; i < projectArr.length; i++) {
          let projectInfo: any = projectArr[i]
          let newProjecInfo: any = {}
          newProjecInfo['label'] = projectInfo.colName
          newProjecInfo['value'] = projectInfo.id
          arr.push(newProjecInfo)
        }
        this.projectsOptions = arr
      })
      .finally(() => {
        this.mainLoading = false
      })
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    if (to.params.flag === '0') {
      to.meta.title = '添加贷款产品'
    } else {
      to.meta.title = '查看详情'
    }
    next()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('LoanProductsDetail')
    next()
  }
  querDetailData(id: string) {
    const params = {
      id: id
    }
    queryDetail(params)
      .then((res) => {
        if (res.data.message === 'success') {
          let data = res.data.data
          let formInfo: any = {}
          formInfo.bankName = data.bankName
          formInfo.productName = data.productName
          formInfo.maxAmount = data.maxAmount == 'null' ? '' : data.maxAmount
          // for (let i = 0; i < this.tradeCenterOptions.length; i++) {
          //   let item = this.tradeCenterOptions[i]
          //   if (data.propertyTradingCenter == item.value) {
          //     formInfo.propertyTradingCenter = item.label
          //   }
          // }
          this.formInfo = formInfo
          this.showCodition(data.loanTermsEntities)
        }
      })
      .finally(() => {
        this.mainLoading = false
      })
  }

  showCodition(arr: any) {
    let conditonarr: any = []
    for (let i = 0; i < arr.length; i++) {
      let info = arr[i]
      let newInfo: any = {}
      newInfo['itemId'] = info.itemId
      newInfo['item'] = info.item
      newInfo['condition'] = info.condition
      newInfo['value'] = info.value
      newInfo['isNecessary'] = info.isNecessary
      conditonarr.push(this.transformConditionInfo(newInfo, true))
    }
    this.conditionData = conditonarr
  }

  addCondition() {
    // 添加条件
    if (this.checkNotNull(this.conditionInfo)) {
      let _conditionInfo: any = {}
      _conditionInfo = this.transformConditionInfo(this.conditionInfo, false)
      this.conditionData.push(_conditionInfo)
    }
  }

  checkNotNull(info: any): boolean {
    //检查录入条件情况
    if (!info['item']) {
      alert('请选择项目')
      return false
    } else if (!info['condition']) {
      alert('请选择条件')
      return false
    } else if (!info['value']) {
      alert('请输入值')
      return false
    } else if (!info['isNecessary']) {
      alert('请选择是否')
      return false
    } else {
      let currentData = this.conditionData
      if (currentData && currentData.length > 0) {
        for (let i = 0; i < currentData.length; i++) {
          if (info.item == currentData[i].itemId) {
            alert('项目已添加过')
            return false
          }
        }
      }
    }
    return true
  }

  deleteCondition(datas: any) {
    // 删除条件
    let _conditionData = this.conditionData,
      itemId: any = datas.itemId,
      deleteVal: any
    for (let i = 0; i < _conditionData.length; i++) {
      let info: any = _conditionData[i]
      if (info.itemId == itemId) {
        for (let l = 0; l < this.deletedProjects.length; l++) {
          if (this.deletedProjects[i].value == itemId) {
            this.projectsOptions.push(this.deletedProjects[i])
            this.deletedProjects.splice(i, 1)
            break
          }
        }
        _conditionData.splice(i, 1)
        break
      }
    }
  }

  /**
   * flag -- 查看true; 添加false
   */
  transformConditionInfo(info: any, flag: boolean): any {
    let newInfo: any = {},
      addProjectsInd: number = -1
    for (let k in info) {
      let val = info[k]
      let itemId = info['itemId']
      if (k == 'item') {
        // 项目
        if (flag) {
          for (let i = 0; i < this.projectsOptions.length; i++) {
            let item = this.projectsOptions[i]
            if (item.label == val) {
              newInfo[k] = item.label
              newInfo['itemId'] = itemId
              addProjectsInd = i
              break
            }
          }
        } else {
          for (let i = 0; i < this.projectsOptions.length; i++) {
            let item = this.projectsOptions[i]
            if (item.value == val) {
              //添加时item实际是itemId
              newInfo[k] = item.label
              newInfo['itemId'] = val
              addProjectsInd = i
              break
            }
          }
        }
        if (addProjectsInd > -1) {
          this.conditionInfo.item = ''
          this.deletedProjects.push(this.projectsOptions[addProjectsInd])
          this.projectsOptions.splice(addProjectsInd, 1)
        }
      } else if (k == 'condition') {
        this.conditionOptions.forEach((item, index) => {
          if (item.value == val) {
            newInfo[k] = item.label
          }
        })
      } else if (k == 'isNecessary') {
        this.yesOrNoOptions.forEach((item, index) => {
          if (item.value == val) {
            newInfo[k] = item.label
          }
        })
      } else if (k == 'value') {
        newInfo[k] = val
      }
    }
    return newInfo
  }

  restoreConditionInfo(info: any): any {
    let newInfo: any = {},
      allOptions: any = this.projectsOptions.concat(this.deletedProjects)
    for (let k in info) {
      let val = info[k]
      if (k == 'item') {
        for (let i = 0; i < allOptions.length; i++) {
          let item = allOptions[i]
          if (item.label == val) {
            newInfo['item'] = item.label
            newInfo['itemId'] = item.value
          }
        }
      } else if (k == 'condition') {
        this.conditionOptions.forEach((item, index) => {
          if (item.label == val) {
            newInfo[k] = item.value
          }
        })
      } else if (k == 'isNecessary') {
        this.yesOrNoOptions.forEach((item, index) => {
          if (item.label == val) {
            newInfo[k] = item.value
          }
        })
      } else if (k == 'value') {
        newInfo[k] = val
      } else if (k == 'id') {
        newInfo[k] = val
      }
    }
    return newInfo
  }

  handleSubmit(data: any) {
    if (this.formInfo.maxAmount && this.formInfo.maxAmount.trim() && isNaN(this.formInfo.maxAmount)) {
      this.$message.error('最高额度为数值')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    let datas: any = this.formInfo
    let currentConditionData = this.conditionData
    let loanTermsEntities: any = []
    for (let i = 0; i < currentConditionData.length; i++) {
      let info = currentConditionData[i]
      info = this.restoreConditionInfo(info)
      delete info.id
      loanTermsEntities.push(info)
    }
    datas.loanTermsEntities = loanTermsEntities
    if (this.$route.params.flag == '1') {
      datas.id = this.id
      updateProduct(datas)
        .then((res) => {
          this.$message.success('修改成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    } else {
      // delete datas.area
      addProduct(datas)
        .then((res) => {
          if (res.data.message === 'success') {
            this.$message({
              message: '成功提交',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 300)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
    }
  }

  handleReturn() {
    this.$router.go(-1)
  }
}
