var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "project-info" },
    [
      _c("div", { staticClass: "part first" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "span" }, [_vm._v("投资类会员")]),
          _c(
            "div",
            { staticClass: "title-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = true
                    }
                  }
                },
                [_vm._v("注销投资类会员")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", [_vm._v("风险评估得分：")]),
              _c("span", { staticStyle: { "padding-right": "100px" } }, [
                _vm._v("84")
              ]),
              _c(
                "el-link",
                { attrs: { href: "", target: "_blank", underline: false } },
                [_vm._v(" 查看详情 ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("风险承受能力类型：")]),
            _c("span", [_vm._v(_vm._s(`baseInfo.status`))])
          ]),
          _c("div", { staticClass: "item" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "right" },
              _vm._l(_vm.baseInfo, function(item, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "flex" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          href: item.fileAddr,
                          target: "_blank",
                          underline: false
                        }
                      },
                      [_vm._v(" " + _vm._s(item.fileName) + " ")]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "part first" }, [
        _vm._m(1),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item-title" }, [_vm._v("申请信息")]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("姓名：")]),
            _c("span", { staticClass: "el-link", on: { click: _vm.goBack } }, [
              _vm._v(_vm._s(_vm.baseInfo.status))
            ])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("通讯地址：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("手机：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("证件类型：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("证件号：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("邮箱地址：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("个人简介：")]),
            _c("span", [_vm._v(_vm._s(_vm.baseInfo.status))])
          ]),
          _c("div", [
            _c("div", { staticClass: "item-title" }, [_vm._v("会员合同")]),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("会员合同：")]),
                _c(
                  "el-link",
                  { attrs: { href: "#", target: "_blank", underline: false } },
                  [_vm._v("会员合同")]
                )
              ],
              1
            )
          ]),
          _vm._m(2)
        ])
      ]),
      _c("dia", { attrs: { centerDialogVisible: _vm.centerDialogVisible } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("相关文件：")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "span" }, [_vm._v("非投资类会员")]),
      _c("div", { staticClass: "title-right" }, [
        _c("div", { staticClass: "titles" }, [
          _c("span", [_vm._v("已注销")]),
          _c("span", [_vm._v("情况说明XXXXXXXxxxxx")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "item-title" }, [_vm._v("审批信息")]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("审批时间：")]),
        _c("span", { staticStyle: { "padding-right": "100px" } }, [
          _vm._v("2020-03-26 18:28:10")
        ]),
        _c("span", [_vm._v("同意")])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("审批意见：")]),
        _c("span", [_vm._v("审批意见")])
      ]),
      _c("div", { staticClass: "item" }, [
        _c("span", [_vm._v("缴费状态：")]),
        _c("span", [_vm._v("已缴费")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }