
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { marginManagementList, getAreaPowerList, getBusinessTypeList } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class BondManagementList extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private businessTypeOptions: any = []
  private areaOptions: any = []
  private statusOptions: any = [
    { label: '竞价中', value: 1 },
    { label: '待退款', value: 2 },
    { label: '退款成功', value: 3 },
    // { label: '退款审批中', value: 2 },
    // { label: '已驳回', value: 3 },
    // { label: '退款中', value: 4 },
    // { label: '退款失败', value: 6 }
  ]
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    businessType: '',
    listingApplyName: '',
    marginMoneyLower: '',
    marginMoneyUpper: '',
    areaConfigId: '',
    marginStatus: ''
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'BondManagementDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaConfigId && (vim.formInfo.areaConfigId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaConfigId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      if (isGetList && !vim.businessTypeOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessTypeOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'BondManagementDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        businessTypeOptions: this.businessTypeOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private getBusinessTypeDesc(type: any) {
    let returnDesc: string = ''
    if (type) {
      for (let i = 0; i < this.businessTypeOptions.length; i++) {
        if (type == this.businessTypeOptions[i].businessType) {
          returnDesc = this.businessTypeOptions[i].businessTypeDesc
          break
        }
      }
    }
    return returnDesc
  }
  private getStatusDesc(status: any) {
    let returnDesc: string = ''
    if (status || status === 0) {
      for (let i = 0; i < this.statusOptions.length; i++) {
        if (status == this.statusOptions[i].value) {
          returnDesc = this.statusOptions[i].label
          break
        }
      }
    }
    return returnDesc
  }
  private handleSearch() {
    let marginMoneyLower = this.formInfo.marginMoneyLower.trim(),
      marginMoneyUpper = this.formInfo.marginMoneyUpper.trim()
    if (marginMoneyLower && marginMoneyUpper) {
      if (isNaN(marginMoneyLower) || isNaN(marginMoneyUpper)) {
        this.$message.error('保证金请输入整数')
        return
      }
      let marginMoneyLowerNum = Math.round(Number(marginMoneyLower)),
        marginMoneyUpperNum = Math.round(Number(marginMoneyUpper))
      if (marginMoneyLowerNum > marginMoneyUpperNum) {
        this.$message.error('保证金请按从小到大范围输入')
        return
      }
      this.$set(this.formInfo, 'marginMoneyLower', marginMoneyLowerNum + '')
      this.$set(this.formInfo, 'marginMoneyUpper', marginMoneyUpperNum + '')
    } else {
      this.$set(this.formInfo, 'marginMoneyLower', '')
      this.$set(this.formInfo, 'marginMoneyUpper', '')
    }
    this.getList()
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    marginManagementList(params).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'BondManagementDetail',
      params: {
        datas: JSON.stringify(row)
      }
    })
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaConfigId' || (key == 'areaConfigId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
}
