
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import uploadBase from '@/components/UploadBase64/index.vue'
import ProjectInfo from '@/views/countryside-property-transaction/components/project-info.vue'
const VueUeditorWrap = require('vue-ueditor-wrap')
@Component({
  components: {
    ProjectInfo,
    VueUeditorWrap,
    uploadBase
  }
})
export default class ApprovalInfo extends Vue {
  @Prop() info: any
  @Prop({ default: true }) showTitle: any
  @Prop({ default: true }) showTimeline: any //是否展示时间线审批记录
  @Prop({ default: false }) isRemarkShow?: boolean
  @Prop({
    default: () => []
  })
  bankCardOptions?: any
  @Prop({ default: false }) selectPay?: boolean
  @Prop({
    default: null
  })
  oldRecord?: any
  @Prop({
    default: true
  })
  oldType?: boolean

@Prop({}) processStatus:number
  // 资格参与通知书-START
  @Prop({
    default: () => {
      return []
    }
  })
  fileList?: any
  @Prop({
    default: false
  })
  qualificationsShow?: boolean
  @Prop({
    default: false
  })
  transactionAmountShow?: boolean
  @Prop({
    default: false
  })
  qualificationsEdit?: boolean
  @Prop({
    default: false
  })
  projectDetailShow?: boolean
  private loading: boolean = false
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg,.pdf,.doc,.docx',
    flag: '1'
  }
  // 资格参与通知书-END

  private myConfig: any = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 100,
    // 初始容器宽度
    initialFrameWidth: '100%',
    pasteplain: true,
    filterTxtRules: function () {
      function delDom(node: any) {
        // 如果是图片、超链接 直接删除
        node.parentNode.removeChild(node, node.innerText())
      }
      return {
        img: delDom
      }
    },
    enableAutoSave: false,
    iframeCssUrl: './static/tableStyle.css',
    catchRemoteImageEnable: false, //设置是否抓取远程图片
    elementPathEnabled: false, //是否启用元素路径
    wordCount: false, //是否开启字数统计
    serverUrl: `${process.env.VUE_APP_BASE_URL}file/upload`,
    toolbars: [['bold', 'indent', 'italic', 'underline', 'strikethrough', 'fontsize', 'forecolor', 'backcolor', 'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols']]
  }
  private isFirst: boolean = true
  private isOpenBidNotice: boolean = false
  private icon: object = ['', 'dael-icon-checknger', 'el-icon-close']
  private iconType: object = ['primary', 'success', 'danger']
  private remark: string = ''
  private transactionAmount: string = ''
  private transactionPrice: string = ''
  private remarks: string = ''
  private bankCard: string = ''
  private costItem: string = ''
  private projectId: string = ''
  private projectName: String = ''
  private projectShow: boolean = false

  // 获取项目id
  private getProjectId(projectId: string) {
    this.projectId = projectId.trim()
    this.projectShow = false
    this.changeProjectId()
  }

  // 获取项目标题
  private getProjectName(projectName: string) {
    this.projectName = projectName.trim()
  }

  private projectEvt() {
    this.projectShow = true
  }

  // 资格参与通知书-START
  private activeUpload() {
    if (this.loading) {
      return
    }
    ;(document as any).querySelector('#appinfo-files-btn input').click()
  }
  private deleteFile(ind: number) {
    if (this.loading) {
      this.$message.error('上传文件中请不要操作文件')
      return
    }
    this.fileList.splice(ind, 1)
    this.$forceUpdate()
  }
  private beforeUpFn() {
    this.loading = true
  }
  private getFileUrl(v: any) {
    this.uploadOption.limit += 1
    if (v) {
      this.fileList.push({
        fileUrl: v.url,
        fileName: v.name
      })
      this.$message.success('上传成功')
    } else {
      this.$message.error('上传失败')
    }
    this.loading = false
  }
  private uploadError() {
    this.loading = false
  }
  // 资格参与通知书-END

  // 选择银行卡
  private selecttedbankCard(value: any) {
    this.costItem = ''
    this.$emit('cardSelected', value, '')
  }
  // 选择费项
  private selecttedResult(value: any) {
    // 返回选择结果
    this.$emit('cardSelected', this.bankCard, value)
  }
  // 获取银行卡拼接名
  private getBankCardIdTail(stratVal: any, cardId: any) {
    let returnVal: string = ''
    if (stratVal) {
      returnVal = stratVal
      if (cardId) {
        let cardIdArr: any = cardId.split('')
        returnVal += '（' + (cardIdArr.length >= 4 ? cardId.slice(cardIdArr.length - 4) : cardId) + '）'
      } else {
        returnVal += '()'
      }
    }
    return returnVal
  }
  // 获取对应银行卡费项
  private getFeedItems(ind: any) {
    let returnArr: any = []
    this.bankCardOptions[ind] && this.bankCardOptions[ind].bussinessRels && (returnArr = this.bankCardOptions[ind].bussinessRels)
    return returnArr
  }
  created() {

    if (process.env.VUE_APP_TITLE == 'exercise') {
      this.myConfig.UEDITOR_HOME_URL = (process.env.VUE_APP_SYSTEM_TYPE == 'zct' ? '/zct' : '/ruralBg') + '/static/UEditor/'
    }
  }
  @Emit('transactionAmount')
  changeTransactionAmount() {
    return this.transactionAmount
  }
  @Emit('transactionPrice')
  changeTransactionPrice() {
    return this.transactionPrice
  }
  @Emit('remark')
  changeremark() {
    return this.remark
  }
  @Emit('remarks')
  changeRemarks() {
    return this.remarks
  }
  @Emit('projectId')
  changeProjectId() {
    return this.projectId
  }
  @Watch('info.bidNotice', { immediate: true })
  private watchInfo(newValue: any) {
    if (this.isFirst && newValue) {
      this.isFirst = false
      if (newValue.bidNoticDetail && newValue.bidNoticDetail.length && ((!this.isRemarkShow && [1, 7].indexOf(this.info.status) == -1) || (this.isRemarkShow && this.info.ruleType == '7'))) {
        this.isOpenBidNotice = true
      }
    }
  }
  @Watch('oldRecord')
  private watchOldRecord(newValue: any) {
    if (newValue) {
      this.remark = newValue.remark
      this.remarks = newValue.remarks
      this.bankCard = newValue.bankCard
      this.costItem = newValue.costItem
    }
  }
}
