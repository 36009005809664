
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { creditRatingDrop, getAssessedPersonList, creditInfoShow } from '@/api/creditRatingManagement'
import { getProvinceList, getCityList, getCountyList, getTownshipList } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class FarmerCreditList extends Vue {
  private loadingTxt: string = loadingText
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private isCreditOptions: any = [
    { label: '是', value: '1' },
    { label: '否', value: '0' }
  ]
  private creditRatingOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    // personName: '',
    name: '',
    assessorName: '',
    isCredit: '',
    creditRatingId: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'FarmerCreditDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.provinceOptions.length) {
        getProvinceList().then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      if (!vim.creditRatingOptions.length) {
        creditRatingDrop({}).then((res) => {
          if (res.data.data && res.data.data.ratings) {
            vim.creditRatingOptions = res.data.data.ratings
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'FarmerCreditDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions,
        creditRatingOptions: this.creditRatingOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    getAssessedPersonList(params).then((res) => {
      if (res.data && res.data.code == 1000) {
        this.tableData = res.data.data
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }
  private selecttedProvince(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private changeFn(row: any) {
    let contentText = '是否确认允许用户查看信用评定结果？'
    if (!row.isShow) {
      contentText = '是否确认不允许用户查看信用评定结果？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        creditInfoShow({
          id: row.id,
          isShow: row.isShow ? 1 : 0
        })
          .then((res) => {
            if (!(res.data && res.data.code == 1000)) {
              row.isShow = !row.isShow
            }
          })
          .catch(() => {
            row.isShow = !row.isShow
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {
        row.isShow = !row.isShow
      })
  }
  // 搜索农户信用列表
  private handleSearch() {
    this.getList()
  }
  // 查看农户信用详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'FarmerCreditDetails',
      params: {
        id: row.id
      }
    })
  }
}
