
import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
// 接口联调
import timeFormat from '@/utils/timeFormat'
import upLoadFileSign from '@/components/upLoadFileSign/index.vue'
import SignatureUpload from '@/components/SignatureUpload/index.vue'
import QrCode from '@/components/QrCode.vue'
import {
  saveSignatureConfig,
  // quaryCompanyList,
  // quaryCompanyInfo,
  quarySignatureConfig
} from '@/api/config'
import { uploadSignature } from '@/api/common.api'
// import { isNeedFace } from '@/api/common.api'

@Component({
  name: 'Layout',
  components: {
    upLoadFileSign,
    SignatureUpload,
    QrCode
  }
})
export default class ProjectNotice extends Vue {
  private rules = {
    companyName: { required: true, message: '请输入公司名称', trigger: 'blur' },
    address: { required: true, message: '请输入公司地址', trigger: 'blur' },
    legalPerson: { required: true, message: '请输入法定代表人', trigger: 'blur' },
    contactPerson: { required: true, message: '请输入联系人', trigger: 'blur' },
    mobile: { required: true, message: '请输入电话', trigger: 'blur' },
    email: { required: true, message: '请输入电子邮箱', trigger: 'blur' },

    accountName: { required: true, message: '请输入银行户名', trigger: 'blur' },
    openBank: { required: true, message: '请输入开户银行', trigger: 'blur' },
    bankAccount: { required: true, message: '请输入银行账号', trigger: 'blur' },

    // companySignUrl: { required: true, message: '请上传公司印章', trigger: 'blur' },
    // legalPersonSignUrl: { required: true, message: '请上传法人印章', trigger: 'blur' },
    businessLicense: { required: true, message: '请上传营业执照', trigger: 'blur' }
  }
  private listShow: boolean = false
  private formInfo = {
    companyId: '',
    companyName: '',
    address: '',
    legalPerson: '',
    contactPerson: '',
    mobile: '',
    email: '',
    businessLicense: '',

    accountName: '',
    openBank: '',
    bankAccount: '',

    companySignUrl: '',
    legalPersonSignUrl: ''
  }
  // private businessLicenseUrl = ''

  private legalPersonSignUrl = ''
  private companySignUrl = ''

  // private legalSign = ''
  private uploadBusinessData = ''
  private uploadComData = ''
  private uploadlegalData = ''

  private businessSignFlag = false
  private companySignFlag = false
  private legalSignFlag = false

  private loading = false
  private qrDiaShow: boolean = false
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg',
    // listType:'picture',
    // tip: '请上传pdf格式文件',
    uploadUrl: '',
    flag: '1'
  }
  created() {
    this.quarySignatureConfig()
  }
  //查询签章信息，如不存在则查询企业列表信息；存在则查询公司印章
  quarySignatureConfig() {
    quarySignatureConfig({}).then((res) => {
      let data = res.data
      if (data.code == 1000 && data.data) {
        this.formInfo = data.data
        // this.formInfo.companyId = String(this.formInfo.companyId)
        // this.querySignature(this.formInfo.companyId)
        this.uploadOption.uploadUrl = data.data.businessLicense
        this.legalPersonSignUrl = data.data.legalSignUrl
        this.companySignUrl = data.data.companySignUrl
      }
      // else {
      // this.quaryCompanyList()
      // }
    })
  }
  private getFileUrlUrl(v: any) {
    this.formInfo.businessLicense = v.url
  }

  //查询企业列表获取userid=companyid
  // async quaryCompanyList() {
  //   const param = { param: '捷算' }
  //   await quaryCompanyList(param)
  //     .then((res) => {
  //       if (res.data.data.length > 0) {
  //         var item = res.data.data[0]
  //         this.formInfo.companyId = item.companyId
  //         this.queryCompanyInfo(this.formInfo.companyId)
  //         this.querySignature(this.formInfo.companyId)
  //       }
  //     })
  //     .catch((err) => {})
  // }
  // //查询企业实名信息自动加载出来
  // queryCompanyInfo(companyId: string) {
  //   quaryCompanyInfo({ id: companyId }).then((res) => {
  //     let info = res.data.data.enterprise
  //     this.formInfo.companyName = info.name
  //     this.formInfo.address = info.registAddress
  //     this.formInfo.legalPerson = info.legalPersonName
  //     this.formInfo.contactPerson = info.contactName
  //     this.formInfo.mobile = info.contactPhone
  //     this.formInfo.email = info.contactEmail
  //   })
  // }

  // //查询企业公章和法人章
  // qurerySigns(companyId: string) {
  //   querySignature({ userId: companyId }).then((res) => {
  //     let data = res.data.data
  //     if (data && data.length > 0) {
  //       for (let key in data) {
  //         let item = data[key]
  //         if (item.type == 1) {
  //           this.companySign = item.signatureBase64
  //         } else {
  //           this.legalSign = item.signatureBase64
  //         }
  //       }
  //     }
  //   })
  // }

  //上传营业执照
  // businessOption(data: any) {
  //   this.uploadBusinessData = data
  // }
  //上传公司印章
  comOption(data: any) {
    this.uploadComData = data
  }
  //上传法人印章
  legalOption(data: any) {
    this.uploadlegalData = data
  }

  // 监测是否修改营业执照上传文件
  // imgBaseBusiness(img: any) {
  //   this.businessLicenseUrl = img
  //   this.businessSignFlag = true
  // }

  //监测是否修改公司上传文件
  imgBaseCompany(img: any) {
    this.companySignUrl = img
    this.companySignFlag = true
  }
  //监测是否修改法人上传文件
  imgBaselegal(img: any) {
    this.legalPersonSignUrl = img
    this.legalSignFlag = true
  }
  upload(data: any) {
    return uploadSignature(data)
      .then((res) => {
        if (res.data.code == 1000) {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        return false
      })
  }

  //查询印章
  // querySignature(v: any) {
  //   querySignature({ userId: v }).then((res) => {
  //     let data = res.data
  //     if (data.code == 1000) {
  //       let arr = data.data
  //       for (let i in arr) {
  //         if (arr[i].type == '1') {
  //           this.companySign = 'data:image/jpeg;base64,' + arr[i].signatureBase64
  //         } else {
  //           this.legalSign = 'data:image/jpeg;base64,' + arr[i].signatureBase64
  //         }
  //       }
  //     }
  //   })
  // }

  //保存/新增签章信息
  async submit(formName: string) {
    const anyThing: any = this.$refs[formName]
    // if (!this.companySignUrl || this.companySignUrl.length === 0) {
    //   this.$message('请上传公司印章')
    //   return
    // }
    // if (!this.legalPersonSignUrl || this.legalPersonSignUrl.length === 0) {
    //   this.$message('请上传法人印章')
    //   return
    // }
    if (this.businessSignFlag) {
      if (!(await this.upload(this.uploadBusinessData))) {
        return false
      }
    }
    if (this.companySignFlag) {
      if (!(await this.upload(this.uploadComData))) {
        return false
      }
    }
    if (this.legalSignFlag) {
      if (!(await this.upload(this.uploadlegalData))) {
        return false
      }
    }
    anyThing.validate((valid: any) => {
      if (valid) {
        // isNeedFace({})
        //   .then((res) => {
        //     if (res.data.data && res.data.data.result) {
        //       saveSignatureConfig({
        //         ...this.formInfo,
        //         callbackId: res.data.data.callbackId
        //       }).then((res) => {
        //         let data = res.data
        //         if (data.code == 1000) {
        //           this.$message({
        //             type: 'success',
        //             message: '成功!'
        //           })
        //         }
        //       })
        //     } else {
        //       this.qrDiaShow = true
        //     }
        //   })
        //   .catch(() => {
        this.qrDiaShow = true
        // })
      } else {
        return false
      }
    })
  }
  // handleValid(){
  //   valid({})
  //       .then((res) => {
  //         if (res.data.data.canPass) {
  //           this.qrDiaShow = false
  //           this.notify(res.data.data.canPass)
  //         } else {
  //           this.qrDiaShow = true
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error.data)
  //       })
  // }
  //二维码认证成功
  notify(v: any, callbackId: any) {
    if (v) {
      saveSignatureConfig({
        ...this.formInfo,
        callbackId
      }).then((res) => {
        let data = res.data
        if (data.code == 1000) {
          this.$message({
            type: 'success',
            message: '成功!'
          })
          this.qrDiaShow = false
        }
      })
    }
  }
}
