
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
import { getCompanyList } from '@/api/enterpriseCreditRating'

@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class EnterpriseFinancialRating extends Vue {
  private loadingTxt: string = loadingText
  private formInfo: any = {
    name: ''
  }
  private ratingOptions: any = [
    { value: '', label: '全部' },
    { value: '2', label: '已评级' },
    { value: '1', label: '未评级' }
  ]
  private creditOptions: any = [
    { value: '', label: '全部' },
    // { value: '0', label: '无' },
    { value: 'AAA', label: 'AAA' },
    { value: 'AA', label: 'AA' },
    { value: 'A', label: 'A' },
    { value: 'BBB', label: 'BBB' },
    { value: 'BB', label: 'BB' },
    { value: 'B', label: 'B' },
    { value: 'CCC', label: 'CCC' },
    { value: 'CC', label: 'CC' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' }
  ]
  private resultOptions: any = [
    { value: '', label: '全部' },
    { value: '2', label: '有效' },
    { value: '1', label: '失效' }
  ]
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading: boolean = false

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      if (['FinancialRating', 'RatingList'].indexOf(from.name) > -1 && pageParams) {
        vim.formInfo = pageParams.formInfo
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['FinancialRating', 'RatingList'].indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo
      }
      sessionStore.set('pageParams', pageParams)
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    getCompanyList(params)
      .then((res: any) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleSearch() {
    this.getList()
  }

  private handleCredit(id: any) {
    this.$router.push({
      name: 'FinancialRating',
      params: {
        id: id
      }
    })
  }
  private handleCreditList(row: any) {
    this.$router.push({
      name: 'RatingList',
      params: {
        creditCode: row.creditCode
      }
    })
  }
}
