var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settlement-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("状态")]),
              _c("span", [_vm._v(_vm._s(_vm.info.statusDesc))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("申请时间")]),
              _c("span", [_vm._v(_vm._s(_vm.info.applyTime))])
            ]),
            _vm.info.finishTime
              ? _c("div", { staticClass: "item" }, [
                  _c("span", [_vm._v("完成时间")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.finishTime))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("结算状态")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }