
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { sessionStore } from '@/utils/data-management'
import { loadingText } from '@/utils/defaultGoalConfig'
import { subsidyList, subsidyDel, areaAll, subsidyApplyDel } from '@/api/countrysidePropertyTransaction'
import { getVerifyStatus } from '@/api/login'

@Component({
  components: {
    Pagination,
    numsTips
  }
})
//0 待确认 1 通过，2 不通过，3 待提交审核，4 待审核，5 已发放 6 未提交
export default class SubsidyList extends Vue {
  private loadingTxt: string = loadingText
  private statusOptions: any = [
    // { label: '待申请', value: '0' },
    // { label: '待审核', value: 4 },
    // { label: '不通过', value: 2 },
    // // { label: '待提交审核', value: 3 },
    // { label: '已通过', value: 1 },
    // { label: '发放中', value: 6 },
    // { label: '发放失败', value: 7 },
    // { label: '已发放', value: 5 }
    { label: '待申请', value: '0' },
    { label: '已通过', value: '1' },
    { label: '不通过', value: '2' },
    // { label: '待提交审核', value: '3' },
    { label: '待审核', value: '4' },
    { label: '未发放', value: '10' },
    { label: '发放中（处理中）', value: '5' },
    { label: '发放中（排队中）', value: '9' },
    { label: '发放失败', value: '7' },
    { label: '发放成功', value: '8' },
    { label: '未提交', value: '6' }
  ]
  private statusArea: any = []
  private formInfo: any = {
    realName: '',
    idCardNumber: '',
    proxyRealName: '',
    subsidyExamineStatus: '',
    areaId: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private grant: any = []
  private loading: Boolean = false
  private role: any = ''
  private roleStatus: boolean = true

  created() {
    if (sessionStore.get('grantList')) {
      sessionStore.remove('grantList')
    }
    this.getArea()
    this.verifyStatus()
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStore.get('pageParams')
      let arr = ['SubsidyAddType', 'SubsidyAdd', 'SubsidyImport', 'SubsidyDetail', 'SubsidyGrant', 'SubsidyAduit']
      if (arr.indexOf(from.name) > -1 && pageParams) {
        vim.formInfo = pageParams.formInfo
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    let arr = ['SubsidyAddType', 'SubsidyAdd', 'SubsidyImport', 'SubsidyDetail', 'SubsidyGrant', 'SubsidyAduit']
    if (arr.indexOf(to.name) > -1) {
      let pageParams: any = {
        formInfo: this.formInfo
      }
      sessionStore.set('pageParams', pageParams)
    } else if (sessionStore.get('pageParams')) {
      sessionStore.remove('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private verifyStatus() {
    getVerifyStatus({})
      .then((res) => {
        if (res.data.data) {
          this.role = res.data.data.role
          if (res.data.data.permissions && res.data.data.permissions.indexOf('subsidy-manager') != -1) {
            this.role = 2
          }
        }
      })
      .finally(() => {
        this.roleStatus = false
      })
  }

  private getArea() {
    areaAll({}).then((res) => {
      this.statusArea = res.data.data
    })
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    if (params.subsidyExamineStatus == 5) {
      params.payTaskStatus = 3
    }
    if (params.subsidyExamineStatus == 7) {
      params.subsidyExamineStatus = 5
      params.payTaskStatus = 2
    }
    if (params.subsidyExamineStatus == 8) {
      params.subsidyExamineStatus = 5
      params.payTaskStatus = 1
    }
    if (params.subsidyExamineStatus == 9) {
      params.subsidyExamineStatus = 5
      params.payTaskStatus = 0
    }
    if (params.subsidyExamineStatus == 10) {
      params.subsidyExamineStatus = 5
      params.payTaskStatus = -1
    }
    // Object.keys(params).forEach((key) => {
    //   params[key] &&
    //     (key == 'province' || key == 'city' || key == 'county' || key == 'township') &&
    //     (params[key] = params[key].split(',')[1])
    //   !params[key] && delete params[key]
    // })
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
    })
    // this.tableData = res.data
    subsidyList(params)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 搜索补贴列表
  private handleSearch() {
    this.getList()
  }
  // 补贴状态
  private getStatus(val: number, payTaskStatus: number) {
    switch (val) {
      case 0:
        return '待申请'
        break
      case 1:
        return '已通过'
        break
      case 2:
        return '不通过'
        break
      case 3:
        return '待提交审核'
        break
      case 4:
        return '待审核'
        break
      case 5:
        if (payTaskStatus == 0) {
          return '发放中（排队中）'
        } else if (payTaskStatus == 1) {
          return '发放成功'
        } else if (payTaskStatus == 2) {
          return '发放失败'
        } else if (payTaskStatus == 3) {
          return '发放中（处理中）'
        }
        return '未发放'
        break
      case 6:
        return '未提交'
        break
      default:
        return '待申请'
        break
    }
  }
  private getGrantStatus(val: any) {
    switch (val) {
      case 1:
        return '直接发放'
        break
      case 2:
        return '确认信息发放'
        break
      case 3:
        return '采集信息发放'
        break
      default:
        return '发放'
        break
    }
  }
  // 添加补贴
  private handleAdd() {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    this.$router.push({
      name: 'SubsidyAddType',
      params: { type: 'add', id: 'add' }
    })
    return
    this.$router.push({
      name: 'SubsidyAdd',
      params: { id: 'add' }
    })
  }
  // 导入补贴
  private handleImport() {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    this.$router.push({
      name: 'SubsidyAddType',
      params: { type: 'import' }
    })
    return
    this.$router.push('subsidy-import')
  }
  // 补贴发放
  private handleGrant() {
    if (this.role != '2') {
      return this.$message.error('您暂无该权限')
    }
    if (this.grant.length < 1) {
      return this.$message.error('请选择要补贴发放的农户')
    }
    let list = this.grant.filter((item: any) => item.examineStatus == 1)
    if (list.length < 1) {
      ;(this.$refs.multipleTable as any).clearSelection()
      return this.$message.error('请选择已通过待发放的农户')
    }
    let ids = list.map((item: any) => item.applyId)
    sessionStore.set('grantList', ids)
    this.$router.push({
      name: 'SubsidyGrant'
    })
  }
  // 隐藏
  private checkbox(row: any) {
    if (row.row.examineStatus != 1) {
      return 'grant'
    }
  }
  // 表格选中
  private handleChange(val: any) {
    // ;(this.$refs.multipleTable as any).clearSelection()
    // this.grant = val.filter((item) => item.examineStatus == 1)
    this.grant = val
  }
  // 修改
  private handleEdit(row: any) {
    this.$router.push({
      name: 'SubsidyAdd',
      params: { id: row.applyId, type: '3' }
    })
  }
  // 删除
  private handleDel(row: any) {
    this.$confirm('确认删除？').then(() => {
      // subsidyDel({ subsidyId: row.subsidyId }).then((res) => {
      //   this.$message.success('删除成功')
      //   this.getList()
      // })
      subsidyApplyDel({ applyId: row.applyId }).then((res) => {
        this.$message.success('删除成功')
        this.getList()
      })
    })
  }
  // 查看详情
  private handleDetails(row: any) {
    let name = 'SubsidyDetail'
    if (row.examineStatus == '4') {
      name = 'SubsidyAduit'
    }
    this.$router.push({
      name: name,
      params: { id: row.applyId, index: row.examineStatus, type: row.type }
    })
  }
}
