var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tinymce-editor" },
    [
      _c("editor", {
        attrs: { init: _vm.init, disabled: _vm.disabled },
        on: { onClick: _vm.onClick },
        model: {
          value: _vm.myValue,
          callback: function($$v) {
            _vm.myValue = $$v
          },
          expression: "myValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }