
import { Component, Vue, Prop } from 'vue-property-decorator'
import { bankCardEncryption } from '../components/bankCard'
import { floatAdd, floatMul, floatDiv } from '@/utils/basicOperation'
import { searchPayeeInfo } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {}
})
export default class LiquidationInfo extends Vue {
  @Prop({
    default: false
  })
  openEdit?: boolean
  @Prop({
    default: false
  })
  openStatus?: boolean
  @Prop() info: any // 展示数据
  @Prop() payeeList: any
  @Prop({
    default: () => []
  })
  disableList: any
  private disabled: boolean = false
  private getStatus(status: any) {
    let returnStatus: any = ['待清算', '失败', '成功', '清算中']
    return status || status === 0 ? returnStatus[status] : ''
  }
  private getCellClass(evt: any) {
    if (this.openEdit == false) {
      return 'fixed-span'
    } else {
      if (evt.column.label == '金额（元）') {
        return 'fixed-span'
      } else if (!evt.rowIndex && ['收款方性质', '收款方'].indexOf(evt.column.label) > -1) {
        return 'fixed-span'
      } else {
        return ''
      }
    }
  }
  private bankFn: any = bankCardEncryption // 银行卡加密方法
  private addPerson() {
    this.payeeList.push({
      type: 1,
      payeeNature: '',
      payee: '',
      openBank: '',
      bankAccount: '',
      amountScale: '',
      amount: ''
    })
    this.disableList.push(false)
  }
  private deleteItem(index: any) {
    if (!this.disabled) {
      this.payeeList.splice(index, 1)
      this.disableList.splice(index, 1)
    }
  }
  // 收款方失去焦点事件
  private blurInput(obj: any, ind: number) {
    this.$set(this.disableList, ind, true)
    this.disabled = true
    searchPayeeInfo({
      payee: this.payeeList[ind].payee
    })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          let payee: any = this.payeeList[ind]
          !payee.payeeNature && datas.payeeNature && (payee.payeeNature = datas.payeeNature)
          !payee.openBank && datas.openBank && (payee.openBank = datas.openBank)
          !payee.bankAccount && datas.bankAccount && (payee.bankAccount = datas.bankAccount)
          !payee.amountScale && datas.amountScale && (payee.amountScale = datas.amountScale)
          this.changeInput(datas.amountScale, ind)
        }
      })
      .finally(() => {
        this.$set(this.disableList, ind, false)
        this.disabled = false
      })
  }
  // 限制输入
  private changeInput(value: any, ind: number) {
    this.payeeList[ind].amountScale = value.replace(/[^\d\.]/g, '')
    let amountScale: any = this.payeeList[ind].amountScale
    if (!amountScale) {
      return
    }
    if (this.info.type == 1) {
      this.payeeList[ind].amount = floatMul(floatDiv(amountScale, 100), this.info.liquidationAmount).toFixed(2)
    } else {
      this.payeeList[ind].amount = parseFloat(amountScale).toFixed(2)
    }
  }
  // 切换选择
  private typeChange(value: any) {
    let liquidationPerson: any = this.payeeList,
      liquidationAmount: any = this.info.liquidationAmount
    if (liquidationPerson && liquidationAmount) {
      for (let i = 0; i < liquidationPerson.length; i++) {
        // if (value == 1) {
        //   liquidationPerson[i].amountScale !== null && liquidationPerson[i].amountScale + '' && (liquidationPerson[i].amount = floatMul(floatDiv(liquidationPerson[i].amountScale, 100), liquidationAmount).toFixed(2))
        // } else {
        //   liquidationPerson[i].amountScale !== null && liquidationPerson[i].amountScale + '' && (liquidationPerson[i].amount = parseFloat(liquidationPerson[i].amountScale).toFixed(2))
        // }
        liquidationPerson[i].amountScale = ''
        liquidationPerson[i].amount = ''
      }
    }
  }
}
