var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadings,
          expression: "loadings"
        }
      ],
      staticClass: "contentBox",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "el-button",
            {
              staticClass: "exportBtn global-dotted-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleExportData }
            },
            [_vm._v("导出贷款信息")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabPaneBox" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticStyle: { position: "relative" },
                  attrs: { label: "基本信息", name: "1" }
                },
                [
                  _c("div", { staticClass: "top-title" }, [_vm._v("基本信息")]),
                  _vm.paneDatas.mortgageBasicResponse &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                    .status == "1" &&
                  _vm.paneDatas.mortgageBasicResponse
                    .blacklistManagementEntities.length > 0
                    ? _c("div", { staticClass: "blacklist" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/blacklist.png")
                          }
                        }),
                        _c(
                          "div",
                          _vm._l(
                            _vm.paneDatas.mortgageBasicResponse
                              .blacklistManagementEntities,
                            function(item, index) {
                              return _c("div", { key: index }, [
                                index < 2
                                  ? _c(
                                      "div",
                                      { class: { "first-item": !index } },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              item.businessType == "2"
                                                ? "政府黑名单"
                                                : item.businessType == "1"
                                                ? "银行黑名单"
                                                : ""
                                            )
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(_vm._s(item.reason))
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }
                          ),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm.paneDatas.mortgageBasicResponse &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                    .lenderType == "2"
                    ? _c("div", { staticClass: "partOne" }, [
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("贷款企业")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .companyName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("注册地址")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .companyAddress
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("联系人")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .contactPerson
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("联系地址")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .contactAddress
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("联系电话")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .contactNumber
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("企业办公地点")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse.companyInfo
                            .officeImageUrl
                            ? _c("p", { staticClass: "itemValue item-img" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.paneDatas.mortgageBasicResponse
                                        .companyInfo.officeImageUrl
                                  },
                                  on: { click: _vm.showBig }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("统一社会信用代码")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .unifiedCreditCode
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("成立时间")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatTime")(
                                  _vm.paneDatas.mortgageBasicResponse
                                    .companyInfo.establishTime
                                )
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("主要负责人姓名")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .principalName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("身份证号")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .identityNumber
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("负责人电话")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .principalNumber
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("所属区域")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse
                                  .mortgageApprovalEntity.areaName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("企业主营业务")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .mainBusiness
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("营业时间段")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse.companyInfo
                            .startTime !== null &&
                          _vm.paneDatas.mortgageBasicResponse.companyInfo
                            .startTime !== undefined &&
                          _vm.paneDatas.mortgageBasicResponse.companyInfo
                            .endTime !== null &&
                          _vm.paneDatas.mortgageBasicResponse.companyInfo
                            .endTime !== undefined
                            ? _c("p", { staticClass: "itemValue" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTime")(
                                      _vm.paneDatas.mortgageBasicResponse
                                        .companyInfo.startTime
                                    )
                                  ) +
                                    "至" +
                                    _vm._s(
                                      _vm._f("formatTime")(
                                        _vm.paneDatas.mortgageBasicResponse
                                          .companyInfo.endTime
                                      )
                                    )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("营业收入 (万元)")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .operatingIncome
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("营业利润 (万元)")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .operatingProfit
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("员工人数 (人)")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .staffAmount
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("参保人数 (人)")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .insuredAmount
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("所属行业")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .industry
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("贷款银行")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse
                                  .mortgageApprovalEntity.loanBank
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("申请贷款金额 (万元)")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse.companyInfo
                                  .loanAmount
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("贷款余额明细")
                          ]),
                          _c("p", { staticClass: "itemValue item-adown" }, [
                            _vm.paneDatas.mortgageBasicResponse.companyInfo
                              .loanBalanceUrl &&
                            _vm.paneDatas.mortgageBasicResponse.companyInfo
                              .loanBalanceUrl != "无"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      download: "",
                                      href:
                                        _vm.paneDatas.mortgageBasicResponse
                                          .companyInfo.loanBalanceUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("贷款余额明细表")]
                                )
                              : _c("span", [_vm._v("无")])
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("企业材料")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse &&
                          _vm.paneDatas.mortgageBasicResponse
                            .mortgageApprovalEntity &&
                          _vm.paneDatas.mortgageBasicResponse
                            .mortgageApprovalEntity.mortgageApprovalFileEntities
                            ? _c(
                                "div",
                                { staticClass: "companyData" },
                                _vm._l(
                                  _vm.paneDatas.mortgageBasicResponse
                                    .mortgageApprovalEntity
                                    .mortgageApprovalFileEntities,
                                  function(item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        item.fileTypeType === 1
                                          ? _c(
                                              "el-link",
                                              {
                                                staticClass: "itemCompanyData",
                                                attrs: {
                                                  href: item.fileAddr,
                                                  target: "_blank",
                                                  underline: false
                                                }
                                              },
                                              [_vm._v(_vm._s(item.fileName))]
                                            )
                                          : _vm._e(),
                                        item.fileTypeType === 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "itemCompanyData itemCompanyData2"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "itemValue" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.fileName)
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "itemContents"
                                                  },
                                                  [_vm._v(_vm._s(item.content))]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.paneDatas.mortgageBasicResponse &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                    .lenderType == "1"
                    ? _c("div", { staticClass: "partOne" }, [
                        _c("span", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("贷款人")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse
                                  .mortgageApprovalEntity.lenderName
                              )
                            )
                          ])
                        ]),
                        _c("span", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("电话")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse.zctUserEntity
                            ? _c("p", { staticClass: "itemValue" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.paneDatas.mortgageBasicResponse
                                      .zctUserEntity.phone
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("span", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("身份证号")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse.zctUserEntity
                            ? _c("p", { staticClass: "itemValue" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.paneDatas.mortgageBasicResponse
                                      .zctUserEntity.idCard
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("span", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("贷款银行")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse
                                  .mortgageApprovalEntity.loanBank
                              )
                            )
                          ])
                        ]),
                        _c("span", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("所属区域")
                          ]),
                          _c("p", { staticClass: "itemValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm.paneDatas.mortgageBasicResponse
                                  .mortgageApprovalEntity.areaName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("申请人信息")
                          ]),
                          _c(
                            "div",
                            { staticClass: "partTwo partTwo-width" },
                            _vm._l(
                              _vm.paneDatas.mortgageBasicResponse.customs,
                              function(v, index) {
                                return _c("div", { key: index }, [
                                  !v.isRuralLoan || v.isRuralLoan == 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "tableItemBox",
                                          staticStyle: {
                                            "padding-right": "50px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tableTitle" },
                                            [_vm._v(_vm._s(v.title))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "tablelist" },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticClass: "tables",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    data:
                                                      v.values.length > 0
                                                        ? v.values
                                                        : [],
                                                    "header-cell-style": {
                                                      textAlign: "center",
                                                      background: "#FAFAFA"
                                                    },
                                                    "cell-style": {
                                                      textAlign: "center"
                                                    },
                                                    size: "mini"
                                                  }
                                                },
                                                _vm._l(
                                                  v.titles.length > 0
                                                    ? v.titles
                                                    : [],
                                                  function(item, i) {
                                                    return _c(
                                                      "el-table-column",
                                                      {
                                                        key: i,
                                                        attrs: { label: item },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                scope
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      v.values[
                                                                        scope
                                                                          .$index
                                                                      ][i]
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              }
                            ),
                            0
                          )
                        ]),
                        _c("div", { staticClass: "itemBaseInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("农户贷信息")
                          ]),
                          _c(
                            "div",
                            { staticClass: "partTwo partTwo-width" },
                            _vm._l(
                              _vm.paneDatas.mortgageBasicResponse.customs,
                              function(v, index) {
                                return _c("div", { key: index }, [
                                  v.isRuralLoan == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "tableItemBox",
                                          staticStyle: {
                                            "padding-right": "50px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tableTitle" },
                                            [_vm._v(_vm._s(v.title))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "tablelist" },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticClass: "tables",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    data:
                                                      v.values.length > 0
                                                        ? v.values
                                                        : [],
                                                    "header-cell-style": {
                                                      textAlign: "center",
                                                      background: "#FAFAFA"
                                                    },
                                                    "cell-style": {
                                                      textAlign: "center"
                                                    },
                                                    size: "mini"
                                                  }
                                                },
                                                _vm._l(
                                                  v.titles.length > 0
                                                    ? v.titles
                                                    : [],
                                                  function(item, i) {
                                                    return _c(
                                                      "el-table-column",
                                                      {
                                                        key: i,
                                                        attrs: { label: item },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                scope
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      v.values[
                                                                        scope
                                                                          .$index
                                                                      ][i]
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              }
                            ),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.paneDatas.mortgageBasicResponse &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity &&
                  _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                    .lenderType == "1"
                    ? _c("div", { staticClass: "partTwo" }, [
                        _vm.paneDatas.mortgageBasicResponse
                          .mortgageApprovalEntity.mortgageApprovalFileEntities
                          ? _c("div", { staticClass: "tableItemBox2" }, [
                              _c("span", { staticClass: "itemTitle" }, [
                                _vm._v("申请人相关资料")
                              ]),
                              _c(
                                "div",
                                { staticClass: "itemValue" },
                                _vm._l(
                                  _vm.paneDatas.mortgageBasicResponse
                                    .mortgageApprovalEntity
                                    .mortgageApprovalFileEntities,
                                  function(item) {
                                    return _c(
                                      "el-link",
                                      {
                                        key: item.id,
                                        attrs: {
                                          href: item.fileAddr,
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [_vm._v(_vm._s(item.fileName))]
                                    )
                                  }
                                ),
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              ),
              _c("el-tab-pane", { attrs: { label: "标的信息", name: "2" } }, [
                _c("div", { staticClass: "top-title" }, [_vm._v("标的信息")]),
                _vm.paneDatas.myTargetInfo
                  ? _c("div", { staticClass: "subjectInfos" }, [
                      _c("span", { staticClass: "itemSubjectInfo" }, [
                        _c("p", { staticClass: "itemSubjectTitle" }, [
                          _vm._v("挂牌申请人")
                        ]),
                        _c("p", { staticClass: "itemSubjectValue" }, [
                          _vm._v(_vm._s(_vm.paneDatas.myTargetInfo.applyPerson))
                        ])
                      ]),
                      _c("span", { staticClass: "itemSubjectInfo" }, [
                        _c("p", { staticClass: "itemSubjectTitle" }, [
                          _vm._v("联系电话")
                        ]),
                        _c("p", { staticClass: "itemSubjectValue" }, [
                          _vm._v(
                            _vm._s(_vm.paneDatas.myTargetInfo.contactNumber)
                          )
                        ])
                      ]),
                      _c("span", { staticClass: "itemSubjectInfo" }, [
                        _c("p", { staticClass: "itemSubjectTitle" }, [
                          _vm._v("标的名称")
                        ]),
                        _c("p", { staticClass: "itemSubjectValue" }, [
                          _vm._v(_vm._s(_vm.paneDatas.myTargetInfo.name))
                        ])
                      ]),
                      _vm.paneDatas.myTargetInfo.usePurpose
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("土地用途")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.myTargetInfo.usePurpose)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.owner
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("所有权人")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(_vm._s(_vm.paneDatas.myTargetInfo.owner))
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.isOtherRightsHolder ||
                      _vm.paneDatas.myTargetInfo.isOtherRightsHolder === 0
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("是否存在他项权利人")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo
                                    .isOtherRightsHolder === 1
                                    ? "是"
                                    : "否"
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.isOtherRightsHolder == 1
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("他项权利人")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo.otherRightsHolder
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.isOtherRightsHolder == 1
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("他项权利内容")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo.otherRightsContent
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.isGiveUpAdvanceRight ||
                      _vm.paneDatas.myTargetInfo.isGiveUpAdvanceRight === 0
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("相关人员是否放弃优先权")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo
                                    .isGiveUpAdvanceRight === 1
                                    ? "是"
                                    : "否"
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.location
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("所在地区")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.myTargetInfo.location)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.contractingMode
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("承包方式")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo.contractingMode
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.holdArea
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("持有面积 (亩)")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.myTargetInfo.holdArea)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "itemSubjectInfo" }, [
                        _c("p", { staticClass: "itemSubjectTitle" }, [
                          _vm._v("交易方式")
                        ]),
                        _c("p", { staticClass: "itemSubjectValue" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterTranferType")(
                                _vm.paneDatas.myTargetInfo.tranferType
                              )
                            )
                          )
                        ])
                      ]),
                      _vm.paneDatas.myTargetInfo.tranferType === 2
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("标的价格 (元)")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(_vm._s(_vm.paneDatas.myTargetInfo.amount))
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.tranferType !== 2
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("起拍价格 (元)")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(_vm._s(_vm.paneDatas.myTargetInfo.amount))
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.tranferEnsureAmount ||
                      _vm.paneDatas.myTargetInfo.tranferEnsureAmount === 0
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("交易保证金 (元)")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo.tranferEnsureAmount
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.myTargetInfo.auctionInterval ||
                      _vm.paneDatas.myTargetInfo.auctionInterval === 0
                        ? _c("span", { staticClass: "itemSubjectInfo" }, [
                            _c("p", { staticClass: "itemSubjectTitle" }, [
                              _vm._v("竞价阶梯 (元/次)")
                            ]),
                            _c("p", { staticClass: "itemSubjectValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.myTargetInfo.auctionInterval
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c(
                "el-tab-pane",
                { attrs: { label: "标的相关资料", name: "3" } },
                [
                  _c("div", { staticClass: "top-title" }, [
                    _vm._v("标的相关资料")
                  ]),
                  _c(
                    "div",
                    { staticClass: "relatedData" },
                    _vm._l(_vm.paneDatas.files, function(item, index) {
                      return _c("div", { key: index }, [
                        item.fileUrl && item.fileUrl.length
                          ? _c(
                              "div",
                              { staticClass: "itemRelatedData" },
                              _vm._l(item.fileUrl, function(v, indx) {
                                return _c(
                                  "el-link",
                                  {
                                    key: indx,
                                    attrs: {
                                      href: v,
                                      target: "_blank",
                                      underline: false
                                    }
                                  },
                                  [_vm._v(_vm._s(item.fileName))]
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ]
              ),
              _c("el-tab-pane", { attrs: { label: "交易信息", name: "4" } }, [
                _c("div", { staticClass: "top-title" }, [_vm._v("交易信息")]),
                _vm.paneDatas.tradeInfo
                  ? _c("div", { staticClass: "tradeInfoBox" }, [
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("摘牌人性质")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(
                            _vm._s(
                              _vm.paneDatas.tradeInfo.userType == 1
                                ? "个人"
                                : _vm.paneDatas.tradeInfo.userType == 2
                                ? "企业"
                                : _vm.paneDatas.tradeInfo.userType == 3
                                ? "组织"
                                : ""
                            )
                          )
                        ])
                      ]),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "1"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("姓名")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(_vm._s(_vm.paneDatas.tradeInfo.name))
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "2"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("法定代表人")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.tradeInfo.legalPerson)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "2"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("企业名称")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.tradeInfo.companyName)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "2"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("注册地址")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.tradeInfo.registAddress)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "1"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("身份证号")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.tradeInfo.identityCode)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        .lenderType == "1"
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("联系电话")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(_vm.paneDatas.tradeInfo.contactPhone)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("开户银行")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(_vm._s(_vm.paneDatas.tradeInfo.openBank))
                        ])
                      ]),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("银行账号")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(_vm._s(_vm.paneDatas.tradeInfo.bankCode))
                        ])
                      ]),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("摘牌时间")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(_vm._s(_vm.paneDatas.tradeInfo.delistingTime))
                        ])
                      ]),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("摘牌方式")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterDelisting")(
                                _vm.paneDatas.tradeInfo.delistingType
                              )
                            )
                          )
                        ])
                      ]),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("成交价格 (元)")
                        ]),
                        _c("p", { staticClass: "itemValue" }, [
                          _vm._v(
                            _vm._s(_vm.paneDatas.tradeInfo.transactionPrice)
                          )
                        ])
                      ]),
                      _vm.paneDatas.tradeInfo.paymentInfo
                        ? _c("span", { staticClass: "itemTradeInfo" }, [
                            _c("p", { staticClass: "itemTitle" }, [
                              _vm._v("转账方式")
                            ]),
                            _c("p", { staticClass: "itemValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.paneDatas.tradeInfo.paymentInfo
                                    .payMethod == 1
                                    ? "代缴代付"
                                    : _vm.paneDatas.tradeInfo.paymentInfo
                                        .payMethod == 2
                                    ? "自行转账"
                                    : ""
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "itemTradeInfo" }, [
                        _c("p", { staticClass: "itemTitle" }, [
                          _vm._v("相关资料")
                        ]),
                        _c(
                          "div",
                          { staticClass: "item-files" },
                          _vm._l(
                            _vm.paneDatas.tradeInfo.delistingFiles,
                            function(v, vInd) {
                              return _c(
                                "el-link",
                                {
                                  key: vInd,
                                  attrs: {
                                    href: v.url,
                                    target: "_blank",
                                    underline: false
                                  }
                                },
                                [_c("div", [_vm._v(_vm._s(v.name))])]
                              )
                            }
                          ),
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "el-tab-pane",
                { attrs: { label: "鉴证书及贷款补充材料", name: "5" } },
                [
                  _c("div", { staticClass: "top-title" }, [
                    _vm._v("鉴证书及贷款补充材料")
                  ]),
                  _c("div", { staticClass: "mortgageData" }, [
                    _vm.paneDatas.applyCertificate
                      ? _c("span", { staticClass: "itemMortgageInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("鉴证书")
                          ]),
                          _vm.paneDatas.applyCertificate
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href:
                                          _vm.paneDatas.applyCertificate
                                            .certificateUrl,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [_c("div", [_vm._v("预览")])]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm.paneDatas.mortgageBasicResponse &&
                    _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                      ? _c("span", { staticClass: "itemMortgageInfo" }, [
                          _c("p", { staticClass: "itemTitle" }, [
                            _vm._v("补充材料")
                          ]),
                          _vm.paneDatas.mortgageBasicResponse
                            .mortgageApprovalEntity.mortgageApprovalAddFile
                            .length > 0
                            ? _c(
                                "div",
                                { staticClass: "item-files" },
                                _vm._l(
                                  _vm.paneDatas.mortgageBasicResponse
                                    .mortgageApprovalEntity
                                    .mortgageApprovalAddFile,
                                  function(k, kInd) {
                                    return _c(
                                      "el-link",
                                      {
                                        key: kInd,
                                        attrs: {
                                          href: k.fileAddr,
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("预览" + _vm._s(kInd + 1))
                                        ])
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _vm.optionData.statusNb === "4" ||
              _vm.optionData.statusNb === "3" ||
              _vm.optionData.statusNb === "2" ||
              _vm.optionData.statusNb === "5"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "审批结果", name: "6" } },
                    [
                      _c("div", { staticClass: "top-title" }, [
                        _vm._v("审批结果")
                      ]),
                      _vm.paneDatas.mortgageBasicResponse &&
                      _vm.paneDatas.mortgageBasicResponse.mortgageApprovalEntity
                        ? _c("div", { staticClass: "approvalData" }, [
                            (_vm.optionData.statusNb === "4" ||
                              _vm.optionData.statusNb === "5") &&
                            _vm.paneDatas.mortgageBasicResponse
                              .mortgageApprovalEntity.mortgageApproved
                              ? _c("span", [
                                  _c(
                                    "span",
                                    { staticClass: "itemApprovalInfo" },
                                    [
                                      _c("p", { staticClass: "itemTitle" }, [
                                        _vm._v("审批额度 (万)")
                                      ]),
                                      _c("p", { staticClass: "itemValue" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.paneDatas.mortgageBasicResponse
                                              .mortgageApprovalEntity
                                              .mortgageApproved.approvalAmount
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "itemApprovalInfo" },
                                    [
                                      _c("p", { staticClass: "itemTitle" }, [
                                        _vm._v("贷款期限 (年)")
                                      ]),
                                      _c("p", { staticClass: "itemValue" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.paneDatas.mortgageBasicResponse
                                              .mortgageApprovalEntity
                                              .mortgageApproved.deadline
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "itemApprovalInfo" },
                                    [
                                      _c("p", { staticClass: "itemTitle" }, [
                                        _vm._v("贷款利率 (%)")
                                      ]),
                                      _c("p", { staticClass: "itemValue" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.paneDatas.mortgageBasicResponse
                                              .mortgageApprovalEntity
                                              .mortgageApproved.interestRate
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "itemApprovalInfo" },
                                    [
                                      _c("p", { staticClass: "itemTitle" }, [
                                        _vm._v("还款方式")
                                      ]),
                                      _c("p", { staticClass: "itemValue" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterRepayment")(
                                              _vm.paneDatas
                                                .mortgageBasicResponse
                                                .mortgageApprovalEntity
                                                .mortgageApproved.repayment
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "itemApprovalInfo" },
                                    [
                                      _c("p", { staticClass: "itemTitle" }, [
                                        _vm._v("发放方式")
                                      ]),
                                      _vm.paneDatas.mortgageBasicResponse
                                        .mortgageApprovalEntity.mortgageApproved
                                        .issuingMethod
                                        ? _c(
                                            "p",
                                            { staticClass: "itemValue" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paneDatas
                                                    .mortgageBasicResponse
                                                    .mortgageApprovalEntity
                                                    .mortgageApproved
                                                    .issuingMethod == "2"
                                                    ? "现场办理"
                                                    : "银行直接发放"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm.paneDatas.mortgageBasicResponse
                                    .mortgageApprovalEntity.mortgageApproved
                                    .issuingMethod == "2"
                                    ? _c(
                                        "span",
                                        { staticClass: "itemApprovalInfo" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "itemTitle" },
                                            [_vm._v("办理地址")]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "itemValue" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paneDatas
                                                    .mortgageBasicResponse
                                                    .mortgageApprovalEntity
                                                    .mortgageApproved.address
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              { staticClass: "itemApprovalInfo" },
                              [
                                _c("p", { staticClass: "itemTitle" }, [
                                  _vm._v("审批意见")
                                ]),
                                _c("el-input", {
                                  staticClass: "approvalDetailTextarea",
                                  attrs: { type: "textarea", disabled: "" },
                                  model: {
                                    value:
                                      _vm.paneDatas.mortgageBasicResponse
                                        .mortgageApprovalEntity
                                        .approvalComments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.paneDatas.mortgageBasicResponse
                                          .mortgageApprovalEntity,
                                        "approvalComments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "paneDatas.mortgageBasicResponse.mortgageApprovalEntity.approvalComments"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.optionData.statusNb === "4" ||
                            _vm.optionData.statusNb === "5"
                              ? _c(
                                  "span",
                                  { staticClass: "itemApprovalInfo" },
                                  [
                                    _c("p", { staticClass: "itemTitle" }, [
                                      _c(
                                        "i",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-top": "13px"
                                          }
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 贷款还清证明 ")
                                    ]),
                                    _vm.optionData.statusNb == "5" &&
                                    _vm.paneDatas.mortgageBasicResponse
                                      .mortgageApprovalEntity
                                      .mortgageSettleProof &&
                                    _vm.paneDatas.mortgageBasicResponse
                                      .mortgageApprovalEntity
                                      .mortgageSettleProof.proofUrl
                                      ? _c(
                                          "el-link",
                                          {
                                            staticClass: "margin-16",
                                            attrs: {
                                              href:
                                                _vm.paneDatas
                                                  .mortgageBasicResponse
                                                  .mortgageApprovalEntity
                                                  .mortgageSettleProof.proofUrl,
                                              target: "_blank",
                                              underline: false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "preview" },
                                              [_vm._v("预览")]
                                            )
                                          ]
                                        )
                                      : _vm.optionData.statusNb == "4" &&
                                        _vm.proveUrl
                                      ? _c(
                                          "el-link",
                                          {
                                            staticClass: "margin-16",
                                            attrs: {
                                              href: _vm.proveUrl,
                                              target: "_blank",
                                              underline: false
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "preview" },
                                              [_vm._v("预览")]
                                            )
                                          ]
                                        )
                                      : _vm.optionData.statusNb == "4" &&
                                        !_vm.proveUrl
                                      ? _c("upLoadFileImg", {
                                          ref: "refa",
                                          staticClass: "upLoadFileImg",
                                          attrs: {
                                            uploadOption: _vm.uploadOptionBtn,
                                            listShow: false,
                                            disabled: false
                                          },
                                          on: {
                                            "update:uploadOption": function(
                                              $event
                                            ) {
                                              _vm.uploadOptionBtn = $event
                                            },
                                            "update:upload-option": function(
                                              $event
                                            ) {
                                              _vm.uploadOptionBtn = $event
                                            },
                                            fileUrl: _vm.getFileUrl
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.btnShow &&
      _vm.optionData.statusNb === "1" &&
      _vm.$route.params.permission == 1
        ? _c(
            "div",
            { staticClass: "approvalBox" },
            [
              _c("div", { staticClass: "itemTtitles" }, [_vm._v("审批")]),
              _c("el-input", {
                staticClass: "approvalTextarea",
                attrs: { type: "textarea" },
                model: {
                  value: _vm.optionData.textArea,
                  callback: function($$v) {
                    _vm.$set(_vm.optionData, "textArea", $$v)
                  },
                  expression: "optionData.textArea"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.btnShow
        ? _c(
            "div",
            { staticClass: "btnBox" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _vm.optionData.statusNb === "1" &&
              _vm.$route.params.permission == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "info", size: "small" },
                      on: { click: _vm.handleSupplyData }
                    },
                    [_vm._v("补充资料")]
                  )
                : _vm._e(),
              _vm.optionData.statusNb === "1" &&
              _vm.$route.params.permission == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.handleRefuse }
                    },
                    [_vm._v("拒绝")]
                  )
                : _vm._e(),
              _vm.optionData.statusNb === "1" &&
              _vm.$route.params.permission == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handlePass }
                    },
                    [_vm._v("通过")]
                  )
                : _vm._e(),
              _vm.optionData.statusNb === "4" &&
              _vm.$route.params.permission == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSettlemented }
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "贷款通过",
            visible: _vm.optionData.dialogTableVisible,
            width: "420px",
            "close-on-click-modal": _vm.isOrModalClose,
            "before-close": _vm.handleDialogClose
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.optionData, "dialogTableVisible", $event)
            }
          }
        },
        [
          _c("dialogMortgage", {
            attrs: { option: _vm.optionData },
            on: { close: _vm.handleDialogClose }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showImg },
          on: {
            "update:visible": function($event) {
              _vm.showImg = $event
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.nowSrc }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }