var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "transaction-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("摘牌人性质")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.info.userType == 1
                      ? "个人"
                      : _vm.info.userType == 2
                      ? "企业"
                      : _vm.info.userType == 3
                      ? "组织"
                      : ""
                  )
                )
              ])
            ]),
            _vm.info.userType == 1
              ? _c("div", [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("姓名")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.name))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("身份证号")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.identityCode))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("联系电话")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.contactPhone))])
                  ])
                ])
              : _vm._e(),
            _vm.info.userType == 2
              ? _c("div", [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("法定代表人")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.legalPerson))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("企业名称")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.companyName))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("注册地址")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.registAddress))])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("开户银行")]),
              _c("span", [_vm._v(_vm._s(_vm.info.openBank))])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("银行账号")]),
              _c("span", [_vm._v(_vm._s(_vm.info.bankCode))])
            ]),
            _c("div", [
              _vm.tranferType == 1 &&
              [4, 5, 6].indexOf(_vm.status) > -1 &&
              _vm.info.deduction !== null &&
              _vm.info.deduction !== undefined
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("保证金抵扣")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.info.deduction ? "是" : "否"))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("摘牌时间")]),
                _c("span", [_vm._v(_vm._s(_vm.info.delistingTime))])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", [_vm._v("摘牌方式")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.delistingType[_vm.info.delistingType]))
                ])
              ]),
              _vm.info.transactionPrice
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("竞拍终价")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.info.amount) + "元" + _vm._s(_vm.unitAppend)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.info.transactionPrice
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("成交价款（元）")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.transactionPrice))])
                  ])
                : _vm._e(),
              _vm.info.paymentInfo && _vm.info.paymentInfo.createdTime
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("付款时间")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.info.paymentInfo.createdTime))
                    ])
                  ])
                : _vm._e(),
              _vm.info.paymentInfo && _vm.info.paymentInfo.payMethod
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("转账方式")]),
                    _c("div", [
                      _vm.info.paymentInfo.payMethod == 1
                        ? _c("span", [_vm._v("代收代付")])
                        : _vm.info.paymentInfo.payMethod == 4
                        ? _c("span", [_vm._v("自行转给出让方")])
                        : _c("span", [_vm._v("自行转账")]),
                      _vm.info.paymentInfo.fileUrl
                        ? _c(
                            "div",
                            _vm._l(_vm.info.paymentInfo.fileUrl, function(
                              ite,
                              ind
                            ) {
                              return _c(
                                "div",
                                { key: ind },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: ite,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.info.paymentInfo.payMethod == 2
                                            ? "转账凭证"
                                            : "企业付款授权协议"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.info.transactionAmount
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("交易服务费（元）")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.transactionAmount))])
                  ])
                : _vm._e(),
              _vm.info.delistingType == "2" &&
              _vm.info.paymentInfo &&
              _vm.info.paymentInfo.amount
                ? _c("div", { staticClass: "item border-top-style" }, [
                    _c("span", [_vm._v("预付金额（元）")]),
                    _c("span", [_vm._v(_vm._s(_vm.info.paymentInfo.amount))])
                  ])
                : _vm._e(),
              _vm.info.transactionAmountInfo &&
              _vm.info.transactionAmountInfo.createdTime
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("付款时间")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.info.transactionAmountInfo.createdTime))
                    ])
                  ])
                : _vm._e(),
              _vm.info.transactionAmountInfo &&
              _vm.info.transactionAmountInfo.payMethod
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("转账方式")]),
                    _c("div", [
                      _vm.info.transactionAmountInfo.payMethod == 1
                        ? _c("span", [_vm._v("代收代付")])
                        : _vm.info.transactionAmountInfo.payMethod == 4
                        ? _c("span", [_vm._v("自行转给出让方")])
                        : _c("span", [_vm._v("自行转账")]),
                      _vm.info.transactionAmountInfo.fileUrl
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.info.transactionAmountInfo.fileUrl,
                              function(ite, ind) {
                                return _c(
                                  "div",
                                  { key: ind },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          href: ite,
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.info.transactionAmountInfo
                                              .payMethod == 2
                                              ? "转账凭证"
                                              : "企业付款授权协议"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.info.promiseAmountInfo && _vm.info.promiseAmountInfo.amount
                ? _c("div", { staticClass: "item border-top-style" }, [
                    _c("span", [_vm._v("保证金（元）")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.info.promiseAmountInfo.amount))
                    ])
                  ])
                : _vm._e(),
              _vm.info.promiseAmountInfo &&
              _vm.info.promiseAmountInfo.createdTime
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("付款时间")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.info.promiseAmountInfo.createdTime))
                    ])
                  ])
                : _vm._e(),
              _vm.info.promiseAmountInfo && _vm.info.promiseAmountInfo.payMethod
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("转账方式")]),
                    _c("div", [
                      _vm.info.promiseAmountInfo.payMethod == 1
                        ? _c("span", [_vm._v("代收代付")])
                        : _vm.info.promiseAmountInfo.payMethod == 4
                        ? _c("span", [_vm._v("自行转给出让方")])
                        : _c("span", [_vm._v("自行转账")]),
                      _vm.info.promiseAmountInfo.fileUrl
                        ? _c(
                            "div",
                            _vm._l(_vm.info.promiseAmountInfo.fileUrl, function(
                              ite,
                              ind
                            ) {
                              return _c(
                                "div",
                                { key: ind },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        href: ite,
                                        target: "_blank",
                                        underline: false
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.info.promiseAmountInfo
                                            .payMethod == 2
                                            ? "转账凭证"
                                            : "企业付款授权协议"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.info.finalPaymentInfo && _vm.info.finalPaymentInfo.createdTime
                ? _c("div", [
                    _vm.info.finalPaymentInfo.amount
                      ? _c("div", { staticClass: "item border-top-style" }, [
                          _c("span", [_vm._v("尾款金额（元）")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.info.finalPaymentInfo.amount))
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "item" }, [
                      _c("span", [_vm._v("付款时间")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.info.finalPaymentInfo.createdTime))
                      ])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", [_vm._v("转账方式")]),
                      _c("div", [
                        _vm.info.finalPaymentInfo.payMethod == 1
                          ? _c("span", [_vm._v("代收代付")])
                          : _vm.info.finalPaymentInfo.payMethod == 4
                          ? _c("span", [_vm._v("自行转给出让方")])
                          : _c("span", [_vm._v("自行转账")]),
                        _vm.info.finalPaymentInfo.fileUrl
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.info.finalPaymentInfo.fileUrl,
                                function(ite, ind) {
                                  return _c(
                                    "div",
                                    { key: ind },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            href: ite,
                                            target: "_blank",
                                            underline: false
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.info.finalPaymentInfo
                                                .payMethod == 2
                                                ? "转账凭证"
                                                : "企业付款授权协议"
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.info.delistingFiles && _vm.info.delistingFiles.length
                ? _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("相关资料")]),
                    _c(
                      "div",
                      _vm._l(_vm.info.delistingFiles, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-link",
                              {
                                class: { "is-first": !index },
                                attrs: {
                                  href: item.url,
                                  target: "_blank",
                                  underline: false
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.info.contractFiles
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "item",
                      attrs: { "element-loading-text": _vm.loadingTxt }
                    },
                    [
                      _c("span", [_vm._v("相关合同")]),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.info.contractFiles, function(item, index) {
                            return _c(
                              "div",
                              { key: index, class: { "admin-way": true } },
                              [
                                item.fileUrl && item.fileUrl.length > 1
                                  ? _c(
                                      "div",
                                      { staticClass: "item-a-list" },
                                      [
                                        _c(
                                          "span",
                                          { class: { "top-20": index } },
                                          [_vm._v(_vm._s(item.fileName))]
                                        ),
                                        _vm._l(item.fileUrl, function(
                                          ite,
                                          ind
                                        ) {
                                          return _c(
                                            "div",
                                            { key: ind },
                                            [
                                              !ite
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "pad-top-20"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.fileName +
                                                            (ind + 1)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        href: ite,
                                                        target: "_blank",
                                                        underline: false
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.fileName +
                                                            (ind + 1)
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : !item.fileUrl || !item.fileUrl[0]
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "div-text": true,
                                          "top-20": index
                                        }
                                      },
                                      [_vm._v(_vm._s(item.fileName))]
                                    )
                                  : _c(
                                      "el-link",
                                      {
                                        class: { "is-first": !index },
                                        attrs: {
                                          href: item.fileUrl[0],
                                          target: "_blank",
                                          underline: false
                                        }
                                      },
                                      [_vm._v(_vm._s(item.fileName))]
                                    ),
                                item.type == 2
                                  ? _c(
                                      "div",
                                      {
                                        class: {
                                          "switch-div": true,
                                          "top-20": index
                                        }
                                      },
                                      [
                                        _c("el-switch", {
                                          on: {
                                            change: function($event) {
                                              return _vm.handleSwitch(
                                                $event,
                                                item.fileId,
                                                index,
                                                4
                                              )
                                            }
                                          },
                                          model: {
                                            value: item.isShow,
                                            callback: function($$v) {
                                              _vm.$set(item, "isShow", $$v)
                                            },
                                            expression: "item.isShow"
                                          }
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.isShow
                                                ? "前端展示"
                                                : "前端不展示"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.type == 2
                                  ? _c(
                                      "span",
                                      {
                                        class: {
                                          "delete-file": true,
                                          "top-20": index
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFile(
                                              item.fileId,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("upload-base", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: {
                              showSuccessTip: false,
                              id: "contractfiles-btn",
                              listShow: false,
                              uploadOption: _vm.uploadOption
                            },
                            on: {
                              fileUrl: _vm.getFileUrl,
                              beforeUpFn: _vm.beforeUpFn,
                              uploadError: _vm.uploadError
                            }
                          }),
                          _vm.isUpdateFiles
                            ? _c(
                                "el-button",
                                {
                                  staticClass:
                                    "global-dotted-btn vertical-bottom",
                                  staticStyle: { "margin-top": "16px" },
                                  attrs: { size: "small" },
                                  on: { click: _vm.updateFiles }
                                },
                                [_vm._v("上传文件")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("交易信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }