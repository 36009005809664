
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import basicInfo from '../components/basic-info.vue'
import bondInfo from '../components/bond-info.vue'
import simpleApprovalInfo from '../components/simple-approval-info.vue'
import tradeList from '../components/trade-list.vue'
import tradeDetails from '../components/trade-details.vue'
import tradeReceipt from '../components/trade-receipt.vue'
import Pagination from '@/components/Pagination/index.vue'
import { marginManagementDetails, launchBondApproval, bondApprovalDetail, bondRecordList, bondRecordDetail, bondPayeeList } from '@/api/countrysidePropertyTransaction'
const tradeStatusList = [
  {
    label: '退款中',
    value: '0'
  },
  {
    label: '成功',
    value: '1'
  },
  {
    label: '失败',
    value: '2'
  }
]
@Component({
  components: {
    Pagination,
    basicInfo,
    bondInfo,
    simpleApprovalInfo,
    tradeList,
    tradeDetails,
    tradeReceipt
  },
  filters: {
    filterTrade(val: number) {
      if (val === undefined || val === null) return
      const value = val.toString()
      return tradeStatusList.find((v) => v.value == value)?.label
    }
  }
})
export default class BondManagementDetail extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private routeParams: any = {} // 路由参数
  private depositInfos: any = null // 保证金列表
  private bondLoading: boolean = false // 保证金列表加载标志
  private bondLoading2: boolean = false // 保证金审批列表加载标志
  private isBondEditItem: any = [] // 保证金编辑项
  // 保证金分页数据
  private bondPaginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private oldRecord: any = null // 传输保证金分页记录数据
  private activeName: any = '1' // 当前 tab 选项
  private approvalInfo: any = null
  private tradeShow: string = 'list' // 交易记录展示标志
  private tradeState: any // 交易记录状态
  private tradeListData: any = [] //交易记录列表
  private tradeDetails: any = null // 交易详情数据
  private tradeId: any // 交易id
  private tableLoading: boolean = true // 收款人加载动画标志
  private tableData: any = [] // 收款人数据
  // 收款人分页
  private paypeePaginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 收款人列标
  private columnData: any = [
    { label: '交易编号', prop: 'tradeNo' },
    { label: '姓名', prop: 'userName' },
    { label: '卡号', prop: 'bankNumber' },
    { label: '开户行', prop: 'openBank' },
    { label: '金额', prop: 'amount' },
    { label: '交易状态', prop: 'status' }
  ]
  get returnTotal() {
    let returnVal: any = this.routeParams.marginPayCount
    if (returnVal) {
      returnVal -= this.routeParams.usePromiseAmountCount
    }
    return returnVal
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      //如果之前有记录 进入几号标签，取出后再删除记录
      let tab = sessionStorage.getItem('bondManagementDetailTab')
      if (tab) {
        vim.activeName = tab
        sessionStorage.removeItem('bondManagementDetailTab')
      }
      if (vim.$route.params.datas) {
        vim.routeParams = JSON.parse(vim.$route.params.datas)
        sessionStorage.setItem('bondManagementDetail', vim.$route.params.datas)
      } else {
        let routeP: any = sessionStorage.getItem('bondManagementDetail')
        vim.routeParams = JSON.parse(routeP)
      }
      if (['CountrysideProjectDetail'].indexOf(from.name) > -1 && sessionStorage.getItem('bondManagementDetailP')) {
        let nowP: any = sessionStorage.getItem('bondManagementDetailP')
        nowP = JSON.parse(nowP)
        vim.activeName = nowP.activeName
        vim.bondPaginationParam = nowP.bondPaginationParam
        vim.tradeShow = nowP.tradeShow
        vim.tradeId = nowP.tradeId
        vim.tradeState = nowP.tradeState
        nowP.paypeePaginationParam && (vim.paypeePaginationParam = nowP.paypeePaginationParam)
      }
      console.log(vim.routeParams);
      vim.routeParams.marginStatus > 1 && vim.getApprovalData()
      if (vim.routeParams.marginStatus > 4) {
        if (vim.tradeShow == 'list') {
          vim.getTradeList()
        } else if (vim.tradeShow == 'details') {
          vim.getTradeDetail(vim.tradeId)
        } else if (this.tradeShow == 'receiveList') {
          vim.getTradePayeeList(this.tradeId)
        } else {
          vim.getTradeDetail(vim.tradeId)
          vim.getTradePayeeList(this.tradeId)
        }
      }
      vim.searchBondList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['CountrysideProjectDetail'].indexOf(to.name) == -1) {
      // 当离开组件时，是否允许离开
      sessionStorage.removeItem('bondManagementDetail')
    } else {
      let pageDate: any = {
        activeName: this.activeName,
        bondPaginationParam: this.bondPaginationParam,
        tradeShow: this.tradeShow,
        tradeId: this.tradeId,
        tradeState: this.tradeState
      }
      if (this.tradeShow == 'receiveList') {
        pageDate.paypeePaginationParam = this.paypeePaginationParam
      }
      sessionStorage.setItem('bondManagementDetailP', JSON.stringify(pageDate))
    }
    next()
  }
  // 判断对象
  private hasObj(obj: any) {
    return obj && Object.keys(obj).length > 0
  }
  // 获取交易记录列表
  private getTradeList() {
    bondRecordList({
      id: this.routeParams.listingApplyId
    }).then((res) => {
      if (res.data.data) {
        this.tradeListData = res.data.data
      }
    })
  }
  // 获取交易记录详情
  private getTradeDetail(id: any) {
    bondRecordDetail({
      id
    }).then((res) => {
      if (res.data.data) {
        this.tradeDetails = res.data.data
      }
    })
  }
  // 获取收款人列表
  private getTradePayeeList(id: any) {
    !this.tableLoading && (this.tableLoading = true)
    let params: any = {
      id
    }
    if (this.tradeShow == 'receiveList') {
      params.isPage = true
      params.pageNumber = this.paypeePaginationParam.pageIndex
      params.pageSize = this.paypeePaginationParam.pageSize
    } else {
      params.isPage = false
    }
    bondPayeeList(params)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          this.tradeShow == 'receiveList' && (this.paypeePaginationParam.total = res.data.total)
        }
      })
      .finally(() => {
        this.tableLoading = false
      })
  }
  // 收款人分页查询
  private getList() {
    this.getTradePayeeList(this.tradeId)
  }
  // 交易记录切换到详情
  private transactionInfo(id: any, status: any) {
    this.tradeShow = 'details'
    this.tradeId = id
    this.tradeState = status
    this.getTradeDetail(id)
  }
  // 交易详情切换到收款人列表
  private tradeReceiveList() {
    this.tradeShow = 'receiveList'
    this.tableData = []
    this.getTradePayeeList(this.tradeId)
  }
  // 交易记录详情切换到交易凭证
  private tradeReceipt() {
    this.tradeShow = 'tradeReceipt'
    this.tableData = []
    this.getTradePayeeList(this.tradeId)
  }
  // 获取保证金列表
  private searchBondList() {
    this.bondLoading = true
    marginManagementDetails({
      listingApplyId: this.routeParams.listingApplyId,
      pageNumber: this.bondPaginationParam.pageIndex,
      pageSize: this.bondPaginationParam.pageSize
    })
      .then((res) => {
        let datas: any = res.data
        if (datas.data) {
          if (this.routeParams.marginStatus == 1 && datas.data.length) {
            this.isBondEditItem = []
            for (let i = 0; i < datas.data.length; i++) {
              this.isBondEditItem.push(false)
            }
          }
          this.depositInfos = datas.data
          this.bondPaginationParam.total = datas.total
        }
      })
      .finally(() => {
        this.bondLoading = false
        this.loading && (this.loading = false)
      })
  }
  // 获取审批详情
  private getApprovalData() {
    this.bondLoading2 = true
    bondApprovalDetail({
      listingApplyId: this.routeParams.listingApplyId
    }).then((res) => {
      if (res.data.data) {
        this.approvalInfo = res.data.data
        console.log(res.data);
        
      }
    }).finally(() => {
      this.bondLoading2 = false
    })
  }
  private returnPage() {
    if (this.activeName == '4' && this.tradeShow != 'list') {
      if (this.tradeShow == 'details') {
        this.tradeShow = 'list'
      } else if (['receiveList', 'tradeReceipt'].indexOf(this.tradeShow) > -1) {
        this.tradeShow = 'details'
      }
    } else {
      this.$router.push({ name: 'ProjectBondManagement' })
    }
  }
  // 发起退款
  private refund() {
    this.loading = true

    let chooseList = (this.$refs.bondInfo as any).multipleSelection
    if (chooseList.length <= 0) {
      this.$message.error('请勾选选项！')
      this.loading = false
      return
    }

    let isNull: boolean = false,
    errorText: string = ''
    if (this.isBondEditItem.some(item => item == true)) {
      this.$message.error('请保存后再发起退款')
      this.loading = false
      return
    }
    if (chooseList && chooseList.length) {
      for (let i = 0; i < chooseList.length; i++) {
        let depositInfosItem: any = chooseList[i]

        if (depositInfosItem && depositInfosItem.payMethod == 2 && (!depositInfosItem.bankAcount || !depositInfosItem.openBank) && depositInfosItem.usePromiseAmount == 0) {
          errorText = '保证金列表，退款银行卡信息不能为空'
          isNull = true
          break
        }
      }
    } else {
      isNull = true
    }
    if (isNull) {
      this.$message.error(errorText)
      this.loading = false
      return
    }

    launchBondApproval({
      listingApplyId: this.routeParams.listingApplyId,
      flowIdList: chooseList.map((item) => {
        return item.delistingAmountFlowId
      })
    })
      .then((res) => {
        this.$message.success('提交成功')
        this.$router.push({ name: 'ProjectBondManagement' })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
