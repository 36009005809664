var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "part first" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("项目名称：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeObj.name == "2"
                      ? _vm.proDetail.productName
                      : _vm.proDetail.demandName
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费类型：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeObj.name == "0"
                      ? "会员费"
                      : _vm.typeObj.name == "1"
                      ? "挂牌服务费"
                      : _vm.typeObj.name == "2"
                      ? "结算费用"
                      : _vm.typeObj.name
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("缴费方：")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.typeObj.name == "2"
                      ? _vm.proDetail.enterpriseName
                      : _vm.proDetail.payer
                  )
                )
              ])
            ]),
            _vm.typeObj.name == "0" || _vm.typeObj.name == "1"
              ? _c("div", [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("缴费金额（元）：")]),
                    _c("span", [_vm._v(_vm._s(_vm.proDetail.cost))])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("缴费时间：")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterTimeStamp")(_vm.proDetail.createdTime)
                        )
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("span", [_vm._v("缴费凭证：")]),
                      _vm.proDetail.certificate
                        ? _c("el-image", {
                            staticClass: "touchImg",
                            attrs: {
                              src: _vm.proDetail.certificate,
                              title: "点击可以放大图片"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDialog(
                                  _vm.proDetail.certificate
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.typeObj.status == 1
                        ? _c("upLoadFileBtn", {
                            ref: "payments1",
                            attrs: {
                              loadingOpt: _vm.loadingOpt1,
                              showSuccessText: false,
                              uploadOption: _vm.uploadOptionBtn,
                              listShow: _vm.listShow,
                              flag: 1
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOptionBtn = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOptionBtn = $event
                              },
                              "update:listShow": function($event) {
                                _vm.listShow = $event
                              },
                              "update:list-show": function($event) {
                                _vm.listShow = $event
                              },
                              fileUrl: _vm.getFileUrlUrls1
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.typeObj.name == "2"
              ? _c("div", [
                  _c("div", { staticClass: "item item-cost" }, [
                    _c("span", [_vm._v("结算总金额（元）：")]),
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm._f("toThousandsSigns")(_vm.proDetail.amount)
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm._f("numMoneyToChinese")(_vm.proDetail.amount)
                            )
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "item align-b" }, [
                    _c("span", [_vm._v("支付方式：")]),
                    _vm.proDetail.settlementApplyPays &&
                    _vm.proDetail.settlementApplyPays[0]
                      ? _c("div", [
                          _c("div", { staticClass: "text-ling" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.proDetail.settlementApplyPays[0]
                                    .payMethod == 1
                                    ? "自行转账"
                                    : _vm.proDetail.settlementApplyPays[0]
                                        .payMethod == 2
                                    ? "代收代付"
                                    : _vm.proDetail.settlementApplyPays[0]
                                        .payMethod
                                ) +
                                " "
                            )
                          ]),
                          _vm.proDetail.settlementApplyPays[0].payMethod == 1
                            ? _c(
                                "div",
                                { staticClass: "img-box" },
                                [
                                  _vm.proDetail.settlementApplyPays[0].filePath
                                    ? _c("el-image", {
                                        staticClass: "touchImg",
                                        attrs: {
                                          src:
                                            _vm.proDetail.settlementApplyPays[0]
                                              .filePath,
                                          title: "点击可以放大图片"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleDialog(
                                              _vm.proDetail
                                                .settlementApplyPays[0].filePath
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.typeObj.status == 1 &&
                                  _vm.proDetail.settlementApplyPays[0].id
                                    ? _c("upLoadFileBtn", {
                                        ref: "payments2",
                                        attrs: {
                                          showSuccessText: false,
                                          loadingOpt: _vm.loadingOpt1,
                                          uploadOption: _vm.uploadOptionBtn,
                                          listShow: _vm.listShow,
                                          flag: 1
                                        },
                                        on: {
                                          "update:uploadOption": function(
                                            $event
                                          ) {
                                            _vm.uploadOptionBtn = $event
                                          },
                                          "update:upload-option": function(
                                            $event
                                          ) {
                                            _vm.uploadOptionBtn = $event
                                          },
                                          "update:listShow": function($event) {
                                            _vm.listShow = $event
                                          },
                                          "update:list-show": function($event) {
                                            _vm.listShow = $event
                                          },
                                          fileUrl: _vm.getFileUrlUrls2
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm.proDetail.settlementApplyPays &&
                  _vm.proDetail.settlementApplyPays[0] &&
                  _vm.proDetail.settlementApplyPays[0].payMethod == 2
                    ? _c("div", [
                        _c("div", { staticClass: "item" }, [
                          _c("span", [_vm._v("开户行：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.settlementApplyPays[0].bankName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("span", [_vm._v("账户：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.settlementApplyPays[0].bankCard
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "item item-cost" }, [
                    _c("span", [_vm._v("结算服务费（元）：")]),
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm._f("toThousandsSigns")(
                                _vm.proDetail.serviceAmount
                              )
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm._f("numMoneyToChinese")(
                                _vm.proDetail.serviceAmount
                              )
                            )
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "item align-b" }, [
                    _c("span", [_vm._v("支付方式：")]),
                    _vm.proDetail.settlementApplyPays &&
                    _vm.proDetail.settlementApplyPays[1]
                      ? _c("div", [
                          _c("div", { staticClass: "text-ling" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.proDetail.settlementApplyPays[1]
                                    .payMethod == 1
                                    ? "自行转账"
                                    : _vm.proDetail.settlementApplyPays[1]
                                        .payMethod == 2
                                    ? "代收代付"
                                    : _vm.proDetail.settlementApplyPays[1]
                                        .payMethod
                                ) +
                                " "
                            )
                          ]),
                          _vm.proDetail.settlementApplyPays[1].payMethod == 1
                            ? _c(
                                "div",
                                { staticClass: "img-box" },
                                [
                                  _vm.proDetail.settlementApplyPays[1].filePath
                                    ? _c("el-image", {
                                        staticClass: "touchImg",
                                        attrs: {
                                          src:
                                            _vm.proDetail.settlementApplyPays[1]
                                              .filePath,
                                          title: "点击可以放大图片"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleDialog(
                                              _vm.proDetail
                                                .settlementApplyPays[1].filePath
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.typeObj.status == 1 &&
                                  _vm.proDetail.settlementApplyPays[1].id
                                    ? _c("upLoadFileBtn", {
                                        ref: "payments3",
                                        attrs: {
                                          loadingOpt: _vm.loadingOpt2,
                                          showSuccessText: false,
                                          uploadOption: _vm.uploadOptionBtn,
                                          listShow: _vm.listShow,
                                          flag: 2
                                        },
                                        on: {
                                          "update:uploadOption": function(
                                            $event
                                          ) {
                                            _vm.uploadOptionBtn = $event
                                          },
                                          "update:upload-option": function(
                                            $event
                                          ) {
                                            _vm.uploadOptionBtn = $event
                                          },
                                          "update:listShow": function($event) {
                                            _vm.listShow = $event
                                          },
                                          "update:list-show": function($event) {
                                            _vm.listShow = $event
                                          },
                                          fileUrl: _vm.getFileUrlUrls3
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm.proDetail.settlementApplyPays &&
                  _vm.proDetail.settlementApplyPays[1] &&
                  _vm.proDetail.settlementApplyPays[1].payMethod == 2
                    ? _c("div", [
                        _c("div", { staticClass: "item" }, [
                          _c("span", [_vm._v("开户行：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.settlementApplyPays[1].bankName
                              )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _c("span", [_vm._v("账户：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.proDetail.settlementApplyPays[1].bankCard
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.dialogVisible },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisible = $event
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.nowSrc }
                })
              ]
            ),
            _c("div", { staticClass: "item" }, [
              _c("span", [_vm._v("状态：")]),
              _c("span", { staticClass: "flex-none" }, [
                _vm._v(
                  _vm._s(
                    _vm.typeObj.status == 1
                      ? "待确认"
                      : _vm.typeObj.status == 2
                      ? "已驳回"
                      : _vm.typeObj.status == 3
                      ? "已确认"
                      : _vm.typeObj.status == 4
                      ? "待结算"
                      : _vm.typeObj.status == 5
                      ? "已结算"
                      : _vm.typeObj.status == 6
                      ? "结算中"
                      : _vm.typeObj.status == 7
                      ? "结算失败"
                      : _vm.typeObj.status
                  )
                )
              ]),
              _vm.typeObj.status == 2
                ? _c("span", { staticClass: "reject-reason" }, [
                    _vm._v(_vm._s(_vm.proDetail.reason))
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _vm.typeObj.name == "1" &&
      (_vm.typeObj.status == 1 ||
        _vm.typeObj.status == 4 ||
        _vm.typeObj.status == 5)
        ? _c("div", { staticClass: "part first" }, [
            _vm._m(1),
            _c("div", { staticClass: "contents" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "itemFile" }, [
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "textTitle" }, [
                        _vm._v("经纪服务商：")
                      ]),
                      _c("el-input", {
                        model: {
                          value:
                            _vm.proDetail.brokerProviderResponse.brokerProvider,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.proDetail.brokerProviderResponse,
                              "brokerProvider",
                              $$v
                            )
                          },
                          expression:
                            "proDetail.brokerProviderResponse.brokerProvider"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "contractTitle" }, [
                        _vm._v("经纪服务合同：")
                      ]),
                      _vm.proDetail.brokerProviderResponse.fileAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.brokerProviderResponse
                                    .fileAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[0],
                              uploadOption: _vm.uploadOption0
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOption0 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOption0 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "confirmLetter" }, [
                        _vm._v("   确认函：")
                      ]),
                      _vm.proDetail.brokerProviderResponse.confirmationAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.brokerProviderResponse
                                    .confirmationAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[1],
                              uploadOption: _vm.uploadOptions1
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOptions1 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOptions1 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "itemFile" }, [
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "textTitle" }, [
                        _vm._v("渠道服务商：")
                      ]),
                      _c("el-input", {
                        model: {
                          value:
                            _vm.proDetail.channelProviderResponse
                              .channelProvider,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.proDetail.channelProviderResponse,
                              "channelProvider",
                              $$v
                            )
                          },
                          expression:
                            "proDetail.channelProviderResponse.channelProvider"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "contractTitle" }, [
                        _vm._v("渠道服务合同：")
                      ]),
                      _vm.proDetail.channelProviderResponse.fileAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.channelProviderResponse
                                    .fileAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[2],
                              uploadOption: _vm.uploadOption2
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOption2 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOption2 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "confirmLetter" }, [
                        _vm._v("   确认函：")
                      ]),
                      _vm.proDetail.channelProviderResponse.confirmationAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.channelProviderResponse
                                    .confirmationAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[3],
                              uploadOption: _vm.uploadOptions3
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOptions3 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOptions3 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "itemFile" }, [
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "textTitle" }, [
                        _vm._v("咨询服务商：")
                      ]),
                      _c("el-input", {
                        model: {
                          value:
                            _vm.proDetail.consultingProviderResponse
                              .consultingProvider,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.proDetail.consultingProviderResponse,
                              "consultingProvider",
                              $$v
                            )
                          },
                          expression:
                            "proDetail.consultingProviderResponse.consultingProvider"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "contractTitle" }, [
                        _vm._v("咨询服务合同：")
                      ]),
                      _vm.proDetail.consultingProviderResponse.fileAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.consultingProviderResponse
                                    .fileAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[4],
                              uploadOption: _vm.uploadOption4
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOption4 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOption4 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemDetail" },
                    [
                      _c("span", { staticClass: "confirmLetter" }, [
                        _vm._v("   确认函：")
                      ]),
                      _vm.proDetail.consultingProviderResponse
                        .confirmationAddress
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                href:
                                  _vm.proDetail.consultingProviderResponse
                                    .confirmationAddress,
                                target: "_blank",
                                underline: false
                              }
                            },
                            [
                              _c("div", { staticClass: "preview" }, [
                                _vm._v("预览")
                              ])
                            ]
                          )
                        : _c("UploadBase", {
                            attrs: {
                              loadingOpt: _vm.loadingOpt3[5],
                              uploadOption: _vm.uploadOptions5
                            },
                            on: {
                              "update:uploadOption": function($event) {
                                _vm.uploadOptions5 = $event
                              },
                              "update:upload-option": function($event) {
                                _vm.uploadOptions5 = $event
                              },
                              fileUrl: _vm.getFileUrlUrl
                            }
                          })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.typeObj.name == "2"
        ? _c("div", { staticClass: "part first" }, [
            _c(
              "div",
              { staticClass: "title title-link" },
              [
                _c("span", [_vm._v("结算明细")]),
                _vm.proDetail.settlementFile
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          href: _vm.proDetail.settlementFile,
                          underline: false,
                          download: ""
                        }
                      },
                      [_vm._v(_vm._s(_vm.proDetail.settlementFileName))]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("nums-tips", { attrs: { nums: _vm.paginationParam.total } }),
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.settlementInfo,
                          "element-loading-text": _vm.loadingTxt,
                          "header-cell-style": {
                            textAlign: "center",
                            background: "#FAFAFA"
                          },
                          "cell-style": { textAlign: "center" }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            type: "index",
                            index: index => index + 1,
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "企业名/姓名", prop: "name" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "统一社会信用代码/身份证号",
                            prop: "identityCode"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "银行卡号", prop: "bankCard" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "开户行", prop: "openBank" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "本息和(元)" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousandsSigns")(
                                          scope.row.priceAndInterest
                                        )
                                      )
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3759181458
                          )
                        }),
                        _c("el-table-column", {
                          attrs: { label: "结算状态" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.status == 1
                                          ? "未结算"
                                          : scope.row.status == 2
                                          ? "已结算"
                                          : scope.row.status == 3
                                          ? "结算失败"
                                          : scope.row.status
                                      )
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3732920192
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    total: _vm.paginationParam.total,
                    page: _vm.paginationParam.pageIndex,
                    limit: _vm.paginationParam.pageSize
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.paginationParam, "pageIndex", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.paginationParam, "pageSize", $event)
                    },
                    pagination: _vm.query
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.typeObj.name == "2" && _vm.typeObj.status >= 5
        ? _c("div", { staticClass: "part first" }, [
            _vm._m(2),
            _c(
              "div",
              {
                staticClass: "content",
                staticStyle: { "padding-top": "20px" }
              },
              [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading2,
                            expression: "tableLoading2"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.transaction,
                          "element-loading-text": _vm.loadingTxt,
                          "header-cell-style": {
                            textAlign: "center",
                            background: "#FAFAFA"
                          },
                          "cell-style": { textAlign: "center" }
                        }
                      },
                      [
                        _c("el-table-column", {
                          staticClass: "single-line",
                          attrs: {
                            label: "序号",
                            type: "index",
                            index: index => index + 1
                          }
                        }),
                        _vm._l(_vm.columnData, function(item) {
                          return _c("el-table-column", {
                            key: item.label,
                            attrs: { prop: item.prop, label: item.label },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function({ row }) {
                                    return [
                                      item.prop === "createdTime"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatTime")(
                                                  row[item.prop]
                                                )
                                              )
                                            )
                                          ])
                                        : item.prop === "tradeState"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("filterTrade")(
                                                    row[item.prop]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c(
                                            "span",
                                            {
                                              class: {
                                                usage: item.prop === "resultMsg"
                                              }
                                            },
                                            [_vm._v(_vm._s(row[item.prop]))]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function({ row }) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.transactionInfo(row)
                                          }
                                        }
                                      },
                                      [_vm._v("查看")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4268062695
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogTableVisibles,
            width: "600px",
            "close-on-click-modal": _vm.isOrModalClose,
            "before-close": _vm.handleDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisibles = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "rejectReason" },
            [
              _c("div", { staticClass: "rejectTitle" }, [
                _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _c("span", [_vm._v("驳回理由：")])
              ]),
              _c("el-input", {
                staticClass: "dialogTextarea",
                attrs: { maxlength: "50", type: "textarea" },
                model: {
                  value: _vm.dialogInput,
                  callback: function($$v) {
                    _vm.dialogInput = $$v
                  },
                  expression: "dialogInput"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialogBlock" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancelDialog } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleComfireDialog }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              staticClass: "goback",
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "FinancialList" })
                }
              }
            },
            [_vm._v("返回")]
          ),
          _vm.typeObj.status == 1
            ? _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.handleReject }
                },
                [_vm._v("驳回")]
              )
            : _vm._e(),
          _vm.typeObj.status == 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.tabConfirmReceipt(5)
                    }
                  }
                },
                [_vm._v("确认收款")]
              )
            : _vm._e(),
          (_vm.typeObj.status == 4 || _vm.typeObj.status == 7) &&
          (_vm.typeObj.name == "1" || _vm.typeObj.name == "2")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.Evt }
                },
                [
                  _vm._v(
                    _vm._s(_vm.typeObj.name == "2" ? "发起结算" : "确认结算")
                  )
                ]
              )
            : _vm._e(),
          _vm.typeObj.status == 1 &&
          (_vm.typeObj.name == "1" || _vm.typeObj.name == "2")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.Evt }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.typeObj.name == "2" ? "确认并发起结算" : "确认并结算"
                    )
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("缴费信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("结算处理")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title title-link" }, [
      _c("span", [_vm._v("交易记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }