
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class BasicInfo extends Vue {
  @Prop() info: any
  @Prop({
    default: false
  })
  openStatus?: boolean
  private intoDetails() {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: this.info.listingApplyId, isExport: '0' }
    })
  }
  get filteStatus() {
    let returnDesc: string = '',
      marginStatus: any = this.info.marginStatus
    if (marginStatus || marginStatus === 0) {
      let statusOptions: any = ['竞价中', '待退款', '退款审批中', '已驳回', '退款中', '退款成功', '退款失败']
      returnDesc = statusOptions[marginStatus]
    }
    return returnDesc
  }
}
