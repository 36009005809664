import { render, staticRenderFns } from "./offline-need-data.vue?vue&type=template&id=605d0046&scoped=true&"
import script from "./offline-need-data.vue?vue&type=script&lang=ts&"
export * from "./offline-need-data.vue?vue&type=script&lang=ts&"
import style0 from "./offline-need-data.vue?vue&type=style&index=0&id=605d0046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605d0046",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('605d0046')) {
      api.createRecord('605d0046', component.options)
    } else {
      api.reload('605d0046', component.options)
    }
    module.hot.accept("./offline-need-data.vue?vue&type=template&id=605d0046&scoped=true&", function () {
      api.rerender('605d0046', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/countryside-property-transaction/components/offline-need-data.vue"
export default component.exports