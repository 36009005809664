
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import liquidationBasicInfo from '../components/liquidation-basic-info.vue'
import liquidationInfo from '../components/liquidation-info.vue'
import simpleApprovalInfo from '../components/simple-approval-info.vue'
import tradeList from '../components/trade-list.vue'
import tradeDetails from '../components/trade-details.vue'
import tradeReceipt from '../components/trade-receipt.vue'
import AddOfflineLiq from './addEditDetail/AddOfflineLiq.vue'
import Pagination from '@/components/Pagination/index.vue'
import { floatAdd, floatMul, floatDiv } from '@/utils/basicOperation'
import moment from 'moment'

import { liquidationDetail, launchLiquidation, searchPayeeInfo, liquidationRecordList, liquidationRecordDetail, liquidationPayeeList } from '@/api/countrysidePropertyTransaction'
const tradeStatusList = [
  {
    label: '清算中',
    value: '0'
  },
  {
    label: '成功',
    value: '1'
  },
  {
    label: '失败',
    value: '2'
  }
]
@Component({
  components: {
    Pagination,
    liquidationBasicInfo,
    liquidationInfo,
    simpleApprovalInfo,
    tradeList,
    tradeDetails,
    tradeReceipt,
    AddOfflineLiq
  },
  filters: {
    filterTrade(val: number) {
      if (val === undefined || val === null) return
      const value = val.toString()
      return tradeStatusList.find((v) => v.value == value)?.label
    }
  }
})
export default class LiquidationDetail extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private disableList: any = [] // 清算列表禁用选项
  private routeParams: any // 路由参数
  private activeName: any = '1' // 当前 tab 选项
  private proDetail: any = {}
  private tradeListData: any = [] //交易记录列表
  private tradeShow: string = 'list' // 交易记录展示标志
  private tradeState: any // 交易记录状态
  private tradeDetails: any = null // 交易详情数据
  private tradeId: any // 交易id
  private tableData: any = [] // 收款人数据
  private tableLoading: boolean = true // 收款人加载标志
  // 收款人分页
  private paypeePaginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 收款人列标
  private columnData: any = [
    { label: '交易编号', prop: 'tradeNo' },
    { label: '姓名', prop: 'userName' },
    { label: '卡号', prop: 'bankNumber' },
    { label: '开户行', prop: 'openBank' },
    { label: '金额', prop: 'amount' },
    { label: '交易状态', prop: 'status' }
  ]
  // 线下清算逻辑
  showOffLineLiqDialog = false
  // 线下清算逻辑 END
  timeFormat(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      //如果之前有记录 进入几号标签，取出后再删除记录
      let tab = sessionStorage.getItem('liquidationDetail')
      if (tab) {
        vim.activeName = tab
        sessionStorage.removeItem('liquidationDetail')
      }
      if (vim.$route.params.id) {
        vim.routeParams = vim.$route.params.id
        sessionStorage.setItem('LiquidationManagement', vim.routeParams)
      } else {
        vim.routeParams = sessionStorage.getItem('LiquidationManagement')
      }
      if (['CountrysideProjectDetail'].indexOf(from.name) > -1 && sessionStorage.getItem('LiquidationManagementP')) {
        let nowP: any = sessionStorage.getItem('LiquidationManagementP')
        nowP = JSON.parse(nowP)
        vim.activeName = nowP.activeName
        vim.tradeShow = nowP.tradeShow
        vim.tradeId = nowP.tradeId
        vim.tradeState = nowP.tradeState
        nowP.paypeePaginationParam && (vim.paypeePaginationParam = nowP.paypeePaginationParam)
      }
      vim.getData()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (['CountrysideProjectDetail'].indexOf(to.name) == -1) {
      // 当离开组件时，是否允许离开
      sessionStorage.removeItem('LiquidationManagement')
    } else {
      let pageDate: any = {
        activeName: this.activeName,
        tradeShow: this.tradeShow,
        tradeId: this.tradeId,
        tradeState: this.tradeState
      }
      if (this.tradeShow == 'receiveList') {
        pageDate.paypeePaginationParam = this.paypeePaginationParam
      }
      if (this.proDetail.status == 1 || this.proDetail.status == 4) {
        pageDate.payeeList = this.proDetail.payeeList
        pageDate.liquidationApply = this.proDetail.liquidationApply
      }
      sessionStorage.setItem('LiquidationManagementP', JSON.stringify(pageDate))
    }
    next()
  }
  // 判断对象
  private hasObj(obj: any) {
    return obj && Object.keys(obj).length > 0
  }
  // 收款人分页查询
  private getList() {
    this.getTradePayeeList(this.tradeId)
  }
  // 获取收款人列表
  private getTradePayeeList(id: any) {
    !this.tableLoading && (this.tableLoading = true)
    let params: any = {
      id
    }
    if (this.tradeShow == 'receiveList') {
      params.isPage = true
      params.pageNumber = this.paypeePaginationParam.pageIndex
      params.pageSize = this.paypeePaginationParam.pageSize
    } else {
      params.isPage = false
    }
    liquidationPayeeList(params)
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
          this.tradeShow == 'receiveList' && (this.paypeePaginationParam.total = res.data.total)
        }
      })
      .finally(() => {
        this.tableLoading = false
      })
  }
  // 获取交易记录列表
  private getTradeList(id: any) {
    liquidationRecordList({
      id
    }).then((res) => {
      if (res.data.data) {
        this.tradeListData = res.data.data
      }
    })
  }
  // 获取交易记录详情
  private getTradeDetail(id: any) {
    liquidationRecordDetail({
      id
    }).then((res) => {
      if (res.data.data) {
        this.tradeDetails = res.data.data
      }
    })
  }
  // 交易记录切换到详情
  private transactionInfo(id: any, status: any) {
    this.tradeShow = 'details'
    this.tradeId = id
    this.tradeState = status
    this.getTradeDetail(id)
  }
  // 交易详情切换到收款人列表
  private tradeReceiveList() {
    this.tradeShow = 'receiveList'
    this.tableData = []
    this.getTradePayeeList(this.tradeId)
  }
  // 交易记录详情切换到交易凭证
  private tradeReceipt() {
    this.tradeShow = 'tradeReceipt'
    this.tableData = []
    this.getTradePayeeList(this.tradeId)
  }
  // 限制输入
  private changeInput(value: any, liquidationAmount: any, type: any) {
    let returnVal: any = ''
    if (!value && value !== 0) {
      return
    }
    if (type == 1) {
      returnVal = floatMul(floatDiv(value, 100), liquidationAmount).toFixed(2)
    } else {
      returnVal = parseFloat(value).toFixed(2)
    }
    return returnVal
  }
  private async filtePayeeList(list: any, listingParty: any, transactionAmount: any, type: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let returnArr: any = [],
        firstItem: any = null
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == 2) {
            firstItem = list[i]
          } else {
            returnArr.push(list[i])
          }
        }
        if (!firstItem) {
          firstItem = {
            payeeNature: '转让方',
            type: 2,
            payee: listingParty,
            openBank: '',
            bankAccount: '',
            amountScale: '',
            amount: ''
          }
          await searchPayeeInfo({
            payee: listingParty
          }).then((res) => {
            let datas: any = res.data.data
            if (datas) {
              datas.openBank && (firstItem.openBank = datas.openBank)
              datas.bankAccount && (firstItem.bankAccount = datas.bankAccount)
              datas.amountScale && (firstItem.amountScale = datas.amountScale)
              datas.amountScale && transactionAmount && (firstItem.amount = this.changeInput(datas.amountScale, transactionAmount, type))
            }
          })
        }
        returnArr.unshift(firstItem)
      }
      resolve(returnArr)
    })
  }
  //获取详情
  private getData() {
    console.log({
      id: this.routeParams
    });

    liquidationDetail({
      id: this.routeParams
    })
      .then(async (res) => {
        if (res.data.data) {
          this.proDetail = res.data.data
          if (this.proDetail.status > 4) {
            if (this.tradeShow == 'list') {
              let liquidationApply: any = this.proDetail.liquidationApply,
                tradeListDataId = liquidationApply && liquidationApply.id ? liquidationApply.id : ''
              tradeListDataId && this.getTradeList(tradeListDataId)
            } else if (this.tradeShow == 'details') {
              this.getTradeDetail(this.tradeId)
            } else if (this.tradeShow == 'receiveList') {
              this.getTradePayeeList(this.tradeId)
            } else {
              this.getTradeDetail(this.tradeId)
              this.getTradePayeeList(this.tradeId)
            }
          }
          let lp: any = sessionStorage.getItem('LiquidationManagementP')
          if (lp && (this.proDetail.status == 1 || this.proDetail.status == 4)) {
            lp = JSON.parse(lp)
            this.proDetail.payeeList = lp.payeeList
            this.proDetail.liquidationApply = lp.liquidationApply
          } else {
            !this.proDetail.liquidationApply &&
              (this.proDetail.liquidationApply = {
                id: this.proDetail.id,
                type: 1,
                liquidationAmount: this.proDetail.transactionAmount,
                status: this.proDetail.status
              })
            await this.filtePayeeList(this.proDetail.payeeList, this.proDetail.listingParty, this.proDetail.transactionAmount, this.proDetail.liquidationApply.type).then((res: any) => {
              this.proDetail.payeeList = res
            })
          }
          if ((this.proDetail.status == 1 || this.proDetail.status == 4) && this.proDetail.payeeList) {
            for (let i = 0; i < this.proDetail.payeeList.length; i++) {
              this.disableList.push(false)
            }
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private returnPage() {
    if (this.activeName == '4' && this.tradeShow != 'list') {
      if (this.tradeShow == 'details') {
        this.tradeShow = 'list'
      } else if (['receiveList', 'tradeReceipt'].indexOf(this.tradeShow) > -1) {
        this.tradeShow = 'details'
      }
    } else {
      this.$router.push({ name: 'LiquidationManagement' })
    }
  }
  // 发起清算
  private refund() {
    if (this.proDetail.status != 5) {
      let isError: any = this.judgeLiquidationInfo()
      if (isError.error) {
        this.$message.error(isError.errorTitle)
        return
      }
    }
    this.loading = true
    let params: any = {
      listingApplyId: this.proDetail.id,
      type: this.proDetail.liquidationApply.type,
      liquidationAmount: this.proDetail.transactionAmount,
      status: 2,
      payeeList: this.proDetail.payeeList
    }
    launchLiquidation(params)
      .then((res) => {
        this.$message.success('提交成功')
        this.$router.push({ name: 'LiquidationManagement' })
      })
      .finally(() => {
        this.loading = false
      })
  }
  // 判断清算信息完整与否
  private judgeLiquidationInfo() {
    let payeeList: any = this.proDetail.payeeList,
      liquidationApply: any = this.proDetail.liquidationApply,
      isError: boolean = false,
      errorTitle: string = '',
      isNull: boolean = false
    if (payeeList && liquidationApply && liquidationApply.liquidationAmount) {
      let total: number = 0
      for (let i = 0; i < payeeList.length; i++) {
        // 遍历收款人字段
        for (let k in payeeList[i]) {
          // 非必填跳过
          if (['id', 'status'].indexOf(k) > -1) {
            continue
            // 必填为空结束提示
          } else if ([null, undefined, ''].indexOf(payeeList[i][k]) > -1) {
            errorTitle = '清算信息有字段没有填写'
            isError = true
            isNull = true
            break
          } else if (k == 'amountScale' && payeeList[i][k] == 0) {
            errorTitle = '清算的比例/金额不能为0'
            isError = true
            isNull = true
            break
          } else if (k == 'amountScale') {
            let amountScale: any = payeeList[i][k]
            if (amountScale !== null && amountScale + '') {
              total = floatAdd(total, amountScale)
            }
          }
        }
        if (isNull) {
          break
        }
      }
      if (!isNull && liquidationApply.type == 1 && total != 100) {
        isError = true
      } else if (!isNull && liquidationApply.type == 2 && total != liquidationApply.liquidationAmount) {
        isError = true
      }
      if (!isNull && isError) {
        errorTitle = liquidationApply.type == 1 ? '清算信息的收款方的金额比例和必须为100%' : '清算信息的收款方的金额和必须等于清算总金额'
      }
    } else {
      errorTitle = '清算信息为空'
      isError = true
    }
    return { error: isError, errorTitle }
  }
  //线下清算逻辑

  //线下清算逻辑 END
  //预览
  private showEnlarge: boolean = false // 放大图片
  private nowSrc: any = '' // 当前凭证图片路径
  private enlargeImg(src: any) {
    this.nowSrc = src
    this.showEnlarge = true
  }
}
