var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "bidders-detail",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "竞买人信息", name: "1" } },
            [_c("bidder-info", { attrs: { info: _vm.proDetail } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "所需资料", name: "2" } },
            [
              _c("need-data", {
                attrs: {
                  ruleType: 13,
                  info: _vm.proDetail.delistingFiles,
                  otherFileList: _vm.proDetail.delistingOtherFiles
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName == "2"
        ? _c(
            "div",
            { staticClass: "operate" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }