var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "trading-rules-config",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", { staticClass: "area-item li-item" }, [
        _c("span", { staticClass: "left-li" }, [_vm._v("区域：")]),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.isSuperAdmin != "1",
                  placeholder: "请选择",
                  size: "small"
                },
                on: { change: _vm.selecttedArea },
                model: {
                  value: _vm.formInfo.areaConfigId,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                  },
                  expression: "formInfo.areaConfigId"
                }
              },
              _vm._l(_vm.areaOptions, function(item) {
                return _c("el-option", {
                  key: item.areaId,
                  attrs: { label: item.areaName, value: item.areaId }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择", size: "small" },
                on: { change: _vm.selecttedRuleType },
                model: {
                  value: _vm.formInfo.ruleType,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "ruleType", $$v)
                  },
                  expression: "formInfo.ruleType"
                }
              },
              _vm._l(_vm.ruleTypeOptions, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.value }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _vm.formInfo.ruleType == 1
        ? _c("div", { staticClass: "li-item" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "right-li" },
              [
                _vm._v(" 交易违约方需承担项目成交价格的 "),
                _c("el-input", {
                  staticClass: "number-input",
                  attrs: { placeholder: "请输入", size: "small" },
                  on: { input: _vm.changeInput },
                  model: {
                    value: _vm.formInfo.transactionViolate,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "transactionViolate", $$v)
                    },
                    expression: "formInfo.transactionViolate"
                  }
                }),
                _vm._v("%，作为交易违约金 ")
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c("el-input", {
              staticClass: "name-input",
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.formInfo.transactionName,
                callback: function($$v) {
                  _vm.$set(_vm.formInfo, "transactionName", $$v)
                },
                expression: "formInfo.transactionName"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "li-item" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "right-li" },
          [
            _c("quill-editor", {
              staticClass: "contnt-editor",
              attrs: { options: _vm.editorOption },
              model: {
                value: _vm.formInfo.transactionContent,
                callback: function($$v) {
                  _vm.$set(_vm.formInfo, "transactionContent", $$v)
                },
                expression: "formInfo.transactionContent"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "control-box" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.isSuperAdmin != "1" && !_vm.formInfo.areaConfigId
              },
              on: { click: _vm.saveFn }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("规则类型：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("交易违约金：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("规则名称：")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left-li" }, [
      _c("span", { staticClass: "red-dot" }, [_vm._v("*")]),
      _vm._v("规则内容：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }