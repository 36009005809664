var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "table-column" }, [
    _c("div", { staticClass: "part" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.info.columnName))]),
        _vm.isControl &&
        _vm.info &&
        _vm.info.canNew &&
        _vm.info.fieldList &&
        _vm.info.fieldList.length
          ? _c("span", { staticClass: "add", on: { click: _vm.addFn } }, [
              _vm._v("新增")
            ])
          : _vm._e(),
        !_vm.isControl && _vm.info
          ? _c(
              "div",
              { staticClass: "unqualified" },
              [
                _c("el-checkbox", {
                  model: {
                    value: _vm.checkVal,
                    callback: function($$v) {
                      _vm.checkVal = $$v
                    },
                    expression: "checkVal"
                  }
                }),
                _vm._v("此栏目填写不合格 ")
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.info.valueList && _vm.info.fieldList && _vm.info.fieldList.length
        ? _c(
            "div",
            { staticClass: "table" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.valueList,
                    "header-cell-style": {
                      textAlign: "center",
                      background: "#FAFAFA"
                    },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _vm._l(_vm.info.fieldList, function(item, index) {
                    return _c(
                      "el-table-column",
                      {
                        key: index,
                        attrs: { align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm.isControl &&
                                  _vm.isEitd[scope.$index] &&
                                  item.fieldName == "所属村组(合作社/社区)"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: !_vm.requestEnd,
                                                placeholder: "请选择省份",
                                                size: "small"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selecttedProvince(
                                                    $event,
                                                    index,
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.fixedSelection.province,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fixedSelection,
                                                    "province",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fixedSelection.province"
                                              }
                                            },
                                            _vm._l(
                                              _vm.options.provinceOptions,
                                              function(ite, inde) {
                                                return _c("el-option", {
                                                  key: inde,
                                                  attrs: {
                                                    label: ite,
                                                    value: ite
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: !_vm.requestEnd,
                                                placeholder: "请选择城市",
                                                size: "small"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selecttedCity(
                                                    $event,
                                                    index,
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.fixedSelection.city,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fixedSelection,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fixedSelection.city"
                                              }
                                            },
                                            _vm._l(
                                              _vm.options.cityOptions,
                                              function(ite, inde) {
                                                return _c("el-option", {
                                                  key: inde,
                                                  attrs: {
                                                    label: ite,
                                                    value: ite
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: !_vm.requestEnd,
                                                placeholder: "请选择区县",
                                                size: "small"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selecttedCounty(
                                                    $event,
                                                    index,
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.fixedSelection.county,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fixedSelection,
                                                    "county",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fixedSelection.county"
                                              }
                                            },
                                            _vm._l(
                                              _vm.options.countyOptions,
                                              function(ite, inde) {
                                                return _c("el-option", {
                                                  key: inde,
                                                  attrs: {
                                                    label: ite,
                                                    value: ite
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: !_vm.requestEnd,
                                                placeholder: "请选择街道/镇",
                                                size: "small"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selecttedTownship(
                                                    $event,
                                                    index,
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.fixedSelection.township,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fixedSelection,
                                                    "township",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fixedSelection.township"
                                              }
                                            },
                                            _vm._l(
                                              _vm.options.townshipOptions,
                                              function(ite, inde) {
                                                return _c("el-option", {
                                                  key: inde,
                                                  attrs: {
                                                    label: ite,
                                                    value: ite
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择村组",
                                                size: "small"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.selecttedVillage(
                                                    $event,
                                                    index,
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.fixedSelection
                                                    .villageName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fixedSelection,
                                                    "villageName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fixedSelection.villageName"
                                              }
                                            },
                                            _vm._l(
                                              _vm.options.villageOptions,
                                              function(ite) {
                                                return _c("el-option", {
                                                  key: ite.villageId,
                                                  attrs: {
                                                    label: ite.villageName,
                                                    value:
                                                      ite.villageId +
                                                      "," +
                                                      ite.villageName
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm.isControl &&
                                      _vm.isEitd[scope.$index] &&
                                      item.fieldType != "1" &&
                                      item.fieldType != "5" &&
                                      item.valueType != "2sum"
                                    ? _c("el-input", {
                                        attrs: {
                                          size: "small",
                                          type: "textarea",
                                          autosize: { minRows: 1 },
                                          resize: "none"
                                        },
                                        on: {
                                          blur: function($event) {
                                            $event.preventDefault()
                                            return _vm.changeValue(
                                              $event,
                                              scope.row,
                                              index,
                                              item
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            scope.row.valueData[index].value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row.valueData[index],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.valueData[index].value"
                                        }
                                      })
                                    : !_vm.isControl ||
                                      !_vm.isEitd[scope.$index] ||
                                      item.valueType == "2sum"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.fieldName !=
                                              "所属村组(合作社/社区)"
                                              ? scope.row.valueData[index].value
                                              : _vm.filterVal(
                                                  scope.row.valueData[index]
                                                    .value
                                                )
                                          )
                                        )
                                      ])
                                    : _vm.isEitd[scope.$index] &&
                                      item.fieldType == "1"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择",
                                                size: "small"
                                              },
                                              model: {
                                                value:
                                                  scope.row.valueData[index]
                                                    .value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row.valueData[index],
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.valueData[index].value"
                                              }
                                            },
                                            _vm._l(
                                              _vm.unsetOptions[
                                                scope.row.valueData[index]
                                                  .fieldId
                                              ],
                                              function(ite, ind) {
                                                return _c("el-option", {
                                                  key: ind,
                                                  attrs: {
                                                    label: ite.itemValue,
                                                    value: ite.itemValue
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "选择日期"
                                            },
                                            model: {
                                              value:
                                                scope.row.valueData[index]
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row.valueData[index],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.valueData[index].value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _vm._v(
                            _vm._s(
                              (item.fieldName ? item.fieldName : "") +
                                (item.isRequire ? "*" : "")
                            )
                          )
                        ])
                      ],
                      2
                    )
                  }),
                  _vm.isControl
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          "min-width": "90"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm.isEitd[scope.$index]
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.preserveFn(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("保存")]
                                      )
                                    : _vm._e(),
                                  !_vm.isEitd[scope.$index]
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.editFn(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm.info.canNew
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteFn(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          891405078
                        )
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasSum
        ? _c("div", { staticClass: "sum" }, [
            _vm._v(_vm._s(_vm.totalTile) + "合计：" + _vm._s(_vm.total))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }