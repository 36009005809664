import request from '@/utils/request'
// 行政区域信用管理

// ------------评定小组管理-----------------
// 获取评定小组列表
export function getAssessmentTeamList(data: Object) {
    return request({
        url: '/assessmentGroup/page',
        method: 'POST',
        data: data
    })
}

// 获取评定小组详情
export function getAssessmentTeamDetails(data: Object) {
    return request({
        url: '/assessmentGroup/detail',
        method: 'POST',
        data: data
    })
}

// 添加小组详情
export function addAssessmentTeam(data: Object) {
    return request({
        url: '/assessmentGroup/add',
        method: 'POST',
        data: data
    })
}

// 更新小组详情
export function updateAssessmentTeam(data: Object) {
    return request({
        url: '/assessmentGroup/update',
        method: 'POST',
        data: data
    })
}

// ------------我的辖区-----------------
// 获取我的辖区列表
export function getMyJurisdictionList(data: Object) {
    return request({
        url: '/villageManage/myArea/page',
        method: 'POST',
        data: data
    })
}

// 村组保存
export function villageSave(data: Object) {
    return request({
        url: '/creditAssessVillage/saveOrEdit',
        method: 'POST',
        data: data
    })
}

// 乡保存
export function townshipSave(data: Object) {
    return request({
        url: '/creditAssessTownship/saveOrEdit',
        method: 'POST',
        data: data
    })
}

// 县保存
export function countySave(data: Object) {
    return request({
        url: '/creditAssessCounty/saveOrEdit',
        method: 'POST',
        data: data
    })
}

// 申请信用村/镇/县
export function applyCredit(data: Object) {
    return request({
        url: '/creditAssessApproval/submit',
        method: 'POST',
        data: data
    })
}

//---------------- 信用村组信息模块
// 信用村组信息详情--获取村组
export function getVillagePermission(data: Object) {
    return request({
        url: '/villageManage/getVillagePermission',
        method: 'POST',
        data: data
    })
}
export function VillageDetail(data: Object) {
    return request({
        url: '/creditAssessVillage/detail',
        method: 'POST',
        data: data
    })
}
// 信用村组信息列表
export function VillagePage(data: Object) {
    return request({
        url: '/creditAssessVillage/page',
        method: 'POST',
        data: data
    })
}
// 提交信用村信息
export function VillageSubmit(data: Object) {
    return request({
        url: '/creditAssessVillage/submit',
        method: 'POST',
        data: data
    })
}
// 提交信用村信息
export function VillageEditShow(data: Object) {
    return request({
        url: '/creditAssessVillage/editShow',
        method: 'POST',
        data: data
    })
}
//---------------- 信用乡
export function TownshipDetail(data: Object) {
    return request({
        url: '/creditAssessTownship/detail',
        method: 'POST',
        data: data
    })
}
// 信用村组信息列表
export function TownshipPage(data: Object) {
    return request({
        url: '/creditAssessTownship/page',
        method: 'POST',
        data: data
    })
}
// 提交信用村信息
export function TownshipSubmit(data: Object) {
    return request({
        url: '/creditAssessTownship/submit',
        method: 'POST',
        data: data
    })
}
// 列表是否前端展示
export function TownshipEditShow(data: Object) {
    return request({
        url: '/creditAssessTownship/editShow',
        method: 'POST',
        data: data
    })
}
//---------------- 信用县
export function CountyDetail(data: Object) {
    return request({
        url: '/creditAssessCounty/detail',
        method: 'POST',
        data: data
    })
}
// 信用村组信息列表
export function CountyPage(data: Object) {
    return request({
        url: '/creditAssessCounty/page',
        method: 'POST',
        data: data
    })
}
// 提交信用村信息
export function CountySubmit(data: Object) {
    return request({
        url: '/creditAssessCounty/submit',
        method: 'POST',
        data: data
    })
}
// 列表是否前端展示
export function CountyEditShow(data: Object) {
    return request({
        url: '/creditAssessCounty/editShow',
        method: 'POST',
        data: data
    })
}

// ------------我的审批-----------------
// 审批列表
export function creditApprovalList(data: Object) {
    return request({
        url: '/creditAssessApproval/page',
        method: 'POST',
        data: data
    })
}

// 审批提交
export function creditApprovalSubmit(data: Object) {
    return request({
        url: '/creditAssessApproval/approval',
        method: 'POST',
        data: data
    })
}
//申请摘牌
export function cancelvillage(data: Object) {
    return request({
        url: '/creditAssessApproval/cancel',
        method: 'POST',
        data: data
    })
}

// ------------数据统计-----------------
// 数据统计区域列表
export function dataStatisticsArea() {
    return request({
        url: '/villageManage/back/userOwnCounty',
        method: 'POST'
    })
}

// 获取数据统计列表
export function dataStatisticsList(data: Object) {
    return request({
        url: '/villageManage/back/creditStatistic',
        method: 'POST',
        data
    })
}

// ------------信用标准设置-----------------
// 信用标准详情
export function standardDetail() {
    return request({
        url: '/creditStandardConfig/detail',
        method: 'POST',
        data: {}
    })
}

// 信用标准添加
export function standardAdd(data: any) {
    return request({
        url: '/creditStandardConfig/submit',
        method: 'POST',
        data
    })
}