
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { PersonnelForm } from '@/views/type'
import importTemplateBtn from '@/components/uploadExcels/index.vue'
// 接口联调
// import { getProjectList, deleteProject } from '@/api/noticeManagement'
// import timeFormat from '@/utils/timeFormat'
// import {
//   addCompanyBankBusiness,
//   getCompanyBankBusinessList,
//   deleteCompanyBankBusiness,
//
// } from '@/api/center'
import { checkBankCardInfo, importTemplate } from '@/api/center'
import numsTips from '@/components/numsTips/index.vue'
import { localStore } from '../../../utils/data-management'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    importTemplateBtn
  },
  filters: {
    filterStatus: (status: any) => {
      if (status === 1) {
        return '政府部门'
      } else if (status === 2) {
        return '金融机构'
      } else {
        return '无数据'
      }
    },
    filterTimeStamp(timestamp: number) {
      if (timestamp === null) {
        return
      }
      let data: any = null
      let date: any = null
      if (typeof timestamp === 'number') {
        data = timestamp + ''
        if (data.length === 10) {
          date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        } else if (data.length === 13) {
          date = new Date(timestamp)
        }

        let Y = date.getFullYear() + '-'
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        let s = date.getSeconds()
        return Y + M + D + h + m + s
      }
    }
  }
  //  if (time) return timeFormat(new Date(time))
})
export default class ImportBankDataDetail extends Vue {
  private loadingTxt: string = loadingText
  private disabled: boolean = false
  private businessItem: any = [
    { label: '实时付款', value: 1 },
    { label: '实时收款', value: 2 },
    { label: '批量代付', value: 3 },
    { label: '批量代收', value: 4 }
  ]
  private formData: any = {
    bankCardId: '',
    bankCard: '',
    bankName: ''
  }
  private rules: any = {
    bankName: { required: true, message: '请选择开户行', trigger: 'blur' },
    bankCard: { required: true, message: '请选择账户', trigger: 'blur' }
  }
  private uploadOption: any = {
    limit: 1,
    accept: '.xls,.xlsx',
    // listType: 'picture-card'
    btnText: '导入模板'
    // tip: '请上传pdf格式文件',
  }
  private listShow: boolean = true
  private downloadUrl: string = './static/excel/银行业务类型.xls'
  private fileUrl: string = ''
  private bankList: Array<any> = []
  private bankNameArr: Array<any> = []
  private bankAccountArr: Array<any> = []

  private created(): void {
    // if (this.$route.params.flag === 'check' || localStore.get('dt').flag === 'check') {
    //   localStore.set('detail', this.$route.params)
    //   // this.handleDetail()
    //   this.formData = this.$route.params.datas
    // }
    this.getBankList()
  }

  private mounted(): void {
    this.getBankList()
  }
  // ---------------------------------------------------自定义函数-----------------start-----------------------------------------------------------------------
  // 开户行下拉数据
  private selectedBankName(data: any) {
    let arr = this.bankList.filter((item, index) => {
      return item.bankName === data
    })
    let accountArr: Array<any> = []
    arr.forEach((item: any, index: any) => {
      accountArr.push({ bankCard: item.bankCard, key: index, id: item.id })
    })
    this.bankAccountArr = accountArr
  }
  // 账号下拉数据
  private selectedBankAccount(data: any) {
    this.formData.bankCardId = data
  }
  // 获取银行列表
  private getBankList(): void {
    let data: any = {
      type: 2
    }
    checkBankCardInfo(data)
      .then((res) => {
        let dataList: Array<any> = res.data.data
        let bankNameArr: Array<any> = []
        let bankAccountArr: Array<any> = []
        dataList.forEach((item: any, index: any) => {
          bankNameArr.push(item.bankName)
        })
        let arr = Array.from(new Set(bankNameArr))
        let bankNameArrs: Array<any> = []
        arr.forEach((item: any, index: any) => {
          bankNameArrs.push({ bankName: item, key: index })
        })
        this.bankList = res.data.data
        this.bankNameArr = bankNameArrs
      })
      .catch((err) => {})
  }

  private importData(data: any): void {
    this.fileUrl = data.url
  }

  // 返回
  private handleReturn(): void {
    this.$router.go(-1)
  }
  // 提交
  private handleFormData(): void {
    if (this.formData.bankName === '') {
      this.$message.warning('请选择开户行')
      return
    } else if (this.formData.bankCard === '') {
      this.$message.warning('请选择账户')
      return
    }

    let data: any = {
        file: this.fileUrl,
        bankCardId: this.formData.bankCardId
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    importTemplate(data)
      .then((res) => {
        this.$router.push({
          name: 'CompanyBankManagement'
        })
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
}
