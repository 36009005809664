import { render, staticRenderFns } from "./rating-standard.vue?vue&type=template&id=6f67c396&scoped=true&"
import script from "./rating-standard.vue?vue&type=script&lang=ts&"
export * from "./rating-standard.vue?vue&type=script&lang=ts&"
import style0 from "./rating-standard.vue?vue&type=style&index=0&id=6f67c396&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f67c396",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f67c396')) {
      api.createRecord('6f67c396', component.options)
    } else {
      api.reload('6f67c396', component.options)
    }
    module.hot.accept("./rating-standard.vue?vue&type=template&id=6f67c396&scoped=true&", function () {
      api.rerender('6f67c396', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/enterprise-credit-rating/enterprise-financial-rating/components/rating-standard.vue"
export default component.exports