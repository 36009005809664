
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class proInfoPerson extends Vue {
  // 展示数据
  @Prop() info: any
  // 状态
  @Prop() detailtype: any
  // 是否二次审批标志位
  @Prop({ default: 'false' }) isSecond: any
  // 标题
  @Prop({ default: '', type: String }) private title!: String
  // 数据类型
  @Prop({ default: '', type: String }) private flag!: String
  // 是否返回改变
  @Prop({ default: false }) refuse?: boolean
  // 展示红色字体与否
  @Prop({ default: true }) isShowRed?: boolean
  // 公开开关标志位
  @Prop({ default: false }) openShow?: boolean
  private infoFiles: any = []
  // 选择框标志位
  private checkboxShow: boolean = false
  // 是否首次加载info
  private isFirst: boolean = true
  // 红色标题标志位
  private redTitle: boolean = true
  private fileL1: any = []
  private fileL2: any = []
  // 选择框状态
  private itemStatus: any = {
    enterpriseName: false,
    registAddress: false,
    legalPerson: false,
    contactPhone: false,
    contactAddress: false,
    contactMember: false,
    introduction: false,
    creditCode: false,
    configKeyIndustry: false,
    configKeyIndustryType: false
  }
  // 选择框展示
  private itemShow: any = {
    enterpriseName: true,
    registAddress: true,
    legalPerson: true,
    contactPhone: true,
    contactAddress: true,
    contactMember: true,
    introduction: true,
    creditCode: true,
    configKeyIndustry: true,
    configKeyIndustryType: true
  }
  @Watch('info', { immediate: true })
  infoWatch(newValue: any) {
    if (newValue && this.isFirst) {
      this.isFirst = false
      let nameShow = false
      let fileShow = false
      if (this.flag != 'guarantors') {
        let nowFilesL = newValue.files
        for (let i = 0; i < nowFilesL.length; i++) {
          if (!this.fileL1[0] && nowFilesL[i].process != 3 && nowFilesL[i].fileName) {
            this.$set(this.fileL1, 0, true)
          } else if (!this.fileL2[0] && nowFilesL[i].process == 3 && nowFilesL[i].fileName) {
            this.$set(this.fileL2, 0, true)
          }
        }
      } else {
        for (let i = 0; i < newValue.length; i++) {
          let nowFilesL = newValue[i].files
          for (let l = 0; l < nowFilesL.length; l++) {
            if (!this.fileL1[i] && nowFilesL[l].process != 3 && nowFilesL[l].fileName) {
              this.$set(this.fileL1, i, true)
            } else if (!this.fileL2[i] && nowFilesL[l].process == 3 && nowFilesL[l].fileName) {
              this.$set(this.fileL2, i, true)
            }
          }
        }
      }
      if (this.detailtype == 1 || this.detailtype == 2 || this.detailtype == 5 || this.detailtype == 6) {
        if (this.flag != 'guarantors') {
          let files = this.info.files
          for (let i = 0; i < files.length; i++) {
            let nowInfoFiles = {
              fileId: files[i].fileId,
              fileStatus: false,
              fileType: files[i].fileType,
              responserRelId: files[i].responserRelId
            }
            if (this.isSecond == 'true' && !files[i].fileStatus) {
              if (this.detailtype == 1 || (this.detailtype == 2 && files[i].process == 3)) {
                fileShow = true
              }
            }
            this.infoFiles.push(nowInfoFiles)
          }
        } else {
          for (let i = 0; i < this.info.length; i++) {
            let nowInfo = this.info[i]
            let nowArry = []
            for (let l = 0; l < nowInfo.files.length; l++) {
              let nowInfoFiles = {
                fileId: nowInfo.files[l].fileId,
                fileStatus: false,
                fileType: nowInfo.files[l].fileType,
                responserRelId: nowInfo.files[l].responserRelId
              }
              if (this.isSecond == 'true' && !nowInfo.files[l].fileStatus) {
                if (this.detailtype == 1 || (this.detailtype == 2 && nowInfo.files[l].process == 3)) {
                  fileShow = true
                }
              }
              nowArry.push(nowInfoFiles)
            }
            this.infoFiles.push(nowArry)
          }
        }
      }
      if (((this.detailtype == 1 && this.isSecond == 'true') || this.detailtype == 0) && this.flag != 'guarantors') {
        let fieldList = this.info.fieldList
        for (let i = 0; i < fieldList.length; i++) {
          if (fieldList[i].status) {
            this.$set(this.itemShow, fieldList[i].fieldName, false)
          } else {
            nameShow = true
          }
        }
      }
      if ((this.detailtype == 1 || this.detailtype == 5 || this.detailtype == 0) && this.flag == 'guarantors') {
        this.itemStatus = []
        this.itemShow = []
        if ((this.detailtype == 1 && this.isSecond == 'true') || this.detailtype == 0) {
          for (let i = 0; i < this.info.length; i++) {
            let fieldList = this.info[i].fieldList
            let nowItemShow: any = {}
            for (let i = 0; i < fieldList.length; i++) {
              if (!fieldList[i].status) {
                nameShow = true
              }
              nowItemShow[fieldList[i].fieldName] = !fieldList[i].status
            }
            this.itemStatus.push({
              enterpriseName: false,
              registAddress: false,
              legalPerson: false,
              contactPhone: false,
              contactAddress: false,
              contactMember: false,
              identityCode: false,
              creditCode: false,
              configKeyIndustry: false,
              configKeyIndustryType: false
            })
            this.itemShow.push(nowItemShow)
          }
        } else {
          for (let i = 0; i < this.info.length; i++) {
            this.itemStatus.push({
              enterpriseName: false,
              registAddress: false,
              legalPerson: false,
              contactPhone: false,
              contactAddress: false,
              contactMember: false,
              identityCode: false,
              creditCode: false,
              configKeyIndustry: false,
              configKeyIndustryType: false
            })
            this.itemShow.push({
              enterpriseName: true,
              registAddress: true,
              legalPerson: true,
              contactPhone: true,
              contactAddress: true,
              contactMember: true,
              identityCode: true,
              creditCode: true,
              configKeyIndustry: true,
              configKeyIndustryType: true
            })
          }
        }
      }
      if ((this.detailtype == 1 || this.detailtype == 2) && this.isSecond == 'true') {
        if (!nameShow && !fileShow) {
          this.redTitle = false
        }
      }
    }
  }
  @Watch('refuse')
  handler(newValue: any) {
    if (newValue) {
      this.$emit('addRefuseItem', this.flag, this.itemStatus, this.infoFiles)
    }
  }
  created() {
    if (this.detailtype == 1 || this.detailtype == 2 || this.detailtype == 5 || this.detailtype == 6) {
      this.checkboxShow = true
    }
  }
  // 公开开关change方法
  private openChange(addTitle: string, obj: any, dfRelId: any) {
    this.$emit('openChange', this.title + addTitle, obj, dfRelId)
  }
}
