var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pubUpload" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.uploadFileUrl,
            accept: _vm.accept,
            limit: _vm.limit,
            "list-type": _vm.listType,
            "on-exceed": _vm.handleExceed,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleUploadSuccess,
            "on-error": _vm.handleUploadError,
            "before-upload": _vm.beforeUpload,
            "show-file-list": _vm.listShow
          }
        },
        [
          _c(
            "el-button",
            { staticClass: "global-dotted-btn", attrs: { size: "small" } },
            [_vm._v(_vm._s(_vm.btnText))]
          ),
          _vm.tip
            ? _c("p", { attrs: { slot: "tip" }, slot: "tip" }, [
                _vm._v(_vm._s(_vm.tip))
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }