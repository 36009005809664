import { render, staticRenderFns } from "./approval-info.vue?vue&type=template&id=6d59cd84&scoped=true&"
import script from "./approval-info.vue?vue&type=script&lang=ts&"
export * from "./approval-info.vue?vue&type=script&lang=ts&"
import style0 from "./approval-info.vue?vue&type=style&index=0&id=6d59cd84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d59cd84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d59cd84')) {
      api.createRecord('6d59cd84', component.options)
    } else {
      api.reload('6d59cd84', component.options)
    }
    module.hot.accept("./approval-info.vue?vue&type=template&id=6d59cd84&scoped=true&", function () {
      api.rerender('6d59cd84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/countryside-property-transaction/components/approval-info.vue"
export default component.exports