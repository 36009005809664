
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import { getMyApprovalList, getBusinessTypeList, getBankAndCost } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class MyApprovalList extends Vue {
  private loadingTxt: string = loadingText
  private businessOptions: any = []
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    businessType: '',
    name: '',
    applyPerson: '',
    ruleType: '',
    processStatus:''
  }
  private ruleTypeOptions: any = [
    { label: '挂牌审批', value: '7' },
    { label: '中止挂牌审批', value: '8' },
    { label: '保证金退款审批', value: '9' },
    { label: '清算审批', value: '12' },
    { label: '资格审批', value: '16' }
  ]
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      getBankAndCost()
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'MyApprovalDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
        vim.time = pageParams.time
      }
      if (!vim.businessOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessOptions = res.data.data
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'MyApprovalDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        businessOptions: this.businessOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    // console.log(this.formInfo)
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }

    // console.log(params);

    // Object.keys(params).forEach((key) => {
    //   !params[key] && delete params[key]
    // })
    getMyApprovalList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    let params: any = {
      approvalId: row.approvalId,
      ruleType: row.ruleType,
      processStatus:row.processStatus
    }
    console.log(row);

    if (row.ruleType == 12 || row.ruleType == 9) {
      params.id = row.directionalFinancingId
    }
    this.$router.push({
      name: 'MyApprovalDetail',
      query:params
    })
  }
}
