
import { Component, Vue } from 'vue-property-decorator'
import formDialog from './component/formDialogs.vue'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
import { checkBankCardInfo, deleteBankCardInfo } from '@/api/center'
import bankCardImg from '@/assets/images/login_bg.png'
import ChinaBankCardImg from '@/assets/images/zh_icon.png'
@Component({
  components: {
    formDialog
  },
  filters: {
    filtersBussinessType(data: any) {
      switch (data) {
        case 1:
          return '实时付款'
          break
        case 2:
          return '实时收款'
          break
        case 3:
          return '批量代付'
          break
        case 4:
          return '批量代收'
          break
      }
    },
    filterString(data: any) {
      return data.substring(data.length - 4)
    }
  }
})
export default class BankCard extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private loadingInstance: any
  private rules: any = {}
  private formData: any = {}
  private formDatas: any = {}
  private formDatass: any = []
  private dialogTableVisible: boolean = false
  private institutionNumber = ''
  private nodeNumber = ''
  private isShowForm: boolean = false
  private flag: any = ''
  private bankCardList: any = []
  private isOrModalClose: boolean = false
  private center: boolean = true
  private title: string = ''
  // -------------------------------------------------data-----end---------------------------------------
  private created() {
    this.loadingInstance = Loading
    this.handleBankCardInfo()
  }
  // ------------------------------------vue--周期方法------------------end------------------------------

  private openFormDialog() {
    this.dialogTableVisible = true
    this.$nextTick(() => {
      this.flag = 'add'
      this.formDatas = {}
      this.formDatass = [
        {
          bussinessType: '',
          feeItem: '',
          feeCode: '',
          contractCode: '',
          feeName: ''
        }
      ]
    })
  }
  private modifyDialog() {}
  private getBankCardInfo(data: any, index: any) {
    this.dialogTableVisible = true
    // this.$nextTick(() => {
    this.flag = 'edit'
    //  (this as ref)
    let datas: any = JSON.parse(JSON.stringify(data))
    delete datas.bankCardBussinessRels
    this.formDatas = datas
    this.formDatass = data.bankCardBussinessRels
    // })
  }
  private bankCardImg = bankCardImg
  private chinaBankCardImg = ChinaBankCardImg
  private handleCancelDialog() {
    this.dialogTableVisible = false
    this.handleBankCardInfo()
  }
  private handleCommand(id: any) {
    let data: object = {
        id
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    deleteBankCardInfo(data)
      .then((res) => {
        this.handleBankCardInfo()
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private handleBankCardInfo() {
    this.loading = true
    let data: any = {
      type: 1
    }
    checkBankCardInfo(data)
      .then((res) => {
        this.bankCardList = res.data.data
      })
      .finally(() => {
        this.loading = false
      })
  }
}
