import { render, staticRenderFns } from "./index.vue?vue&type=template&id=475f10f9&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=475f10f9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475f10f9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('475f10f9')) {
      api.createRecord('475f10f9', component.options)
    } else {
      api.reload('475f10f9', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=475f10f9&scoped=true&", function () {
      api.rerender('475f10f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/regional-credit-management/my-jurisdiction/index.vue"
export default component.exports