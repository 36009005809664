var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "approval-info" }, [
    _c("div", { staticClass: "part first" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            { staticClass: "item", attrs: { size: "small" } },
            [
              _c("el-form-item", { attrs: { label: "结算审批" } }, [
                _vm.isApprovaling && _vm.info && _vm.info.approvalPersonInfos
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-timeline",
                          _vm._l(_vm.info.approvalPersonInfos, function(
                            item,
                            index
                          ) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  icon: _vm.icon[item.status],
                                  type: _vm.iconType[item.status],
                                  size: "large",
                                  hideTimestamp: true
                                }
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "min-width": "100px",
                                        display: "inline-block"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  item.status != 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.approvalTime))
                                      ])
                                    : _vm._e(),
                                  item.status == 1
                                    ? _c("span", { staticClass: "succ" }, [
                                        _vm._v("同意")
                                      ])
                                    : _vm._e(),
                                  item.status == 2
                                    ? _c("span", { staticClass: "err" }, [
                                        _vm._v("拒绝")
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : !_vm.isApprovaling && _vm.info
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.info.approvalTime))]),
                      _vm.info.status
                        ? _c("span", { staticClass: "succ" }, [_vm._v("同意")])
                        : _vm._e(),
                      !_vm.info.status
                        ? _c("span", { staticClass: "err" }, [_vm._v("拒绝")])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "审批意见" } },
                [
                  _vm.isApprovaling && _vm.info
                    ? _c("el-input", {
                        staticClass: "area",
                        attrs: { type: "textarea", autosize: { minRows: 6 } },
                        model: {
                          value: _vm.info.opinion,
                          callback: function($$v) {
                            _vm.$set(_vm.info, "opinion", $$v)
                          },
                          expression: "info.opinion"
                        }
                      })
                    : !_vm.isApprovaling && _vm.info
                    ? _c("span", [_vm._v(_vm._s(_vm.info.opinion))])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("审批信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }