
import { Component, Vue } from 'vue-property-decorator'
import loginBgImg from '@/assets/images/admin/loginBgImg.png'
import { singleLogin, singleImgVerity } from '@/api/login'
import { localStore, sessionStore } from '../utils/data-management'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class ZqtLogin extends Vue {
  private loadingTxt: string = loadingText
  private smallLoad: boolean = false
  private images: Object = {
    loginBgImgs: loginBgImg
  }

  private checked: boolean = false
  private imgVerity: any = {}
  private formData: any = {
    phone: '',
    password: '',
    verify: ''
  }
  // 生命周期---监听等vue方法-----------------------------------------------------------

  private created() {
    this.getImgVerity()
  }

  // ----------------------------自定义函数--------------------------------------------
  private tabLogin() {
    this.$router.push({ name: 'Login' })
  }
  private tabRegister() {
    this.$router.push({ name: 'Register' })
  }
  private getImgVerity() {
    this.smallLoad = true
    singleImgVerity({})
      .then((res) => {
        this.imgVerity = res.data.data
      })
      .finally(() => {
        this.smallLoad = false
      })
  }
  private tabForget() {
    this.$router.push({ name: 'ForgetPwd' })
  }
  private handleLogin() {
    if (this.checked) {
      //传入账号名，密码，和保存天数3个参数
      this.setCookie(this.formData.phone, this.formData.password, 7)
    } else {
      //清空Cookie
      this.clearCookie()
    }
    //与后端请求代码，本功能不需要与后台交互所以省略
    if (!this.formData.phone) {
      this.$message.warning('手机号码不能为空')
      return
    }
    if (!this.formData.password) {
      this.$message.warning('密码不能为空')
      return
    }
    /*if (!this.formData.verify) {
      this.$message.warning('验证码不能为空')
      return
    }*/
    let systemType: any = process.env.VUE_APP_SYSTEM_TYPE,
      data: any = {
        account: this.formData.phone,
        password: this.formData.password,
        captcha: this.formData.verify,
        uuid: this.imgVerity.validCode,
        isAdmin: '1',
        system: systemType == 'rural' ? 'ruralBg' : 'zctBg',
        app: 'pc'
      }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    singleLogin(data)
      .then((res) => {
        this.$store.state.routeArr == '1' && this.$store.commit('CHANGE_ROUTEARR', '0')
        this.$store.state.routeDatass && this.$store.commit({ type: 'ROUTE_UPDATE', datas: [] })
        localStore.get('routes') && localStore.remove('routes')
        this.$router.push({ name: 'Home', params: { bID: 'LOGIN001' } })
        localStore.set('zctToken', res.data.data)
        sessionStore.set('userPhone', this.formData.phone)
      })
      .catch((err) => {
        // this.formData.verify = ''
        // this.handleLogin()
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }

  // 强制刷新
  // private handleChang(e:DragEvent){
  //   this.$forceUpdate()
  // }
  mounted() {
    this.getCookie()
    this.checked = localStore.get('checked')
  }
  private remenberpassword(data: any) {
    localStore.set('checked', this.checked)
    if (!data) {
      this.clearCookie()
    }
  }
  // submitForm(formName: any) {
  //   //判断复选框是否被勾选 勾选则调用配置cookie方法

  // }
  //设置cookie
  setCookie(phone: string, pwd: string, exdays: any) {
    let exdate = new Date() //获取时间
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) //保存的天数
    //字符串拼接cookie
    window.document.cookie = 'userName' + '=' + phone + ';path=/;expires=' + exdate.toUTCString()
    window.document.cookie = 'userPwd' + '=' + pwd + ';path=/;expires=' + exdate.toUTCString()
  }
  //读取cookie
  getCookie() {
    if (document.cookie.length > 0) {
      let arr = document.cookie.split('; ') //这里显示的格式需要切割一下自己可输出看下
      for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=') //再次切割
        //判断查找相对应的值
        if (arr2[0] == 'userName') {
          this.formData.phone = arr2[1] //保存到保存数据的地方
        } else if (arr2[0] == 'userPwd') {
          this.formData.password = arr2[1]
        }
      }
    }
  }
  //清除cookie
  clearCookie() {
    this.setCookie('', '', -1) //修改2值都为空，天数为负1天就好了
  }
}
