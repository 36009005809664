
import { Component, Vue } from 'vue-property-decorator'
import settlementStatus from '../components/settlementStatus.vue'
import aboutInfo from '../components/aboutInfo.vue'
import settlementInfo from '../components/settlementInfo.vue'
import applicationMaterials from '../components/applicationMaterials.vue'
import approvalInfo from '../components/approvalInfo.vue'
import paymentInfo from '../components/paymentInfo.vue'
import timeFormat from '@/utils/timeFormat'
// import numsTips from '@/components/numsTips/index.vue'
// import Pagination from '@/components/Pagination/index.vue'
import { getSettlementDetails } from '@/api/settlementBusiness'
import { transactionList } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
const tradeStatusList = [
  {
    label: '审批中',
    value: '0'
  },
  {
    label: '银行处理中',
    value: '1'
  },
  {
    label: '部分成功',
    value: '2'
  },
  {
    label: '全部成功',
    value: '3'
  },
  {
    label: '交易失败',
    value: '4'
  },
  {
    label: '审批驳回',
    value: '5'
  },
  {
    label: '已撤销',
    value: '6'
  }
]
@Component({
  components: {
    settlementStatus,
    aboutInfo,
    settlementInfo,
    applicationMaterials,
    approvalInfo,
    paymentInfo
    // numsTips,
    // Pagination
  },
  filters: {
    filterTrade(val: number) {
      if (val === undefined || val === null) return
      const value = val.toString()
      return tradeStatusList.find((v) => v.value == value)?.label
    },
    formatTime: (time: any) => {
      if (time) return timeFormat(new Date(time))
    }
  }
})
export default class SettlementDetails extends Vue {
  private loadingTxt: string = loadingText
  private loading: boolean = true
  private nowId: any = ''
  private activeName: any = '1' // 当前 tab 选项
  private status: any
  private proDetail: any = {}
  private tableLoading: boolean = false
  private columnData: any = [
    { label: '交易编号', prop: 'tradeNo' },
    { label: '服务类型', prop: 'type' },
    { label: '用途', prop: 'resultMsg' },
    { label: '发起时间', prop: 'createdTime' },
    { label: '交易发起人', prop: 'userName' },
    { label: '发起人部门', prop: 'userDept' },
    { label: '交易金额', prop: 'amount' },
    { label: '付款账号', prop: 'payBankNumber' },
    { label: '收款账号', prop: 'receiveBankNumber' },
    { label: '交易状态', prop: 'tradeState' }
  ]
  // private paginationParam: any = {
  //   pageIndex: 1,
  //   pageSize: 10,
  //   total: 0
  // }
  private transaction: any = []
  private filterSettlementPay(obj: any) {
    if (obj.settlementPaymentInfo && obj.settlementPaymentInfo.length) {
      let firestItem: any = null,
        lastItem: any = null
      for (let i = 0; i < obj.settlementPaymentInfo.length; i++) {
        if (obj.settlementPaymentInfo[i] && obj.settlementPaymentInfo[i].type == 1) {
          firestItem = obj.settlementPaymentInfo[i]
        } else if (obj.settlementPaymentInfo[i] && obj.settlementPaymentInfo[i].type == 2) {
          lastItem = obj.settlementPaymentInfo[i]
        }
      }
      obj.settlementPaymentInfo = [firestItem, lastItem]
    }
  }
  created() {
    let params: any = this.$route.params
    if (params.id) {
      sessionStorage.setItem('settlementDetails', JSON.stringify(params))
    } else {
      params = JSON.parse(sessionStorage.getItem('settlementDetails') as any)
    }
    this.nowId = params.id
    this.status = params.status
    getSettlementDetails({ id: this.nowId })
      .then((res) => {
        let datas: any = res.data.data
        if (datas) {
          this.filterSettlementPay(datas)
          this.proDetail = datas
          this.status >= 3 && this.query()
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name != 'TradeDetails') {
      sessionStorage.removeItem('settlementDetails')
    }
    next()
  }
  private query() {
    this.tableLoading = true
    transactionList({ settlementApplyId: this.nowId })
      .then((res) => {
        if (res.data.data) {
          this.transaction = res.data.data
        }
      })
      .finally(() => {
        this.tableLoading = false
      })
  }
  private transactionInfo(rowData: any) {
    this.$router.push({ name: 'TradeDetails', params: { id: rowData.id, tradeState: rowData.tradeState } })
  }
}
