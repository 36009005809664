
import { Component, Vue } from 'vue-property-decorator'
import { getMenuList, getMenuDetails, addMenu, updateMenu, deleteMenu } from '@/api/menuConfig'
import { Loading } from 'element-ui'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {}
})
export default class MenuConfig extends Vue {
  private loadingTxt: string = loadingText
  private nowId: any = ''
  // 列表加载中标志
  private loadin: boolean = false
  // 树数据
  private dataTree: any = []
  // 弹窗类型
  private markType: string = ''
  // Options是否改变
  private isChangeOp: boolean = true
  private markShow: boolean = false // 弹窗显示标志位
  // 弹窗数据
  private markInfo: any = {
    pid: '',
    type: '',
    sort: '',
    name: '',
    path: '',
    routeIdentify: '',
    menuId: '',
    icon: '',
    isDefault: '0'
  }
  // 旧的弹窗数据
  private oldMarkInfo: any = {}
  // 类型参数
  private typeOptions: any = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    }
  ]
  // 父节点参数
  private fatherOptions: any = [
    {
      label: '根目录',
      value: '-1'
    }
  ]
  // 是否默认参数
  private isDefaultOptions: any = [
    {
      label: '否',
      value: '0'
    },
    {
      label: '是',
      value: '1'
    }
  ]
  created() {
    this.hadnleAllRouter()
  }
  // 获取所有角色菜单
  private hadnleAllRouter() {
    this.loadin = true
    getMenuList()
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          let newData = res.data.data
          this.dataTree = newData
        }
      })
      .finally(() => {
        this.loadin = false
      })
  }
  // 验证参数
  private verificationParams() {
    let returnVal = true
    if (!this.markInfo.name.trim()) {
      this.$message.error('菜单名称为必填项!!')
      returnVal = false
    } else if (!this.markInfo.type) {
      this.$message.error('类型为必填项!!')
      returnVal = false
    } else if (!this.markInfo.path.trim()) {
      this.$message.error('路径为必填项!!')
      returnVal = false
    } else if (!this.markInfo.pid) {
      this.$message.error('父节点为必填项!!')
      returnVal = false
    } else if (!this.markInfo.routeIdentify.trim()) {
      this.$message.error('路由标识为必填项!!')
      returnVal = false
    }
    return returnVal
  }
  // 判断相关值重复与否
  private judgeRepeat(nowJudgeBase: any, filteVal: any = null) {
    let nowJudgeObj: any = this.markInfo,
      isRepeat: boolean = false
    for (let i = 0; i < nowJudgeBase.length; i++) {
      if (nowJudgeBase[i].path == this.markInfo.path) {
        if (!filteVal || (filteVal && filteVal.path != nowJudgeBase[i].path)) {
          this.$message.error('此路径已经存在')
          isRepeat = true
          break
        }
      } else if (nowJudgeBase[i].routeIdentify == this.markInfo.routeIdentify) {
        if (!filteVal || (filteVal && filteVal.routeIdentify != nowJudgeBase[i].routeIdentify)) {
          this.$message.error('此路由标识已经存在')
          isRepeat = true
          break
        }
      }
      let childrenRepeat: boolean = false
      nowJudgeBase[i].children && nowJudgeBase[i].children.length && (childrenRepeat = this.judgeRepeat(nowJudgeBase[i].children, filteVal))
      if (childrenRepeat) {
        isRepeat = true
        break
      }
    }
    return isRepeat
  }
  // 获取下拉参数
  private getOptions() {
    if (this.isChangeOp) {
      this.isChangeOp = false
      this.fatherOptions = []
      this.fatherOptions.push({
        label: '根目录',
        value: '-1'
      })
      getMenuList().then((res) => {
        if (res.data && res.data.code == 1000) {
          let newData = res.data.data
          for (let i = 0; i < newData.length; i++) {
            this.fatherOptions.push({
              label: newData[i].name,
              value: newData[i].menuId
            })
          }
        }
      })
    }
  }
  private addFn() {
    this.getOptions()
    this.markType = 'add'
    this.markShow = true
  }
  private eitdFn() {
    if (!this.nowId) {
      this.$message.error('请选择编辑项')
      return
    }
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    this.getOptions()
    getMenuDetails({
      menuId: this.nowId
    })
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          Object.keys(this.markInfo).forEach((key) => {
            key == 'isDefault' && (this.markInfo[key] = res.data.data[key] + '' ? res.data.data[key] + '' : '0')
            key != 'isDefault' && (this.markInfo[key] = res.data.data[key] ? res.data.data[key] : '')
            this.oldMarkInfo[key] = this.markInfo[key]
          })
          this.markType = 'update'
          this.markShow = true
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  private deletedInfo() {
    if (!this.nowId) {
      this.$message.error('请选择删除项')
      return
    }
    this.$confirm('确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.deletedFn()
      })
      .catch(() => {})
  }
  private deletedFn() {
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    deleteMenu({
      menuId: this.nowId
    })
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          this.$message.success('删除成功')
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.nowId = ''
            this.isChangeOp = true
            loadingInstance.close()
            this.hadnleAllRouter()
          })
        } else {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        }
      })
      .catch(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 关闭弹窗
  private closeFn() {
    this.markShow = false
    this.markInfo = {
      pid: '',
      type: '',
      sort: '',
      name: '',
      path: '',
      routeIdentify: '',
      menuId: '',
      icon: '',
      isDefault: '0'
    }
  }
  // 弹窗确认按钮
  private confirm(type: string) {
    if (!this.verificationParams()) {
      return
    }
    this.markShow = false
    let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (this.judgeRepeat(this.dataTree, type == 'update' ? this.oldMarkInfo : null)) {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
        this.markShow = true
      })
      return
    }
    let params: any = {
      ...this.markInfo
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    if (type == 'add') {
      addMenu(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message.success('添加成功')
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              this.isChangeOp = true
              loadingInstance.close()
              this.hadnleAllRouter()
            })
          } else {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          }
        })
        .catch(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
        .finally(() => {
          this.markInfo = {
            pid: '',
            type: '',
            sort: '',
            name: '',
            path: '',
            routeIdentify: '',
            menuId: '',
            icon: '',
            isDefault: '0'
          }
        })
    } else if (type == 'update') {
      updateMenu(params)
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.$message.success('编辑成功')
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              this.isChangeOp = true
              loadingInstance.close()
              this.hadnleAllRouter()
            })
          } else {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          }
        })
        .catch(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
        })
        .finally(() => {
          this.markInfo = {
            pid: '',
            type: '',
            sort: '',
            name: '',
            path: '',
            routeIdentify: '',
            menuId: '',
            icon: '',
            isDefault: '0'
          }
        })
    }
  }
}
