var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-form",
        {
          staticClass: "formStyle",
          attrs: { size: "small", "label-width": "110px" }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "inputBox", attrs: { label: "角色名：" } },
            [
              _c("el-input", {
                staticClass: "inputItem",
                attrs: { size: "small", placeholder: "请输入角色名" },
                model: {
                  value: _vm.roleName,
                  callback: function($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单权限：" } },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.dataTree,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": ""
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "inputBox", attrs: { label: "角色权限：" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.checkList,
                    callback: function($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList"
                  }
                },
                _vm._l(_vm.allPermissions, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.permissionId } },
                    [_vm._v(_vm._s(item.name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.code != "common-finance-user" &&
          _vm.code != "common-government-user" &&
          _vm.systemType != "zct"
            ? _c(
                "el-form-item",
                { attrs: { label: "角色所属区域：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isSuperAdmin != "1",
                        placeholder: "请选择",
                        size: "small"
                      },
                      model: {
                        value: _vm.areaId,
                        callback: function($$v) {
                          _vm.areaId = $$v
                        },
                        expression: "areaId"
                      }
                    },
                    _vm._l(_vm.areaOptions, function(item) {
                      return _c("el-option", {
                        key: item.areaConfigId,
                        attrs: {
                          label: item.areaConfigName,
                          value: item.areaConfigId,
                          disabled: item.isDelete || item.isDisable
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.code != "common-finance-user" &&
          _vm.code != "common-government-user"
            ? _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                      _c("el-radio", { attrs: { label: -1 } }, [_vm._v("禁用")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-button", { on: { click: _vm.handleReturn } }, [
            _vm._v("返回")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }