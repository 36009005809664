var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "project-notice" },
    [
      _c(
        "el-form",
        { attrs: { inline: "", model: _vm.formInfo, size: "mini" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formInfo.role,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "role", $$v)
                        },
                        expression: "formInfo.role"
                      }
                    },
                    _vm._l(_vm.roleList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文件名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入文件名称" },
                    model: {
                      value: _vm.formInfo.fileName,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "fileName", $$v)
                      },
                      expression: "formInfo.fileName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "文件唯一标识：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入文件唯一标识" },
                    model: {
                      value: _vm.formInfo.fileType,
                      callback: function($$v) {
                        _vm.$set(_vm.formInfo, "fileType", $$v)
                      },
                      expression: "formInfo.fileType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生成节点：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formInfo.generatePosition,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "generatePosition", $$v)
                        },
                        expression: "formInfo.generatePosition"
                      }
                    },
                    _vm._l(_vm.generatePositionList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formInfo.listingMode,
                        callback: function($$v) {
                          _vm.$set(_vm.formInfo, "listingMode", $$v)
                        },
                        expression: "formInfo.listingMode"
                      }
                    },
                    _vm._l(_vm.listingModeList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small" },
                      on: { click: _vm.query }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small" },
                      on: { click: _vm.resetFn }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "global-dotted-btn vertical-bottom",
                      attrs: { size: "small", icon: "el-icon-plus" },
                      on: { click: _vm.toAddPage }
                    },
                    [_vm._v("新增文档")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("nums-tips", { attrs: { nums: _vm.paginationParam.total } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  index: index => index + 1,
                  "min-width": "80px",
                  width: "80px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(
                          _vm._s(_vm.convertListingModeToName(row.listingMode))
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "文件类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(row.type == 1 ? "文件" : "文本"))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileName",
                  label: "文件名称",
                  "min-width": "200"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileType",
                  label: "文件唯一标识",
                  "min-width": "150"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "role", label: "角色", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(_vm.convertRoleToName(row.role)))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "requireNumber", label: "需要数量" }
              }),
              _c("el-table-column", {
                attrs: { prop: "maxNumber", label: "最大可传数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(
                          _vm._s(row.maxNumber == -1 ? "无限制" : row.maxNumber)
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否必须" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(row.isRequire != 1 ? "否" : "是"))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(_vm._s(_vm._f("formatTime")(row.createdTime)))
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "排序" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(row.sort))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "生成节点" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.convertGeneratePostion(row.generatePosition)
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "是否可下载" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(row.canDownload ? "是" : "否"))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "文件" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        row.canDownload
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: row.downloadUrl,
                                  target: "download"
                                }
                              },
                              [_vm._v("下载")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(row, "0")
                              }
                            }
                          },
                          [_vm._v("复制")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(row, "1")
                              }
                            }
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.paginationParam.total,
          page: _vm.paginationParam.pageIndex,
          limit: _vm.paginationParam.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.paginationParam, "pageIndex", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.paginationParam, "pageSize", $event)
          },
          pagination: _vm.query
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }