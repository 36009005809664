var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "project-notice" },
    [
      _c(
        "el-form",
        { attrs: { inline: "", model: _vm.formInfo, size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.formInfo.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formInfo, "title", $$v)
                  },
                  expression: "formInfo.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属区域：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: _vm.isSuperAdmin != "1" },
                  on: { change: _vm.handleSelectedArea },
                  model: {
                    value: _vm.formInfo.areaConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.formInfo, "areaConfigId", $$v)
                    },
                    expression: "formInfo.areaConfigId"
                  }
                },
                _vm._l(_vm.areaData, function(item) {
                  return _c("el-option", {
                    key: item.areaConfigId,
                    attrs: {
                      label: item.areaConfigName,
                      value: item.areaConfigId
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  size: "small",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.time,
                  callback: function($$v) {
                    _vm.time = $$v
                  },
                  expression: "time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "global-dotted-btn vertical-bottom",
                  attrs: {
                    disabled:
                      _vm.isSuperAdmin != "1" && !_vm.formInfo.areaConfigId,
                    size: "small"
                  },
                  on: { click: _vm.query }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("nums-tips", { attrs: { nums: _vm.paginationParam.total } }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.loadingTxt,
                "header-cell-style": {
                  textAlign: "center",
                  background: "#FAFAFA"
                },
                "cell-style": { textAlign: "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  index: index => index + 1
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题", "min-width": "250" }
              }),
              _c("el-table-column", {
                attrs: { prop: "configType", label: "类型" }
              }),
              _c("el-table-column", {
                attrs: { prop: "areaConfigName", label: "所属区域" }
              }),
              _c("el-table-column", {
                attrs: { prop: "systemConfigName", label: "发布系统" }
              }),
              _c("el-table-column", {
                attrs: { label: "置顶" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleSwitch($event, row.id)
                            }
                          },
                          model: {
                            value: row.booleanTopState,
                            callback: function($$v) {
                              _vm.$set(row, "booleanTopState", $$v)
                            },
                            expression: "row.booleanTopState"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "time", label: "发布日期", "min-width": "190" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [_vm._v(_vm._s(row.publicTime))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function({ row }) {
                      return [
                        _vm.projectId != row.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDetails(row)
                                  }
                                }
                              },
                              [_vm._v("选择")]
                            )
                          : _vm._e(),
                        _vm.projectId == row.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(row)
                                  }
                                }
                              },
                              [_vm._v("移除")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.paginationParam.total,
          page: _vm.paginationParam.pageIndex,
          limit: _vm.paginationParam.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.paginationParam, "pageIndex", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.paginationParam, "pageSize", $event)
          },
          pagination: _vm.query
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }