var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "rating-standard" }, [
    _c("div", [
      _vm._v(
        "1、评级对象：凡申请挂牌备案的客户，如已有至少两个会计年度经营期财务报表，均应按规定进行信用等级评定。"
      )
    ]),
    _c("div", [
      _vm._v(
        "2、评级的企业类型：考虑到不同行业评价企业财务和经营状况的标准不同，主要参考上海和深圳证券交易所对上市公司的分类方法，将评级对象分为工业、商贸、公用事业、房地产开发、综合等五种类型，分别设置工业企业、商贸企业、房地产开发企业、公用事业企业、综合类企业等类型企业的信用评级指标体系与计分标准。"
      )
    ]),
    _c("div", [
      _vm._v(
        "3、评级指标体系：信用评级指标按偿债能力、获利能力、经营管理水平、履约情况及发展能力和潜力共五个方面设置。根据评级对象所属行业的差异及其资金运用和从事经营活动的特点，按上述五个方面有针对性地分别设置若干量化或非量化指标，由此构成一个完整的评级指标体系。"
      )
    ]),
    _c(
      "div",
      [
        _c("div", { staticClass: "next-table" }, [
          _vm._v(
            "4、评级采用百分制，见下表，企业信用评级指标体系与计分标准表。"
          )
        ]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData1,
              "span-method": _vm.arrayMethod1,
              border: ""
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                width: "50px",
                label: "序号",
                type: "index",
                index: index => index + 1,
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "180px",
                prop: "classify",
                label: "指标分类",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "180px",
                prop: "name",
                label: "指标名称",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "formula", label: "计算公式", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                width: "70px",
                prop: "value",
                label: "标准值",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "50px",
                prop: "fullMarks",
                label: "满分",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "explain", label: "记分标准说明", align: "center" }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "next-table" }, [
          _vm._v(
            "5、考虑到银行自身对客户取舍方面的信贷政策定位与要求，以及当前中国的中小企业普遍存在着在不良比率高、财务报表真实性差、经营波动性大、抗风险能力弱等问题，客户信用评级中还按不同评级企业类型设立信用等级限定指标，这些指标主要是资产负债率、利润增长率、履约指标、客户规模指标、同业竞争力及报表真实性等6项。企业信用评级指标体系与计分标准表："
          )
        ]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData2, "show-header": false, border: "" }
          },
          [_c("el-table-column", { attrs: { prop: "name" } })],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "next-table" }, [
          _vm._v(
            "6、信用等级的设置和评级标准。参照国际惯例，授信对象的信用等级划分为AAA、AA、A、BBB、BB、B、CCC、CC、C 和D级共10个等级。评级对象所获评级总分与信用等级和信用度的关系见下表。"
          )
        ]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData3,
              "span-method": _vm.arrayMethod3,
              "show-header": false,
              border: ""
            }
          },
          [
            _c("el-table-column", {
              attrs: { width: "100px", prop: "mark", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { width: "100px", prop: "grade", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { width: "100px", prop: "rank", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "explain", align: "center" }
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", [
      _vm._v(
        "7、客户信用评级管理：针对客户经营中可能出现的重大不利变动情况，必须对客户进行动态跟踪。如发生重大变动因素，须酌情调整有关评级对象的信用等级。"
      )
    ]),
    _c("div", [
      _vm._v(
        "8、评级不利因素可包括：客户主要评级指标明显恶化，导致评级分数降低10分或10分以上。客户主要管理人员涉嫌重大贪污、受贿、舞弊或违法经营案件。客户在业务往来中有重大违约行为。客户弄虚作假提供有关评级材料。客户发生或涉入重大诉讼或仲裁案件等。"
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }