var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "info-check",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("div", [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("企业名称")]),
            _c("span", [_vm._v(_vm._s(_vm.info.enterpriseName))])
          ]),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", [_vm._v("社会信用代码")]),
              _vm.creditCode
                ? _c("span", [_vm._v(_vm._s(_vm.info.creditCode))])
                : _c("el-input", {
                    attrs: {
                      size: "small",
                      oninput: "value=value.replace(/[^\\w]/g,'')",
                      placeholder: "请输入企业信用代码"
                    },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = $$v
                      },
                      expression: "code"
                    }
                  })
            ],
            1
          ),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("注册地址")]),
            _c("span", [_vm._v(_vm._s(_vm.info.registAddress))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("法人姓名")]),
            _c("span", [_vm._v(_vm._s(_vm.info.legalPerson))])
          ]),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", [_vm._v("法人身份证照")]),
              _vm.info.codeAddress &&
              _vm.info.codeAddress.toLowerCase().indexOf(".pdf") == -1
                ? _c("img", { attrs: { src: _vm.info.codeAddress } })
                : _c(
                    "el-link",
                    {
                      staticClass: "blue",
                      attrs: {
                        href: _vm.info.codeAddress,
                        target: "_blank",
                        underline: false
                      }
                    },
                    [_vm._v("法人身份证照")]
                  )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", [_vm._v("营业执照")]),
              _vm.info.fileAddr &&
              _vm.info.fileAddr.toLowerCase().indexOf(".pdf") == -1
                ? _c("img", { attrs: { src: _vm.info.fileAddr } })
                : _c(
                    "el-link",
                    {
                      staticClass: "blue",
                      attrs: {
                        href: _vm.info.fileAddr,
                        target: "_blank",
                        underline: false
                      }
                    },
                    [_vm._v("营业执照")]
                  )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _vm.type != "detail"
            ? _c("el-button", { on: { click: _vm.next } }, [_vm._v("下一步")])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }