
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({})
export default class FinancialData extends Vue {
  @Prop() private info!: any

  get financialInfo() {
    let year: any = {}
    let data: any = []
    this.info.map((item: any) => {
      if (item.code == 'year') {
        year = item
        return
      }
      let obj = {
        name: item.name,
        code: item.code,
        value: item.value
      }
      obj.value = this.priceFormat(obj.value)
      if (obj.name.indexOf('是否') > -1) {
        if (obj.value == 1) {
          obj.value = '是'
        } else {
          obj.value = '否'
        }
      }
      // if (
      //   obj.name.indexOf('是否') == -1 &&
      //   obj.name.indexOf('以上') == -1 &&
      //   obj.name.indexOf('超过') == -1
      // ) {
      //   obj.name += '(元)'
      // }
      data.push(obj)
    })
    data.unshift()
    return [year, data]
  }

  private priceFormat(value: any) {
    value = String(value)
    let sign = ''
    if (value.indexOf('-') > -1) {
      sign = '-'
      value = value.slice(1)
    }
    let num = '',
      tail = ''
    if (value.indexOf('.') > 0) {
      num = value.slice(0, value.indexOf('.'))
      tail = value.slice(value.indexOf('.'))
    } else {
      num = value.replace(/[^\d^\.]+/g, '')
    }
    let oldNum = num.split('').reverse()
    let newNum: any = []
    oldNum.map((item: any, index: number) => {
      newNum.push(item)
      if ((index + 1) % 3 == 0 && index + 1 != oldNum.length) {
        newNum.push(',')
      }
    })
    return `${sign}${newNum.reverse().join('')}${tail}`
  }
}
