
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { getCountrysideProjectList, projectLimitDetails, saveProjectLimit } from '@/api/countrysidePropertyTransaction'
import { Loading } from 'element-ui'
// import { hasIdCard, hasSocialCreditCode } from '@/utils/validate'
// import axios from 'axios'
@Component({
  components: {}
})
export default class ProjectLimitDetail extends Vue {
  private loadingTxt: string = loadingText
  private isSuperAdmin: any = '0'
  private nowAreaconfigId: any = ''
  // 下拉加载中文字显示开关
  private loadin: boolean = true
  // 展示下拉
  private showInput: boolean = false
  // 是否下拉加载禁止
  private disabled: boolean = false
  // 没有更多数据展示
  private noMore: boolean = false
  // 是否离开
  private isFocus: boolean = false
  // 加载页信息
  private nowPageInfo: any = {
    pageNumber: 1,
    pageSize: 4,
    total: 0
  }
  // 请求中标志
  private requestLoanding: boolean = false
  // 取消请求对象
  // private cancelToken: any = null
  // private areaOptions: any = [
  //   { areaConfigName: '百色市', areaConfigId: 1 },
  //   { areaConfigName: '田东县', areaConfigId: 2 }
  // ]
  // 展示的数据
  private showInfo: any = {
    // areaConfigName: '',
    name: '',
    businessTypeDesc: '',
    list: []
  }
  // 提交的数据
  private formInfo: any = {
    // areaConfigId: '',
    listingApplyId: '',
    list: [],
    deleteIds: []
  }
  // 旧查询记录
  private oldSearch: any = {
    listingApplyId: '',
    name: ''
  }
  // 项目名称查询结果
  private searchResult: any = []
  // 当前页面类型:search查询页面，add添加页面
  private pageType: any = 'add'
  // 类型参数
  private typeOptions: any = [
    {
      name: '个人',
      value: 2
    },
    {
      name: '企业',
      value: 1
    }
  ]
  created() {
    this.pageType = this.$route.params.type
    if (this.pageType == 'search') {
      let baseInfo: any = sessionStorage.getItem('projectLimitDetail')
      baseInfo = JSON.parse(baseInfo)
      this.formInfo.listingApplyId = baseInfo.id ? baseInfo.id : ''
      // this.formInfo.areaConfigId = this.$route.params.areaConfigId
      this.showInfo.areaConfigName = baseInfo.areaConfigName
      this.showInfo.name = baseInfo.name != null ? baseInfo.name : ''
      this.showInfo.businessTypeDesc = baseInfo.businessTypeDesc != null ? baseInfo.businessTypeDesc : ''
      projectLimitDetails({ listingApplyId: this.formInfo.listingApplyId }).then((res) => {
        if (res.data && res.data.code == 1000) {
          let showData = res.data.data
          for (let i = 0; i < showData.length; i++) {
            showData[i].noEitd = true
          }
          this.showInfo.list = showData
        }
      })
    } else {
      this.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (this.isSuperAdmin != '1') {
        this.nowAreaconfigId = sessionStorage.getItem('nowAreaconfigId')
      }
    }
    // else {
    //   let sourceToken: any = axios.CancelToken
    //   this.cancelToken = sourceToken.source()
    // }
  }
  // private blurFn(ent: any, row: any) {
  //   if (!hasIdCard(row.idCode) && !hasSocialCreditCode(row.idCode)) {
  //     this.$message.error('身份证/社会信用代码格式不对')
  //     row.idCode = ''
  //   }
  // }
  // 销毁前清理sessionStorage
  beforeDestroy() {
    sessionStorage.removeItem('projectLimitDetail')
  }
  // 输入框值改变事件
  private changeInput() {
    this.requestLoanding = false
    this.showInput = true
    this.disabled = false
    this.noMore = false
    this.nowPageInfo.pageNumber = 1
    this.nowPageInfo.total = 0
    this.loadin = true
    this.searchResult = []
    this.upLoad()
  }
  // 滚动刷新
  private upLoad() {
    if (this.requestLoanding) {
      return
    }
    this.requestLoanding = true
    this.loadin = true
    let isFail: boolean = true,
      params: any = {
        name: this.showInfo.name,
        pageNumber: this.nowPageInfo.pageNumber,
        pageSize: this.nowPageInfo.pageSize,
        status: 3
      }
    this.isSuperAdmin != '1' && (params.areaId = this.nowAreaconfigId)
    getCountrysideProjectList(params)
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          let resArr = res.data.data
          if (resArr.length) {
            this.searchResult.push(...resArr)
            this.nowPageInfo.total = res.data.total
            if (this.nowPageInfo.total > this.nowPageInfo.pageSize * this.nowPageInfo.pageNumber) {
              this.nowPageInfo.pageNumber += 1
              isFail = false
            } else {
              // this.disabled = true
              this.noMore = true
            }
          }
          // else {
          //   this.disabled = true
          // }
        }
      })
      .finally(() => {
        isFail && (this.disabled = true)
        this.loadin = false
        this.requestLoanding = false
      })
  }
  // 输入框失焦事件
  private outDiv() {
    if (!this.isFocus) {
      this.showInput = false
      if (this.formInfo.listingApplyId && this.showInfo.name != this.oldSearch.name) {
        this.showInfo.name = this.oldSearch.name
      } else if (!this.formInfo.listingApplyId) {
        this.showInfo.name = ''
        this.showInfo.businessTypeDesc = ''
        this.showInfo.areaConfigName = ''
      }
    }
  }
  // 输入框选中获得数据
  private getSelectData(obj: any) {
    this.showInfo.name = obj.name
    this.oldSearch.name = obj.name
    this.showInfo.businessTypeDesc = obj.businessTypeDesc
    this.showInfo.areaConfigName = obj.areaName
    this.formInfo.listingApplyId = obj.listingApplyId
    this.oldSearch.listingApplyId = obj.listingApplyId
    this.showInput = false
  }
  // 提交
  private handleSubmit() {
    let params: any = {
        ...this.formInfo
      },
      loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
    if (
      this.pageType == 'add' &&
      (!params.listingApplyId || !this.showInfo.list.length)
      // (!params.listingApplyId || !this.showInfo.list.length || !this.showInfo.areaConfigId)
    ) {
      this.$message({
        message: '请确保所有必填项已经输入或选择',
        type: 'error'
      })
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
      return
    } else {
      let nowForList = this.showInfo.list
      for (let i = 0; i < nowForList.length; i++) {
        if (!nowForList[i].noEitd && (!nowForList[i].name.trim() || !nowForList[i].idCode.trim())) {
          this.$message({
            message: '限制参与项内容不能为空',
            type: 'error'
          })
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          return
        }
        if (!nowForList[i].noEitd && nowForList[i].name.trim() && nowForList[i].idCode.trim()) {
          params.list.push(nowForList[i])
        }
      }
    }
    saveProjectLimit(params)
      .then((res) => {
        if (res.data && res.data.code == 1000) {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 300)
        }
      })
      .finally(() => {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close()
        })
      })
  }
  // 返回
  private handleReturn() {
    this.$router.go(-1)
  }
  // 删除
  private deleteDetails(ind: any) {
    this.$confirm('确认删除？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        if (this.pageType != 'add' && this.showInfo.list[ind].noEitd) {
          this.formInfo.deleteIds.push(this.showInfo.list[ind].id)
        }
        this.showInfo.list.splice(ind, 1)
      })
      .catch(() => {})
  }
  // 添加
  private addFn() {
    this.showInfo.list.push({
      type: 2,
      name: '',
      idCode: ''
    })
  }
}
