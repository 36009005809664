
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue' //分页
import { PaginationParam } from '@/views/type'
import numsTips from '@/components/numsTips/index.vue'
import {
  offlineProjectList,
  getOfflineProject,
  offlineProjectDelete,
  getAreaPowerList, getBusinessTypeList
} from '@/api/countrysidePropertyTransaction'
import { deleteExportWord } from '@/api/peasantInfoManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class ProjectOffline extends Vue {
  private loadingTxt: string = loadingText
  private businessOptions: any = []
  private isSuperAdmin: any = '0'
  private areaOptions: any = []
  private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private formInfo: any = {
    listingName: '',
    listingType: '',
    transferorName: '',
    transfereeName: '',
    areaId: '',
    tranferType: ''
  }
  private ruleTypeOptions: any = [
    { label: '竞拍', value: '1' },
    { label: '非竞拍', value: '2' },
    { label: '预售', value: '3' }
  ]
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams'),
        isGetList: boolean = true
      if (from.name == 'ProjectOfflineDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      vim.isSuperAdmin = sessionStorage.getItem('isSuperAdmin')
      if (vim.isSuperAdmin != '1') {
        !vim.formInfo.areaId && (vim.formInfo.areaId = sessionStorage.getItem('nowAreaconfigId'))
        !vim.formInfo.areaId && (isGetList = false)
      }
      if (isGetList && !vim.areaOptions.length) {
        getAreaPowerList().then((res) => {
          if (res.data.data) {
            vim.areaOptions = res.data.data
          }
        })
      }
      if (isGetList && !vim.businessOptions.length) {
        getBusinessTypeList().then((res) => {
          if (res.data.data) {
            vim.businessOptions = res.data.data
          }
        })
      }
      isGetList && vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'ProjectOfflineDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        areaOptions: this.areaOptions,
        businessOptions: this.businessOptions,
        time: this.time
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    this.time = ''
    Object.keys(this.formInfo).forEach((key) => {
      if (key != 'areaId' || (key == 'areaId' && this.isSuperAdmin == '1')) {
        this.formInfo[key] = ''
      }
    })
  }
  private handleSearch() {
    this.getList()
  }
  private getList() {
    if (this.time) {
      this.$set(this.formInfo, 'startTime', this.time[0])
      this.$set(this.formInfo, 'endTime', this.time[1])
    } else {
      this.$set(this.formInfo, 'startTime', '')
      this.$set(this.formInfo, 'endTime', '')
    }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      !params[key] && delete params[key]
    })
    offlineProjectList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private handleDetails(row: any) {
    this.$router.push({
      name: 'ProjectOfflineDetail',
      params: { offlineProjectId: row.offlineProjectId}
    })
  }
  private handleDelete(row: any) {
    this.$confirm('删除后数据将无法恢复，请谨慎操作。', {
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      type: 'warning'
    })
      .then(() => {
        offlineProjectDelete({ offlineProjectId: row.offlineProjectId }).then((res) => {
          this.getList()
        })
      })
      .catch(() => {})
  }
}
