var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "bidder-info" }, [
    _c("div", { staticClass: "part first" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("竞买人信息")]),
        _vm.checkboxShow && _vm.processStatus === 0
          ? _c("span", { staticClass: "red-Tips" }, [
              _vm._v("审核不通过请选择不合格项")
            ])
          : _vm._e()
      ]),
      _vm.info
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("竞买人身份")]),
                _vm.checkboxShow && _vm.processStatus === 0
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.userType,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "userType", $$v)
                        },
                        expression: "itemStatus.userType"
                      }
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.info.userType == 1
                        ? "个人"
                        : _vm.info.userType == 2
                        ? "企业"
                        : "组织"
                    )
                  )
                ])
              ],
              1
            ),
            _vm.info.userType == 2
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("法定代表")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.legalPerson,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "legalPerson", $$v)
                            },
                            expression: "itemStatus.legalPerson"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.legalPerson))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 2
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("企业名称")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.enterpriseName,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "enterpriseName", $$v)
                            },
                            expression: "itemStatus.enterpriseName"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.enterpriseName))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 2
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("统一信用代码")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.creditCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "creditCode", $$v)
                            },
                            expression: "itemStatus.creditCode"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.creditCode))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 2
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("注册地址")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.registAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "registAddress", $$v)
                            },
                            expression: "itemStatus.registAddress"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.registAddress))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 1
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("姓名")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "name", $$v)
                            },
                            expression: "itemStatus.name"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.name))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 1
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("身份证号")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.identityCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "identityCode", $$v)
                            },
                            expression: "itemStatus.identityCode"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.identityCode))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 3
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("组织名称")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "name", $$v)
                            },
                            expression: "itemStatus.name"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.name))])
                  ],
                  1
                )
              : _vm._e(),
            _vm.info.userType == 3
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("联系人")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.contactPerson,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "contactPerson", $$v)
                            },
                            expression: "itemStatus.contactPerson"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.contactPerson))])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("span", [_vm._v("联系电话")]),
                _vm.checkboxShow && _vm.processStatus === 0
                  ? _c("el-checkbox", {
                      model: {
                        value: _vm.itemStatus.contactPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.itemStatus, "contactPhone", $$v)
                        },
                        expression: "itemStatus.contactPhone"
                      }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.info.contactPhone))])
              ],
              1
            ),
            _vm.info.userType == 1 || _vm.info.userType == 3
              ? _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", [_vm._v("联系地址")]),
                    _vm.checkboxShow && _vm.processStatus === 0
                      ? _c("el-checkbox", {
                          model: {
                            value: _vm.itemStatus.contactAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.itemStatus, "contactAddress", $$v)
                            },
                            expression: "itemStatus.contactAddress"
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.info.contactAddress))])
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }