
import { Component, Vue, Prop } from 'vue-property-decorator'
import { editFileShow, deleteFiles, patchUpLoad } from '@/api/countrysidePropertyTransaction'
import uploadBase from '@/components/UploadBase64/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
import { download } from '@/utils/index'

@Component({
  components: {
    uploadBase
  }
})
export default class NeedData extends Vue {
  @Prop() info: any
  @Prop() otherFileList: any
  @Prop({
    default: () => {
      return []
    }
  })
  itemStatus?: any
  @Prop({
    default: () => {
      return []
    }
  })
  otherStatus?: any
  // @Prop({
  //   default: () => {
  //     return []
  //   }
  // })
  // fileDisabled: any
  // @Prop({
  //   default: () => {
  //     return []
  //   }
  // })
  // otherFileDisabled: any
  @Prop({ default: false }) checkboxShow ?: boolean
  @Prop({}) processStatus:number
  @Prop({ default: false }) isUpdateFiles?: boolean
  @Prop({ default: '' }) listingApplyId?: any
  @Prop({ default: null }) ruleType?: any
  //下载方法
  download: any = download
  private loadingTxt: string = loadingText
  private loading: boolean = false
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.png,.jpg,.pdf,.dox,.docx',
    flag: '1'
  }
  private handleSwitch(val: boolean, fileId: any, ind: number, type: any) {
    this.loading = true
    let param = { ruleType: this.ruleType, fileId, isShow: val, type }
    // type == 2 ? this.$set(this.otherFileDisabled, ind, true) : this.$set(this.fileDisabled, ind, true)
    editFileShow(param)
      .then((res) => {
        this.$message.success('展示切换成功')
      })
      .catch(() => {
        type == 2 ? (this.otherFileList[ind].isShow = !val) : (this.info[ind].isShow = !val)
      })
      .finally(() => {
        this.loading = false
        // type == 2 ? this.$set(this.otherFileDisabled, ind, false) : this.$set(this.fileDisabled, ind, false)
      })
  }
  private beforeUpFn() {
    this.loading = true
  }
  private uploadError() {
    this.loading = false
  }
  private getFileUrl(v: any) {
    this.uploadOption.limit += 1
    if (v) {
      patchUpLoad({
        type: 3,
        fileName: v.name,
        fileUrl: v.url,
        listingApplyId: this.listingApplyId
      })
        .then((res) => {
          let datas: any = res.data.data
          if (datas && datas.id) {
            this.$message.success('上传成功')
            this.otherFileList.push({
              fileId: datas.id,
              fileName: v.name,
              fileUrl: v.url,
              isShow: true,
              type: 2
            })
            this.$emit('updateFilesFn')
          }
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.$message.error('上传失败')
      this.loading = false
    }
  }
  private updateFiles() {
    ;(document as any).querySelector('#needfiles-btn input').click()
  }
  private deleteFile(fileId: any, index: any) {
    this.$confirm('是否确认删除？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        deleteFiles({
          fileId,
          type: 3
        })
          .then((res) => {
            this.$message.success('删除成功')
            this.otherFileList.splice(index, 1)
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch(() => {})
  }
}
