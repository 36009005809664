
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import UploadBase from '@/components/UploadBase64/index.vue'
import bgSrc from '@/assets/images/up_files.png'
import { Loading } from 'element-ui'
import { countrysideFinancialDetails, uploadVoucher, deleteVoucher, confirmFinance } from '@/api/countrysidePropertyTransaction'
@Component({
  components: {
    UploadBase
  }
})
export default class CountrysideFinancialDetail extends Vue {
  private loadingTxt: string = loadingText
  private delistingAmountFlowId: any
  private info: any = {}
  private bgImg: any = bgSrc
  private showEnlarge: boolean = false // 放大图片
  private showEndBtn: boolean = false // 未到账、继续挂牌按钮展示标志位
  private uploadOption: any = {
    limit: 1,
    accept: '.jpeg,.jpg,.png',
    flag: '1'
  }
  private loadingInstance: any
  private nowSrc: any = '' // 当前凭证图片路径
  unitTimeOption = {
    '0': '无',
    '1': '年 ',
    '2': '季度 ',
    '3': '月 '
  }
  // 获取info长度
  get getInfoLength() {
    return Object.keys(this.info).length
  }
  get unitAppend() {
    if (this.info.bidType == 1) {
      return `${((this.info.unitNum == '无' || !this.info.unitNum) ? '' : '/' + this.info.unitNum) +
        ((this.info.unitTime == '0' || !this.info.unitTime) ? '' : '/' + this.unitTimeOption[this.info.unitTime])}`
    } else {
      return ''
    }
  }
  created() {
    if (this.$route.params.delistingAmountFlowId) {
      this.delistingAmountFlowId = this.$route.params.delistingAmountFlowId
      sessionStorage.setItem('countrysideFinancialDetails', this.delistingAmountFlowId)
    } else {
      this.delistingAmountFlowId = sessionStorage.getItem('countrysideFinancialDetails')
    }
    this.getData()
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    if (to.name != 'CountrysideProjectDetail') {
      sessionStorage.removeItem('countrysideFinancialDetails')
    }
    next()
  }
  private getData() {
    // console.log({delistingAmountFlowId: this.delistingAmountFlowId});
    countrysideFinancialDetails({
      delistingAmountFlowId: this.delistingAmountFlowId
    }).then((res) => {
      if (res.data.code == 1000 && res.data.data) {
        this.info = res.data.data
        this.info.status == '2' && this.info.listing && ['1', '2', '3'].indexOf(this.info.delistingType + '') != -1 && ['8', '6'].indexOf(this.info.type + '') == -1 && this.info.tranferType != 1 && (this.showEndBtn = true)
      }
    })
  }
  // 返回页面
  private returnPaper() {
    this.$router.go(-1)
  }
  private intoDetails() {
    this.$router.push({
      name: 'CountrysideProjectDetail',
      params: { listingApplyId: this.info.listingApplyId, isExport: '0' }
    })
  }
  private beforeUpFn() {
    this.loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
  }
  private uploadError() {
    this.$nextTick(() => {
      // 以服务的方式调用的 Loading 需要异步关闭
      this.loadingInstance.close()
    })
  }
  private getFileUrl(v: any) {
    this.uploadOption.limit = this.uploadOption.limit + 1
    if (v) {
      uploadVoucher({
        delistingAmountFlowId: this.delistingAmountFlowId,
        url: v.url,
        name: '缴费凭证'
      })
        .then((res) => {
          if (res.data && res.data.code == 1000) {
            this.info.paymentVouchers.push({
              name: '缴费凭证',
              url: v.url,
              voucherId: res.data.data.voucherId
            })
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close()
          })
        })
    }
  }
  private enlargeImg(src: any) {
    this.nowSrc = src
    this.showEnlarge = true
  }
  private deleteImgFn(index: number, deletedItem: any) {
    this.$confirm('是否确认删除？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
        deleteVoucher({
          voucherId: deletedItem.voucherId
        })
          .then((res) => {
            if (res.data && res.data.code == 1000) {
              this.info.paymentVouchers.splice(index, 1)
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      })
      .catch(() => { })
  }
  private accountFn(type: number) {
    let contentText = '是否确认该笔资金未到账？'
    if (type == 2) {
      contentText = '是否确认该笔资金已到账？'
    } else if (type == 3) {
      contentText = '是否确认该笔资金未到账，且将项目修改为挂牌中？'
    }
    this.$confirm(contentText, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        let loadingInstance = Loading.service({ target: document.body, text: this.loadingTxt })
        confirmFinance({
          delistingAmountFlowId: this.delistingAmountFlowId,
          type: type
        })
          .then((res) => {
            if (res.data && res.data.code) {
              this.$message({
                type: 'success',
                message: '提交成功'
              })
              setTimeout(() => {
                this.$router.go(-1)
              }, 300)
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close()
            })
          })
      })
      .catch(() => { })
  }
}
