
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
// import timeFormat from '@/utils/timeFormat' //时间过滤器
import { PaginationParam } from '@/views/type'
import {
  getLoanProductsList,
  // getPropertyrightTransactionCenterData,
  deleProduct
} from '@/api/loans'
// import { searchArea } from '@/api/noticeManagement'
import numsTips from '@/components/numsTips/index.vue'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    numsTips,
    Pagination
  }
  // filters: {
  //   formatTime: (time: any) => {
  //     if (time) return timeFormat(new Date(time))
  //   }
  // }
})
export default class LoanProducts extends Vue {
  private loadingTxt: string = loadingText
  private tableData: any[] = []
  private loading = false
  // private time: any = ''
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // private areaData: any = [
  //   {
  //     areaConfigId: '',
  //     areaConfigName: '全部'
  //   }
  // ]
  private formInfo: any = {
    bankName: '',
    // areaConfigId: '',
    // propertyTradingCenter: '',
    productName: '',
    startAmount: '',
    endAmount: ''
  }
  // private tradeCenterOptions: Array<any> = []

  // created() {
  // searchArea({ isDelete: false, isDisable: false }).then((res) => {
  //   if (res.data && res.data.code == 1000) {
  //     this.areaData = this.areaData.concat(res.data.data.aeras)
  //   }
  // })
  // // this.getTradeCenterData()
  //   this.query()
  // }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'LoanProductsDetail' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.formInfo = pageParams.formInfo
        vim.paginationParam = pageParams.paginationParam
      }
      vim.query()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'LoanProductsDetail') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
  }
  // getTradeCenterData() {
  //   const data: any = {
  //     categoryName: 'propertyright_transaction_center'
  //   }
  // getPropertyrightTransactionCenterData(data)
  //   .then((res) => {
  //     let data: any = res.data.data
  //     let arr: any = []
  //     data.forEach((item: any) => {
  //       arr.push({ label: item.value, value: item.key })
  //     })
  //     arr.unshift({ label: '全部', value: '' })
  //     this.tradeCenterOptions = arr
  //   })
  // }

  private async query() {
    // if (this.time) {
    //   this.$set(this.formInfo, 'startTime', this.time[0])
    //   this.$set(this.formInfo, 'endTime', this.time[1])
    // } else {
    //   this.$set(this.formInfo, 'startTime', '')
    //   this.$set(this.formInfo, 'endTime', '')
    // }
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    getLoanProductsList(params as any).then((res) => {
      if (res.data.data) {
        this.tableData = res.data.data
        // this.filterData(res.data.data)
        this.paginationParam.total = res.data.total
        this.loading = false
      }
    })
  }

  // filterData(arr: Array<any>) {
  //   let _tradeCenterOptions = this.tradeCenterOptions
  //   arr.forEach((item) => {
  //     _tradeCenterOptions.forEach((it) => {
  //       if (it.value == item.propertyTradingCenter) {
  //         item.propertyTradingCenter = it.label
  //       }
  //     })
  //   })
  //   this.tableData = arr
  // }

  handleAddLoanProduct() {
    // let _companyOptions: any = this.tradeCenterOptions
    // _companyOptions.shift()
    this.$router.push({
      name: 'LoanProductsDetail',
      params: {
        disabled: 'false',
        flag: '0'
        // tradeCenterOptions: _companyOptions
      }
    })
  }

  deleteProduct(datas: any) {
    this.$confirm('确认删除？').then(() => {
      this.loading = true
      const params = {
        id: datas.id
      }
      deleProduct(params)
        .then((res) => {
          if (res.data.code == 1000) {
            this.paginationParam = {
              pageIndex: 1,
              pageSize: 10,
              total: 0
            }
            this.query()
          }
        })
        .finally(() => {
          this.loading = false
        })
    })
  }

  private handleDetails(datas: any) {
    // let _companyOptions: any = this.tradeCenterOptions
    // _companyOptions.shift()
    this.$router.push({
      name: 'LoanProductsDetail',
      params: {
        dataDetail: datas,
        disabled: 'true',
        flag: '1'
        // tradeCenterOptions: _companyOptions
      }
    })
  }
}
